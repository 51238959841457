import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const MISSING_EMPLOYMENTS_QUERY = gql`
  query missingEmploymentsQuery(
    $id: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    import(id: $id) {
      id
      analysis {
        missingEmployments(
          first: $first
          after: $after
          last: $last
          before: $before
        ) {
          totalCount
          pageInfo {
            ...pageInfoDetails
          }
          edges {
            node {
              membership {
                employments(first: 1) {
                  edges {
                    node {
                      id
                      period
                    }
                  }
                }
                id
                userAccountId
                naturalPersonIdentifiers
                givenName
                lastName
              }
            }
          }
        }
      }
    }
  }
  ${pageInfoDetails}
`;
