import {
  FlexSalaryExchangeRequestStatus,
  salaryExchangeRequestsQuery,
  salaryExchangeRequestsQueryVariables,
} from 'app/apollo/graphql/types';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { RequestsTable } from './components/Table';
import { SALARY_EXCHANGE_REQUESTS_QUERY } from './graphql/queries';
import { RequestSalaryExchangeType } from './types';
import { updateQuery } from './utils/update-query';

const isEmpty = (data?: salaryExchangeRequestsQuery) =>
  !data?.salaryExchangeRequests?.edges.length;

const hasPendingRequests = (
  data?: salaryExchangeRequestsQuery,
): data is salaryExchangeRequestsQuery => !isEmpty(data);

export const SalaryExchangeRequests: React.FC = () => {
  const suspendRef = useRef(true);

  const [perPage, setPerPage] = useState(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data, loading, previousData, fetchMore } = useQuery<
    salaryExchangeRequestsQuery,
    salaryExchangeRequestsQueryVariables
  >(SALARY_EXCHANGE_REQUESTS_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    suspend: suspendRef.current,
    isEmpty,
    variables: {
      companyId,
      statuses: [FlexSalaryExchangeRequestStatus.REQUESTED],
      first: Number(perPage),
    },
    onCompleted: () => {
      suspendRef.current = false;
    },
  });

  const _data = data ?? previousData;

  if (!_data?.salaryExchangeRequests || !hasPendingRequests(_data)) {
    return null;
  }

  const { edges, pageInfo } = _data.salaryExchangeRequests;

  const salaryExchangeRequests = edges.map(e => e.node);

  const navigation = (
    <TableNavigation
      perPage={perPage}
      setPerPage={setPerPage}
      pageInfo={pageInfo}
      onNextPage={() => {
        fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
          updateQuery,
        });
      }}
      onPreviousPage={() => {
        fetchMore({
          variables: {
            before: pageInfo.startCursor,
            first: undefined,
            last: Number(perPage),
          },
          updateQuery,
        });
      }}
    />
  );

  return (
    <>
      {!suspendRef.current && loading && <TopLoading />}
      <RequestsTable
        type={RequestSalaryExchangeType.ONGOING}
        requests={salaryExchangeRequests}
        navigation={navigation}
      />
    </>
  );
};
