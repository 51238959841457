import { Section, SectionHeader } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { Company } from 'features/sme/companies/company';
import { smeCompanyMessages } from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const SmeCompanyOverviewRoute: React.FC = () => (
  <Suspense fallback={<TopLoading />}>
    {({ errors }) => (
      <>
        {errors && <GraphQlErrors errors={errors} />}
        <Section>
          <SectionHeader>
            <FormattedMessage {...smeCompanyMessages.organisationInformation} />
          </SectionHeader>
          <Company />
        </Section>
      </>
    )}
  </Suspense>
);
