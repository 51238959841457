import { NaturalPersonIdentifier } from '@advinans/belt-natural-person-identifier';

/**
 * @returns the 10 digit ISO formatted birthdate parsed from the nid,
 * or null if unable to parse a natural personal identifier from input
 */
export const getBirthdateFromNid = (nid?: string | null): string | null => {
  if (!nid) {
    return null;
  }

  try {
    const _nid = NaturalPersonIdentifier.FromString(nid);

    const birthYear = _nid.toString().slice(6, 10);
    const birthMonth = _nid.toString().slice(10, 12);
    const birthDay = Number(_nid.toString().slice(12, 14));

    return new Date(
      `${birthYear}-${birthMonth}-${(
        birthDay - (_nid.isCoordinationNumber() ? 60 : 0)
      )
        .toString()
        .padStart(2, '0')}`,
    )
      .toISOString()
      .slice(0, 10);
  } catch {
    return null;
  }
};
