import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import React from 'react';

import { companySalaryExchangeMessages } from '../../../messages';

export const RequestsEmptyState: React.FC = () => (
  <EmptyState
    title={
      <FormattedMessage
        {...companySalaryExchangeMessages.noSalaryExchangeRequestItems}
      />
    }
  >
    <FormattedMessage
      {...companySalaryExchangeMessages.noSalaryExchangeRequestItemsText}
    />
  </EmptyState>
);
