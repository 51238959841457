import { ApolloError } from '@apollo/client';
import React, {
  createContext,
  useCallback,
  useContext,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';

interface QueryState {
  isEmpty: boolean;
  loading: boolean;
  error?: ApolloError;
}

interface ContextState {
  addQuery: (id: string) => void;
  /**
   * Indicates if the suspense context has an empty state.
   *
   * Becomes `true` if the `isEmpty` callback is triggered or
   * an `emptyState` component is passed. When `true`, the
   * `isEmpty` function must be passed as a query option in
   * `useQuery` to determine if the query result is empty.
   *
   */
  hasEmptyState: boolean;
  isEmpty: boolean;
  loading: boolean;
  removeQuery: (index: string) => void;
  updateQuery: (index?: string, state?: QueryState) => void;
  batch?: boolean;
  batchId?: string;
}

interface InternalQueryState {
  queries: Record<string, QueryState>;
}

interface Args {
  isEmpty: () => boolean;
  errors?: ApolloError[];
}

interface Props {
  children: (args: Args) => React.ReactNode;
  fallback: React.ReactNode;
  batch?: boolean;
  emptyState?: React.ReactNode;
}

const isApolloError = (
  possibleError?: ApolloError,
): possibleError is ApolloError => possibleError != null;

export const SuspenseContext = createContext<ContextState>({
  addQuery: () => '',
  updateQuery: () => undefined,
  removeQuery: () => undefined,
  loading: false,
  isEmpty: true,
  hasEmptyState: false,
});

export const useSuspenseContext = (): ContextState =>
  useContext(SuspenseContext);

/**
 * Manages a suspense context in which queries may be suspended
 * by using useQuery with suspend: true option
 */
export const Suspense: React.FC<Props> = ({
  batch: _batch,
  fallback,
  children,
  emptyState,
}) => {
  const initalRender = useRef(true);
  const [{ queries }, setQueries] = useState<InternalQueryState>({
    queries: {},
  });
  const batchId = useId();
  const [isEmptyCalled, setIsEmptyCalled] = useState<boolean>(false);

  const [contextState] = useState<
    Omit<ContextState, 'loading' | 'isEmpty' | 'hasEmptyState'>
  >(() => ({
    batch: _batch,
    batchId: _batch ? batchId : undefined,
    removeQuery: (id: string) => {
      setQueries(({ queries: _queries }) => {
        const { [id]: _, ...rest } = _queries;
        return { queries: rest };
      });
    },
    updateQuery: (id: string, state: QueryState) => {
      setQueries(({ queries: _queries }) => ({
        queries: {
          ..._queries,
          [id]: {
            loading: state.loading,
            error: state.error,
            isEmpty: state.isEmpty,
          },
        },
      }));
    },
    addQuery: (id: string) => {
      setQueries(({ queries: _queries }) => ({
        queries: { ..._queries, [id]: { loading: true, isEmpty: false } },
      }));
    },
  }));

  const [loading, isEmpty] = useMemo(() => {
    if (initalRender.current) {
      initalRender.current = false;
      return [true, false];
    }
    const _queries = Object.values(queries);
    const _loading = _queries.some(query => query.loading);
    const _isEmpty =
      !!_queries.length && _queries.every(query => query.isEmpty);
    return [_loading, _isEmpty];
  }, [queries]);

  const hasEmptyState = useMemo(
    () => isEmptyCalled || !!emptyState,
    [isEmptyCalled, emptyState],
  );

  const value = useMemo(
    () => ({
      ...contextState,
      loading,
      isEmpty,
      hasEmptyState,
    }),
    [contextState, loading, isEmpty, hasEmptyState],
  );

  const isEmptyCallback = useCallback(() => {
    if (!isEmptyCalled) {
      setIsEmptyCalled(true);
    }
    return isEmpty && !loading;
  }, [isEmptyCalled, isEmpty, loading]);

  return (
    <SuspenseContext.Provider value={value}>
      {loading && !!Object.keys(queries).length && fallback}
      <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
        {children({
          isEmpty: isEmptyCallback,
          errors: !loading
            ? Object.values(queries)
                .map(query => query.error)
                .filter(isApolloError)
            : undefined,
        })}
        {isEmpty && !loading && emptyState ? emptyState : null}
      </div>
    </SuspenseContext.Provider>
  );
};
