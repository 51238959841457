import gql from 'graphql-tag';

export const CREATE_JOB_OFFER_LAYOUT_QUERY = gql`
  query createJobOfferLayoutQuery($id: ID!) {
    company(id: $id) {
      id
      logoUrl
    }
  }
`;
