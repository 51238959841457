import { SelectField } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { FormattedMessage } from 'components/formats';
import { InlineSelectWrapper } from 'components/InlineSelect';
import qs from 'query-string';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const DEFAULT_PER_PAGE_OPTIONS = [10, 50, 75, 100];

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const InnerLabel = styled.label`
  margin-right: 0.125rem;
  color: ${p => p.theme.onSurfaceVariant};
`;

export interface PerPageSelectProps {
  perPage?: string;
  setPerPage?: (perPage: string) => void;
}

export const PerPageSelect: React.FC<PerPageSelectProps> = ({
  perPage,
  setPerPage,
}) => {
  const history = useHistory();

  if (setPerPage) {
    return (
      <Container>
        <InnerLabel htmlFor="per_page">
          <FormattedMessage {...commonMessages.perPageResult} />
        </InnerLabel>
        <InlineSelectWrapper nbrOfCharacters={perPage?.length ?? 3}>
          <SelectField
            required
            value={perPage}
            label="per page select"
            onChange={({ detail: { value } }) => {
              setPerPage(value);
            }}
            options={DEFAULT_PER_PAGE_OPTIONS.map(option => ({
              label: option,
              value: option,
            }))}
            withoutMargin
          />
        </InlineSelectWrapper>
      </Container>
    );
  }

  const { per_page } = qs.parse(location.search);

  const handleChange = ({ detail: { value } }) => {
    const currentSearch = qs.parse(location.search);
    const search = qs.stringify({ ...currentSearch, per_page: value });
    history.push({
      search,
    });
  };

  const perPageValue: string =
    per_page ?? (perPage || DEFAULT_RESULT_PER_PAGE.toString());

  return (
    <Container>
      <InnerLabel htmlFor="per_page">
        <FormattedMessage {...commonMessages.perPageResult} />
      </InnerLabel>
      <InlineSelectWrapper nbrOfCharacters={perPageValue.length}>
        <SelectField
          required
          value={perPageValue}
          label="per page select"
          onChange={handleChange}
          options={DEFAULT_PER_PAGE_OPTIONS.map(option => ({
            label: option,
            value: option,
          }))}
          withoutMargin
        />
      </InlineSelectWrapper>
    </Container>
  );
};
