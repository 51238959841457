import gql from 'graphql-tag';

export const COLLECTUM_INFO_QUERY = gql`
  query collectumInfoQuery($companyId: ID!, $benefitTypeNames: [String!]) {
    company(id: $companyId) {
      id
      benefits: flexBenefits(benefitTypeNames: $benefitTypeNames) {
        edges {
          node {
            id
            type
            content {
              locale
            }
            configuration {
              data
            }
          }
        }
      }
    }
  }
`;
