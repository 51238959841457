import { useMutation } from '@apollo/client';
import {
  deleteSharedFileMutation,
  deleteSharedFileMutationVariables,
  SharedFile,
} from 'app/apollo/graphql/types';
import { sharedFileMessages } from 'app/messages/shared-files';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';

import { DELETE_SHARED_FILE_MUTATION } from './graphql/mutations';

export const useDelete = () => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const { confirm } = useConfirm();

  const [deleteSharedFile, { loading, error }] = useMutation<
    deleteSharedFileMutation,
    deleteSharedFileMutationVariables
  >(DELETE_SHARED_FILE_MUTATION);

  const _delete = async (sharedFile: SharedFile) => {
    const confirmed = await confirm({
      title: formatMessage(sharedFileMessages.deleteFile),
      description: formatMessage(sharedFileMessages.confirmDeleteSharedFile, {
        filename: sharedFile.file.filename,
      }),
    });

    if (!confirmed) {
      return;
    }

    await deleteSharedFile({
      update: cache => {
        cache.evict({
          id: `SharedFile:${sharedFile.id}`,
        });
        cache.gc();
      },
      variables: { input: { id: sharedFile.id } },
    });

    send({
      message: formatMessage(sharedFileMessages.deleteSharedFileSuccess),
      type: 'success',
    });
  };
  return { _delete, loading, error };
};
