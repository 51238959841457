import gql from 'graphql-tag';

export const UPDATE_MEMBERSHIP_BENEFIT_PACKAGE = gql`
  mutation updateMembershipBenefitPackageMutation(
    $input: UpdateMembershipBenefitPackageInput!
  ) {
    updateMembershipBenefitPackage(input: $input) {
      benefitPackageId
    }
  }
`;
