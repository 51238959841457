import { FieldInlineSearchableSelect, Form, Formik } from '@frontend/formik';
import { Button, Table, Td, Th, Tr, WizardSection } from '@frontend/ui';
import {
  importColumnsQuery,
  importColumnsQuery_import_Import_columnMappings_ImportColumnMapping as ColumnMappings,
  importColumnsQueryVariables,
  ImportStatus,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employeesImportMessages } from 'app/messages/employees';
import { HandleHistoryReplace } from 'app/pages/companies/company/employees/import';
import { POLL_INTERVAL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { Modal } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { SimpleWizard } from 'components/SimpleWizard';
import { TopLoading } from 'components/TopLoading';
import format from 'date-fns/format';
import { useTransitionOverlay } from 'features/transition-overlay';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { MatchParams as CompanyMatchParams } from '../../..';
import { ImportSettings } from './components/ImportSettings';
import { IMPORT_COLUMNS_QUERY } from './graphql/queries';
import {
  formValueIdentifier,
  FormValues,
  toFormValues,
} from './utils/form-values';
import { useSubmit } from './utils/use-submit';
import { useTargetColumnOptions } from './utils/use-target-column-options';
import { validationSchema } from './utils/validation-schema';

interface Props {
  onContinue: HandleHistoryReplace;
  title: React.ReactNode;
}

export interface MatchParams extends CompanyMatchParams {
  importId: string;
}

export const Match: React.FC<Props> = ({ title, onContinue }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const {
    params: { companyId, importId },
  } = useRouteMatch<MatchParams>();

  const { startTransition, clearTransition } = useTransitionOverlay();

  const [validated, setValidated] = useState<boolean>(false);

  const validateImportStatus = (_importData: importColumnsQuery) => {
    const _importStatus = _importData?.import?.status;
    if (
      _importStatus &&
      _importStatus !== ImportStatus.PENDING &&
      _importStatus !== ImportStatus.NO_CHANGES &&
      _importStatus !== ImportStatus.PROCESSING
    ) {
      onContinue({ importId });
    }
    setValidated(true);
  };

  const {
    data: importData,
    loading: importLoading,
    error: importError,
    startPolling,
    stopPolling,
  } = useQuery<importColumnsQuery, importColumnsQueryVariables>(
    IMPORT_COLUMNS_QUERY,
    {
      variables: { id: importId },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (importData) {
      validateImportStatus(importData);
    }
  }, [importData]);

  const importStatus = importData?.import?.status;

  useEffect(() => {
    if (importStatus && importStatus === ImportStatus.PROCESSING) {
      startPolling(POLL_INTERVAL);
      startTransition({
        illustration: 'import-file',
        title: employeesImportMessages.generateChangesTitle,
        description: formatMessage(
          employeesImportMessages.generateColumnChangeDescription,
        ),
      });
    }
    if (importStatus && importStatus !== ImportStatus.PROCESSING) {
      stopPolling();
      clearTransition();
    }
  }, [importStatus]);

  const nonEmptyColumn = (mapping: ColumnMappings | null) => mapping || [];

  const currentColumns =
    importData?.import?.columnMappings &&
    importData?.import?.columnMappings.flatMap(nonEmptyColumn);

  const { submissionLoading, submit, submissionError } = useSubmit({
    currentColumns,
    onCompleted: () => {
      onContinue({ importId });
    },
  });

  const options = useTargetColumnOptions();

  if (
    importStatus === ImportStatus.PROCESSING ||
    !currentColumns ||
    !validated
  ) {
    return <Modal size="full-window" />;
  }

  const initialValues: FormValues = {
    benefitsStartWithEmployment:
      importData?.import?.benefitsStartWithEmployment ?? false,
    createMissingOrganizationalUnits:
      importData?.import?.createMissingOrganizationalUnits ?? false,
    fallbackDateForBenefits: format(
      new Date(importData?.import?.fallbackDateForBenefits ?? ''),
      'yyyy-MM',
    ),
    updatedColumns: toFormValues(currentColumns),
  };

  const withForm = (content: React.ReactNode) => <Form>{content}</Form>;

  return (
    <Formik<FormValues>
      onSubmit={submit}
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      {({ isValid, isSubmitting, errors: formikErrors }) => (
        <SimpleWizard
          title={title}
          parentLink={`/companies/${companyId}/employees/imports`}
          actions={
            <Button
              filled
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
            >
              <FormattedMessage {...commonMessages.continue} />
            </Button>
          }
          withForm={withForm}
        >
          {(importLoading || submissionLoading) && <TopLoading />}
          {(importError || submissionError) && (
            <NotificationCard type="error">
              {importError
                ? formatMessage(employeesImportMessages.importColumnsError)
                : formatMessage(employeesImportMessages.updateColumnsError)}
            </NotificationCard>
          )}
          {formikErrors.updatedColumns && (
            <NotificationCard type="error">
              <FormattedMessage
                {...employeesImportMessages.invalidColumnsError}
              />
            </NotificationCard>
          )}
          <>
            <WizardSection>
              <DescriptionWrapper>
                <FormattedMessage
                  {...employeesImportMessages.updateColumnsDescription}
                />
              </DescriptionWrapper>
              <Table size="small" fixed>
                <colgroup>
                  <col style={{ width: '50%' }} />
                  <col style={{ width: '50%' }} />
                </colgroup>
                <thead>
                  <Tr>
                    <Th>
                      <FormattedMessage {...employeesImportMessages.column} />
                    </Th>
                    <Th align="left">
                      <FormattedMessage {...employeesImportMessages.field} />
                    </Th>
                  </Tr>
                </thead>
                <tbody>
                  {currentColumns.map(column => (
                    <Tr key={column.source}>
                      <Th>{column.source}</Th>
                      <Td>
                        <FieldInlineSearchableSelect
                          name={formValueIdentifier(column.source)}
                          options={options}
                        />
                      </Td>
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </WizardSection>
            <ImportSettings />
          </>
        </SimpleWizard>
      )}
    </Formik>
  );
};
