import { Form, Formik, SelectField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Figure,
  Grid,
  GridCell,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectOption,
  Subsection,
} from '@frontend/ui';
import { companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge_node_FlexFcfwRequest as FcfwRequest } from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell50 } from 'components/GridCell';
import { Modal } from 'components/Modal';
import React from 'react';
import * as Yup from 'yup';

import { FcfwInfoCollapsible } from '../components/FcfwInfoCollapsible';
import { FcfwStatus } from '../components/FcfwStatus';
import { workCapabilityMessages } from '../messages';
import { fcfwStatus } from '../utils/fcfw-status';
import { useSubmit } from './use-submit';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    effectiveDate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  effectiveDate: string;
  userAccountId: string;
}

interface Props {
  infoText: string;
  isOpen: boolean;
  onRequestClose: () => void;
  request: FcfwRequest;
}

export const AssertWorkCapabilityModal: React.FC<Props> = ({
  isOpen,
  request,
  infoText,
  onRequestClose,
}) => {
  const intl = useIntl();
  const { submit, submissionLoading, submissionError } = useSubmit({
    companyId: request.companyId,
    infoText,
    onSuccess: onRequestClose,
  });

  const {
    userAccountId,
    possibleAssertionDates,
    requestedDate,
    membership: { givenName, lastName, naturalPersonIdentifiers },
  } = request;

  const effectiveDateOptions: SelectOption[] = possibleAssertionDates.map(
    date => ({
      value: date,
      label: date,
    }),
  );

  const initialValues = {
    userAccountId,
    effectiveDate: requestedDate,
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage
          {...workCapabilityMessages.confirmFullWorkCapabilityFor}
          values={{
            name: `${givenName} ${lastName}`,
          }}
        />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema(intl)}
      >
        {({ isValid, values: { effectiveDate } }) => (
          <Form>
            <ModalBody>
              <Subsection>
                <FormattedMessage
                  {...workCapabilityMessages.confirmFullWorkCapabilityLede}
                />
              </Subsection>
              <Subsection>
                <Grid>
                  <GridCell span={12}>
                    <Figure
                      title={
                        <FormattedNaturalPersonIdentifier
                          nids={naturalPersonIdentifiers}
                        />
                      }
                      description={
                        <FormattedMessage
                          {...commonMessages.personalIdentityNumber}
                        />
                      }
                    />
                  </GridCell>
                  <GridCell50>
                    <SelectField
                      required
                      fixed
                      dense
                      label={
                        <FormattedMessage {...commonMessages.effectiveDate} />
                      }
                      name="effectiveDate"
                      options={effectiveDateOptions}
                    />
                  </GridCell50>

                  {effectiveDate && (
                    <GridCell span={12}>
                      <Figure
                        title={
                          <FcfwStatus
                            status={fcfwStatus([request], effectiveDate)}
                          />
                        }
                        description={
                          <FormattedMessage {...commonMessages.status} />
                        }
                      />
                    </GridCell>
                  )}
                </Grid>
              </Subsection>
              <Subsection>
                <FcfwInfoCollapsible infoText={infoText} />
              </Subsection>
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  type="submit"
                  text
                  disabled={!isValid}
                  loading={submissionLoading}
                >
                  <FormattedMessage {...formMessages.approve} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
