import { Dot } from '@frontend/ui';
import {
  FlexSalaryExchangeRequestStatus,
  FlexSalaryExchangeStatus,
} from 'app/apollo/graphql/types';
import React from 'react';

type RequestStatus = Omit<FlexSalaryExchangeRequestStatus, 'REJECTED'>;

interface SalaryExchange {
  effectiveDate: string;
  status: FlexSalaryExchangeStatus | RequestStatus;
  violated: boolean;
}

interface StatusDotProps {
  item: Omit<SalaryExchange, 'effectiveDate'>;
  label: React.ReactNode;
}

export const StatusDot: React.FC<StatusDotProps> = ({
  item: { status, violated },
  label,
}) => {
  if (violated && status !== 'INACTIVE') {
    return <Dot colorValue="error" size={0.75} label={label} />;
  }
  if (status === 'ACTIVE') {
    return <Dot colorValue="green" size={0.75} label={label} />;
  }
  if (status === 'APPROVED') {
    return <Dot colorValue="orange" size={0.75} label={label} />;
  }
  return <Dot colorValue="outline" size={0.75} label={label} />;
};
