import styled from 'styled-components';

export const InlineSelect = styled.select`
  background-color: transparent;
  border: none;
  width: 100%;
  appearance: none;
  border-radius: 0;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  color: ${p => p.theme.onSurface};
  outline: none;
  cursor: pointer;
`;

interface InlineSelectWrapper {
  nbrOfCharacters: number;
}

export const InlineSelectWrapper = styled.div<InlineSelectWrapper>`
  width: calc(4.125rem + ${p => p.nbrOfCharacters} * 0.5rem);
  margin-right: 0.5rem;
  .mdc-menu {
    margin: 1.25rem 0;
  }
  & .mdc-select__anchor {
    height: auto;
    * {
      border: none;
    }
  }
  & .mdc-list-item__text {
    font-size: 0.875rem;
  }
  & .mdc-select__selected-text {
    font-size: 0.875rem;
  }
  & .mdc-floating-label {
    display: none;
  }
`;
