import { ApolloError, useMutation } from '@apollo/client';
import {
  cancelMeetingMutation,
  cancelMeetingMutationVariables,
} from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { CANCEL_MEETING_MUTATION } from '../graphql/mutations';
import { EMPLOYEE_MEETINGS_QUERY } from '../graphql/queries';

interface CancelMeeting {
  cancelMeeting: (meetingId: string) => void;
  loading: boolean;
  error?: ApolloError;
}

export const useCancelMeeting = (): CancelMeeting => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [cancelMeeting, { error, loading }] = useMutation<
    cancelMeetingMutation,
    cancelMeetingMutationVariables
  >(CANCEL_MEETING_MUTATION, { refetchQueries: [EMPLOYEE_MEETINGS_QUERY] });

  const callback = async (id: string) => {
    // eslint-disable-next-line no-alert
    if (confirm(formatMessage(advisorMessages.cancelMeetingConfirmation))) {
      await cancelMeeting({
        variables: {
          input: { id },
        },
      });
      send({
        message: formatMessage(advisorMessages.meetingCancelled),
        type: 'success',
      });
    }
  };

  return {
    cancelMeeting: callback,
    loading,
    error,
  };
};
