import gql from 'graphql-tag';

const importRecordString = gql`
  fragment importRecordString on ImportRecordString {
    value
    generatedChange
    isChanged
  }
`;

const importRecordBoolean = gql`
  fragment importRecordBoolean on ImportRecordBoolean {
    value
    generatedChange
    isChanged
  }
`;

const importRecordInt = gql`
  fragment importRecordInt on ImportRecordInt {
    value
    generatedChange
    isChanged
  }
`;

const importRecordMoney = gql`
  fragment importRecordMoney on ImportRecordMoney {
    value
    generatedChange
    isChanged
  }
`;

const importRecordDecimal = gql`
  fragment importRecordDecimal on ImportRecordDecimal {
    value
    generatedChange
    isChanged
  }
`;

const importRecordDate = gql`
  fragment importRecordDate on ImportRecordDate {
    value
    generatedChange
    isChanged
  }
`;

const importRecordCollectiveAgreement = gql`
  fragment importRecordCollectiveAgreement on ImportRecordCollectiveAgreement {
    value
    generatedChange
    isChanged
  }
`;

const importRecordCustomItem = gql`
  fragment importRecordCustomItem on ImportRecordCustomItem {
    type {
      id
      type
    }
    from
    item {
      ...importRecordDecimal
    }
  }
  ${importRecordDecimal}
`;

const importRecordFieldsDetails = gql`
  fragment importRecordFieldsDetails on ImportRecordFields {
    email {
      ...importRecordString
    }
    phone {
      ...importRecordString
    }
    isOwner {
      ...importRecordBoolean
    }
    collectiveAgreement {
      ...importRecordCollectiveAgreement
    }
    vacationDaysSaved {
      ...importRecordInt
    }
    vacationDaysPaid {
      ...importRecordInt
    }
    vacationDaysUnpaid {
      ...importRecordInt
    }
    vacationDaysPerYear {
      ...importRecordInt
    }
    vacationDaysAdvance {
      ...importRecordInt
    }
    companyCarMonthlyValue {
      ...importRecordMoney
    }
    employerContributionShare {
      ...importRecordDecimal
    }
    fitnessContributionBalance {
      ...importRecordMoney
    }
    incomeTaxTable {
      ...importRecordInt
    }
    costCenter {
      ...importRecordString
    }
    customTypeItems {
      ...importRecordCustomItem
    }
    benefitPackage {
      effectiveDate
      benefitPackageId {
        ...importRecordString
      }
    }
    salary {
      effectiveDate
      monthlySalary {
        ...importRecordMoney
      }
    }
    activity {
      effectiveDate {
        ...importRecordDate
      }
      effectiveUntil {
        ...importRecordDate
      }
      type
      rate {
        ...importRecordDecimal
      }
    }
    employment {
      effectiveDate {
        ...importRecordDate
      }
      effectiveUntil {
        ...importRecordDate
      }
      rate {
        ...importRecordDecimal
      }
      type {
        ...importRecordString
      }
      hoursWorked {
        ...importRecordDecimal
      }
    }
    organizationalUnits {
      value
      generatedChange
      isChanged
    }
    accountingItems {
      dimension
      identifier {
        ...importRecordString
      }
    }
  }
  ${importRecordString}
  ${importRecordBoolean}
  ${importRecordInt}
  ${importRecordMoney}
  ${importRecordDecimal}
  ${importRecordDate}
  ${importRecordCollectiveAgreement}
  ${importRecordCustomItem}
`;

export const importRecordDetails = gql`
  fragment importRecordDetails on ImportRecord {
    index
    status
    causes
    id {
      personalIdentityNumber
      employeeNumber {
        ...importRecordString
      }
      userAccountId
    }
    fields {
      ...importRecordFieldsDetails
    }
  }
  ${importRecordFieldsDetails}
  ${importRecordString}
`;
