import { A, Td, Tr } from '@frontend/ui';
import { sharedFilesQuery_sharedFiles_SharedFileConnection_edges_SharedFileEdge_node_SharedFile as SharedFile } from 'app/apollo/graphql/types';
import { NoValue } from 'components/NoValue';
import React from 'react';

import { RowOptions } from '../RowOptions';

interface Props {
  deleteSharedFile: (sharedFile: SharedFile) => void;
  sharedFile: SharedFile;
}

export const SharedFilesTableRow: React.FC<Props> = ({
  sharedFile,
  deleteSharedFile,
}) => (
  <Tr
    inlineMenu={
      <RowOptions deleteSharedFile={() => deleteSharedFile(sharedFile)} />
    }
  >
    <Td>
      <A href={sharedFile.file.url} download target="_blank">
        {sharedFile.file.filename}
      </A>
    </Td>
    <Td align="right" type="number">
      {sharedFile.createdAt}
    </Td>
    <Td>{sharedFile.author.name ?? <NoValue />}</Td>
  </Tr>
);
