import gql from 'graphql-tag';

export const UPCOMING_SALARY_EXCHANGES_CARD_QUERY = gql`
  query upcomingSalaryExchangesCardQuery(
    $companyId: ID!
    $statuses: [FlexSalaryExchangeRequestStatus!]
  ) {
    salaryExchangeRequests(companyId: $companyId, statuses: $statuses) {
      totalCount
    }
  }
`;
