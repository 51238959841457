import { SelectField } from '@frontend/formik';
import { Subsection, SubsectionHeader } from '@frontend/ui';
import { membershipDetailsQuery_membership_Membership as Membership } from 'app/apollo/graphql/types';
import { workInformationMessages } from 'app/messages/employees';
import { collectiveAgreementOptions } from 'app/utils/constants';
import { FormattedMessage } from 'components/formats';
import React from 'react';

export interface EmploymentDataFormValues {
  collectiveAgreement: string | null;
}

export const employmentDataInitialValues = (
  membership: Membership,
): EmploymentDataFormValues => ({
  collectiveAgreement: membership.collectiveAgreement,
});

export const EmploymentDataFields: React.FC = () => (
  <Subsection>
    <SubsectionHeader>
      <FormattedMessage {...workInformationMessages.employmentDetails} />
    </SubsectionHeader>
    <SelectField
      dense
      fixed
      name="collectiveAgreement"
      label={
        <FormattedMessage
          {...workInformationMessages.collectiveAgreementLabel}
        />
      }
      options={collectiveAgreementOptions}
    />
  </Subsection>
);
