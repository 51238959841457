import { ApolloError, useMutation } from '@apollo/client';
import {
  EuroAccidentSicknessAccident,
  EuroAccidentSicknessAccidentInput,
} from '@frontend/benefit-types';
import {
  companyEditBenefitQuery_flexBenefit_FlexBenefit as Benefit,
  FlexUpdateBenefitInput,
  updateBenefitMutation,
  updateBenefitMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useIntl } from 'components/formats';
import format from 'date-fns/format';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { benefitFormMessages } from '../../../form/messages';
import { FormValues } from '../../../form/sickness-and-accident';
import { UPDATE_BENEFIT_MUTATION } from '../../graphql/mutations';

interface Submit {
  submit: (
    values: FormValues,
    benefit: EuroAccidentSicknessAccident<Benefit>,
  ) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const { params } = useRouteMatch<MatchParams>();

  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [updateBenefit, { error: submissionError }] = useMutation<
    updateBenefitMutation,
    updateBenefitMutationVariables
  >(UPDATE_BENEFIT_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${params.companyId}`,
        fieldName: 'flexBenefits',
      });
      cache.evict({
        id: `Company:${params.companyId}`,
        fieldName: 'benefitPackages',
      });
      cache.gc();
    },
  });

  const submit = async (
    values: FormValues,
    benefit: EuroAccidentSicknessAccident<Benefit>,
  ) => {
    // This value is guaranteed through the form validation
    if (!values.level) {
      return;
    }
    try {
      const input: EuroAccidentSicknessAccidentInput<FlexUpdateBenefitInput> = {
        id: params.benefitId,
        configEffectiveDate: format(
          new Date(values.effectiveFrom),
          'yyyy-MM-dd',
        ),
        configuration: {
          isActiveOnParentalLeave: values.isActiveOnParentalLeave,
          level: values.level,
        },
        entitlementRules: {
          accountingCostIds: benefit.entitlementRules?.accountingCostIds ?? [],
          benefitPackageIds: values.benefitPackageIds,
          ouIds: benefit.entitlementRules?.ouIds ?? [],
        },
      };

      await updateBenefit({
        variables: {
          input,
        },
      });

      send({
        message: formatMessage(benefitFormMessages.benefitSaved),
        type: 'success',
      });

      push(`/companies/${params.companyId}/benefit-packages/benefits`);
    } catch (error) {
      // do nothing
    }
  };

  return { submit, submissionError };
};
