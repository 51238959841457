import { PremiumMatrix } from '@frontend/benefit-types';
import { Permissions } from 'contexts/current-permissions';

import { hasPensionPremiumPermission } from './has-pension-premium-permission';

interface PensionBenefit {
  configuration?: {
    data?: {
      premiumMatrix?: PremiumMatrix | null;
    } | null;
  } | null;
}

interface Args {
  benefit?: PensionBenefit;
  permissions?: Permissions;
}

/**
 * @returns true iff the user has permission to update pension premiums,
 * and the pension benefit and its configuration data exist with a null value for the premium matrix;
 * otherwise, returns false.
 */
export const showUpdatePremiumButton = ({
  benefit,
  permissions,
}: Args): boolean => {
  if (!hasPensionPremiumPermission(permissions)) {
    return false;
  }

  if (benefit?.configuration?.data) {
    return benefit?.configuration.data.premiumMatrix === null;
  }

  return false;
};
