import { salaryExchangeReportsQuery } from 'app/apollo/graphql/types';

interface FetchMoreResult {
  fetchMoreResult?: salaryExchangeReportsQuery;
}

export const updateSalaryExchangeReportQuery = (
  previousResult: salaryExchangeReportsQuery,
  { fetchMoreResult }: FetchMoreResult,
) => {
  if (
    !previousResult.flexSalaryExchangeReports ||
    !fetchMoreResult?.flexSalaryExchangeReports
  ) {
    return previousResult;
  }

  return fetchMoreResult;
};
