import { defineMessages } from 'react-intl';

export const errorBoundaryMessages = defineMessages({
  title: {
    id: 'errorMessages.title',
    defaultMessage: 'Internt tekniskt fel',
  },
  description: {
    id: 'errorMessages.description',
    defaultMessage:
      'Ett tekniskt fel har uppstått som gjort att sidan inte kan visas. Felet har rapporteras.',
  },
  buttonLabel: {
    id: 'errorMessages.buttonLabel',
    defaultMessage: 'Gå till startsida',
  },
  loadingButtonLabel: {
    id: 'errorMessages.loadingButtonLabel',
    defaultMessage: 'Skapar felrapport',
  },
  successMessage: {
    id: 'errorMessages.successMessage',
    defaultMessage: 'Felrapport skickad',
  },
  errorMessage: {
    id: 'errorMessages.errorMessage',
    defaultMessage: 'Kunde inte skapa felrapport',
  },
});
