import { SelectField } from '@frontend/formik';
import { formOrgUnitsOptions } from '@frontend/utils';
import {
  organizationalUnitsQuery,
  organizationalUnitsQueryVariables,
} from 'app/apollo/graphql/types';
import { formMessages } from 'app/messages/form';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { ORGANIZATIONAL_UNITS_QUERY } from 'features/companies/company/organizational-units/graphql/queries';
import React from 'react';
import { useRouteMatch } from 'react-router';

interface Props {
  name: string;
  dense?: boolean;
  label?: React.ReactNode;
  orgUnitId?: string | null;
}

export const OrgUnitSelectField: React.FC<Props> = ({
  name,
  label,
  orgUnitId,
  dense,
}) => {
  const {
    params: { companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data, loading } = useQuery<
    organizationalUnitsQuery,
    organizationalUnitsQueryVariables
  >(ORGANIZATIONAL_UNITS_QUERY, {
    errorPolicy: 'all',
    variables: { companyId },
  });

  if (loading) {
    return null;
  }

  const orgUnits =
    data?.company?.organizationalUnits?.edges
      ?.map(e => e.node)
      .filter(n => !orgUnitId || !n.path.includes(orgUnitId)) ?? [];
  const selectOptions = formOrgUnitsOptions(orgUnits);

  return (
    <SelectField
      fixed
      type="select"
      dense={dense}
      name={name}
      label={label || <FormattedMessage {...formMessages.unitOf} />}
      options={[
        { value: 'NO_ORG_UNIT', label: 'Ingen avdelning' },
        ...selectOptions,
      ]}
    />
  );
};
