import gql from 'graphql-tag';

export const DUPLICATE_PROPOSAL_QUERY = gql`
  query duplicateProposalQuery($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      name
      companySize
      adviceWaived
    }
  }
`;
