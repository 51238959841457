import gql from 'graphql-tag';

export const SHARED_FILES_QUERY = gql`
  query sharedFilesQuery(
    $companyId: ID!
    $sortOrder: SortOrder
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    sharedFiles(
      companyId: $companyId
      sortOrder: $sortOrder
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        node {
          id
          file {
            id
            url
            filename
          }
          createdAt
          author {
            adminUserExternalId
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
