import { InlineChevronTd, TableRowProps, Td, Tr } from '@frontend/ui';
import type { TdProps } from '@frontend/ui/components/Table/components';
import React, { createContext, useContext, useState } from 'react';

interface CollapseContext {
  collapsed: boolean;
}

const CollapseContext = createContext<CollapseContext>({
  collapsed: false,
});

interface Props extends TableRowProps {
  subrows: React.ReactNode;
}

export const CollapsibleTr: React.FC<Props> = ({
  subrows,
  children,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState({ collapsed: true });

  return (
    <>
      <Tr {...props}>
        <InlineChevronTd
          expanded={!collapsed}
          toggle={() =>
            setCollapsed(({ collapsed: _collapsed }) => ({
              collapsed: !_collapsed,
            }))
          }
        />
        {children}
      </Tr>
      <CollapseContext.Provider value={collapsed}>
        {subrows}
      </CollapseContext.Provider>
    </>
  );
};

export const SubTr: React.FC<TableRowProps> = ({ children, ...props }) => {
  const { collapsed } = useContext(CollapseContext);
  return (
    <Tr {...props} subrow collapsible hide={collapsed}>
      <Td chevron collapsible hide={collapsed} />
      {children}
    </Tr>
  );
};

export const SubTd: React.FC<TdProps> = props => {
  const { collapsed } = useContext(CollapseContext);
  return <Td {...props} collapsible hide={collapsed} />;
};
