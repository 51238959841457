import { useMutation } from '@apollo/client';
import {
  renameBenefitPackageMutation,
  renameBenefitPackageMutationVariables,
  UpdateBenefitPackageInput,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { FormValues } from '..';
import { RENAME_BENEFIT_PACKAGE_MUTATION } from '../graphql/mutations';

interface Props {
  benefitPackageId: string;
  onRequestClose: () => void;
}

export const useSubmit = ({ benefitPackageId, onRequestClose }: Props) => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [updateBenefitPackage, { error: submissionError }] = useMutation<
    renameBenefitPackageMutation,
    renameBenefitPackageMutationVariables
  >(RENAME_BENEFIT_PACKAGE_MUTATION);

  const submit = async (formValues: FormValues) => {
    try {
      const { name } = formValues;
      const input: UpdateBenefitPackageInput = {
        benefitPackageId,
        name,
      };

      const res = await updateBenefitPackage({
        variables: { input },
      });

      if (!res.data?.updateBenefitPackage?.benefitPackage) {
        throw new Error();
      }

      send({
        message: formatMessage(commonBenefitMessages.benefitPackageRenamed, {
          name,
        }),
        type: 'success',
      });

      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return { submit, submissionError };
};
