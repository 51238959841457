import gql from 'graphql-tag';

export const RISK_INSURANCES_ADMIN_QUERY = gql`
  query riskInsurancesAdminQuery(
    $userAccountId: ID!
    $companyId: ID!
    $includeDeleted: Boolean!
  ) {
    membership(userAccountId: $userAccountId, companyId: $companyId) {
      id
      insurances(includeDeleted: $includeDeleted) {
        edges {
          node {
            id
            components {
              id
              insuranceNumber
              insurance {
                id
                asOf
                deletedAt
                brokeredByAdvinans
                effectiveDate
                institute {
                  id
                  name
                }
                nextPremiumPayment {
                  value
                  currency
                }
                premiumPaymentFrequency
              }
              status
              payout {
                amount {
                  currency
                  value
                }
                payoutEvent
              }
              ... on InsuranceComponentDeath {
                typeCode
              }
              ... on InsuranceComponentSickness {
                sicknessLevel
                typeCode
              }
              ... on InsuranceComponentAccident {
                coverage
              }
              ... on InsuranceComponentHealthCare {
                copayment {
                  value
                  currency
                }
              }
            }
          }
        }
      }
    }
  }
`;
