import gql from 'graphql-tag';

export const RENAME_BENEFIT_PACKAGE_MUTATION = gql`
  mutation renameBenefitPackageMutation($input: UpdateBenefitPackageInput!) {
    updateBenefitPackage(input: $input) {
      benefitPackage {
        id
        name
      }
    }
  }
`;
