import { useNavigationContext } from '../containers/NavigationContext';

export interface NavigationStack {
  peek: () => string | undefined;
  push: (path: string) => void;
}

export const useNavigationStackUtils = (): NavigationStack => {
  const {
    browseHistory: { push, stack },
  } = useNavigationContext();

  const peek = (): string | undefined => stack[0];

  return { peek, push };
};
