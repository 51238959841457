import gql from 'graphql-tag';

export const CREATE_PROPOSAL_SALARY_EXCHANGE_BENEFIT_MUTATION = gql`
  mutation createProposalSalaryExchangeBenefitMutation(
    $input: CreateProposalSalaryExchangeBenefitInput!
  ) {
    createProposalSalaryExchangeBenefit(input: $input) {
      id
      benefits {
        id
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
