import { Table, TableSortButton, Th, Tr } from '@frontend/ui';
import {
  noteAuthorsQuery_noteAuthors_Author as NoteAuthor,
  notesQuery_notes_NoteConnection_edges_NoteEdge_node_Note as Note,
  pageInfoDetails,
} from 'app/apollo/graphql/types';
import { noteMessages } from 'app/messages/notes';
import { TableSort } from 'app/utils/use-table-sort';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyTable } from 'components/EmptyTable';
import { FormattedMessage } from 'components/formats';
import { TableNavigation } from 'components/TableNavigation';
import React from 'react';

import { NotesChips } from '../components/NotesChips';
import { NotesTableRow } from '../components/NotesTableRow';

interface Props {
  deleteNote: (noteId: string) => void;
  noteAuthors: Array<NoteAuthor>;
  notes: Array<Note>;
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
  sort: TableSort<string>;
}

const NotesTableColgroup = () => (
  <colgroup>
    <col style={{ width: '15%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: 'auto' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '15%' }} />
  </colgroup>
);

const NotesTableThead = ({ sort }: { sort: TableSort<string> }) => (
  <thead>
    <Tr withInlineMenuTh>
      <Th>
        <FormattedMessage {...noteMessages.subject} />
      </Th>
      <Th>
        <FormattedMessage {...noteMessages.category} />
      </Th>
      <Th>
        <FormattedMessage {...noteMessages.note} />
      </Th>
      <Th>
        <FormattedMessage {...noteMessages.createdBy} />
      </Th>
      <Th align="right">
        <TableSortButton
          onClick={sort.handleSort}
          order={sort.orderBy === 'date' ? sort.order : undefined}
          value="date"
          align="left"
        >
          <FormattedMessage {...noteMessages.date} />
        </TableSortButton>
      </Th>
    </Tr>
  </thead>
);

export const NotesTable: React.FC<Props> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  notes,
  noteAuthors,
  sort,
  deleteNote,
}) => (
  <>
    <ChipsWrapper belowSearch>
      <NotesChips noteAuthors={noteAuthors} />
    </ChipsWrapper>
    <Table
      navigation={
        <TableNavigation
          pageInfo={pageInfo}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
        />
      }
      fixed
    >
      {notes.length === 0 ? (
        <EmptyTable noMatchMessage={noteMessages.noNotes} />
      ) : (
        <>
          <NotesTableColgroup />
          <NotesTableThead sort={sort} />
          <tbody>
            {notes.map(note => (
              <NotesTableRow
                key={note.id}
                note={note}
                deleteNote={deleteNote}
              />
            ))}
          </tbody>
        </>
      )}
    </Table>
  </>
);
