import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import isAfter from 'date-fns/isAfter';
import React from 'react';

import { benefitFormMessages } from '../../messages';

interface Props {
  latestChangesEffectiveDate?: string | null;
}

export const UpcomingChangesNotification: React.FC<Props> = ({
  latestChangesEffectiveDate,
}) => {
  const hasUpcomingChanges =
    latestChangesEffectiveDate &&
    isAfter(new Date(latestChangesEffectiveDate), new Date());

  if (!hasUpcomingChanges) {
    return null;
  }

  return (
    <NotificationCard
      type="info"
      title={<FormattedMessage {...benefitFormMessages.upcomingChanges} />}
    >
      <FormattedMessage
        {...benefitFormMessages.upcomingChangesDescription}
        values={{
          configEffectiveDate: new Date(latestChangesEffectiveDate),
        }}
      />
    </NotificationCard>
  );
};
