import gql from 'graphql-tag';

import { employmentActivityDetails } from '../../graphql/fragments';

export const UPDATE_EMPLOYMENT_ACTIVITY_MUTATION = gql`
  mutation updateEmploymentActivityMutation(
    $input: UpdateEmploymentActivityInput!
  ) {
    updateEmploymentActivity(input: $input) {
      employmentActivity {
        ...employmentActivityDetails
      }
    }
  }
  ${employmentActivityDetails}
`;
