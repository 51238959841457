import gql from 'graphql-tag';

export const JOB_OFFER_STATUS_NOTIFICATION_QUERY = gql`
  query jobOfferStatusNotificationQuery($jobOfferId: ID!) {
    jobOffer(id: $jobOfferId) {
      id
      status
    }
  }
`;
