import {
  CheckboxField,
  DatePickerField,
  SelectField,
  TextField,
  useFormikContext,
} from '@frontend/formik';
import { Grid, Section, SectionHeader } from '@frontend/ui';
import { Sex } from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import {
  employeeFormMessages,
  workInformationMessages,
} from 'app/messages/employees';
import { loginMessages } from 'app/messages/login';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GridCell50 } from 'components/GridCell';
import React, { useEffect, useState } from 'react';
import {
  isEmail,
  isValidDate,
  isValidPhoneNumber,
  validateNaturalPersonIdentifier,
} from 'validations';
import * as Yup from 'yup';

import { getBirthdateFromNid } from '../../utils/get-birthdate-from-nid';
import { getEmployeeWarningMessage } from '../../utils/get-employee-warning-message';

const requiredStringWhenNidOptOut = (intl: IntlShape) =>
  Yup.string().when('nidOptOut', {
    is: true,
    then: schema =>
      schema.required(intl.formatMessage(validationMessages.mandatoryField)),
  });

const requiredWhenAnyFieldSet = (fields: string[], intl: IntlShape) =>
  Yup.string().when(fields, {
    is: (...values) => values.some(value => value),
    then: schema =>
      schema.required(intl.formatMessage(validationMessages.isValidAddress)),
  });

export const personalDataValidationSchema = (intl: IntlShape) =>
  Yup.object().shape(
    {
      givenName: Yup.string().required(
        intl.formatMessage(validationMessages.mandatoryField),
      ),
      lastName: Yup.string().required(
        intl.formatMessage(validationMessages.mandatoryField),
      ),
      nid: Yup.string().when('nidOptOut', {
        is: false,
        then: schema =>
          schema
            .required(intl.formatMessage(validationMessages.mandatoryField))
            .test(
              'valid first nid',
              intl.formatMessage(validationMessages.isValidFirstNid),
              value => validateNaturalPersonIdentifier(value),
            ),
      }),
      birthdate: requiredStringWhenNidOptOut(intl).test(
        'valid date',
        intl.formatMessage(validationMessages.invalidDate),
        value => isValidDate(value),
      ),
      sex: requiredStringWhenNidOptOut(intl),
      line1: requiredStringWhenNidOptOut(intl).concat(
        requiredWhenAnyFieldSet(['postalCode', 'city'], intl),
      ),
      postalCode: requiredStringWhenNidOptOut(intl).concat(
        requiredWhenAnyFieldSet(['line1', 'city'], intl),
      ),
      city: requiredStringWhenNidOptOut(intl).concat(
        requiredWhenAnyFieldSet(['line1', 'postalCode'], intl),
      ),
      email: Yup.string()
        .required(intl.formatMessage(validationMessages.mandatoryField))
        .test(
          'valid email',
          intl.formatMessage(validationMessages.isValidEmail),
          value => isEmail(value),
        ),
      phone: Yup.string().test(
        'valid or no phone number',
        intl.formatMessage(validationMessages.isValidPhone),
        value => !value || isValidPhoneNumber(value),
      ),
    },
    [
      ['line1', 'city'],
      ['postalCode', 'city'],
      ['postalCode', 'line1'],
    ],
  );

export interface PersonalDataFormValues {
  birthdate: string;
  city: string;
  email: string;
  givenName: string;
  lastName: string;
  line1: string;
  nid: string;
  nidOptOut: boolean;
  postalCode: string;
  sex: Sex | '';
  employeeId?: string;
  phone?: string;
}

export const personalDataInitialValues: PersonalDataFormValues = {
  givenName: '',
  line1: '',
  lastName: '',
  nid: '',
  birthdate: '',
  sex: '',
  postalCode: '',
  city: '',
  nidOptOut: false,
  email: '',
};

interface SelectOption {
  label: string;
  value: Sex;
}

const sexOptions = (intl: IntlShape): SelectOption[] => [
  {
    value: Sex.F,
    label: intl.formatMessage(employeeFormMessages.female),
  },
  {
    value: Sex.M,
    label: intl.formatMessage(employeeFormMessages.male),
  },
];

export const PersonalDataFields: React.FC = () => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext<PersonalDataFormValues>();
  const [birthdateWarningMsg, setBirthdateWarning] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (values.nidOptOut) {
      setFieldValue('nid', '');
    }
  }, [values.nidOptOut]);

  useEffect(() => {
    const birthdate = getBirthdateFromNid(values.nid);
    if (birthdate) {
      setFieldValue('birthdate', birthdate);
    }
  }, [values.nid]);

  useEffect(() => {
    if (values.birthdate) {
      const warningMsg = getEmployeeWarningMessage(values.birthdate, intl);
      setBirthdateWarning(warningMsg);
    }
  }, [values.birthdate]);

  const hasAddressSet = !!values.city || !!values.line1 || !!values.postalCode;

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...workInformationMessages.personalDetails} />
      </SectionHeader>
      <Grid>
        <GridCell50>
          <TextField
            dense
            name="givenName"
            label={<FormattedMessage {...commonMessages.givenName} />}
            required
          />
        </GridCell50>
        <GridCell50>
          <TextField
            dense
            name="lastName"
            label={<FormattedMessage {...commonMessages.lastName} />}
            required
          />
        </GridCell50>
      </Grid>
      <CheckboxField
        name="nidOptOut"
        label={<FormattedMessage {...employeeFormMessages.nidOptOut} />}
      />
      <TextField
        dense
        name="nid"
        label={<FormattedMessage {...commonMessages.naturalPersonIdentifier} />}
        helperText={
          <FormattedMessage
            {...loginMessages.personalIdentityNumberHelperText}
          />
        }
        required={!values.nidOptOut}
        disabled={values.nidOptOut}
      />
      <DatePickerField
        dense
        name="birthdate"
        label={<FormattedMessage {...employeeFormMessages.birthdate} />}
        required={values.nidOptOut}
        warning={!!birthdateWarningMsg}
        helperText={birthdateWarningMsg}
      />
      <SelectField
        dense
        name="sex"
        label={<FormattedMessage {...employeeFormMessages.sex} />}
        options={sexOptions(intl)}
        required={values.nidOptOut}
      />
      <TextField
        dense
        name="line1"
        label={<FormattedMessage {...employeeFormMessages.address} />}
        required={values.nidOptOut || hasAddressSet}
      />
      <Grid>
        <GridCell50>
          <TextField
            dense
            name="postalCode"
            label={<FormattedMessage {...commonMessages.postalCode} />}
            required={values.nidOptOut || hasAddressSet}
          />
        </GridCell50>
        <GridCell50>
          <TextField
            dense
            name="city"
            label={<FormattedMessage {...employeeFormMessages.city} />}
            required={values.nidOptOut || hasAddressSet}
          />
        </GridCell50>
      </Grid>
      <TextField
        dense
        name="employeeId"
        label={
          <FormattedMessage {...workInformationMessages.employeeNumberLabel} />
        }
      />
      <TextField
        dense
        name="email"
        type="email"
        label={<FormattedMessage {...commonMessages.email} />}
        required
      />
      <TextField
        dense
        name="phone"
        type="tel"
        label={<FormattedMessage {...commonMessages.phone} />}
      />
    </Section>
  );
};
