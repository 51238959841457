import gql from 'graphql-tag';

export const IMPORT_SUMMARY_CARDS_QUERY = gql`
  query importSummaryCardsQuery($id: ID!) {
    import(id: $id) {
      id
      status
      changesSummary {
        newMemberships
        salaryChanges
        newActivities
        terminatedEmployments
        unchangedRows
      }
    }
  }
`;
