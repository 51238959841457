import { ApolloError } from '@apollo/client';
import { Form, Formik } from '@frontend/formik';
import { Button, Subsection } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { companySalaryExchangeMessages } from 'features/companies/company/salary-exchanges/messages';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router';

import { isError, useSubmit } from '../../utils/use-submit';

interface RejectValues {
  reason?: string | null;
}

type Requests = Record<string, RejectValues>;

export interface FormValues {
  requests: Requests;
}

interface Props extends RouteComponentProps {
  approved: boolean;
  children: React.ReactNode;
  omitForm: boolean;
  requestIds: string[];
}

export const RejectForm: React.FC<Props> = ({
  requestIds,
  omitForm,
  approved,
  children,
  history: { push },
  location,
}) => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { submit, results } = useSubmit({
    approved,
    onCompleted: () => push(`/companies/${companyId}/salary-exchanges`),
    onError: _requestIds =>
      push({
        ...location,
        search: qs.stringify({ requestIds: _requestIds }),
      }),
  });

  const submissionErrors = results.reduce<ApolloError[]>(
    (acc, res) => (isError(res) ? [...acc, res.error] : acc),
    [],
  );

  const initialValues = requestIds.reduce<Requests>(
    (acc, requestId) => ({
      ...acc,
      [requestId]: {},
    }),
    {},
  );

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{ requests: initialValues }}
      onSubmit={submit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Subsection>{children}</Subsection>
          {!!submissionErrors.length && (
            <GraphQlErrors
              title={
                <FormattedMessage
                  {...(approved
                    ? companySalaryExchangeMessages.terminateSalaryExchangeSubmitError
                    : companySalaryExchangeMessages.rejectSubmitErrorMessagesTitle)}
                />
              }
              errors={submissionErrors}
            />
          )}
          {!omitForm && (
            <Button
              disabled={!!submissionErrors.length}
              filled
              type="submit"
              loading={isSubmitting}
            >
              <FormattedMessage
                {...(approved
                  ? commonMessages.stop
                  : companySalaryExchangeMessages.reject)}
              />
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};
