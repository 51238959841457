import { Button, CheckboxField, Table, Th, Tr } from '@frontend/ui';
import {
  insurancesQuery_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance,
  pageInfoDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { insuranceMessages } from 'app/messages/insurance';
import { EmptyTable } from 'components/EmptyTable';
import { FormattedMessage, useIntl } from 'components/formats';
import { SearchField } from 'components/SearchField';
import { TableNavigation } from 'components/TableNavigation';
import React from 'react';

import { useCheckboxRows } from '../utils/use-checkbox-rows';
import { InsuranceTableRow } from './components/InsuranceTableRow';

export type InsuranceKey = {
  insuranceNumber: string;
  instituteId: string;
};

export type Insurance =
  insurancesQuery_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance;

interface Props {
  deleteInsurances: (insuranceKeys: Array<InsuranceKey>) => void;
  insurances: Array<Insurance>;
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
}

const InsuranceTableColgroup = () => (
  <colgroup>
    <col style={{ width: '5%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: 'auto' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '10%' }} />
    <col style={{ width: '15%' }} />
  </colgroup>
);

const InsuranceTableThead = ({
  allSelected,
  indeterminate,
  toggleSelectAll,
}: {
  allSelected: boolean;
  toggleSelectAll: (checked: boolean) => void;
  indeterminate: boolean;
}) => {
  const { formatMessage } = useIntl();

  return (
    <thead>
      <Tr withInlineMenuTh>
        <Th type="input">
          <CheckboxField
            checked={allSelected}
            onChange={toggleSelectAll}
            indeterminate={indeterminate}
            aria-label={formatMessage(commonMessages.selectAllRows)}
          />
        </Th>
        <Th align="left">
          <FormattedMessage {...insuranceMessages.insuranceNumber} />
        </Th>
        <Th align="left">
          <FormattedMessage {...insuranceMessages.institute} />
        </Th>
        <Th align="left">
          <FormattedMessage {...insuranceMessages.insured} />
        </Th>
        <Th align="left">
          <FormattedMessage {...commonMessages.identityNumber} />
        </Th>
        <Th align="left">
          <FormattedMessage {...insuranceMessages.value} />
        </Th>
        <Th align="left">
          <FormattedMessage {...commonMessages.status} />
        </Th>
      </Tr>
    </thead>
  );
};

export const InsuranceTable: React.FC<Props> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  insurances,
  deleteInsurances,
}) => {
  const insuranceKeys = insurances.map(insurance => ({
    insuranceNumber: insurance.insuranceNumber,
    instituteId: insurance.institute.id,
  }));

  const {
    checkedRowKeys,
    allSelected,
    indeterminate,
    toggleSelectAll,
    toggleSelectRow,
  } = useCheckboxRows(insuranceKeys);

  return (
    <>
      <SearchField
        label={
          <FormattedMessage {...insuranceMessages.searchInsuranceNumber} />
        }
      />
      <Table
        navigation={
          <TableNavigation
            pageInfo={pageInfo}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
          />
        }
        fixed
        feedback={{
          actions: (
            <Button text onClick={() => deleteInsurances(checkedRowKeys)}>
              <FormattedMessage {...insuranceMessages.markAsDeleted} />
            </Button>
          ),
          active: checkedRowKeys.length > 0,
          children: (
            <FormattedMessage
              {...commonMessages.nRowsSelected}
              values={{ count: checkedRowKeys.length }}
            />
          ),
        }}
      >
        {insurances.length === 0 ? (
          <EmptyTable noMatchMessage={insuranceMessages.noInsurancesFound} />
        ) : (
          <>
            <InsuranceTableColgroup />
            <InsuranceTableThead
              allSelected={allSelected}
              indeterminate={indeterminate}
              toggleSelectAll={toggleSelectAll}
            />
            <tbody>
              {insurances.map(insurance => (
                <InsuranceTableRow
                  key={insurance.id}
                  insurance={insurance}
                  deleteInsurances={() =>
                    deleteInsurances([
                      {
                        insuranceNumber: insurance.insuranceNumber,
                        instituteId: insurance.institute.id,
                      },
                    ])
                  }
                  checked={checkedRowKeys.some(
                    key =>
                      key.insuranceNumber === insurance.insuranceNumber &&
                      key.instituteId === insurance.institute.id,
                  )}
                  onCheckboxChange={checked => {
                    toggleSelectRow(checked, {
                      insuranceNumber: insurance.insuranceNumber,
                      instituteId: insurance.institute.id,
                    });
                  }}
                />
              ))}
            </tbody>
          </>
        )}
      </Table>
    </>
  );
};
