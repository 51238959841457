import { Form, Formik } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import {
  formatNaturalPersonIdentifier,
  stripSearchParams,
} from '@frontend/utils';
import {
  editRepresentativeModalQuery,
  editRepresentativeModalQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import { smeRepresentativesMessages } from 'features/sme/messages/sme';
import qs from 'query-string';
import React from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';

import {
  FormValues as FieldsFormValues,
  RepresentativeFormFields,
  validationSchema,
} from '../form';
import { EDIT_REPRESENTATIVE_MODAL_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

export interface FormValues extends FieldsFormValues {
  proposalId: string;
  representativeId: string;
}

export const EditRepresentativeModal = () => {
  const intl = useIntl();

  const history = useHistory();
  const location = useLocation();
  const { params } = useRouteMatch<ProposalMatchParams>();

  const { 'edit-representative': representativeId } = qs.parse(location.search);

  const editRepresentative = !!representativeId;

  const onRequestClose = () => {
    stripSearchParams(history, location, ['edit-representative']);
  };

  const { submit, submissionError } = useSubmit({ onRequestClose });

  const { data, previousData, loading, error } = useQuery<
    editRepresentativeModalQuery,
    editRepresentativeModalQueryVariables
  >(EDIT_REPRESENTATIVE_MODAL_QUERY, {
    errorPolicy: 'all',
    variables: { companyId: params.companyId, representativeId },
  });

  // Render previous data when modal is fading out
  // so error state is not shown
  const _data = data ?? previousData;

  if (editRepresentative && loading) {
    return <TopLoading />;
  }

  if (!_data?.proposalContactPerson || !_data?.companyRepresentative) {
    return (
      <Modal isOpen={editRepresentative} onRequestClose={onRequestClose}>
        <ModalHeader>
          <FormattedMessage
            {...smeRepresentativesMessages.editRepresentative}
          />
        </ModalHeader>
        <ModalBody>
          <GraphQlError error={error} inModal />
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button text onClick={onRequestClose}>
              <FormattedMessage {...commonMessages.cancel} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </Modal>
    );
  }

  const { firstName, lastName, personalIdentityNumber } =
    _data.proposalContactPerson;

  const contactPerson = {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    personalIdentityNumber: personalIdentityNumber
      ? formatNaturalPersonIdentifier(personalIdentityNumber)
      : '',
  };

  const representative = _data.companyRepresentative;

  const initialValues: FormValues = {
    firstName: representative.firstName,
    identification: representative.identificationType,
    identificationReference: representative.identificationReference ?? '',
    lastName: representative.lastName,
    personalIdentityNumber: formatNaturalPersonIdentifier(
      representative.personalIdentityNumber,
    ),
    proposalId: params.proposalId,
    representativeId: representative.id,
    role: representative.role,
  };

  return (
    <Modal isOpen={editRepresentative} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage {...smeRepresentativesMessages.editRepresentative} />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              <RepresentativeFormFields contactPerson={contactPerson} />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  text
                  type="submit"
                >
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

interface Args {
  location: RouteComponentProps['location'];
  representativeId: string;
}

export const getEditRepresentativeLink = ({
  location,
  representativeId,
}: Args): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'edit-representative': representativeId }),
});
