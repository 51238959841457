import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

import { requestDetails } from '../../graphql/queries';

export const BONUS_SALARY_EXCHANGE_REQUESTS_QUERY = gql`
  query bonusSalaryExchangeRequestsQuery(
    $companyId: ID!
    $requestIds: [ID!]
    $statuses: [FlexSalaryExchangeRequestStatus!]
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    bonusSalaryExchangeRequests(
      companyId: $companyId
      requestIds: $requestIds
      statuses: $statuses
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          ...requestDetails
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${requestDetails}
  ${pageInfoDetails}
`;
