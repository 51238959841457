import gql from 'graphql-tag';

export const ADD_MEMBERSHIP_ACCOUNTING_ITEM_MUTATION = gql`
  mutation addMembershipAccountingItemMutation(
    $input: AddMembershipAccountingItemInput
  ) {
    addMembershipAccountingItem(input: $input) {
      accountingObject {
        id
      }
    }
  }
`;
