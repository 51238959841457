import type { ThemeColor } from '@frontend/ui/theme';
import {
  TotalCompensationCategory2 as TotalCompensationCategory,
  TotalCompensationId2 as TotalCompensationId,
  totalCompensationSummaryCardQuery_membership_Membership_totalCompensation_TotalCompensationItem2 as TotalCompensation,
} from 'app/apollo/graphql/types';
import BigNumber from 'bignumber.js';

import { TOTAL_COMP_LEGEND_COLOR } from './total-comp-legend-color';
import { TOTAL_COMP_ID_SORT_ORDER } from './total-comp-sort-order';

type Title = Record<TotalCompensationCategory, number>;

export type GraphItemId = TotalCompensationId | 'BENEFITS';

interface GraphDataItem {
  colorValue: ThemeColor;
  label: GraphItemId;
  percentValue: number;
  value: number;
}

export interface ChartData {
  graphData: GraphDataItem[];
  titles: Title;
  totalValue: number;
}

const getRoundedValue = (value: number, total: number) =>
  Math.round((value / total) * 100);

export const aggregateTotalCompChartData = (
  totalCompensation: readonly TotalCompensation[],
): ChartData => {
  const compensationItems = totalCompensation
    .filter(item => item.includedInCompensation)
    .sort(
      (a, b) =>
        TOTAL_COMP_ID_SORT_ORDER.indexOf(a.key) -
        TOTAL_COMP_ID_SORT_ORDER.indexOf(b.key),
    );

  const titles: Title = compensationItems.reduce((acc, item) => {
    const compensationAsNumber = new BigNumber(item.annualCompensation || 0)
      .dividedBy(12)
      .toNumber();

    if (!isNaN(compensationAsNumber)) {
      acc[item.category] = acc[item.category]
        ? acc[item.category] + compensationAsNumber
        : compensationAsNumber;
    }
    return acc;
  }, {} as Title);

  const itemsExceptBenefits = compensationItems.reduce((acc, item) => {
    if (item.category === TotalCompensationCategory.BENEFITS) {
      return acc;
    }

    return [
      ...acc,
      {
        label: item.key,
        value: item.annualCompensation
          ? new BigNumber(item.annualCompensation).dividedBy(12).toNumber()
          : 0,
        colorValue: TOTAL_COMP_LEGEND_COLOR[item.key] ?? 'primary',
      },
    ];
  }, []);

  const _graphData = [
    ...itemsExceptBenefits,
    ...(titles.BENEFITS
      ? [
          {
            label: 'BENEFITS',
            value: titles.BENEFITS,
            colorValue: 'redCopper',
          },
        ]
      : []),
  ];

  const totalValue = Object.values(titles).reduce((acc, val) => acc + val, 0);

  const graphData: GraphDataItem[] = _graphData.map(
    (current: GraphDataItem, i, arr) => {
      const percentValue =
        i === arr.length - 1
          ? 100 -
            arr
              .slice(0, -1)
              .reduce((a, b) => a + getRoundedValue(b.value, totalValue), 0)
          : getRoundedValue(current.value, totalValue);

      return {
        ...current,
        percentValue,
      };
    },
  );

  return {
    graphData,
    titles,
    totalValue,
  };
};
