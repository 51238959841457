import gql from 'graphql-tag';

export const IMPORT_COLUMNS_QUERY = gql`
  query importColumnsQuery($id: ID!) {
    import(id: $id) {
      id
      companyId
      name
      createdAt
      status
      columnMappings {
        source
        column
      }
      benefitsStartWithEmployment
      fallbackDateForBenefits
      createMissingOrganizationalUnits
    }
  }
`;
