import { Section } from '@frontend/ui';
import { toNumber } from '@frontend/utils';
import {
  companyInvoicesQuery,
  companyInvoicesQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { Filters } from './components/Filters';
import { InvoicesTable } from './components/InvoicesTable';
import { COMPANY_INVOICES_QUERY } from './graphql/queries';
import { invoiceMessages } from './messages';
import { updateQuery } from './utils/update-query';

export const Invoices: React.FC<RouteComponentProps<CompanyMatchParams>> = ({
  location: { search },
  match: {
    params: { companyId },
  },
}) => {
  const { per_page, ...filters } = qs.parse(search);
  const {
    supplier,
    invoiceFrom,
    invoiceTo,
    dueFrom,
    dueTo,
    invoiceStatus,
    invoiceNumber,
    amountFrom,
    amountTo,
  } = filters;

  const { data, previousData, loading, error, fetchMore } = useQuery<
    companyInvoicesQuery,
    companyInvoicesQueryVariables
  >(COMPANY_INVOICES_QUERY, {
    variables: {
      companyId,
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      supplier,
      dueDate: {
        from: dueFrom || undefined,
        to: dueTo || undefined,
      },
      issueDate: {
        from: invoiceFrom || undefined,
        to: invoiceTo || undefined,
      },
      status: invoiceStatus,
      invoiceNumber,
      payableAmount: {
        from: toNumber(amountFrom),
        to: toNumber(amountTo),
      },
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;

  if (!_data && loading) {
    return <TopLoading />;
  }

  if (!_data?.company?.invoices) {
    return <GraphQlError error={error} />;
  }

  if (!Object.keys(filters).length && !_data.company.invoices.edges.length) {
    return (
      <EmptyState
        title={<FormattedMessage {...invoiceMessages.noInvoices} />}
        error={error}
      />
    );
  }

  const { pageInfo } = _data.company.invoices;
  const { startCursor, endCursor } = pageInfo;
  const invoices = _data.company.invoices.edges.map(edge => edge.node);

  return (
    <Section>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <Filters />
      <InvoicesTable
        companyId={companyId}
        invoices={invoices}
        navigation={
          <TableNavigation
            pageInfo={pageInfo}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: endCursor,
                },
                updateQuery,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: startCursor,
                  first: undefined,
                  last: per_page
                    ? parseInt(per_page, 10)
                    : DEFAULT_RESULT_PER_PAGE,
                },
                updateQuery,
              });
            }}
          />
        }
      />
    </Section>
  );
};
