import gql from 'graphql-tag';

export const CREATE_PROPOSAL_EMPLOYEE_MUTATION = gql`
  mutation createProposalEmployeeMutation(
    $input: CreateProposalMembershipInput!
  ) {
    createProposalMembership(input: $input) {
      id
      benefitPackages {
        id
        memberships {
          id
        }
      }
      memberships {
        id
      }
    }
  }
`;
