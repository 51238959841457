import { SubsectionHeader } from '@frontend/ui';
import {
  bonusSalaryExchangeRequestsQuery,
  bonusSalaryExchangeRequestsQueryVariables,
  FlexSalaryExchangeRequestStatus,
} from 'app/apollo/graphql/types';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { companySalaryExchangeMessages } from '../../../messages';
import { BONUS_SALARY_EXCHANGE_REQUESTS_QUERY } from '../../bonus/graphql/queries';
import { updateQuery } from '../../bonus/utils/update-query';
import { RequestSalaryExchangeType } from '../../types';
import { RejectTable } from '../components/Table';

const isEmpty = (data?: bonusSalaryExchangeRequestsQuery) =>
  !data?.bonusSalaryExchangeRequests?.edges.length;

const hasPendingRequests = (
  data?: bonusSalaryExchangeRequestsQuery,
): data is bonusSalaryExchangeRequestsQuery => !isEmpty(data);

interface Props {
  requestIds: string[];
}

export const RejectBonusSalaryExchangeRequests: React.FC<Props> = ({
  requestIds,
}) => {
  const suspendRef = useRef(true);

  const [perPage, setPerPage] = useState(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data, previousData, fetchMore, loading } = useQuery<
    bonusSalaryExchangeRequestsQuery,
    bonusSalaryExchangeRequestsQueryVariables
  >(BONUS_SALARY_EXCHANGE_REQUESTS_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    suspend: suspendRef.current,
    isEmpty,
    variables: {
      companyId,
      requestIds,
      statuses: [FlexSalaryExchangeRequestStatus.REQUESTED],
      first: Number(perPage),
    },
    onCompleted: () => {
      suspendRef.current = false;
    },
  });

  const _data = data ?? previousData;

  if (!_data?.bonusSalaryExchangeRequests || !hasPendingRequests(_data)) {
    return null;
  }

  const { edges, pageInfo } = _data.bonusSalaryExchangeRequests;

  const requests = edges.map(e => e.node);

  const navigation = (
    <TableNavigation
      perPage={perPage}
      setPerPage={setPerPage}
      pageInfo={pageInfo}
      onNextPage={() => {
        fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
          updateQuery,
        });
      }}
      onPreviousPage={() => {
        fetchMore({
          variables: {
            before: pageInfo.startCursor,
            first: undefined,
            last: Number(perPage),
          },
          updateQuery,
        });
      }}
    />
  );

  return (
    <>
      {!suspendRef.current && loading && <TopLoading />}
      <SubsectionHeader>
        <FormattedMessage
          {...companySalaryExchangeMessages.onetimeSalaryExchanges}
        />
      </SubsectionHeader>
      <RejectTable
        type={RequestSalaryExchangeType.ONETIME}
        navigation={navigation}
        requests={requests}
      />
    </>
  );
};
