import { previewTableQuery } from 'app/apollo/graphql/types';

export const updateRecords = (
  previousResult: previewTableQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: previewTableQuery;
  },
): previewTableQuery => {
  if (!previousResult?.import?.records || !fetchMoreResult?.import?.records) {
    return previousResult;
  }

  const { edges, pageInfo, totalCount } = fetchMoreResult.import.records;

  return {
    import: {
      ...previousResult.import,
      records: {
        __typename: previousResult.import.records.__typename,
        edges: [...edges],
        pageInfo,
        totalCount,
      },
    },
  };
};
