import { invoiceLinesQuery } from 'app/apollo/graphql/types';

export const updateInvoiceLinesQuery = (
  previousResult: invoiceLinesQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: invoiceLinesQuery;
  },
): invoiceLinesQuery => {
  if (
    !previousResult?.invoiceSearch?.invoiceLines ||
    !fetchMoreResult?.invoiceSearch?.invoiceLines
  ) {
    return previousResult;
  }

  const { edges, pageInfo, totalCount } =
    fetchMoreResult.invoiceSearch.invoiceLines;

  return {
    invoiceSearch: {
      ...previousResult.invoiceSearch,
      invoiceLines: {
        edges: [...edges],
        pageInfo,
        totalCount,
      },
    },
  };
};
