import { Formik } from '@frontend/formik';
import {
  smeCompanyEditDisabilityQuery,
  smeCompanyEditDisabilityQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as BenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/benefit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  DisabilityForm,
  FormValues,
  validationSchema,
} from '../../form/disability';
import { SME_COMPANY_EDIT_DISABILITY_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

interface Config {
  __typename?: string;
}

interface DisabilityConfig {
  __typename: 'ProposalSicknessBenefitConfig';
}

export const isDisabilityConfig = (
  config: Config,
): config is DisabilityConfig =>
  config.__typename === 'ProposalSicknessBenefitConfig';

export const EditDisability: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<BenefitMatchParams>();
  const { submit, submissionError } = useSubmit({ params });

  const { data, loading, error } = useQuery<
    smeCompanyEditDisabilityQuery,
    smeCompanyEditDisabilityQueryVariables
  >(SME_COMPANY_EDIT_DISABILITY_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, proposalId: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.benefit) {
    return <EmptyState error={error} />;
  }

  const { benefit, benefitPackages } = data.proposal;

  if (!benefit?.configuration || !isDisabilityConfig(benefit.configuration)) {
    return null;
  }

  const initialValues: FormValues = {
    benefitPackageIds: benefit.benefitPackages.map(({ id }) => id),
    converting: benefit.configuration.converting,
    convertingFrom: benefit.configuration.convertingFrom ?? '',
    extendedSickPay: benefit.configuration.extendedSickPay,
    level: benefit.configuration.sicknessLevel ?? '',
    salaryMultiplier: benefit.configuration.salaryMultiplier.toString(),
  };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ isValid, isSubmitting, values }) => (
        <DisabilityForm
          benefitPackages={benefitPackages}
          isValid={isValid}
          isSubmitting={isSubmitting}
          submissionError={submissionError}
          values={values}
        />
      )}
    </Formik>
  );
};
