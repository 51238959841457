import { SortOrder } from 'app/apollo/graphql/types';
import { useState } from 'react';

export interface Order<T> {
  order?: SortOrder;
  orderBy?: T;
}

export interface TableSort<T> extends Order<T> {
  handleSort: (value: T) => void;
}

export const usePensionTransferTableSort = <T>(): TableSort<T> => {
  const [{ order, orderBy }, setState] = useState<Order<T>>({});

  return {
    order,
    orderBy,
    handleSort: (column: T) => {
      if (orderBy === column) {
        if (order === SortOrder.DESC) {
          setState(() => ({
            orderBy: undefined,
            order: undefined,
          }));

          return;
        }
        setState(state => ({
          ...state,
          order: SortOrder.DESC,
        }));
        return;
      }
      setState(() => ({
        orderBy: column,
        order: SortOrder.ASC,
      }));
    },
  };
};
