import { toNumber } from '@frontend/utils';
import {
  FlexChangeType,
  InsurancePremiumStatus,
  pensionTableQuery_changes_FlexOccupationalPensionChange as OccupationalPensionChange,
  pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance as MembershipOccupationalPension,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  BankingInstituteId,
  OCCUPATIONAL_PENSION_PROVIDER_ID,
} from 'app/utils/constants';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

type PensionChangeType =
  | FlexChangeType.PENSION_FUTUR
  | FlexChangeType.PENSION_NORDNET
  | FlexChangeType.PENSION_LF;

const CHANGE_TYPE_PROVIDER_MAP: Record<
  PensionChangeType,
  OCCUPATIONAL_PENSION_PROVIDER_ID
> = {
  PENSION_FUTUR: BankingInstituteId.FUTUR,
  PENSION_NORDNET: BankingInstituteId.NORDNET,
  PENSION_LF: BankingInstituteId.LFS,
};

export const PremiumEntry: React.FC<{
  pensionInsurance: MembershipOccupationalPension;
  firstChange?: OccupationalPensionChange;
  status?: InsurancePremiumStatus | null;
}> = ({ pensionInsurance, status, firstChange }) => {
  const pensionPremium =
    pensionInsurance.nextPremiumPayment ??
    pensionInsurance.previousPremiumPayment;

  if (!pensionPremium) {
    return <NoValue />;
  }

  const willPremiumBeUpdated =
    !!firstChange &&
    CHANGE_TYPE_PROVIDER_MAP[firstChange.type] ===
      pensionInsurance.institute.id &&
    toNumber(pensionPremium.value) !== toNumber(firstChange.premium);

  if (firstChange && willPremiumBeUpdated) {
    return (
      <FormattedMessage
        {...commonMessages.premiumWithUpcomingPremium}
        values={{
          premium: pensionPremium.value,
          newPremium: firstChange.premium,
          newPremiumEffectiveDate: (
            <FormattedDate value={firstChange.effectiveDate} />
          ),
        }}
      />
    );
  }

  if (
    [
      InsurancePremiumStatus.ACTIVE,
      InsurancePremiumStatus.ACTIVE_WAIVER,
      InsurancePremiumStatus.OPEN,
    ].some(_status => _status === status) &&
    pensionInsurance.effectiveDate
  ) {
    return (
      <FormattedMessage
        {...commonMessages.premiumPerMonth}
        values={{
          value: pensionPremium.value,
        }}
      />
    );
  }
  if (status === InsurancePremiumStatus.PENDING_ACTIVATION) {
    return (
      <FormattedMessage
        {...commonMessages.updatingPremiumValue}
        values={{
          value: pensionPremium.value,
        }}
      />
    );
  }

  return (
    <FormattedCurrency
      value={pensionPremium.value}
      currency="SEK"
      after="PER_MONTH"
    />
  );
};
