import { employeesWithPersonalAdviceQuery } from 'app/apollo/graphql/types';

export const updatePersonalAdviceEmployeesQuery = (
  previousResult: employeesWithPersonalAdviceQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: employeesWithPersonalAdviceQuery;
  },
) => {
  if (
    !previousResult?.employeesWithPersonalAdvice ||
    !fetchMoreResult?.employeesWithPersonalAdvice
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.employeesWithPersonalAdvice;

  return {
    employeesWithPersonalAdvice: {
      __typename: previousResult.employeesWithPersonalAdvice.__typename,
      edges: [...edges],
      pageInfo,
    },
  };
};
