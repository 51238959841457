import { useMutation } from '@apollo/client';
import {
  invoiceDetails,
  InvoiceStatus,
  UpdateInvoiceStatusInput,
  updateInvoiceStatusMutation,
  updateInvoiceStatusMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { InlineMenu } from 'components/InlineMenu';
import { useNotification } from 'features/notifications';
import React from 'react';

import { UPDATE_INVOICE_STATUS_MUTATION } from '../../graphql/mutations';
import { invoiceMessages } from '../../messages';

interface Props {
  companyId: string;
  invoice: invoiceDetails;
}

export const InvoiceStatusMenu: React.FC<Props> = ({ companyId, invoice }) => {
  const { send } = useNotification();
  const intl = useIntl();

  const [updateInvoiceStatus] = useMutation<
    updateInvoiceStatusMutation,
    updateInvoiceStatusMutationVariables
  >(UPDATE_INVOICE_STATUS_MUTATION);

  const isInvoiceStatus = (status: string): status is InvoiceStatus =>
    Object.values(InvoiceStatus).includes(status as InvoiceStatus);

  const onChange = async (status: string): Promise<void> => {
    try {
      if (!isInvoiceStatus(status)) {
        throw new Error();
      }

      const input: UpdateInvoiceStatusInput = {
        companyId,
        invoiceId: invoice.id,
        status,
      };

      const res = await updateInvoiceStatus({
        variables: { input },
        optimisticResponse: {
          updateInvoiceStatus: {
            __typename: 'UpdateInvoiceStatusPayload',
            invoice: {
              ...invoice,
              status,
            },
          },
        },
      });

      if (!res?.data?.updateInvoiceStatus?.invoice || res?.errors?.length) {
        throw new Error();
      }
    } catch {
      send({
        message: intl.formatMessage(invoiceMessages.statusError),
        type: 'error',
      });
    }
  };

  return (
    <InlineMenu
      onChange={event => onChange(event.target.value)}
      options={[
        {
          label: intl.formatMessage(invoiceMessages.statusIgnored),
          value: InvoiceStatus.IGNORED,
          selected: invoice.status === InvoiceStatus.IGNORED,
        },
        {
          label: intl.formatMessage(invoiceMessages.statusPaid),
          value: InvoiceStatus.PAID,
          selected: invoice.status === InvoiceStatus.PAID,
        },
        {
          label: intl.formatMessage(invoiceMessages.statusNotPaid),
          value: InvoiceStatus.NOT_PAID,
          selected: invoice.status === InvoiceStatus.NOT_PAID,
        },
      ]}
    />
  );
};
