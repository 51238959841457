import compareAsc from 'date-fns/compareAsc';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';

interface FixedPremium {
  createdAt: string;
  effectiveDate: string;
  premium: string;
}

interface Args {
  fixedPremiums: FixedPremium[];
  today: Date;
}

export const getFixedPremiumForDate = ({ fixedPremiums, today }: Args) =>
  fixedPremiums
    .sort((a, b) => compareAsc(new Date(b.createdAt), new Date(a.createdAt)))
    .find(
      ({ effectiveDate }) =>
        isBefore(new Date(effectiveDate), today) ||
        isSameDay(new Date(effectiveDate), today),
    );
