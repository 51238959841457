import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import startOfMonth from 'date-fns/startOfMonth';

/**
 * Computes the 'yyyy-MM-dd' formatted proposal start date based on the date of signing
 * and the start date configured on the proposal.
 *
 * @param dateOfSigning The date of signing.
 * @param startDate The start date. This is always assumed to be the first day of the month.
 */
export const getProposalStartDate = (
  dateOfSigning: string,
  startDate: string,
) => {
  const _dateOfSigning = new Date(dateOfSigning);
  const _startDate = new Date(startDate);

  const isSameMonth =
    format(_dateOfSigning, 'yyyy-MM') === format(_startDate, 'yyyy-MM');

  if (isSameMonth) {
    return format(
      isAfter(_dateOfSigning, addDays(_startDate, 14))
        ? startOfMonth(addMonths(_dateOfSigning, 1))
        : _startDate,
      'yyyy-MM-dd',
    );
  }

  const isDateOfSigningBeforeStartDate = isBefore(_dateOfSigning, _startDate);

  if (isDateOfSigningBeforeStartDate) {
    return format(_startDate, 'yyyy-MM-dd');
  }

  return format(startOfMonth(_dateOfSigning), 'yyyy-MM-dd');
};
