import { ContentContainer } from '@frontend/ui';
import { companyMessages } from 'app/messages/company';
import { FormattedMessage } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { AddAgreements } from 'features/companies/company/agreements/add';
import { Page } from 'features/page';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams as CompanyMatchParams } from '../../..';

export const AddAgreementsRoute: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({ match: { params, url } }) => (
  <NavigationAnchor title={companyMessages.addAgreements} url={url}>
    <Page
      title={<FormattedMessage {...companyMessages.addAgreements} />}
      parentLink={`/companies/${params.companyId}/settings`}
    >
      <ContentContainer>
        <AddAgreements />
      </ContentContainer>
    </Page>
  </NavigationAnchor>
);
