import { ContentState, convertToRaw, EditorState } from 'draft-js';
import _draftToHtml from 'draftjs-to-html';
import _htmlToDraft from 'html-to-draftjs';

/**
 * Converts html markup string to EditorState
 */
export const htmlToDraft: (html: string) => EditorState = html => {
  if (html) {
    const content = _htmlToDraft(html);
    if (content) {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(content.contentBlocks),
      );
    }
  }
  return EditorState.createEmpty();
};

/**
 * Converts EditorState to html markup string
 */
export const draftToHtml: (state: EditorState) => string = state =>
  _draftToHtml(convertToRaw(state.getCurrentContent()));

/**
 * Disables bold, italic and underline shortcuts in the editor
 */
export const disableRichTextShortcuts = (command: string) => {
  if (['bold', 'italic', 'underline'].includes(command)) {
    return 'handled';
  }
  return 'not-handled';
};

/**
 * Moves the focus to the start of the editor
 */
export const moveFocusToStart = (editorState: EditorState): EditorState => {
  const selectionState = editorState.getSelection();
  return EditorState.forceSelection(
    editorState,
    selectionState.merge({
      anchorOffset: 0,
      focusOffset: 0,
    }),
  );
};

/**
 * Returns the length of the selected text
 */
const getLengthOfSelectedText = (editorState: EditorState): number => {
  const currentSelection = editorState.getSelection();

  const currentContent = editorState.getCurrentContent();
  const startKey = currentSelection.getStartKey();
  const endKey = currentSelection.getEndKey();
  const isStartAndEndBlockTheSame = startKey === endKey;

  if (isStartAndEndBlockTheSame) {
    return currentSelection.getEndOffset() - currentSelection.getStartOffset();
  }

  const startBlock = currentContent.getBlockForKey(startKey);
  const startBlockTextLength = startBlock.getLength();
  const startSelectedTextLength =
    startBlockTextLength - currentSelection.getStartOffset();
  const endSelectedTextLength = currentSelection.getEndOffset();
  const keyAfterEnd = currentContent.getKeyAfter(endKey);

  let length = 0;
  let currentKey = startKey;

  while (currentKey && currentKey !== keyAfterEnd) {
    if (currentKey === startKey) {
      length += startSelectedTextLength + 1;
      break;
    }
    if (currentKey === endKey) {
      length += endSelectedTextLength;
      break;
    }
    length += currentContent.getBlockForKey(currentKey).getLength() + 1;
    currentKey = currentContent.getKeyAfter(currentKey);
  }
  return length;
};

/**
 * Checks if the character limit is reached before input
 */
export const checkCharacterLimitBeforeInput = (
  editorState: EditorState,
  maxLength?: number,
) => {
  if (!maxLength) {
    return undefined;
  }
  const currentContent = editorState.getCurrentContent();
  const currentContentLength = currentContent.getPlainText('').length;
  const selectedTextLength = getLengthOfSelectedText(editorState);

  if (currentContentLength - selectedTextLength > maxLength - 1) {
    return 'handled';
  }
  return undefined;
};

/**
 * Checks if the character limit is reached before paste
 */
export const checkCharacterLimitBeforePaste =
  (editorState: EditorState, maxLength?: number) => (text: string) => {
    if (!maxLength) {
      return false;
    }
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText(editorState);
    const textLength = text.length;

    if (currentContentLength - selectedTextLength + textLength > maxLength) {
      return true;
    }
    return false;
  };
