import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';

import { benefitFormMessages } from '../../messages';
import { Agreements } from '../../types';
import { getHasEaGruppAgreement } from '../../utils';

interface Props {
  agreements: Agreements;
}

export const MissingSicknessAndAccidentAgreementNotification: React.FC<
  Props
> = ({ agreements }) => {
  const hasEaGruppAgreement = getHasEaGruppAgreement(agreements);

  if (hasEaGruppAgreement) {
    return null;
  }

  return (
    <NotificationCard type="warning">
      <FormattedMessage
        {...benefitFormMessages.missingSicknessAndAccidentAgreement}
      />
    </NotificationCard>
  );
};
