import { ApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  claimPensionTransferTasks,
  claimPensionTransferTasksVariables,
  confirmMeetingMutation,
  confirmMeetingMutationVariables,
} from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import {
  CLAIM_PENSION_TRANSFERS,
  CONFIRM_MEETING_MUTATION,
} from '../../graphql/mutations';
import {
  EMPLOYEE_MEETINGS_QUERY,
  EMPLOYEES_WITH_PERSONAL_ADVICE_QUERY,
  MEETING_STATISTICS_QUERY,
} from '../../graphql/queries';
import { AddFormValues } from '..';

interface Props {
  onRequestClose: () => void;
}

interface Submit {
  loading: boolean;
  submit: (
    values?: AddFormValues,
    helpers?: FormikHelpers<AddFormValues>,
  ) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = ({ onRequestClose }: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [
    addHeldMeeting,
    { error: addHeldMeetingError, loading: addHeldMeetingLoading },
  ] = useMutation<confirmMeetingMutation, confirmMeetingMutationVariables>(
    CONFIRM_MEETING_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        EMPLOYEE_MEETINGS_QUERY,
        EMPLOYEES_WITH_PERSONAL_ADVICE_QUERY,
        MEETING_STATISTICS_QUERY,
      ],
    },
  );

  const [
    claimPensionTransfers,
    {
      error: claimPensionTransfersError,
      loading: claimPensionTransfersLoading,
    },
  ] = useMutation<
    claimPensionTransferTasks,
    claimPensionTransferTasksVariables
  >(CLAIM_PENSION_TRANSFERS);

  const callback = async (values: AddFormValues, helpers) => {
    try {
      await addHeldMeeting({
        variables: {
          input: {
            userAccountId: values.userAccountId,
            companyId: values.companyId,
            date: values.date,
            eventTypeCategory: values.eventTypeCategory,
          },
        },
      });

      if (values.pensionTransferTaskIds?.length) {
        await claimPensionTransfers({
          variables: {
            input: {
              pensionTransferTaskIds: values.pensionTransferTaskIds,
            },
          },
        });
      }

      send({
        message: formatMessage(advisorMessages.heldMeetingAdded),
        type: 'success',
      });

      onRequestClose();
    } catch (error) {
      helpers.setErrors({
        submissionError: error?.message,
      });
    }
  };

  return {
    submit: callback,
    error: addHeldMeetingError ?? claimPensionTransfersError,
    loading: addHeldMeetingLoading ?? claimPensionTransfersLoading,
  };
};
