import { agreementDetails } from 'app/apollo/graphql/types';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

import { agreementMessages } from '../../messages';

const getAgreementName = (agreement: agreementDetails) => {
  switch (agreement.__typename) {
    case 'AgreementDanica':
    case 'AgreementRequestDanica':
      return <FormattedMessage {...agreementMessages.danicaAgreement} />;
    case 'AgreementRequestAdminPoa':
    case 'AgreementAdminPoa':
      return <FormattedMessage {...agreementMessages.poa} />;
    case 'AgreementCustom':
      return <FormattedMessage {...agreementMessages.other} />;
    case 'AgreementRequestNordea':
    case 'AgreementNordea':
      return 'Nordea';
    case 'AgreementRequestEuroAccident':
    case 'AgreementEuroAccident':
      switch (agreement.subAgreementType) {
        case 'GRUPP':
          return <FormattedMessage {...agreementMessages.euroAccidentGrupp} />;
        case 'TJP':
          return <FormattedMessage {...agreementMessages.euroAccidentTjp} />;
        default:
          return <NoValue />;
      }
    default:
      return <NoValue />;
  }
};

interface Props {
  agreement: agreementDetails;
}

export const AgreementName: React.FC<Props> = ({ agreement }) => (
  <>{getAgreementName(agreement)}</>
);
