import gql from 'graphql-tag';

export const SME_COMPANY_BENEFIT_COSTS_QUERY = gql`
  query smeCompanyBenefitCostsQuery($id: ID!) {
    proposal(id: $id) {
      id
      cost {
        total
        riskTotal
        riskSickness
        riskAccident
        riskLife
        riskHealthcare
        riskTgl
        riskPensionPremiumWaiver
        salaryExchange
        occupationalPension
        employersPension
      }
    }
  }
`;
