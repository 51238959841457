import { CheckboxField } from '@frontend/formik';
import { Table, Td, Th, Tr } from '@frontend/ui';
import {
  addBenefitsToBenefitPackageQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage as BenefitPackage,
  addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit,
  ManagingCompany,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { DetailCell } from 'features/companies/company/benefits/components/BenefitsTable/DetailCell';
import { InBenefitPackage } from 'features/companies/company/benefits/components/BenefitsTable/InBenefitPackage';
import { getBenefitDisplayName } from 'features/companies/company/benefits/utils/get-benefit-display-name';
import React from 'react';

interface Props {
  allBenefitPackages: BenefitPackage[];
  allBenefits: Benefit[];
  companyId: string;
  disabledBenefitIds: string[];
  managedBy: ManagingCompany;
  nBenefitsSelected: number;
}

export const AddBenefitsTable: React.FC<Props> = ({
  allBenefitPackages,
  allBenefits,
  companyId,
  disabledBenefitIds,
  managedBy,
  nBenefitsSelected,
}) => {
  const intl = useIntl();

  return (
    <Table
      feedback={{
        children: (
          <FormattedMessage
            {...commonMessages.nSelected}
            values={{ selected: nBenefitsSelected }}
          />
        ),
        active: !!nBenefitsSelected,
      }}
    >
      <colgroup>
        <col style={{ width: '2.5rem' }} />
        <col style={{ width: '25%' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '25%' }} />
      </colgroup>
      <thead>
        <Tr>
          <Th />
          <Th>
            <FormattedMessage {...commonMessages.name} />
          </Th>
          <Th>
            <FormattedMessage {...commonMessages.category} />
          </Th>
          <Th multiline>
            <FormattedMessage {...commonMessages.detail} />
          </Th>
          <Th multiline>
            <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />
          </Th>
        </Tr>
      </thead>
      <tbody>
        {allBenefits.map(benefit => (
          <Tr key={benefit.id}>
            <Td type="input">
              <CheckboxField
                withMargin={false}
                disabled={disabledBenefitIds.includes(benefit.id)}
                name={`benefits.${benefit.id}`}
              />
            </Td>
            <Th multiline>{getBenefitDisplayName(benefit, intl)}</Th>
            <Td>{benefit.categories[0]?.name ?? <NoValue />}</Td>
            <Td multiline>
              <DetailCell
                benefit={benefit}
                companyId={companyId}
                managedBy={managedBy}
              />
            </Td>
            <Td multiline>
              <InBenefitPackage
                benefit={benefit}
                benefitPackageOptions={allBenefitPackages}
              />
            </Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};
