import gql from 'graphql-tag';

import { employmentActivityDetails } from './fragments';

export const EMPLOYMENT_ACTIVITIES_QUERY = gql`
  query employmentActivitiesQuery($id: ID!) {
    employment(id: $id) {
      id
      period
      rate
      membership {
        id
        userAccountId
        givenName
        lastName
      }
      activities {
        edges {
          node {
            ...employmentActivityDetails
          }
        }
      }
    }
  }
  ${employmentActivityDetails}
`;
