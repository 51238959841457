import { PremiumMatrix } from '@advinans/premium-matrix';
import { BaseAmountType } from '@advinans/premium-matrix/build/premium-matrix';
import { BenefitType } from '@frontend/benefit-types';
import { toNumber, toPercent } from '@frontend/utils';
import BigNumber from 'bignumber.js';
import format from 'date-fns/format';
import subMonths from 'date-fns/subMonths';

import {
  Agreement,
  Agreements,
  Benefit,
  BenefitPackageOption,
  BenefitPackages,
  EuroAccidentAgreement,
  PensionConfigurationPremiumMatrix,
  PensionPremiumCalculationMethod,
  PensionProviderOptionValue,
  PersonalAdviceMeetingFrequencyOptionValue,
  PremiumMatrixFormValues,
} from '../types';
import {
  AGREEMENT,
  PERSONAL_ADVICE_MEETING_FREQUENCY_OPTION_VALUES,
} from './constants';

/**
 * Format API data to form values format
 */
export const formatPremiumMatrixFormValues = (
  matrix: PensionConfigurationPremiumMatrix,
): PremiumMatrixFormValues => {
  const { age, baseAmount, baseAmountType, intervals } = matrix;

  return {
    age,
    baseAmount: baseAmount.map(b => (toNumber(b) ?? 0).toFixed(2)),
    baseAmountType,
    intervals: intervals.map(interval =>
      interval.map(i => toPercent(i).toFixed(2)),
    ),
  };
};

/**
 * Format form values to API data format
 */
export const formatPremiumMatrixInput = (
  formValues: PremiumMatrixFormValues,
): PensionConfigurationPremiumMatrix => {
  const { age, baseAmount, baseAmountType, intervals } = formValues;
  return {
    age,
    baseAmount: baseAmount.map(b =>
      new BigNumber(b.replace(',', '.')).toFixed(4),
    ),
    baseAmountType,
    intervals: intervals.map(interval =>
      interval.map(i => {
        const value = i || '0,00';
        return new BigNumber(value.replace(',', '.')).dividedBy(100).toFixed(4);
      }),
    ),
  };
};

/**
 * Since benefits can only have a retroactivity of 3 months,
 * this function uses the current date and subtracts 3 months from it.
 *
 * @returns Formatted date string (yyyy-MM)
 */
export const getEarliestRetroactiveDate = (): string =>
  format(subMonths(new Date(), 3), 'yyyy-MM');

/**
 * @returns true iff the `benefitType` is included
 * as a type in the list of benefits provided by `benfits`
 */
const benefitTypeInBenefitPackage = (
  benefitType: BenefitType | BenefitType[],
  benefits: Benefit[],
): boolean => {
  const existingBenefitTypes = benefits.map(({ type }) => type);
  return Array.isArray(benefitType)
    ? benefitType.some(t => existingBenefitTypes.includes(t))
    : existingBenefitTypes.includes(benefitType);
};

export const getBenefitPackageOptions = <T extends BenefitPackages>(
  benefitType: BenefitType | BenefitType[],
  benefitPackages: T,
  benefitId?: string,
): BenefitPackageOption[] => {
  if (!benefitPackages) {
    return [];
  }
  const { edges } = benefitPackages;

  return edges.map(({ node: benefitPackage }) => ({
    value: benefitPackage.id,
    label: benefitPackage.name,
    disabled: benefitTypeInBenefitPackage(
      benefitType,
      benefitPackage.benefits.edges
        .map(({ node }) => node)
        .filter(node => node.id !== benefitId),
    ),
  }));
};

/**
 * @returns true iff the company has an agreement with the provider
 */
export const hasAgreementWithProvider = (
  provider: PensionProviderOptionValue,
  agreements: Agreements,
) => agreements.some(agreement => agreement.__typename === AGREEMENT[provider]);

/**
 * Type guard for EuroAccidentAgreement agreements to access
 * the subAgreementType property
 */
export const isEuroAccidentAgreement = (
  agreement: Agreement,
): agreement is EuroAccidentAgreement =>
  agreement.__typename === 'AgreementEuroAccident';

/**
 * @returns true iff the company has a group agreement with EuroAccident
 */
export const getHasEaGruppAgreement = (agreements: Agreements) =>
  agreements.some(
    agreement =>
      isEuroAccidentAgreement(agreement) &&
      agreement.subAgreementType === 'GRUPP',
  );

/**
 * @returns true iff the company has a TJP agreement with EuroAccident
 */
export const getHasEaTjpAgreement = (agreements: Agreements) =>
  agreements.some(
    agreement =>
      isEuroAccidentAgreement(agreement) &&
      agreement.subAgreementType === 'TJP',
  );

interface GetPremiumCalculationMethodArgs {
  availableOptions: readonly PensionPremiumCalculationMethod[];
  premiumMatrix?: PensionConfigurationPremiumMatrix | null;
}
/**
 * Determines the premium calculation method based on the premium matrix.
 *
 * If the matrix is not provided, the method is set to `FIXED_PREMIUM_ONLY`.
 * If the matrix type is not recognized or not available in `availableOptions`,
 * the method is set to `CUSTOM_MATRIX`.
 */
export const getPremiumCalculationMethod = ({
  availableOptions,
  premiumMatrix,
}: GetPremiumCalculationMethodArgs): PensionPremiumCalculationMethod => {
  if (!premiumMatrix) {
    return 'FIXED_PREMIUM_ONLY';
  }
  const getDefaultPremiumMatrixType = () => {
    const _premiumMatrix = new PremiumMatrix({
      ...premiumMatrix,
      baseAmountType:
        premiumMatrix.baseAmountType === 'IBB'
          ? BaseAmountType.IBB
          : BaseAmountType.PBB,
      // salaryMultiplier is deprecated and not used,
      // but still required
      salaryMultiplier: 12,
    });
    if (_premiumMatrix.equals(PremiumMatrix.ITP1)) {
      return 'ITP_MATRIX';
    }
    if (_premiumMatrix.equals(PremiumMatrix.ITP_LIKE)) {
      return 'ITP_LIKE_MATRIX';
    }
    return undefined;
  };

  const matrix = getDefaultPremiumMatrixType();
  return matrix && availableOptions.includes(matrix) ? matrix : 'CUSTOM_MATRIX';
};
/**
 * Determines the extra salary exchange premium from the provided tax benefit share.
 */
export const getExtraPremiumFromTaxBenefitShare = (val: number) =>
  (1.3142 / 1.2426 - 1) * (val / 100);

/**
 * Format form value to API data format
 */
export const formatPersonalAdviceMeetingFrequencyMonthsInput = (
  value: PersonalAdviceMeetingFrequencyOptionValue,
) => (value === 'ONE_ADVICE_MEETING_LIMIT' ? null : toNumber(value)) ?? null;

const isPersonalAdviceMeetingFrequencyOptionValue = (
  value: string,
): value is PersonalAdviceMeetingFrequencyOptionValue =>
  PERSONAL_ADVICE_MEETING_FREQUENCY_OPTION_VALUES.some(
    optionValue => optionValue === value,
  );

/**
 * Format API data to form value format
 */
export const formatPersonalAdviceMeetingFrequencyMonthsFormValue = (
  value: number | null,
): PersonalAdviceMeetingFrequencyOptionValue | '' => {
  if (value === null) {
    return 'ONE_ADVICE_MEETING_LIMIT';
  }
  const _value = value.toString();
  if (isPersonalAdviceMeetingFrequencyOptionValue(_value)) {
    return _value;
  }
  return '';
};
