import gql from 'graphql-tag';

export const EDIT_BENEFIT_PACKAGE_MODAL_QUERY = gql`
  query editBenefitPackageModalQuery($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      benefitPackages {
        id
        name
      }
    }
  }
`;
