import {
  ProposalStatus,
  smeProposalReadonlyQuery,
  smeProposalReadonlyQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { useParams } from 'react-router';

import { SME_PROPOSAL_READONLY_QUERY } from '../graphql/queries';

export const useProposalReadonly = () => {
  const { proposalId } = useParams<MatchParams>();

  const { data } = useQuery<
    smeProposalReadonlyQuery,
    smeProposalReadonlyQueryVariables
  >(SME_PROPOSAL_READONLY_QUERY, {
    errorPolicy: 'all',
    variables: { proposalId },
  });

  if (!data?.proposal) {
    return false;
  }

  return data.proposal.status !== ProposalStatus.STARTED;
};
