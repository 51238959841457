/* eslint-disable */
import 'core-js/proposals';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';

import ReactModal from 'react-modal';
ReactModal.setAppElement('#app');
import { createRoot } from 'react-dom/client';

import { Root } from 'app';
import { Provider } from 'app/Provider';

import { Route } from 'react-router-dom';
import { APP_ID } from 'app/utils/constants';

export const basename = document
  .getElementsByTagName('base')[0]!
  .getAttribute('href')!
  .replace(/\/$/, '');

async function renderApp(Component: any) {
  const rootNode = document.getElementById(APP_ID);

  if (rootNode) {
    const root = createRoot(rootNode);
    root.render(
      <Provider>
        <Route component={Component} />
      </Provider>,
    );
  }
}

renderApp(Root);
