import { DatePickerField, NumberField } from '@frontend/formik';
import { Grid, GridCell } from '@frontend/ui';
import { suffixMessages } from 'app/messages/common';
import {
  employeeFormMessages,
  employmentMessages,
} from 'app/messages/employees';
import { FormattedMessage, useIntl } from 'components/formats';
import { GridCell50 } from 'components/GridCell';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';

export const EmploymentFormFields: React.FC = () => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  return (
    <Grid>
      <GridCell50>
        <DatePickerField
          gridMargin
          name="effectiveDate"
          label={
            <FormattedMessage {...employmentMessages.employmentStartDate} />
          }
          required
        />
      </GridCell50>
      <GridCell50>
        <DatePickerField
          gridMargin
          name="effectiveThrough"
          label={<FormattedMessage {...employmentMessages.employmentEndDate} />}
        />
      </GridCell50>
      <GridCell span={12}>
        <NumberField
          gridMargin
          thousandSeparator={false}
          name="rate"
          label={
            <FormattedMessage {...employmentMessages.partTimeEmployment} />
          }
          affix={formatMessage(suffixMessages.percentage)}
          helperText={
            <FormattedMessage
              {...employeeFormMessages.membershipStatusExtentHelperText}
            />
          }
          required
          locale={locale}
        />
      </GridCell>
    </Grid>
  );
};
