import { ApolloError, useMutation } from '@apollo/client';
import { Modifiers } from '@apollo/client/cache';
import { client } from 'app/apollo/client';
import {
  UpdateProposalInput,
  updateProposalMutation,
  updateProposalMutationVariables,
  updateProposalQuery,
  updateProposalQuery_proposal_Proposal as Proposal,
  updateProposalQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';

import {
  AllowedFields,
  clearProposalCache,
} from '../../../utils/clear-proposal-cache';
import { UPDATE_PROPOSAL_MUTATION } from '../../graphql/mutations';
import { UPDATE_PROPOSAL_QUERY } from '../../graphql/queries';
import { useIsOnline } from './utils/use-is-online';
import {
  Operation,
  useRetryWithExponentialBackoff,
} from './utils/use-retry-with-exponential-backoff';
import { useStopPreviousMutation } from './utils/use-stop-previous-mutation';

export enum AutosaveStatus {
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  RECONNECTING = 'RECONNECTING',
}

interface OnChangeParams {
  /**
   * Represents the expected cache change after the mutation.
   * Used to update the cache optimistically for immediate user feedback.
   */
  cacheValue: Modifiers<Proposal>;
  /**
   * The mutation input. Only the fields
   * that are being updated should be included.
   */
  mutationInput: Omit<UpdateProposalInput, 'id'>;
  /**
   * Optional argument to evict fields from the cache upon
   * successful mutation.
   *
   * Used for fields that are not part of the autosave form
   * but affected by the mutation.
   *
   * Note: Only use for fields which are too costly
   * to resolve in the mutation response for each update.
   */
  evictCacheFields?: AllowedFields[];
}

export type OnChange = (params: OnChangeParams) => void;

interface IAutosaveContext {
  onChange: OnChange;
  status: AutosaveStatus;
  data?: updateProposalQuery;
  error?: ApolloError;
  loading?: boolean;
}

const AutosaveContext = createContext<IAutosaveContext>({
  onChange: () => {},
  status: AutosaveStatus.SAVED,
});

interface Configuration {
  skipAdviceData: boolean;
}

interface Props {
  children: (context: IAutosaveContext) => React.ReactNode;
  configuration: Configuration;
}

/**
 * Manages autosaving data fetching and updates.
 */
export const Autosave: React.FC<Props> = ({
  children,
  configuration: { skipAdviceData },
}) => {
  const { params } = useRouteMatch<MatchParams>();
  const { isOnline } = useIsOnline();
  const { isRetrying, run } = useRetryWithExponentialBackoff({
    skipRetry: !isOnline,
  });
  const reconnecting = !isOnline || isRetrying;

  const [saving, setSaving] = useState(false);
  const [_run] = useDebouncedCallback(async (operation: Operation<unknown>) => {
    await run(operation);
    setSaving(false);
  }, 500);

  const {
    data,
    loading,
    error: queryError,
  } = useQuery<updateProposalQuery, updateProposalQueryVariables>(
    UPDATE_PROPOSAL_QUERY,
    {
      errorPolicy: 'all',
      variables: {
        id: params.proposalId,
        skipAdviceData,
      },
    },
  );

  const [mutate] = useMutation<
    updateProposalMutation,
    updateProposalMutationVariables
  >(UPDATE_PROPOSAL_MUTATION);

  const { addToInputQueue, clearInputQueue, isStopped, stopPreviousMutation } =
    useStopPreviousMutation();

  const onChange = ({
    cacheValue,
    evictCacheFields,
    mutationInput,
  }: OnChangeParams) => {
    const signal = stopPreviousMutation();
    const _mutationInput = addToInputQueue(mutationInput);
    client.cache.modify<Proposal>({
      id: client.cache.identify({
        __typename: 'Proposal',
        id: params.proposalId,
      }),
      fields: cacheValue,
    });
    // Delay the saving state update so the cache update is
    // reflected in query data upon next re-render.
    setTimeout(() => setSaving(true), 0);
    _run(async () => {
      try {
        await mutate({
          context: {
            fetchOptions: { signal },
          },
          update: evictCacheFields
            ? clearProposalCache({
                proposalId: params.proposalId,
                fieldNames: evictCacheFields,
              })
            : undefined,
          variables: {
            input: { ..._mutationInput, id: params.proposalId },
            skipAdviceData,
          },
        });
        clearInputQueue();
      } catch (error) {
        if (!isStopped(error)) {
          throw error;
        }
      }
    });
  };

  const value = useMemo<IAutosaveContext>(
    () => ({
      onChange,
      data,
      error: queryError,
      loading,
      status: reconnecting
        ? AutosaveStatus.RECONNECTING
        : saving
          ? AutosaveStatus.SAVING
          : AutosaveStatus.SAVED,
    }),
    [data, loading, queryError, saving, reconnecting],
  );

  return (
    <AutosaveContext.Provider value={value}>
      {children(value)}
    </AutosaveContext.Provider>
  );
};

/**
 * Manages autosaving data fetching and updates.
 */
export const useAutosave = () => useContext(AutosaveContext);
