import { useMutation } from '@apollo/client';
import {
  assertFullyCapableForWorkMutation,
  assertFullyCapableForWorkMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { ASSERT_FULLY_CAPABLE_FOR_WORK_MUTATION } from '../graphql/mutations';
import { COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY } from '../graphql/queries';
import { workCapabilityMessages } from '../messages';
import { refetchFcfwRequests } from '../utils/refetch-fcfw-requests';
import { FormValues } from './index';

interface Args {
  companyId: string;
  infoText: string;
  onSuccess: () => void;
}

export const useSubmit = ({ companyId, infoText, onSuccess }: Args) => {
  const { send } = useNotification();
  const intl = useIntl();

  const [
    assertFullyCapableForWork,
    { loading: submissionLoading, error: submissionError },
  ] = useMutation<
    assertFullyCapableForWorkMutation,
    assertFullyCapableForWorkMutationVariables
  >(ASSERT_FULLY_CAPABLE_FOR_WORK_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY,
        variables: { companyId },
      },
    ],
  });

  const submit = async (values: FormValues) => {
    const { userAccountId, effectiveDate } = values;

    const input = {
      userAccountId,
      companyId,
      effectiveDate,
      infoText,
    };

    try {
      const res = await assertFullyCapableForWork({
        variables: {
          input,
        },
      });

      if (!res.data?.assertFullyCapableForWork) {
        throw new Error();
      }

      await refetchFcfwRequests(companyId);
      onSuccess();

      send({
        type: 'success',
        message: intl.formatMessage(
          workCapabilityMessages.assertWorkCapabilitySuccess,
        ),
      });
    } catch {
      // Do nothing
    }
  };

  return { submit, submissionLoading, submissionError };
};
