import { defaultTheme } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { useIntl } from 'components/formats';
import React from 'react';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { STACK_ID } from '../utils/constants';
import { tickFormatter } from '../utils/tick-formatter';

interface Data {
  name: string;
  remainingCapacity: number;
  value: number;
}

interface Props {
  data: Data[];
  hasSalaryExchangeCapacity: boolean;
}

export const Chart: React.FC<Props> = ({ data, hasSalaryExchangeCapacity }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <ResponsiveContainer width="100%" height={120}>
      <BarChart data={data} layout="vertical">
        <XAxis
          domain={[0, 'dataMax']}
          axisLine={false}
          tickLine={false}
          tickFormatter={value =>
            tickFormatter(value, intl, hasSalaryExchangeCapacity)
          }
          type="number"
        />
        <YAxis
          width={100}
          tickFormatter={value =>
            formatMessage(
              value === 'salaryExchange'
                ? commonMessages.requested
                : commonMessages.capacity,
            )
          }
          tickMargin={15}
          axisLine={false}
          tickLine={false}
          dataKey="name"
          type="category"
        />
        <Bar barSize={16} stackId={STACK_ID} dataKey="value">
          {data.map((a, i) => (
            <Cell
              // @types/rechart doesn't yet have support for array
              // radius input so we have ignore ts-lint errors.
              // eslint-disable-next-line
              // @ts-ignore
              radius={a.remainingCapacity === 0 ? 4 : [4, 0, 0, 4]}
              key={i}
              fill={
                a.name === 'available'
                  ? defaultTheme.primary30
                  : defaultTheme.tertiary70
              }
            />
          ))}
        </Bar>
        <Bar barSize={16} stackId={STACK_ID} dataKey="remainingCapacity">
          {data.map((a, i) => (
            <Cell
              // @types/rechart doesn't yet have support for array
              // radius input so we have ignore ts-lint errors.
              // eslint-disable-next-line
              // @ts-ignore
              radius={a.value !== 0 ? [0, 4, 4, 0] : 4}
              key={i}
              fill={defaultTheme.surfaceVariant}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
