import { companyEmployeesOverviewQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: companyEmployeesOverviewQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: companyEmployeesOverviewQuery;
  },
) => {
  if (
    !previousResult?.company?.memberships ||
    !fetchMoreResult?.company?.memberships
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.memberships;

  return {
    company: {
      ...previousResult.company,
      memberships: {
        __typename: previousResult.company.memberships.__typename,
        edges: [...edges],
        pageInfo,
      },
    },
  };
};
