import { insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory_configuration as Configuration } from 'app/apollo/graphql/types';
import { DetailCell } from 'components/DetailCell';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

import {
  insuranceEventHistoryMessages,
  nordeaPensionManagementTypeMessages,
} from '../messages';
import {
  isOccupationalPensionChange,
  isRetirementAgeChangeConfig,
  isRiskAccidentChangeConfig,
  isRiskHealthcareChangeConfig,
  isRiskLifeChangeConfig,
  isRiskPremiumWaiverChangeConfig,
  isRiskSicknessAndAccidentChangeConfig,
  isRiskSicknessChangeConfig,
} from '../utils';

interface Props {
  configuration: Configuration;
}

export const ConfigurationCell: React.FC<Props> = ({ configuration }) => {
  if (isOccupationalPensionChange(configuration)) {
    return (
      <DetailCell>
        <p>
          <FormattedMessage
            {...insuranceEventHistoryMessages.premium}
            values={{ amount: configuration.premium }}
          />
        </p>
        {configuration.nordeaManagementType && (
          <p>
            <FormattedMessage
              select={configuration.nordeaManagementType}
              messages={nordeaPensionManagementTypeMessages}
            />
          </p>
        )}
      </DetailCell>
    );
  }

  if (isRetirementAgeChangeConfig(configuration)) {
    return (
      <DetailCell>
        <p>
          <FormattedMessage
            {...insuranceEventHistoryMessages.retirementAge}
            values={{ age: configuration.newRetirementAge }}
          />
        </p>
        <p>
          <FormattedMessage
            {...insuranceEventHistoryMessages.insuranceNumber}
            values={{ insuranceNumber: configuration.insuranceNumber }}
          />
        </p>
      </DetailCell>
    );
  }

  if (isRiskAccidentChangeConfig(configuration)) {
    return (
      <DetailCell>
        <p>
          <FormattedMessage
            {...insuranceEventHistoryMessages.level}
            values={{ level: configuration.accidentLevel }}
          />
        </p>
      </DetailCell>
    );
  }

  if (isRiskSicknessAndAccidentChangeConfig(configuration)) {
    return (
      <DetailCell>
        <p>
          <FormattedMessage
            {...insuranceEventHistoryMessages.level}
            values={{ level: configuration.sicknessAndAccidentLevel }}
          />
        </p>
      </DetailCell>
    );
  }

  if (isRiskHealthcareChangeConfig(configuration)) {
    return (
      <DetailCell>
        <p>
          <FormattedMessage
            {...insuranceEventHistoryMessages.level}
            values={{ level: configuration.healthcareLevel }}
          />
        </p>
      </DetailCell>
    );
  }

  if (isRiskLifeChangeConfig(configuration)) {
    return (
      <DetailCell>
        <p>
          <FormattedMessage
            {...insuranceEventHistoryMessages.level}
            values={{ level: configuration.lifeLevel }}
          />
        </p>
      </DetailCell>
    );
  }

  if (isRiskPremiumWaiverChangeConfig(configuration)) {
    return (
      <DetailCell>
        <p>
          <FormattedMessage
            {...insuranceEventHistoryMessages.premium}
            values={{ amount: configuration.monthlyPremium }}
          />
        </p>
      </DetailCell>
    );
  }

  if (isRiskSicknessChangeConfig(configuration)) {
    return (
      <DetailCell>
        <p>
          <FormattedMessage
            {...insuranceEventHistoryMessages.annualSalary}
            values={{ amount: configuration.annualSalary }}
          />
        </p>
        <p>
          <FormattedMessage
            {...insuranceEventHistoryMessages.level}
            values={{ level: configuration.sicknessLevel }}
          />
        </p>
      </DetailCell>
    );
  }

  return <NoValue />;
};
