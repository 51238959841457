import { PaymentMethod } from 'app/apollo/graphql/types';

interface CompanyDetails {
  accountNumber: string | null;
  paymentMethod: PaymentMethod | null;
  clearingSalesOffice?: string | null;
}

/**
 * Checks if any essential company details are missing for generating a proposal.
 * Returns true if any required details are missing, otherwise false.
 *
 * @param {CompanyDetails} values - The company details to check.
 */
export const getHasMissingCompanyDetails = (values: CompanyDetails) => {
  if (!values.paymentMethod || !values.clearingSalesOffice) {
    return true;
  }
  if (values.paymentMethod === PaymentMethod.INVOICE) {
    return false;
  }
  return !values.accountNumber;
};
