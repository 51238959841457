import { Section, SectionHeader } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { Knowledge } from 'features/sme/companies/company/proposals/edit/knowledge';
import { smeKnowledgeMessages } from 'features/sme/messages/sme';
import React from 'react';

export const SmeCompanyProposalKnowledgeRoute: React.FC = () => (
  <Section>
    <SectionHeader>
      <FormattedMessage {...smeKnowledgeMessages.knowledge} />
    </SectionHeader>
    <Knowledge />
  </Section>
);
