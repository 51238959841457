import { FlexSalaryExchangeStatus } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const salaryExchangeAdminStatusMessages = defineMessages({
  willBeActive: {
    id: 'salaryExchangeAdminStatusMessages.willBeActive',
    description: 'salary exchange will be active from {effectiveDate}',
    defaultMessage: `Premie {monthlyPremium} kr/mån gäller från {effectiveDate}.`,
  },
});

export const salaryExchangeAgreementMessages = defineMessages({
  agreementShortTitle: {
    id: 'salaryExchangeAgreementMessages.agreementShortTitle',
    description: 'salary exchange agreement',
    defaultMessage: `Avtal om löneväxling`,
  },
});

export const salaryExchangeMessages = defineMessages({
  grossSalaryDeduction: {
    id: 'salaryExchangeMessages.grossSalaryDeduction',
    defaultMessage: `Bruttolöneavdrag (kr/mån)`,
  },
});

export const salaryExchangeProviderMessages = defineMessages({
  advinans: {
    id: 'salaryExchangeProviderMessages.advinans',
    defaultMessage: 'Advinans Autopilot',
  },
  nordnet: {
    id: 'salaryExchangeProviderMessages.nordnet',
    defaultMessage: 'Nordnet',
  },
  lf: {
    id: 'salaryExchangeProviderMessages.lf',
    defaultMessage: 'Länsförsäkringar',
  },
  collectum: {
    id: 'salaryExchangeProviderMessages.collectum',
    defaultMessage: 'ITP',
  },
  nordea_fund: {
    id: 'salaryExchangeProviderMessages.nordea_fund',
    defaultMessage: 'Nordea Fondförsäkring',
  },
  nordea_trad: {
    id: 'salaryExchangeProviderMessages.nordea_trad',
    defaultMessage: 'Nordea Traditionell försäkring',
  },
});

export const salaryExchangeStatusMessages =
  defineMessages<FlexSalaryExchangeStatus>({
    ACTIVE: {
      id: 'salaryExchangeStatusMessages.ACTIVE',
      defaultMessage: 'Aktiv',
    },
    INACTIVE: {
      id: 'salaryExchangeStatusMessages.INACTIVE',
      defaultMessage: 'Ej aktiv fr.o.m. {effectiveDate}',
    },
  });
