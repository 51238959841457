import { useLazyQuery } from '@apollo/client';
import { Form, Formik } from '@frontend/formik';
import { Button, WizardGrid, WizardSection } from '@frontend/ui';
import { client } from 'app/apollo/client';
import {
  importCompleteUserRecordsQuery,
  importCompleteUserRecordsQueryVariables,
  importPollingQuery,
  importPollingQueryVariables,
  ImportStatus,
} from 'app/apollo/graphql/types';
import { employeesImportMessages } from 'app/messages/employees';
import { formMessages } from 'app/messages/form';
import { HandleHistoryReplace } from 'app/pages/companies/company/employees/import';
import { useQuery } from 'app/utils/use-query';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError, GraphQlErrors } from 'components/GraphQlError';
import { SimpleWizard } from 'components/SimpleWizard';
import { TopLoading } from 'components/TopLoading';
import { Suspense } from 'features/Suspense';
import { useTransitionOverlay } from 'features/transition-overlay';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams as CompanyMatchParams } from '../../..';
import {
  ConfirmFields,
  FormValues,
  initialValues,
  validationSchema,
} from './components/ConfirmFields';
import { ImportEmptyState } from './components/EmptyState';
import { hasStatusMessage, StatusMessage } from './components/StatusMessage';
import { ImportErrorsTable } from './errors-table';
import {
  IMPORT_COMPLETE_USER_RECORDS_QUERY,
  IMPORT_POLLING_QUERY,
} from './graphql/queries';
import { InactiveEmployments } from './inactive-employments';
import { MissingEmployments } from './missing-employments';
import { OverlappingEmployments } from './overlapping-employments';
import { ImportPreviewTable } from './preview-table';
import { ImportSummaryCards } from './summary-cards';
import { CHANGES_ONLY } from './utils/changes-only';
import { formatProgress } from './utils/format-progress';
import { useSubmit } from './utils/use-submit';

interface Props {
  onPrevious: HandleHistoryReplace;
  title: React.ReactNode;
}

const IMPORT_POLL_INTERVAL = 2000;

export interface MatchParams extends CompanyMatchParams {
  importId: string;
}

export const Preview: React.FC<Props> = ({ title, onPrevious }) => {
  const [isPolling, setIsPolling] = useState(true);
  const intl = useIntl();
  const { formatMessage } = intl;

  const {
    params: { companyId, importId },
  } = useRouteMatch<MatchParams>();

  const { startTransition, updateTransition, clearTransition, transition } =
    useTransitionOverlay();

  const [fetchCompleteUserRecords, { error: completeUserRecordError }] =
    useLazyQuery<
      importCompleteUserRecordsQuery,
      importCompleteUserRecordsQueryVariables
    >(IMPORT_COMPLETE_USER_RECORDS_QUERY, {
      variables: {
        id: importId,
      },
      errorPolicy: 'all',
    });

  const {
    data: pollingData,
    error: pollingError,
    stopPolling,
    startPolling,
  } = useQuery<importPollingQuery, importPollingQueryVariables>(
    IMPORT_POLLING_QUERY,
    {
      errorPolicy: 'all',
      variables: { id: importId, statuses: CHANGES_ONLY },
    },
  );

  const { submissionCalled, submit, submissionError } = useSubmit({
    onCompleted: () => startPolling(IMPORT_POLL_INTERVAL),
    onError: clearTransition,
  });

  const hasPendingChanges =
    !!pollingData?.import?.records?.totalCount &&
    pollingData.import.status === ImportStatus.PENDING;
  const fileUrl = pollingData?.import?.fileUrl;
  const progress = pollingData?.import?.progress;
  const importStatus = pollingData?.import?.status;

  const handleStatusChange = async () => {
    if (importStatus === ImportStatus.PROCESSING) {
      startPolling(IMPORT_POLL_INTERVAL);
      startTransition({
        illustration: 'import-file',
        title: employeesImportMessages.generateChangesTitle,
        description: formatMessage(
          employeesImportMessages.generateChangeDescription,
        ),
        progress: formatProgress(progress?.preview),
      });
    }

    if (
      importStatus &&
      importStatus !== ImportStatus.WORKING &&
      importStatus !== ImportStatus.PROCESSING
    ) {
      stopPolling();
      clearTransition();
      setIsPolling(false);
    }

    if (submissionCalled && importStatus === ImportStatus.COMPLETE) {
      const { data: completeUserRecordsData } =
        await fetchCompleteUserRecords();

      if (!completeUserRecordsData?.import?.records?.edges?.length) {
        return;
      }

      const updatedMembershipIds =
        completeUserRecordsData.import.records.edges.map(
          ({ node }) => node.id?.membershipId,
        );

      updatedMembershipIds.forEach(membershipId => {
        if (!membershipId) {
          return;
        }

        client.cache.evict({
          id: `Membership:${membershipId}`,
        });
      });

      client.cache.evict({
        id: `Import:${importId}`,
      });
      client.cache.gc();
    }
  };

  useEffect(() => {
    handleStatusChange();
  }, [importStatus]);

  useEffect(() => {
    if (importStatus === ImportStatus.PROCESSING) {
      updateTransition({ progress: formatProgress(progress?.preview) });
    }
    if (importStatus === ImportStatus.WORKING) {
      updateTransition({ progress: formatProgress(progress?.runner) });
    }
  }, [progress]);

  const handleSubmit = (values: FormValues) => {
    startTransition({
      illustration: 'import-file',
      title: employeesImportMessages.updateLoadingTitle,
      description: formatMessage(
        employeesImportMessages.updateLoadingDescription,
      ),
      progress: formatProgress(progress?.runner),
    });
    submit(values);
  };

  if (transition != null) {
    return null;
  }

  // As long as IMPORT_POLLING_QUERY in a polling state
  // we return null to defer child component queries
  if (isPolling) {
    return null;
  }

  const error = submissionError ?? pollingError ?? completeUserRecordError;

  const withForm = (content: React.ReactNode) => <Form>{content}</Form>;

  return (
    <Formik<FormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      {({ isValid, isValidating }) => (
        <SimpleWizard
          fullBleed
          title={title}
          parentLink={`/companies/${companyId}/employees/imports`}
          actions={
            <>
              <Button
                text
                onClick={() =>
                  onPrevious({
                    importId,
                  })
                }
                disabled={
                  isValidating ||
                  (importStatus !== ImportStatus.PENDING &&
                    importStatus !== ImportStatus.NO_CHANGES)
                }
              >
                <FormattedMessage {...formMessages.goBack} />
              </Button>
              <Button
                filled
                type="submit"
                disabled={!isValid || importStatus !== ImportStatus.PENDING}
                loading={isValidating}
              >
                <FormattedMessage {...formMessages.approve} />
              </Button>
            </>
          }
          withForm={withForm}
        >
          {(error || hasStatusMessage(importStatus) || hasPendingChanges) && (
            <WizardGrid>
              <WizardSection>
                {error && <GraphQlError error={error} />}
                {importStatus && (
                  <StatusMessage status={importStatus} fileUrl={fileUrl} />
                )}
                {hasPendingChanges && (
                  <DescriptionWrapper>
                    <FormattedMessage
                      {...employeesImportMessages.previewPageDescription}
                    />
                  </DescriptionWrapper>
                )}
              </WizardSection>
            </WizardGrid>
          )}
          <Suspense batch fallback={<TopLoading />}>
            {({ errors }) => (
              <>
                {importStatus === ImportStatus.NO_CHANGES && (
                  <WizardGrid>
                    <ImportEmptyState
                      action={() =>
                        onPrevious({
                          importId,
                        })
                      }
                    />
                  </WizardGrid>
                )}
                {errors && <GraphQlErrors errors={errors} />}
                <ImportSummaryCards importId={importId} />
                <WizardSection>
                  <OverlappingEmployments importId={importId} />
                  <MissingEmployments importId={importId} />
                  <InactiveEmployments importId={importId} />
                </WizardSection>
                <ImportErrorsTable importId={importId} />
                <ImportPreviewTable importId={importId} />
                {hasPendingChanges && (
                  <WizardGrid>
                    <ConfirmFields />
                  </WizardGrid>
                )}
              </>
            )}
          </Suspense>
        </SimpleWizard>
      )}
    </Formik>
  );
};
