import { NumberField } from '@frontend/formik';
import { Subsection, SubsectionHeader } from '@frontend/ui';
import { membershipDetailsQuery_membership_Membership as Membership } from 'app/apollo/graphql/types';
import { wellnessBenefitMessages } from 'app/messages/benefits';
import { commonMessages, suffixMessages } from 'app/messages/common';
import { workInformationMessages } from 'app/messages/employees';
import { BigNumber } from 'bignumber.js';
import { FormattedMessage, useIntl } from 'components/formats';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';

export interface CompensationDataFormValues {
  companyCarMonthlyValue: number | null;
  fitnessContributionBalance: number | null;
  incomeTaxTable: number | null;
}

export const compensationInitialValues = (
  membership: Membership,
): CompensationDataFormValues => ({
  incomeTaxTable: membership.incomeTaxTable,
  companyCarMonthlyValue: membership.companyCarMonthlyValue
    ? new BigNumber(membership.companyCarMonthlyValue)
        .decimalPlaces(0)
        .toNumber()
    : null,
  fitnessContributionBalance: membership.fitnessContributionBalance
    ? new BigNumber(membership.fitnessContributionBalance)
        .decimalPlaces(0)
        .toNumber()
    : null,
});

interface Props {
  wellnessDisabled?: boolean;
}

export const CompensationFields: React.FC<Props> = ({ wellnessDisabled }) => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  return (
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...commonMessages.compensation} />
      </SubsectionHeader>
      <NumberField
        dense
        name="incomeTaxTable"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage {...workInformationMessages.incomeTaxTableLabel} />
        }
        locale={locale}
      />
      <NumberField
        dense
        name="companyCarMonthlyValue"
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.companyCarMonthlyValueLabel}
          />
        }
        affix={formatMessage(suffixMessages.krPerMonth)}
        locale={locale}
      />
      <SubsectionHeader>
        <FormattedMessage {...wellnessBenefitMessages.wellness} />
      </SubsectionHeader>
      <p>
        <FormattedMessage
          {...workInformationMessages.fitnessContributionBalanceDescText}
        />
      </p>
      <NumberField
        dense
        name="fitnessContributionBalance"
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.fitnessContributionBalanceLabel}
          />
        }
        affix={formatMessage(suffixMessages.kr)}
        helperText={
          <FormattedMessage
            {...workInformationMessages.fitnessContributionBalanceHelperText}
          />
        }
        disabled={wellnessDisabled}
        locale={locale}
      />
    </Subsection>
  );
};
