import gql from 'graphql-tag';

export const EXECUTE_PROPOSAL_MUTATION = gql`
  mutation executeProposalMutation($input: ExecuteProposalInput!) {
    executeProposal(input: $input) {
      id
      name
      status
    }
  }
`;
