import {
  CheckboxField,
  DatePickerField,
  Form,
  Formik,
  NumberField,
  SelectField,
  TextField,
} from '@frontend/formik';
import {
  Button,
  Grid,
  WizardContentCard,
  WizardSection,
  WizardSectionHeader,
} from '@frontend/ui';
import {
  CompanySize,
  createCompanyQuery,
  EAGruppAgreementType,
  Language,
  NLPFundFeeDiscountType,
  PaymentMethod,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell50 } from 'components/GridCell';
import { SimpleWizard } from 'components/SimpleWizard';
import { TopLoading } from 'components/TopLoading';
import {
  ClearingFieldType,
  SelectClearingField,
} from 'features/sme/companies/company/select-clearing';
import React from 'react';
import { useHistory } from 'react-router';
import { getSelectComponentMonthOptions } from 'utils/get-select-month-options';

import { companyMessages, companySizeMessages } from '../messages';
import { CREATE_COMPANY_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';
import { validationSchema } from './utils/validations';

const DEFAULT_ACCOUNT_CLOSURE_MONTH = '12';

export interface FormValues {
  accountClosureMonth: string;
  clearingCode: ClearingFieldType;
  createEaGrupp: boolean;
  createEaTjp: boolean;
  createNordea: boolean;
  eaExtendedFcfw: boolean;
  eaStartDate: string | null;
  email: string;
  firstName: string;
  isOwner: boolean;
  language: Language;
  lastName: string;
  nodeAdminPid: string | null;
  nordeaAnnualFeeFund: number | null;
  nordeaAnnualFeeGuarantee: number | null;
  nordeaCapitalFeeFund: number | null;
  nordeaCapitalFeeGuarantee: number | null;
  nordeaStartDate: string | null;
  paymentMethod: PaymentMethod.INVOICE;
  phone: string;
  pid: string;
  registrationNumber: string;
  salesRep: string;
  startDate: string;
  eaCompanySize?: CompanySize;
  eaGruppAgreementType?: EAGruppAgreementType;
  nordeaFundFeeDiscountType?: NLPFundFeeDiscountType;
}

export const initialValues: FormValues = {
  accountClosureMonth: DEFAULT_ACCOUNT_CLOSURE_MONTH,
  clearingCode: null,
  language: Language.SV_SE,
  eaExtendedFcfw: false,
  isOwner: false,
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  pid: '',
  nordeaCapitalFeeFund: null,
  nordeaAnnualFeeFund: null,
  nordeaCapitalFeeGuarantee: null,
  nordeaAnnualFeeGuarantee: null,
  registrationNumber: '',
  createNordea: false,
  startDate: new Date().toISOString().substring(0, 10),
  createEaGrupp: false,
  createEaTjp: false,
  eaStartDate: '',
  nordeaStartDate: '',
  // This option is hard coded to invoice for all non-SME companies.
  // We do not have a corresponding form field for this.
  paymentMethod: PaymentMethod.INVOICE,
  salesRep: '',
  nodeAdminPid: '',
};

export const Create: React.FC = () => {
  const intl = useIntl();
  const { submit, submissionError } = useSubmit();
  const { formatMessage } = intl;
  const history = useHistory();

  const { loading, error, data } = useQuery<createCompanyQuery>(
    CREATE_COMPANY_QUERY,
    { errorPolicy: 'all' },
  );

  const handleClose = () => history.push('/companies');

  const withForm = (content: React.ReactNode) => <Form>{content}</Form>;

  if (loading) {
    return <TopLoading />;
  }

  const monthOptions = getSelectComponentMonthOptions({ intl });

  const salesRepOptions =
    data?.nlpSalesReps.map(({ id, firstName, lastName, externalId }) => ({
      label: `${firstName} ${lastName} (${externalId})`,
      value: id,
    })) ?? [];

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ values, isValid, isSubmitting }) => (
        <SimpleWizard
          actions={
            <>
              <Button text onClick={handleClose}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>
              <Button
                filled
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
              >
                <FormattedMessage {...companyMessages.addCompany} />
              </Button>
            </>
          }
          offWhite
          parentLink="/companies"
          title={<FormattedMessage {...companyMessages.addCompany} />}
          withForm={withForm}
        >
          <WizardContentCard
            header={
              <FormattedMessage {...companyMessages.customerInformation} />
            }
          >
            {error && <GraphQlError error={error} />}
            <TextField
              label={formatMessage(companyMessages.registrationNumber)}
              name="registrationNumber"
              dense
              required
            />
            <DatePickerField
              label={formatMessage(companyMessages.startDate)}
              name="startDate"
              dense
              required
              value={values.startDate}
            />
            <SelectField
              label={formatMessage(companyMessages.companyLanguage)}
              name="language"
              dense
              required
              options={Object.keys(Language).map(k => ({
                value: k,
                label: k,
              }))}
            />
            <WizardSection>
              <WizardSectionHeader>
                <FormattedMessage {...companyMessages.administrator} />
              </WizardSectionHeader>
              <CheckboxField
                label={formatMessage(companyMessages.isOwner)}
                name="isOwner"
              />
              <Grid>
                <GridCell50>
                  <TextField
                    label={formatMessage(companyMessages.firstName)}
                    name="firstName"
                    dense
                    required
                  />
                </GridCell50>
                <GridCell50>
                  <TextField
                    label={formatMessage(companyMessages.lastName)}
                    name="lastName"
                    dense
                    required
                  />
                </GridCell50>
              </Grid>
              <TextField
                label={formatMessage(companyMessages.pid)}
                name="pid"
                dense
                required
              />
              <TextField
                label={formatMessage(companyMessages.email)}
                name="email"
                dense
              />
              <TextField
                label={formatMessage(companyMessages.phone)}
                name="phone"
                dense
              />
            </WizardSection>
            <WizardSection>
              <WizardSectionHeader>
                <FormattedMessage {...companyMessages.nodeAdmin} />
              </WizardSectionHeader>
              <TextField
                label={formatMessage(companyMessages.pid)}
                name="nodeAdminPid"
                dense
              />
            </WizardSection>
            <WizardSection>
              <WizardSectionHeader>
                <FormattedMessage {...companyMessages.nordeaAgreement} />
              </WizardSectionHeader>
              <CheckboxField
                label={formatMessage(companyMessages.createNordea)}
                name="createNordea"
                checked={values.createNordea}
              />
              <SelectField
                name="salesRep"
                dense
                options={salesRepOptions}
                label={formatMessage(companyMessages.nordeaSalesRep)}
                disabled={!values.createNordea}
                required
              />
              <SelectClearingField
                name="clearingCode"
                disabled={!values.createNordea}
                required
              />
              <SelectField
                name="accountClosureMonth"
                dense
                disabled={!values.createNordea}
                required
                options={monthOptions}
                label={formatMessage(companyMessages.accountClosureMonth)}
              />
              <SelectField
                label={formatMessage(companyMessages.nordeaFundFeeDiscountType)}
                name="nordeaFundFeeDiscountType"
                dense
                options={Object.keys(NLPFundFeeDiscountType).map(k => ({
                  value: k,
                  label: k,
                }))}
                disabled={!values.createNordea}
              />
              <DatePickerField
                label={formatMessage(companyMessages.nordeaStartDate)}
                name="nordeaStartDate"
                dense
                required
                disabled={!values.createNordea}
              />
              <NumberField
                dense
                required
                disabled={!values.createNordea}
                decimalScale={4}
                name="nordeaCapitalFeeFund"
                label={formatMessage(companyMessages.nordeaCapitalFeeFund)}
              />
              <NumberField
                dense
                required
                disabled={!values.createNordea}
                decimalScale={4}
                name="nordeaAnnualFeeFund"
                label={formatMessage(companyMessages.nordeaAnnualFeeFund)}
              />
              <NumberField
                dense
                required
                disabled={!values.createNordea}
                decimalScale={4}
                name="nordeaCapitalFeeGuarantee"
                label={formatMessage(companyMessages.nordeaCapitalFeeGuarantee)}
              />
              <NumberField
                dense
                required
                disabled={!values.createNordea}
                decimalScale={4}
                name="nordeaAnnualFeeGuarantee"
                label={formatMessage(companyMessages.nordeaAnnualFeeGuarantee)}
              />
            </WizardSection>
            <WizardSection>
              <WizardSectionHeader>
                <FormattedMessage {...companyMessages.eaAgreement} />
              </WizardSectionHeader>
              <CheckboxField
                label={formatMessage(companyMessages.createEaGrupp)}
                name="createEaGrupp"
                checked={values.createEaGrupp}
              />
              <CheckboxField
                label={formatMessage(companyMessages.createEaTjp)}
                name="createEaTjp"
                checked={values.createEaTjp}
              />
              <SelectField
                label={formatMessage(companyMessages.companySize)}
                name="eaCompanySize"
                options={Object.keys(CompanySize).map(k => ({
                  value: k,
                  label: formatMessage({
                    messages: companySizeMessages,
                    select: k,
                  }),
                }))}
                required
                dense
                disabled={!values.createEaGrupp && !values.createEaTjp}
              />
              <SelectField
                label={formatMessage(companyMessages.eaGruppAgreementType)}
                name="eaGruppAgreementType"
                options={Object.keys(EAGruppAgreementType).map(k => ({
                  value: k,
                  label: k,
                }))}
                required
                dense
                disabled={!values.createEaGrupp}
              />
              <CheckboxField
                label={formatMessage(companyMessages.eaExtendedFcfw)}
                name="eaExtendedFcfw"
                disabled={!values.createEaGrupp && !values.createEaTjp}
              />
              <DatePickerField
                label={formatMessage(companyMessages.eaStartDate)}
                name="eaStartDate"
                dense
                required
                disabled={!values.createEaGrupp && !values.createEaTjp}
              />
            </WizardSection>
            {submissionError && <GraphQlError error={submissionError} />}
          </WizardContentCard>
        </SimpleWizard>
      )}
    </Formik>
  );
};
