import { defineMessages } from 'react-intl';

export const loginMessages = defineMessages({
  welcome: {
    id: 'login.welcome',
    defaultMessage: 'Välkommen till Backstage',
  },
  googleSSO: {
    id: 'login.googleSSO',
    defaultMessage: 'Logga in med Googlekonto',
  },
  nordeaSSO: {
    id: 'login.nordeaSSO',
    defaultMessage: 'Logga in med Nordeakonto',
  },
  title: {
    id: 'login.title',
    defaultMessage: 'Logga in',
  },
  personalIdentityNumberHelperText: {
    id: 'loginMessages.personalIdentityNumberHelperText',
    defaultMessage: '10 eller 12 siffror',
  },
});
