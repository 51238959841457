import gql from 'graphql-tag';

export const EMPLOYEE_SALARY_EXCHANGE_QUERY = gql`
  query employeeSalaryExchangeQuery(
    $userAccountId: ID!
    $companyId: ID!
    $statuses: [FlexSalaryExchangeRequestStatus!]
  ) {
    salaryExchanges(userAccountId: $userAccountId, companyId: $companyId) {
      edges {
        node {
          id
          deductionValue
          premium
          provider
          status
          effectiveDate
          violated
          insuranceNumber
        }
      }
    }
    salaryExchangeRequests(
      userAccountId: $userAccountId
      companyId: $companyId
      statuses: $statuses
    ) {
      edges {
        node {
          id
          premium
          effectiveDate
          status
        }
      }
    }
    salaryExchangeRequestsHistory(
      userAccountId: $userAccountId
      companyId: $companyId
      statuses: $statuses
    ) {
      edges {
        node {
          id
          userAccountId
          companyId
          deductionValue
          premium
          status
          requestedAt
          effectiveDate
        }
      }
    }
  }
`;
