import { addCircle } from '@frontend/ui/icons';
import { noteAuthorsQuery_noteAuthors_Author as NoteAuthor } from 'app/apollo/graphql/types';
import { noteMessages } from 'app/messages/notes';
import { AssistChip } from 'components/AssistChip';
import { FormattedMessage } from 'components/formats';
import React from 'react';

import { Filters } from '../Filters';

interface Props {
  noteAuthors: Array<NoteAuthor>;
}

export const NotesChips: React.FC<Props> = ({ noteAuthors }) => (
  <>
    <AssistChip
      text={<FormattedMessage {...noteMessages.addNote} />}
      leadingIcon={addCircle}
      to="notes/add"
    />
    <Filters noteAuthors={noteAuthors} />
  </>
);
