import gql from 'graphql-tag';

export const DELETE_REPRESENTATIVE_MUTATION = gql`
  mutation deleteRepresentativeMutation(
    $input: DeleteCompanyRepresentativeInput!
  ) {
    deleteCompanyRepresentative(input: $input) {
      id
      representatives {
        id
      }
    }
  }
`;
