import {
  Card,
  SnapshotCardLayout,
  SnapshotCardLayoutProps,
} from '@frontend/ui';
import React from 'react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  height: 100%;
`;

export const SnapshotCard: React.FC<SnapshotCardLayoutProps> = props => (
  <StyledCard filled rounded>
    <SnapshotCardLayout {...props} filled />
  </StyledCard>
);
