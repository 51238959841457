import { overlappingEmploymentsQuery } from 'app/apollo/graphql/types';

export const updateOverlappingEmployments = (
  previousResult: overlappingEmploymentsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: overlappingEmploymentsQuery;
  },
): overlappingEmploymentsQuery => {
  if (
    !previousResult?.import?.analysis?.overlappingEmployments ||
    !fetchMoreResult?.import?.analysis?.overlappingEmployments
  ) {
    return previousResult;
  }

  return fetchMoreResult;
};
