import gql from 'graphql-tag';

export const UPDATE_SME_COMPANY_MUTATION = gql`
  mutation updateSmeCompanyMutation($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      clientMutationId
      company {
        id
      }
    }
  }
`;
