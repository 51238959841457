import { RiskBucket } from 'app/apollo/graphql/types';

/**
 * This array lists the risk bucket levels for a proposal,
 * sorted for display purposes rather than in the alphabetical order of the enum values.
 */
export const RISK_BUCKET_LEVELS: RiskBucket[] = [
  RiskBucket.NEW,
  RiskBucket.SMALL,
  RiskBucket.LEVEL_1,
  RiskBucket.LEVEL_2,
  RiskBucket.LEVEL_3,
  RiskBucket.LEVEL_4,
  RiskBucket.LEVEL_5,
  RiskBucket.LEVEL_6,
  RiskBucket.LEVEL_7,
];
