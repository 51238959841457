import { useMutation } from '@apollo/client';
import {
  deleteNoteMutation,
  deleteNoteMutationVariables,
} from 'app/apollo/graphql/types';
import { noteMessages } from 'app/messages/notes';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';

import { DELETE_NOTE_MUTATION } from './graphql/mutations';

export const useDelete = () => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const { confirm } = useConfirm();

  const [deleteNote, { loading, error }] = useMutation<
    deleteNoteMutation,
    deleteNoteMutationVariables
  >(DELETE_NOTE_MUTATION);

  const _delete = async (noteId: string) => {
    const confirmed = await confirm({
      description: formatMessage(noteMessages.confirmDeleteNote),
    });

    if (!confirmed) {
      return;
    }

    await deleteNote({
      update: cache => {
        cache.evict({
          id: `Note:${noteId}`,
        });
        cache.gc();
      },
      variables: { input: { id: noteId } },
    });

    send({
      message: formatMessage(noteMessages.deleteNoteSuccess),
      type: 'success',
    });
  };
  return { _delete, loading, error };
};
