export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M12.1 21q.2 0 .35-.05.15-.05.35-.25l8.325-8.35q.425-.425.65-.938Q22 10.9 22 10.4q0-.725-.212-1.275-.213-.55-.663-1L16.9 3.875q-.45-.45-1-.663Q15.35 3 14.7 3q-.525 0-1.05.212-.525.213-.95.613L17.175 8.3q.325.325.45.7.125.375.125.725 0 .875-.6 1.437-.6.563-1.4.563-.375 0-.75-.163-.375-.162-.65-.437L10.6 7.35l-5.575 5.575q-.15.15-.225.325-.075.175-.075.375 0 .425.288.712.287.288.712.288.25 0 .425-.075t.3-.2l4.6-4.625.725.7L7.15 15.05q-.15.15-.225.325-.075.175-.075.375 0 .4.3.7.3.3.7.3.225 0 .4-.075.175-.075.325-.225l4.625-4.6.7.7-4.625 4.625q-.125.125-.2.287-.075.163-.075.413 0 .425.288.712.287.288.712.288.2 0 .375-.075t.325-.225l4.6-4.625.725.725L11.4 19.3q-.15.15-.225.337-.075.188-.075.363 0 .425.288.712.287.288.712.288Zm-8.575-8 7.05-7.05 4.5 4.45q.175.175.325.237.15.063.375.063.4 0 .7-.3.3-.3.3-.7 0-.2-.075-.375T16.475 9L11.35 3.875q-.5-.5-1.025-.687Q9.8 3 9.125 3 8.6 3 8.05 3.237q-.55.238-.95.638l-4.225 4.25Q2.5 8.5 2.25 9.05T2 10.25q0 .6.225 1.15.225.55.65.95Z',
      },
    },
  ],
};
