import { ContentContainer } from '@frontend/ui';
import { checkCircle, mail } from '@frontend/ui/icons';
import type { ThemeColor } from '@frontend/ui/theme';
import {
  ProposalAdviceOption,
  smeCompanyProposalLayoutQuery,
  smeCompanyProposalLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { SME_COMPANY_PROPOSAL_LAYOUT_QUERY } from 'app/queries';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { RoutedTab } from 'components/RoutedTabBar';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Page } from 'features/page';
import { Autosave } from 'features/sme/companies/company/proposals/edit/contexts/autosave';
import { autosaveStatusMessages } from 'features/sme/companies/company/proposals/edit/contexts/autosave/messages';
import { STATUS_ICON } from 'features/sme/companies/company/proposals/edit/contexts/autosave/utils/constants';
import {
  getIsValid,
  Validity,
} from 'features/sme/companies/company/proposals/edit/contexts/autosave/utils/get-validity';
import {
  EditProposalNameModal,
  getEditProposalNameAction,
} from 'features/sme/companies/company/proposals/edit/name';
import { useProposalReadonly } from 'features/sme/companies/company/proposals/utils/use-proposal-readonly';
import {
  smeBenefitsMessages,
  smeCompanyProposalsMessages,
  smeCompilationMessages,
  smeEmployeesMessages,
  smeFeesMessages,
  smeGenerateProposalMessages,
  smeKnowledgeMessages,
  smeNeedsMessages,
  smeSummaryMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router';

import { MatchParams as SmeCompanyMatchParams } from '..';
import { SmeCompanyProposalBenefitsRoute } from './benefits';
import { SmeCompanyProposalBenefitRoute } from './benefits/benefit';
import { SmeCompanyProposalBenefitsCreateRoutes } from './benefits/create';
import { SmeCompanyProposalCompilationRoute } from './compilation';
import { SmeCompanyProposalEmployeeRoutes } from './employee';
import { SmeCompanyProposalEmployeesRoute } from './employees';
import { SmeCompanyProposalEmployeesCreateRoute } from './employees/create';
import { SmeCompanyProposalEmployeesFcfwRoute } from './employees/fcfw';
import { SmeCompanyProposalFeesRoute } from './fees';
import { SmeCompanyProposalGenerateRoute } from './generate';
import { SmeCompanyProposalKnowledgeRoute } from './knowledge';
import { SmeCompanyProposalNeedsRoute } from './needs';
import { SmeCompanyProposalSummaryRoute } from './summary';

interface Tab extends RoutedTab {
  path: string;
}

interface GetTabsParams {
  skipAdviceTabs: boolean;
  validity?: Validity;
}

export interface MatchParams extends SmeCompanyMatchParams {
  proposalId: string;
}

export const SmeCompanyProposalRoutes: React.FC = () => {
  const { formatMessage } = useIntl();
  const match = useRouteMatch<MatchParams>();
  const { params } = match;
  const location = useLocation();
  const isReadonly = useProposalReadonly();

  const { data, loading, error } = useQuery<
    smeCompanyProposalLayoutQuery,
    smeCompanyProposalLayoutQueryVariables
  >(SME_COMPANY_PROPOSAL_LAYOUT_QUERY, {
    variables: { id: params.proposalId },
  });

  const neutralColor: ThemeColor = 'neutral80';
  const green: ThemeColor = 'green';

  const getTabs = ({ skipAdviceTabs, validity }: GetTabsParams): Tab[] => [
    ...(!skipAdviceTabs
      ? [
          {
            title: smeNeedsMessages.needs,
            url: `${match.url}/needs`,
            path: `${match.path}/needs`,
            trailingIcon: checkCircle,
            iconColor: !validity?.validNeed ? neutralColor : undefined,
            color: validity?.validNeed ? green : undefined,
          },
        ]
      : []),
    ...(!skipAdviceTabs
      ? [
          {
            title: smeKnowledgeMessages.knowledge,
            url: `${match.url}/knowledge`,
            path: `${match.path}/knowledge`,
            trailingIcon: checkCircle,
            iconColor: !validity?.validProductKnowledge
              ? neutralColor
              : undefined,
            color: validity?.validProductKnowledge ? green : undefined,
          },
        ]
      : []),
    {
      title: smeBenefitsMessages.benefits,
      url: `${match.url}/benefits`,
      path: `${match.path}/benefits`,
      trailingIcon: checkCircle,
      iconColor: !validity?.validBenefits ? neutralColor : undefined,
      color: validity?.validBenefits ? green : undefined,
    },
    {
      title: smeEmployeesMessages.employees,
      url: `${match.url}/employees`,
      path: `${match.path}/employees`,
      trailingIcon: checkCircle,
      iconColor: !validity?.validEmployees ? neutralColor : undefined,
      color: validity?.validEmployees ? green : undefined,
    },
    {
      title: smeFeesMessages.fees,
      url: `${match.url}/fees`,
      path: `${match.path}/fees`,
      trailingIcon: checkCircle,
      iconColor: !validity?.validFees ? neutralColor : undefined,
      color: validity?.validFees ? green : undefined,
    },
    {
      title: smeCompilationMessages.summation,
      url: `${match.url}/compilation`,
      path: `${match.path}/compilation`,
      trailingIcon: checkCircle,
      iconColor: !validity?.validCompilation ? neutralColor : undefined,
      color: validity?.validCompilation ? green : undefined,
    },
    ...(!skipAdviceTabs
      ? [
          {
            title: smeSummaryMessages.summary,
            url: `${match.url}/summary`,
            path: `${match.path}/summary`,
            trailingIcon: checkCircle,
            iconColor: !validity?.validSummary ? neutralColor : undefined,
            color: validity?.validSummary ? green : undefined,
          },
        ]
      : []),
  ];

  if (loading) {
    return <TopLoading />;
  }

  const parentLink = `/sme/${params.companyId}`;

  if (!data?.proposal) {
    return <EmptyStatePage error={error} parentLink={parentLink} />;
  }

  const { name, adviceWaived } = data.proposal;

  const skipAdviceTabs = adviceWaived !== ProposalAdviceOption.ADVICE_REQUIRED;

  const firstTabUrl = getTabs({ skipAdviceTabs })[0]?.url;

  if (!firstTabUrl) {
    return <EmptyStatePage parentLink={parentLink} />;
  }

  return (
    <NavigationAnchor
      title={formatMessage(smeCompanyProposalsMessages.editProposal, {
        name,
      })}
      url={match.url}
    >
      <Switch>
        <Redirect exact from={match.url} to={firstTabUrl} />
        <Route exact path={getTabs({ skipAdviceTabs }).map(({ path }) => path)}>
          <Autosave configuration={{ skipAdviceData: skipAdviceTabs }}>
            {({ data: validityData, status }) => (
              <Page
                fixed
                status={{
                  title: (
                    <FormattedMessage
                      select={status}
                      messages={autosaveStatusMessages}
                    />
                  ),
                  icon: STATUS_ICON[status],
                }}
                title={name}
                tabs={getTabs({
                  skipAdviceTabs,
                  validity: getIsValid(validityData),
                })}
                parentLink={parentLink}
                actions={[
                  getEditProposalNameAction({
                    location,
                    isReadonly,
                    proposalId: params.proposalId,
                  }),
                  {
                    to: `${match.url}/generate`,
                    text: (
                      <FormattedMessage
                        {...smeGenerateProposalMessages.generateProposal}
                      />
                    ),
                    leadingIcon: mail,
                    disabled:
                      isReadonly ||
                      !validityData?.proposal?.validity.validProposal.isValid,
                  },
                ]}
              >
                <ContentContainer>
                  <EditProposalNameModal />
                  <Route
                    exact
                    path={`${match.path}/needs`}
                    component={SmeCompanyProposalNeedsRoute}
                  />
                  <Route
                    exact
                    path={`${match.path}/knowledge`}
                    component={SmeCompanyProposalKnowledgeRoute}
                  />
                  <Route
                    exact
                    path={`${match.path}/benefits`}
                    component={SmeCompanyProposalBenefitsRoute}
                  />
                  <Route
                    exact
                    path={`${match.path}/employees`}
                    component={SmeCompanyProposalEmployeesRoute}
                  />
                  <Route
                    exact
                    path={`${match.path}/fees`}
                    component={SmeCompanyProposalFeesRoute}
                  />
                  <Route
                    exact
                    path={`${match.path}/compilation`}
                    component={SmeCompanyProposalCompilationRoute}
                  />
                  <Route
                    exact
                    path={`${match.path}/summary`}
                    component={SmeCompanyProposalSummaryRoute}
                  />
                </ContentContainer>
              </Page>
            )}
          </Autosave>
        </Route>
        <Route
          path={`${match.path}/benefits/create`}
          component={SmeCompanyProposalBenefitsCreateRoutes}
        />
        <Route
          path={`${match.path}/benefits/:benefitId`}
          component={SmeCompanyProposalBenefitRoute}
        />
        <Route
          exact
          path={`${match.path}/employees/create`}
          component={SmeCompanyProposalEmployeesCreateRoute}
        />
        <Route
          exact
          path={`${match.path}/employees/fcfw`}
          component={SmeCompanyProposalEmployeesFcfwRoute}
        />
        <Route
          path={`${match.path}/employees/:employeeId`}
          component={SmeCompanyProposalEmployeeRoutes}
        />
        <Route
          path={`${match.path}/generate`}
          component={SmeCompanyProposalGenerateRoute}
        />
        <Route component={EmptyStatePage} />
      </Switch>
    </NavigationAnchor>
  );
};
