import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

import { importRecordDetails } from '../../graphql/fragments';

export const IMPORT_ERRORS_TABLE_QUERY = gql`
  query importErrorsTableQuery(
    $id: ID!
    $first: Int
    $before: String
    $after: String
    $last: Int
  ) {
    import(id: $id) {
      id
      records(
        first: $first
        before: $before
        after: $after
        last: $last
        onlyWithErrors: true
      ) {
        totalCount
        edges {
          node {
            ...importRecordDetails
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
      }
    }
  }
  ${importRecordDetails}
  ${pageInfoDetails}
`;
