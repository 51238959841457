import gql from 'graphql-tag';

export const UPDATE_COMPANY_DETAILS_MUTATION = gql`
  mutation updateCompanyDetailsMutation($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        id
        registrationNumber
        name
        displayName
        logoUrl
        email
        phone
        hasJobOffers
        newsletterOptOut
        address {
          line1
          line2
          co
          postalCode
          city
        }
        emailLoginAllowed
      }
    }
  }
`;

export const CREATE_PACKAGE_MUTATION = gql`
  mutation createBenefitPackageMutation($input: CreateBenefitPackageInput!) {
    createBenefitPackage(input: $input) {
      benefitPackage {
        id
        name
      }
    }
  }
`;

export const UPDATE_BENEFIT_PACKAGE_NAME_MUTATION = gql`
  mutation updateBenefitPackageNameMutation(
    $input: UpdateBenefitPackageInput!
  ) {
    updateBenefitPackage(input: $input) {
      benefitPackage {
        id
        name
      }
    }
  }
`;
