import { ApolloError } from '@apollo/client';
import { CheckboxGroupField, Form } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { commonBenefitMessages } from 'app/messages/benefits';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { NotificationCard } from 'components/NotificationCard';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import React from 'react';

import { useProposalReadonly } from '../../../../utils/use-proposal-readonly';

export interface FormValues {
  benefitPackageIds: string[];
}

interface BenefitPackage {
  id: string;
  name: string;
}

interface Props {
  isSubmitting: boolean;
  isValid: boolean;
  benefitPackages?: readonly BenefitPackage[] | null;
  submissionError?: ApolloError;
}

export const TglForm: React.FC<Props> = ({
  benefitPackages,
  isSubmitting,
  isValid,
  submissionError,
}) => {
  const disabled = useProposalReadonly();
  return (
    <Form>
      <Section>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.benefitPackages} />
          </SubsectionHeader>
          {benefitPackages?.length ? (
            <>
              <DescriptionWrapper>
                <FormattedMessage
                  {...smeBenefitFormMessages.benefitPackagesDescription}
                />
              </DescriptionWrapper>

              <CheckboxGroupField
                name="benefitPackageIds"
                options={benefitPackages.map(benefitPackage => ({
                  label: benefitPackage.name,
                  value: benefitPackage.id,
                }))}
                disabled={disabled}
              />
            </>
          ) : (
            <NotificationCard type="warning">
              <FormattedMessage {...commonBenefitMessages.noBenefitPackages} />
            </NotificationCard>
          )}
        </Subsection>
        {submissionError && <GraphQlError error={submissionError} />}
        <ButtonLayout>
          <Button
            loading={isSubmitting}
            filled
            type="submit"
            disabled={disabled || !isValid}
          >
            <FormattedMessage {...smeBenefitFormMessages.save} />
          </Button>
        </ButtonLayout>
      </Section>
    </Form>
  );
};
