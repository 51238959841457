import { AssertFullyCapableForWorkInput } from 'app/apollo/graphql/types';

import { SimpleMembership } from './constants';

export const getFcfwInputCollection = (
  employees: SimpleMembership[],
  includedEmployees: Record<string, boolean>,
  employeeEffectiveDates: Record<string, string>,
  infoText: string,
): AssertFullyCapableForWorkInput[] => {
  const includedUserAccountIds = Object.entries(includedEmployees)
    .filter(([, included]) => included)
    .map(([userAccountId]) => userAccountId);

  return includedUserAccountIds.map(userAccountId => {
    const employee = employees.find(e => e.userAccountId === userAccountId);
    return {
      userAccountId,
      companyId: employee ? employee.companyId : '',
      effectiveDate: employeeEffectiveDates[userAccountId],
      infoText,
    };
  });
};
