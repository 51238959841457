import { defineMessages } from 'react-intl';

import { AutosaveStatus } from '..';

export const autosaveStatusMessages = defineMessages<AutosaveStatus>({
  SAVING: {
    id: 'autosave.status.saving',
    defaultMessage: 'Sparar...',
  },
  RECONNECTING: {
    id: 'autosave.status.reconnecting',
    defaultMessage: 'Försöker ansluta...',
  },
  SAVED: {
    id: 'autosave.status.saved',
    defaultMessage: 'Sparad',
  },
});
