import styled from 'styled-components';

interface ChipsWrapperProps {
  belowSearch?: boolean;
}

export const ChipsWrapper = styled.div<ChipsWrapperProps>`
  margin-bottom: 0.75rem;
  ${p => p.belowSearch && ` margin-top: 1rem;`};
`;
