import { companyRemunerationTypesQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: companyRemunerationTypesQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: companyRemunerationTypesQuery;
  },
) => {
  if (
    !previousResult?.company?.remunerationTypes ||
    !fetchMoreResult?.company?.remunerationTypes
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.remunerationTypes;

  return {
    company: {
      ...previousResult.company,
      remunerationTypes: {
        pageInfo,
        __typename: previousResult.company.remunerationTypes.__typename,
        edges: [...edges],
      },
    },
  };
};
