import {
  CompaniesOrderByFields,
  smeCompaniesQuery,
  smeCompaniesQueryVariables,
} from 'app/apollo/graphql/types';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { useTableSort } from 'app/utils/use-table-sort';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';

import { CompanyTable } from './components/CompanyTable';
import { EmptyState } from './components/EmptyState';
import { getFilterQueryArgs } from './filters/utils/get-filter-query-args';
import { SME_COMPANIES_QUERY } from './graphql/queries';
import { getSortingQueryArgs } from './utils/get-sorting-query-args';
import { updateCompaniesQuery } from './utils/update-companies-query';

export type SortableColumns =
  | CompaniesOrderByFields.NAME
  | CompaniesOrderByFields.PROPOSAL_STATUS;

export const SmeCompanies: React.FC = () => {
  const location = useLocation();

  const { per_page, search } = qs.parse(location.search);

  const { handleSort, order, orderBy } = useTableSort<SortableColumns>();
  const queryArgs = getSortingQueryArgs({ order, orderBy });

  const filterArgs = getFilterQueryArgs();

  const { previousData, data, loading, error, fetchMore } = useQuery<
    smeCompaniesQuery,
    smeCompaniesQueryVariables
  >(SME_COMPANIES_QUERY, {
    variables: {
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      orderBy: queryArgs,
      search,
      filters: { ...filterArgs, smeOnboarding: true },
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;

  if (!_data && loading) {
    return <TopLoading />;
  }

  if (!_data?.companies) {
    return <GraphQlError error={error} />;
  }

  const { edges, pageInfo } = _data.companies;

  const companies = edges.map(({ node }) => node);

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      {companies.length === 0 && !search ? (
        <EmptyState />
      ) : (
        <CompanyTable
          pageInfo={pageInfo}
          companies={companies}
          onNextPage={() => {
            fetchMore({
              variables: {
                after: pageInfo.endCursor,
              },
              updateQuery: updateCompaniesQuery,
            });
          }}
          onPreviousPage={() => {
            fetchMore({
              variables: {
                before: pageInfo.startCursor,
                first: undefined,
                last: per_page
                  ? parseInt(per_page, 10)
                  : DEFAULT_RESULT_PER_PAGE,
              },
              updateQuery: updateCompaniesQuery,
            });
          }}
          sort={{ order, orderBy, handleSort }}
        />
      )}
    </>
  );
};
