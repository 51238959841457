import { useField } from '@frontend/formik';
import { ImageType, ImageUpload, Value } from 'components/ImageUpload';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

interface Props {
  companyId: string;
  imageType: ImageType;
  label: (args: { isEdit: boolean }) => React.ReactNode;
  name: string;
  disabled?: boolean;
}

export const ImageUploadField: React.FC<Props> = ({ name, ...props }) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<
    Value | undefined
  >(name);
  const isInvalid = touched && Boolean(error);

  return (
    <Wrapper>
      <ImageUpload
        {...props}
        value={value}
        onChange={setValue}
        onBlur={() => setTouched(true)}
        errorText={isInvalid ? error : undefined}
      />
    </Wrapper>
  );
};

export type Image = Value;
