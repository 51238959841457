import { isOccupationalPensionBenefit } from '@frontend/benefit-types';
import { toNumber } from '@frontend/utils';
import {
  employeeDetailsLayoutQuery,
  employeeDetailsLayoutQuery_changes as Change,
  employeeDetailsLayoutQuery_changes_FlexOccupationalPensionChange as FlexOccupationalPensionChange,
  employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node as Entitlement,
  employeeDetailsLayoutQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement as OccupationalPensionEntitlement,
  FlexChangeAction,
} from 'app/apollo/graphql/types';

import { isNotSinglePremium } from './is-not-single-premium';

type Data = Pick<
  employeeDetailsLayoutQuery,
  'entitlements' | 'upcomingEntitlements' | 'changes'
>;

const isOccupationalPensionEntitlement = (
  entitlement: Entitlement,
): entitlement is OccupationalPensionEntitlement =>
  entitlement.__typename === 'FlexOccupationalPensionEntitlement';

const isOccupationalPensionChange = (
  change: Change,
): change is FlexOccupationalPensionChange =>
  change.__typename === 'FlexOccupationalPensionChange';

export const getIsMissingFixedPremium = (data: Data) => {
  const currentOccupationalPensionEntitlement = data.entitlements?.edges
    .map(({ node }) => node)
    .find(isOccupationalPensionEntitlement);

  const upcomingOccupationalPensionEntitlement =
    data.upcomingEntitlements?.edges
      .map(({ node }) => node)
      .find(isOccupationalPensionEntitlement);

  const occupationalPensionEntitlement =
    currentOccupationalPensionEntitlement ??
    upcomingOccupationalPensionEntitlement;

  if (!occupationalPensionEntitlement) {
    return false;
  }

  if (!isOccupationalPensionBenefit(occupationalPensionEntitlement.benefit)) {
    return false;
  }

  const occupationalPensionBenefit = occupationalPensionEntitlement.benefit;

  // Fixed premium is not applicable when there's a premium matrix
  if (occupationalPensionBenefit.configuration?.data?.premiumMatrix) {
    return false;
  }

  const firstChange = data?.changes
    ?.filter(isOccupationalPensionChange)
    .find(isNotSinglePremium);

  const { grossPremium, status } = occupationalPensionEntitlement;

  const hasSetPremium =
    firstChange?.action === FlexChangeAction.CREATE &&
    (toNumber(firstChange.premium) ?? 0) > 0;

  return status === 'INACTIVE' && !toNumber(grossPremium) && !hasSetPremium;
};
