import gql from 'graphql-tag';

export const membershipDetails = gql`
  fragment membershipDetails on Membership {
    id
    userAccountId
    company {
      id
    }
    givenName
    firstName
    lastName
    naturalPersonIdentifiers
    email
    phone
    collectiveAgreement
    employeeNumber
    incomeTaxTable
    employerContributionShare
    companyCarMonthlyValue
    fitnessContributionBalance
    vacationDaysSaved
    vacationDaysPaid
    vacationDaysUnpaid
    vacationDaysAdvance
    vacationDaysPerYear
  }
`;
