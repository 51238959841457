import { UpdateExtraPremiumModal } from 'features/companies/company/employees/employee/occupational/update-premium/components/UpdateExtraPremiumModal';
import { UpdatePensionPremiumModal } from 'features/companies/company/employees/employee/occupational/update-premium/components/UpdatePensionPremiumModal';
import { SinglePremiumDepositModal } from 'features/companies/company/employees/employee/occupational/update-single-premium';
import React from 'react';

import { Occupational } from './_index';

export const OccupationalRoute: React.FC = () => (
  <>
    <SinglePremiumDepositModal />
    <UpdatePensionPremiumModal />
    <UpdateExtraPremiumModal />
    <Occupational />
  </>
);
