import { Section, SectionHeader } from '@frontend/ui';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { Summary } from 'features/sme/companies/company/proposals/edit/summary';
import { smeSummaryMessages } from 'features/sme/messages/sme';
import React from 'react';

export const SmeCompanyProposalSummaryRoute: React.FC = () => (
  <Section>
    <SectionHeader>
      <FormattedMessage {...smeSummaryMessages.adviceSummary} />
    </SectionHeader>
    <TextGrid>
      <DescriptionWrapper>
        <FormattedMessage {...smeSummaryMessages.summaryDescription} />
      </DescriptionWrapper>
    </TextGrid>
    <Summary />
  </Section>
);
