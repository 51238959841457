import { Billing } from 'features/companies/company/billing';
import { AddBillingEmailModal } from 'features/companies/company/billing/add';
import { EditBillingEmailModal } from 'features/companies/company/billing/edit';
import React from 'react';

export const BillingRoute: React.FC = () => (
  <>
    <Billing />
    <AddBillingEmailModal />
    <EditBillingEmailModal />
  </>
);
