import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const INSURANCE_EVENTS_TABLE_QUERY = gql`
  query insuranceEventsTableQuery(
    $companyId: ID!
    $userAccountId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $filter: FlexChangesHistoryInputFilter
  ) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      changesHistory(
        first: $first
        after: $after
        last: $last
        before: $before
        filter: $filter
      ) {
        edges {
          node {
            id
            action
            status
            effectiveDate
            type
            txId
            statusReason
            sentAt
            configuration {
              ... on OccupationalPensionChangeConfig {
                premium
                nordeaManagementType
              }
              ... on RiskSicknessAndAccidentChangeConfig {
                sicknessAndAccidentLevel
              }
              ... on RiskAccidentChangeConfig {
                accidentLevel
              }
              ... on RetirementAgeChangeConfig {
                newRetirementAge
                insuranceNumber
              }
              ... on RiskSicknessChangeConfig {
                sicknessLevel
                annualSalary
              }
              ... on RiskPremiumWaiverChangeConfig {
                monthlyPremium
              }
              ... on RiskHealthcareChangeConfig {
                healthcareLevel
              }
              ... on RiskLifeChangeConfig {
                lifeLevel
              }
            }
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
      }
    }
  }
  ${pageInfoDetails}
`;
