import { CheckboxField, SelectField } from '@frontend/formik';
import { WizardContentCard } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, IntlShape } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { PageProps } from 'features/FormikWizard';
import { smeGenerateProposalMessages } from 'features/sme/messages/sme';
import React from 'react';
import * as Yup from 'yup';

import { FormValues as GenerateProposalFormValues } from '../';
import { getProposalStartDateOptions } from '../utils/get-proposal-start-date-options';

export interface FormValues {
  includeIndividualProposal: boolean;
  startDate: string;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    includeIndividualProposal: Yup.boolean().required(
      intl.formatMessage(commonMessages.requiredField),
    ),
    startDate: Yup.string().required(
      intl.formatMessage(commonMessages.requiredField),
    ),
  });

export const initialValues: FormValues = {
  includeIndividualProposal: false,
  startDate: '',
};

type Props = GenerateProposalFormValues & PageProps<GenerateProposalFormValues>;

export const ProposalDetails: React.FC<Props> = ({ direction, errors }) => {
  const effectiveDateOptions = getProposalStartDateOptions(new Date());

  return (
    <WizardContentCard
      slideFrom={direction}
      header={
        <FormattedMessage
          {...smeGenerateProposalMessages.proposalDetailsTitle}
        />
      }
      description={
        <FormattedMessage
          {...smeGenerateProposalMessages.proposalDetailsDescription}
        />
      }
    >
      <SelectField
        dense
        name="startDate"
        options={effectiveDateOptions}
        label={<FormattedMessage {...smeGenerateProposalMessages.startDate} />}
        required
      />
      <CheckboxField
        label={
          <FormattedMessage
            {...smeGenerateProposalMessages.includeIndividualProposal}
          />
        }
        name="includeIndividualProposal"
      />
      {errors?.submissionError && (
        <NotificationCard type="error">
          {errors.submissionError}
        </NotificationCard>
      )}
    </WizardContentCard>
  );
};
