import { EmploymentActivityType } from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { IntlShape } from 'components/formats';
import * as Yup from 'yup';

export const employmentActivityValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    type: Yup.string()
      .oneOf(Object.values(EmploymentActivityType))
      .required(intl.formatMessage(validationMessages.mandatoryField)),
    effectiveDate: Yup.date().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    effectiveThrough: Yup.date().min(
      Yup.ref('effectiveDate'),
      intl.formatMessage(validationMessages.isBeforeStartDate),
    ),
    rate: Yup.number()
      .min(0, intl.formatMessage(validationMessages.invalidEmploymentRate))
      .max(100, intl.formatMessage(validationMessages.invalidEmploymentRate)),
  });

export const updateEmploymentActivityValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    effectiveDate: Yup.date().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    effectiveThrough: Yup.date().min(
      Yup.ref('effectiveDate'),
      intl.formatMessage(validationMessages.isBeforeStartDate),
    ),
    rate: Yup.number()
      .min(0, intl.formatMessage(validationMessages.invalidEmploymentRate))
      .max(100, intl.formatMessage(validationMessages.invalidEmploymentRate)),
  });
