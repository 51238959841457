import { ApolloError } from '@apollo/client';
import { Form, Formik } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Icon,
  Section,
  Table,
  Th,
  Tr,
} from '@frontend/ui';
import { chatBubble } from '@frontend/ui/icons';
import { terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange as SalaryExchange } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { companySalaryExchangeMessages } from '../../messages';
import { getFailedTerminations } from '../utils/get-failed-terminations';
import { isError, useSubmit } from '../utils/use-submit';
import { Row } from './Row';

const StyledIcon = styled(Icon)`
  margin-right: 0.5rem;
  vertical-align: middle;
`;

interface TerminateValues {
  salaryExchangeId: string;
  reason?: string | null;
}

type SalaryExchanges = Record<string, TerminateValues>;

export interface FormValues {
  salaryExchanges: SalaryExchanges;
}

interface Props extends RouteComponentProps {
  salaryExchanges: SalaryExchange[];
}

export const TerminateSalaryExchangesTable: React.FC<Props> = ({
  salaryExchanges,
  history: { push },
}) => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();
  const { submit, results } = useSubmit({
    onCompleted: () => push(`/companies/${companyId}/salary-exchanges`),
  });

  const submissionErrors = results.reduce<ApolloError[]>(
    (acc, res) => (isError(res) ? [...acc, res.error] : acc),
    [],
  );

  const _salaryExchanges = results.length
    ? getFailedTerminations({
        results,
        salaryExchanges,
      })
    : salaryExchanges;

  const initialValues = _salaryExchanges.reduce<SalaryExchanges>(
    (acc, salaryExchange) => ({
      ...acc,
      [salaryExchange.userAccountId]: { salaryExchangeId: salaryExchange.id },
    }),
    {},
  );

  return (
    <Section>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{ salaryExchanges: initialValues }}
        onSubmit={submit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Section>
              <Table>
                <thead>
                  <Tr>
                    <Th>
                      <FormattedMessage {...commonMessages.from} />
                    </Th>
                    <Th>
                      <FormattedMessage {...commonMessages.name} />
                    </Th>
                    <Th type="number">
                      <FormattedMessage
                        {...companySalaryExchangeMessages.benefitQualifyingIncome}
                      />
                    </Th>
                    <Th type="number">
                      <FormattedMessage
                        {...companySalaryExchangeMessages.deduction}
                      />
                    </Th>
                    <Th type="number">
                      <FormattedMessage
                        {...companySalaryExchangeMessages.premium}
                      />
                    </Th>
                    <Th>
                      <FormattedMessage {...commonMessages.via} />
                    </Th>
                    <Th align="left">
                      <StyledIcon icon={chatBubble} decorative />
                      <FormattedMessage
                        {...companySalaryExchangeMessages.comments}
                      />
                    </Th>
                  </Tr>
                </thead>
                <tbody>
                  {_salaryExchanges.map(_salaryExchange => (
                    <Row key={_salaryExchange.id} item={_salaryExchange} />
                  ))}
                </tbody>
              </Table>
            </Section>
            {!!submissionErrors?.length && (
              <GraphQlErrors
                errors={submissionErrors}
                title={
                  <FormattedMessage
                    {...companySalaryExchangeMessages.terminateSalaryExchangeSubmitError}
                  />
                }
              />
            )}
            <Section>
              <ButtonLayout>
                <Button filled type="submit" loading={isSubmitting}>
                  <FormattedMessage {...commonMessages.stop} />
                </Button>
              </ButtonLayout>
            </Section>
          </Form>
        )}
      </Formik>
    </Section>
  );
};
