import gql from 'graphql-tag';

export const MEMBERSHIP_BENEFIT_PACKAGE_QUERY = gql`
  query membershipBenefitPackageQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      userAccountId
      employment {
        benefitPackage {
          to
          from
          benefitPackage {
            id
            name
          }
        }
      }
    }
  }
`;
