import { insurancesQuery } from 'app/apollo/graphql/types';

export const updateInsurancesQuery = (
  previousResult: insurancesQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: insurancesQuery;
  },
) => {
  if (!previousResult?.insurances || !fetchMoreResult?.insurances) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.insurances;

  return {
    insurances: {
      __typename: previousResult.insurances.__typename,
      edges: [...edges],
      pageInfo,
    },
  };
};
