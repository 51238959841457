import { Table, Td, Th, Tr } from '@frontend/ui';
import { insuranceEventsTableQuery_membership_Membership_changesHistory_FlexChangeHistoryConnection_edges_FlexChangeHistoryEdge_node_FlexChangeHistory as ChangesHistory } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedDate, FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { PerPageSelectProps } from 'components/PerPageSelect';
import React from 'react';

import { ConfigurationCell } from '../Configuration';
import {
  changeActionMessages,
  changeStatusMessages,
  changeTypeMessages,
  insuranceEventHistoryMessages,
} from '../messages';

interface Props extends PerPageSelectProps {
  changesHistory: ChangesHistory[];
  navigation: React.ReactNode;
}

export const InsuranceEventsTable: React.FC<Props> = ({
  changesHistory,
  navigation,
}) => (
  <Table navigation={navigation}>
    {!changesHistory.length ? (
      <tbody>
        <Tr>
          <Td>
            <FormattedMessage
              {...insuranceEventHistoryMessages.noChangesMatch}
            />
          </Td>
        </Tr>
      </tbody>
    ) : (
      <>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.insurance} />
            </Th>
            <Th>
              <FormattedMessage {...insuranceEventHistoryMessages.change} />
            </Th>
            <Th>
              <FormattedMessage
                {...insuranceEventHistoryMessages.configuration}
              />
            </Th>
            <Th type="number">
              <FormattedMessage {...commonMessages.effectiveDate} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.status} />
            </Th>
            <Th>
              <FormattedMessage
                {...insuranceEventHistoryMessages.explanationStatus}
              />
            </Th>
            <Th type="number">
              <FormattedMessage {...insuranceEventHistoryMessages.sentAt} />
            </Th>
            <Th>
              <FormattedMessage
                {...insuranceEventHistoryMessages.transactionId}
              />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {changesHistory.map((change, i) => (
            <Tr key={i}>
              <Td>
                <FormattedMessage
                  messages={changeTypeMessages}
                  select={change.type}
                />
              </Td>
              <Td>
                {change.action ? (
                  <FormattedMessage
                    messages={changeActionMessages}
                    select={change.action}
                  />
                ) : (
                  <NoValue />
                )}
              </Td>
              <Td>
                {change.configuration ? (
                  <ConfigurationCell configuration={change.configuration} />
                ) : (
                  <NoValue />
                )}
              </Td>
              <Td type="number">
                {change.effectiveDate ? (
                  <FormattedDate value={change.effectiveDate} />
                ) : (
                  <NoValue />
                )}
              </Td>
              <Td>
                <FormattedMessage
                  messages={changeStatusMessages}
                  select={change.status}
                />
              </Td>
              <Td>{change.statusReason ?? <NoValue />}</Td>
              <Td type="number">
                {change.sentAt ? (
                  <FormattedDate value={change.sentAt} />
                ) : (
                  <NoValue />
                )}
              </Td>
              <Td multiline>{change.txId ?? <NoValue />}</Td>
            </Tr>
          ))}
        </tbody>
      </>
    )}
  </Table>
);
