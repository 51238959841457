import {
  MenuItem as MenuItemComponent,
  MenuItemImage,
  MenuItemLabel,
} from '@frontend/ui';
import type { MenuItemProps as _MenuItemProps } from '@frontend/ui/components/Menu';
import { NavLink as _NavLink } from 'components/Links/NavLink';
import React from 'react';
import {
  LinkProps,
  match as Match,
  RouteComponentProps,
} from 'react-router-dom';
import styled from 'styled-components';

interface NavLinkProps {
  $navigation?: boolean;
}

const NavLink = styled(_NavLink)<NavLinkProps>`
  ${p =>
    p.$navigation &&
    `
    border-radius: 1.75rem;
    margin: 0 0.75rem;

    &:focus-visible {
      position: relative;
      z-index: 1;
    }
  `};
`;

const StyledNavLink = styled(NavLink)`
  width: 100%;
  display: block;
  &:hover {
    text-decoration: none;
  }
`;

export interface MenuItemProps
  extends Omit<_MenuItemProps, 'tooltip' | 'multiline' | 'title'> {
  description?: React.ReactNode;
  exact?: boolean;
  image?: {
    image?: string | null;
  };
  isActive?: (
    match: Match<object> | null,
    location: RouteComponentProps['location'],
  ) => boolean;
  link?: LinkProps['to'];
  noIcon?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  title?: React.ReactNode;
}
export const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  title,
  link,
  image,
  disabled,
  exact = false,
  isActive,
  onClick,
  noIcon,
  description,
  ...props
}) =>
  disabled ? (
    <MenuItemComponent
      icon={icon}
      disabled={disabled}
      multiline={!!description}
      {...props}
    >
      {!icon && <MenuItemImage image={image?.image} />}
      <MenuItemLabel title={title} description={description} />
    </MenuItemComponent>
  ) : link ? (
    !title && icon ? (
      <NavLink
        role="menuitem"
        isActive={isActive}
        to={link}
        exact={exact}
        activeClassName={props.navigation ? 'activeMenu' : undefined}
        $navigation={props.navigation}
      >
        <MenuItemComponent role="none" icon={icon} {...props} />
      </NavLink>
    ) : (
      <StyledNavLink
        role="menuitem"
        isActive={isActive}
        to={link}
        exact={exact}
        activeClassName={props.navigation ? 'activeMenu' : undefined}
        $navigation={props.navigation}
      >
        <MenuItemComponent
          role="none"
          icon={icon}
          multiline={!!description}
          {...props}
        >
          {!icon && !noIcon && <MenuItemImage image={image?.image} />}
          <MenuItemLabel title={title} description={description} />
        </MenuItemComponent>
      </StyledNavLink>
    )
  ) : (
    <MenuItemComponent
      icon={icon}
      multiline={!!description}
      onClick={onClick}
      {...props}
    >
      {!icon && !noIcon && <MenuItemImage image={image?.image} />}
      <MenuItemLabel title={title} description={description} />
    </MenuItemComponent>
  );
