import { ApolloError, useMutation } from '@apollo/client';
import {
  AdministratorInput2,
  CreateCompany2Input,
  createCompany2Mutation,
  createCompany2MutationVariables,
  MembershipRole,
  NLPAdministrativeFeeDiscountType,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory } from 'react-router';

import { companyMessages } from '../../messages';
import { FormValues } from '..';
import { CREATE_COMPANY_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [createCompany, { error: companyError }] = useMutation<
    createCompany2Mutation,
    createCompany2MutationVariables
  >(CREATE_COMPANY_MUTATION);

  const submit = async (formValues: FormValues) => {
    try {
      const admins: AdministratorInput2[] = [
        {
          email: formValues.email,
          isOwner: formValues.isOwner,
          personalIdentityNumber: formValues.pid,
          phone: formValues.phone,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          role: MembershipRole.SUPER,
        },
      ];
      if (formValues.nodeAdminPid) {
        admins.push({
          personalIdentityNumber: formValues.nodeAdminPid,
          isOwner: false,
          role: MembershipRole.SUPER,
          firstName: '',
          lastName: '',
        });
      }

      const input: CreateCompany2Input = {
        registrationNumber: formValues.registrationNumber,
        smeOnboarding: false,
        email: formValues.email,
        phone: formValues.phone,
        employeeEmailLanguage: formValues.language,
        administrator: admins,
        clearingCode: formValues.clearingCode
          ? formValues.clearingCode.value
          : undefined,
        salesRep: formValues.salesRep ? formValues.salesRep : undefined,
        // We're only interested in the payment method when creating Nordea agreements.
        // For now, the payment method is hardcoded to always be INVOICE.
        paymentMethod: formValues.createNordea
          ? formValues.paymentMethod
          : undefined,
        accountClosureMonth: Number(formValues.accountClosureMonth),
        companyStatusEffective: new Date(formValues.startDate).toISOString(),
        nordeaAgreement: formValues.createNordea
          ? {
              nlpFundFeeDiscountType:
                formValues.nordeaFundFeeDiscountType || null,
              nlpAdministrativeFeeDiscount:
                formValues.nordeaCapitalFeeFund == null ||
                formValues.nordeaAnnualFeeFund == null ||
                formValues.nordeaCapitalFeeGuarantee == null ||
                formValues.nordeaAnnualFeeGuarantee == null
                  ? []
                  : [
                      {
                        DiscountType:
                          NLPAdministrativeFeeDiscountType.CAPITAL_FEE_FUND,
                        DiscountValue: +formValues.nordeaCapitalFeeFund,
                      },
                      {
                        DiscountType:
                          NLPAdministrativeFeeDiscountType.ANNUAL_FEE_FUND,
                        DiscountValue: +formValues.nordeaAnnualFeeFund,
                      },
                      {
                        DiscountType:
                          NLPAdministrativeFeeDiscountType.CAPITAL_FEE_GUARANTEE,
                        DiscountValue: +formValues.nordeaCapitalFeeGuarantee,
                      },
                      {
                        DiscountType:
                          NLPAdministrativeFeeDiscountType.ANNUAL_FEE_GUARANTEE,
                        DiscountValue: +formValues.nordeaAnnualFeeGuarantee,
                      },
                    ],
              // Validation should have taken care of this
              nlpStartDate: formValues.nordeaStartDate!,
            }
          : undefined,
        eaAgreement:
          formValues.createEaGrupp || formValues.createEaTjp
            ? {
                createEaGrupp: formValues.createEaGrupp,
                createEaTjp: formValues.createEaTjp,
                eaExtendedFcfw: formValues.eaExtendedFcfw,
                eaGruppAgreementType: formValues.eaGruppAgreementType,
                // These boys have been validated already
                eaStartDate: formValues.eaStartDate!,
                eaCompanySize: formValues.eaCompanySize!,
              }
            : undefined,
      };

      const res = await createCompany({
        variables: { input },
      });

      if (!res.data?.createCompany2) {
        return;
      }

      send({
        message: formatMessage(companyMessages.created),
        type: 'success',
      });
      push(`/companies/${res.data.createCompany2?.company.id}`);
    } catch (error) {
      // do nothing
    }
  };

  return {
    submit,
    submissionError: companyError,
  };
};
