import { EuroAccidentPlansjuk } from '@frontend/benefit-types';
import { Formik } from '@frontend/formik';
import {
  companyEditBenefitQuery,
  companyEditBenefitQuery_flexBenefit_FlexBenefit as Benefit,
  companyEditBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import format from 'date-fns/format';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  DisabilityForm,
  FormValues,
  validationSchema,
} from '../../form/disability';
import { getBenefitPackageOptions } from '../../form/utils';
import { COMPANY_EDIT_BENEFIT_QUERY } from '../graphql/queries';
import { useSubmit } from './utils/use-submit';

export const EditDisability: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    companyEditBenefitQuery,
    companyEditBenefitQueryVariables
  >(COMPANY_EDIT_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, companyId: params.companyId },
  });

  const { submit, submissionError } = useSubmit();

  if (loading) {
    return <TopLoading />;
  }

  if (
    !data?.company?.agreements ||
    !data.company.benefitPackages ||
    !data.company.remunerationTypes ||
    !data.flexBenefit?.configuration?.data ||
    !data.flexBenefit?.entitlementRules
  ) {
    return <EmptyState error={error} />;
  }

  const benefit: EuroAccidentPlansjuk<Benefit> = data.flexBenefit;

  const { agreements, benefitPackages, managedBy, remunerationTypes } =
    data.company;

  const benefitPackageOptions = getBenefitPackageOptions(
    'euro_accident_plansjuk',
    benefitPackages,
    benefit.id,
  );

  const { configEffectiveDate, configuration, entitlementRules } = benefit;

  if (!configuration?.data || !entitlementRules) {
    return null;
  }

  const effectiveFrom = entitlementRules.from
    ? format(new Date(entitlementRules.from), 'yyyy-MM')
    : '';

  const initialValues: FormValues = {
    benefitPackageIds: [...entitlementRules.benefitPackageIds],
    converting: !!configuration.data.convertingFrom,
    convertingFrom: configuration.data.convertingFrom ?? '',
    effectiveFrom,
    extendedSickPay: configuration.data.extendedSickPay,
    isActiveOnParentalLeave: configuration.data.isActiveOnParentalLeave,
    level: configuration.data.level,
    payrollElementCode: configuration.data.payrollElementCode,
    payrollElementMultiplier: configuration.data.payrollElementMultiplier,
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={values => submit(values, benefit)}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      <DisabilityForm
        agreements={agreements}
        benefitPackageOptions={benefitPackageOptions}
        isEdit
        latestChangesEffectiveDate={configEffectiveDate}
        managedBy={managedBy}
        remunerationTypes={remunerationTypes}
        submissionError={submissionError}
      />
    </Formik>
  );
};
