import { ApolloQueryResult } from '@apollo/client';
import { client } from 'app/apollo/client';
import { companyFullyCapableForWorkRequestsQuery } from 'app/apollo/graphql/types';

import { COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY } from '../graphql/queries';

export const refetchFcfwRequests = async (
  companyId: string,
): Promise<ApolloQueryResult<companyFullyCapableForWorkRequestsQuery>> =>
  client.query({
    query: COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY,
    variables: {
      companyId,
    },
    fetchPolicy: 'network-only',
  });
