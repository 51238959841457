import { Formik } from '@frontend/formik';
import {
  createSmeCompanyQuery,
  createSmeCompanyQueryVariables,
  PaymentMethod,
} from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { useQuery } from 'app/utils/use-query';
import { IntlShape, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import {
  isEmail,
  isValidEmailDomain,
  isValidPersonalIdentityNumber,
  isValidPhoneNumber,
  isValidRegistrationNumber,
  VALID_EMAIL_DOMAINS,
  validateEmail,
  validateNaturalPersonIdentifier,
} from 'validations';
import * as Yup from 'yup';

import { ClearingFieldType } from '../companies/company/select-clearing';
import { CreateCompanyForm } from './form';
import { CREATE_SME_COMPANY_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    registrationNumber: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid registration number',
        intl.formatMessage(validationMessages.invalidRegistrationNumber),
        value =>
          !value ||
          isValidRegistrationNumber(value) ||
          isValidPersonalIdentityNumber(value),
      ),
    adminFirstName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    adminLastName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    adminEmail: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid email',
        intl.formatMessage(validationMessages.isValidEmail),
        value => !validateEmail(value),
      )
      .test(
        'valid email domain',
        intl.formatMessage(validationMessages.invalidEmailDomain, {
          domains: VALID_EMAIL_DOMAINS.join(', '),
        }),
        value => isValidEmailDomain(value),
      ),
    adminNaturalPersonIdentifier: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid personal identity number',
        intl.formatMessage(validationMessages.isValidFirstNid),
        value => !value || validateNaturalPersonIdentifier(value),
      ),
    adminPhone: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'isValidPhone',
        intl.formatMessage(validationMessages.invalidPhoneNumber),
        value => !value || isValidPhoneNumber(value),
      ),
    contactFirstName: Yup.string().when('separateContactAndAdmin', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    contactLastName: Yup.string().when('separateContactAndAdmin', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    contactNaturalPersonIdentifier: Yup.string().when(
      'separateContactAndAdmin',
      {
        is: true,
        then: schema =>
          schema.test(
            'valid personal identity number',
            intl.formatMessage(validationMessages.isValidFirstNid),
            value => !value || validateNaturalPersonIdentifier(value),
          ),
      },
    ),
    contactEmail: Yup.string().when('separateContactAndAdmin', {
      is: true,
      then: schema =>
        schema
          .required(intl.formatMessage(validationMessages.mandatoryField))
          .test(
            'valid email',
            intl.formatMessage(validationMessages.isValidEmail),
            value => isEmail(value),
          )
          .test(
            'valid email domain',
            intl.formatMessage(validationMessages.invalidEmailDomain, {
              domains: VALID_EMAIL_DOMAINS.join(', '),
            }),
            value => isValidEmailDomain(value),
          ),
    }),
    contactPhone: Yup.string().when('separateContactAndAdmin', {
      is: true,
      then: schema =>
        schema
          .required(intl.formatMessage(validationMessages.mandatoryField))
          .test(
            'valid phone number',
            intl.formatMessage(validationMessages.invalidPhoneNumber),
            value => !value || isValidPhoneNumber(value),
          ),
    }),
    accountClosureMonth: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    salaryReviewMonth: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export enum HasCompanyGroupType {
  YES = 'YES',
  NO = 'NO',
}

export interface FormValues {
  accountClosureMonth: string | null;
  accountNumber: string;
  adminEmail: string;
  adminFirstName: string;
  adminLastName: string;
  adminNaturalPersonIdentifier: string;
  adminPhone: string;
  clearingSalesOffice: ClearingFieldType;
  contactEmail: string;
  contactFirstName: string;
  contactLastName: string;
  contactNaturalPersonIdentifier: string;
  contactPhone: string;
  paymentMethod: PaymentMethod | '';
  registrationNumber: string;
  salaryReviewMonth: string | null;
  salesRep: string | null;
  separateContactAndAdmin: boolean;
  hasCompanyGroup?: HasCompanyGroupType;
}

export const Create: React.FC = () => {
  const intl = useIntl();
  const { submit, submissionError } = useSubmit();

  const { data, loading, error } = useQuery<
    createSmeCompanyQuery,
    createSmeCompanyQueryVariables
  >(CREATE_SME_COMPANY_QUERY, {
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  const nlpSalesReps = data?.nlpSalesReps ?? [];
  const adminViewerId = data?.adminViewer?.id;

  const initialValues: FormValues = {
    accountClosureMonth: '12',
    accountNumber: '',
    adminEmail: '',
    adminFirstName: '',
    adminLastName: '',
    adminNaturalPersonIdentifier: '',
    adminPhone: '',
    clearingSalesOffice: null,
    contactEmail: '',
    contactFirstName: '',
    contactLastName: '',
    contactNaturalPersonIdentifier: '',
    contactPhone: '',
    paymentMethod: '',
    registrationNumber: '',
    salaryReviewMonth: '4',
    salesRep:
      // Ensure that the admin viewer is also a valid sales rep
      // before setting it as the default value
      nlpSalesReps.some(({ id }) => id === adminViewerId) && adminViewerId
        ? adminViewerId
        : null,
    separateContactAndAdmin: false,
  };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {() => (
        <CreateCompanyForm
          nlpSalesReps={nlpSalesReps}
          submissionError={submissionError}
          error={error}
        />
      )}
    </Formik>
  );
};
