import {
  benefitPackagesOptionsQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage as BenefitPackage,
  benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit,
} from 'app/apollo/graphql/types';
import { NoValue } from 'components/NoValue';
import React from 'react';

interface Props {
  benefit: Benefit;
  benefitPackageOptions?: BenefitPackage[];
}

export const InBenefitPackage: React.FC<Props> = ({
  benefit,
  benefitPackageOptions,
}) => {
  const benefitPackageIds = benefit.entitlementRules?.benefitPackageIds;

  if (!benefitPackageOptions?.length || !benefitPackageIds?.length) {
    return <NoValue />;
  }

  return (
    <span>
      {benefit.entitlementRules?.benefitPackageIds
        .reduce((acc, id) => {
          const bp = benefitPackageOptions.find(option => option.id === id);
          return bp?.name ? [...acc, bp.name] : acc;
        }, [])
        .join(', ')}
    </span>
  );
};
