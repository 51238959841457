import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';

import { benefitFormMessages } from '../../messages';

export const CompanySizeNotification: React.FC = () => (
  <NotificationCard type="warning">
    <FormattedMessage {...benefitFormMessages.companySizeLife} />
  </NotificationCard>
);
