import { ApolloError, useMutation } from '@apollo/client';
import {
  duplicateProposalMutation,
  duplicateProposalMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import * as qs from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router';

import { SME_COMPANY_PROPOSALS_QUERY } from '../../graphql/queries';
import { FormValues } from '..';
import { DUPLICATE_PROPOSAL_MUTATION } from '../graphql/mutations';
import { DUPLICATE_PROPOAL_QUERY_KEY } from './use-duplicate-proposal';

interface Submit {
  submit: (value: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

interface Args {
  onRequestClose: () => void;
}

export const useSubmit = (args: Args): Submit => {
  const { formatMessage } = useIntl();
  const params = useParams<MatchParams>();
  const history = useHistory();
  const { send, clear } = useNotification();
  const { onRequestClose } = args;
  const location = useLocation();

  const [duplicateProposal, { error: submissionError }] = useMutation<
    duplicateProposalMutation,
    duplicateProposalMutationVariables
  >(DUPLICATE_PROPOSAL_MUTATION, {
    refetchQueries: [SME_COMPANY_PROPOSALS_QUERY],
    awaitRefetchQueries: true,
  });

  const { [DUPLICATE_PROPOAL_QUERY_KEY]: proposalId } = qs.parse(
    location.search,
  );

  const submit = async (values: FormValues) => {
    try {
      const { adviceWaived, companySize, name } = values;

      const res = await duplicateProposal({
        variables: {
          input: {
            proposalId,
            adviceWaived: adviceWaived || undefined,
            companySize: companySize || undefined,
            name: name || undefined,
          },
        },
      });
      const proposal = res.data?.duplicateProposal;
      if (!proposal?.id) {
        return;
      }
      send({
        message: formatMessage(
          smeCompanyProposalsMessages.duplicateProposalNotification,
        ),
        callback: {
          fn: () => {
            history.push(`/sme/${params.companyId}/proposals/${proposal.id}`);
            clear();
          },
          label: formatMessage(
            smeCompanyProposalsMessages.viewDuplicatedProposal,
            { name: proposal.name },
          ),
        },
        type: 'success',
      });

      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    submissionError,
  };
};
