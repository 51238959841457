import gql from 'graphql-tag';

export const RENAME_BENEFIT_PACKAGE_QUERY = gql`
  query renameBenefitPackageQuery($benefitPackageId: ID!) {
    benefitPackage(id: $benefitPackageId) {
      id
      name
    }
  }
`;
