import gql from 'graphql-tag';

export const DELETE_BENEFIT_PACKAGE_MUTATION = gql`
  mutation deleteBenefitPackageMutation(
    $input: DeleteProposalBenefitPackageInput!
  ) {
    deleteProposalBenefitPackage(input: $input) {
      id
      benefits {
        benefitPackages {
          id
        }
      }
      benefitPackages {
        id
      }
    }
  }
`;
