import { ApolloError, useMutation } from '@apollo/client';
import {
  removeBenefitFromBenefitPackage,
  removeBenefitFromBenefitPackageVariables,
  RemoveProposalBenefitFromBenefitPackageInput,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitPackagesMessages } from 'features/sme/messages/sme';
import { useMemo, useRef } from 'react';

import { clearProposalCache } from '../../../utils/clear-proposal-cache';
import { REMOVE_BENEFIT_FROM_BENEFIT_PACKAGE } from './graphql/mutations';

interface RemoveError {
  error?: ApolloError;
  id?: string;
}

interface Submit {
  remove: (
    input: RemoveProposalBenefitFromBenefitPackageInput,
  ) => Promise<void>;
  removeError: RemoveError;
  removeLoading: boolean;
}

interface Params {
  params: MatchParams;
}

export const useRemoveBenefit = ({ params }: Params): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const benefitPackageRef = useRef<string>();

  const [removeBenefit, { loading: removeLoading, error }] = useMutation<
    removeBenefitFromBenefitPackage,
    removeBenefitFromBenefitPackageVariables
  >(REMOVE_BENEFIT_FROM_BENEFIT_PACKAGE, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals', 'validity'],
    }),
  });

  const remove = async (
    input: RemoveProposalBenefitFromBenefitPackageInput,
  ) => {
    benefitPackageRef.current = input.benefitPackageId;
    try {
      const res = await removeBenefit({
        variables: {
          input,
        },
      });
      const proposalId = res.data?.removeProposalBenefitFromBenefitPackage?.id;
      if (!proposalId) {
        return;
      }
      send({
        message: formatMessage(smeBenefitPackagesMessages.benefitRemoved),
        type: 'success',
      });
      benefitPackageRef.current = undefined;
    } catch {
      // Do nothing
    }
  };

  const removeError = useMemo(
    () => ({
      error,
      id: benefitPackageRef.current,
    }),
    [error],
  );

  return {
    remove,
    removeLoading,
    removeError,
  };
};
