import gql from 'graphql-tag';

export const SME_GENERATE_PROPOSAL_QUERY = gql`
  query smeGenerateProposalQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      accountNumber
      clearingCode {
        code
        displayName
      }
      paymentMethod
    }
  }
`;

export const SME_GENERATE_PROPOSAL_DOCUMENTS_QUERY = gql`
  query smeGenerateProposalDocumentsQuery($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      documents {
        offer {
          name
          path
          url
        }
        otherDocuments {
          name
          path
          url
        }
      }
    }
  }
`;
