import gql from 'graphql-tag';

export const EDIT_PROPOSAL_SALARY_EXCHANGE_BENEFIT_MUTATION = gql`
  mutation editProposalSalaryExchangeBenefitMutation(
    $benefitId: ID!
    $input: UpdateProposalSalaryExchangeBenefitInput!
  ) {
    updateProposalSalaryExchangeBenefit(input: $input) {
      id
      benefit(id: $benefitId) {
        id
        benefitPackages {
          id
        }
        configuration {
          ... on ProposalSalaryExchangeBenefitConfig {
            automaticApproval
            incomeMinIBB
            ongoingDeductionShareMax
            ongoingMax
            pensionTaxBenefitShare
            premiumWaiver
            retirementAge
            salaryMultiplier
          }
        }
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
