import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { InsuranceEvents } from 'features/companies/company/employees/employee/insurance-events';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const InsuranceEventsRoute: React.FC = () => (
  <Suspense batch fallback={<TopLoading />}>
    {({ errors }) => (
      <>
        {errors && <GraphQlErrors errors={errors} />}
        <InsuranceEvents />
      </>
    )}
  </Suspense>
);
