import { BaseAmountType } from '@advinans/premium-matrix/build/premium-matrix';
import {
  getIn,
  NumberField,
  RadioGroupField,
  useField,
} from '@frontend/formik';
import { Subsection, SubsectionHeader, Table, Td, Th, Tr } from '@frontend/ui';
import { settings } from '@frontend/ui/icons';
import { toNumber } from '@frontend/utils';
import {
  commonMessages,
  suffixMessages,
  validationMessages,
} from 'app/messages/common';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import {
  FormattedMessage,
  FormattedNumber,
  FormattedRange,
  IntlShape,
  useIntl,
} from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  benefitFormMessages,
  benefitPensionBaseAmountTypeMessages,
} from '../messages';
import { PremiumMatrixFormValues } from '../types';
import { IntervalsModal } from './IntervalsModal';
import { premiumIsNotOver100, trimAndPadIntervals } from './utils';
import { premiumIntervalValidationMessages } from './utils/messages';

const NumberFieldWrapper = styled.div`
  width: 7rem;
`;

const ErrorWrapper = styled.div`
  margin-top: 1rem;
`;

export const validationSchema = (name: string, intl: IntlShape) =>
  Yup.object().shape({
    [name]: Yup.object().shape({
      intervals: Yup.array().of(
        Yup.array().of(
          Yup.string()
            .required(intl.formatMessage(validationMessages.mandatoryField))
            .test({
              name: 'premium is not over 100',
              message: intl.formatMessage(
                premiumIntervalValidationMessages.PREMIUMS_NOT_OVER_100,
              ),
              test: premiumIsNotOver100,
            }),
        ),
      ),
    }),
  });

interface Props {
  name: string;
}

export const CustomPremiumMatrixSection: React.FC<Props> = ({ name }) => {
  const [{ value }, { error }, { setValue }] =
    useField<PremiumMatrixFormValues>(name);

  const {
    age: ages,
    baseAmount: baseAmounts,
    baseAmountType,
    intervals,
  } = value;

  const nColumns = baseAmounts.length + 1;

  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...benefitFormMessages.premiumMatrix} />
      </SubsectionHeader>
      <RadioGroupField
        name={`${name}.baseAmountType`}
        options={Object.keys(BaseAmountType).map(option => ({
          label: formatMessage({
            select: option,
            messages: benefitPensionBaseAmountTypeMessages,
          }),
          value: option,
        }))}
      />
      <ChipsWrapper>
        <AssistChip
          onClick={() => setModalIsOpen(true)}
          text={
            <FormattedMessage {...benefitFormMessages.editPremiumIntervals} />
          }
          leadingIcon={settings}
        />
      </ChipsWrapper>
      <Table fixed size="xsmall">
        <colgroup>
          {Array(nColumns).map(col => (
            <col key={col} style={{ width: `${100 / nColumns}%` }} />
          ))}
        </colgroup>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.age} />
            </Th>
            {baseAmounts.map((baseAmount, i) => (
              <Th align="left" key={baseAmount}>
                <FormattedRange
                  lowerBound={
                    <FormattedNumber value={toNumber(baseAmount) ?? 0} />
                  }
                  upperBound={
                    baseAmounts[i + 1] && (
                      <FormattedNumber
                        value={toNumber(baseAmounts[i + 1]) ?? 0}
                      />
                    )
                  }
                />{' '}
                {baseAmountType}
              </Th>
            ))}
          </Tr>
        </thead>
        <tbody>
          {ages.map((age, ageIndex) => (
            <Tr key={age}>
              <Th>
                <FormattedMessage
                  {...commonMessages.nYears}
                  values={{
                    count: (
                      <FormattedRange
                        lowerBound={age}
                        upperBound={ages[ageIndex + 1]}
                      />
                    ),
                  }}
                />
              </Th>
              {intervals[ageIndex]?.map((_, baseAmountIndex) => (
                <Td
                  align="left"
                  key={`${age}-${baseAmounts[baseAmountIndex]}`}
                  type="input"
                >
                  <NumberFieldWrapper>
                    <NumberField
                      affix={formatMessage(suffixMessages.percentage)}
                      allowNegative={false}
                      decimalScale={2}
                      dense
                      fixedDecimalScale
                      hideValidationMessage
                      locale={locale}
                      name={`${name}.intervals.${ageIndex}.${baseAmountIndex}`}
                      required
                      withoutMargin
                    />
                  </NumberFieldWrapper>
                </Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>
      {getIn(error, 'intervals') && (
        <ErrorWrapper>
          <NotificationCard type="error">
            {getIn(error, 'intervals')}
          </NotificationCard>
        </ErrorWrapper>
      )}
      <IntervalsModal
        ages={ages}
        baseAmountType={baseAmountType}
        baseAmounts={baseAmounts}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        onSubmit={({ ages: newAges, baseAmounts: newBaseAmounts }) =>
          setValue({
            ...value,
            age: newAges,
            baseAmount: newBaseAmounts,
            intervals: trimAndPadIntervals(newAges, newBaseAmounts, intervals),
          })
        }
      />
    </Subsection>
  );
};
