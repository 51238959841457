import { accountingObjectsQuery } from 'app/apollo/graphql/types';

export const updateAccountingObjectsQuery = (
  previousResult: accountingObjectsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: accountingObjectsQuery;
  },
): accountingObjectsQuery => {
  if (
    !previousResult.accountingObjects ||
    !fetchMoreResult?.accountingObjects
  ) {
    return previousResult;
  }

  return fetchMoreResult;
};
