import { ApolloError } from '@apollo/client';
import {
  accountingObjectQuery,
  accountingObjectQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { useRouteMatch } from 'react-router';

import { ACCOUNTING_OBJECT_QUERY } from '../graphql/queries';

interface AccountingDimension {
  loading: boolean;
  error?: ApolloError;
  identifier?: string;
  name?: string;
}

export const useAccountingDimensionObject = (
  objectId?: string,
  dimensionId?: string,
): AccountingDimension => {
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { data, loading, error } = useQuery<
    accountingObjectQuery,
    accountingObjectQueryVariables
  >(ACCOUNTING_OBJECT_QUERY, {
    variables: {
      companyId,
      id: objectId ?? '',
      dimensionId: dimensionId ?? '',
    },
    skip: !objectId,
    errorPolicy: 'all',
  });

  return {
    loading,
    error,
    name: data?.accountingObject?.name ?? undefined,
    identifier: data?.accountingObject?.identifier ?? undefined,
  };
};
