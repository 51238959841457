import { IntlShape } from 'components/formats';

import { unitCodeSuffixMessages } from './constants';

export const formatRemunerationSuffix = (
  intl: IntlShape,
  currency?: string | null,
  unitCode?: string | null,
) => {
  if (!currency) {
    return '';
  }
  const unit = unitCodeSuffixMessages[unitCode ?? ''];

  if (!unit) {
    return currency;
  }
  return `${currency}/${intl.formatMessage(unit)}`;
};
