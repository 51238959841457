import styled from 'styled-components';

export const PlainButton = styled.button`
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  text-align: inherit;
`;
