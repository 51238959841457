import { SelectField, TextField } from '@frontend/formik';
import { WizardContentCard } from '@frontend/ui';
import { PaymentMethod } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { PageProps } from 'features/FormikWizard';
import {
  ClearingFieldType,
  SelectClearingField,
} from 'features/sme/companies/company/select-clearing';
import {
  smeAccountNumberMessages,
  smeCompanyMessages,
  smeGenerateProposalMessages,
  smePaymentMethodMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import * as Yup from 'yup';

export interface FormValues {
  accountNumber: string;
  clearingSalesOffice: ClearingFieldType;
  paymentMethod: PaymentMethod | '';
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    accountNumber: Yup.string().when('paymentMethod', {
      is: (value: PaymentMethod) => value !== 'INVOICE',
      then: schema =>
        schema.required(intl.formatMessage(commonMessages.requiredField)),
    }),
    clearingSalesOffice: Yup.object()
      .required(intl.formatMessage(commonMessages.requiredField))
      .nullable(),
    paymentMethod: Yup.string().required(
      intl.formatMessage(commonMessages.requiredField),
    ),
  });

type Props = FormValues & PageProps<FormValues>;

export const MissingCompanyDetails: React.FC<Props> = ({
  direction,
  paymentMethod,
}) => {
  const { formatMessage } = useIntl();

  return (
    <WizardContentCard
      slideFrom={direction}
      header={
        <FormattedMessage
          {...smeGenerateProposalMessages.missingCompanyDetailsTitle}
        />
      }
      description={
        <FormattedMessage
          {...smeGenerateProposalMessages.missingCompanyDetailsDescription}
        />
      }
    >
      <SelectClearingField name="clearingSalesOffice" required />
      <SelectField
        dense
        label={<FormattedMessage {...smeCompanyMessages.paymentMethod} />}
        name="paymentMethod"
        options={Object.values(PaymentMethod).map(value => ({
          label: formatMessage({
            messages: smePaymentMethodMessages,
            select: value,
          }),
          value,
        }))}
        required
      />
      {paymentMethod && paymentMethod !== PaymentMethod.INVOICE && (
        <TextField
          dense
          label={
            <FormattedMessage
              select={paymentMethod}
              messages={smeAccountNumberMessages}
            />
          }
          name="accountNumber"
          required
        />
      )}
    </WizardContentCard>
  );
};
