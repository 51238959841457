import { upcomingSalaryExchangesTableQuery as Query } from 'app/apollo/graphql/types';

interface FetchMore {
  fetchMoreResult?: Query;
}

export const updateQuery = (
  previousResult: Query,
  { fetchMoreResult }: FetchMore,
) => {
  if (
    !previousResult?.salaryExchangeRequests ||
    !fetchMoreResult?.salaryExchangeRequests
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.salaryExchangeRequests;

  return {
    salaryExchangeRequests: {
      ...previousResult.salaryExchangeRequests,
      pageInfo,
      edges,
    },
  };
};
