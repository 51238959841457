import { BenefitType } from '@frontend/benefit-types';
import { Dot, Section, SectionHeader } from '@frontend/ui';
import {
  digitalAdviceTableQuery,
  digitalAdviceTableQueryVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { DescriptionTable } from 'components/DescriptionTable';
import { FormattedMessage } from 'components/formats';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { DIGITAL_ADVICE_TABLE_QUERY } from './queries';

const getPlusBenefit = (data?: digitalAdviceTableQuery) =>
  data?.entitlements?.edges[0]?.node;

const isEmpty = (data?: digitalAdviceTableQuery) => !getPlusBenefit(data);

const benefitTypeNames: BenefitType[] = ['nordea_node_digital_advice'];

export const DigitalAdviceTable: React.FC = () => {
  const {
    params: { companyId, userAccountId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const { data } = useQuery<
    digitalAdviceTableQuery,
    digitalAdviceTableQueryVariables
  >(DIGITAL_ADVICE_TABLE_QUERY, {
    isEmpty,
    suspend: true,
    errorPolicy: 'all',
    variables: { companyId, userAccountId, benefitTypeNames },
  });

  const advinansAdviceBenefit = getPlusBenefit(data);

  if (!advinansAdviceBenefit) {
    return null;
  }

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonBenefitMessages.digitalAdvice} />
      </SectionHeader>
      <DescriptionTable
        alignLeft
        entries={[
          {
            id: 'advinansPlus.status',
            label: <FormattedMessage {...commonMessages.status} />,
            value: (
              <Dot
                colorValue="green"
                size={0.75}
                label={<FormattedMessage {...commonMessages.active} />}
              />
            ),
          },
        ]}
      />
    </Section>
  );
};
