import { ButtonLayout } from '@frontend/ui';
import { formMessages } from 'app/messages/form';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { FormattedMessage } from 'components/formats';
import { LinkButton } from 'components/LinkButton';
import qs from 'query-string';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { companySalaryExchangeMessages } from '../../../messages';

interface Props {
  requestIds: string[];
  approved?: boolean;
}

export const TableActions: React.FC<Props> = ({ requestIds, approved }) => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();
  const search = qs.stringify({ requestIds, approved });

  return (
    <ButtonLayout>
      {!approved && (
        <LinkButton
          text
          to={{
            pathname: `/companies/${companyId}/salary-exchanges/requests/approve`,
            search,
          }}
        >
          <FormattedMessage {...formMessages.approve} />
        </LinkButton>
      )}
      <LinkButton
        text
        to={{
          pathname: `/companies/${companyId}/salary-exchanges/requests/reject`,
          search,
        }}
      >
        <FormattedMessage
          {...(approved
            ? companySalaryExchangeMessages.stopSalaryExchange
            : formMessages.reject)}
          values={{ count: requestIds.length }}
        />
      </LinkButton>
    </ButtonLayout>
  );
};
