import gql from 'graphql-tag';

export const SELECT_CLEARING_QUERY = gql`
  query selectClearingQuery($search: String!) {
    clearingCodes(searchString: $search) {
      code
      name
    }
  }
`;
