import {
  Form,
  Formik,
  FormikProps,
  SelectField,
  TextField,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { person } from '@frontend/ui/icons';
import { MembershipKey } from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages, validationMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { useSubmit } from 'features/advisor/send-invitations/utils/use-submit';
import React from 'react';
import { isValidUuid } from 'validations';

export interface SendFormValues {
  companyId: string;
  eventType: string;
  userAccountId: string;
  submissionError?: string;
}

interface SendFormProps {
  eventTypes: Array<{ label: string; value: string }>;
  numberOfInvitations: number;
  onRequestClose: () => void;
  submit?: () => Promise<void>;
}

const SendForm: React.FC<FormikProps<SendFormValues> & SendFormProps> = ({
  onRequestClose,
  isSubmitting,
  errors,
  eventTypes,
  numberOfInvitations,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Form>
      <ModalHeader>
        <FormattedMessage
          {...advisorMessages.sendInvitationsTo}
          values={{ count: numberOfInvitations }}
        />
      </ModalHeader>
      <ModalBody>
        {numberOfInvitations === 0 ? (
          <>
            <TextField
              dense
              name="userAccountId"
              label={<FormattedMessage {...commonMessages.userAccountId} />}
              required
              leadingIcon={<Icon icon={person} decorative />}
              spellCheck={false}
              validate={value =>
                isValidUuid(value)
                  ? ''
                  : formatMessage(validationMessages.invalidUuid)
              }
            />
            <TextField
              dense
              name="companyId"
              label={<FormattedMessage {...commonMessages.companyId} />}
              required
              leadingIcon={<Icon icon={person} decorative />}
              spellCheck={false}
              validate={value =>
                isValidUuid(value)
                  ? ''
                  : formatMessage(validationMessages.invalidUuid)
              }
            />
          </>
        ) : null}
        <SelectField
          gridMargin
          name="eventType"
          label={<FormattedMessage {...advisorMessages.eventType} />}
          options={eventTypes}
          required
          fixed
        />
        {errors.submissionError && (
          <NotificationCard type="error" inModal>
            {errors.submissionError}
          </NotificationCard>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={onRequestClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button text type="submit" loading={isSubmitting}>
            <FormattedMessage {...commonMessages.send} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Form>
  );
};

interface Props {
  eventTypes: Array<{ label: string; value: string }>;
  isOpen: boolean;
  membershipKeys: Array<MembershipKey>;
  onRequestClose: () => void;
}

export const SendInvitationsModal: React.FC<Props> = ({
  isOpen,
  membershipKeys,
  eventTypes,
  onRequestClose,
}) => {
  const { submit, error } = useSubmit({
    membershipKeys,
    onRequestClose,
  });

  return (
    <Formik<SendFormValues>
      initialValues={{
        userAccountId: '',
        companyId: '',
        eventType: '',
      }}
      onSubmit={submit}
      enableReinitialize
    >
      {formikProps => (
        <>
          {error && <GraphQlError error={error} />}
          <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
            <SendForm
              {...formikProps}
              {...{
                onRequestClose,
                eventTypes,
                numberOfInvitations: membershipKeys.length,
              }}
            />
          </Modal>
        </>
      )}
    </Formik>
  );
};
