import { CostReporting } from 'features/companies/company/cost-reporting';
import { CreateDimensionModal } from 'features/companies/company/cost-reporting/create';
import { EditDimensionModal } from 'features/companies/company/cost-reporting/edit';
import React from 'react';

export const CostReportingRoute: React.FC = () => (
  <>
    <CostReporting />
    <CreateDimensionModal />
    <EditDimensionModal />
  </>
);
