import gql from 'graphql-tag';

export const UPDATE_MEMBERSHIP_ROLES_MUTATION = gql`
  mutation updateMembershipRolesMutation(
    $userAccountId: ID!
    $companyId: ID!
    $roles: [MembershipRole!]!
  ) {
    updateMembership(
      input: {
        userAccountId: $userAccountId
        companyId: $companyId
        roles: $roles
      }
    ) {
      membership {
        id
        userAccountId
        roles
      }
    }
  }
`;
