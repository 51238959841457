import gql from 'graphql-tag';

const pensionCardDetails = gql`
  fragment pensionCardDetails on FlexEntitlement {
    ... on FlexOccupationalPensionEntitlement {
      status
      netPremium
    }
    benefit {
      id
      type
      content {
        locale
      }
    }
  }
`;

export const PENSION_CARD_QUERY = gql`
  query pensionCardQuery(
    $companyId: ID!
    $benefitTypeNames: [String!]
    $userAccountId: ID!
    $date: Date
  ) {
    entitlements(
      companyId: $companyId
      userAccountId: $userAccountId
      benefitTypeNames: $benefitTypeNames
      showAll: true
    ) {
      edges {
        node {
          ...pensionCardDetails
        }
      }
    }
    entitlementsOnDate: entitlements(
      companyId: $companyId
      userAccountId: $userAccountId
      benefitTypeNames: $benefitTypeNames
      showAll: true
      date: $date
    ) {
      edges {
        node {
          ...pensionCardDetails
        }
      }
    }
    company(id: $companyId) {
      id
      totalCompensationConfiguration {
        companyId
        pension
      }
    }
  }
  ${pensionCardDetails}
`;
