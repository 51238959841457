import { IntlShape } from 'components/formats';

import { DEFAULT_VALUE } from './constants';

export const tickFormatter = (
  value: number,
  intl: IntlShape,
  hasSxCapacity: boolean,
): string =>
  hasSxCapacity
    ? intl.formatNumber(value, {
        format: 'currency',
        currency: 'SEK',
      })
    : value === DEFAULT_VALUE
      ? '- kr'
      : value === 0
        ? '0'
        : '';
