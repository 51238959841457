import { FlexChangeAction } from 'app/apollo/graphql/types';

interface ChangeType {
  action: FlexChangeAction;
}

/**
 * @returns true iff the change is relevant for occupational
 * pension table, otherwise false.
 */
export const isNotSinglePremium = <T extends ChangeType>(change: T): boolean =>
  change.action !== FlexChangeAction.SINGLE_PREMIUM;
