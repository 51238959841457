import React, { createContext, useContext, useMemo, useState } from 'react';

import { TransitionOverlay as TransitionOverlayComponent } from './TransitionOverlay';
import { Transition, TransitionOverlayContextProps } from './utils/types';

export const TransitionOverlayContext =
  createContext<TransitionOverlayContextProps>({
    startTransition: (transition: Transition) => transition,
    updateTransition: (transition: Partial<Transition>) => transition,
    clearTransition: () => null,
  });

export const useTransitionOverlay = (): TransitionOverlayContextProps =>
  useContext(TransitionOverlayContext);

interface Props {
  children: React.ReactNode;
}
export const TransitionOverlayContextProvider: React.FC<Props> = ({
  children,
}) => {
  const [transition, setTransition] = useState<Transition>();

  const startTransition = (_transition: Transition) =>
    setTransition(_transition);
  const updateTransition = (_transition: Partial<Transition>) =>
    setTransition(
      prevState =>
        prevState && {
          ...prevState,
          ..._transition,
        },
    );
  const clearTransition = () => setTransition(undefined);
  const value = useMemo(
    () => ({
      startTransition,
      updateTransition,
      clearTransition,
      transition,
    }),
    [transition],
  );

  return (
    <TransitionOverlayContext.Provider value={value}>
      {children}
    </TransitionOverlayContext.Provider>
  );
};

interface TransitionOverlayProps {
  pathname: string;
}

export const TransitionOverlay: React.FC<TransitionOverlayProps> = ({
  pathname,
}) => {
  const { clearTransition, transition } = useTransitionOverlay();

  if (!transition) {
    return null;
  }

  return (
    <TransitionOverlayComponent
      clearTransition={clearTransition}
      transition={transition}
      pathname={pathname}
    />
  );
};
