import { Formik } from '@frontend/formik';
import { noteQuery, noteQueryVariables } from 'app/apollo/graphql/types';
import { noteMessages } from 'app/messages/notes';
import { NoteMatchParams } from 'app/pages/companies/company/notes';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useCurrentUser } from 'contexts/current-permissions';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { NoteForm } from '../components/NoteForm';
import { NOTE_QUERY } from '../graphql/queries';
import { useSubmit } from './use-submit';

export const EditNote: React.FC = () => {
  const match = useRouteMatch<NoteMatchParams>();
  const { submit, error: submissionError } = useSubmit();

  const { formatMessage } = useIntl();

  const { noteId } = match.params;
  const user = useCurrentUser();

  const { data, loading, error } = useQuery<noteQuery, noteQueryVariables>(
    NOTE_QUERY,
    {
      skip: !noteId,
      variables: {
        id: noteId!,
      },
      errorPolicy: 'all',
    },
  );

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.note) {
    return <GraphQlError error={error} />;
  }

  const { note } = data;
  if (note.author?.adminUserExternalId !== user?.externalId) {
    return <EmptyState />;
  }

  return (
    <NavigationAnchor
      title={formatMessage(noteMessages.editNoteSubject, {
        subject: note.subject,
      })}
      url={match.url}
    >
      <Formik
        initialValues={{
          category: note?.category,
          content: note?.content ?? '',
          subject: note?.subject ?? '',
          attachments: note?.attachments.map(({ id, filename, url }) => ({
            id: id ?? undefined,
            name: filename!,
            url: url ?? undefined,
          })),
        }}
        onSubmit={submit}
      >
        {formikProps => (
          <NoteForm
            {...formikProps}
            submissionError={submissionError}
            companyId={match.params.companyId}
          />
        )}
      </Formik>
    </NavigationAnchor>
  );
};
