import { TextField } from '@frontend/formik';
import { Body, Grid, Subsection, SubsectionHeader } from '@frontend/ui';
import { membershipDetailsQuery_membership_Membership as Membership } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  employeeFormMessages,
  workInformationMessages,
} from 'app/messages/employees';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { GridCell50 } from 'components/GridCell';
import React from 'react';

import { NidTableField, NidValue } from '../NidTableField';

export interface PersonalDataFormValues {
  email: string | null;
  employeeId: string | null;
  givenName: string | null;
  lastName: string | null;
  nids: NidValue[];
  phone: string | null;
}

export const personalDataInitialValues = (
  membership: Membership,
): PersonalDataFormValues => ({
  givenName: membership.givenName ?? '',
  lastName: membership.lastName ?? '',
  employeeId: membership.employeeNumber ?? '',
  email: membership.email ?? '',
  phone: membership.phone ?? '',
  nids: membership.naturalPersonIdentifiers.map(nid => ({
    value: nid,
    editable: false,
  })),
});

export const PersonalDataFields: React.FC = () => (
  <Subsection>
    <SubsectionHeader>
      <FormattedMessage {...workInformationMessages.personalDetails} />
    </SubsectionHeader>
    <Grid>
      <GridCell50>
        <TextField
          dense
          name="givenName"
          label={<FormattedMessage {...commonMessages.givenName} />}
          required
        />
      </GridCell50>
      <GridCell50>
        <TextField
          dense
          name="lastName"
          label={<FormattedMessage {...commonMessages.lastName} />}
          required
        />
      </GridCell50>
    </Grid>
    <Body>
      <FormattedMessage {...employeeFormMessages.nidDescription} />
    </Body>
    <DescriptionWrapper>
      <NidTableField name="nids" />
    </DescriptionWrapper>
    <TextField
      dense
      name="employeeId"
      label={
        <FormattedMessage {...workInformationMessages.employeeNumberLabel} />
      }
    />
    <TextField
      dense
      name="email"
      type="email"
      label={<FormattedMessage {...commonMessages.email} />}
      required
    />
    <TextField
      dense
      name="phone"
      type="tel"
      label={<FormattedMessage {...commonMessages.phone} />}
    />
  </Subsection>
);
