import { Td, Tr } from '@frontend/ui';
import { companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership as Employee } from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { MatchParams as BenefitPackageMatchParams } from 'app/pages/companies/company/benefit-packages/benefit-package';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';

type MatchParams = CompanyMatchParams | BenefitPackageMatchParams;
interface Props {
  employee: Employee;
}

export const EmployeeTableRow: React.FC<Props> = ({ employee }) => {
  const { push } = useHistory();
  const { params } = useRouteMatch<MatchParams>();

  return (
    <Tr
      linked
      onClick={() => {
        push(
          `/companies/${params.companyId}/employees/${employee.userAccountId}`,
        );
      }}
    >
      <Td align="left">{employee.userAccountId}</Td>
      <Td type="number" align="left">
        <FormattedNaturalPersonIdentifier
          nids={employee.naturalPersonIdentifiers}
        />
      </Td>
      <Td>{employee.givenName}</Td>
      <Td align="left">{employee.lastName}</Td>
    </Tr>
  );
};
