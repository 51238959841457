import gql from 'graphql-tag';

export const EDIT_REPRESENTATIVE_MODAL_QUERY = gql`
  query editRepresentativeModalQuery($companyId: ID!, $representativeId: ID!) {
    proposalContactPerson(companyId: $companyId) {
      firstName
      lastName
      personalIdentityNumber
    }
    companyRepresentative(id: $representativeId) {
      id
      firstName
      identificationReference
      identificationType
      lastName
      personalIdentityNumber
      role
    }
  }
`;
