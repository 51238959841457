import { useFormikObserver } from '@frontend/formik';
import { WizardContainer, WizardFooter, WizardGrid } from '@frontend/ui';
import { formSubmitMessages } from 'app/messages/form';
import { useIntl } from 'components/formats';
import { Modal } from 'components/Modal';
// eslint-disable-next-line
import { WizardHeader } from 'components/WizardHeader';
import { useConfirm } from 'contexts/confirmation';
import React from 'react';

interface Props {
  children: React.ReactNode;
  parentLink: string;
  title: React.ReactNode;
  actions?: React.ReactNode;
  fullBleed?: boolean;
  headerActions?: React.ReactNode;
  offWhite?: boolean;
  progress?: number;
  /**
   * Wraps the wizard modal content in a Formik form.
   * Note: Wrapping only the `children` in a Formik form is insufficient,
   * as it covers only the wizard's body, not the `actions`.
   * The `Form` element cannot wrap `SimpleWizard` directly because the wizard
   * is a modal and is rendered outside the form in the DOM.
   */
  withForm?: (children: React.ReactNode) => React.ReactNode;
}

export const SimpleWizard: React.FC<Props> = ({
  children,
  title,
  parentLink,
  actions,
  progress,
  fullBleed,
  headerActions,
  offWhite,
  withForm,
}) => {
  const { formatMessage } = useIntl();
  const { hasDirtyFields } = useFormikObserver();
  const { confirm } = useConfirm();

  const onConfirm = async () => {
    if (hasDirtyFields) {
      return confirm({
        title: formatMessage(formSubmitMessages.discardChanges),
        description: formatMessage(formSubmitMessages.confirmExitWizard),
      });
    }

    return true;
  };

  const headerProps = {
    title,
    progress,
    actions: headerActions,
    parentLink,
    onConfirm,
  };

  const render = withForm ?? ((_children: React.ReactNode) => _children);

  return (
    <Modal size="full-window">
      {render(
        <>
          <WizardHeader {...headerProps} />
          <WizardContainer offWhite={offWhite}>
            {fullBleed ? children : <WizardGrid>{children}</WizardGrid>}
          </WizardContainer>
          {actions && (
            <WizardFooter
              noOffsetLeft={fullBleed}
              noOffsetRight={fullBleed}
              actions={actions}
            />
          )}
        </>,
      )}
    </Modal>
  );
};
