import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  openDrawer: {
    id: 'appBar.openDrawer',
    defaultMessage: 'Meny',
  },
  back: {
    id: 'appBar.navigateBack',
    defaultMessage: 'Navigera bakåt',
  },
  logout: {
    id: 'appBar.logout',
    defaultMessage: 'Logga ut',
  },
  skipToMain: {
    id: 'skipToMain',
    defaultMessage: 'Hoppa till huvudinnehåll',
  },
  userMenu: {
    id: 'appBar.userMenu',
    defaultMessage: 'Användarmeny',
  },
});
