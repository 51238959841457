import { Loading } from '@frontend/ui';
import { client } from 'app/apollo/client';
import { clearBrowserStore } from 'app/browser-store';
import { getBackstageCsrfTokenFromCookie } from 'app/helper';
import axios from 'axios';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { logoutMessages } from './messages';

type Props = RouteComponentProps<
  object,
  object,
  { from?: string; loggedOut?: boolean }
>;

export const Logout: React.FC<Props> = ({
  location: { state },
  history: { push },
}) => {
  const [logoutError, setLogoutError] = useState<boolean>(false);

  const clearState = async (): Promise<void> => {
    try {
      await client.resetStore();
      await clearBrowserStore();

      push({
        pathname: '/login',
        search: state?.from ? `?next=${state.from}` : undefined,
        state: { ...state, loggedOut: true },
      });
    } catch {
      setLogoutError(true);
    }
  };

  const logout = async (): Promise<void> => {
    setLogoutError(false);

    const csrfToken = getBackstageCsrfTokenFromCookie();
    if (!csrfToken) {
      clearState();
      return;
    }

    try {
      await axios.delete(`${window.env.API}/session`, {
        withCredentials: true,
        headers: { 'X-Csrf-Token': csrfToken },
      });
      clearState();
    } catch (error) {
      setLogoutError(true);
    }
  };

  useEffect(() => {
    logout();
  }, []);

  if (logoutError)
    return (
      <NotificationCard type="warning">
        <FormattedMessage {...logoutMessages.logoutError} />
      </NotificationCard>
    );

  return (
    <Loading message={<FormattedMessage {...logoutMessages.loggingOut} />} />
  );
};
