import { benefitPackageMembershipsQuery as Query } from 'app/apollo/graphql/types';

interface FetchMore {
  fetchMoreResult?: Query;
}

export const updateQuery = (
  previousResult: Query,
  { fetchMoreResult }: FetchMore,
): Query => {
  if (
    !previousResult?.benefitPackage?.memberships ||
    !fetchMoreResult?.benefitPackage?.memberships
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.benefitPackage.memberships;

  return {
    benefitPackage: {
      ...previousResult.benefitPackage,
      memberships: {
        ...previousResult.benefitPackage.memberships,
        edges: [...edges],
        pageInfo,
      },
    },
  };
};
