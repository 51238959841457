import { ContentContainer, defaultTheme } from '@frontend/ui';
import { messages } from 'app/messages';
import { FormattedMessage, useIntl } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Page } from 'features/page';
import React from 'react';
import { styled } from 'styled-components';

import { useCurrentUser } from '../../contexts/current-permissions';

const P = styled(ContentContainer)`
  font-style: italic;
  color: ${defaultTheme.nordeaGray};
`;

export const IndexRoute: React.FC = () => {
  const { firstName, permissions } = useCurrentUser();
  const { formatMessage } = useIntl();

  const welcomeMessages = Object.keys(messages).filter(key =>
    key.startsWith('welcome'),
  ).length;

  const msg = `welcome${Math.floor(Math.random() * welcomeMessages + 1)}`;

  const hour = new Date().getHours();
  let greeting;

  if (hour > 4 && hour < 6) {
    greeting = messages.gold;
  } else if (hour < 9) {
    greeting = messages.morning;
  } else if (hour < 11) {
    greeting = messages.earlyDay;
  } else if (hour < 13) {
    greeting = messages.day;
  } else if (hour < 17) {
    greeting = messages.lateDay;
  } else if (hour < 19) {
    greeting = messages.evening;
  } else if (hour < 22) {
    greeting = messages.night;
  } else {
    greeting = messages.midnight;
  }

  return (
    <NavigationAnchor title={messages.backstage} url="/">
      <Page title={<FormattedMessage {...greeting} values={{ firstName }} />}>
        <P>
          {permissions && Object.keys(permissions).length ? (
            `"${formatMessage(messages[msg])}"`
          ) : (
            <FormattedMessage {...messages.goodbye} />
          )}
        </P>
      </Page>
    </NavigationAnchor>
  );
};
