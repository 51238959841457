import gql from 'graphql-tag';

export const PENDING_SALARY_EXCHANGE_REQUESTS_CARD_QUERY = gql`
  query pendingSalaryExchangeRequestsCardQuery(
    $companyId: ID!
    $statuses: [FlexSalaryExchangeRequestStatus!]
  ) {
    salaryExchangeRequests(companyId: $companyId, statuses: $statuses) {
      totalCount
    }
    bonusSalaryExchangeRequests(companyId: $companyId, statuses: $statuses) {
      totalCount
    }
  }
`;
