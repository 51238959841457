import { Section } from '@frontend/ui';
import {
  companyAdministratorsQuery,
  companyAdministratorsQueryVariables,
  MembershipRole,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { GraphQlError } from 'components/GraphQlError';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { AdministratorsTable } from './components/AdministratorsTable';
import { EditAdministratorModal } from './edit';
import { COMPANY_ADMINISTRATORS_QUERY } from './graphql/queries';
import { updateCompanyAdministratorsQuery } from './lib';

interface EditModalState {
  isOpen: boolean;
  membershipRoles?: Array<MembershipRole>;
  userAccountId?: string;
}

export const Administrators: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({
  location: { search },
  match: {
    params: { companyId },
  },
}) => {
  const { per_page } = qs.parse(search);

  const [{ isOpen, userAccountId, membershipRoles }, setEditModalState] =
    useState<EditModalState>({
      isOpen: false,
      userAccountId: undefined,
      membershipRoles: undefined,
    });

  const openEditModal = (
    _userAccountId?: string,
    _membershipRoles?: Array<MembershipRole>,
  ) =>
    setEditModalState({
      isOpen: true,
      userAccountId: _userAccountId,
      membershipRoles: _membershipRoles,
    });

  const {
    previousData,
    data: _data,
    loading,
    error,
    fetchMore,
  } = useQuery<companyAdministratorsQuery, companyAdministratorsQueryVariables>(
    COMPANY_ADMINISTRATORS_QUERY,
    {
      skip: !companyId,
      variables: {
        companyId: companyId ?? '',
        first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      },
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    },
  );

  const data = _data ?? previousData;

  if (!data && loading) {
    return <TopLoading />;
  }

  const pageInfo = data?.company?.administrators?.pageInfo;
  const administrators =
    data?.company?.administrators?.edges.map(edge => edge.node) ?? [];

  return (
    <>
      <Section>
        {loading && <TopLoading />}
        {error && <GraphQlError error={error} />}
        {administrators && pageInfo && (
          <AdministratorsTable
            administrators={administrators}
            openEditModal={openEditModal}
            companyId={companyId}
            navigation={
              <TableNavigation
                pageInfo={pageInfo}
                onNextPage={() => {
                  fetchMore({
                    variables: {
                      after: pageInfo.endCursor,
                    },
                    updateQuery: updateCompanyAdministratorsQuery,
                  });
                }}
                onPreviousPage={() => {
                  fetchMore({
                    variables: {
                      before: pageInfo.startCursor,
                      last: per_page
                        ? parseInt(per_page, 10)
                        : DEFAULT_RESULT_PER_PAGE,
                    },
                    updateQuery: updateCompanyAdministratorsQuery,
                  });
                }}
              />
            }
          />
        )}
      </Section>
      <EditAdministratorModal
        companyId={companyId}
        isOpen={isOpen}
        userAccountId={userAccountId}
        onRequestClose={() =>
          setEditModalState(state => ({ ...state, isOpen: false }))
        }
        roles={membershipRoles}
      />
    </>
  );
};
