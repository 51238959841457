import { ContentContainer } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Create } from 'features/companies/company/job-offers/create';
import { jobOfferMessages } from 'features/companies/company/job-offers/messages';
import { Page } from 'features/page';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams } from '../..';

export const CreateRoute: React.FC<RouteComponentProps<MatchParams>> = ({
  match: { params, url },
}) => (
  <NavigationAnchor title={jobOfferMessages.newJobOffer} url={url}>
    <Page
      parentLink={`companies/${params.companyId}/job-offers`}
      title={<FormattedMessage {...jobOfferMessages.newJobOffer} />}
    >
      <Suspense batch fallback={<TopLoading />}>
        {({ errors }) => (
          <ContentContainer>
            {errors && <GraphQlErrors errors={errors} />}
            <Create />
          </ContentContainer>
        )}
      </Suspense>
    </Page>
  </NavigationAnchor>
);
