import {
  code,
  formatBold,
  formatItalic,
  formatListBulleted,
  formatListNumbered,
  formatUnderlined,
  insertLink,
  linkOff,
  restartAlt,
  strikethroughS,
  subscript,
  superscript,
} from '@frontend/ui/icons';

import { BlockType, Embedded, History, Inline, Link, List } from './components';

export const toolbarConfig = (wysiwyg?: boolean) => ({
  options: [
    'blockType',
    'inline',
    'list',
    'link',
    wysiwyg ? '' : 'embedded',
    'history',
  ].filter(option => !!option),
  inline: {
    options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
    component: Inline,
    bold: {
      icon: formatBold,
    },
    italic: {
      icon: formatItalic,
    },
    underline: {
      icon: formatUnderlined,
    },
    strikethrough: {
      icon: strikethroughS,
    },
    superscript: {
      icon: superscript,
    },
    subscript: {
      icon: subscript,
    },
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H5'],
    component: BlockType,
  },
  list: {
    component: List,
    options: ['unordered', 'ordered'],
    unordered: { icon: formatListBulleted },
    ordered: { icon: formatListNumbered },
  },
  link: {
    inDropdown: false,
    showOpenOptionOnHover: false,
    defaultTargetOption: '_self',
    options: ['link', 'unlink'],
    component: Link,
    link: { icon: insertLink },
    unlink: { icon: linkOff },
  },
  embedded: {
    inDropdown: false,
    icon: code,
    component: Embedded,
    defaultSize: {
      height: '450',
      width: '800',
    },
    embedCallback: link => {
      const src: string[] = link.match(/<iframe.*? src="(.*?)"/);
      return src ? src[1] : link;
    },
  },
  history: {
    inDropdown: false,
    options: ['undo'],
    component: History,
    undo: {
      icon: restartAlt,
    },
  },
});
