import { ContentContainer } from '@frontend/ui';
import { companyMessages } from 'app/messages/company';
import { sharedFileMessages } from 'app/messages/shared-files';
import { FormattedMessage } from 'components/formats';
import { RoutedTab } from 'components/RoutedTabBar';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { SalaryReports } from 'features/companies/company/salary-reports';
import { SharedFiles } from 'features/companies/company/shared-files';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { MatchParams as CompanyMatchParams } from '..';

export const Reports: React.FC<RouteComponentProps<CompanyMatchParams>> = ({
  match,
}) => {
  const tabs: RoutedTab[] = [
    {
      title: companyMessages.reports,
      url: match.url,
    },
    {
      title: sharedFileMessages.sharedFiles,
      url: `${match.url}/shared-files`,
    },
  ];

  return (
    <NavigationAnchor title={companyMessages.reportsAndFiles} url={match.url}>
      <Switch>
        <Route exact path={tabs.map(({ url }) => url)}>
          <Page
            title={<FormattedMessage {...companyMessages.reportsAndFiles} />}
            parentLink={`/companies/${match.params.companyId}`}
            tabs={tabs}
          >
            <ContentContainer>
              <Route exact path={match.path} component={SalaryReports} />
              <Route
                exact
                path={`${match.path}/shared-files`}
                component={SharedFiles}
              />
            </ContentContainer>
          </Page>
        </Route>
      </Switch>
    </NavigationAnchor>
  );
};
