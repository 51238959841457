import { NumberField } from '@frontend/formik';
import { Section, SectionHeader } from '@frontend/ui';
import { commonMessages, suffixMessages } from 'app/messages/common';
import { workInformationMessages } from 'app/messages/employees';
import { FormattedMessage, useIntl } from 'components/formats';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';

export interface VacationFormValues {
  vacationDaysAdvance?: number;
  vacationDaysPaid?: number;
  vacationDaysPerYear?: number;
  vacationDaysSaved?: number;
  vacationDaysUnpaid?: number;
}

export const VacationFields: React.FC = () => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonMessages.vacation} />
      </SectionHeader>
      <NumberField
        dense
        name="vacationDaysSaved"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.vacationDaysSavedLabel}
          />
        }
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
      <NumberField
        dense
        name="vacationDaysPaid"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.vacationDaysPaidLabel}
          />
        }
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
      <NumberField
        dense
        name="vacationDaysUnpaid"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.vacationDaysUnpaidLabel}
          />
        }
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
      <NumberField
        dense
        name="vacationDaysAdvance"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.vacationDaysAdvanceLabel}
          />
        }
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
      <NumberField
        dense
        name="vacationDaysPerYear"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.vacationDaysPerYearLabel}
          />
        }
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
    </Section>
  );
};
