import { Icon, Table, Th, Tr } from '@frontend/ui';
import { edit } from '@frontend/ui/icons';
import { pageInfoDetails } from 'app/apollo/graphql/types';
import { a11yMessages } from 'app/messages/a11y';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import { TableNavigation } from 'components/TableNavigation';
import React from 'react';
import { LinkProps } from 'react-router-dom';

interface TableProps {
  children: React.ReactNode;
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
  columns?: string[];
  editLinks?: boolean;
  perPage?: string;
  setPerPage?: (perPage: string) => void;
}

export const AccountPlanTable: React.FC<TableProps> = ({
  columns,
  editLinks,
  pageInfo,
  onNextPage,
  onPreviousPage,
  perPage,
  setPerPage,
  children,
}) => (
  <Table
    fixed
    size="xsmall"
    navigation={
      <TableNavigation
        pageInfo={pageInfo}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    }
  >
    <thead>
      <Tr withInlineMenuTh={editLinks}>
        {columns && columns.map(column => <Th key={column}>{column}</Th>)}
      </Tr>
    </thead>
    <tbody>{children}</tbody>
  </Table>
);
interface TableRowProps {
  children: React.ReactNode;
  editLink?: LinkProps['to'];
}

export const AccountPlanTableRow: React.FC<TableRowProps> = ({
  editLink,
  children,
}) => (
  <Tr
    inlineMenu={
      editLink ? (
        <KebabMenu label={a11yMessages.accountPlanEditMenu} fixed>
          <MenuItem
            title={<FormattedMessage {...commonMessages.update} />}
            link={editLink}
            icon={<Icon icon={edit} decorative />}
          />
        </KebabMenu>
      ) : undefined
    }
  >
    {children}
  </Tr>
);
