import { ContentContainer, Section } from '@frontend/ui';
import { commonBenefitMessages } from 'app/messages/benefits';
import { FormattedMessage } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { AddBenefitsToBenefitPackage } from 'features/companies/company/benefit-packages/add-benefits';
import { Page } from 'features/page';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '..';

interface BenefitPackage {
  name: string;
}

interface Props {
  benefitPackage: BenefitPackage;
}

export const BenefitPackageAddBenefitsRoute: React.FC<Props> = ({
  benefitPackage,
}) => {
  const { url, params } = useRouteMatch<MatchParams>();

  const parentLink = `/companies/${params.companyId}/benefit-packages/${params.benefitPackageId}`;
  return (
    <NavigationAnchor
      title={commonBenefitMessages.addBenefitToBenefitPackageBreadcrumb}
      url={url}
    >
      <Page
        parentLink={parentLink}
        title={
          <FormattedMessage
            {...commonBenefitMessages.addBenefitsToBenefitPackage}
            values={{
              name: benefitPackage.name,
            }}
          />
        }
      >
        <ContentContainer>
          <Section>
            <AddBenefitsToBenefitPackage parentLink={parentLink} />
          </Section>
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
