import { ApolloError, useMutation } from '@apollo/client';
import {
  deleteBenefitMutation,
  deleteBenefitMutationVariables,
  DeleteCompanyRepresentativeInput,
} from 'app/apollo/graphql/types';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitsMessages } from 'features/sme/messages/sme';

import { clearProposalCache } from '../../../utils/clear-proposal-cache';
import { DELETE_BENEFIT_MUTATION } from './graphql/mutations';

interface Submit {
  _delete: (input: DeleteCompanyRepresentativeInput) => Promise<void>;
  deleteLoading: boolean;
  deleteError?: ApolloError;
}
interface Params {
  params: ProposalMatchParams;
}

export const useDelete = ({ params }: Params): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [deleteBenefit, { loading: deleteLoading, error: deleteError }] =
    useMutation<deleteBenefitMutation, deleteBenefitMutationVariables>(
      DELETE_BENEFIT_MUTATION,
      {
        update: clearProposalCache({
          proposalId: params.proposalId,
          fieldNames: ['cost', 'proposals', 'validity'],
        }),
      },
    );

  const _delete = async (input: DeleteCompanyRepresentativeInput) => {
    try {
      const res = await deleteBenefit({
        variables: {
          input,
        },
      });
      const proposalId = res.data?.deleteProposalBenefit?.id;
      if (!proposalId) {
        return;
      }
      send({
        message: formatMessage(smeBenefitsMessages.benefitDeleted),
        type: 'success',
      });
    } catch {
      // Do nothing
    }
  };

  return {
    _delete,
    deleteLoading,
    deleteError,
  };
};
