import { validationMessages } from 'app/messages/common';
import { IntlShape } from 'components/formats';
import * as Yup from 'yup';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    adviceWaived: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    companySize: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });
