import {
  BenefitType,
  isBenefitType,
  isDisabilityInsuranceBenefit,
} from '@frontend/benefit-types';
import { getMostRecentEmployment, toNumber } from '@frontend/utils';
import {
  employeeOverviewNotificationsQuery,
  employeeOverviewNotificationsQueryVariables,
} from 'app/apollo/graphql/types';
import { workInformationMessages } from 'app/messages/employees';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { EMPLOYEE_OVERVIEW_NOTIFICATIONS_QUERY } from './graphql/queries';

const sicknessInsuranceBenefitTypeNames: BenefitType[] = [
  'euro_accident_plansjuk',
];
const otherRiskInsuranceBenefitTypeNames: BenefitType[] = [
  'euro_accident_accident',
  'euro_accident_life',
  'euro_accident_tgl',
  'euro_accident_privataccess',
];

const benefitTypeNames: BenefitType[] = [
  ...sicknessInsuranceBenefitTypeNames,
  ...otherRiskInsuranceBenefitTypeNames,
];

export const EmployeeOverviewNotifications: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data } = useQuery<
    employeeOverviewNotificationsQuery,
    employeeOverviewNotificationsQueryVariables
  >(EMPLOYEE_OVERVIEW_NOTIFICATIONS_QUERY, {
    variables: {
      userAccountId,
      benefitTypeNames,
      companyId,
    },
    errorPolicy: 'all',
    suspend: true,
  });

  if (!data?.entitlements?.edges.length || !data.membership) {
    return null;
  }

  const { email } = data.membership;
  const insuranceBenefitTypeNames: BenefitType[] = data.entitlements.edges
    .map(({ node }) => node.benefit.type)
    .filter(isBenefitType);

  const sicknessInsuranceBenefit = data.entitlements.edges
    .map(({ node }) => node.benefit)
    .find(isDisabilityInsuranceBenefit);
  const payrollElementCode =
    sicknessInsuranceBenefit?.configuration?.data?.payrollElementCode;

  const employments = data.membership?.employment;
  const latestEmployment = employments
    ? getMostRecentEmployment(employments)
    : undefined;

  const missingRemuneration = !latestEmployment?.remuneration.some(
    ({ type }) => type.id === payrollElementCode,
  );
  const missingRemunerationValue = !toNumber(
    latestEmployment?.remuneration.find(
      ({ type }) => type.id === payrollElementCode,
    )?.price ?? 0,
  );
  const showRemunerationWarning =
    missingRemuneration || missingRemunerationValue;

  const remunerationName = data.company?.remunerationTypes?.edges
    .map(({ node }) => node)
    .find(({ id }) => id === payrollElementCode)?.name;
  const remunerationType = remunerationName ?? payrollElementCode;

  return (
    <>
      {!email &&
        insuranceBenefitTypeNames.some(type =>
          benefitTypeNames.includes(type),
        ) && (
          <NotificationCard fullBleed type="warning">
            <FormattedMessage
              {...workInformationMessages.emailInsuranceWarning}
            />
          </NotificationCard>
        )}
      {showRemunerationWarning &&
        insuranceBenefitTypeNames.some(type =>
          sicknessInsuranceBenefitTypeNames.includes(type),
        ) && (
          <NotificationCard fullBleed type="warning">
            <FormattedMessage
              {...workInformationMessages.remunerationInsuranceWarning}
              values={{
                remunerationType,
              }}
            />
          </NotificationCard>
        )}
    </>
  );
};
