import gql from 'graphql-tag';

export const SET_INSURANCE_DELETED_MUTATION = gql`
  mutation setInsuranceDeleted(
    $instituteId: String!
    $insuranceNumber: String!
  ) {
    setInsuranceDeleted(
      input: {
        instituteId: $instituteId
        insuranceNumber: $insuranceNumber
        deleted: true
      }
    ) {
      insurance {
        id
        deletedAt
      }
    }
  }
`;
