import { ApolloError } from '@apollo/client';
import { Form, FormikProps, SelectField, TextField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { NoteCategory } from 'app/apollo/graphql/types';
import { formMessages } from 'app/messages/form';
import { noteCategoryMessages, noteMessages } from 'app/messages/notes';
import { OneOfUserAccountIdOrCompanyId } from 'app/utils/use-upload-files';
import { FileInfo } from 'components/FileUploadTable';
import { FormattedMessage, useIntl } from 'components/formats';
import { FileUploadField } from 'components/formik/FileUploadField';
import { RichTextEditor } from 'components/formik/RichTextEditor';
import { GraphQlError } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import React from 'react';
import styled from 'styled-components';

export interface NoteFormValues {
  attachments?: FileInfo[];
  category?: NoteCategory;
  content?: string;
  subject?: string;
}

type NoteFormProps = {
  companyId: string;
  userAccountId?: string;
  submissionError?: ApolloError;
} & FormikProps<NoteFormValues>;

const StyleWrapper = styled.div`
  .rdw-editor-wrapper {
    border-radius: 0.25rem;
    border: 0.0625rem solid ${p => p.theme.outline};
  }
  .rdw-editor-main {
    margin-top: 0;
    padding: 8px;
  }
  .public-DraftEditor-content > div > * {
    width: 100%;
  }
`;

export const NoteForm: React.FC<NoteFormProps> = ({
  companyId,
  userAccountId,
  submissionError,
  isSubmitting,
}) => {
  const { formatMessage } = useIntl();

  const userAccountIdOrCompanyId = {
    companyId: !userAccountId ? companyId : undefined,
    userAccountId,
  } as OneOfUserAccountIdOrCompanyId;

  return (
    <Form>
      <Section>
        <TextGrid>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...noteMessages.details} />
            </SubsectionHeader>
            <TextField
              name="subject"
              label={<FormattedMessage {...noteMessages.subject} />}
              required
              dense
            />
            <SelectField
              name="category"
              label={<FormattedMessage {...noteMessages.category} />}
              required
              dense
              options={Object.values(NoteCategory).map(category => ({
                label: formatMessage({
                  select: category,
                  messages: noteCategoryMessages,
                }),
                value: category,
              }))}
            />
            <StyleWrapper>
              <RichTextEditor
                name="content"
                markup="markdown"
                toolbarHidden
                stripPastedStyles
                placeholder={formatMessage(noteMessages.content)}
              />
            </StyleWrapper>
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...noteMessages.attachFiles} />
            </SubsectionHeader>
            <FileUploadField
              name="attachments"
              label={<FormattedMessage {...noteMessages.selectFiles} />}
              {...userAccountIdOrCompanyId}
            />
          </Subsection>
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout align="left">
            <Button filled type="submit" loading={isSubmitting}>
              <FormattedMessage {...formMessages.save} />
            </Button>
          </ButtonLayout>
        </TextGrid>
      </Section>
    </Form>
  );
};
