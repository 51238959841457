import { Section, SectionHeader } from '@frontend/ui';
import {
  totalCompensationDetailsQuery,
  totalCompensationDetailsQueryVariables,
} from 'app/apollo/graphql/types';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { Table } from '../components/Table';
import { TOTAL_COMPENSATION_DETAILS_QUERY } from './graphql/queries';
import { getTotalCompDetails } from './utils/get-total-comp-details';

export const Details: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data } = useQuery<
    totalCompensationDetailsQuery,
    totalCompensationDetailsQueryVariables
  >(TOTAL_COMPENSATION_DETAILS_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    variables: {
      companyId,
      userAccountId,
    },
    isEmpty: d => !getTotalCompDetails(d),
  });

  const totalCompDetails = getTotalCompDetails(data);

  if (!totalCompDetails) {
    return null;
  }

  const {
    cashItems,
    pensionItems,
    benefitItems,
    sections,
    employerContributionShare,
    totalCompensationConfiguration,
  } = totalCompDetails;

  return (
    <>
      {!!totalCompensationConfiguration?.salary && !!cashItems.length && (
        <Section>
          <SectionHeader>
            <FormattedMessage {...totalCompensationMessages.salary} />
          </SectionHeader>
          <Table items={cashItems} />
        </Section>
      )}
      {!!totalCompensationConfiguration?.variableCompensation &&
        !!sections.BONUS?.length && (
          <Section>
            <SectionHeader>
              <FormattedMessage
                {...totalCompensationMessages.variableCompensation}
              />
            </SectionHeader>
            <Table items={sections.BONUS} />
          </Section>
        )}
      {!!pensionItems.length && (
        <Section>
          <SectionHeader>
            <FormattedMessage {...totalCompensationMessages.pension} />
          </SectionHeader>
          <Table items={pensionItems} />
        </Section>
      )}
      {!!benefitItems.length && (
        <Section>
          <SectionHeader>
            <FormattedMessage {...totalCompensationMessages.other} />
          </SectionHeader>
          <Table items={benefitItems} />
        </Section>
      )}
      {!!sections.EMPLOYERS_CONTRIBUTIONS?.length && (
        <Section>
          <SectionHeader>
            <FormattedMessage
              {...totalCompensationMessages.employersContributionsAndspecialPayrollTax}
            />
          </SectionHeader>
          <Table
            items={sections.EMPLOYERS_CONTRIBUTIONS}
            employerContributionShare={employerContributionShare}
          />
        </Section>
      )}
    </>
  );
};
