import { Synsam } from '@frontend/benefit-types';
import { Formik } from '@frontend/formik';
import {
  companyEditBenefitQuery,
  companyEditBenefitQuery_flexBenefit_FlexBenefit as Benefit,
  companyEditBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import format from 'date-fns/format';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { FormValues, SynsamForm, validationSchema } from '../../form/synsam';
import { getBenefitPackageOptions } from '../../form/utils';
import { COMPANY_EDIT_BENEFIT_QUERY } from '../graphql/queries';
import { useSubmit } from './utils/use-submit';

export const EditSynsam: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    companyEditBenefitQuery,
    companyEditBenefitQueryVariables
  >(COMPANY_EDIT_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, companyId: params.companyId },
  });

  const { submit, submissionError } = useSubmit();

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company?.benefitPackages || !data.flexBenefit?.entitlementRules) {
    return <EmptyState error={error} />;
  }

  const benefit: Synsam<Benefit> = data.flexBenefit;

  const { benefitPackages } = data.company;

  const benefitPackageOptions = getBenefitPackageOptions(
    'synsam',
    benefitPackages,
    benefit.id,
  );

  const { configEffectiveDate, entitlementRules } = benefit;

  if (!entitlementRules) {
    return null;
  }

  const effectiveFrom = entitlementRules.from
    ? format(new Date(entitlementRules.from), 'yyyy-MM')
    : '';

  const initialValues: FormValues = {
    benefitPackageIds: [...entitlementRules.benefitPackageIds],
    effectiveFrom,
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={values => submit(values, benefit)}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      <SynsamForm
        benefitPackageOptions={benefitPackageOptions}
        isEdit
        latestChangesEffectiveDate={configEffectiveDate}
        submissionError={submissionError}
      />
    </Formik>
  );
};
