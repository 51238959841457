import {
  Card,
  IconContent,
  NotificationCardLayout,
  NotificationCardLayoutProps,
  NotificationType,
} from '@frontend/ui';
import { check, error, info, warning } from '@frontend/ui/icons';
import React from 'react';
import styled from 'styled-components';

const NOTIFICATION_ICON: Record<NotificationType, IconContent> = {
  warning,
  success: check,
  error,
  info,
};

const StyledCard = styled(Card)`
  margin-bottom: 1.5rem;
`;

const NoTitleNotificationCardLayout = styled(NotificationCardLayout)`
  padding-left: 0;
`;

interface NotificationCardProps
  extends Omit<NotificationCardLayoutProps, 'icon'> {
  inModal?: boolean;
}

export const NotificationCard: React.FC<NotificationCardProps> = ({
  title,
  type,
  actions,
  inModal,
  fullBleed,
  children,
}) => {
  if (inModal) {
    return (
      <NoTitleNotificationCardLayout
        fullBleed
        type={type}
        icon={type && NOTIFICATION_ICON[type]}
      >
        {children}
      </NoTitleNotificationCardLayout>
    );
  }

  return (
    <StyledCard>
      <NotificationCardLayout
        fullBleed={fullBleed}
        title={title}
        type={type}
        icon={type && NOTIFICATION_ICON[type]}
        actions={actions}
      >
        {children}
      </NotificationCardLayout>
    </StyledCard>
  );
};
