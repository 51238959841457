import { ApolloError } from '@apollo/client';
import {
  accountingDimensionsQuery,
  accountingDimensionsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { useRouteMatch } from 'react-router-dom';

import { ACCOUNTING_DIMENSIONS_QUERY } from '../graphql/queries';

interface AccountingDimension {
  loading: boolean;
  error?: ApolloError;
  identifier?: string;
  name?: string;
}

export const useAccountingDimension = (
  dimensionId?: string,
): AccountingDimension => {
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { data, loading, error } = useQuery<
    accountingDimensionsQuery,
    accountingDimensionsQueryVariables
  >(ACCOUNTING_DIMENSIONS_QUERY, {
    variables: { companyId, id: dimensionId },
    skip: !dimensionId,
    errorPolicy: 'all',
  });

  return {
    loading,
    error,
    name: data?.accountingDimensions?.length
      ? data?.accountingDimensions[0].name
      : undefined,
    identifier: data?.accountingDimensions?.length
      ? data?.accountingDimensions[0].identifier ?? undefined
      : undefined,
  };
};
