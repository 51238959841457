import gql from 'graphql-tag';

export const CREATE_NOTE_MUTATION = gql`
  mutation createNoteMutation($input: CreateNoteInput!) {
    createNote(input: $input) {
      clientMutationId
      id
    }
  }
`;

export const UPDATE_NOTE_MUTATION = gql`
  mutation updateNoteMutation($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      clientMutationId
      id
    }
  }
`;

export const DELETE_NOTE_MUTATION = gql`
  mutation deleteNoteMutation($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      clientMutationId
      id
    }
  }
`;
