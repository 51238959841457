import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const SALARY_EXCHANGES_TABLE_QUERY = gql`
  query salaryExchangesTableQuery(
    $companyId: ID!
    $statuses: [FlexSalaryExchangeStatus!]
    $onlyViolated: Boolean
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    salaryExchanges(
      companyId: $companyId
      statuses: $statuses
      onlyViolated: $onlyViolated
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          id
          companyId
          userAccountId
          effectiveDate
          deductionValue
          premium
          provider
          status
          violated
          membership {
            id
            givenName
            lastName
          }
          remuneration {
            unitCode
            currency
            value: price
          }
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${pageInfoDetails}
`;
