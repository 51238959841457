import { CheckboxField, DatePickerField } from '@frontend/formik';
import { WizardSection, WizardSectionHeader } from '@frontend/ui';
import { employeesImportMessages } from 'app/messages/employees';
import { menuMessages } from 'app/messages/menu';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import React from 'react';

export const ImportSettings = () => (
  <>
    <WizardSection>
      <WizardSectionHeader>
        <FormattedMessage {...menuMessages.organizationalUnits} />
      </WizardSectionHeader>
      <CheckboxField
        name="createMissingOrganizationalUnits"
        label={
          <FormattedMessage
            {...employeesImportMessages.createMissingOrgUnits}
          />
        }
      />
    </WizardSection>
    <WizardSection>
      <WizardSectionHeader>
        <FormattedMessage
          {...employeesImportMessages.benefitPackageEffectiveDateTitle}
        />
      </WizardSectionHeader>
      <DescriptionWrapper>
        <FormattedMessage
          {...employeesImportMessages.benefitPackageEffectiveDateDescription}
        />
      </DescriptionWrapper>

      <DatePickerField
        name="fallbackDateForBenefits"
        type="month"
        dense
        required
        label={
          <FormattedMessage
            {...employeesImportMessages.fallbackBenefitPackageEffectiveDateLabel}
          />
        }
        helperText={
          <FormattedMessage
            {...employeesImportMessages.fallbackBenefitPackageEffectiveDateHelperText}
          />
        }
      />
      <CheckboxField
        name="benefitsStartWithEmployment"
        label={
          <FormattedMessage
            {...employeesImportMessages.benefitsStartWithEmploymentsLabel}
          />
        }
      />
    </WizardSection>
  </>
);
