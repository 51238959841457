import gql from 'graphql-tag';

export const DELETE_BENEFIT_MUTATION = gql`
  mutation deleteBenefitMutation($input: DeleteProposalBenefitInput!) {
    deleteProposalBenefit(input: $input) {
      id
      benefits {
        id
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
