import gql from 'graphql-tag';

import { totalCompensationConfigurationDetails } from './fragments';

export const UPDATE_TOTAL_COMPENSATION_CONFIGURATION_MUTATION = gql`
  mutation updateTotalCompensationConfigurationMutation(
    $input: TotalCompensationConfigurationInput!
  ) {
    updateTotalCompensationConfiguration(input: $input) {
      ...totalCompensationConfigurationDetails
    }
  }
  ${totalCompensationConfigurationDetails}
`;
