import { toNumber } from '@frontend/utils';
import { smeCompanyEmployeeQuery_proposalMembership_ProposalMembership as Employee } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { DescriptionTable } from 'components/DescriptionTable';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import React from 'react';

import {
  FixedPremiumType,
  getFixedPremiumPensionConfig,
} from '../../form/utils';

interface Props {
  employee: Employee;
}

export const InformationTable: React.FC<Props> = ({ employee }) => {
  const fixedPremiumType = getFixedPremiumPensionConfig(
    employee.benefitPackage,
  );

  const isEntitledToFixedPremium =
    fixedPremiumType === FixedPremiumType.FIXED_PREMIUM_ONLY ||
    fixedPremiumType === FixedPremiumType.EXTRA_FIXED_PREMIUM;

  return (
    <DescriptionTable
      alignLeft
      entries={[
        {
          id: 'personalIdentityNumber',
          label: <FormattedMessage {...commonMessages.nid} />,
          value: (
            <FormattedNaturalPersonIdentifier
              nid={employee.personalIdentityNumber}
            />
          ),
        },
        {
          id: 'name',
          label: <FormattedMessage {...commonMessages.name} />,
          value: `${employee.firstName} ${employee.lastName}`,
        },
        {
          id: 'email',
          label: <FormattedMessage {...commonMessages.email} />,
          value: employee.email,
        },
        {
          id: 'phone',
          label: <FormattedMessage {...commonMessages.phone} />,
          value: employee.phoneNumber,
        },
        {
          id: 'benefitPackage',
          label: <FormattedMessage {...commonMessages.benefitPackage} />,
          value: employee.benefitPackage.name,
        },
        {
          id: 'monthlySalary',
          label: <FormattedMessage {...commonMessages.monthlySalary} />,
          value: employee.monthlySalary ? (
            <FormattedCurrency
              currency="SEK"
              value={toNumber(employee.monthlySalary) ?? 0}
              after="PER_MONTH"
            />
          ) : (
            <NoValue />
          ),
        },
        ...(isEntitledToFixedPremium
          ? [
              {
                id: 'monthlyFixedPensionPremium',
                label: (
                  <FormattedMessage
                    {...smeEmployeesMessages.monthlyFixedPensionAlt}
                  />
                ),
                value: employee.monthlyFixedPensionPremium ? (
                  <FormattedCurrency
                    currency="SEK"
                    value={toNumber(employee.monthlyFixedPensionPremium) ?? 0}
                    after="PER_MONTH"
                  />
                ) : (
                  <NoValue />
                ),
              },
            ]
          : []),
        {
          id: 'isOwner',
          label: (
            <FormattedMessage {...smeEmployeesMessages.isOwnerShortLabel} />
          ),
          value: (
            <FormattedMessage
              {...(employee.isOwner ? commonMessages.yes : commonMessages.no)}
            />
          ),
        },
        {
          id: 'fcfw',
          label: <FormattedMessage {...smeEmployeesMessages.isFcfw} />,
          value:
            employee.isFcfw != null ? (
              <FormattedMessage
                {...(employee.isFcfw ? commonMessages.yes : commonMessages.no)}
              />
            ) : (
              <NoValue />
            ),
        },
      ]}
    />
  );
};
