import { CheckboxField, DatePickerField, SelectField } from '@frontend/formik';
import {
  Section,
  SectionHeader,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { CollectiveAgreement } from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import {
  employmentMessages,
  workInformationMessages,
} from 'app/messages/employees';
import { collectiveAgreementOptions } from 'app/utils/constants';
import { FormattedMessage, IntlShape } from 'components/formats';
import React from 'react';
import { isValidDate } from 'validations';
import * as Yup from 'yup';

export const employmentDataValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    employmentEffectiveDate: Yup.date()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid date',
        intl.formatMessage(validationMessages.invalidDate),
        value => isValidDate(value.toISOString()),
      ),
    employmentEffectiveThrough: Yup.date()
      .test(
        'valid or no date',
        intl.formatMessage(validationMessages.invalidDate),
        value => !value || isValidDate(value.toISOString()),
      )
      .when('employmentEffectiveDate', {
        is: employmentEffectiveDate => !!employmentEffectiveDate,
        then: schema =>
          schema.min(
            Yup.ref('employmentEffectiveDate'),
            intl.formatMessage(validationMessages.isBeforeStartDate),
          ),
      }),
  });

export interface EmploymentDataFormValues {
  employmentEffectiveDate: string;
  isOwner: boolean;
  collectiveAgreement?: CollectiveAgreement;
  employmentEffectiveThrough?: string;
}

export const employmentDataInitialValues = {
  employmentEffectiveDate: '',
  isOwner: false,
};

export const EmploymentDataFields: React.FC = () => (
  <Section>
    <SectionHeader>
      <FormattedMessage {...workInformationMessages.employmentDetails} />
    </SectionHeader>
    <DatePickerField
      dense
      name="employmentEffectiveDate"
      label={<FormattedMessage {...employmentMessages.employmentStartDate} />}
      required
    />
    <DatePickerField
      dense
      name="employmentEffectiveThrough"
      label={<FormattedMessage {...employmentMessages.employmentEndDate} />}
    />
    <SelectField
      dense
      name="collectiveAgreement"
      label={
        <FormattedMessage
          {...workInformationMessages.collectiveAgreementLabel}
        />
      }
      options={collectiveAgreementOptions}
    />
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...workInformationMessages.ownerLabel} />
      </SubsectionHeader>
      <CheckboxField
        name="isOwner"
        label={<FormattedMessage {...workInformationMessages.owner} />}
      />
    </Subsection>
  </Section>
);
