import gql from 'graphql-tag';

export const BENEFIT_PACKAGES_OVERVIEW_QUERY = gql`
  query benefitPackagesOverviewQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
