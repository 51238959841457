import { employeeFormMessages } from 'app/messages/employees';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { DigitalAdviceTable } from 'features/companies/company/employees/employee/occupational/digital-advice';
import { PensionTable } from 'features/companies/company/employees/employee/occupational/pension';
import { SalaryExchange } from 'features/companies/company/employees/employee/occupational/salary-exchange';
import { SinglePremiumDepositHistoryTable } from 'features/companies/company/employees/employee/occupational/single-premium-history';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const Occupational = () => (
  <Suspense
    batch
    fallback={<TopLoading />}
    emptyState={
      <EmptyState
        title={<FormattedMessage {...employeeFormMessages.noPensionBenefits} />}
      >
        <FormattedMessage {...employeeFormMessages.noPensionBenefitsText} />
      </EmptyState>
    }
  >
    {({ errors }) => (
      <>
        {errors && <GraphQlErrors errors={errors} />}
        <PensionTable />
        <SinglePremiumDepositHistoryTable />
        <SalaryExchange />
        <DigitalAdviceTable />
      </>
    )}
  </Suspense>
);
