import axios from 'axios';
import { IntlShape } from 'components/formats';
import { Send } from 'features/notifications';
import StackTrace from 'stacktrace-js';

import { errorBoundaryMessages } from '../messages';

const SERVICE = 'frontstage';
const MODULE_NOT_FOUND = 'MODULE_NOT_FOUND';

// We're matching these strings with the message on the error
// object. A matching message indicates that a code bundle is missing.
// In turn, this is the result of a new frontend version having been
// published which the CDN has not yet cached. When this happens we
// try to ensure that the error reporter reports the same error
// regardless of which bundle is missing.
const MODULE_NOT_FOUND_MESSAGE = [
  `Uncaught SyntaxError: Unexpected token '<'`,
  `SyntaxError: Unexpected token '<'undefined:undefined`,
];

export interface ErrorContext {
  httpRequest: {
    url: string;
    userAgent: string;
  };
  user: string;
  reportLocation?: {
    filePath?: string;
    lineNumber?: number;
  };
}

export class InfoError extends Error {
  constructor(
    message: string,
    public info: Record<string, unknown>,
  ) {
    super(message);
  }
}

interface ErrorType extends Error {
  code?: string;
  info?: Record<string, unknown>;
}

export const report = async (
  error: ErrorType,
  context: ErrorContext,
  intl?: IntlShape,
  send?: Send,
): Promise<void> => {
  try {
    const isMissingBundle =
      error.code === MODULE_NOT_FOUND ||
      MODULE_NOT_FOUND_MESSAGE.some(m => error.message?.startsWith(m));

    const stackTrace = await StackTrace.fromError(error);

    // Convert stack trace into a readable error message
    const _message = isMissingBundle
      ? 'Error: Could not load js bundle. This error is likely due to missing CDN cache after a recent frontend deployment.'
      : stackTrace
        ? stackTrace.reduce((acc, stackFrame) => {
            const trace = `    at ${
              stackFrame.getFunctionName() || `<anonymous>`
            } (${stackFrame.getFileName()}${stackFrame.getLineNumber()}:${stackFrame.getColumnNumber()})`;
            return acc.concat(`\n${trace}`);
          }, error.message?.toString() || '')
        : undefined;

    const message = _message ?? 'Unable to build message from stack trace';

    const payload = {
      eventTime: new Date().toISOString(),
      message,
      info: error.info,
      context,
      serviceContext: {
        service: SERVICE,
      },
    };

    const reportUrl = `${window.env.API}/error-report`;
    const { status } = await axios.post(reportUrl, JSON.stringify(payload), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (status !== 200) {
      throw new Error(
        `Unable to publish payload: ${payload}. Network responded with: ${status}`,
      );
    }

    send?.({
      message: intl?.formatMessage(errorBoundaryMessages.successMessage),
      type: 'success',
    });
  } catch {
    send?.({
      message: intl?.formatMessage(errorBoundaryMessages.errorMessage),
      type: 'error',
    });
  }
};
