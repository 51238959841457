import { ApolloError, useMutation } from '@apollo/client';
import {
  importColumnsQuery_import_Import_columnMappings_ImportColumnMapping as SourceColumnPair,
  updateImportMutation,
  updateImportMutationVariables,
} from 'app/apollo/graphql/types';
import { useRouteMatch } from 'react-router';
import { monthToDate } from 'validations';

import { MatchParams } from '..';
import { UPDATE_IMPORT_MUTATION } from '../graphql/mutations';
import { FormValues, fromFormValues } from './form-values';

interface Params {
  onCompleted: () => void;
  currentColumns?: SourceColumnPair[] | null;
}

interface Submit {
  submissionLoading: boolean;
  submit: (values: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = ({ currentColumns, onCompleted }: Params): Submit => {
  const {
    params: { importId },
  } = useRouteMatch<MatchParams>();

  const [updateImport, { loading: submissionLoading, error: submissionError }] =
    useMutation<updateImportMutation, updateImportMutationVariables>(
      UPDATE_IMPORT_MUTATION,
    );

  const submit = async (values: FormValues) => {
    if (!values.updatedColumns || !currentColumns) {
      return;
    }
    const updatedColumnList = fromFormValues(
      values.updatedColumns,
      currentColumns,
    );
    await updateImport({
      variables: {
        input: {
          id: importId,
          generateChanges: true,
          columnMappings: updatedColumnList,
          createMissingOrganizationalUnits:
            values.createMissingOrganizationalUnits,
          benefitsStartWithEmployment: values.benefitsStartWithEmployment,
          fallbackDateForBenefits: monthToDate(values.fallbackDateForBenefits),
        },
      },
    });
    onCompleted();
  };

  return { submissionLoading, submit, submissionError };
};
