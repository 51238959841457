import { BenefitType } from '@frontend/benefit-types';
import { Figure, Icon } from '@frontend/ui';
import { swapHorizontalCircle } from '@frontend/ui/icons';
import {
  FlexSalaryExchangeRequestStatus,
  salaryExchangeCardQuery,
  salaryExchangeCardQueryVariables,
} from 'app/apollo/graphql/types';
import {
  commonBenefitMessages,
  salaryExchangeBenefitMessages,
} from 'app/messages/benefits';
import { workInformationMessages } from 'app/messages/employees';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { SALARY_EXCHANGE_CARD_QUERY } from './graphql/queries';
import { getSalaryExchangeCardData } from './utils/get-salary-exchange-card-data';

const benefitTypeNames: BenefitType[] = ['advinans_salary_exchange'];

interface CardProps {
  figureDescription: React.ReactNode;
  figureTitle: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ figureTitle, figureDescription }) => (
  <CardGridCell>
    <SnapshotCard
      icon={
        <Icon
          icon={swapHorizontalCircle}
          color="primary"
          size="medium"
          decorative
        />
      }
      title={<FormattedMessage {...commonBenefitMessages.salaryExchange} />}
      figure1={
        <Figure
          size="medium"
          title={figureTitle}
          description={figureDescription}
        />
      }
    />
  </CardGridCell>
);

export const SalaryExchangeCard: React.FC = () => {
  const {
    params: { companyId, userAccountId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data } = useQuery<
    salaryExchangeCardQuery,
    salaryExchangeCardQueryVariables
  >(SALARY_EXCHANGE_CARD_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    skip: !companyId,
    variables: {
      userAccountId,
      companyId,
      benefitTypeNames,
      requestStatuses: [FlexSalaryExchangeRequestStatus.APPROVED],
    },
    isEmpty: d => !getSalaryExchangeCardData(d),
  });

  const salaryExchangeCardData = getSalaryExchangeCardData(data);

  if (!salaryExchangeCardData) {
    return null;
  }

  const { monthlyPremium } = salaryExchangeCardData;

  if (monthlyPremium) {
    return (
      <Card
        figureTitle={
          <FormattedCurrency value={monthlyPremium} currency="SEK" />
        }
        figureDescription={
          <FormattedMessage {...workInformationMessages.monthlyDeposit} />
        }
      />
    );
  }

  return (
    <Card
      figureTitle={
        <FormattedMessage {...salaryExchangeBenefitMessages.beneficialSaving} />
      }
      figureDescription={
        <FormattedMessage
          {...salaryExchangeBenefitMessages.informationAboutSalaryExchange}
        />
      }
    />
  );
};
