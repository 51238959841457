import gql from 'graphql-tag';

export const SME_COMPANY_REPRESENTATIVES_QUERY = gql`
  query smeCompanyRepresentativesQuery($id: ID!) {
    proposal(id: $id) {
      id
      representatives {
        id
        firstName
        identificationReference
        identificationType
        lastName
        personalIdentityNumber
        role
      }
    }
  }
`;
