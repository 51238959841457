import { ContentContainer, PageLede, Section } from '@frontend/ui';
import { employeeFormMessages } from 'app/messages/employees';
import { FormattedMessage } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { AddEmployeeForm } from 'features/companies/company/employees/add';
import { Page } from 'features/page';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router';

export interface MatchParams {
  companyId: string;
}

export const Add: React.FC<RouteComponentProps> = () => {
  const { params, url } = useRouteMatch<MatchParams>();

  return (
    <NavigationAnchor title={employeeFormMessages.addEmployee} url={url}>
      <Page
        title={<FormattedMessage {...employeeFormMessages.addEmployee} />}
        parentLink={`/companies/${params.companyId}/employees`}
      >
        <ContentContainer>
          <Section>
            <PageLede>
              <FormattedMessage
                {...employeeFormMessages.addEmployeeDescription}
              />
            </PageLede>
            <AddEmployeeForm />
          </Section>
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
