import { MAIN_CONTENT_ID } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

import { messages } from '../messages';

const StyledHashLink = styled(HashLink)`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0.5rem;
    background: ${p => p.theme.surface};
    z-index: 10;
  }
`;

export const SkipToMainLink: React.FC = () => {
  const focusMainContent = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const mainContent = document.getElementById(MAIN_CONTENT_ID);
    if (mainContent) {
      mainContent.focus({ preventScroll: true });
      window.scrollTo(0, 0);
    }
  };

  return (
    <StyledHashLink
      // Skip the default link scroll
      scroll={() => {}}
      to={`#${MAIN_CONTENT_ID}`}
      onClick={focusMainContent}
    >
      <FormattedMessage {...messages.skipToMain} />
    </StyledHashLink>
  );
};
