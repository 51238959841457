import { Table, Td, Th, Tr } from '@frontend/ui';
import { toDecimalFraction, toNumber } from '@frontend/utils';
import { commonMessages } from 'app/messages/common';
import {
  FormattedMessage,
  FormattedNumber,
  FormattedRange,
} from 'components/formats';
import React from 'react';

import { ProposalPremiumMatrixFormValues } from '../proposals/edit/benefits/form/utils/types';

interface Props {
  matrix: Omit<ProposalPremiumMatrixFormValues, 'salaryMultiplier'>;
}

export const PremiumMatrixTable: React.FC<Props> = ({ matrix }) => {
  const {
    age: ages,
    baseAmount: baseAmounts,
    baseAmountType,
    intervals,
  } = matrix;

  const nColumns = baseAmounts.length + 1;

  return (
    <Table fixed size="xsmall">
      <colgroup>
        {Array(nColumns).map(col => (
          <col key={col} style={{ width: `${100 / nColumns}%` }} />
        ))}
      </colgroup>
      <thead>
        <Tr>
          <Th>
            <FormattedMessage {...commonMessages.age} />
          </Th>
          {baseAmounts.map((baseAmount, i) => (
            <Th align="left" key={baseAmount}>
              <FormattedRange
                lowerBound={
                  <FormattedNumber value={toNumber(baseAmount) ?? 0} />
                }
                upperBound={
                  baseAmounts[i + 1] && (
                    <FormattedNumber
                      value={toNumber(baseAmounts[i + 1]) ?? 0}
                    />
                  )
                }
              />{' '}
              {baseAmountType}
            </Th>
          ))}
        </Tr>
      </thead>
      <tbody>
        {ages.map((age, ageIndex) => (
          <Tr key={age}>
            <Th>
              <FormattedMessage
                {...commonMessages.nYears}
                values={{
                  count: (
                    <FormattedRange
                      lowerBound={age}
                      upperBound={ages[ageIndex + 1]}
                    />
                  ),
                }}
              />
            </Th>
            {intervals[ageIndex]?.map((share, baseAmountIndex) => (
              <Td align="left" key={`${age}-${baseAmounts[baseAmountIndex]}`}>
                <FormattedNumber
                  value={toNumber(toDecimalFraction(share)) ?? 0}
                  minimumSignificantDigits={2}
                  // eslint-disable-next-line react/style-prop-object
                  style="percent"
                  trailingZeroDisplay="stripIfInteger"
                />
              </Td>
            ))}
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};
