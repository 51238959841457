import { TitleMedium } from '@frontend/ui';
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  margin-right: 1rem;

  svg {
    vertical-align: middle;
  }
`;

const Description = styled.div<{ hasTitle?: boolean }>`
  line-height: ${p => (p.hasTitle ? '1.25rem' : '1.5rem')};
`;

const Container = styled.div<{ hasTitle?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${p => (p.hasTitle ? '0.5rem' : '0.375rem')};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface Props {
  children: React.ReactNode;
  icon: React.ReactNode;
  title?: React.ReactNode;
}

export const IconListItem: React.FC<Props> = ({ icon, title, children }) => (
  <Container hasTitle={!!title}>
    <IconWrapper>{icon}</IconWrapper>
    <FlexWrapper>
      <TitleMedium>{title && title}</TitleMedium>
      <Description hasTitle={!!title}>{children}</Description>
    </FlexWrapper>
  </Container>
);
