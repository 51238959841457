import gql from 'graphql-tag';

export const BENEFIT_PACKAGE_SETTINGS_QUERY = gql`
  query benefitPackageSettingsQuery($benefitPackageId: ID!) {
    benefitPackage(id: $benefitPackageId) {
      id
      hasBenefitPortal
      showTotalCompensation
    }
  }
`;
