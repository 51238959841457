import { useMutation } from '@apollo/client';
import { Section, SectionHeader } from '@frontend/ui';
import {
  updateFeesLookedAtMutation,
  updateFeesLookedAtMutationVariables,
} from 'app/apollo/graphql/types';
import { FormattedMessage } from 'components/formats';
import { useAutosave } from 'features/sme/companies/company/proposals/edit/contexts/autosave';
import { Fees } from 'features/sme/companies/company/proposals/edit/fees';
import { smeFeesMessages } from 'features/sme/messages/sme';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { MatchParams } from '..';
import { UPDATE_FEES_LOOKED_AT_MUTATION } from './graphql/mutations';

export const SmeCompanyProposalFeesRoute: React.FC = () => {
  const params = useParams<MatchParams>();

  const { data } = useAutosave();
  const validFees = data?.proposal?.validity.validFees;

  const [updateFeesLookedAt] = useMutation<
    updateFeesLookedAtMutation,
    updateFeesLookedAtMutationVariables
  >(UPDATE_FEES_LOOKED_AT_MUTATION);

  useEffect(() => {
    if (validFees?.isValid) {
      return;
    }
    updateFeesLookedAt({
      variables: { input: { proposalId: params.proposalId } },
    });
  }, []);

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...smeFeesMessages.title} />
      </SectionHeader>
      <Fees />
    </Section>
  );
};
