import { NumberField } from '@frontend/formik';
import {
  Section,
  SectionHeader,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { MembershipRemunerationItemInput } from 'app/apollo/graphql/types';
import { wellnessBenefitMessages } from 'app/messages/benefits';
import { suffixMessages } from 'app/messages/common';
import { workInformationMessages } from 'app/messages/employees';
import { MatchParams } from 'app/pages/companies/company/employees/employee/add';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { Link } from 'components/Links/Link';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import {
  RemunerationModalFormValues,
  validationSchema as RemunerationModalValidationSchema,
} from 'features/companies/company/employees/employee/remunerations/update';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';

import { Remunerations } from '../RemunerationFields';

const AccountPlanHref = (chunk: React.ReactNode) => {
  const { params } = useRouteMatch<MatchParams>();

  return (
    <Link to={`/companies/${params.companyId}/settings/account-plan`}>
      {chunk}
    </Link>
  );
};

export const compensationValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    remunerationModalData: RemunerationModalValidationSchema(intl)
      .default(null)
      .nullable(),
  });

export interface CompensationDataFormValues {
  remunerations: MembershipRemunerationItemInput[];
  companyCarMonthlyValue?: number;
  fitnessContributionBalance?: number;
  incomeTaxTable?: number;
  remunerationModalData?: RemunerationModalFormValues;
}

export const compensationInitialValues = {
  remunerations: [],
  remunerationModalData: undefined,
};

export const CompensationFields: React.FC = () => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...workInformationMessages.salaryAndOtherBenefits} />
      </SectionHeader>
      <DescriptionWrapper>
        <FormattedMessage
          {...workInformationMessages.salaryAndOtherBenefitsDescription}
          values={{
            accountPlanHref: AccountPlanHref,
          }}
        />
      </DescriptionWrapper>
      <Subsection>
        <Remunerations />
      </Subsection>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...workInformationMessages.incomeTaxTableLabel} />
        </SubsectionHeader>
        <NumberField
          dense
          name="incomeTaxTable"
          thousandSeparator={false}
          decimalScale={0}
          label={
            <FormattedMessage
              {...workInformationMessages.incomeTaxTableLabel}
            />
          }
          locale={locale}
        />
      </Subsection>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage
            {...workInformationMessages.companyCarMonthlyValueLabel}
          />
        </SubsectionHeader>
        <NumberField
          dense
          name="companyCarMonthlyValue"
          decimalScale={0}
          label={
            <FormattedMessage
              {...workInformationMessages.companyCarMonthlyValueLabel}
            />
          }
          affix={formatMessage(suffixMessages.krPerMonth)}
          locale={locale}
        />
      </Subsection>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...wellnessBenefitMessages.wellness} />
        </SubsectionHeader>
        <DescriptionWrapper>
          <FormattedMessage
            {...workInformationMessages.fitnessContributionBalanceDescText}
          />
        </DescriptionWrapper>
        <NumberField
          dense
          name="fitnessContributionBalance"
          decimalScale={0}
          label={
            <FormattedMessage
              {...workInformationMessages.fitnessContributionBalanceLabel}
            />
          }
          affix={formatMessage(suffixMessages.kr)}
          helperText={
            <FormattedMessage
              {...workInformationMessages.fitnessContributionBalanceHelperText}
            />
          }
          locale={locale}
        />
      </Subsection>
    </Section>
  );
};
