import { Td, Tr } from '@frontend/ui';
import { notesQuery_notes_NoteConnection_edges_NoteEdge_node_Note as Note } from 'app/apollo/graphql/types';
import { noteCategoryMessages } from 'app/messages/notes';
import { useIntl } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { useCurrentUser } from 'contexts/current-permissions';
import React from 'react';
import { useHistory } from 'react-router';

import { RowOptions } from '../RowOptions';

interface Props {
  deleteNote: (noteId: string) => void;
  note: Note;
}

export const NotesTableRow: React.FC<Props> = ({ note, deleteNote }) => {
  const { formatMessage } = useIntl();

  const {
    location: { pathname },
    push,
  } = useHistory();

  const user = useCurrentUser();
  const canEditOrDelete = note.author?.adminUserExternalId === user?.externalId;

  return (
    <Tr
      inlineMenu={
        <RowOptions
          noteId={note.id}
          deleteNote={deleteNote}
          disabled={!canEditOrDelete}
        />
      }
      linked
      onClick={() => {
        push(`${pathname}/${note.id}`);
      }}
    >
      <Td>{note.subject}</Td>
      <Td>{formatMessage(noteCategoryMessages[note.category])}</Td>
      <Td>{note.content}</Td>
      <Td>{note.author.name ?? <NoValue />}</Td>
      <Td align="right" type="number">
        {note.createdAt}
      </Td>
    </Tr>
  );
};
