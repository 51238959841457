import { useMutation } from '@apollo/client';
import { Formik } from '@frontend/formik';
import {
  addAccountingObjectMutation,
  addAccountingObjectMutationVariables,
} from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { ACCOUNTING_OBJECTS_QUERY } from 'features/companies/graphql/queries';
import { useNotification } from 'features/notifications';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';

import { CostReportingModal } from '../../components/Modal';
import { ADD_ACCOUNTING_OBJECT_MUTATION } from '../../graphql/mutations';
import { costReportingMessages } from '../../messages';
import { useAccountingDimension } from '../../utils/use-accounting-dimension';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    identifier: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

interface LocationState {
  perPage?: string;
}

interface MatchParams extends CompanyMatchParams {
  dimensionId: string;
}

interface FormValues {
  identifier: string;
  name: string;
}

export const CreateDimensionObject: React.FC = () => {
  const intl = useIntl();
  const { send } = useNotification();
  const {
    params: { companyId, dimensionId },
  } = useRouteMatch<MatchParams>();
  const { push } = useHistory();
  const { pathname, search, state } = useLocation<LocationState>();

  const { create: isOpen } = qs.parse(search);

  const onCompleted = () => {
    send({
      type: 'success',
      message: (
        <FormattedMessage {...costReportingMessages.createObjectSuccess} />
      ),
    });
    push(pathname);
  };

  const [addAccountingObject, { error }] = useMutation<
    addAccountingObjectMutation,
    addAccountingObjectMutationVariables
  >(ADD_ACCOUNTING_OBJECT_MUTATION, {
    onCompleted,
    errorPolicy: 'all',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ACCOUNTING_OBJECTS_QUERY,
        variables: {
          companyId,
          dimensionId,
          first: state?.perPage
            ? Number(state.perPage)
            : DEFAULT_RESULT_PER_PAGE_SMALL,
        },
      },
    ],
  });

  const {
    loading: dimensionLoading,
    error: dimensionError,
    name: dimensionName,
    // Don't run this hook if create modal is not yet opened
  } = useAccountingDimension(isOpen ? dimensionId : undefined);

  if (dimensionLoading) {
    return <TopLoading />;
  }

  if (dimensionError || !dimensionName) {
    return null;
  }

  return (
    <Formik<FormValues>
      validationSchema={validationSchema(intl)}
      initialValues={{
        identifier: '',
        name: '',
      }}
      onSubmit={({ identifier, name }) => {
        addAccountingObject({
          variables: { input: { companyId, name, identifier, dimensionId } },
        });
      }}
    >
      {({ isValid }) => (
        <CostReportingModal
          title={
            <FormattedMessage
              {...costReportingMessages.createObject}
              values={{ dimensionName }}
            />
          }
          onClose={() => push(pathname)}
          name="name"
          code="identifier"
          isValid={isValid}
          error={error}
        />
      )}
    </Formik>
  );
};
