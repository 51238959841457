import { ApolloError, useMutation } from '@apollo/client';
import {
  createProposalMutation,
  createProposalMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeCreateProposalMessages } from 'features/sme/messages/sme';
import { useHistory, useRouteMatch } from 'react-router';

import { FormValues } from '../form';
import { CREATE_PROPOSAL_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values?: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const { params } = useRouteMatch<MatchParams>();
  const { companyId } = params;

  const { push } = useHistory();

  const [createProposal, { error: submissionError }] = useMutation<
    createProposalMutation,
    createProposalMutationVariables
  >(CREATE_PROPOSAL_MUTATION, {
    update: cache => {
      cache.evict({ fieldName: 'proposals' });
      cache.gc();
    },
  });

  const submit = async (values: FormValues) => {
    // These values are guaranteed through the form validation
    if (!values.companySize || !values.adviceWaived) {
      return;
    }
    try {
      const res = await createProposal({
        variables: {
          input: {
            adviceWaived: values.adviceWaived,
            companyId,
            companySize: values.companySize,
            name: values.name,
          },
        },
      });
      const proposalId = res.data?.createProposal.id;
      if (!proposalId) {
        return;
      }
      send({
        message: formatMessage(smeCreateProposalMessages.proposalCreated),
        type: 'success',
      });
      push(`/sme/${companyId}/proposals/${proposalId}`);
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    submissionError,
  };
};
