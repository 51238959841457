import { useField } from '@frontend/formik';
import { OneOfUserAccountIdOrCompanyId } from 'app/utils/use-upload-files';
import { FileInfo, FileUploadTable } from 'components/FileUploadTable';
import React from 'react';
import { Accept } from 'react-dropzone';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

type Props = {
  label: React.ReactNode;
  name: string;
  disabled?: boolean;
  accept?: Accept;
  helperText?: React.ReactNode;
  multiple?: boolean;
} & OneOfUserAccountIdOrCompanyId;

export const FileUploadField: React.FC<Props> = ({ name, ...props }) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<
    FileInfo[] | undefined
  >(name);
  const isInvalid = touched && Boolean(error);

  return (
    <Wrapper>
      <FileUploadTable
        {...props}
        value={value}
        onChange={setValue}
        onBlur={() => setTouched(true)}
        errorText={isInvalid ? error : undefined}
      />
    </Wrapper>
  );
};
