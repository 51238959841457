export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M4 21V9L12 3L20 9V21H14V14H10V21Z',
      },
    },
  ],
};
