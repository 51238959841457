import {
  AdvinansSalaryExchange,
  BenefitType,
  isSalaryExchangeBenefit,
} from '@frontend/benefit-types';
import { A } from '@frontend/ui';
import {
  collectumInfoQuery,
  collectumInfoQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit,
  collectumInfoQueryVariables,
} from 'app/apollo/graphql/types';
import { EXTERNAL_URLS } from 'app/utils/external-urls';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { companySalaryExchangeMessages } from 'features/companies/company/salary-exchanges/messages';
import React from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { SalaryExchangeRouteMatchParams } from '../..';
import { COLLECTUM_INFO_QUERY } from '../graphql/queries';

const ParagraphWrapper = styled.div`
  margin-top: 2rem;
`;

const benefitTypeNames: BenefitType[] = ['advinans_salary_exchange'];

const benefitHasProviderCollectum = (
  benefit: AdvinansSalaryExchange<Benefit>,
) => !!benefit?.configuration?.data?.providers?.includes('collectum');

export const CollectumInfo: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data } = useQuery<collectumInfoQuery, collectumInfoQueryVariables>(
    COLLECTUM_INFO_QUERY,
    {
      errorPolicy: 'all',
      suspend: true,
      // This query must not affect the empty state of the page.
      // Ideally the component/query composition should be different
      // to avoid having to pass this hard-coded `isEmpty` function.
      isEmpty: () => true,
      variables: {
        companyId,
        benefitTypeNames,
      },
    },
  );

  const benefits = data?.company?.benefits?.edges.map(edge => edge.node);

  const companyHasProviderCollectum = benefits?.some(
    benefit =>
      isSalaryExchangeBenefit(benefit) && benefitHasProviderCollectum(benefit),
  );

  if (!companyHasProviderCollectum) {
    return null;
  }

  return (
    <ParagraphWrapper>
      <FormattedMessage
        {...companySalaryExchangeMessages.approvalCollectumDesc}
        values={{
          collectumLink: (
            <A href={EXTERNAL_URLS.collectum}>
              <FormattedMessage
                {...companySalaryExchangeMessages.collectumSiteLinkText}
              />
            </A>
          ),
        }}
      />
    </ParagraphWrapper>
  );
};
