import { updateProposalQuery } from 'app/apollo/graphql/types';

export interface Validity {
  validBenefits: boolean;
  validCompilation: boolean;
  validEmployees: boolean;
  validFees: boolean;
  validNeed: boolean;
  validProductKnowledge: boolean;
  validProposal: boolean;
  validSummary: boolean;
}

export const getIsValid = (data?: updateProposalQuery): Validity => {
  const validity = data?.proposal?.validity;

  return {
    validBenefits: validity?.validBenefits.isValid ?? false,
    validCompilation: validity?.validCompilation.isValid ?? false,
    validEmployees: validity?.validEmployees.isValid ?? false,
    validFees: validity?.validFees.isValid ?? false,
    validNeed: validity?.validNeed.isValid ?? false,
    validProductKnowledge: validity?.validProductKnowledge.isValid ?? false,
    validProposal: validity?.validProposal.isValid ?? false,
    validSummary: validity?.validSummary.isValid ?? false,
  };
};
