import {
  ContentContainer,
  Grid,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { SalaryExchanges } from 'features/companies/company/salary-exchanges';
import { BonusSalaryExchanges } from 'features/companies/company/salary-exchanges/bonus';
import { ViolationFilter } from 'features/companies/company/salary-exchanges/components/ViolationFilter';
import { companySalaryExchangeMessages } from 'features/companies/company/salary-exchanges/messages';
import { PendingSalaryExchangeRequestsCard } from 'features/companies/company/salary-exchanges/requests/summary';
import { SalaryExchangesCard } from 'features/companies/company/salary-exchanges/summary';
import { UpcomingSalaryExchanges } from 'features/companies/company/salary-exchanges/upcoming';
import { UpcomingSalaryExchangesCard } from 'features/companies/company/salary-exchanges/upcoming/summary';
import { ViolatedSalaryExchangesNotification } from 'features/companies/company/salary-exchanges/violation-notification';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { RouteComponentProps } from 'react-router';

export const Overview: React.FC<RouteComponentProps> = props => (
  <Suspense batch fallback={<TopLoading />}>
    {({ errors }) => (
      <ContentContainer>
        {errors && <GraphQlErrors errors={errors} />}
        <ViolatedSalaryExchangesNotification {...props} />
        <Section>
          <Grid>
            <SalaryExchangesCard />
            <UpcomingSalaryExchangesCard />
            <PendingSalaryExchangeRequestsCard />
          </Grid>
        </Section>
        <Section>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage
                {...companySalaryExchangeMessages.upcomingSalaryExchangesEffectiveFrom}
              />
            </SubsectionHeader>
            <ViolationFilter queryParam="upcoming-violated" />
            <UpcomingSalaryExchanges violatedQueryParam="upcoming-violated" />
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage
                {...companySalaryExchangeMessages.salaryExchanges}
              />
            </SubsectionHeader>
            <ViolationFilter queryParam="violated" />
            <SalaryExchanges violatedQueryParam="violated" />
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage
                {...companySalaryExchangeMessages.onetimeSalaryExchanges}
              />
            </SubsectionHeader>
            <BonusSalaryExchanges />
          </Subsection>
        </Section>
      </ContentContainer>
    )}
  </Suspense>
);
