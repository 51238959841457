import gql from 'graphql-tag';

import { employmentDetails } from './fragments';

export const MEMBERSHIP_EMPLOYMENTS_QUERY = gql`
  query membershipEmploymentsQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      employments {
        edges {
          node {
            ...employmentDetails
          }
        }
      }
    }
  }
  ${employmentDetails}
`;
