import { jobOffersOverviewQuery } from 'app/apollo/graphql/types';

export const updateJobOffersOverviewQuery = (
  previousResult: jobOffersOverviewQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: jobOffersOverviewQuery;
  },
) => {
  if (
    !previousResult?.company?.jobOffers ||
    !fetchMoreResult?.company?.jobOffers
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.jobOffers;

  return {
    company: {
      ...previousResult.company,
      jobOffers: {
        __typename: previousResult.company.jobOffers.__typename,
        edges: [...edges],
        pageInfo,
      },
    },
  };
};
