import { InvoiceStatus } from 'app/apollo/graphql/types';

const STATUS_SORT_ORDER: Record<InvoiceStatus, number> = {
  PAID: 0,
  NOT_PAID: 1,
  IGNORED: 2,
};

/**
 * Computes the sort order of two invoice statuses.
 */
export const sortInvoiceByStatus = (a, b) =>
  STATUS_SORT_ORDER[a] - STATUS_SORT_ORDER[b];
