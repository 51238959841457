import { defineMessages } from 'react-intl';

export const emptyStateMessages = defineMessages({
  emptyState404Message: {
    id: 'common.EmptyState404Message',
    description: 'This page is not available',
    defaultMessage: 'Den här sidan är inte tillgänglig',
  },
  noReports: {
    id: 'emptyStateMessages.noReports',
    defaultMessage: 'Det finns inga rapporter att visa',
  },
});
