import { ApolloError } from '@apollo/client';
import { EmptyState } from 'components/EmptyState';
import { useNavigationAnchorContext } from 'contexts/navigation-anchor';
import { Page } from 'features/page';
import React from 'react';

interface Props {
  parentLink: string;
  children?: React.ReactNode;
  error?: ApolloError | string;
  errors?: ApolloError[];
  title?: React.ReactNode;
}

export const EmptyStatePage: React.FC<Props> = props => {
  const { stack } = useNavigationAnchorContext();
  const title = Object.values(stack)
    .sort((a, b) => a.url.split('/').length - b.url.split('/').length)
    .pop()?.title;

  return (
    <Page
      title={props.title ?? title}
      parentLink={props.parentLink ?? '/'}
      trackPageOnUnmount={false}
    >
      <EmptyState {...props} />
    </Page>
  );
};
