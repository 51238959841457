import { isNullOrUndefined } from '@frontend/utils';
import {
  fitnessContributionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit as WellnessBenefit,
  fitnessContributionCardQuery_membership_Membership as Membership,
  fitnessContributionCardQuery_Query,
} from 'app/apollo/graphql/types';

/**
 * There can be a delay when syncing with Epassi
 * with newly created memberships which will result
 * in the fitnessContributionBalance being null.
 */
const hideCard = (
  membership: Membership,
  wellnessBenefit?: WellnessBenefit,
): boolean =>
  isNullOrUndefined(membership.fitnessContributionBalance) &&
  (!wellnessBenefit || wellnessBenefit.type === 'epassi');

interface FitnessContributionCardData {
  /**
   * The yearly wellness allowance
   */
  allowance: string;
  /**
   * The wellness balance
   */
  fitnessContributionBalance: string | null;
  /**
   * id of the wellness benefit
   */
  id: string;
}

/**
 * Retrieves the relevant fitness contribution card data from the query response
 * or returns null if the user has no access to fitness contribution.
 */
export const getFitnessContributionData = (
  data?: fitnessContributionCardQuery_Query,
): FitnessContributionCardData | null => {
  if (
    !data?.membership ||
    !data?.entitlements?.edges?.length ||
    !data?.company?.totalCompensationConfiguration?.wellness
  ) {
    return null;
  }

  const { membership } = data;
  const wellnessBenefit = data.entitlements?.edges.map(
    ({ node }) => node.benefit,
  )[0];

  if (hideCard(membership, wellnessBenefit)) {
    return null;
  }

  const { fitnessContributionBalance } = membership;
  const { id, configuration } = wellnessBenefit ?? {};
  const allowance = configuration?.data?.allowance;

  return { id, allowance, fitnessContributionBalance };
};
