import { Dot, Tooltip } from '@frontend/ui';
import {
  FlexSalaryExchangeRequestStatus,
  FlexSalaryExchangeStatus,
} from 'app/apollo/graphql/types';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';

import { companySalaryExchangeMessages } from '../../messages';

type RequestStatus = Omit<FlexSalaryExchangeRequestStatus, 'REJECTED'>;

interface SalaryExchange {
  effectiveDate: string;
  status: FlexSalaryExchangeStatus | RequestStatus;
  violated: boolean;
}

interface StatusDotProps {
  item: Omit<SalaryExchange, 'effectiveDate'>;
  label: React.ReactNode;
}

const StatusDot: React.FC<StatusDotProps> = ({
  item: { status, violated },
  label,
}) => {
  if (violated && status !== 'INACTIVE') {
    return <Dot colorValue="error" size={0.75} label={label} />;
  }
  if (status === 'ACTIVE') {
    return <Dot colorValue="green" size={0.75} label={label} />;
  }
  if (status === 'APPROVED') {
    return <Dot colorValue="orange" size={0.75} label={label} />;
  }
  return <Dot colorValue="outline" size={0.75} label={label} />;
};

interface StatusProps {
  item: SalaryExchange;
  label: React.ReactNode;
}

export const Status: React.FC<StatusProps> = ({
  item: { status, effectiveDate, violated },
  label,
}) => (
  <Tooltip
    alignParentRight
    parent={<StatusDot item={{ status, violated }} label={label} />}
    tooltipContent={
      <FormattedMessage
        {...(violated && status !== 'INACTIVE'
          ? companySalaryExchangeMessages.violatedStatusTooltip
          : status === 'ACTIVE' || status === 'APPROVED'
            ? companySalaryExchangeMessages.activeStatusTooltip
            : companySalaryExchangeMessages.inactiveStatusTooltip)}
        values={{
          effectiveDate: effectiveDate ? (
            <FormattedDate value={effectiveDate} />
          ) : (
            <NoValue />
          ),
        }}
      />
    }
  />
);
