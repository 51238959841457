import { useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  GenerateProposalInput,
  smeGenerateProposalMutation,
  smeGenerateProposalMutationVariables,
  smeUpdateMissingCompanyDetailsMutation,
  smeUpdateMissingCompanyDetailsMutationVariables,
  UpdateCompanyInput,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import { smeGenerateProposalMessages } from 'features/sme/messages/sme';
import { useTransitionOverlay } from 'features/transition-overlay';

import { clearProposalCache } from '../../../utils/clear-proposal-cache';
import { FormValues } from '..';
import {
  SME_GENERATE_PROPOSAL_MUTATION,
  SME_UPDATE_MISSING_COMPANY_DETAILS_MUTATION,
} from '../graphql/mutations';

interface Params {
  onCompleted: () => void;
  params: MatchParams;
}

interface SubmitConfig {
  updateCompanyDetails: boolean;
}

interface Submit {
  submit: (
    params: SubmitConfig,
  ) => (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => Promise<void>;
}

export const useSubmit = ({ onCompleted, params }: Params): Submit => {
  const { clearTransition, startTransition } = useTransitionOverlay();

  const [updateCompany] = useMutation<
    smeUpdateMissingCompanyDetailsMutation,
    smeUpdateMissingCompanyDetailsMutationVariables
  >(SME_UPDATE_MISSING_COMPANY_DETAILS_MUTATION);

  const [generateProposal] = useMutation<
    smeGenerateProposalMutation,
    smeGenerateProposalMutationVariables
  >(SME_GENERATE_PROPOSAL_MUTATION, {
    update: clearProposalCache({
      fieldNames: ['proposals'],
      proposalId: params.proposalId,
    }),
  });

  const submit =
    ({ updateCompanyDetails }: SubmitConfig) =>
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      startTransition({
        opaqueFadeIn: true,
        title: smeGenerateProposalMessages.generatingProposal,
        illustration: 'generate-proposal',
        progress: 'indeterminate',
      });

      try {
        if (updateCompanyDetails) {
          // This value is guaranteed through the form validation
          if (!values.paymentMethod) {
            return;
          }
          const updateCompanyInput: UpdateCompanyInput = {
            accountNumber: values.accountNumber,
            clearingCode: values.clearingSalesOffice?.value,
            id: params.companyId,
            paymentMethod: values.paymentMethod,
          };

          await updateCompany({
            variables: { input: updateCompanyInput },
          });
        }
        const generateProposalInput: GenerateProposalInput = {
          includeIndividual: values.includeIndividualProposal,
          proposalId: params.proposalId,
          startDate: format(
            startOfMonth(new Date(values.startDate)),
            'yyyy-MM-dd',
          ),
        };
        const res = await generateProposal({
          variables: { input: generateProposalInput },
        });

        if (!res.data) {
          throw new Error();
        }

        if (res.data.generateProposal.documents) {
          clearTransition();
          onCompleted();
        }
      } catch (error) {
        helpers.setErrors({ submissionError: error?.message });
        clearTransition();
      }
    };

  return { submit };
};
