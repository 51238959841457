import { Icon } from '@frontend/ui';
import { _delete } from '@frontend/ui/icons';
import { sharedFileMessages } from 'app/messages/shared-files';
import { FormattedMessage } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import React from 'react';

interface Props {
  deleteSharedFile: () => void;
}

export const RowOptions: React.FC<Props> = ({ deleteSharedFile }) => (
  <KebabMenu label={sharedFileMessages.sharedFiles} inClickableArea fixed>
    <MenuItem
      title={<FormattedMessage {...sharedFileMessages.deleteFile} />}
      icon={<Icon icon={_delete} decorative />}
      onClick={() => deleteSharedFile()}
    />
  </KebabMenu>
);
