import { Dot, Section, SectionHeader, Table, Td, Th, Tr } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { FormattedMessage } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import React, { useState } from 'react';

import { ConfirmModal } from '../ConfirmModal';

const Active: React.FC = () => (
  <Dot
    size={0.75}
    colorValue="green"
    label={<FormattedMessage {...commonMessages.activated} />}
  />
);

const Inactive: React.FC = () => (
  <Dot
    size={0.75}
    colorValue="outline"
    label={<FormattedMessage {...commonMessages.deactivated} />}
  />
);

interface Props {
  emailLoginAllowed: boolean | null;
  id: string;
}

export const LoginMethodTable: React.FC<Props> = ({
  id,
  emailLoginAllowed,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Section>
        <SectionHeader>
          <FormattedMessage {...companyMessages.loginMethod} />
        </SectionHeader>
        <Table size="xsmall">
          <tbody>
            <Tr inlineMenu>
              <Th>BankID</Th>
              <Td>
                <Active />
              </Td>
            </Tr>
            <Tr
              inlineMenu={
                <KebabMenu label={companyMessages.loginEditMenu} fixed>
                  <MenuItem
                    title={
                      emailLoginAllowed ? (
                        <FormattedMessage {...commonMessages.deactivate} />
                      ) : (
                        <FormattedMessage {...commonMessages.activate} />
                      )
                    }
                    noIcon
                    onClick={() => setIsModalOpen(true)}
                  />
                </KebabMenu>
              }
            >
              <Th>
                <FormattedMessage {...commonMessages.email} />
              </Th>
              <Td>{emailLoginAllowed ? <Active /> : <Inactive />}</Td>
            </Tr>
          </tbody>
        </Table>
      </Section>
      {isModalOpen && (
        <ConfirmModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          emailLoginAllowed={emailLoginAllowed}
          id={id}
        />
      )}
    </>
  );
};
