import { Section } from '@frontend/ui';
import { employeesImportMessages } from 'app/messages/employees';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { TopLoading } from 'components/TopLoading';
import { Imports } from 'features/companies/company/employees/import';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '../..';

export const ImportsRoute: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  return (
    <Suspense
      batch
      emptyState={
        <EmptyState
          title={<FormattedMessage {...employeesImportMessages.noImports} />}
          actions={
            <LinkButton to={`/companies/${companyId}/employees/import`}>
              <FormattedMessage {...employeesImportMessages.importEmployees} />
            </LinkButton>
          }
        >
          <FormattedMessage
            {...employeesImportMessages.noEmployeesImportedText}
          />
        </EmptyState>
      }
      fallback={<TopLoading />}
    >
      {({ errors }) => (
        <Section>
          {errors && <GraphQlErrors errors={errors} />}
          <Imports />
        </Section>
      )}
    </Suspense>
  );
};
