import { DatePickerField, TextField } from '@frontend/formik';
import {
  Section,
  SectionHeader,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { validationMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/companies/company';
import { FileInfo } from 'components/FileUploadTable';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { FileUploadField } from 'components/formik/FileUploadField';
import { Image, ImageUploadField } from 'components/formik/ImageUploadField';
import { RichTextEditor } from 'components/formik/RichTextEditor';
import React from 'react';
import { useRouteMatch } from 'react-router';
import * as Yup from 'yup';

import { jobOfferFormMessages } from '../../messages';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    prospectName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    birthdate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    title: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    workLocation: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    workSetting: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  birthdate: string;
  body: string;
  prospectName: string;
  title: string;
  workLocation: string;
  workSetting: string;
  attachmentFiles?: FileInfo[] | null;
  coverPicture?: Image | null;
  profilePicture?: Image | null;
}

export const initialValues: FormValues = {
  birthdate: '',
  body: '',
  prospectName: '',
  title: '',
  workLocation: '',
  workSetting: '',
};

export const InformationFields: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();
  const { formatMessage } = useIntl();

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...jobOfferFormMessages.info} />
      </SectionHeader>
      <TextField
        dense
        name="prospectName"
        label={<FormattedMessage {...jobOfferFormMessages.prospect} />}
        required
      />
      <DatePickerField
        dense
        label={<FormattedMessage {...jobOfferFormMessages.birthDate} />}
        name="birthdate"
        required
      />
      <TextField
        dense
        name="title"
        label={<FormattedMessage {...jobOfferFormMessages.position} />}
        required
      />
      <TextField
        dense
        name="workLocation"
        label={<FormattedMessage {...jobOfferFormMessages.city} />}
        helperText={<FormattedMessage {...jobOfferFormMessages.cityHelper} />}
        required
      />
      <TextField
        dense
        name="workSetting"
        label={<FormattedMessage {...jobOfferFormMessages.location} />}
        helperText={
          <FormattedMessage {...jobOfferFormMessages.locationHelper} />
        }
        required
      />
      <div className="with-presentation-text-styles">
        <RichTextEditor
          name="body"
          placeholder={formatMessage(jobOfferFormMessages.body)}
          height="21rem"
        />
      </div>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...jobOfferFormMessages.addAvatar} />
        </SubsectionHeader>
        <ImageUploadField
          companyId={companyId}
          name="profilePicture"
          imageType="avatar"
          // eslint-disable-next-line
          label={({ isEdit }) => (
            <FormattedMessage
              {...(!isEdit
                ? jobOfferFormMessages.addAvatar
                : jobOfferFormMessages.editAvatar)}
            />
          )}
        />
      </Subsection>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...jobOfferFormMessages.addCover} />
        </SubsectionHeader>
        <ImageUploadField
          companyId={companyId}
          name="coverPicture"
          imageType="hero"
          // eslint-disable-next-line
          label={({ isEdit }) => (
            <FormattedMessage
              {...(!isEdit
                ? jobOfferFormMessages.addCover
                : jobOfferFormMessages.editCover)}
            />
          )}
        />
      </Subsection>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...jobOfferFormMessages.attachFiles} />
        </SubsectionHeader>
        <FileUploadField
          companyId={companyId}
          name="attachmentFiles"
          label={<FormattedMessage {...jobOfferFormMessages.selectFiles} />}
        />
      </Subsection>
    </Section>
  );
};
