import {
  Card as _Card,
  Icon,
  IconContent,
  NavigationCardLayout,
  NavigationCardLayoutProps,
} from '@frontend/ui';
import { CardLink } from 'components/CardLink';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const Card = styled(_Card)`
  height: 100%;
`;

interface Props extends Omit<NavigationCardLayoutProps, 'icon'> {
  icon: IconContent;
  link: LinkProps;
  pill?: React.ReactNode;
}

export const NavigationCard: React.FC<Props> = ({ icon, link, ...props }) => (
  <Card filled>
    <CardLink {...link}>
      <NavigationCardLayout
        {...props}
        icon={<Icon size="medium" icon={icon} color="primary" decorative />}
      />
    </CardLink>
  </Card>
);
