import { Table, Td, Th, Tr } from '@frontend/ui';
import { salaryReportsQuery_company_Company_salaryReports_SalaryReportConnection_edges_SalaryReportEdge_node_SalaryReport as SalaryReport } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import React from 'react';

const SalaryReportRow: React.FC<{
  report: SalaryReport;
}> = ({ report }) => (
  <Tr
    linked={Boolean(report.excelUrl)}
    // eslint-disable-next-line
    onClick={() => report.excelUrl && (window.location.href = report.excelUrl)}
  >
    <Td>{report.name}</Td>
    <Td>
      <FormattedMessage
        {...commonMessages.yearMonth}
        values={{ value: new Date(report.year, (report.month + 11) % 12) }}
      />
    </Td>
  </Tr>
);

interface Props {
  navigation: React.ReactNode;
  reports: SalaryReport[];
}

export const SalaryReportsTable: React.FC<Props> = ({
  reports,
  navigation,
}) => (
  <Table size="xsmall" navigation={navigation}>
    <thead>
      <Tr>
        <Th>
          <FormattedMessage {...commonMessages.name} />
        </Th>
        <Th>
          <FormattedMessage {...commonMessages.date} />
        </Th>
      </Tr>
    </thead>
    <tbody>
      {reports.map(report => (
        <SalaryReportRow key={report.id} report={report} />
      ))}
    </tbody>
  </Table>
);
