import gql from 'graphql-tag';

export const SALARY_EXCHANGE_CARD_QUERY = gql`
  query salaryExchangeCardQuery(
    $companyId: ID!
    $userAccountId: ID!
    $benefitTypeNames: [String!]
    $requestStatuses: [FlexSalaryExchangeRequestStatus!]
  ) {
    company(id: $companyId) {
      id
      totalCompensationConfiguration {
        companyId
        salaryExchange
      }
    }
    entitlements(
      companyId: $companyId
      userAccountId: $userAccountId
      benefitTypeNames: $benefitTypeNames
      showAll: true
    ) {
      edges {
        node {
          ... on FlexSalaryExchangeEntitlement {
            eligibleForOngoingExchange
            eligibleForOnetimeExchange
            benefit {
              id
              content {
                locale
              }
            }
          }
        }
      }
    }
    salaryExchange(companyId: $companyId, userAccountId: $userAccountId) {
      id
      status
      premium
    }
    salaryExchangeRequests(
      companyId: $companyId
      userAccountId: $userAccountId
      statuses: $requestStatuses
    ) {
      edges {
        node {
          id
          status
          premium
        }
      }
    }
  }
`;
