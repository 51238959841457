import {
  ProposalCompanySize,
  smeCompanyFcfwCompanySizeQuery,
  smeCompanyFcfwCompanySizeQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { SME_COMPANY_FCFW_COMPANY_SIZE_QUERY } from '../graphql/queries';

type FcfwCompanySize = 'small' | 'large';

const FCFW_COMPANY_SIZE: Record<ProposalCompanySize, FcfwCompanySize> = {
  LT_10: 'small',
  LT_50: 'small',
  GTE_50: 'large',
  GTE_250: 'large',
  GTE_1000: 'large',
};

export const CompanySizeDescription: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();

  const { data } = useQuery<
    smeCompanyFcfwCompanySizeQuery,
    smeCompanyFcfwCompanySizeQueryVariables
  >(SME_COMPANY_FCFW_COMPANY_SIZE_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: { proposalId: params.proposalId },
  });

  if (!data?.proposal) {
    return null;
  }

  const { companySize } = data.proposal;

  return (
    <FormattedMessage
      {...(FCFW_COMPANY_SIZE[companySize] === 'small'
        ? smeEmployeesMessages.fcfwGroupSizeSmall
        : smeEmployeesMessages.fcfwGroupSizeLarge)}
    />
  );
};
