import { NotificationType } from '@frontend/ui';
import React, { useContext, useMemo, useState } from 'react';

interface NotificationCallback {
  fn: () => void;
  label: string;
}

export interface Notification {
  message: React.ReactNode;
  type: NotificationType;
  callback?: NotificationCallback;
}

export type Send = (notification: Notification) => void;

export interface NotificationContext {
  clear: () => void;
  send: Send;
  notification?: Notification | null;
}

const NotificationContext = React.createContext<NotificationContext>({
  notification: null,
  send: (n: Notification) => n,
  clear: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const NotificationProvider: React.FC<Props> = ({ children }) => {
  const [notification, setNotification] = useState<Notification | null>(null);
  const value = useMemo<NotificationContext>(
    () => ({
      notification,
      send: n => setNotification(n),
      clear: () => setNotification(null),
    }),
    [notification],
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContext =>
  useContext(NotificationContext);

export const withNotification = Component =>
  function ThemedComponent(props) {
    const notificationContext = useNotification();

    return <Component {...props} {...notificationContext} />;
  };
