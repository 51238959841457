import {
  DatePickerField,
  Form,
  Formik,
  FormikProps,
  SelectField,
  TextField,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { person } from '@frontend/ui/icons';
import {
  EventTypeCategory,
  signedPensionTransfersQuery,
  signedPensionTransfersQueryVariables,
} from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages, validationMessages } from 'app/messages/common';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { TopLoading } from 'components/TopLoading';
import { useSubmit } from 'features/advisor/add-held-meeting/utils/use-submit';
import React, { useEffect } from 'react';
import { isValidUuid } from 'validations';

import { SIGNED_PENSION_TRANSFERS_QUERY } from '../graphql/queries';

export interface AddFormValues {
  companyId: string;
  date: string;
  eventTypeCategory: EventTypeCategory;
  userAccountId: string;
  pensionTransferTaskIds?: number[];
  submissionError?: string;
}

interface AddFormProps {
  onRequestClose: () => void;
  submit?: () => Promise<void>;
}

const AddForm: React.FC<FormikProps<AddFormValues> & AddFormProps> = ({
  onRequestClose,
  isSubmitting,
  values,
  errors,
  setFieldValue,
}) => {
  const { formatMessage } = useIntl();

  const shouldGetTransferredInsurances = isValidUuid(values.userAccountId);

  const { data, loading, error } = useQuery<
    signedPensionTransfersQuery,
    signedPensionTransfersQueryVariables
  >(SIGNED_PENSION_TRANSFERS_QUERY, {
    skip: !shouldGetTransferredInsurances,
    errorPolicy: 'all',
    variables: {
      userAccountId: values.userAccountId,
      date: values.date,
    },
  });

  const signedPensionTransfers = data?.signedPensionTransfers;
  const pensionTransferTaskIds = signedPensionTransfers?.map(
    transfer => transfer.id,
  );
  useEffect(() => {
    setFieldValue('pensionTransferTaskIds', pensionTransferTaskIds);
  }, [JSON.stringify(signedPensionTransfers)]);

  return (
    <>
      {loading && <TopLoading />}
      <Form>
        <ModalHeader>
          <FormattedMessage {...advisorMessages.addHeldMeeting} />
        </ModalHeader>
        <ModalBody>
          <TextField
            dense
            name="userAccountId"
            label={<FormattedMessage {...commonMessages.userAccountId} />}
            required
            leadingIcon={<Icon icon={person} decorative />}
            spellCheck={false}
            validate={value =>
              isValidUuid(value)
                ? ''
                : formatMessage(validationMessages.invalidUuid)
            }
          />
          <TextField
            dense
            name="companyId"
            label={<FormattedMessage {...commonMessages.companyId} />}
            required
            leadingIcon={<Icon icon={person} decorative />}
            spellCheck={false}
            validate={value =>
              isValidUuid(value)
                ? ''
                : formatMessage(validationMessages.invalidUuid)
            }
          />
          <DatePickerField
            fixed
            dense
            name="date"
            label={<FormattedMessage {...commonMessages.date} />}
          />
          <SelectField
            dense
            name="eventTypeCategory"
            label={<FormattedMessage {...advisorMessages.category} />}
            required
            fixed
            options={[
              {
                value: EventTypeCategory.PERSONAL_ADVICE,
                label: formatMessage(advisorMessages.personalAdvice),
              },
              {
                value: EventTypeCategory.PENSION_TRANSFER,
                label: formatMessage(advisorMessages.transferAdvice),
              },
            ]}
          />
          {signedPensionTransfers?.length ? (
            <>
              <p>
                <FormattedMessage
                  {...advisorMessages.transferredInsurancesDesc}
                />
              </p>

              {signedPensionTransfers
                .flatMap(transfer => transfer.sources)
                .map(source => (
                  <FormattedMessage
                    key={source.insuranceNumber}
                    {...advisorMessages.transferredInsuranceCheck}
                    values={{
                      insuranceNumber: source.insuranceNumber,
                      capital: source.capital,
                    }}
                  />
                ))}
            </>
          ) : values.eventTypeCategory ===
            EventTypeCategory.PENSION_TRANSFER ? (
            <p>
              <FormattedMessage {...advisorMessages.noTransferredInsurances} />
            </p>
          ) : null}
          {errors.submissionError && (
            <NotificationCard type="error" inModal>
              {errors.submissionError}
            </NotificationCard>
          )}
          {error && <GraphQlError inModal error={error} />}
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button text onClick={onRequestClose}>
              <FormattedMessage {...commonMessages.cancel} />
            </Button>
            <Button text type="submit" loading={isSubmitting}>
              <FormattedMessage {...commonMessages.confirm} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </Form>
    </>
  );
};

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  defaultCompanyId?: string;
  defaultDate?: string;
  defaultEventTypeCategory?: EventTypeCategory;
  defaultUserAccountId?: string;
}

export const AddHeldMeetingModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  defaultUserAccountId,
  defaultCompanyId,
  defaultDate,
  defaultEventTypeCategory,
}) => {
  const { submit, error } = useSubmit({ onRequestClose });
  const date = defaultDate ? new Date(defaultDate) : new Date();

  return (
    <Formik<AddFormValues>
      initialValues={{
        date: date.toISOString().substring(0, 10),
        userAccountId: defaultUserAccountId ?? '',
        companyId: defaultCompanyId ?? '',
        eventTypeCategory:
          defaultEventTypeCategory ?? EventTypeCategory.PENSION_TRANSFER,
      }}
      onSubmit={submit}
      enableReinitialize
    >
      {formikProps => (
        <>
          {error && <GraphQlError error={error} />}
          <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
            <AddForm {...formikProps} {...{ onRequestClose }} />
          </Modal>
        </>
      )}
    </Formik>
  );
};
