import { Section, Th, Tr } from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import {
  companyBillingConfigurationQuery,
  companyBillingConfigurationQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { LinkButton } from 'components/LinkButton';
import { ListTable } from 'components/ListTable';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { BillingMailRow } from './components/BillingMailRow';
import { COMPANY_BILLING_CONFIGURATION_QUERY } from './graphql/queries';
import { billingMessages } from './messages';

export const Billing: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { data, loading, error } = useQuery<
    companyBillingConfigurationQuery,
    companyBillingConfigurationQueryVariables
  >(COMPANY_BILLING_CONFIGURATION_QUERY, {
    variables: { companyId },
  });

  if (loading) {
    return <TopLoading />;
  }

  const billingEmails =
    data?.company?.billingConfigurations?.edges?.map(e => e.node) ?? [];

  if (billingEmails.length === 0) {
    return (
      <EmptyState
        title={<FormattedMessage {...billingMessages.noBillingEmailsTitle} />}
        actions={
          <LinkButton
            to={{ pathname, search: qs.stringify({ add: true }) }}
            text={billingEmails.length !== 0}
          >
            <FormattedMessage {...billingMessages.addBillingEmail} />
          </LinkButton>
        }
        error={error}
      />
    );
  }

  return (
    <Section>
      <ChipsWrapper>
        <AssistChip
          onClick={() =>
            push({ pathname, search: qs.stringify({ add: true }) })
          }
          text={<FormattedMessage {...billingMessages.addBillingEmail} />}
          leadingIcon={addCircle}
        />
      </ChipsWrapper>
      <ListTable>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.email} />
            </Th>
            <Th>
              <FormattedMessage {...billingMessages.attachments} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {billingEmails.map(billingEmail => (
            <BillingMailRow
              key={billingEmail.id}
              billingEmail={billingEmail}
              onClick={() =>
                push({
                  pathname,
                  search: qs.stringify({ edit: billingEmail.id }),
                })
              }
            />
          ))}
        </tbody>
      </ListTable>
    </Section>
  );
};
