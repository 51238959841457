// eslint-disable-next-line
import { Modal as ModalComponent, ModalProps } from '@frontend/ui';
import { APP_ID } from 'app/utils/constants';
import React from 'react';

export const Modal: React.FC<ModalProps> = props => {
  const appElement = document.getElementById(APP_ID);
  return <ModalComponent appElement={appElement} {...props} />;
};

/**
 * Use this function to run an action after the modal has faded out.
 * Primiarily intended to clear modal forms after the modal has closed
 * to prevent flickering.
 */
export const afterFadeout = (callback: () => void) => {
  setTimeout(() => {
    callback();
  }, 300);
};
