import {
  CheckboxField,
  DatePickerField,
  SelectField,
  useFormikContext,
} from '@frontend/formik';
import { Grid, Section, SectionHeader } from '@frontend/ui';
import { dateToMonth } from '@frontend/utils';
import {
  benefitPackagesOverviewQuery,
  benefitPackagesOverviewQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/companies/company/employees/employee/add';
import { useQuery } from 'app/utils/use-query';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell50 } from 'components/GridCell';
import { FormValues } from 'features/companies/company/employees/add';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { isValidMonth } from 'validations';
import * as Yup from 'yup';

import { BENEFIT_PACKAGES_OVERVIEW_QUERY } from './graphql/queries';

export const benefitPackageValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    benefitPackageId: Yup.string().when('benefitPackageOptOut', {
      is: false,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    benefitPackageEffectiveDate: Yup.string().when('benefitPackageOptOut', {
      is: false,
      then: schema =>
        schema
          .required(intl.formatMessage(validationMessages.mandatoryField))
          .test(
            'valid date',
            intl.formatMessage(validationMessages.invalidMonth),
            value => isValidMonth(value),
          ),
    }),
  });

export interface BenefitPackageFormValues {
  benefitPackageOptOut: boolean;
  benefitPackageEffectiveDate?: string;
  benefitPackageId?: string;
}

export const benefitPackageInitialValues = {
  benefitPackageOptOut: false,
};

export const BenefitPackageFields: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const {
    values: {
      benefitPackageOptOut,
      benefitPackageEffectiveDate,
      employmentEffectiveDate,
    },
    touched,
    setFieldValue,
  } = useFormikContext<FormValues>();

  useEffect(() => {
    if (benefitPackageOptOut) {
      setFieldValue('benefitPackageEffectiveDate', '');
      setFieldValue('benefitPackageId', '');
    }
  }, [benefitPackageOptOut]);

  useEffect(() => {
    if (
      employmentEffectiveDate &&
      !benefitPackageOptOut &&
      (!benefitPackageEffectiveDate || !touched.benefitPackageEffectiveDate)
    ) {
      setFieldValue(
        'benefitPackageEffectiveDate',
        dateToMonth(employmentEffectiveDate),
      );
    }
  }, [employmentEffectiveDate]);

  const { data, loading, error } = useQuery<
    benefitPackagesOverviewQuery,
    benefitPackagesOverviewQueryVariables
  >(BENEFIT_PACKAGES_OVERVIEW_QUERY, {
    variables: { companyId: params.companyId },
    errorPolicy: 'all',
  });

  if (loading) {
    return null;
  }

  if (!data?.company?.benefitPackages) {
    return (
      <Section>
        <SectionHeader>
          <FormattedMessage {...commonMessages.benefitPackage} />
        </SectionHeader>
        <GraphQlError error={error} />
      </Section>
    );
  }

  const {
    company: { benefitPackages },
  } = data;

  const benefitPackageOptions = benefitPackages.edges.map(p => ({
    value: p.node.id,
    label: p.node.name,
  }));

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonMessages.benefitPackage} />
      </SectionHeader>
      {error && <GraphQlError error={error} />}
      <DescriptionWrapper>
        <FormattedMessage {...commonMessages.benefitPackageDescription} />
      </DescriptionWrapper>
      <CheckboxField
        name="benefitPackageOptOut"
        label={<FormattedMessage {...commonMessages.benefitPackageOptOut} />}
      />
      <Grid>
        <GridCell50>
          <SelectField
            dense
            name="benefitPackageId"
            label={<FormattedMessage {...commonMessages.benefitPackageLabel} />}
            options={benefitPackageOptions}
            required={!benefitPackageOptOut}
            disabled={benefitPackageOptOut}
          />
        </GridCell50>
        <GridCell50>
          <DatePickerField
            dense
            name="benefitPackageEffectiveDate"
            type="month"
            label={<FormattedMessage {...commonMessages.effectiveDate} />}
            required={!benefitPackageOptOut}
            disabled={benefitPackageOptOut}
          />
        </GridCell50>
      </Grid>
    </Section>
  );
};
