import { Dot } from '@frontend/ui';
import type { ThemeColor } from '@frontend/ui/theme';
import { FormattedMessage } from 'components/formats';
import React from 'react';

import { effectiveDateStatusMessages } from '../../messages';

export type Status = 'INSURANCE_WARNING' | 'NOT_COMPLETE' | 'COMPLETE';

const STATUS_COLOR: Record<Status, ThemeColor> = {
  INSURANCE_WARNING: 'orange',
  NOT_COMPLETE: 'primary',
  COMPLETE: 'green',
};

export const FcfwStatus: React.FC<{ status: Status }> = ({ status }) => (
  <Dot
    colorValue={STATUS_COLOR[status]}
    size={0.75}
    label={
      <FormattedMessage
        select={status}
        messages={effectiveDateStatusMessages}
      />
    }
  />
);
