import { ApolloError, useMutation } from '@apollo/client';
import {
  createProposalBenefitPackageMutation,
  createProposalBenefitPackageMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitPackagesMessages } from 'features/sme/messages/sme';

import { clearProposalCache } from '../../../../utils/clear-proposal-cache';
import { FormValues } from '..';
import { CREATE_PROPOSAL_BENEFIT_PACKAGE_MUTATION } from '../graphql/mutations';

interface Props {
  onRequestClose: () => void;
  params: MatchParams;
}

interface Submit {
  submit: (values?: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = ({ onRequestClose, params }: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [createProposalBenefitPackage, { error: submissionError }] =
    useMutation<
      createProposalBenefitPackageMutation,
      createProposalBenefitPackageMutationVariables
    >(CREATE_PROPOSAL_BENEFIT_PACKAGE_MUTATION, {
      update: clearProposalCache({
        proposalId: params.proposalId,
        fieldNames: ['cost', 'proposals', 'validity'],
      }),
    });

  const submit = async (values: FormValues) => {
    try {
      const res = await createProposalBenefitPackage({
        variables: {
          input: {
            defaultBenefitPackage: values.defaultBenefitPackage || undefined,
            name: values.name,
            proposalId: values.proposalId,
          },
        },
      });
      const proposalId = res.data?.createProposalBenefitPackage?.id;
      if (!proposalId) {
        return;
      }
      send({
        message: formatMessage(
          smeBenefitPackagesMessages.benefitPackageCreated,
        ),
        type: 'success',
      });
      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    submissionError,
  };
};
