import { NoteCategory } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const noteMessages = defineMessages({
  subject: {
    id: 'note.subject',
    defaultMessage: 'Rubrik',
  },
  category: {
    id: 'note.category',
    defaultMessage: 'Kategori',
  },
  note: {
    id: 'note.note',
    defaultMessage: 'Anteckning',
  },
  createdBy: {
    id: 'note.createdBy',
    defaultMessage: 'Skapad av',
  },
  createdByNoteAuthor: {
    id: 'note.createdByNoteAuthor',
    defaultMessage: '<b>Skapad av:</b> {authorName}',
  },
  date: {
    id: 'note.date',
    defaultMessage: 'Datum',
  },
  editNote: {
    id: 'note.editNote',
    defaultMessage: 'Redigera anteckning',
  },
  editNoteSubject: {
    id: 'note.editNoteSubject',
    defaultMessage: 'Redigera {subject}',
  },
  deleteNote: {
    id: 'note.deleteNote',
    defaultMessage: 'Ta bort anteckning',
  },
  addNote: {
    id: 'note.addNote',
    defaultMessage: 'Lägg till anteckning',
  },
  notes: {
    id: 'note.notes',
    defaultMessage: 'Anteckningar',
  },
  name: {
    id: 'note.name',
    defaultMessage: 'Namn',
  },
  notesDescription: {
    id: 'note.notesDescription',
    defaultMessage: 'Se & skapa företagsanteckningar',
  },
  noNotes: {
    id: 'note.noNotes',
    defaultMessage: 'Inga anteckningar',
  },
  details: {
    id: 'note.details',
    defaultMessage: 'Detaljer',
  },
  attachFiles: {
    id: 'note.attachFiles',
    defaultMessage: 'Bifoga filer',
  },
  selectFiles: {
    id: 'note.selectFiles',
    defaultMessage: 'Välj filer',
  },
  saveNoteSuccess: {
    id: 'note.createNoteSuccess',
    defaultMessage: 'Anteckningen har sparats',
  },
  confirmDeleteNote: {
    id: 'note.confirmDeleteNote',
    defaultMessage: 'Är du säker på att du vill ta bort anteckningen?',
  },
  deleteNoteSuccess: {
    id: 'note.deleteNoteSuccess',
    defaultMessage: 'Anteckningen har tagits bort',
  },
  deleteNoteFailure: {
    id: 'note.deleteNoteFailure',
    defaultMessage: 'Kunde inte ta bort anteckningen',
  },
  content: {
    id: 'note.content',
    defaultMessage: 'Brödtext',
  },
});

export const noteCategoryMessages = defineMessages<NoteCategory>({
  PENSION: {
    id: 'noteCategory.PENSION',
    defaultMessage: 'Pension',
  },
  INSURANCE: {
    id: 'noteCategory.INSURANCE',
    defaultMessage: 'Försäkringar',
  },
  ADVICE: {
    id: 'noteCategory.ADVICE',
    defaultMessage: 'Rådgivning',
  },
  AGREEMENT: {
    id: 'noteCategory.AGREEMENT',
    defaultMessage: 'Avtal',
  },
  MISC: {
    id: 'noteCategory.MISC',
    defaultMessage: 'Övrigt',
  },
  ARCHIVED: {
    id: 'noteCategory.ARCHIVED',
    defaultMessage: 'Arkivering',
  },
});
