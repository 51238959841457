import { ApolloError } from '@apollo/client';
import { mergeDeep } from '@apollo/client/utilities';
import { UpdateProposalInput } from 'app/apollo/graphql/types';
import { useRef } from 'react';

type Input = Omit<UpdateProposalInput, 'id'>;

interface Response {
  addToInputQueue: (mutationInput: Input) => Input;
  clearInputQueue: () => void;
  isStopped: (error: ApolloError) => boolean;
  stopPreviousMutation: () => AbortSignal;
}

/**
 * Manages mutation abortions using the native
 * AbortController API and a queue for mutation inputs.
 *
 * Running mutations are aborted to prevent mutation responses from
 * writing to cache when the response value will be outdated since another
 * field has been updated or the value is already outdated.
 *
 * In order to not lose mutation inputs for aborted mutations,
 * the inputs are queued and merged into the next mutation.
 *
 * @returns addToInputQueue - A function that adds a mutation
 * input to the queue.
 * @returns clearInputQueue - A function that clears the queue
 * upon successful mutations.
 * @returns isStopped - A function that checks if the error
 * is due to an aborted mutation.
 * @returns stopPreviousMutations - A function that aborts
 * the potential running mutation tracked by a signal. The
 * function returns a signal to be passed to the next mutation
 * for a potential future abortion.
 */
export const useStopPreviousMutation = (): Response => {
  const mutationSignalRef = useRef<AbortController>();
  const mutationInputRef = useRef<Omit<UpdateProposalInput, 'id'>>();

  const addToInputQueue = (mutationInput: Input) => {
    mutationInputRef.current = mutationInputRef.current
      ? mergeDeep(mutationInputRef.current, mutationInput)
      : mutationInput;
    return mutationInputRef.current;
  };

  const clearInputQueue = () => {
    mutationInputRef.current = undefined;
  };

  const stopPreviousMutation = () => {
    mutationSignalRef.current?.abort();
    mutationSignalRef.current = new AbortController();
    return mutationSignalRef.current.signal;
  };

  return {
    addToInputQueue,
    clearInputQueue,
    isStopped: error => error.networkError?.name === 'AbortError',
    stopPreviousMutation,
  };
};
