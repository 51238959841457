import { notesQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: notesQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: notesQuery;
  },
) => {
  if (!previousResult?.notes || !fetchMoreResult?.notes) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.notes;

  return {
    notes: {
      pageInfo,
      edges: [...edges],
    },
  };
};
