import { BenefitType, isSalaryExchangeBenefit } from '@frontend/benefit-types';
import { A, SectionHeader } from '@frontend/ui';
import {
  salaryExchangeReportsIntroQuery,
  salaryExchangeReportsIntroQueryVariables,
} from 'app/apollo/graphql/types';
import { menuMessages } from 'app/messages/menu';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { EXTERNAL_URLS } from 'app/utils/external-urls';
import { useQuery } from 'app/utils/use-query';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { companySalaryExchangeMessages } from '../../messages';
import { SALARY_EXCHANGE_REPORTS_INTRO_QUERY } from './graphql/queries';

const benefitTypeNames: BenefitType[] = ['advinans_salary_exchange'];

export const SalaryExchangeReportsIntro = () => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data } = useQuery<
    salaryExchangeReportsIntroQuery,
    salaryExchangeReportsIntroQueryVariables
  >(SALARY_EXCHANGE_REPORTS_INTRO_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: { companyId, benefitTypeNames },
  });

  if (!data?.company?.flexBenefits?.edges.length) {
    return null;
  }

  const salaryExchangeBenefits = data.company.flexBenefits.edges
    .map(({ node }) => node)
    .filter(isSalaryExchangeBenefit);

  const salaryExchangeProviders = salaryExchangeBenefits.flatMap(
    benefit => benefit.configuration?.data?.providers,
  );

  const usesCollectum = salaryExchangeProviders.includes('collectum');

  return (
    <>
      <SectionHeader>
        <FormattedMessage {...menuMessages.reports} />
      </SectionHeader>
      <DescriptionWrapper>
        <FormattedMessage
          {...companySalaryExchangeMessages.salaryExchangeReportsDesc}
        />
      </DescriptionWrapper>
      {usesCollectum && (
        <DescriptionWrapper>
          <FormattedMessage
            {...companySalaryExchangeMessages.salaryExchangeReportCollectumDesc}
            values={{
              link: (
                <A href={EXTERNAL_URLS.collectum} target="__blank">
                  <FormattedMessage
                    {...companySalaryExchangeMessages.readMoreAboutCollectum}
                  />
                </A>
              ),
            }}
          />
        </DescriptionWrapper>
      )}
    </>
  );
};
