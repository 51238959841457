import { SelectField, TextField } from '@frontend/formik';
import { SelectOption } from '@frontend/ui';
import { validationMessages } from 'app/messages/common';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import React from 'react';
import * as Yup from 'yup';

import { orgUnitMessages } from '../../messages';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  name: string;
  slug?: string;
  unitOf?: string;
}

interface Props {
  orgUnitSelectOptions: SelectOption[];
}

export const OrganizationalUnitFormFields: React.FC<Props> = ({
  orgUnitSelectOptions,
}) => {
  const intl = useIntl();

  return (
    <>
      <TextField
        dense
        type="text"
        name="name"
        label={intl.formatMessage(orgUnitMessages.name)}
        required
      />
      <TextField
        dense
        type="text"
        name="slug"
        label={intl.formatMessage(orgUnitMessages.slug)}
      />

      <SelectField
        fixed
        dense
        type="select"
        name="unitOf"
        label={<FormattedMessage {...orgUnitMessages.unitOf} />}
        options={[
          {
            value: 'NO_ORG_UNIT',
            label: intl.formatMessage(orgUnitMessages.noOrgUnit),
          },
          ...orgUnitSelectOptions,
        ]}
      />
    </>
  );
};
