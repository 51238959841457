import { salaryReportsQuery } from 'app/apollo/graphql/types';

export const updateSalaryReportsQuery = (
  previousResult: salaryReportsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: salaryReportsQuery;
  },
) => {
  if (
    !previousResult?.company?.salaryReports ||
    !fetchMoreResult?.company?.salaryReports
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.salaryReports;

  return {
    company: {
      ...previousResult.company,
      salaryReports: {
        pageInfo,
        __typename: previousResult.company.salaryReports.__typename,
        edges: [...edges],
      },
    },
  };
};
