export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M19 13H5v-2h14v2z',
      },
    },
  ],
};
