import { Form, Formik, NumberField, SelectField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import {
  getEffectiveDateOptions,
  getLimitingDate,
  toNumber,
} from '@frontend/utils';
import {
  updatePensionPremiumModalQuery,
  updatePensionPremiumModalQueryVariables,
} from 'app/apollo/graphql/types';
import {
  commonMessages,
  suffixMessages,
  validationMessages,
} from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { pensionMessages } from 'app/messages/pension';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import {
  FormattedCurrency,
  FormattedMessage,
  IntlShape,
  useIntl,
} from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { NoValue } from 'components/NoValue';
import { TopLoading } from 'components/TopLoading';
import { ViewField } from 'components/ViewField';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import qs from 'query-string';
import React from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useParams,
} from 'react-router';
import * as Yup from 'yup';

import { getPensionInsurance } from '../../../utils/get-pension-insurance';
import { UPDATE_PENSION_PREMIUM_MODAL_QUERY } from '../../graphql/queries';
import { getFixedPremiumForDate } from '../../utils/get-fixed-premium-for-date';
import { FormValues, useSubmit } from '../../utils/use-submit';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    premium: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    effectiveDate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const UpdateExtraPremiumModal: React.FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { locale } = useIntlContext();
  const history = useHistory();
  const { userAccountId, companyId } = useParams<EmployeeRouteMatchParams>();
  const { search } = useLocation();
  const { update_extra_premium: isOpen } = qs.parse(search);

  const onRequestClose = () =>
    history.push(
      `/companies/${companyId}/employees/${userAccountId}/occupational`,
    );

  const { submit, mutationError } = useSubmit({
    onRequestClose,
  });

  const { data, loading } = useQuery<
    updatePensionPremiumModalQuery,
    updatePensionPremiumModalQueryVariables
  >(UPDATE_PENSION_PREMIUM_MODAL_QUERY, {
    skip: !isOpen,
    variables: { userAccountId, companyId, includeFixedPremium: true },
  });

  if (!isOpen || !data?.membership) {
    return null;
  }

  if (loading) {
    return <TopLoading />;
  }

  const currentFixedPremiumItem = getFixedPremiumForDate({
    fixedPremiums: [...(data.fixedPremium ?? [])],
    today: new Date(),
  });
  const currentPremium = currentFixedPremiumItem?.premium;
  const currentEffectiveDate = currentFixedPremiumItem?.effectiveDate;

  const pension = getPensionInsurance(
    data.membership.insurances?.edges.map(({ node }) => node) ?? [],
  );

  const { benefitPackageEffectiveDate } = data.membership;

  const availableDates = [pension?.effectiveDate, benefitPackageEffectiveDate];

  const limitingDate = getLimitingDate(availableDates);

  const effectiveDateOptions = getEffectiveDateOptions(
    new Date(),
    limitingDate,
  );

  return (
    <Modal size="medium" isOpen onRequestClose={onRequestClose}>
      <Formik<FormValues>
        initialValues={{
          premium: toNumber(currentPremium) ?? 0,
          effectiveDate: currentEffectiveDate ?? '',
        }}
        validateOnMount
        validationSchema={validationSchema(intl)}
        onSubmit={submit}
      >
        {({ isValid, isSubmitting, errors }) => (
          <Form>
            <ModalHeader>
              <FormattedMessage {...pensionMessages.updateFixedExtraPremium} />
            </ModalHeader>
            <ModalBody>
              <p>
                <FormattedMessage
                  {...pensionMessages.updateFixedExtraPremiumDesc}
                />
              </p>
              {currentPremium && (
                <ViewField
                  label={
                    <FormattedMessage
                      {...pensionMessages.currentFixedExtraPremium}
                      values={{
                        effectiveDate: currentEffectiveDate ?? <NoValue />,
                      }}
                    />
                  }
                  value={
                    <FormattedCurrency
                      currency="SEK"
                      value={parseInt(currentPremium, 10)}
                      after="PER_MONTH"
                    />
                  }
                />
              )}
              <NumberField
                dense
                name="premium"
                label={formatMessage(pensionMessages.newFixedExtraPremium)}
                affix={formatMessage(suffixMessages.krPerMonth)}
                decimalScale={0}
                required
                locale={locale}
              />
              <SelectField
                dense
                fixed
                name="effectiveDate"
                options={effectiveDateOptions}
                label={formatMessage(commonMessages.effectiveDate)}
                required
              />
              {mutationError && <GraphQlError inModal error={mutationError} />}
              {errors.submissionError && (
                <NotificationCard inModal type="error">
                  {errors.submissionError}
                </NotificationCard>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  loading={isSubmitting}
                  text
                  type="submit"
                  disabled={!isValid}
                >
                  <FormattedMessage {...formMessages.approve} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const getUpdateExtraPremiumLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ update_extra_premium: true }),
});
