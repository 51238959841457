import { Dot, Table, TableSortButton, Td, Th, Tr } from '@frontend/ui';
import { formatRegistrationNumber } from '@frontend/utils';
import {
  CompaniesOrderByFields,
  pageInfoDetails,
  smeCompaniesQuery_companies_CompanyConnection_edges_CompanyEdge_node_Company as Company,
} from 'app/apollo/graphql/types';
import { TableSort } from 'app/utils/use-table-sort';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { TableNavigation } from 'components/TableNavigation';
import {
  smeCompanyMessages,
  smeProposalStatusMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import { useHistory } from 'react-router';

import { SortableColumns } from '../..';
import { PROPOSAL_STATUS_COLORS } from '../../utils/constants';

interface Props {
  companies: Company[];
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
  sort: TableSort<SortableColumns>;
}

export const CompanyTable: React.FC<Props> = ({
  companies,
  onNextPage,
  onPreviousPage,
  pageInfo,
  sort,
}) => {
  const { push } = useHistory();
  return (
    <Table
      navigation={
        <TableNavigation
          pageInfo={pageInfo}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
        />
      }
    >
      <>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...smeCompanyMessages.registrationNumber} />
            </Th>
            <Th>
              <TableSortButton
                onClick={sort.handleSort}
                order={
                  sort.orderBy === CompaniesOrderByFields.NAME
                    ? sort.order
                    : undefined
                }
                value={CompaniesOrderByFields.NAME}
              >
                <FormattedMessage {...smeCompanyMessages.companyName} />
              </TableSortButton>
            </Th>
            <Th>
              <FormattedMessage {...smeCompanyMessages.seller} />
            </Th>
            <Th align="left">
              <TableSortButton
                onClick={sort.handleSort}
                order={
                  sort.orderBy === CompaniesOrderByFields.PROPOSAL_STATUS
                    ? sort.order
                    : undefined
                }
                value={CompaniesOrderByFields.PROPOSAL_STATUS}
              >
                <FormattedMessage {...smeCompanyMessages.latestQuote} />
              </TableSortButton>
            </Th>
          </Tr>
        </thead>
        <tbody>
          {companies.length === 0 ? (
            <Tr>
              <Td>
                <FormattedMessage {...smeCompanyMessages.noSearchResults} />
              </Td>
            </Tr>
          ) : (
            companies.map(
              ({
                id,
                latestProposalStatus,
                name,
                registrationNumber,
                nlpSalesRep,
              }) => (
                <Tr key={id} linked onClick={() => push(`/sme/${id}`)}>
                  <Td type="number" align="left">
                    {formatRegistrationNumber(registrationNumber)}
                  </Td>
                  <Td>{name}</Td>
                  <Td>
                    {nlpSalesRep ? (
                      `${nlpSalesRep.firstName} ${nlpSalesRep.lastName}`
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                  <Td align="left">
                    {!latestProposalStatus ? (
                      <NoValue />
                    ) : (
                      <Dot
                        colorValue={
                          PROPOSAL_STATUS_COLORS[latestProposalStatus]
                        }
                        size={0.75}
                        label={
                          <FormattedMessage
                            select={latestProposalStatus}
                            messages={smeProposalStatusMessages}
                          />
                        }
                      />
                    )}
                  </Td>
                </Tr>
              ),
            )
          )}
        </tbody>
      </>
    </Table>
  );
};
