import { gql } from 'graphql-tag';

export const pageInfoDetails = gql`
  fragment pageInfoDetails on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
