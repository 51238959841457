import gql from 'graphql-tag';

export const UPDATE_MEMBERSHIP_MUTATION = gql`
  mutation updateMembershipMutation($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      membership {
        id
      }
    }
  }
`;
