import { noteMessages } from 'app/messages/notes';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { NotesOverviewRoute } from './_index';
import { AddNoteRoute } from './add';
import { EditNoteRoute } from './edit';
import { ViewNoteRoute } from './view';

export interface NoteMatchParams {
  companyId: string;
  noteId?: string;
  userAccountId?: string;
}

export const Notes: React.FC<RouteComponentProps<NoteMatchParams>> = ({
  match,
}) => (
  <NavigationAnchor title={noteMessages.notes} url={match.url}>
    <Switch>
      <Route exact path={match.path} component={NotesOverviewRoute} />
      <Route exact path={`${match.path}/add`} component={AddNoteRoute} />
      <Route exact path={`${match.path}/:noteId`} component={ViewNoteRoute} />
      <Route
        exact
        path={`${match.path}/:noteId/edit`}
        component={EditNoteRoute}
      />
    </Switch>
  </NavigationAnchor>
);
