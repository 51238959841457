import browserStore from 'localforage';

const BROWSER_STORE_NAME = 'frontend-app';
const LOCALE_KEY = 'locale';

const PERSISTED_STORAGE_KEYS = [LOCALE_KEY];

export async function clearBrowserStore(): Promise<void> {
  try {
    const keys = await browserStore.keys();
    keys.forEach(async key => {
      if (!PERSISTED_STORAGE_KEYS.includes(key)) {
        await browserStore.removeItem(key);
      }
    });
  } catch {
    throw new Error();
  }
}

export const initBrowserStore = () => {
  browserStore.config({
    name: BROWSER_STORE_NAME,
  });
};

export const setLocale = (locale: string): Promise<string | null> =>
  browserStore.setItem(LOCALE_KEY, locale);
export const getLocale = (): Promise<string | null | undefined> =>
  browserStore.getItem(LOCALE_KEY);

export { browserStore };
