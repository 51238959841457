import { ApolloError } from '@apollo/client';
import { WizardContainer } from '@frontend/ui';
import { EmptyState } from 'components/EmptyState';
import { Modal } from 'components/Modal';
import React from 'react';

interface Props {
  error?: ApolloError;
}

export const WizardEmptyState: React.FC<Props> = ({ error }) => (
  <Modal size="full-window">
    <WizardContainer>
      <EmptyState error={error} />
    </WizardContainer>
  </Modal>
);
