import gql from 'graphql-tag';

export const EDIT_PROPOSAL_FCFW_MUTATION = gql`
  mutation editProposalFcfwMutation($input: UpdateProposalMembershipInput!) {
    updateProposalMembership(input: $input) {
      id
      isFcfw
    }
  }
`;
