import { NumberField } from '@frontend/formik';
import { Subsection, SubsectionHeader } from '@frontend/ui';
import { membershipDetailsQuery_membership_Membership as Membership } from 'app/apollo/graphql/types';
import { commonMessages, suffixMessages } from 'app/messages/common';
import { workInformationMessages } from 'app/messages/employees';
import { FormattedMessage, useIntl } from 'components/formats';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';

export interface VacationFormValues {
  vacationDaysAdvance: number | null;
  vacationDaysPaid: number | null;
  vacationDaysPerYear: number | null;
  vacationDaysSaved: number | null;
  vacationDaysUnpaid: number | null;
}

export const vacationInitialValues = (
  membership: Membership,
): VacationFormValues => ({
  vacationDaysSaved: membership.vacationDaysSaved,
  vacationDaysPaid: membership.vacationDaysPaid,
  vacationDaysUnpaid: membership.vacationDaysUnpaid,
  vacationDaysAdvance: membership.vacationDaysAdvance,
  vacationDaysPerYear: membership.vacationDaysPerYear,
});

export const VacationFields: React.FC = () => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  return (
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...commonMessages.vacation} />
      </SubsectionHeader>
      <NumberField
        dense
        name="vacationDaysSaved"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.vacationDaysSavedLabel}
          />
        }
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
      <NumberField
        dense
        name="vacationDaysPaid"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.vacationDaysPaidLabel}
          />
        }
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
      <NumberField
        dense
        name="vacationDaysUnpaid"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.vacationDaysUnpaidLabel}
          />
        }
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
      <NumberField
        dense
        name="vacationDaysAdvance"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.vacationDaysAdvanceLabel}
          />
        }
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
      <NumberField
        dense
        name="vacationDaysPerYear"
        thousandSeparator={false}
        decimalScale={0}
        label={
          <FormattedMessage
            {...workInformationMessages.vacationDaysPerYearLabel}
          />
        }
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
    </Subsection>
  );
};
