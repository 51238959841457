import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

import { Status } from '../components/FcfwStatus';

interface StatusData {
  requestedDate: string;
}
/**
 * Returns a status for what an asserting for fully capable for work
 * for an employee for a specific date would have for consequenses given a
 * a set of requests.
 *
 * A request is provided by the system for the admin to act upon, by
 * asserting "fully capable for work" for a speficic date.
 *
 * @param {Array.<{effectiveDate:string}>} requests - Array of fully
 * capabable for work requests.
 * @param {string} requestedDate - Effective date
 * @returns {string} 'INSURANCE_WARNING', 'NOT_COMPLETE' or 'COMPLETE.
 *
 * Possible return statuses sorted by priority:
 * 'INSURANCE_WARNING' = The employee would miss out on insurance,
 * for a while or forever.
 * 'NOT_COMPLETE' = More actions are required to complete this
 * employees requests.
 * 'COMPLETE' = No more actions are required to complete this
 * employees requests.
 */
export const fcfwStatus = (
  requests: StatusData[],
  requestedDate: string,
): Status => {
  const effective = new Date(requestedDate);

  const warnings = requests.filter(item =>
    isBefore(new Date(item.requestedDate), effective),
  );
  if (warnings.length > 0) {
    return 'INSURANCE_WARNING';
  }

  const nonCompletes = requests.filter(item =>
    isAfter(new Date(item.requestedDate), effective),
  );
  if (nonCompletes.length > 0) {
    return 'NOT_COMPLETE';
  }

  return 'COMPLETE';
};
