import { formatRegistrationNumber } from '@frontend/utils';
import {
  companyEmployeesImportLayoutQuery,
  companyEmployeesImportLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { employeesImportMessages } from 'app/messages/employees';
import { COMPANY_EMPLOYEES_IMPORT_LAYOUT_QUERY } from 'app/queries';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import { WizardEmptyState } from 'components/WizardEmptyState';
import { Match } from 'features/companies/company/employees/import/steps/match';
import { Preview } from 'features/companies/company/employees/import/steps/preview';
import { Upload } from 'features/companies/company/employees/import/steps/upload';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { MatchParams } from '../..';

export type HandleHistoryReplace = (args: { importId: string }) => void;

export const ImportRoutes: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { companyId },
    path,
    url,
  },
  history: { replace },
}) => {
  const { data, loading, error } = useQuery<
    companyEmployeesImportLayoutQuery,
    companyEmployeesImportLayoutQueryVariables
  >(COMPANY_EMPLOYEES_IMPORT_LAYOUT_QUERY, {
    errorPolicy: 'all',
    variables: { companyId },
  });

  if (loading) {
    return (
      <Modal size="full-window">
        <TopLoading />
      </Modal>
    );
  }

  if (!data?.company) {
    return <WizardEmptyState error={error} />;
  }

  const { registrationNumber, name: companyName } = data.company;

  const title = (
    <FormattedMessage
      {...employeesImportMessages.importEmployeesTo}
      values={{
        regNbr: formatRegistrationNumber(registrationNumber),
        companyName,
      }}
    />
  );

  interface GetHistoryReplaceHandlerArgs {
    newRoute: string;
  }
  const getHistoryReplaceHandler =
    ({ newRoute }: GetHistoryReplaceHandlerArgs): HandleHistoryReplace =>
    ({ importId }) =>
      replace(`${url}/${newRoute}/${importId}`);

  return (
    <Switch>
      <Route path={`${path}/upload`}>
        <Upload
          title={title}
          onContinue={getHistoryReplaceHandler({ newRoute: 'match' })}
        />
      </Route>
      <Route path={`${path}/match/:importId`}>
        <Match
          title={title}
          onContinue={getHistoryReplaceHandler({ newRoute: 'preview' })}
        />
      </Route>
      <Route path={`${path}/preview/:importId`}>
        <Preview
          title={title}
          onPrevious={getHistoryReplaceHandler({ newRoute: 'match' })}
        />
      </Route>
      <Redirect from={path} to={`${path}/upload`} />
    </Switch>
  );
};
