import { Section, SectionHeader, Table, Td, Th, Tr } from '@frontend/ui';
import {
  singlePremiumDepositHistoryTableQuery,
  singlePremiumDepositHistoryTableQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { pensionMessages } from 'app/messages/pension';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
} from 'components/formats';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { SINGLE_PREMIUM_DEPOSIT_HISTORY_TABLE_QUERY } from './graphql/queries';

const getSinglePremiumHistory = (
  data?: singlePremiumDepositHistoryTableQuery,
) => data?.membership?.singlePremiums;

const isEmpty = (data?: singlePremiumDepositHistoryTableQuery) =>
  !getSinglePremiumHistory(data)?.length;

export const SinglePremiumDepositHistoryTable: React.FC = () => {
  const {
    params: { companyId, userAccountId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const { data } = useQuery<
    singlePremiumDepositHistoryTableQuery,
    singlePremiumDepositHistoryTableQueryVariables
  >(SINGLE_PREMIUM_DEPOSIT_HISTORY_TABLE_QUERY, {
    isEmpty,
    suspend: true,
    errorPolicy: 'all',
    variables: { userAccountId, companyId },
  });

  const history = getSinglePremiumHistory(data);

  if (!history?.length) {
    return null;
  }

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...pensionMessages.singlePremiumDeposits} />
      </SectionHeader>
      <Table size="xsmall">
        <colgroup>
          <col style={{ width: '33%' }} />
          <col style={{ width: '33%' }} />
          <col style={{ width: '33%' }} />
        </colgroup>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.refersToDate} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.of} />
            </Th>
            <Th type="number">
              <FormattedMessage {...commonMessages.amountLabel} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {history.map((entry, i) => (
            <Tr key={i}>
              <Td>
                <FormattedDate value={entry.effectiveDate} />
              </Td>
              <Td>
                {entry.authorizedByMembership?.givenName &&
                entry.authorizedByMembership?.lastName ? (
                  <>
                    {entry.authorizedByMembership.givenName}{' '}
                    {entry.authorizedByMembership.lastName}
                  </>
                ) : (
                  <>Nordea Node</>
                )}
              </Td>
              <Td type="number">
                <FormattedCurrency
                  value={entry.premium}
                  currency="SEK"
                  noSuffix
                />
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Section>
  );
};
