import gql from 'graphql-tag';

export const EDIT_PROPOSAL_LIFE_BENEFIT_MUTATION = gql`
  mutation editProposalLifeBenefitMutation(
    $benefitId: ID!
    $input: UpdateProposalLifeBenefitInput!
  ) {
    updateProposalLifeBenefit(input: $input) {
      id
      benefit(id: $benefitId) {
        id
        benefitPackages {
          id
        }
        configuration {
          ... on ProposalLifeBenefitConfig {
            lifeLevel
          }
        }
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
