import { ProposalStatusFilter } from 'app/apollo/graphql/types';

/**
 * This array lists the company proposal status options available for company filtering,
 * sorted for display purposes rather than in the alphabetical order of the enum values.
 */
export const PROPOSAL_STATUS_FILTER_OPTIONS: ProposalStatusFilter[] = [
  ProposalStatusFilter.NO_PROPOSALS,
  ProposalStatusFilter.STARTED,
  ProposalStatusFilter.GENERATED,
  ProposalStatusFilter.IN_PRODUCTION,
];
