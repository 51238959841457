import gql from 'graphql-tag';

export const EDIT_PROPOSAL_DISABILITY_BENEFIT_MUTATION = gql`
  mutation editProposalDisabilityBenefitMutation(
    $benefitId: ID!
    $input: UpdateProposalSicknessBenefitInput!
  ) {
    updateProposalSicknessBenefit(input: $input) {
      id
      benefit(id: $benefitId) {
        id
        benefitPackages {
          id
        }
        configuration {
          ... on ProposalSicknessBenefitConfig {
            converting
            convertingFrom
            extendedSickPay
            salaryMultiplier
            sicknessLevel
          }
        }
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
