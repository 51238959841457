import gql from 'graphql-tag';

export const DUPLICATE_PROPOSAL_MUTATION = gql`
  mutation duplicateProposalMutation($input: DuplicateProposalInput!) {
    duplicateProposal(input: $input) {
      id
      name
    }
  }
`;
