import { isError, Result } from './use-submit';

export interface SalaryExchange {
  userAccountId: string;
}

interface Args<T> {
  results: Result[];
  salaryExchanges: T[];
}

export const getFailedTerminations = <T extends SalaryExchange>({
  salaryExchanges,
  results,
}: Args<T>): T[] => {
  const successIds = results.reduce(
    (acc, r) => (!isError(r) ? [...acc, r.userAccountId] : acc),
    [],
  );

  return salaryExchanges.filter(
    ({ userAccountId }) => !successIds.includes(userAccountId),
  );
};
