import { Button, media, Snackbar } from '@frontend/ui';
import React, { useEffect, useReducer } from 'react';
import styled, { keyframes } from 'styled-components';

import { useNotification } from '..';

const fadeIn = keyframes`
  from { opacity: 0; margin-bottom: -1rem }
  to   { opacity: 1; margin-bottom: 0rem }
`;

const SnackbarWrapper = styled.div`
  animation: ${fadeIn} 0.5s;
  min-width: 21.5rem;
  margin: 0 auto;

  ${media.lessThan('tablet')`
    width: 100%;
    min-width: unset;
    padding: 0 1rem;
  `};
`;

const Container = styled.div<{ navigationOffset: number }>`
  position: fixed;
  left: ${p => `calc(50% + ${p.navigationOffset / 2}px)`};
  transform: translateX(-50%);
  z-index: 9999;
  bottom: 1.5rem;
  display: flex;
  align-items: center;

  ${media.lessThan('desktopHD')`
    bottom: 0.5rem;
  `};

  ${media.lessThan('tablet')`
    transform: unset;
    left: unset;
    width: 100%;
  `}
`;

const DEFAULT_TIME_OUT = 6000;
interface NotificationState {
  remaining: number;
  start: number;
  timer: NodeJS.Timeout | null;
}

interface SetTimeAction {
  type: 'SET_START' | 'SET_REMAINING';
}

interface SetTimeOutAction {
  timer: NodeJS.Timeout | null;
  type: 'SET_TIMER' | 'RESET_TIMER';
}

type NotificationAction = SetTimeOutAction | SetTimeAction;

const initialState: NotificationState = {
  timer: null,
  start: DEFAULT_TIME_OUT,
  remaining: DEFAULT_TIME_OUT,
};

const reducer = (
  state: NotificationState,
  action: NotificationAction,
): NotificationState => {
  switch (action.type) {
    case 'SET_START':
      return { ...state, start: Date.now() };
    case 'SET_REMAINING':
      return {
        ...state,
        remaining: state.remaining - (Date.now() - state.start),
      };
    case 'SET_TIMER':
      return { ...state, timer: action.timer };
    case 'RESET_TIMER': {
      return initialState;
    }
    default:
      return state;
  }
};

export const NotificationCenter: React.FC = () => {
  const { notification, clear } = useNotification();
  const [state, stateDispatch] = useReducer(reducer, initialState);

  // Resumes timer execution with
  // remaining time from component state
  const resume = () => {
    if (state.timer) {
      clearTimeout(state.timer);
    }

    stateDispatch({ type: 'SET_START' });
    stateDispatch({
      type: 'SET_TIMER',
      timer: setTimeout(clear, state.remaining),
    });
  };

  // Clear current timer and set remaining time on
  // notification to be used once resume is called
  const pause = () => {
    if (state.timer) {
      clearTimeout(state.timer);
      stateDispatch({ type: 'SET_TIMER', timer: null });
    }
    stateDispatch({ type: 'SET_REMAINING' });
  };

  const reset = () => {
    if (state.timer) {
      clearTimeout(state.timer);
      stateDispatch({ type: 'RESET_TIMER', timer: null });
    }
  };

  useEffect(() => {
    //  Start notification timer (resume) if we have it
    //  otherwise reset it to initial state
    if (notification != null) {
      resume();
    } else {
      reset();
    }
  }, [notification]);

  if (notification == null) {
    return null;
  }

  const { callback } = notification;

  return (
    <Container navigationOffset={0}>
      <SnackbarWrapper
        onClick={() => clear()}
        onMouseEnter={() => pause()}
        onMouseLeave={() => resume()}
      >
        <Snackbar
          type={notification.type}
          action={
            !!callback && (
              <Button
                text
                onClick={(e: React.SyntheticEvent) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  callback.fn();
                }}
              >
                {callback.label}
              </Button>
            )
          }
        >
          {notification.message}
        </Snackbar>
      </SnackbarWrapper>
    </Container>
  );
};
