import {
  benefitPackageMembershipsQuery,
  benefitPackageMembershipsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { MatchParams } from 'app/pages/companies/company/benefit-packages/benefit-package';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { EmployeesTable } from '../../employees/components/EmployeesTable';
import { queryParam } from '../../employees/components/IncludeTerminatedFilter';
import { BENEFIT_PACKAGE_MEMBERSHIPS_QUERY } from './graphql/queries';
import { updateQuery } from './utils/update-query';

export const BenefitPackageEmployees: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const location = useLocation();

  const parsed = qs.parse(location.search);
  const { search, per_page } = parsed;
  const includeTerminated = parsed[queryParam] === 'true';

  const { previousData, data, loading, error, fetchMore } = useQuery<
    benefitPackageMembershipsQuery,
    benefitPackageMembershipsQueryVariables
  >(BENEFIT_PACKAGE_MEMBERSHIPS_QUERY, {
    variables: {
      benefitPackageId: params.benefitPackageId,
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      search,
      includeTerminated,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;

  if (!_data && loading) {
    return <TopLoading />;
  }

  if (!_data?.benefitPackage?.memberships) {
    return <GraphQlError error={error} />;
  }

  const { edges, pageInfo } = _data.benefitPackage.memberships;

  if (!edges.length && !search?.length) {
    return (
      <EmptyState
        title={
          <FormattedMessage
            {...commonBenefitMessages.benefitPackageNoEmployeesTitle}
          />
        }
      >
        <FormattedMessage
          {...commonBenefitMessages.benefitPackageNoEmployeesDescription}
        />
      </EmptyState>
    );
  }

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <EmployeesTable
        hideAddEmployee
        pageInfo={pageInfo}
        onNextPage={() => {
          fetchMore({
            variables: {
              after: pageInfo.endCursor,
            },
            updateQuery,
          });
        }}
        onPreviousPage={() => {
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
              first: undefined,
              last: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
            },
            updateQuery,
          });
        }}
        employees={edges.map(e => e.node)}
      />
    </>
  );
};
