import { RadioGroupField } from '@frontend/formik';
import { Subsection, SubsectionHeader } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';

import { benefitFormMessages } from '../../messages';
import { Agreements } from '../../types';
import { getHasEaTjpAgreement } from '../../utils';

interface Props {
  agreements: Agreements;
  name: string;
}

export const PremiumWaiverSection: React.FC<Props> = ({ agreements, name }) => {
  const { formatMessage } = useIntl();

  const hasEaTjpAgreement = getHasEaTjpAgreement(agreements);

  return (
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...benefitFormMessages.premiumWaiver} />
      </SubsectionHeader>
      {!hasEaTjpAgreement && (
        <NotificationCard type="warning">
          <FormattedMessage
            {...benefitFormMessages.missingPremiumWaiverAgreement}
          />
        </NotificationCard>
      )}
      <RadioGroupField
        disabled={!hasEaTjpAgreement}
        name={name}
        options={[
          {
            label: formatMessage(commonMessages.yes),
            value: 'yes',
          },
          {
            label: formatMessage(commonMessages.no),
            value: 'no',
          },
        ]}
        required={hasEaTjpAgreement}
      />
    </Subsection>
  );
};
