import gql from 'graphql-tag';

const organizationMembership = gql`
  fragment organizationMembership on Membership {
    id
    userAccountId
    company {
      id
    }
    firstName
    lastName
    organizationalUnit {
      id
      name
    }
    accountingCost {
      from
      to
      accountingCost {
        object {
          id
          name
          dimension {
            id
          }
        }
      }
    }
  }
`;

const organizationCompany = gql`
  fragment organizationCompany on Company {
    id
    organizationalUnits {
      edges {
        node {
          id
          name
        }
      }
    }
    costCenters {
      edges {
        node {
          id
          identifier
          name
        }
      }
    }
  }
`;

const organizationAccountingDimension = gql`
  fragment organizationAccountingDimension on AccountingDimension {
    id
    identifier
    name
  }
`;

export const MEMBERSHIP_ORGANIZATION_QUERY = gql`
  query membershipOrganizationQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      ...organizationMembership
    }
    accountingDimensions(companyId: $companyId) {
      ...organizationAccountingDimension
    }
    company(id: $companyId) {
      ...organizationCompany
    }
  }
  ${organizationMembership}
  ${organizationAccountingDimension}
  ${organizationCompany}
`;
