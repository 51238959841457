import gql from 'graphql-tag';

export const DIGITAL_ADVICE_TABLE_QUERY = gql`
  query digitalAdviceTableQuery(
    $userAccountId: ID!
    $companyId: ID!
    $benefitTypeNames: [String!]
  ) {
    entitlements(
      userAccountId: $userAccountId
      companyId: $companyId
      benefitTypeNames: $benefitTypeNames
    ) {
      edges {
        node {
          benefit {
            id
            content {
              locale
            }
          }
        }
      }
    }
  }
`;
