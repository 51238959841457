import gql from 'graphql-tag';

export const CREATE_PROPOSAL_LIFE_BENEFIT_MUTATION = gql`
  mutation createProposalLifeBenefitMutation(
    $input: CreateProposalLifeBenefitInput!
  ) {
    createProposalLifeBenefit(input: $input) {
      id
      benefits {
        id
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
