import { costReportingMessages } from 'app/messages/cost-reporting';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { CostReportingDimension as Dimension } from 'features/companies/company/cost-reporting/dimension';
import { CreateDimensionObject } from 'features/companies/company/cost-reporting/dimension/create';
import { EditDimensionObject } from 'features/companies/company/cost-reporting/dimension/edit';
import { useAccountingDimension } from 'features/companies/company/cost-reporting/utils/use-accounting-dimension';
import { Page } from 'features/page';
import React from 'react';
import { useRouteMatch } from 'react-router';

interface MatchState extends CompanyMatchParams {
  dimensionId: string;
}

export const CostReportingDimension: React.FC = () => {
  const {
    params: { companyId, dimensionId },
    url,
  } = useRouteMatch<MatchState>();
  const { formatMessage } = useIntl();

  const {
    loading: dimensionLoading,
    name: dimensionName,
    identifier: dimensionCode,
  } = useAccountingDimension(dimensionId);

  if (dimensionLoading) {
    return <TopLoading />;
  }

  const title = formatMessage(costReportingMessages.dimensionAppbarTitle, {
    dimensionName,
    dimensionCode,
  });

  return (
    <NavigationAnchor title={title} url={url}>
      <Page
        parentLink={`/companies/${companyId}/settings/cost-reporting`}
        title={title}
      >
        <Dimension />
        <EditDimensionObject />
        <CreateDimensionObject />
      </Page>
    </NavigationAnchor>
  );
};
