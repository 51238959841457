import gql from 'graphql-tag';

export const MEMBERSHIP_ROLES_QUERY = gql`
  query membershipRolesQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      userAccountId
      company {
        id
      }
      roles
    }
  }
`;
