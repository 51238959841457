import { Section, SectionHeader } from '@frontend/ui';
import {
  membershipDetailsQuery,
  membershipDetailsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { MEMBERSHIP_DETAILS_QUERY } from './graphql/queries';
import { PersonalDetailsTable } from './PersonalDetailsTable';

export const MembershipDetails: React.FC = () => {
  const { params } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data } = useQuery<
    membershipDetailsQuery,
    membershipDetailsQueryVariables
  >(MEMBERSHIP_DETAILS_QUERY, {
    variables: {
      userAccountId: params.userAccountId,
      companyId: params.companyId,
    },
    errorPolicy: 'all',
    suspend: true,
  });

  if (!data?.membership) {
    return null;
  }

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonMessages.informationDetails} />
      </SectionHeader>
      <PersonalDetailsTable membership={data.membership} />
    </Section>
  );
};
