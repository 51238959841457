import { ApolloError, useMutation } from '@apollo/client';
import {
  addBenefitsToBenefitPackageMutation,
  addBenefitsToBenefitPackageMutationVariables,
  FlexAddBenefitsToBenefitPackageInput,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import format from 'date-fns/format';
import { useNotification } from 'features/notifications';
import { useHistory } from 'react-router';

import { FormValues } from '../form';
import { ADD_BENEFITS_TO_BENEFIT_PACKAGE_MUTATION } from '../graphql/mutations';
import { getSelectedBenefitIds } from './';

interface Props {
  benefitPackageId: string;
  companyId: string;
  parentLink: string;
}

interface Submit {
  submit: (initialValues: FormValues, values: FormValues) => void;
  submissionError?: ApolloError;
}

export const useSubmit = ({
  benefitPackageId,
  companyId,
  parentLink,
}: Props): Submit => {
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const { confirm } = useConfirm();

  const [addBenefits, { error: submissionError }] = useMutation<
    addBenefitsToBenefitPackageMutation,
    addBenefitsToBenefitPackageMutationVariables
  >(ADD_BENEFITS_TO_BENEFIT_PACKAGE_MUTATION, {
    update: cache => {
      cache.evict({ id: `Company:${companyId}`, fieldName: 'flexBenefits' });
      cache.evict({
        id: `BenefitPackage:${benefitPackageId}`,
        fieldName: 'benefits',
      });
      cache.gc();
    },
  });

  const submit = async (initialValues: FormValues, values: FormValues) => {
    const confirmed = await confirm({
      title: formatMessage(
        commonBenefitMessages.confirmAddBenefitsToBenefitPackageTitle,
      ),
      description: formatMessage(
        commonBenefitMessages.confirmAddBenefitsToBenefitPackageDescription,
      ),
    });

    if (!confirmed) {
      return;
    }

    try {
      const effectiveDate = format(
        new Date(values.effectiveFrom),
        'yyyy-MM-dd',
      );

      const selectedBenefitIds = getSelectedBenefitIds(values);
      const initialBenefitIds = getSelectedBenefitIds(initialValues);

      const newSelectedBenefitIds = selectedBenefitIds.filter(
        id => !initialBenefitIds.includes(id),
      );
      const input: FlexAddBenefitsToBenefitPackageInput = {
        benefitPackageId,
        effectiveDate,
        benefitIds: newSelectedBenefitIds,
      };

      await addBenefits({ variables: { input } });

      send({
        message: formatMessage(
          commonBenefitMessages.benefitsAddedToBenefitPackage,
          { count: newSelectedBenefitIds.length },
        ),
        type: 'success',
      });

      push(parentLink);
    } catch (error) {
      // Do nothing
    }
  };

  return { submit, submissionError };
};
