import { Section, SectionHeader } from '@frontend/ui';
import { cancel, edit } from '@frontend/ui/icons';
import { isDeletableEmployment, toEffectiveThrough } from '@frontend/utils';
import {
  employmentDetailsQuery,
  employmentDetailsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employmentMessages } from 'app/messages/employees';
import { EmploymentRouteMatchParams } from 'app/pages/companies/company/employees/employee/employments/employment';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage, FormattedPercent } from 'components/formats';
import { ListTable, ListTableItem } from 'components/ListTable';
import { NoValue } from 'components/NoValue';
import React, { useState } from 'react';
import { FormattedDate } from 'react-intl';
import { useRouteMatch } from 'react-router';

import { DeleteEmploymentModal } from './delete';
import { EditEmploymentModal } from './edit';
import { EMPLOYMENT_DETAILS_QUERY } from './graphql/queries';

const isEmpty = (data?: employmentDetailsQuery) => !data?.employment;

export const EmploymentDetails: React.FC = () => {
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const {
    params: { employmentId },
  } = useRouteMatch<EmploymentRouteMatchParams>();

  const { data } = useQuery<
    employmentDetailsQuery,
    employmentDetailsQueryVariables
  >(EMPLOYMENT_DETAILS_QUERY, {
    variables: { id: employmentId },
    errorPolicy: 'all',
    suspend: true,
    isEmpty,
  });

  if (!data?.employment) {
    return null;
  }

  const { employment } = data;
  const {
    period: [effectiveDate, effectiveUntil],
    rate,
  } = employment;

  const today = new Date().toISOString();
  const _isDeletableEmployment = isDeletableEmployment(today, effectiveDate);

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonMessages.employment} />
      </SectionHeader>
      <EditEmploymentModal
        isDeletableEmployment={_isDeletableEmployment}
        employment={employment}
        isOpen={isEditModalOpen}
        onRequestClose={() => {
          setEditModalOpen(false);
        }}
      />
      <DeleteEmploymentModal
        employment={employment}
        isOpen={isDeleteModalOpen}
        onRequestClose={() => {
          setDeleteModalOpen(false);
        }}
      />
      <ChipsWrapper>
        <AssistChip
          onClick={() => {
            setEditModalOpen(true);
          }}
          text={<FormattedMessage {...employmentMessages.editEmployment} />}
          leadingIcon={edit}
        />
        {_isDeletableEmployment && (
          <AssistChip
            onClick={() => {
              setDeleteModalOpen(true);
            }}
            text={<FormattedMessage {...employmentMessages.deleteEmployment} />}
            leadingIcon={cancel}
          />
        )}
      </ChipsWrapper>
      <ListTable>
        <tbody>
          <ListTableItem
            label={
              <FormattedMessage {...employmentMessages.employmentStartDate} />
            }
          >
            <FormattedDate value={effectiveDate} />
          </ListTableItem>
          <ListTableItem
            label={
              <FormattedMessage {...employmentMessages.employmentEndDate} />
            }
          >
            {effectiveUntil ? (
              <FormattedDate value={toEffectiveThrough(effectiveUntil)} />
            ) : (
              <NoValue />
            )}
          </ListTableItem>
          <ListTableItem
            label={
              <FormattedMessage {...employmentMessages.partTimeEmployment} />
            }
          >
            <FormattedPercent value={rate} />
          </ListTableItem>
        </tbody>
      </ListTable>
    </Section>
  );
};
