import { DatePickerField, NumberField, SelectField } from '@frontend/formik';
import { Grid, GridCell } from '@frontend/ui';
import { EmploymentActivityType } from 'app/apollo/graphql/types';
import { commonMessages, suffixMessages } from 'app/messages/common';
import { employmentMessages } from 'app/messages/employees';
import { employeeStatus } from 'app/messages/status';
import { FormattedMessage, useIntl } from 'components/formats';
import { GridCell50 } from 'components/GridCell';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';

export const EmploymentActivityFormFields: React.FC<{ edit?: boolean }> = ({
  edit,
}) => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  return (
    <Grid>
      {!edit && (
        <GridCell span={12}>
          <SelectField
            name="type"
            label={
              <FormattedMessage
                {...employmentMessages.employmentActivityType}
              />
            }
            options={[
              {
                label: formatMessage(employeeStatus.OFF_DUTY),
                value: EmploymentActivityType.OFF_DUTY,
              },
              {
                label: formatMessage(employeeStatus.PARENTAL_LEAVE),
                value: EmploymentActivityType.PARENTAL_LEAVE,
              },
            ]}
            required
          />
        </GridCell>
      )}
      <GridCell50>
        <DatePickerField
          name="effectiveDate"
          label={<FormattedMessage {...commonMessages.startingAt} />}
          required
        />
      </GridCell50>
      <GridCell50>
        <DatePickerField
          name="effectiveThrough"
          label={<FormattedMessage {...commonMessages.endingOn} />}
        />
      </GridCell50>
      <GridCell span={12}>
        <NumberField
          name="rate"
          thousandSeparator={false}
          label={<FormattedMessage {...commonMessages.extent} />}
          affix={formatMessage(suffixMessages.percentage)}
          locale={locale}
        />
      </GridCell>
    </Grid>
  );
};
