import { ApolloError } from '@apollo/client';
import {
  CheckboxField as FormikCheckboxField,
  CheckboxFieldProps,
  CheckboxGroupField,
  CheckboxGroupOption,
  Form,
  FormikProps,
  SelectField as FormikSelectField,
  SelectFieldProps,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  CheckboxField as CheckboxFieldUI,
  Grid,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { totalCompensationConfigurationQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType as RemunerationType } from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell33, TextGrid } from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

const CheckboxField: React.FC<CheckboxFieldProps> = props => (
  <div>
    <FormikCheckboxField withMargin={false} {...props} />
  </div>
);

const SubField = styled.div`
  margin-left: 2.5rem;
  margin-top: 0.5rem;
`;

const SelectField: React.FC<SelectFieldProps> = props => (
  <Grid>
    <GridCell33>
      <SubField>
        <FormikSelectField dense gridMargin {...props} />
      </SubField>
    </GridCell33>
  </Grid>
);

export interface FormValues {
  benefitPackageIds?: string[];
  benefits?: boolean;
  pension?: boolean;
  salary?: boolean;
  salaryExchange?: boolean;
  salaryPayrollElementCode?: string;
  submissionError?: string;
  vacation?: boolean;
  variableCompensation?: boolean;
  variableCompensationPayrollElementCode?: string;
  wellness?: boolean;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape(
    {
      salaryPayrollElementCode: Yup.string().when('salary', {
        is: true,
        then: schema =>
          schema.required(
            intl.formatMessage(validationMessages.mandatoryField),
          ),
      }),
      variableCompensationPayrollElementCode: Yup.string().when(
        'variableCompensation',
        {
          is: true,
          then: schema =>
            schema.required(
              intl.formatMessage(validationMessages.mandatoryField),
            ),
        },
      ),
    },
    [['salary', 'variableCompensation']],
  );

interface Props extends FormikProps<FormValues> {
  benefitPackageOptions: CheckboxGroupOption[];
  remunerationTypes: RemunerationType[];
  submissionError?: ApolloError;
}

export const ConfigurationForm: React.FC<Props> = ({
  benefitPackageOptions,
  remunerationTypes,
  values: {
    benefitPackageIds,
    benefits,
    pension,
    salary,
    salaryExchange,
    vacation,
    variableCompensation,
    wellness,
  },
  setFieldValue,
  submissionError,
}) => {
  const { isSubmitting, isValid, dirty } = useFormikContext<FormValues>();

  const backagesAllChecked =
    benefitPackageIds?.length === benefitPackageOptions.length;

  const backageIndeterminate =
    (benefitPackageIds?.length ?? 0) < benefitPackageOptions.length &&
    benefitPackageIds?.length !== 0;

  const handleSelectAllBackages = (checked: boolean) => {
    const _selectedPackageIds = checked
      ? benefitPackageOptions.map(({ value }) => value)
      : [];

    setFieldValue('benefitPackageIds', _selectedPackageIds);
  };

  const configFields = {
    benefits,
    pension,
    salary,
    salaryExchange,
    vacation,
    variableCompensation,
    wellness,
  };

  const configAllChecked = Object.values(configFields).every(value => !!value);

  const configIndeterminate =
    !configAllChecked && Object.values(configFields).some(value => !!value);

  const handleSelectAllConfig = (checked: boolean) => {
    const keys = Object.keys(configFields);
    keys.map(key => setFieldValue(key, checked));
  };

  const remunerationOptions = remunerationTypes.map(({ name, id }) => ({
    label: `${id} ${name ?? ''}`,
    value: id,
  }));

  return (
    <Form>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage
            {...commonBenefitMessages.benefitPackagesFieldLabel}
          />
        </SubsectionHeader>
        <DescriptionWrapper>
          <FormattedMessage
            {...totalCompensationMessages.totalCompensationConfigurationBenefitPackages}
          />
        </DescriptionWrapper>
        {!benefitPackageOptions.length && (
          <NotificationCard type="warning">
            <FormattedMessage {...commonBenefitMessages.noBenefitPackages} />
          </NotificationCard>
        )}
        {!!benefitPackageOptions.length && (
          <>
            <CheckboxFieldUI
              checked={backagesAllChecked}
              onChange={handleSelectAllBackages}
              indeterminate={backageIndeterminate}
            >
              <FormattedMessage {...formMessages.selectAll} />
            </CheckboxFieldUI>
            <CheckboxGroupField
              name="benefitPackageIds"
              options={benefitPackageOptions}
            />
          </>
        )}
      </Subsection>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage
            {...totalCompensationMessages.totalCompensationConfigurationIncluded}
          />
        </SubsectionHeader>
        <TextGrid>
          <DescriptionWrapper>
            <FormattedMessage
              {...totalCompensationMessages.totalCompensationConfigurationIncludedDescription}
            />
          </DescriptionWrapper>
        </TextGrid>
        <CheckboxFieldUI
          checked={configAllChecked}
          indeterminate={configIndeterminate}
          onChange={handleSelectAllConfig}
        >
          <FormattedMessage {...formMessages.selectAll} />
        </CheckboxFieldUI>
        <CheckboxField
          name="salary"
          label={<FormattedMessage {...totalCompensationMessages.salary} />}
        />
        <SelectField
          name="salaryPayrollElementCode"
          label={
            <FormattedMessage {...commonBenefitMessages.remunerationType} />
          }
          options={remunerationOptions}
          required={!!configFields.salary}
          disabled={!configFields.salary}
        />
        <CheckboxField
          name="variableCompensation"
          label={
            <FormattedMessage
              {...totalCompensationMessages.variableCompensation}
            />
          }
        />
        <SelectField
          name="variableCompensationPayrollElementCode"
          label={
            <FormattedMessage {...commonBenefitMessages.remunerationType} />
          }
          options={remunerationOptions}
          required={!!configFields.variableCompensation}
          disabled={!configFields.variableCompensation}
        />
        <CheckboxField
          name="pension"
          label={
            <FormattedMessage {...commonBenefitMessages.occupationalPension} />
          }
        />
        <CheckboxField
          name="salaryExchange"
          label={<FormattedMessage {...commonBenefitMessages.salaryExchange} />}
        />
        <CheckboxField
          name="benefits"
          label={<FormattedMessage {...commonMessages.benefits} />}
        />
        <CheckboxField
          name="wellness"
          label={
            <FormattedMessage
              {...totalCompensationMessages.fitnessContribution}
            />
          }
        />
        <CheckboxField
          name="vacation"
          label={
            <FormattedMessage {...totalCompensationMessages.vacationDays} />
          }
        />
      </Subsection>
      {submissionError && <GraphQlError error={submissionError} />}
      <ButtonLayout>
        <Button
          type="submit"
          filled
          disabled={!isValid || !dirty}
          loading={isSubmitting}
        >
          <FormattedMessage {...formMessages.save} />
        </Button>
      </ButtonLayout>
    </Form>
  );
};
