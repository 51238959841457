import gql from 'graphql-tag';

export const CREATE_SME_COMPANY_QUERY = gql`
  query createSmeCompanyQuery {
    nlpSalesReps {
      id
      firstName
      lastName
      externalId
    }
    adminViewer {
      id
    }
  }
`;
