import gql from 'graphql-tag';

export const EDIT_PROPOSAL_EMPLOYEE_MUTATION = gql`
  mutation editProposalEmployeeMutation(
    $input: UpdateProposalMembershipInput!
  ) {
    updateProposalMembership(input: $input) {
      id
      benefitPackage {
        id
      }
      email
      firstName
      isOwner
      lastName
      monthlyFixedPensionPremium
      monthlySalary
      personalIdentityNumber
      phoneNumber
    }
  }
`;
