import { ApolloError, useMutation } from '@apollo/client';
import {
  membershipRolesQuery_membership_Membership as Membership,
  updateMembershipMutation,
  updateMembershipMutationVariables,
} from 'app/apollo/graphql/types';
import { formSubmitMessages } from 'app/messages/form';
import { useIntl } from 'components/formats';
import { UPDATE_MEMBERSHIP_MUTATION } from 'features/companies/company/employees/graphql/mutations';
import { useNotification } from 'features/notifications';

import { MEMBERSHIP_ROLES_QUERY } from '../../graphql/queries';
import { FormValues } from '..';

interface Args {
  membership: Membership;
  onRequestClose: () => void;
}

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = ({ onRequestClose, membership }: Args): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [updateMembership, { error }] = useMutation<
    updateMembershipMutation,
    updateMembershipMutationVariables
  >(UPDATE_MEMBERSHIP_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: MEMBERSHIP_ROLES_QUERY,
        variables: {
          companyId: membership.company.id,
          userAccountId: membership.userAccountId,
        },
      },
    ],
  });

  const submit = async (values: FormValues) => {
    try {
      const res = await updateMembership({
        variables: {
          input: {
            ...values,
            companyId: membership.company.id,
            userAccountId: membership.userAccountId,
          },
        },
      });

      if (!res?.data?.updateMembership?.membership) {
        throw new Error();
      }

      send({
        message: formatMessage(
          formSubmitMessages.editContactInformationSubmitSuccess,
        ),
        type: 'success',
      });
      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return { submit, error };
};
