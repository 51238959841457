import gql from 'graphql-tag';

export const DELETE_EMPLOYMENT_MUTATION = gql`
  mutation deleteEmploymentMutation($input: DeleteEmploymentInput!) {
    deleteEmployment(input: $input) {
      employment {
        id
      }
    }
  }
`;
