import { useEffect, useState } from 'react';

interface CheckedRowKey {
  instituteId: string;
  insuranceNumber: string;
}

export const useCheckboxRows = (rowKeys: Array<CheckedRowKey>) => {
  const [checkedRowKeys, setCheckedRows] = useState<Array<CheckedRowKey>>([]);

  // If search result changes, filter out rows that no longer exist
  useEffect(() => {
    setCheckedRows(previousState =>
      previousState.filter(checkedKey =>
        rowKeys.some(
          rowKey =>
            rowKey.instituteId === checkedKey.instituteId &&
            rowKey.insuranceNumber === checkedKey.insuranceNumber,
        ),
      ),
    );
  }, [JSON.stringify(rowKeys)]);

  const allSelected =
    checkedRowKeys.length === rowKeys.length && rowKeys.length > 0;
  const indeterminate = checkedRowKeys.length > 0 && !allSelected;

  const toggleSelectAll = (checked: boolean) => {
    if (checked) {
      setCheckedRows(rowKeys);
    } else {
      setCheckedRows([]);
    }
  };

  const toggleSelectRow = (checked: boolean, key: CheckedRowKey) => {
    if (checked) {
      setCheckedRows(previousState => [...previousState, key]);
    } else {
      setCheckedRows(previousState =>
        previousState.filter(
          ({ insuranceNumber, instituteId }) =>
            insuranceNumber !== key.insuranceNumber ||
            instituteId !== key.instituteId,
        ),
      );
    }
  };

  return {
    checkedRowKeys,
    allSelected,
    indeterminate,
    toggleSelectAll,
    toggleSelectRow,
  };
};
