import gql from 'graphql-tag';

export const UPDATE_SME_COMPANY_CONTACT_PERSON_MUTATION = gql`
  mutation updateSmeCompanyContactPersonMutation(
    $input: SetProposalCompanyContactPersonInput!
  ) {
    setProposalContactPerson(input: $input) {
      firstName
      lastName
      phoneNumber
      email
      personalIdentityNumber
    }
  }
`;

export const UPDATE_SME_COMPANY_HEAD_ADMIN_MUTATION = gql`
  mutation updateSmeCompanyHeadAdminMutation(
    $input: SetProposalCompanyHeadAdminInput!
  ) {
    setProposalHeadAdmin(input: $input) {
      firstName
      lastName
      phoneNumber
      email
      personalIdentityNumber
    }
  }
`;
