import gql from 'graphql-tag';

const agreementDetails = gql`
  fragment agreementDetails on Agreement {
    id
    status
    attachments {
      name
      type
      url
    }
    ... on AgreementEuroAccident {
      subAgreementType
    }
    ... on AgreementRequestEuroAccident {
      subAgreementType
    }
  }
`;

export const AGREEMENTS_LIST_QUERY = gql`
  query agreementsListQuery($id: ID!) {
    company(id: $id) {
      id
      agreements {
        ...agreementDetails
      }
    }
  }
  ${agreementDetails}
`;
