import { BENEFIT_TYPES, BenefitType } from '@frontend/benefit-types';
import { Form, Formik, SelectField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { add } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import {
  companyCreateBenefitOptionsQuery,
  companyCreateBenefitOptionsQueryVariables,
} from 'app/apollo/graphql/types';
import { a11yMessages } from 'app/messages/a11y';
import { benefitTypesMessages } from 'app/messages/benefits';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { MatchParams } from 'app/pages/companies/company';
import { BENEFIT_URL } from 'app/pages/companies/company/benefits/create';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import * as Yup from 'yup';

import { COMPANY_CREATE_BENEFIT_OPTIONS_QUERY } from './graphql/queries';
import { NLP_BENEFIT_TYPES } from './utils/constants';
import { byNameDefault } from './utils/sort-benefits-by';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    benefitType: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  benefitType: BenefitType | '';
}

export const CreateBenefitModal = () => {
  const { params } = useRouteMatch<MatchParams>();
  const history = useHistory();
  const { push } = history;
  const location = useLocation();

  const intl = useIntl();
  const { formatMessage } = intl;

  const search = qs.parse(location.search);
  const isOpen = 'create' in search;

  const onRequestClose = () => {
    stripSearchParams(history, location, ['create']);
  };

  const { data, loading, error } = useQuery<
    companyCreateBenefitOptionsQuery,
    companyCreateBenefitOptionsQueryVariables
  >(COMPANY_CREATE_BENEFIT_OPTIONS_QUERY, {
    errorPolicy: 'all',
    variables: { companyId: params.companyId },
  });

  if (loading && isOpen) {
    return <TopLoading />;
  }

  if (!data?.company?.managedBy) {
    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <ModalHeader>
          <FormattedMessage {...formMessages.createBenefit} />
        </ModalHeader>
        <ModalBody>
          <GraphQlError error={error} inModal />
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button text onClick={onRequestClose}>
              <FormattedMessage {...a11yMessages.close} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </Modal>
    );
  }

  const isManagedByNode = data.company.managedBy === 'NODE';

  const initialValues: FormValues = {
    benefitType: '',
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage {...formMessages.createBenefit} />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={value =>
          push(
            `/companies/${params.companyId}/benefit-packages/benefits/create/${BENEFIT_URL[value.benefitType]}`,
          )
        }
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              <SelectField
                dense
                name="benefitType"
                fixed
                label={<FormattedMessage {...formMessages.selectBenefitType} />}
                options={[
                  ...(isManagedByNode ? BENEFIT_TYPES : NLP_BENEFIT_TYPES),
                ]
                  .sort(byNameDefault)
                  .map(benefitType => ({
                    label: formatMessage({
                      select: benefitType,
                      messages: benefitTypesMessages,
                    }),
                    value: benefitType,
                  }))}
                required
              />
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  text
                  type="submit"
                >
                  <FormattedMessage {...commonMessages.continue} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const getCreateBenefitLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: 'create',
});

export const CreateBenefitAssistChip: React.FC = () => {
  const location = useLocation();

  return (
    <AssistChip
      text={<FormattedMessage {...formMessages.createBenefit} />}
      leadingIcon={add}
      to={getCreateBenefitLink(location)}
    />
  );
};
