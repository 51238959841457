import { smeCompanyProposalsQuery as Query } from 'app/apollo/graphql/types';

interface FetchMore {
  fetchMoreResult?: Query;
}

export const updateProposalsQuery = (
  previousResult: Query,
  { fetchMoreResult }: FetchMore,
): Query => {
  if (!previousResult?.proposals || !fetchMoreResult?.proposals) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.proposals;

  return {
    proposals: {
      edges: [...edges],
      pageInfo,
    },
  };
};
