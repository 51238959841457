import { commonBenefitMessages } from 'app/messages/benefits';
import { IntlShape } from 'components/formats';

interface Content {
  title: string;
}

interface Benefit {
  content: Content;
  type: string;
}

export const getBenefitDisplayName = <T extends Benefit>(
  benefit: T,
  { formatMessage }: IntlShape,
): string =>
  benefit.type === 'advinans_occupational_pension'
    ? formatMessage(commonBenefitMessages.pension)
    : benefit.content.title;
