import { SelectField, SelectOption } from '@frontend/ui';
import React from 'react';

import {
  Props as SimpleTextEditorProps,
  SimpleTextEditor,
} from '../SimpleTextEditor';

interface Props extends SimpleTextEditorProps {
  label: string;
  options: SelectOption[];
}

export const TemplateSelect: React.FC<Props> = ({
  label,
  options,
  ...props
}) => (
  <>
    <SelectField
      dense
      disabled={props.disabled}
      label={label}
      value={props.value}
      onChange={e => props.setValue(e.detail.value)}
      options={options}
    />
    <SimpleTextEditor {...props} />
  </>
);
