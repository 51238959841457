import { toNumber, toPercent } from '@frontend/utils';
import { ProposalPensionPremiumMatrix } from 'app/apollo/graphql/types';
import BigNumber from 'bignumber.js';

import { ProposalPremiumMatrixFormValues } from './types';

/**
 * Format API data to form values format
 */
export const formatPremiumMatrixFormValues = (
  values: ProposalPensionPremiumMatrix,
): ProposalPremiumMatrixFormValues => {
  const { age, baseAmount, baseAmountType, intervals, salaryMultiplier } =
    values;
  return {
    age,
    baseAmount: baseAmount.map(b => (toNumber(b) ?? 0).toFixed(2)),
    baseAmountType,
    intervals: intervals.map(interval =>
      interval.map(i => toPercent(i).toFixed(2)),
    ),
    salaryMultiplier: salaryMultiplier.toString(),
  };
};

/**
 * Format form values to API data format
 */
export const formatPremiumMatrixInput = (
  params: ProposalPremiumMatrixFormValues,
): ProposalPensionPremiumMatrix => {
  const { age, baseAmount, baseAmountType, intervals } = params;
  return {
    age,
    baseAmount: baseAmount.map(b =>
      new BigNumber(b.replace(',', '.')).toFixed(4),
    ),
    baseAmountType,
    intervals: intervals.map(interval =>
      interval.map(i => {
        const value = i || '0,00';
        return new BigNumber(value.replace(',', '.')).dividedBy(100).toFixed(4);
      }),
    ),
    salaryMultiplier: toNumber(params.salaryMultiplier) ?? 0,
  };
};
