import { Collapsible, CollapsibleProps } from '@frontend/ui';
import React, { useState } from 'react';

interface CollapsibleSimpleProps
  extends Omit<CollapsibleProps, 'isOpen' | 'onClick'> {
  defaultOpen?: boolean;
}

export const CollapsibleSimple: React.FC<CollapsibleSimpleProps> = ({
  children,
  defaultOpen = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <Collapsible {...props} isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      {children}
    </Collapsible>
  );
};
