import { Dot } from '@frontend/ui';
import {
  companyCustomColumnsQuery,
  companyCustomColumnsQueryVariables,
  importRecordDetails as ImportRecord,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  employeesImportMessages,
  employeesImportStatusMessages,
  employmentMessages,
} from 'app/messages/employees';
import { UnitCodeOptions, unitCodeSuffixMessages } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import {
  FormattedCurrency,
  FormattedIdentityNumber,
  FormattedMessage,
  FormattedPercent,
} from 'components/formats';
import React, { ReactNode, useState } from 'react';
import { FormattedDate, MessageDescriptor } from 'react-intl';
import { useRouteMatch } from 'react-router';

import { COMPANY_CUSTOM_COLUMNS_QUERY } from '../../../graphql/queries';
import { staticColumnLabelMessages } from '../../match/messages';
import { MatchParams } from '..';

enum EmployeeStatus {
  NEW = 'NEW',
  TERMINATED = 'TERMINATED',
  UPDATED = 'UPDATED',
}

interface EmployeeStatusData {
  color: string;
  title: MessageDescriptor;
}

const EMPLOYEE_STATUS: Record<EmployeeStatus, EmployeeStatusData> = {
  NEW: {
    title: employeesImportMessages.newEmployee,
    color: 'green',
  },
  UPDATED: {
    title: employeesImportMessages.updatedEmployee,
    color: 'orange',
  },
  TERMINATED: {
    title: employeesImportMessages.terminatedEmployee,
    color: 'error',
  },
};

const formatEmployeeStatus = (status: EmployeeStatus) => (
  <Dot
    colorValue={EMPLOYEE_STATUS[status].color}
    size={0.75}
    label={<FormattedMessage {...EMPLOYEE_STATUS[status].title} />}
  />
);
interface Column {
  data: (record: ImportRecord) => ReactNode | undefined;
  title: MessageDescriptor;
  type?: 'number' | 'input';
}

export const TABLE_COLUMNS: Record<string, Column> = {
  IMPORT_STATUS: {
    title: employeesImportMessages.importStatus,
    data: (record: ImportRecord) => (
      <FormattedMessage
        select={record.status}
        messages={employeesImportStatusMessages}
      />
    ),
  },
  EMPLOYEE_STATUS: {
    title: employeesImportMessages.employeeStatus,
    data: ({ id, fields }: ImportRecord) =>
      !id?.userAccountId
        ? formatEmployeeStatus(EmployeeStatus.NEW)
        : !fields?.employment?.effectiveUntil?.generatedChange
          ? formatEmployeeStatus(EmployeeStatus.UPDATED)
          : formatEmployeeStatus(EmployeeStatus.TERMINATED),
  },
  PIN: {
    title: commonMessages.personalIdentityNumber,
    data: ({ id }: ImportRecord) =>
      id?.personalIdentityNumber && (
        <FormattedIdentityNumber value={id?.personalIdentityNumber} />
      ),
  },
  EMPLOYEE_NUMBER: {
    title: staticColumnLabelMessages.EMPLOYEE_NUMBER,
    data: ({ id }: ImportRecord) => id?.employeeNumber?.value,
  },
  EMAIL: {
    title: commonMessages.email,
    data: ({ fields }: ImportRecord) => fields?.email?.generatedChange,
  },
  PHONE: {
    title: commonMessages.phone,
    data: ({ fields }: ImportRecord) => fields?.phone?.generatedChange,
  },
  START_DATE: {
    title: employmentMessages.employmentStartDate,
    data: ({ fields }: ImportRecord) =>
      fields?.employment?.effectiveDate?.generatedChange && (
        <FormattedDate
          value={fields?.employment?.effectiveDate?.generatedChange}
        />
      ),
  },
  END_DATE: {
    title: employmentMessages.employmentEndDate,
    data: ({ fields }: ImportRecord) =>
      fields?.employment?.effectiveUntil?.generatedChange && (
        <FormattedDate
          value={fields.employment.effectiveUntil?.generatedChange}
        />
      ),
  },
  EMPLOYMENT_RATE: {
    title: employmentMessages.partTimeEmployment,
    data: ({ fields }: ImportRecord) =>
      fields?.employment?.rate?.generatedChange && (
        <FormattedPercent value={fields.employment.rate?.generatedChange} />
      ),
  },
  EMPLOYMENT_TYPE: {
    title: staticColumnLabelMessages.EMPLOYMENT_TYPE,
    data: ({ fields }: ImportRecord) =>
      fields?.employment?.type?.generatedChange,
  },
  MONTHLY_SALARY: {
    title: commonMessages.monthlySalary,
    data: ({ fields }: ImportRecord) =>
      fields?.salary?.monthlySalary?.generatedChange && (
        <>
          <FormattedCurrency
            value={fields.salary.monthlySalary.generatedChange}
            currency="SEK"
            noSuffix
          />
          <br />
          <small>
            <FormattedMessage {...commonMessages.effectiveDate} />{' '}
            <FormattedDate value={fields.salary?.effectiveDate ?? ''} />
          </small>
        </>
      ),
  },
  ACTIVITY: {
    title: employmentMessages.employmentActivity,
    data: ({ fields }: ImportRecord) =>
      (fields?.activity?.effectiveDate?.generatedChange ||
        fields?.activity?.effectiveUntil?.generatedChange ||
        fields?.activity?.rate?.generatedChange) && (
        <>
          <FormattedMessage
            {...employeesImportMessages.employeeStatus}
            values={{
              status: fields.activity.type,
            }}
          />
          <br />
          <small>
            {fields.activity.effectiveDate.generatedChange && (
              <FormattedDate
                value={fields.activity.effectiveDate.generatedChange}
              />
            )}
          </small>
          -
          <small>
            {fields.activity.effectiveUntil.generatedChange && (
              <FormattedDate
                value={fields.activity.effectiveUntil.generatedChange}
              />
            )}
          </small>
          {fields.activity.rate.generatedChange && (
            <>
              <br />
              <small>
                <FormattedMessage
                  {...employmentMessages.employmentActivityRateLabel}
                  values={{
                    rate: (
                      <FormattedPercent
                        value={fields.activity.rate.generatedChange}
                      />
                    ),
                  }}
                />
              </small>
            </>
          )}
        </>
      ),
  },
  COLLECTIVE_AGREEMENT: {
    title: commonMessages.collectiveAgreement,
    data: ({ fields }: ImportRecord) =>
      fields?.collectiveAgreement?.generatedChange,
  },
  VACATION_DAYS_SAVED: {
    title: staticColumnLabelMessages.VACATION_DAYS_SAVED,
    data: ({ fields }: ImportRecord) =>
      fields?.vacationDaysSaved?.generatedChange,
    type: 'number',
  },
  VACATION_DAYS_PAID: {
    title: staticColumnLabelMessages.VACATION_DAYS_PAID,
    data: ({ fields }: ImportRecord) =>
      fields?.vacationDaysPaid?.generatedChange,
    type: 'number',
  },
  VACATION_DAYS_UNPAID: {
    title: staticColumnLabelMessages.VACATION_DAYS_UNPAID,
    data: ({ fields }: ImportRecord) =>
      fields?.vacationDaysUnpaid?.generatedChange,
    type: 'number',
  },
  VACATION_DAYS_ADVANCE: {
    title: staticColumnLabelMessages.VACATION_DAYS_ADVANCE,
    data: ({ fields }: ImportRecord) =>
      fields?.vacationDaysAdvance?.generatedChange,
    type: 'number',
  },
  VACATION_DAYS_PER_YEAR: {
    title: staticColumnLabelMessages.VACATION_DAYS_PER_YEAR,
    data: ({ fields }: ImportRecord) =>
      fields?.vacationDaysPerYear?.generatedChange,
    type: 'number',
  },
  FITNESS_CONTRIBUTION_BALANCE: {
    title: staticColumnLabelMessages.FITNESS_CONTRIBUTION_BALANCE,
    data: ({ fields }: ImportRecord) =>
      fields?.fitnessContributionBalance?.generatedChange && (
        <FormattedCurrency
          value={fields.fitnessContributionBalance.generatedChange}
          currency="SEK"
          noSuffix
        />
      ),
    type: 'number',
  },
  COMPANY_CAR_MONTHLY: {
    title: staticColumnLabelMessages.COMPANY_CAR_MONTHLY_VALUE,
    data: ({ fields }: ImportRecord) =>
      fields?.companyCarMonthlyValue?.generatedChange && (
        <FormattedCurrency
          value={fields.companyCarMonthlyValue.generatedChange}
          currency="SEK"
          noSuffix
        />
      ),
    type: 'number',
  },
  INCOME_TAX_TABLE: {
    title: staticColumnLabelMessages.INCOME_TAX_TABLE,
    data: ({ fields }: ImportRecord) => fields?.incomeTaxTable?.generatedChange,
    type: 'number',
  },
  COST_CENTER: {
    title: staticColumnLabelMessages.COST_CENTER,
    data: ({ fields }: ImportRecord) => fields?.costCenter?.generatedChange,
  },
  ORGANIZATIONAL_UNIT: {
    title: commonMessages.organizationalUnit,
    data: ({ fields }: ImportRecord) =>
      fields?.organizationalUnits?.generatedChange?.reduce(
        (acc, curr, index) => (index === 0 ? curr : `${acc} / ${curr}`),
      ),
  },
  BENEFIT_PACKAGE: {
    title: commonMessages.benefitPackage,
    data: ({ fields }: ImportRecord) =>
      fields?.benefitPackage?.benefitPackageId?.generatedChange &&
      `${fields.benefitPackage.benefitPackageId.generatedChange} (${fields.benefitPackage.effectiveDate})`,
  },
  IS_OWNER: {
    title: staticColumnLabelMessages.IS_OWNER,
    data: ({ fields }: ImportRecord) => fields?.isOwner?.generatedChange,
  },
  HOURS_WORKED: {
    title: staticColumnLabelMessages.HOURS_WORKED,
    data: ({ fields }: ImportRecord) =>
      fields?.employment?.hoursWorked?.generatedChange,
  },
};

export interface CustomColumn {
  data: (record: ImportRecord) => ReactNode | undefined;
  title: string;
  type?: string;
}

export const useCustomTableColumns = (): CustomColumn[] => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  const [columns, setColumns] = useState<CustomColumn[]>([]);

  useQuery<companyCustomColumnsQuery, companyCustomColumnsQueryVariables>(
    COMPANY_CUSTOM_COLUMNS_QUERY,
    {
      variables: {
        companyId,
      },
      skip: !companyId,
      errorPolicy: 'all',
      onCompleted: data => {
        const _columns: CustomColumn[] = [];
        const accountingDims = data.accountingDimensions;
        if (accountingDims.length) {
          _columns.push(
            ...accountingDims.map(dim => ({
              title: dim.name,
              data: ({ fields }: ImportRecord) =>
                fields?.accountingItems?.find(
                  accItem => accItem.dimension === dim.name,
                )?.identifier.generatedChange,
            })),
          );
        }

        const customTypes = data.company?.customTypes?.edges;
        if (customTypes?.length) {
          _columns.push(
            ...customTypes.map(({ node }) => ({
              title: `${node.id} ${node.name ?? ''}`,
              data: ({ fields }: ImportRecord) => {
                const customType = fields?.customTypeItems?.find(
                  item => item.type.id === node.id,
                );

                if (!customType?.item.generatedChange) {
                  return null;
                }

                return (
                  <>
                    <FormattedCurrency
                      value={customType.item.generatedChange}
                      currency={node.currency ?? ''}
                      noSuffix={!node.currency}
                      after={
                        node.unitCode &&
                        node.unitCode !== UnitCodeOptions.LS && (
                          <>
                            /
                            <FormattedMessage
                              select={node.unitCode}
                              messages={unitCodeSuffixMessages}
                            />
                          </>
                        )
                      }
                    />
                    {customType.from && (
                      <>
                        <br />
                        <small>
                          <FormattedMessage {...commonMessages.effectiveDate} />{' '}
                          <FormattedDate value={customType.from} />
                        </small>
                      </>
                    )}
                  </>
                );
              },
            })),
          );
        }
        setColumns(_columns);
      },
    },
  );
  return columns;
};
