import { TextField } from '@frontend/ui';
import type { TextFieldProps } from '@frontend/ui/components/Fields/Inputs/TextField';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce/lib';

const DEBOUNCE_TIMER = 300;

interface Props extends TextFieldProps {
  label?: React.ReactNode;
  placeholder?: string;
  transparent?: boolean;
}

export const SearchField: React.FC<Props> = ({
  label,
  placeholder,
  ...props
}) => {
  const history = useHistory();
  const location = useLocation();

  const { search: qsSearch } = qs.parse(location.search);

  const [handleSearchChange] = useDebouncedCallback(value => {
    const currentQsSearch = qs.parse(location.search);
    const search = qs.stringify({ ...currentQsSearch, search: value });

    history.push({
      search,
    });
  }, DEBOUNCE_TIMER);

  return (
    <TextField
      {...props}
      label={label}
      onChange={event => handleSearchChange(event.target.value)}
      defaultValue={qsSearch}
    />
  );
};
