import { gql } from 'graphql-tag';

export const invoiceDetails = gql`
  fragment invoiceDetails on Invoice {
    id
    companyId
    status
    payableAmount
    invoiceNumber
    issueDate
    dueDate
    supplierType
    peppolUrl: url(format: PEPPOL)
    sie4Url: url(format: SIE4)
    pdfUrl: url(format: PDF)
    htmlUrl: url(format: HTML)
    excelUrl: url(format: XLSX)
    csvUrl: url(format: CSV)
  }
`;
