import { smeCompaniesQuery as Query } from 'app/apollo/graphql/types';

interface FetchMore {
  fetchMoreResult?: Query;
}

export const updateCompaniesQuery = (
  previousResult: Query,
  { fetchMoreResult }: FetchMore,
): Query => {
  if (!previousResult?.companies || !fetchMoreResult?.companies) {
    return previousResult;
  }

  return {
    ...fetchMoreResult,
  };
};
