import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const requestDetails = gql`
  fragment requestDetails on FlexSalaryExchangeRequest {
    id
    deductionValue
    premium
    provider
    membership {
      id
      givenName
      lastName
      naturalPersonIdentifiers
    }
    remuneration {
      unitCode
      currency
      value: price
    }
  }
`;

export const SALARY_EXCHANGE_REQUESTS_QUERY = gql`
  query salaryExchangeRequestsQuery(
    $companyId: ID!
    $requestIds: [ID!]
    $statuses: [FlexSalaryExchangeRequestStatus!]
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    salaryExchangeRequests(
      companyId: $companyId
      requestIds: $requestIds
      statuses: $statuses
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          ...requestDetails
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${requestDetails}
  ${pageInfoDetails}
`;
