import gql from 'graphql-tag';

export const VACATION_CARD_QUERY = gql`
  query vacationCardQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      vacationDaysPaid
      vacationDaysSaved
      vacationDaysUnpaid
      vacationDaysAdvance
      vacationDaysPerYear
    }
    company(id: $companyId) {
      id
      totalCompensationConfiguration {
        companyId
        vacation
      }
    }
  }
`;
