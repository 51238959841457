import {
  TotalCompensationId2 as TotalCompensationId,
  variableCompensationCardQuery_Query,
} from 'app/apollo/graphql/types';

export const getAnnualCompensation = (
  data?: variableCompensationCardQuery_Query,
): string | null => {
  if (
    !data?.membership?.totalCompensation ||
    !data.company?.totalCompensationConfiguration?.variableCompensation
  ) {
    return null;
  }

  const { totalCompensation } = data.membership;

  const variableSalaryItem = totalCompensation.find(
    item => item.key === TotalCompensationId.VARIABLE_SALARY,
  );

  if (!variableSalaryItem?.annualCompensation) {
    return null;
  }

  return variableSalaryItem.annualCompensation;
};
