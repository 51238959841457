import gql from 'graphql-tag';

export const ASSERT_FULLY_CAPABLE_FOR_WORK_MUTATION = gql`
  mutation assertFullyCapableForWorkMutation(
    $input: AssertFullyCapableForWorkInput!
  ) {
    assertFullyCapableForWork(input: $input) {
      clientMutationId
    }
  }
`;
