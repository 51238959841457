import { useMutation } from '@apollo/client';
import { A, Button, ButtonLayout } from '@frontend/ui';
import {
  editJobOfferMutation,
  editJobOfferMutationVariables,
  JobOfferStatus,
  jobOfferStatusNotificationQuery,
  jobOfferStatusNotificationQueryVariables,
} from 'app/apollo/graphql/types';
import { useQuery } from 'app/utils/use-query';
import { CopyTextButton } from 'components/CopyTextButton';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { NotificationCard } from 'components/NotificationCard';
import { TopLoading } from 'components/TopLoading';
import React from 'react';

import { EDIT_JOB_OFFER_MUTATION } from '../../graphql/mutations';
import { jobOfferMessages } from '../../messages';
import { JOB_OFFER_STATUS_NOTIFICATION_QUERY } from './graphql/queries';
import { getJobOfferLink } from './utils/get-job-offer-link';

export const JOB_OFFER_VALID_DATE_INPUT = 'job-offer-valid-date-input';

interface ButtonProps {
  jobOfferId: string;
  status: JobOfferStatus;
}

const ToggleJobOfferVisibiltyButton: React.FC<ButtonProps> = ({
  status,
  jobOfferId,
}) => {
  const [editJobOffer, { loading: mutationLoading, error }] = useMutation<
    editJobOfferMutation,
    editJobOfferMutationVariables
  >(EDIT_JOB_OFFER_MUTATION);

  return (
    <>
      {status === JobOfferStatus.PUBLIC && (
        <Button
          loading={mutationLoading}
          onClick={() =>
            editJobOffer({
              variables: {
                input: {
                  id: jobOfferId,
                  hidden: true,
                },
              },
            })
          }
        >
          <FormattedMessage {...jobOfferMessages.hideOffer} />
        </Button>
      )}
      {status === JobOfferStatus.PRIVATE && (
        <Button
          loading={mutationLoading}
          onClick={() =>
            editJobOffer({
              variables: { input: { id: jobOfferId, hidden: false } },
            })
          }
        >
          <FormattedMessage {...jobOfferMessages.showOffer} />
        </Button>
      )}
      {status === JobOfferStatus.EXPIRED && (
        <Button
          onClick={() => {
            const node = document.getElementById(JOB_OFFER_VALID_DATE_INPUT);
            if (node) {
              node.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
              });
              setTimeout(() => node.focus(), 500);
            }
          }}
        >
          <FormattedMessage {...jobOfferMessages.setNewValidDate} />
        </Button>
      )}
      {error && <GraphQlError error={error} />}
    </>
  );
};

interface Props {
  jobOfferId: string;
  preview?: boolean;
}

export const JobOfferStatusNotification: React.FC<Props> = ({
  jobOfferId,
  preview,
}) => {
  const { loading, error, data } = useQuery<
    jobOfferStatusNotificationQuery,
    jobOfferStatusNotificationQueryVariables
  >(JOB_OFFER_STATUS_NOTIFICATION_QUERY, {
    suspend: !!preview,
    variables: { jobOfferId },
    errorPolicy: 'all',
  });

  if (loading && !preview) {
    return <TopLoading />;
  }

  if (!data?.jobOffer) {
    return null;
  }

  const JOB_OFFER_LINK = getJobOfferLink(jobOfferId);

  const { status } = data.jobOffer;

  if (preview && status === JobOfferStatus.PUBLIC) {
    return null;
  }

  return (
    <>
      {!preview && error && <GraphQlError error={error} />}
      <NotificationCard
        type={status === JobOfferStatus.PUBLIC ? 'success' : 'warning'}
        actions={
          !preview && (
            <ButtonLayout>
              <ToggleJobOfferVisibiltyButton
                jobOfferId={jobOfferId}
                status={status}
              />
              {(status === JobOfferStatus.PUBLIC ||
                status === JobOfferStatus.PRIVATE) && (
                <CopyTextButton copyText={JOB_OFFER_LINK} />
              )}
            </ButtonLayout>
          )
        }
      >
        {status === JobOfferStatus.PUBLIC ? (
          <FormattedMessage
            {...jobOfferMessages.publicNotification}
            values={{
              link: (
                <A target="_blank" href={`https://${JOB_OFFER_LINK}`}>
                  {JOB_OFFER_LINK}
                </A>
              ),
            }}
          />
        ) : status === JobOfferStatus.PRIVATE ? (
          <FormattedMessage
            {...jobOfferMessages.privateNotification}
            values={{
              link: (
                <A target="_blank" href={`https://${JOB_OFFER_LINK}`}>
                  {JOB_OFFER_LINK}
                </A>
              ),
            }}
          />
        ) : (
          <FormattedMessage {...jobOfferMessages.expiredNotification} />
        )}
      </NotificationCard>
    </>
  );
};
