import { Form, Formik, NumberField } from '@frontend/formik';
import {
  A,
  Dot,
  Figure,
  Grid,
  Section,
  SectionHeader,
  Subsection,
} from '@frontend/ui';
import {
  Args,
  getRemainingMonthlyPensionPremiumCapacity as getPremiumCapacity,
} from '@frontend/utils';
import { suffixMessages } from 'app/messages/common';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { EXTERNAL_URLS } from 'app/utils/external-urls';
import { report } from 'components/ErrorBoundary/lib/report';
import {
  FormattedCurrency,
  FormattedMessage,
  useIntl,
} from 'components/formats';
import { GridCell33, GridCell50 } from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import { NoValue } from 'components/NoValue';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { detect as detectBrowser } from 'detect-browser';
import React, { useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { companySalaryExchangeMessages } from '../messages';
import { Chart } from './Chart';
import { DEFAULT_VALUE, MAX_VALUE } from './utils/constants';

const StyledGridCell50 = styled(GridCell50)`
  > * {
    margin-bottom: 0;
  }
`;

interface FormValues {
  monthlyPensionPremium?: number;
  monthlySicknessInsurancePremium?: number;
  monthlySxPremium?: number;
  onetimePensionDeposits?: number;
  yearlySalary?: number;
}

export const Calculator: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();
  const browser = useMemo(() => detectBrowser(), []);
  const [error, setError] = useState();

  const intl = useIntl();
  const { formatMessage } = intl;
  const { locale } = useIntlContext();

  const getRemainingMonthlyPensionPremiumCapacity = ({
    monthlyPensionPremium,
    monthlySicknessInsurancePremium,
    onetimePensionDeposits,
    yearlySalary,
  }: Args) => {
    try {
      return getPremiumCapacity({
        monthlyPensionPremium,
        monthlySicknessInsurancePremium,
        onetimePensionDeposits,
        yearlySalary,
      });
    } catch (err) {
      // Optimistically report error to stackdriver and write
      // error message to DOM. If error is already written to
      // DOM skip reporting to avoid network and stackdriver pollution
      if (!error) {
        report(err, {
          httpRequest: {
            url: window.location.href,
            userAgent: `${browser?.os}, ${browser?.name}@${browser?.version}`,
          },
          user: JSON.stringify({
            companyId,
          }),
        });
        setError(err.message);
      }

      return 0;
    }
  };

  return (
    <Formik<FormValues>
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      initialValues={{}}
      // The Formik component requires a submission
      // function which  wouldn't make any sense here.
      // This is only to suppress a type error
      onSubmit={() => undefined}
    >
      {({
        values: {
          yearlySalary: _yearlySalary,
          monthlyPensionPremium: _monthlyPensionPremium,
          monthlySicknessInsurancePremium: _monthlySicknessInsurancePremium,
          monthlySxPremium: _monthlySxPremium,
          onetimePensionDeposits: _onetimePensionDeposits,
        },
      }) => {
        const monthlyPensionPremium = Number(_monthlyPensionPremium ?? 0);
        const monthlySicknessInsurancePremium = Number(
          _monthlySicknessInsurancePremium ?? 0,
        );
        const monthlySxPremium = Number(_monthlySxPremium ?? 0);
        const onetimePensionDeposits = Number(_onetimePensionDeposits ?? 0);
        const yearlySalary = Number(_yearlySalary ?? 0);

        const premiumCapacityBeforeSalaryExchange =
          getRemainingMonthlyPensionPremiumCapacity({
            monthlyPensionPremium,
            monthlySicknessInsurancePremium,
            onetimePensionDeposits,
            yearlySalary,
          });

        const remainingSalaryExchangeCapacity =
          premiumCapacityBeforeSalaryExchange > monthlySxPremium
            ? premiumCapacityBeforeSalaryExchange - monthlySxPremium
            : 0;

        const hasSalaryExchangeCapacity =
          !!premiumCapacityBeforeSalaryExchange &&
          premiumCapacityBeforeSalaryExchange > 0;

        const data = [
          {
            name: 'available',
            value: hasSalaryExchangeCapacity
              ? premiumCapacityBeforeSalaryExchange
              : 0,
            remainingCapacity: hasSalaryExchangeCapacity
              ? premiumCapacityBeforeSalaryExchange < monthlySxPremium
                ? monthlySxPremium - premiumCapacityBeforeSalaryExchange
                : 0
              : DEFAULT_VALUE,
          },
          {
            name: 'salaryExchange',
            value: hasSalaryExchangeCapacity ? monthlySxPremium : 0,
            remainingCapacity: hasSalaryExchangeCapacity
              ? remainingSalaryExchangeCapacity
              : DEFAULT_VALUE,
          },
        ];

        return (
          <Form>
            <Section>
              <Subsection>
                <SectionHeader>
                  <FormattedMessage
                    {...companySalaryExchangeMessages.salaryExchangeCapacityTitle}
                  />
                </SectionHeader>
                <FormattedMessage
                  {...companySalaryExchangeMessages.salaryExchangeCapacityText}
                  values={{
                    link: (
                      <A
                        target="_blank"
                        href={EXTERNAL_URLS.skatteverketPensionPremiums}
                      >
                        <FormattedMessage
                          {...companySalaryExchangeMessages.readMoreTaxOffice}
                        />
                      </A>
                    ),
                  }}
                />
              </Subsection>
              {error && (
                <NotificationCard type="error">{error}</NotificationCard>
              )}
              <Subsection>
                <Grid>
                  <GridCell33>
                    <Figure
                      size="medium"
                      title={
                        premiumCapacityBeforeSalaryExchange ? (
                          <FormattedCurrency
                            currency="SEK"
                            after="PER_MONTH"
                            value={premiumCapacityBeforeSalaryExchange}
                          />
                        ) : (
                          <NoValue />
                        )
                      }
                      description={
                        <Dot
                          size={0.75}
                          colorValue="primary30"
                          label={
                            <FormattedMessage
                              {...companySalaryExchangeMessages.highestSalaryExchangePremium}
                            />
                          }
                        />
                      }
                    />
                  </GridCell33>
                  <GridCell33>
                    <Figure
                      size="medium"
                      title={
                        monthlySxPremium ? (
                          <FormattedCurrency
                            currency="SEK"
                            after="PER_MONTH"
                            value={monthlySxPremium}
                          />
                        ) : (
                          <NoValue />
                        )
                      }
                      description={
                        <Dot
                          size={0.75}
                          colorValue="tertiary70"
                          label={
                            <FormattedMessage
                              {...companySalaryExchangeMessages.salaryExchangePremium}
                            />
                          }
                        />
                      }
                    />
                  </GridCell33>
                  <GridCell33>
                    <Figure
                      size="medium"
                      title={
                        monthlySxPremium &&
                        premiumCapacityBeforeSalaryExchange ? (
                          <FormattedCurrency
                            currency="SEK"
                            after="PER_MONTH"
                            value={
                              premiumCapacityBeforeSalaryExchange -
                              monthlySxPremium
                            }
                          />
                        ) : (
                          <NoValue />
                        )
                      }
                      description={
                        <Dot
                          size={0.75}
                          colorValue="surfaceVariant"
                          label={
                            <FormattedMessage
                              {...companySalaryExchangeMessages.capacityRemaining}
                            />
                          }
                        />
                      }
                    />
                  </GridCell33>
                </Grid>
              </Subsection>
              {premiumCapacityBeforeSalaryExchange < 0 ? (
                <NotificationCard type="warning">
                  <FormattedMessage
                    {...companySalaryExchangeMessages.insufficientCapacityMessage}
                  />
                </NotificationCard>
              ) : premiumCapacityBeforeSalaryExchange - monthlySxPremium >=
                0 ? (
                <NotificationCard type="success">
                  <FormattedMessage
                    {...companySalaryExchangeMessages.salaryExchangeWithinRange}
                    values={{
                      premium: (
                        <FormattedCurrency
                          currency="SEK"
                          value={monthlySxPremium}
                        />
                      ),
                    }}
                  />
                </NotificationCard>
              ) : premiumCapacityBeforeSalaryExchange - monthlySxPremium < 0 ? (
                <NotificationCard type="warning">
                  <FormattedMessage
                    {...companySalaryExchangeMessages.tooHighSxRequested}
                    values={{
                      premium: (
                        <FormattedCurrency
                          currency="SEK"
                          value={monthlySxPremium}
                        />
                      ),
                    }}
                  />
                </NotificationCard>
              ) : null}

              <Subsection>
                <Chart
                  data={data}
                  hasSalaryExchangeCapacity={hasSalaryExchangeCapacity}
                />
              </Subsection>
              <Grid>
                <StyledGridCell50>
                  <NumberField
                    dense
                    min={0}
                    max={MAX_VALUE}
                    decimalScale={0}
                    name="yearlySalary"
                    label={
                      <FormattedMessage
                        {...companySalaryExchangeMessages.yearlySalary}
                      />
                    }
                    affix={formatMessage(suffixMessages.krPerYear)}
                    locale={locale}
                  />
                </StyledGridCell50>
                <StyledGridCell50>
                  <NumberField
                    dense
                    min={0}
                    max={MAX_VALUE}
                    decimalScale={0}
                    name="monthlySxPremium"
                    label={
                      <FormattedMessage
                        {...companySalaryExchangeMessages.salaryExchangePremium}
                      />
                    }
                    affix={formatMessage(suffixMessages.krPerMonth)}
                    locale={locale}
                  />
                </StyledGridCell50>
                <StyledGridCell50>
                  <NumberField
                    dense
                    min={0}
                    max={MAX_VALUE}
                    decimalScale={0}
                    name="monthlyPensionPremium"
                    label={
                      <FormattedMessage
                        {...companySalaryExchangeMessages.occupationalPensionPremiums}
                      />
                    }
                    affix={formatMessage(suffixMessages.krPerMonth)}
                    locale={locale}
                  />
                </StyledGridCell50>
                <StyledGridCell50>
                  <NumberField
                    dense
                    min={0}
                    max={MAX_VALUE}
                    decimalScale={0}
                    name="monthlySicknessInsurancePremium"
                    label={
                      <FormattedMessage
                        {...companySalaryExchangeMessages.insurances}
                      />
                    }
                    affix={formatMessage(suffixMessages.krPerMonth)}
                    locale={locale}
                  />
                </StyledGridCell50>
                <StyledGridCell50>
                  <NumberField
                    dense
                    min={0}
                    max={MAX_VALUE}
                    decimalScale={0}
                    name="onetimePensionDeposits"
                    label={
                      <FormattedMessage
                        {...companySalaryExchangeMessages.oneTimeDeposits}
                      />
                    }
                    affix={formatMessage(suffixMessages.kr)}
                    helperText={
                      <FormattedMessage
                        {...companySalaryExchangeMessages.oneTimeDepositsHelperText}
                      />
                    }
                    locale={locale}
                  />
                </StyledGridCell50>
              </Grid>
            </Section>
          </Form>
        );
      }}
    </Formik>
  );
};
