import { useMutation } from '@apollo/client';
import {
  deleteEmploymentActivityMutation,
  deleteEmploymentActivityMutationVariables,
  employmentActivityDetails as Activity,
} from 'app/apollo/graphql/types';
import { employmentMessages } from 'app/messages/employees';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';

import { DELETE_EMPLOYMENT_ACTIVITY_MUTATION } from '../graphql/mutations';
import { EMPLOYMENT_ACTIVITIES_QUERY } from '../graphql/queries';

interface Args {
  employmentId: string;
}

interface DeleteActivity {
  deleteActivity: (activity: Activity) => void;
  loading: boolean;
}

export const useDeleteActivity = ({ employmentId }: Args): DeleteActivity => {
  const { send } = useNotification();
  const intl = useIntl();
  const { confirm } = useConfirm();

  const [deleteEmployment, { loading }] = useMutation<
    deleteEmploymentActivityMutation,
    deleteEmploymentActivityMutationVariables
  >(DELETE_EMPLOYMENT_ACTIVITY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: EMPLOYMENT_ACTIVITIES_QUERY, variables: { id: employmentId } },
    ],
  });

  const deleteActivity = async ({ type, id }: Activity) => {
    const confirmed = await confirm({
      title: intl.formatMessage(
        employmentMessages.confirmDeleteEmploymentActivityTitle,
      ),
      description: intl.formatMessage(
        employmentMessages.confirmDeleteEmploymentActivity,
        { type },
      ),
    });

    if (!confirmed) {
      return;
    }

    try {
      await deleteEmployment({ variables: { input: { id } } });

      send({
        type: 'success',
        message: intl.formatMessage(
          employmentMessages.absenceRemovalSuccessful,
        ),
      });
    } catch (error) {
      send({
        type: 'error',
        message: intl.formatMessage(employmentMessages.absenceRemovalFailure),
      });
    }
  };

  return { deleteActivity, loading };
};
