import { CollapsibleSimple } from 'components/CollapsibleSimple';
import { FormattedMessage } from 'components/formats';
import React from 'react';

import { workCapabilityMessages } from '../../messages';

interface Props {
  infoText: string;
}

export const FcfwInfoCollapsible: React.FC<Props> = ({ infoText }) => (
  <CollapsibleSimple
    label={
      <FormattedMessage
        {...workCapabilityMessages.fullyCapableForWorkInfoTitle}
      />
    }
  >
    <div
      className="with-text-styles"
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: infoText,
      }}
    />
  </CollapsibleSimple>
);
