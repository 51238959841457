import { Body, ContentContainer, Section, SectionHeader } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { companySalaryExchangeMessages } from 'features/companies/company/salary-exchanges/messages';
import { SalaryExchangeRequests } from 'features/companies/company/salary-exchanges/requests';
import { BonusSalaryExchangeRequests } from 'features/companies/company/salary-exchanges/requests/bonus';
import { RequestsEmptyState } from 'features/companies/company/salary-exchanges/requests/components/EmptyState';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

const P = styled(Body)`
  white-space: pre-wrap;
`;

export const Requests: React.FC<RouteComponentProps> = () => (
  <Suspense fallback={<TopLoading />} batch emptyState={<RequestsEmptyState />}>
    {({ isEmpty, errors }) => (
      <ContentContainer>
        {errors && <GraphQlErrors errors={errors} />}
        {!isEmpty() && (
          <Section>
            <SectionHeader>
              <FormattedMessage {...commonMessages.requests} />
            </SectionHeader>
            <P>
              <FormattedMessage
                {...companySalaryExchangeMessages.salaryExchangeRequestsDesc}
              />
            </P>
          </Section>
        )}
        <SalaryExchangeRequests />
        <BonusSalaryExchangeRequests />
      </ContentContainer>
    )}
  </Suspense>
);
