import { insuranceMessages } from 'app/messages/insurance';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { useCurrentUser } from 'contexts/current-permissions';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Insurances } from 'features/insurances';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

export const InsurancesRoute: React.FC<RouteComponentProps> = ({ match }) => {
  const { permissions } = useCurrentUser();

  if (!hasBackstagePermission('backstageApp', 'insurancesTab', permissions)) {
    return <Redirect to="/" />;
  }

  return (
    <NavigationAnchor title={insuranceMessages.insurances} url="/insurances">
      <Switch>
        <Route exact path={match.url} component={Insurances} />
      </Switch>
    </NavigationAnchor>
  );
};
