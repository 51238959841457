import { CheckboxGroupField } from '@frontend/formik';
import { Subsection, SubsectionHeader } from '@frontend/ui';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';

import { benefitFormMessages } from '../../messages';
import { Agreements } from '../../types';
import { hasAgreementWithProvider } from '../../utils';
import { PENSION_PROVIDER_OPTIONS } from '../../utils/constants';

interface Props {
  agreements: Agreements;
  name: string;
}

export const PensionProviderSection: React.FC<Props> = ({
  agreements,
  name,
}) => {
  const providers = PENSION_PROVIDER_OPTIONS.filter(providerOption =>
    hasAgreementWithProvider(providerOption.value, agreements),
  );

  if (!providers.length) {
    return (
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...benefitFormMessages.providers} />
        </SubsectionHeader>
        <NotificationCard type="warning">
          <FormattedMessage {...benefitFormMessages.missingProviderAgreement} />
        </NotificationCard>
      </Subsection>
    );
  }

  return (
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...benefitFormMessages.providers} />
      </SubsectionHeader>
      <DescriptionWrapper>
        <FormattedMessage {...benefitFormMessages.providersDescription} />
      </DescriptionWrapper>
      <CheckboxGroupField name={name} options={providers} required />
    </Subsection>
  );
};
