import { Icon, Section, Table, Td, Th, Tr } from '@frontend/ui';
import { addCircle, edit } from '@frontend/ui/icons';
import { allBenefitPackagesQuery_companyBenefitPackages_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage as BenefitPackage } from 'app/apollo/graphql/types';
import { a11yMessages } from 'app/messages/a11y';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import { NoValue } from 'components/NoValue';
import { getBenefitDisplayName } from 'features/companies/company/benefits/utils/get-benefit-display-name';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import {
  CreateBenefitPackageModal,
  getCreateBenefitPackageLink,
} from '../../create';
import {
  getRenameBenefitPackageLink,
  RenameBenefitPackageModal,
} from '../../rename';

interface PackageTableRowProps {
  benefitPackage: BenefitPackage;
  editNameLink: RouteComponentProps['location'];
  onClick: () => void;
}

const PackageTableRow: React.FC<PackageTableRowProps> = ({
  benefitPackage,
  onClick,
  editNameLink,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const {
    name,
    benefits,
    memberships,
    showTotalCompensation,
    hasBenefitPortal,
  } = benefitPackage;
  const numberOfEmployees = memberships?.edges.length;

  const benefitNames = [
    ...benefits.edges.map(({ node: benefit }) =>
      getBenefitDisplayName(benefit, intl),
    ),
    ...(showTotalCompensation
      ? [formatMessage(totalCompensationMessages.totalCompensation)]
      : []),
    ...(hasBenefitPortal
      ? [formatMessage(commonBenefitMessages.benefitPortal)]
      : []),
  ];

  const benefitList =
    benefitNames.length > 0 ? benefitNames.join(', ') : <NoValue />;

  return (
    <Tr
      onClick={onClick}
      linked
      inlineMenu={
        <KebabMenu inClickableArea label={a11yMessages.menu} fixed>
          <MenuItem
            title={
              <FormattedMessage
                {...commonBenefitMessages.renameBenefitPackageLink}
              />
            }
            link={editNameLink}
            icon={<Icon icon={edit} decorative />}
          />
        </KebabMenu>
      }
    >
      <Th multiline>{name}</Th>
      <Td multiline>{benefitList}</Td>
      <Td>
        <FormattedNumber value={numberOfEmployees ?? 0} />
      </Td>
    </Tr>
  );
};

interface Props extends RouteComponentProps {
  benefitPackages: BenefitPackage[];
  companyId: string;
}

export const BenefitPackagesTable: React.FC<Props> = ({
  benefitPackages,
  history: { push },
  location,
  match: { url },
}) => {
  const handleClick = (id: string) => {
    push(`${url}/${id}`);
  };

  if (!benefitPackages.length) {
    return (
      <Section>
        <ChipsWrapper>
          <AssistChip
            text={
              <FormattedMessage
                {...commonBenefitMessages.createBenefitPackage}
              />
            }
            to={getCreateBenefitPackageLink({ location })}
            leadingIcon={addCircle}
          />
        </ChipsWrapper>
        <EmptyState
          title={
            <FormattedMessage
              {...commonBenefitMessages.noBenefitPackagesTitle}
            />
          }
        >
          <FormattedMessage {...commonBenefitMessages.noBenefitPackagesText} />
        </EmptyState>
        <CreateBenefitPackageModal />
      </Section>
    );
  }

  return (
    <Section>
      <ChipsWrapper>
        <AssistChip
          text={
            <FormattedMessage {...commonBenefitMessages.createBenefitPackage} />
          }
          to={getCreateBenefitPackageLink({ location })}
          leadingIcon={addCircle}
        />
      </ChipsWrapper>
      <Table size="xsmall">
        <colgroup>
          <col style={{ width: '30%' }} />
          <col style={{ width: '45%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '5%' }} />
        </colgroup>
        <thead>
          <Tr withInlineMenuTh>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.benefits} />
            </Th>
            <Th>
              <FormattedMessage {...commonBenefitMessages.numberOfEmployees} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {benefitPackages.map(p => (
            <PackageTableRow
              key={p.id}
              benefitPackage={p}
              onClick={() => handleClick(p.id)}
              editNameLink={getRenameBenefitPackageLink({
                benefitPackageId: p.id,
                location,
              })}
            />
          ))}
        </tbody>
      </Table>
      <CreateBenefitPackageModal />
      <RenameBenefitPackageModal />
    </Section>
  );
};
