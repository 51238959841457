/* eslint-disable */
import { fieldRichTextMessages as messages } from 'app/messages/common';
import { IntlShape } from 'components/formats';
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentState,
} from 'draft-js';
import _draftToHtml from 'draftjs-to-html';
import _htmlToDraft from 'html-to-draftjs';
import {
  draftToMarkdown as _draftToMarkdown,
  markdownToDraft as _markdownToDraft,
} from 'markdown-draft-js';

/* draftToMarkdown - Converts DraftJS format to markdown.
   - supports 'draft-to-markdown options' as an optional second argument,
   for mapping custom draftJS content to desired markdown syntax.
 */
export const draftToMarkdown = (
  state: EditorState,
  draftToMarkdownOptions?: { [key: string]: any },
): string =>
  _draftToMarkdown(
    convertToRaw(state.getCurrentContent()),
    draftToMarkdownOptions,
  );

/* markdownToDraft - Converts markdown to DraftJS format.
   - supports 'markdown to draft options' as an optional second argument,
     for mapping custom markdown syntax to desired draftJS content.
   - supports 'raw data modifer' as optional third argument to extend
     flexibility of draft js data format of parsed markdown.
  */
export const markdownToDraft = (
  markdown: string,
  markdownToDraftOptions?: { [key: string]: any },
  rawDataModifier?: (rawDraft: RawDraftContentState) => RawDraftContentState,
): EditorState => {
  const _rawDraft: RawDraftContentState = _markdownToDraft(
    markdown,
    markdownToDraftOptions,
  );

  const modifier =
    rawDataModifier ?? ((rawDraft: RawDraftContentState) => rawDraft);

  return EditorState.createWithContent(convertFromRaw(modifier(_rawDraft)));
};

export const htmlToDraft: (html: string) => EditorState = html => {
  if (html) {
    const content = _htmlToDraft(html);
    if (content) {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(content.contentBlocks),
      );
    }
  }
  return EditorState.createEmpty();
};

export const draftToHtml: (state: any) => string = state =>
  state instanceof EditorState
    ? _draftToHtml(convertToRaw(state.getCurrentContent()))
    : '';

export const localization = (intl: IntlShape) => ({
  translations: {
    'generic.add': intl.formatMessage(messages.add),
    'generic.cancel': intl.formatMessage(messages.cancel),
    'components.controls.blocktype.h1': intl.formatMessage(messages.h1),
    'components.controls.blocktype.h2': intl.formatMessage(messages.h2),
    'components.controls.blocktype.h3': intl.formatMessage(messages.h3),
    'components.controls.blocktype.h5': intl.formatMessage(messages.h5),
    'components.controls.blocktype.blockquote': intl.formatMessage(
      messages.blockquote,
    ),
    'components.controls.blocktype.code': intl.formatMessage(messages.code),
    'components.controls.blocktype.blocktype': intl.formatMessage(
      messages.blocktype,
    ),
    'components.controls.blocktype.normal': intl.formatMessage(messages.normal),
    'components.controls.inline.bold': intl.formatMessage(messages.bold),
    'components.controls.inline.italic': intl.formatMessage(messages.italic),
    'components.controls.inline.underline': intl.formatMessage(
      messages.underline,
    ),
    'components.controls.inline.superscript': intl.formatMessage(
      messages.superscript,
    ),
    'components.controls.inline.subscript': intl.formatMessage(
      messages.subscript,
    ),
    'components.controls.link.linkTitle': intl.formatMessage(
      messages.linkTitle,
    ),
    'components.controls.link.linkTarget': intl.formatMessage(
      messages.linkTarget,
    ),
    'components.controls.link.linkTargetOption': intl.formatMessage(
      messages.linkTargetOption,
    ),
    'components.controls.link.link': intl.formatMessage(messages.link),
    'components.controls.link.unlink': intl.formatMessage(messages.unlink),
    'components.controls.list.unordered': intl.formatMessage(
      messages.unordered,
    ),
    'components.controls.list.ordered': intl.formatMessage(messages.ordered),
    'components.controls.embedded.embedded': intl.formatMessage(
      messages.embedIframe,
    ),
    'components.controls.embedded.link': intl.formatMessage(messages.link),
    'components.controls.embedded.height': intl.formatMessage(
      messages.embedHeight,
    ),
    'components.controls.embedded.width': intl.formatMessage(
      messages.embedWidth,
    ),
    'components.controls.history.undo': intl.formatMessage(messages.undo),
    'components.controls.history.redo': intl.formatMessage(messages.redo),
    'components.controls.remove.remove': intl.formatMessage(
      messages.removeFormatting,
    ),
  },
});
