import {
  compilationNotificationsQuery,
  compilationNotificationsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { smeCompilationMessages } from 'features/sme/messages/sme';
import React from 'react';
import { useParams } from 'react-router';

import { COMPILATION_NOTIFICATIONS_QUERY } from './graphql/queries';
import { List } from './List';

export const CompilationNotifications: React.FC = () => {
  const params = useParams<ProposalMatchParams>();

  const { data } = useQuery<
    compilationNotificationsQuery,
    compilationNotificationsQueryVariables
  >(COMPILATION_NOTIFICATIONS_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: {
      id: params.proposalId,
    },
  });

  const errors = data?.proposal?.validity.validCompilation.errors.slice() ?? [];
  const warnings =
    data?.proposal?.validity.validCompilation.warnings.slice() ?? [];
  const info = data?.proposal?.validity.validCompilation.info.slice() ?? [];

  if (!errors.length && !warnings.length && !info.length) {
    return null;
  }

  return (
    <>
      {!!info.length && (
        <NotificationCard
          type="info"
          title={
            <FormattedMessage
              {...smeCompilationMessages.compilationInfoNotificationTitle}
            />
          }
        >
          <List items={info} />
        </NotificationCard>
      )}
      {!!warnings.length && (
        <NotificationCard
          type="warning"
          title={
            <FormattedMessage
              {...smeCompilationMessages.compilationWarningNotificationTitle}
            />
          }
        >
          <List items={warnings} />
        </NotificationCard>
      )}
      {!!errors.length && (
        <NotificationCard
          type="error"
          title={
            <FormattedMessage
              {...smeCompilationMessages.compilationErrorNotificationTitle}
            />
          }
        >
          <List items={errors} />
        </NotificationCard>
      )}
    </>
  );
};
