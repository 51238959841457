import gql from 'graphql-tag';

export const CREATE_BENEFITS_MUTATION = gql`
  mutation createBenefitsMutation($input: FlexCreateBenefitsInput!) {
    createBenefits(input: $input) {
      benefits {
        id
      }
    }
  }
`;
