import {
  smeCompanyBenefitCostsQuery,
  smeCompanyBenefitCostsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import React from 'react';
import { useParams } from 'react-router';

import { CostsTable } from './CostsTable';
import { SME_COMPANY_BENEFIT_COSTS_QUERY } from './graphql/queries';

export const BenefitCosts = () => {
  const { proposalId } = useParams<ProposalMatchParams>();

  const { data } = useQuery<
    smeCompanyBenefitCostsQuery,
    smeCompanyBenefitCostsQueryVariables
  >(SME_COMPANY_BENEFIT_COSTS_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: { id: proposalId },
  });

  const costs = data?.proposal?.cost;

  return <CostsTable costs={costs} />;
};
