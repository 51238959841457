import { Formik } from '@frontend/formik';
import { ModalHeader } from '@frontend/ui';
import { add } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import { Props as AssistChipProps } from 'components/AssistChip';
import { FormattedMessage, useIntl } from 'components/formats';
import { Modal } from 'components/Modal';
import { smeCreateProposalMessages } from 'features/sme/messages/sme';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';

import {
  CreateProposalForm,
  FormValues,
  initialValues,
  validationSchema,
} from './form';
import { useSubmit } from './utils/use-submit';

export const CreateProposalModal: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const intl = useIntl();

  const { 'create-proposal': createProposal } = qs.parse(location.search);

  const onRequestClose = () => {
    stripSearchParams(history, location, ['create-proposal']);
  };

  const { submit, submissionError } = useSubmit();

  return (
    <Modal isOpen={createProposal} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage {...smeCreateProposalMessages.create} />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        <CreateProposalForm
          onRequestClose={onRequestClose}
          submissionError={submissionError}
        />
      </Formik>
    </Modal>
  );
};

export const getCreateProposalAction = (
  location: RouteComponentProps['location'],
): AssistChipProps => ({
  to: {
    ...location,
    search: qs.stringify({ 'create-proposal': true }),
  },
  text: <FormattedMessage {...smeCreateProposalMessages.create} />,
  leadingIcon: add,
});
