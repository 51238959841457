import { ContentContainer } from '@frontend/ui';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { SalaryExchangeEventsTable } from 'features/companies/company/salary-exchanges/events';
import { BonusSalaryExchangeEventsTable } from 'features/companies/company/salary-exchanges/events/bonus';
import { companySalaryExchangeMessages } from 'features/companies/company/salary-exchanges/messages';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const Events: React.FC = () => (
  <Suspense
    batch
    fallback={<TopLoading />}
    emptyState={
      <EmptyState
        title={
          <FormattedMessage
            {...companySalaryExchangeMessages.noSalaryExchangeRequestItems}
          />
        }
      >
        <FormattedMessage
          {...companySalaryExchangeMessages.noSalaryExchangeRequestItemsText}
        />
      </EmptyState>
    }
  >
    {({ errors }) => (
      <ContentContainer>
        {errors && <GraphQlErrors errors={errors} />}
        <SalaryExchangeEventsTable />
        <BonusSalaryExchangeEventsTable />
      </ContentContainer>
    )}
  </Suspense>
);
