import gql from 'graphql-tag';

export const TERMINATE_SALARY_EXCHANGES_TABLE_QUERY = gql`
  query terminateSalaryExchangesTableQuery(
    $companyId: ID!
    $statuses: [FlexSalaryExchangeStatus!]
  ) {
    salaryExchanges(companyId: $companyId, statuses: $statuses) {
      edges {
        node {
          id
          companyId
          userAccountId
          effectiveDate
          deductionValue
          premium
          provider
          status
          violated
          membership {
            id
            givenName
            lastName
          }
          remuneration {
            unitCode
            currency
            value: price
          }
        }
      }
    }
  }
`;
