import { NumberField, SelectField } from '@frontend/formik';
import { Grid, GridCell, Subsection, SubsectionHeader } from '@frontend/ui';
import { alphabeticallyAscending } from '@frontend/utils';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';
import * as Yup from 'yup';

import { benefitFormMessages } from '../../messages';
import { RemunerationTypes } from '../../types';

export interface FormValues {
  payrollElementCode: string;
  payrollElementMultiplier: string;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    payrollElementCode: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    payrollElementMultiplier: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

interface Props {
  remunerationTypes: RemunerationTypes;
}

export const QualifyingIncomeSection: React.FC<Props> = ({
  remunerationTypes,
}) => {
  const { locale } = useIntlContext();

  const options = remunerationTypes.edges
    .map(({ node }) => ({
      label: `${node.id} ${node.name ?? ''}`,
      value: node.id,
    }))
    .sort((a, b) => alphabeticallyAscending(a.label, b.label));

  return (
    <Subsection>
      <TextGrid>
        <SubsectionHeader>
          <FormattedMessage {...benefitFormMessages.benefitQualifyingIncome} />
        </SubsectionHeader>
        <DescriptionWrapper>
          <FormattedMessage
            {...benefitFormMessages.benefitQualifyingIncomeDescription}
          />
        </DescriptionWrapper>
      </TextGrid>
      <Grid>
        <GridCell span={4}>
          <SelectField
            dense
            label={
              <FormattedMessage {...benefitFormMessages.payrollElementCode} />
            }
            name="payrollElementCode"
            options={options}
            required
          />
        </GridCell>
        <GridCell span={4}>
          <NumberField
            decimalScale={2}
            dense
            gridMargin
            label={
              <FormattedMessage
                {...benefitFormMessages.payrollElementMultiplier}
              />
            }
            locale={locale}
            name="payrollElementMultiplier"
            required
          />
        </GridCell>
      </Grid>
    </Subsection>
  );
};
