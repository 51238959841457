import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

import { totalCompensationConfigurationDetails } from './fragments';

export const TOTAL_COMPENSATION_CONFIGURATION_QUERY = gql`
  query totalCompensationConfigurationQuery($id: ID!) {
    company(id: $id) {
      id
      benefitPackages {
        edges {
          node {
            id
            name
            showTotalCompensation
          }
        }
      }
      name
      remunerationTypes {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            id
            name
          }
        }
      }
      totalCompensationConfiguration {
        ...totalCompensationConfigurationDetails
      }
    }
  }
  ${pageInfoDetails}
  ${totalCompensationConfigurationDetails}
`;
