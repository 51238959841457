import gql from 'graphql-tag';

export const CHANGE_PROPOSAL_NAME_QUERY = gql`
  query changeProposalNameQuery($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      name
    }
  }
`;
