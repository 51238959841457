import { ApolloError, useMutation } from '@apollo/client';
import {
  updateCompanyDetailsMutation,
  updateCompanyDetailsMutationVariables,
  UpdateCompanyInput,
} from 'app/apollo/graphql/types';
import { formSubmitMessages } from 'app/messages/form';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useIntl } from 'components/formats';
import { UPDATE_COMPANY_DETAILS_MUTATION } from 'features/companies/graphql/mutations';
import { useNotification } from 'features/notifications';
import { RouteComponentProps } from 'react-router';

import { FormValues } from '..';

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = ({
  history,
  match: {
    params: { companyId },
  },
}: RouteComponentProps<CompanyMatchParams>): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [updateCompanyDetails, { error }] = useMutation<
    updateCompanyDetailsMutation,
    updateCompanyDetailsMutationVariables
  >(UPDATE_COMPANY_DETAILS_MUTATION);

  const submit = async (values: FormValues) => {
    try {
      const input: UpdateCompanyInput = {
        ...values,
        id: companyId,
        logo: undefined,
      };

      const res = await updateCompanyDetails({
        variables: {
          input,
        },
      });

      if (!res?.data?.updateCompany?.company) {
        throw new Error();
      }

      send({
        message: formatMessage(formSubmitMessages.updateCompanySuccess, {
          companyName: values.displayName,
        }),
        type: 'success',
      });

      history.push('/admin/company');
    } catch {
      // Do nothing
    }
  };

  return { submit, error };
};
