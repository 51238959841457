import {
  Form,
  Formik,
  RadioGroupField,
  SelectField,
  TextField,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import {
  duplicateProposalQuery,
  duplicateProposalQueryVariables,
  ProposalAdviceOption,
  ProposalCompanySize,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import {
  smeCompanyProposalsMessages,
  smeCreateProposalMessages,
  smeProposalAdviceOptionMessages,
  smeProposalCompanySizeMessages,
} from 'features/sme/messages/sme';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { PROPOSAL_ADVICE_OPTIONS } from '../utils/constants';
import { DUPLICATE_PROPOSAL_QUERY } from './graphql/queries';
import { DUPLICATE_PROPOAL_QUERY_KEY } from './utils/use-duplicate-proposal';
import { useSubmit } from './utils/use-submit';
import { validationSchema } from './utils/validation-schema';

export interface FormValues {
  adviceWaived: ProposalAdviceOption | '';
  companySize: ProposalCompanySize | '';
  name: string;
}

export const DuplicateProposalModal: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const onRequestClose = () => {
    stripSearchParams(history, location, [DUPLICATE_PROPOAL_QUERY_KEY]);
  };

  const { submit, submissionError } = useSubmit({ onRequestClose });
  const intl = useIntl();
  const { formatMessage } = intl;

  const { [DUPLICATE_PROPOAL_QUERY_KEY]: proposalId } = qs.parse(
    location.search,
  );

  const { data, loading, error } = useQuery<
    duplicateProposalQuery,
    duplicateProposalQueryVariables
  >(DUPLICATE_PROPOSAL_QUERY, {
    errorPolicy: 'all',
    variables: { proposalId },
    skip: !proposalId,
  });

  if (!proposalId) {
    return null;
  }

  if (loading) {
    return <TopLoading />;
  }

  const proposal = data?.proposal;
  const adviceWaived = proposal?.adviceWaived ?? '';
  const companySize = proposal?.companySize ?? '';
  const name = proposal?.name ?? '';

  const initialValues: FormValues = {
    adviceWaived,
    companySize,
    name,
  };

  return (
    <Modal isOpen={proposalId} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage
          {...smeCompanyProposalsMessages.duplicatePropoalName}
          values={{ name }}
        />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <ModalBody>
              {error && <GraphQlError inModal error={error} />}
              <TextField
                dense
                name="name"
                label={
                  <FormattedMessage
                    {...smeCompanyProposalsMessages.proposalName}
                  />
                }
                required
              />
              <SelectField
                dense
                name="companySize"
                label={
                  <FormattedMessage {...smeCreateProposalMessages.nInsured} />
                }
                required
                fixed
                options={[
                  {
                    value: ProposalCompanySize.LT_10,
                    label: formatMessage({
                      select: ProposalCompanySize.LT_10,
                      messages: smeProposalCompanySizeMessages,
                    }),
                  },
                  {
                    value: ProposalCompanySize.LT_50,
                    label: formatMessage({
                      select: ProposalCompanySize.LT_50,
                      messages: smeProposalCompanySizeMessages,
                    }),
                  },
                  {
                    value: ProposalCompanySize.GTE_50,
                    label: formatMessage({
                      select: ProposalCompanySize.GTE_50,
                      messages: smeProposalCompanySizeMessages,
                    }),
                  },
                  {
                    value: ProposalCompanySize.GTE_250,
                    label: formatMessage({
                      select: ProposalCompanySize.GTE_250,
                      messages: smeProposalCompanySizeMessages,
                    }),
                  },
                ]}
              />
              <RadioGroupField
                name="adviceWaived"
                options={PROPOSAL_ADVICE_OPTIONS.map(value => ({
                  label: formatMessage({
                    select: value,
                    messages: smeProposalAdviceOptionMessages,
                  }),
                  value,
                }))}
              />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  text
                  type="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  <FormattedMessage
                    {...smeCompanyProposalsMessages.duplicateProposal}
                  />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
