import { toMoney } from '@frontend/utils';
import { MembershipRemunerationItemInput } from 'app/apollo/graphql/types';

interface RemunerationTypeDetails {
  currency: string | null;
  id: string;
  name: string | null;
  unitCode: string | null;
}

export const fromFormData =
  (
    options: readonly RemunerationTypeDetails[],
    inputs: MembershipRemunerationItemInput[],
  ) =>
  ({ itemTypeId, value, from }: MembershipRemunerationItemInput) => ({
    type: {
      id: itemTypeId,
      name: options.find(r => r.id === itemTypeId)?.name ?? '',
    },
    currency: options.find(r => r.id === itemTypeId)?.currency ?? '',
    unitCode: options.find(r => r.id === itemTypeId)?.unitCode ?? '',
    value: toMoney(value) ?? '',
    from,
    to:
      inputs
        .filter(input => input.itemTypeId === itemTypeId)
        .reduce(
          (
            firstDateAfter: MembershipRemunerationItemInput | undefined,
            current,
          ) => {
            if (new Date(current.from).getTime() <= new Date(from).getTime()) {
              return firstDateAfter;
            }
            if (!firstDateAfter) {
              return current;
            }
            return new Date(current.from).getTime() <
              new Date(firstDateAfter.from).getTime()
              ? current
              : firstDateAfter;
          },
          undefined,
        )?.from ?? null,
  });
