import { SortingDirection, SortingFields } from 'app/apollo/graphql/types';

interface Params {
  order?: SortingDirection;
  orderBy?: SortingFields;
}

export const getSortingQueryArgs = ({ order, orderBy }: Params) => {
  if (!orderBy || !order) {
    return undefined;
  }

  return {
    field: orderBy,
    direction: order,
  };
};
