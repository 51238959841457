import {
  CheckboxGroup,
  RadioGroup,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { ProposalSummaryFormat } from 'app/apollo/graphql/types';
import { FormattedMessage, useIntl } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import { TemplateSelect } from 'features/sme/companies/components/TemplateSelect';
import {
  smeNeedsMessages,
  smeSummaryMessages,
} from 'features/sme/messages/sme';
import React, { useId } from 'react';

import { useProposalReadonly } from '../../utils/use-proposal-readonly';
import { AutosaveStatus, useAutosave } from '../contexts/autosave';
import { getCheckOptions, getFormValues, getOnChangeHandlers } from './utils';

export const Summary = () => {
  const checksId = useId();
  const formatId = useId();

  const { loading, data, onChange, status } = useAutosave();
  const intl = useIntl();
  const { formatMessage } = intl;
  const isReadonly = useProposalReadonly();

  if (loading) {
    return <TopLoading />;
  }

  const formValues = getFormValues(data);
  const _onChange = getOnChangeHandlers(onChange);

  const checkedCheckValues = Object.keys(formValues.checks).filter(
    key => formValues.checks[key],
  );

  const checkOptions = getCheckOptions(intl);

  const disabled = isReadonly || status === AutosaveStatus.RECONNECTING;

  return (
    <>
      <Subsection>
        <TextGrid>
          <TemplateSelect
            disabled={disabled}
            label={formatMessage(smeNeedsMessages.situationTemplateSelectLabel)}
            options={[
              {
                label: formatMessage(
                  smeSummaryMessages.ownerWithNewlyStartedCompany,
                ),
                value: formatMessage(
                  smeSummaryMessages.ownerWithNewlyStartedCompanyPlaceholder,
                ),
              },
              {
                label: formatMessage(
                  smeSummaryMessages.ownerWithExistingInsuranceSolution,
                ),
                value: formatMessage(
                  smeSummaryMessages.ownerWithExistingInsuranceSolutionPlaceholder,
                ),
              },
              {
                label: formatMessage(
                  smeSummaryMessages.companiesWithoutCollectiveAgreement,
                ),
                value: formatMessage(
                  smeSummaryMessages.companiesWithoutCollectiveAgreementPlaceholder,
                ),
              },
              {
                label: formatMessage(smeSummaryMessages.existingSolutionInNLP),
                value: formatMessage(
                  smeSummaryMessages.existingSolutionInNLPPlaceholder,
                ),
              },
              {
                label: formatMessage(
                  smeSummaryMessages.ownerWithExistingInsuranceSolutionEnglish,
                ),
                value: formatMessage(
                  smeSummaryMessages.ownerWithExistingInsuranceSolutionEnglishPlaceholder,
                ),
              },
            ]}
            placeholder={formatMessage(
              smeNeedsMessages.situationTemplateEditorPlaceholder,
            )}
            setValue={_onChange.text}
            value={formValues.text}
            characterLimit={5200}
          />
        </TextGrid>
      </Subsection>
      <Subsection>
        <TextGrid>
          <SubsectionHeader id={checksId}>
            <FormattedMessage {...smeSummaryMessages.checksTitle} />
          </SubsectionHeader>
          <CheckboxGroup
            disabled={disabled}
            options={checkOptions}
            checked={checkedCheckValues}
            onChange={option => {
              _onChange.checks(
                option,
                !checkedCheckValues.includes(option.value),
              );
            }}
            withoutMargin
            aria-labelledby={checksId}
          />
        </TextGrid>
      </Subsection>
      <Subsection>
        <TextGrid>
          <SubsectionHeader id={formatId}>
            <FormattedMessage {...smeSummaryMessages.format} />
          </SubsectionHeader>
          <RadioGroup
            disabled={disabled}
            checked={formValues.format}
            onChange={_onChange.format}
            options={[
              {
                label: formatMessage(smeSummaryMessages.digitalFormat),
                value: ProposalSummaryFormat.DIGITAL,
              },
              {
                label: formatMessage(smeSummaryMessages.paperFormat),
                value: ProposalSummaryFormat.PAPER,
              },
            ]}
            withoutMargin
            aria-labelledby={formatId}
          />
        </TextGrid>
      </Subsection>
    </>
  );
};
