import { CheckboxGroup, TextField } from '@frontend/ui';
import {
  FlexChangeStatus,
  FlexChangeType,
  FlexInsuranceAction,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { FilterSection } from 'features/filter-sheet/components/FilterSection';
import { SelectionFilter } from 'features/filter-sheet/components/SelectionFilter';
import { useDebouncedFilters } from 'features/filter-sheet/utils/use-debounced-filters';
import React, { useId } from 'react';

import {
  changeActionMessages,
  changeStatusMessages,
  changeTypeMessages,
} from '../messages';

export interface Filters {
  action: string[];
  effectiveFrom: string;
  effectiveUntil: string;
  status: string[];
  type: string[];
}

export const Filters: React.FC = () => {
  const insuranceId = useId();
  const changeId = useId();
  const statusId = useId();
  const { formatMessage } = useIntl();
  const { clearFilters, filters, setFilterValue } =
    useDebouncedFilters<Filters>();

  const selectedActions = filters.action ?? [];

  const actionOptions = Object.keys(FlexInsuranceAction).map(key => ({
    label: formatMessage({ messages: changeActionMessages, select: key }),
    value: key,
  }));

  const selectedStatuses = filters.status ?? [];

  const statusOptions = Object.keys(FlexChangeStatus).map(key => ({
    label: formatMessage({ messages: changeStatusMessages, select: key }),
    value: key,
  }));

  const selectedTypes = filters.type ?? [];

  const typeOptions = Object.keys(FlexChangeType)
    // Remove this filter once SSEK migration is complete
    .filter(
      key =>
        key !== 'PENSION_NORDNET_SSEK' &&
        key !== 'SINGLE_PREMIUM_PENSION_NORDNET_SSEK',
    )
    .map(key => ({
      label: formatMessage({ messages: changeTypeMessages, select: key }),
      value: key,
    }));
  const selectedFilters = Object.keys(filters);

  return (
    <ChipsWrapper>
      <SelectionFilter
        clearFilters={clearFilters}
        nSelected={selectedFilters.length}
      >
        <FilterSection
          header={<FormattedMessage {...commonMessages.insurance} />}
          id={insuranceId}
        >
          <CheckboxGroup
            checked={selectedTypes}
            onChange={option => {
              const value = selectedTypes.includes(option.value)
                ? selectedTypes.filter(status => status !== option.value)
                : [option.value, ...selectedTypes];

              setFilterValue(value, 'type');
            }}
            options={typeOptions}
            aria-labelledby={insuranceId}
          />
        </FilterSection>
        <FilterSection
          header={<FormattedMessage {...commonMessages.change} />}
          id={changeId}
        >
          <CheckboxGroup
            checked={selectedActions}
            onChange={option => {
              const value = selectedActions.includes(option.value)
                ? selectedActions.filter(status => status !== option.value)
                : [option.value, ...selectedActions];

              setFilterValue(value, 'action');
            }}
            options={actionOptions}
            aria-labelledby={changeId}
          />
        </FilterSection>
        <FilterSection
          header={<FormattedMessage {...commonMessages.effectiveDate} />}
        >
          <TextField
            dense
            label={<FormattedMessage {...commonMessages.from} />}
            persistentLabel
            type="date"
            value={filters.effectiveFrom ?? ''}
            onChange={event =>
              setFilterValue(event.target.value, 'effectiveFrom')
            }
          />
          <TextField
            dense
            label={<FormattedMessage {...commonMessages.to} />}
            persistentLabel
            type="date"
            value={filters.effectiveUntil ?? ''}
            onChange={event =>
              setFilterValue(event.target.value, 'effectiveUntil')
            }
          />
        </FilterSection>
        <FilterSection
          header={<FormattedMessage {...commonMessages.status} />}
          id={statusId}
        >
          <CheckboxGroup
            checked={selectedStatuses}
            onChange={option => {
              const value = selectedStatuses.includes(option.value)
                ? selectedStatuses.filter(status => status !== option.value)
                : [option.value, ...selectedStatuses];

              setFilterValue(value, 'status');
            }}
            options={statusOptions}
            aria-labelledby={statusId}
          />
        </FilterSection>
      </SelectionFilter>
    </ChipsWrapper>
  );
};
