import { ApolloError, useMutation } from '@apollo/client';
import {
  editBenefitPackageMutation,
  editBenefitPackageMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitPackagesMessages } from 'features/sme/messages/sme';
import { useParams } from 'react-router';

import { clearProposalCache } from '../../../../utils/clear-proposal-cache';
import { FormValues } from '..';
import { EDIT_BENEFIT_PACKAGE_MUTATION } from '../graphql/mutations';

interface Props {
  onRequestClose: () => void;
}

interface Submit {
  submit: (values?: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = ({ onRequestClose }: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const params = useParams<MatchParams>();

  const [editBenefitPackage, { error: submissionError }] = useMutation<
    editBenefitPackageMutation,
    editBenefitPackageMutationVariables
  >(EDIT_BENEFIT_PACKAGE_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals', 'validity'],
    }),
  });

  const submit = async (values: FormValues) => {
    try {
      const res = await editBenefitPackage({
        variables: {
          input: {
            id: values.benefitPackageId,
            name: values.name,
            proposalId: values.proposalId,
          },
        },
      });
      const proposalId = res.data?.updateProposalBenefitPackage?.id;
      if (!proposalId) {
        return;
      }
      send({
        message: formatMessage(smeBenefitPackagesMessages.nameEdited),
        type: 'success',
      });
      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    submissionError,
  };
};
