import {
  Chip,
  ChipSet,
  Icon,
  ListItem,
  MenuSurface,
  MenuSurfaceAnchor,
  TextField,
} from '@frontend/ui';
import { clear } from '@frontend/ui/icons';
import Downshift from 'downshift';
import React from 'react';
import styled from 'styled-components';

export interface FieldChipsOption {
  label: string;
  value: string;
}

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

export interface Props {
  label: React.ReactNode;
  onChange: (value: Record<string, string>) => void;
  options: FieldChipsOption[];
  value: Record<string, string>;
  dense?: boolean;
  disabled?: boolean;
  filterOptionsBySearchFieldValue?: boolean;
  onSearchFieldValueChange?: (value: string) => void;
  persistCasing?: boolean;
}

export const FieldChips: React.FC<Props> = ({
  dense,
  label,
  options: _options,
  value,
  onChange,
  onSearchFieldValueChange,
  persistCasing,
  filterOptionsBySearchFieldValue = true,
  disabled = false,
}) => (
  <>
    <Downshift<FieldChipsOption>
      onChange={(selection, { clearSelection }) => {
        if (selection) {
          onChange({
            ...value,
            [selection.value]: selection.label,
          });
        }
        clearSelection();
      }}
      itemToString={item => (item ? item.value : '')}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
        openMenu,
      }) => {
        const options = filterOptionsBySearchFieldValue
          ? _options.filter(
              option =>
                !inputValue ||
                option.label.toLowerCase().includes(inputValue.toLowerCase()),
            )
          : _options;

        return (
          <Wrapper
            {...getRootProps({ refKey: 'ref' }, { suppressRefError: true })}
          >
            <TextField
              withoutMargin
              dense={dense}
              label={label}
              onClick={() => {
                openMenu();
              }}
              {...getInputProps({
                onChange:
                  onSearchFieldValueChange &&
                  (event => onSearchFieldValueChange(event.target.value)),
                onBlur:
                  onSearchFieldValueChange &&
                  (() => onSearchFieldValueChange('')),
                style: undefined,
              })}
              disabled={disabled}
            />
            <MenuSurfaceAnchor>
              <MenuSurface fullwidth open={isOpen} {...getMenuProps()}>
                {isOpen
                  ? options.map((option, index) => (
                      <ListItem
                        activated={highlightedIndex === index}
                        selected={selectedItem === option}
                        {...getItemProps({
                          key: option.value,
                          index,
                          item: option,
                        })}
                      >
                        {option.label}
                      </ListItem>
                    ))
                  : null}
              </MenuSurface>
            </MenuSurfaceAnchor>
          </Wrapper>
        );
      }}
    </Downshift>
    {Object.keys(value).length > 0 && (
      <ChipSet>
        {Object.entries(value).map(([id, _label]) => (
          <Chip
            key={id}
            persistCasing={persistCasing}
            trailingIcon={<Icon icon={clear} decorative />}
            onClick={() => {
              onChange(
                Object.fromEntries(
                  Object.entries(value).filter(([key]) => key !== id),
                ),
              );
            }}
            clickable
            text={typeof _label === 'string' ? _label : ''}
          />
        ))}
      </ChipSet>
    )}
  </>
);
