import { Grid, WizardSection } from '@frontend/ui';
import {
  ImportStatus,
  importSummaryCardsQuery,
  importSummaryCardsQueryVariables,
} from 'app/apollo/graphql/types';
import { useQuery } from 'app/utils/use-query';
import React from 'react';

import { CardType, ImportSummaryCard } from './Card';
import { IMPORT_SUMMARY_CARDS_QUERY } from './graphql/queries';

interface Props {
  importId: string;
}

export const ImportSummaryCards: React.FC<Props> = ({ importId }) => {
  const { data } = useQuery<
    importSummaryCardsQuery,
    importSummaryCardsQueryVariables
  >(IMPORT_SUMMARY_CARDS_QUERY, {
    suspend: true,
    variables: {
      id: importId,
    },
    errorPolicy: 'all',
  });

  if (
    !data?.import?.changesSummary ||
    data.import.status === ImportStatus.NO_CHANGES
  ) {
    return null;
  }

  const { changesSummary } = data.import;

  return (
    <WizardSection>
      <Grid>
        <ImportSummaryCard
          type={CardType.EMPLOYEE_STATUS}
          data={[
            changesSummary.newMemberships,
            changesSummary.terminatedEmployments,
          ]}
        />
        <ImportSummaryCard
          type={CardType.SALARY_UPDATES}
          data={[changesSummary.salaryChanges]}
        />
        <ImportSummaryCard
          type={CardType.EMPLOYMENT_ACTIVITY}
          data={[changesSummary.newActivities]}
        />
        <ImportSummaryCard
          type={CardType.UNCHANGED}
          data={[changesSummary.unchangedRows]}
        />
      </Grid>
    </WizardSection>
  );
};
