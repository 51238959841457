import { EAGruppAgreementType } from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { IntlShape } from 'components/formats';
import {
  isValidPersonalIdentityNumber,
  isValidRegistrationNumber,
  validateEmail,
  validateNaturalPersonIdentifier,
} from 'validations';
import * as Yup from 'yup';

const yupPercent = (intl: IntlShape) =>
  Yup.number()
    .nullable()
    .min(0, intl.formatMessage(validationMessages.invalidPercent))
    .max(1, intl.formatMessage(validationMessages.invalidPercent));

const agreementDateLimit = (): string => {
  const now = new Date();
  const [Y, M] = now.toISOString().split('T')[0].split('-');
  const windowStart = new Date(Date.UTC(Number(Y), Number(M) - 4, 1));
  // The date constructor above sets the date to 00:00:00, but because of our timezone toISOString adjusts
  // it to 23:00:00 the day before. Get rid of the timezone offset and print!
  return new Date(
    windowStart.valueOf() - windowStart.getTimezoneOffset() * 60000,
  )
    .toISOString()
    .substring(0, 10);
};

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    clearingCode: Yup.object()
      .nullable()
      .when('createNordea', {
        is: true,
        then: schema =>
          schema.required(
            intl.formatMessage(validationMessages.mandatoryField),
          ),
      }),
    salesRep: Yup.string().when('createNordea', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    accountClosureMonth: Yup.string().when('createNordea', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    createEaGrupp: Yup.boolean().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    createEaTjp: Yup.boolean().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    createNordea: Yup.boolean().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    eaExtendedFcfw: Yup.boolean().when(['createEaGrupp', 'createEaTjp'], {
      is: (grupp, tjp) => grupp || tjp,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    eaGruppAgreementType: Yup.string()
      .oneOf(Object.values(EAGruppAgreementType))
      .nullable()
      .when(['createEaGrupp', 'createEaTjp'], {
        is: (grupp, tjp) => grupp || tjp,
        then: schema =>
          schema.required(
            intl.formatMessage(validationMessages.mandatoryField),
          ),
      }),
    eaStartDate: Yup.string()
      .nullable()
      .when(['createEaGrupp', 'createEaTjp'], {
        is: (grupp, tjp) => grupp || tjp,
        then: schema =>
          schema
            .required(intl.formatMessage(validationMessages.mandatoryField))
            .test(
              'valid agreement date',
              intl.formatMessage(validationMessages.agreementStart, {
                date: agreementDateLimit(),
              }),
              value => !value || value >= agreementDateLimit(),
            ),
      }),
    eaCompanySize: Yup.string()
      .nullable()
      .when(['createEaGrupp', 'createEaTjp'], {
        is: (grupp, tjp) => grupp || tjp,
        then: schema =>
          schema.required(
            intl.formatMessage(validationMessages.mandatoryField),
          ),
      }),
    isOwner: Yup.boolean().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    startDate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    registrationNumber: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid registration number',
        intl.formatMessage(validationMessages.invalidRegistrationNumber),
        value =>
          !value ||
          isValidRegistrationNumber(value) ||
          isValidPersonalIdentityNumber(value),
      ),
    firstName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    lastName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    email: Yup.string().test(
      'valid email',
      intl.formatMessage(validationMessages.isValidEmail),
      value => (value ? !validateEmail(value) : true),
    ),
    pid: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid first nid',
        intl.formatMessage(validationMessages.isValidFirstNid),
        value => !value || validateNaturalPersonIdentifier(value),
      ),
    nodeAdminPid: Yup.string().test(
      'valid first nid',
      intl.formatMessage(validationMessages.isValidFirstNid),
      value => !value || validateNaturalPersonIdentifier(value),
    ),
    nordeaStartDate: Yup.string()
      .nullable()
      .when('createNordea', {
        is: true,
        then: schema =>
          schema
            .required(intl.formatMessage(validationMessages.mandatoryField))
            .test(
              'valid agreement date',
              intl.formatMessage(validationMessages.agreementStart, {
                date: agreementDateLimit(),
              }),
              value => !value || value >= agreementDateLimit(),
            ),
      }),
    nordeaCapitalFeeFund: yupPercent(intl).when(['createNordea'], {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    nordeaAnnualFeeFund: yupPercent(intl).when(['createNordea'], {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    nordeaCapitalFeeGuarantee: yupPercent(intl).when(['createNordea'], {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    nordeaAnnualFeeGuarantee: yupPercent(intl).when(['createNordea'], {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
  });
