import { IconContent, Tab, TabBar } from '@frontend/ui';
import { ThemeColor } from '@frontend/ui/theme';
import { FormattedMessage } from 'components/formats';
import React, { useEffect, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

export interface RoutedTab {
  title: MessageDescriptor;
  url: string;
  color?: ThemeColor;
  iconColor?: ThemeColor;
  trailingIcon?: IconContent;
}

function getActiveIndex(pathname: string, tabs: RoutedTab[]): number {
  return tabs.findIndex(tab => tab.url === pathname);
}

interface Props {
  tabs: RoutedTab[];
}

export const RoutedTabBar: React.FC<Props> = ({ tabs }) => {
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const [activeIndex, setIndex] = useState(getActiveIndex(pathname, tabs));

  useEffect(() => {
    const _activeIndex = getActiveIndex(pathname, tabs);
    if (_activeIndex > -1) {
      setIndex(_activeIndex);
    }
  }, [pathname]);

  if (!tabs.length) {
    return null;
  }

  const activate = (i: number) => {
    if (i !== activeIndex) {
      push(tabs[i].url + search);
    }
  };

  return (
    <TabBar activeIndex={activeIndex}>
      {tabs.map((tab, i) => (
        <Tab
          key={i}
          iconColor={tab.iconColor}
          onClick={() => activate(i)}
          trailingIcon={tab.trailingIcon}
          color={tab.color}
        >
          <FormattedMessage {...tab.title} />
        </Tab>
      ))}
    </TabBar>
  );
};
