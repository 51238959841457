import { A, ellipsisCSS } from '@frontend/ui';
import { description } from '@frontend/ui/icons';
import React from 'react';
import styled from 'styled-components';

const AttachmentItem = styled.p`
  margin-bottom: 0.375rem;
  &:last-child {
    margin-bottom: 0;
  }
  ${ellipsisCSS};
`;

interface Attachment {
  readonly name: string;
  readonly type: string;
  readonly url: string | null;
}

interface Props {
  attachments: readonly Attachment[];
}

export const AgreementAttachmentList: React.FC<Props> = ({ attachments }) => (
  <div>
    {attachments.map((attachment, i) => (
      <AttachmentItem key={i}>
        {attachment.url ? (
          <A
            title={attachment.name}
            href={attachment.url}
            target="_blank"
            icon={description}
          >
            {attachment.name}
          </A>
        ) : (
          <p>{attachment.name}</p>
        )}
      </AttachmentItem>
    ))}
  </div>
);
