import { BodyMedium, Chip, SectionHeader, Subsection } from '@frontend/ui';
import { noteQuery, noteQueryVariables } from 'app/apollo/graphql/types';
import { noteCategoryMessages, noteMessages } from 'app/messages/notes';
import { NoteMatchParams } from 'app/pages/companies/company/notes';
import { useQuery } from 'app/utils/use-query';
import { Attachments } from 'components/Attachments';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import React from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { NOTE_QUERY } from '../graphql/queries';
import { Loading } from '../loading';

const ContentWrapper = styled.div`
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
`;

const Overline = styled(BodyMedium)`
  display: block;
  color: ${p => p.theme.onSurfaceVariant};
  margin-bottom: 0.5rem;
`;

export const NoteView: React.FC = () => {
  const match = useRouteMatch<NoteMatchParams>();
  const { formatMessage } = useIntl();

  const { data, loading, error } = useQuery<noteQuery, noteQueryVariables>(
    NOTE_QUERY,
    {
      variables: {
        id: match.params.noteId!,
      },
      errorPolicy: 'all',
    },
  );

  if (loading) {
    return <Loading />;
  }

  if (!data?.note) {
    return <GraphQlError error={error} />;
  }

  const { note } = data;

  return (
    <NavigationAnchor title={note.subject} url={match.url}>
      <TextGrid>
        <Overline>{note.createdAt}</Overline>
        <SectionHeader>{note.subject}</SectionHeader>
        <ContentWrapper>
          <Chip text={formatMessage(noteCategoryMessages[note.category])} />
        </ContentWrapper>
        <Subsection>
          <ContentWrapper>{note.content}</ContentWrapper>
          <FormattedMessage
            {...noteMessages.createdByNoteAuthor}
            values={{ authorName: note.author.name }}
          />
        </Subsection>
        {note.attachments.length > 0 && (
          <Attachments
            attachments={note.attachments.map(attachment => ({
              name: attachment.filename,
              url: attachment.url,
            }))}
          />
        )}
      </TextGrid>
    </NavigationAnchor>
  );
};
