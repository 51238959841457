import { ContentContainer, Section } from '@frontend/ui';
import {
  jobOffersOverviewQuery,
  jobOffersOverviewQueryVariables,
} from 'app/apollo/graphql/types';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import { JobOffersTable } from 'features/companies/company/job-offers/components/JobOffersTable';
import { jobOfferMessages } from 'features/companies/company/job-offers/messages';
import { Page } from 'features/page';
import qs from 'query-string';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '..';
import { JOB_OFFERS_OVERVIEW_QUERY } from './graphql/queries';
import { updateJobOffersOverviewQuery } from './utils/update-query';

export const Overview: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();
  const { per_page } = qs.parse(location.search);

  const { data, previousData, loading, error, fetchMore } = useQuery<
    jobOffersOverviewQuery,
    jobOffersOverviewQueryVariables
  >(JOB_OFFERS_OVERVIEW_QUERY, {
    errorPolicy: 'all',
    variables: {
      companyId,
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;

  if (!_data && loading) {
    return <TopLoading />;
  }

  const jobOffersData = _data?.company?.jobOffers;
  const jobOffers = jobOffersData?.edges.map(({ node }) => node);

  if (!jobOffersData || !jobOffers?.length) {
    return (
      <Page title={<FormattedMessage {...jobOfferMessages.jobOfferTitle} />}>
        <ContentContainer>
          {(error || !jobOffersData) && <GraphQlError error={error} />}
          {jobOffersData && (
            <EmptyState
              title={<FormattedMessage {...jobOfferMessages.noJobOffer} />}
              actions={
                <LinkButton to={`/companies/${companyId}/job-offers/create`}>
                  <FormattedMessage {...jobOfferMessages.createJobOffer} />
                </LinkButton>
              }
            />
          )}
        </ContentContainer>
      </Page>
    );
  }

  const { pageInfo } = jobOffersData;
  const { endCursor, startCursor } = pageInfo;

  return (
    <Page title={<FormattedMessage {...jobOfferMessages.jobOfferTitle} />}>
      <ContentContainer>
        <Section>
          {loading && <TopLoading />}
          {error && <GraphQlError error={error} />}
          <JobOffersTable
            jobOffers={jobOffers}
            navigation={
              <TableNavigation
                pageInfo={pageInfo}
                onNextPage={() => {
                  fetchMore({
                    variables: {
                      after: endCursor,
                    },
                    updateQuery: updateJobOffersOverviewQuery,
                  });
                }}
                onPreviousPage={() => {
                  fetchMore({
                    variables: {
                      before: startCursor,
                      first: undefined,
                      last: per_page
                        ? parseInt(per_page, 10)
                        : DEFAULT_RESULT_PER_PAGE,
                    },
                    updateQuery: updateJobOffersOverviewQuery,
                  });
                }}
              />
            }
          />
        </Section>
      </ContentContainer>
    </Page>
  );
};
