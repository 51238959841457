import { ApolloError } from '@apollo/client';
import { DatePickerField, Form, useFormikContext } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell33, TextGrid } from 'components/GridCell';
import isAfter from 'date-fns/isAfter';
import React from 'react';
import * as Yup from 'yup';

import { BenefitPackagesSection } from '../components/BenefitPackagesSection';
import { UpcomingChangesNotification } from '../components/UpcomingChangesNotification';
import { benefitFormMessages } from '../messages';
import { BenefitPackageOption } from '../types';
import { getEarliestRetroactiveDate } from '../utils';

export interface FormValues {
  benefitPackageIds: string[];
  effectiveFrom: string;
}

interface Props {
  benefitPackageOptions: BenefitPackageOption[];
  isEdit?: boolean;
  latestChangesEffectiveDate?: string | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    effectiveFrom: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test({
        name: 'valid date',
        message: intl.formatMessage(validationMessages.dateMaxThreeMonthsOld),
        test: (value: string) =>
          isAfter(new Date(value), new Date(getEarliestRetroactiveDate())),
      }),
  });

export const SynsamForm: React.FC<Props> = ({
  benefitPackageOptions,
  isEdit = false,
  latestChangesEffectiveDate,
  submissionError,
}) => {
  const { isValid, isSubmitting } = useFormikContext<FormValues>();

  return (
    <Form>
      <Section>
        <UpcomingChangesNotification
          latestChangesEffectiveDate={latestChangesEffectiveDate}
        />
        <BenefitPackagesSection
          benefitPackageOptions={benefitPackageOptions}
          name="benefitPackageIds"
        />
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage
              {...(isEdit
                ? benefitFormMessages.effectiveEditDate
                : benefitFormMessages.effectiveStartDate)}
            />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...(isEdit
                  ? benefitFormMessages.effectiveEditDateDescription
                  : benefitFormMessages.effectiveStartDateDescription)}
              />
            </DescriptionWrapper>
          </TextGrid>
          <Grid>
            <GridCell33>
              <DatePickerField
                dense
                gridMargin
                label={
                  <FormattedMessage
                    {...(isEdit
                      ? benefitFormMessages.effectiveEditDateLabel
                      : benefitFormMessages.effectiveStartDateLabel)}
                  />
                }
                min={getEarliestRetroactiveDate()}
                name="effectiveFrom"
                required
                type="month"
              />
            </GridCell33>
          </Grid>
        </Subsection>
        {submissionError && <GraphQlError error={submissionError} />}
        <ButtonLayout>
          <Button
            loading={isSubmitting}
            filled
            type="submit"
            disabled={!isValid}
          >
            <FormattedMessage {...benefitFormMessages.save} />
          </Button>
        </ButtonLayout>
      </Section>
    </Form>
  );
};
