export default {
  number: {
    currency: {
      style: 'currency',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
    integer: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    // A percentage expressed in percent units (i.e. 50 == 50%) but without
    // the %.
    percentUnit: {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
    percentInteger: {
      style: 'percent',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
  },
};
