import { CheckboxField, Icon, Td, Tr } from '@frontend/ui';
import { cancel } from '@frontend/ui/icons';
import { insuranceMessages } from 'app/messages/insurance';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import { NoValue } from 'components/NoValue';
import React, { useId } from 'react';

import { Insurance } from '../..';

interface Props {
  checked: boolean;
  deleteInsurances: (insurance: Insurance) => void;
  insurance: Insurance;
  onCheckboxChange: (checked: boolean) => void;
}

export const InsuranceTableRow: React.FC<Props> = ({
  insurance,
  deleteInsurances,
  checked,
  onCheckboxChange,
}) => {
  const id = useId();

  return (
    <Tr
      inlineMenu={
        <KebabMenu label={insuranceMessages.insurance} inClickableArea fixed>
          <MenuItem
            title={<FormattedMessage {...insuranceMessages.markAsDeleted} />}
            onClick={() => deleteInsurances(insurance)}
            icon={<Icon icon={cancel} decorative />}
          />
        </KebabMenu>
      }
    >
      <Td type="input">
        <CheckboxField
          id={insurance.id}
          checked={checked}
          onChange={onCheckboxChange}
          aria-labelledby={id}
        />
      </Td>
      <Td align="left" type="number" id={id}>
        {insurance.insuranceNumber}
      </Td>
      <Td>{insurance.institute.name}</Td>
      <Td align="left">{insurance.policyholderName}</Td>
      <Td align="left" type="number">
        <FormattedNaturalPersonIdentifier
          nid={insurance.insuredParty ?? undefined}
        />
      </Td>
      <Td>
        {insurance.totalValue ? (
          <FormattedCurrency
            value={insurance.totalValue.value}
            currency={insurance.totalValue.currency}
          />
        ) : (
          <NoValue />
        )}
      </Td>
      <Td align="left">
        <FormattedMessage
          {...(insurance.deletedAt != null
            ? insuranceMessages.deleted
            : insuranceMessages.notDeleted)}
        />
      </Td>
    </Tr>
  );
};
