import { ContentContainer } from '@frontend/ui';
import {
  smeCompanyProposalBenefitLayoutQuery,
  smeCompanyProposalBenefitLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { SME_COMPANY_PROPOSAL_BENEFIT_LAYOUT_QUERY } from 'app/queries';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Page } from 'features/page';
import {
  EditAccident,
  isAccidentConfig,
} from 'features/sme/companies/company/proposals/edit/benefits/edit/accident';
import {
  EditDisability,
  isDisabilityConfig,
} from 'features/sme/companies/company/proposals/edit/benefits/edit/disability';
import {
  EditEmployersPension,
  isEmployersPensionConfig,
} from 'features/sme/companies/company/proposals/edit/benefits/edit/employers-pension';
import {
  EditHealthcare,
  isHealthcareConfig,
} from 'features/sme/companies/company/proposals/edit/benefits/edit/healthcare';
import {
  EditLife,
  isLifeConfig,
} from 'features/sme/companies/company/proposals/edit/benefits/edit/life';
import {
  EditOccupationalPension,
  isOccupationalPensionConfig,
} from 'features/sme/companies/company/proposals/edit/benefits/edit/occupational-pension';
import {
  EditSalaryExchange,
  isSalaryExchangeConfig,
} from 'features/sme/companies/company/proposals/edit/benefits/edit/salary-exchange';
import {
  EditTgl,
  isTglConfig,
} from 'features/sme/companies/company/proposals/edit/benefits/edit/tgl';
import {
  smeBenefitTypesMessages,
  smeEditBenefitTypesMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams as SmeProposalMatchParams } from '../..';

export interface MatchParams extends SmeProposalMatchParams {
  benefitId: string;
}

export const SmeCompanyProposalBenefitRoute: React.FC<
  RouteComponentProps<MatchParams>
> = ({ match: { params } }) => {
  const { formatMessage } = useIntl();

  const { data, loading, error } = useQuery<
    smeCompanyProposalBenefitLayoutQuery,
    smeCompanyProposalBenefitLayoutQueryVariables
  >(SME_COMPANY_PROPOSAL_BENEFIT_LAYOUT_QUERY, {
    variables: { benefitId: params.benefitId, proposalId: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  const parentLink = `/sme/${params.companyId}/proposals/${params.proposalId}/benefits`;

  const benefit = data?.proposal?.benefit;
  if (!benefit?.configuration) {
    return <EmptyStatePage error={error} parentLink={parentLink} />;
  }

  return (
    <NavigationAnchor
      title={formatMessage({
        select: benefit.type,
        messages: smeEditBenefitTypesMessages,
      })}
      url={parentLink}
    >
      <Page
        title={
          <FormattedMessage
            select={benefit.type}
            messages={smeBenefitTypesMessages}
          />
        }
        parentLink={parentLink}
      >
        <ContentContainer>
          {isAccidentConfig(benefit.configuration) && <EditAccident />}
          {isDisabilityConfig(benefit.configuration) && <EditDisability />}
          {isEmployersPensionConfig(benefit.configuration) && (
            <EditEmployersPension />
          )}
          {isHealthcareConfig(benefit.configuration) && <EditHealthcare />}
          {isLifeConfig(benefit.configuration) && <EditLife />}
          {isOccupationalPensionConfig(benefit.configuration) && (
            <EditOccupationalPension />
          )}
          {isSalaryExchangeConfig(benefit.configuration) && (
            <EditSalaryExchange />
          )}
          {isTglConfig(benefit.configuration) && <EditTgl />}
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
