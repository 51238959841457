import React from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
  padding-inline-start: 1rem;
  margin: 0;

  li:last-child {
    margin-bottom: 0;
  }
`;

interface Props {
  items: string[];
}

export const List: React.FC<Props> = ({ items }) => (
  <Ul>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </Ul>
);
