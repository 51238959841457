import { BenefitType } from '@frontend/benefit-types';
import { SortingDirection } from 'app/apollo/graphql/types';

interface Benefit {
  category: string;
  name: string;
  type: string;
}

export type SortableColumns = 'name' | 'category';

/**
 * Desired benefit sort order as expressed by NLP for
 * the proposal flow. Benefits not available for NLP
 * customers are sorted based on PO's preference.
 * Potential benefits which have not been added to the
 * record are placed at the end of the list.
 */
const DEFAULT_BENEFITS_SORT_ORDER: Record<BenefitType, number> = {
  advinans_occupational_pension: 0,
  /*   nordea_node_employers_pension: 1, */
  euro_accident_plansjuk: 2,
  euro_accident_privataccess: 3,
  euro_accident_accident: 4,
  euro_accident_tgl: 5,
  euro_accident_life: 6,
  euro_accident_sickness_and_accident: 7,
  advinans_salary_exchange: 8,
  nordea_node_digital_advice: 9,
  nordea_node_personal_advice: 10,
  epassi: 11,
  wellness: 12,
  synsam: 13,
  epassi_bike: 14,
};

interface Params {
  order: SortingDirection;
  orderBy: SortableColumns;
}

/**
 * Determines the sort order of benefits according to the given parameters.
 * If no parameters are supplied a predefined default order is returned.
 */
export const by =
  <T extends Benefit>(params?: Params) =>
  (a: T, b: T) => {
    const order = params?.order;
    const orderBy = params?.orderBy;

    if (orderBy === 'category') {
      return order === SortingDirection.ASC
        ? a.category.localeCompare(b.category)
        : b.category.localeCompare(a.category);
    }
    if (orderBy === 'name') {
      return order === SortingDirection.ASC
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    }
    return (
      (DEFAULT_BENEFITS_SORT_ORDER[a.type] ?? Number.MAX_SAFE_INTEGER) -
      (DEFAULT_BENEFITS_SORT_ORDER[b.type] ?? Number.MAX_SAFE_INTEGER)
    );
  };

/**
 * Determines the sort order of benefit type names according to
 * DEFAULT_BENEFEFITS_SORT_ORDER. Any benefit type not found in the
 * DEFAULT_BENEFITS_SORT_ORDER is placed at the end of the list.
 */
export const byNameDefault = (a: BenefitType, b: BenefitType): number => {
  const orderA = DEFAULT_BENEFITS_SORT_ORDER[a] ?? Number.MAX_SAFE_INTEGER;
  const orderB = DEFAULT_BENEFITS_SORT_ORDER[b] ?? Number.MAX_SAFE_INTEGER;
  return orderA - orderB;
};
