import { Table, Td, Th, Tr } from '@frontend/ui';
import { requestDetails as Request } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { salaryExchangeProviderMessages } from 'app/messages/salary-exchange';
import { UnitCodeOptions, unitCodeSuffixMessages } from 'app/utils/constants';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import { companySalaryExchangeMessages } from 'features/companies/company/salary-exchanges/messages';
import React from 'react';

import { RequestSalaryExchangeType } from '../../../types';

interface ModalTableProps {
  navigation: React.ReactNode;
  requests: Request[];
  type: RequestSalaryExchangeType;
}

export const ApproveTable: React.FC<ModalTableProps> = ({
  type,
  requests,
  navigation,
}) => (
  <Table size="small" fixed navigation={navigation}>
    <colgroup>
      <col style={{ width: '20%' }} />
      <col style={{ width: 'auto' }} />
      <col style={{ width: '20%' }} />
      <col style={{ width: '15%' }} />
      <col style={{ width: '15%' }} />
      <col style={{ width: '15%' }} />
    </colgroup>
    <thead>
      <Tr>
        <Th>
          <FormattedMessage {...commonMessages.personalIdentityNumber} />
        </Th>
        <Th multiline>
          <FormattedMessage {...commonMessages.name} />
        </Th>
        <Th type="number">
          <FormattedMessage
            {...companySalaryExchangeMessages.benefitQualifyingIncome}
          />
        </Th>
        <Th type="number">
          <FormattedMessage {...companySalaryExchangeMessages.deduction} />
        </Th>
        <Th type="number">
          <FormattedMessage {...companySalaryExchangeMessages.premium} />
        </Th>
        <Th>
          <FormattedMessage {...commonMessages.via} />
        </Th>
      </Tr>
    </thead>
    <tbody>
      {requests.map(
        ({
          id,
          membership,
          deductionValue,
          premium,
          provider,
          remuneration,
        }) => (
          <Tr key={id}>
            <Td type="number" align="left">
              <FormattedNaturalPersonIdentifier
                nids={membership.naturalPersonIdentifiers}
              />
            </Td>
            <Td multiline>
              {membership.givenName} {membership.lastName}
            </Td>
            <Td type="number">
              {remuneration ? (
                <FormattedCurrency
                  currency={remuneration.currency ?? ''}
                  value={remuneration.value}
                  after={
                    remuneration.unitCode &&
                    remuneration.unitCode !== UnitCodeOptions.LS ? (
                      <>
                        /
                        <FormattedMessage
                          select={remuneration.unitCode}
                          messages={unitCodeSuffixMessages}
                        />
                      </>
                    ) : null
                  }
                />
              ) : (
                <NoValue />
              )}
            </Td>
            <Td type="number">
              <FormattedCurrency
                value={deductionValue}
                currency="SEK"
                after={
                  type === RequestSalaryExchangeType.ONGOING
                    ? 'PER_MONTH'
                    : undefined
                }
              />
            </Td>
            <Td type="number">
              <FormattedCurrency
                value={premium}
                currency="SEK"
                after={
                  type === RequestSalaryExchangeType.ONGOING
                    ? 'PER_MONTH'
                    : undefined
                }
              />
            </Td>
            <Td>
              <FormattedMessage
                select={provider}
                messages={salaryExchangeProviderMessages}
              />
            </Td>
          </Tr>
        ),
      )}
    </tbody>
  </Table>
);
