import { ApolloError } from '@apollo/client';
import { getMostRecentEmployment } from '@frontend/utils';
import {
  membershipRemunerationsQuery,
  membershipRemunerationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem as Remuneration,
  membershipRemunerationsQueryVariables,
} from 'app/apollo/graphql/types';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { EmploymentRouteMatchParams } from 'app/pages/companies/company/employees/employee/employments/employment';
import { useQuery } from 'app/utils/use-query';
import { afterFadeout } from 'components/Modal';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { MEMBERSHIP_REMUNERATIONS_QUERY } from './graphql/queries';
import { RemunerationsTable } from './table';
import { useSubmit } from './update/use-submit';

const before = (endDate: string) => (remuneration: Remuneration) =>
  new Date(remuneration.from).getTime() < new Date(endDate).getTime();

type MatchParams = EmployeeRouteMatchParams | EmploymentRouteMatchParams;

const isEmploymentPath = (
  params: MatchParams,
): params is EmploymentRouteMatchParams => 'employmentId' in params;

const getRemuerations = (data?: membershipRemunerationsQuery) => {
  const employmentEndDate = data?.employment?.period[1];

  /* There's currently no support for concurrent employments in the service */
  const latestEmployment = data?.membership?.employment
    ? getMostRecentEmployment(data.membership.employment)
    : undefined;

  return employmentEndDate
    ? latestEmployment?.remuneration?.filter(before(employmentEndDate)) ?? []
    : latestEmployment?.remuneration ?? [];
};

const isEmpty = (data?: membershipRemunerationsQuery) =>
  !getRemuerations(data).length;

export const MembershipRemunerations: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { userAccountId, companyId } = params;
  const employmentId = isEmploymentPath(params)
    ? params.employmentId
    : undefined;

  const { submit, error } = useSubmit();

  const [submissionError, setSubmissionError] = useState<
    ApolloError | undefined
  >(error);

  useEffect(() => {
    setSubmissionError(error);
  }, [error]);

  const { data } = useQuery<
    membershipRemunerationsQuery,
    membershipRemunerationsQueryVariables
  >(MEMBERSHIP_REMUNERATIONS_QUERY, {
    variables: {
      userAccountId,
      companyId,
      employmentId: employmentId ?? '',
      hasEmploymentId: !!employmentId,
    },
    errorPolicy: 'all',
    suspend: true,
    isEmpty,
  });

  const employmentEndDate = data?.employment?.period[1];
  const remunerations = getRemuerations(data);

  const onClose = () => {
    afterFadeout(() => setSubmissionError(undefined));
  };

  if (isEmploymentPath(params) && !remunerations.length) {
    return null;
  }

  const disabled =
    !!employmentEndDate &&
    new Date(employmentEndDate).getTime() <= new Date().getTime();

  return (
    <RemunerationsTable
      remunerations={remunerations}
      onSubmit={submit}
      submissionError={submissionError}
      disabled={disabled}
      onClose={onClose}
    />
  );
};
