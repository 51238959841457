import {
  companyCustomColumnsQuery,
  companyCustomColumnsQueryVariables,
  SUPER_TYPE,
} from 'app/apollo/graphql/types';
import { employeesImportMessages } from 'app/messages/employees';
import { useQuery } from 'app/utils/use-query';
import { useIntl } from 'components/formats';
import { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { COMPANY_CUSTOM_COLUMNS_QUERY } from '../../../graphql/queries';
import { MatchParams } from '..';
import { selectStaticColumnOptions } from './static-column-options';

interface Option {
  label: string;
  value: string;
}

export const useTargetColumnOptions = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { companyId } = params;
  const intl = useIntl();

  const [options, setOptions] = useState<Option[]>(
    selectStaticColumnOptions(intl),
  );

  useQuery<companyCustomColumnsQuery, companyCustomColumnsQueryVariables>(
    COMPANY_CUSTOM_COLUMNS_QUERY,
    {
      variables: {
        companyId,
      },
      skip: !companyId,
      errorPolicy: 'all',
      onCompleted: data => {
        let updatedOptions: Option[] = [];
        const accountingDims = data.accountingDimensions;
        let accountingDimsOptions: Option[] = [];
        if (accountingDims.length) {
          accountingDimsOptions = accountingDims.map(dim => ({
            label: dim.name,
            value: dim.name,
          }));
        }

        const customTypes = data.company?.customTypes?.edges;
        const customTypesOptions: Option[] = customTypes?.length
          ? customTypes.flatMap(({ node }) => [
              {
                label: `${node.id} ${node.name ?? ''}`,
                value: `1&${node.id}&${node.superType}&V`,
              },
              {
                label: intl.formatMessage(
                  node.superType === SUPER_TYPE.BALANCE
                    ? employeesImportMessages.balanceEffectiveDate
                    : employeesImportMessages.remunerationEffectiveDate,
                  {
                    customType: `${node.id} ${node.name ?? ''}`,
                  },
                ),
                value: `1&${node.id}&${node.superType}&F`,
              },
            ])
          : [];

        updatedOptions = [
          ...options,
          ...accountingDimsOptions.filter(
            // The "Kostnadsställe" (Cost center) dimension is handled by its own option.
            accDimOption => accDimOption.label !== 'Kostnadsställe',
          ),
          ...customTypesOptions,
        ];
        setOptions(updatedOptions);
      },
    },
  );

  return options;
};
