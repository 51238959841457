import { createApolloClient } from '@frontend/apollo';
import possibleTypes from 'app/apollo/graphql/types/possible-types.json';
import { getLocale } from 'app/browser-store';
import { getBackstageCsrfTokenFromCookie as getCsrfTokenFromCookie } from 'app/helper';

export const BATCH_MAX = 50;

export const client = createApolloClient({
  getCsrfTokenFromCookie,
  getLocale,
  graphqlUri: `${window.env.API}/graphql`,
  possibleTypes,
});
