import gql from 'graphql-tag';

export const noteFragmentDetails = gql`
  fragment noteFragmentDetails on Note {
    id
    subject
    content
    category
    author {
      name
      adminUserExternalId
    }
    createdAt
    attachments {
      id
      filename
      url
    }
  }
`;

export const NOTES_QUERY = gql`
  query notesQuery(
    $userAccountId: ID
    $companyId: ID
    $categories: [NoteCategory!]
    $author: AuthorInput
    $sortOrder: SortOrder
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    notes(
      userAccountId: $userAccountId
      companyId: $companyId
      categories: $categories
      author: $author
      sortOrder: $sortOrder
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        node {
          ...noteFragmentDetails
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${noteFragmentDetails}
`;

export const NOTE_AUTHORS_QUERY = gql`
  query noteAuthorsQuery {
    noteAuthors {
      adminUserExternalId
      name
    }
  }
`;

export const NOTE_QUERY = gql`
  query noteQuery($id: ID!) {
    note(id: $id) {
      ...noteFragmentDetails
    }
  }
  ${noteFragmentDetails}
`;
