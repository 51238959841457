import { Icon, Table, TableSortButton, Td, Th, Tr } from '@frontend/ui';
import { _delete as deleteIcon, edit } from '@frontend/ui/icons';
import { ProposalBenefitType } from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { useTableSort } from 'app/utils/use-table-sort';
import { FormattedMessage, useIntl } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import { useConfirm } from 'contexts/confirmation';
import { smeBenefitsMessages } from 'features/sme/messages/sme';
import React from 'react';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import { by, SortableColumns } from '../../utils/sort-benefits-by';

export interface Benefit {
  benefitPackages: string[];
  details: React.ReactNode;
  id: string;
  name: string;
  type: ProposalBenefitType;
}

interface Props {
  benefits: readonly Benefit[];
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

export const BenefitsTable: React.FC<Props> = ({
  benefits,
  onDelete,
  onEdit,
}) => {
  const { handleSort, order, orderBy } = useTableSort<SortableColumns>();
  const { formatMessage } = useIntl();
  const disabled = useProposalReadonly();
  const { confirm } = useConfirm();

  const tableHead = (
    <>
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '35%' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <Tr withInlineMenuTh>
          <Th>
            <TableSortButton
              onClick={handleSort}
              order={orderBy === 'name' ? order : undefined}
              value="name"
            >
              <FormattedMessage {...commonMessages.benefit} />
            </TableSortButton>
          </Th>
          <Th>
            <FormattedMessage {...commonMessages.detail} />
          </Th>
          <Th align="left">
            <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />
          </Th>
        </Tr>
      </thead>
    </>
  );

  const params = order && orderBy ? { order, orderBy } : undefined;
  const sortedBenefits = [...benefits].sort(by(params));

  return (
    <Table size="small">
      <>
        {tableHead}
        <tbody>
          {sortedBenefits.map(benefit => (
            <Tr
              key={benefit.id}
              inlineMenu={
                <KebabMenu inClickableArea fixed disabled={disabled}>
                  <MenuItem
                    title={
                      <FormattedMessage {...smeBenefitsMessages.editLink} />
                    }
                    icon={<Icon icon={edit} decorative />}
                    onClick={() => onEdit(benefit.id)}
                  />
                  <MenuItem
                    title={
                      <FormattedMessage
                        {...smeBenefitsMessages.deleteBenefit}
                      />
                    }
                    icon={<Icon icon={deleteIcon} decorative />}
                    onClick={async () => {
                      const confirmed = await confirm({
                        title: formatMessage(
                          smeBenefitsMessages.confirmDeleteBenefitTitle,
                        ),
                        description: formatMessage(
                          smeBenefitsMessages.confirmDeleteBenefit,
                        ),
                      });

                      if (confirmed) {
                        onDelete(benefit.id);
                      }
                    }}
                  />
                </KebabMenu>
              }
              linked
              onClick={() => onEdit(benefit.id)}
            >
              <Td>{benefit.name}</Td>
              <Td align="left">{benefit.details}</Td>
              <Td align="left">{benefit.benefitPackages.join(', ')}</Td>
            </Tr>
          ))}
        </tbody>
      </>
    </Table>
  );
};
