import { SearchableSelectField, useField } from '@frontend/formik';
import { SearchableSelectOption } from '@frontend/ui';
import {
  selectClearingQuery,
  selectClearingQueryVariables,
} from 'app/apollo/graphql/types';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { smeCompanyMessages } from 'features/sme/messages/sme';
import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';

import { SELECT_CLEARING_QUERY } from './graphql/queries';

const SEARCH_DEBOUNCE = 300;

export type ClearingFieldType = SearchableSelectOption | null;

interface ShouldSkipSearchParams {
  search: string | undefined;
  searchIsUpdatedThroughSelect: boolean;
}

const shouldSkipSearch = (params: ShouldSkipSearchParams) =>
  !params.search ||
  params.search.length < 3 ||
  params.searchIsUpdatedThroughSelect;

interface Props {
  name: string;
  disabled?: boolean;
  required?: boolean;
}

export const SelectClearingField: React.FC<Props> = ({
  name,
  disabled,
  required,
}) => {
  const [{ value }] = useField<ClearingFieldType>(name);

  const [search, setSearch] = useState<string>();
  const [debouncedSearch] = useDebounce(search, SEARCH_DEBOUNCE);

  const { data, previousData, loading, error } = useQuery<
    selectClearingQuery,
    selectClearingQueryVariables
  >(SELECT_CLEARING_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    skip: shouldSkipSearch({
      search: debouncedSearch,
      searchIsUpdatedThroughSelect: value?.label === debouncedSearch,
    }),
    variables: {
      search: debouncedSearch ?? '',
    },
  });

  const _data = data ?? previousData;

  const options: SearchableSelectOption[] =
    _data?.clearingCodes &&
    !shouldSkipSearch({
      search,
      searchIsUpdatedThroughSelect: value?.label === search,
    })
      ? _data.clearingCodes.map(clearing => ({
          label: `${clearing.code} ${clearing.name}`,
          value: clearing.code,
        }))
      : [];

  return (
    <>
      {loading && <TopLoading />}
      <SearchableSelectField
        dense
        label={<FormattedMessage {...smeCompanyMessages.clearingSalesOffice} />}
        name={name}
        options={options}
        search={search}
        onSearchChange={setSearch}
        required={required}
        disabled={disabled}
      />
      {error && <GraphQlError error={error} />}
    </>
  );
};
