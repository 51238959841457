import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const COMPANY_IMPORTS_QUERY = gql`
  query companyImportsQuery(
    $companyId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    company(id: $companyId) {
      id
      imports(first: $first, last: $last, before: $before, after: $after) {
        edges {
          node {
            id
            name
            status
            createdAt
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
      }
    }
  }
  ${pageInfoDetails}
`;

export const COMPANY_CUSTOM_COLUMNS_QUERY = gql`
  query companyCustomColumnsQuery($companyId: ID!, $superType: SUPER_TYPE) {
    accountingDimensions(companyId: $companyId) {
      id
      name
    }
    company(id: $companyId) {
      id
      customTypes(superType: $superType) {
        edges {
          node {
            id
            currency
            name
            superType
            unitCode
          }
        }
      }
    }
  }
`;
