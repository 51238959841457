import gql from 'graphql-tag';

import { membershipDetails } from '../../graphql/fragments';

export const EDIT_EMPLOYEE_DETAILS_FORM_QUERY = gql`
  query editEmployeeDetailsFormQuery(
    $companyId: ID!
    $userAccountId: ID!
    $benefitTypeNames: [String!]
  ) {
    entitlements(
      companyId: $companyId
      userAccountId: $userAccountId
      benefitTypeNames: $benefitTypeNames
    ) {
      edges {
        node {
          benefit {
            id
            type
            content {
              locale
            }
          }
        }
      }
    }
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      ...membershipDetails
    }
  }
  ${membershipDetails}
`;
