import { bonusSalaryExchangesTableQuery as Query } from 'app/apollo/graphql/types';

interface FetchMore {
  fetchMoreResult?: Query;
}

export const updateQuery = (
  previousResult: Query,
  { fetchMoreResult }: FetchMore,
) => {
  if (
    !previousResult?.bonusSalaryExchangeRequestsHistory ||
    !fetchMoreResult?.bonusSalaryExchangeRequestsHistory
  ) {
    return previousResult;
  }

  const { edges, pageInfo } =
    fetchMoreResult.bonusSalaryExchangeRequestsHistory;

  return {
    bonusSalaryExchangeRequestsHistory: {
      ...previousResult.bonusSalaryExchangeRequestsHistory,
      pageInfo,
      edges,
    },
  };
};
