import { companyImportsQuery } from 'app/apollo/graphql/types';

const updateBenefitPackageMembershipsQuery = (
  previousResult: companyImportsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: companyImportsQuery;
  },
): companyImportsQuery => {
  if (
    !previousResult ||
    !previousResult.company ||
    !previousResult.company.imports ||
    !fetchMoreResult ||
    !fetchMoreResult.company ||
    !fetchMoreResult.company.imports
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.imports;

  return {
    company: {
      ...previousResult.company,
      imports: {
        __typename: previousResult.company.imports.__typename,
        edges: [...edges],
        pageInfo,
      },
    },
  };
};

export default updateBenefitPackageMembershipsQuery;
