import gql from 'graphql-tag';

export const REJECT_SALARY_EXCHANGE_REQUEST_MUTATION = gql`
  mutation rejectSalaryExchangeRequestMutation(
    $input: FlexRejectSalaryExchangeRequestInput!
  ) {
    rejectSalaryExchangeRequest(input: $input) {
      salaryExchangeRequest {
        id
      }
    }
  }
`;
