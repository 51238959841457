import { defineMessages } from 'react-intl';

export const logoutMessages = defineMessages({
  logoutError: {
    id: 'logout.logoutError',
    defaultMessage: 'Utloggning misslyckades',
  },
  loggingOut: {
    id: 'logout.loggingOut',
    defaultMessage: 'Du loggas ut',
  },
});
