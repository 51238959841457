import { Form, useFormikContext } from '@frontend/formik';
import { Button, ButtonLayout } from '@frontend/ui';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, IntlShape } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';
import styled from 'styled-components';

import {
  BenefitsFields,
  FormValues as BenefitsFieldsFormValues,
} from './benefits';
import {
  CompensationFields,
  FormValues as CompensationFieldsFormValues,
  validationSchema as compensationValidationSchema,
} from './compensation';
import {
  FormValues as InformationFieldsFormValues,
  InformationFields,
  validationSchema as informationValidationSchema,
} from './information';
import {
  FormValues as SettingsFieldsFieldsFormValues,
  SettingsFields,
  validationSchema as settingsValidationSchema,
} from './settings';

const SubmitContainer = styled.div`
  padding: 1rem;
`;

export const validationSchema = (intl: IntlShape) =>
  informationValidationSchema(intl)
    .concat(settingsValidationSchema(intl))
    .concat(compensationValidationSchema(intl));

export type FormValues = InformationFieldsFormValues &
  CompensationFieldsFormValues &
  BenefitsFieldsFormValues &
  SettingsFieldsFieldsFormValues & {
    submissionError?: string;
  };

interface Props {
  secondaryAction?: React.ReactNode;
}

export const JobOfferForm: React.FC<Props> = ({ secondaryAction }) => {
  const { errors, isSubmitting, isValid } = useFormikContext<FormValues>();
  return (
    <Form>
      <TextGrid>
        <InformationFields />
        <CompensationFields />
        <BenefitsFields />
        <SettingsFields />
        <SubmitContainer>
          {errors?.submissionError && (
            <NotificationCard type="error">
              {errors?.submissionError}
            </NotificationCard>
          )}
          <ButtonLayout>
            {secondaryAction}
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
              filled
            >
              <FormattedMessage {...formMessages.save} />
            </Button>
          </ButtonLayout>
        </SubmitContainer>
      </TextGrid>
    </Form>
  );
};
