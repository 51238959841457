import { ContentContainer } from '@frontend/ui';
import {
  smeCompanyLayoutQuery,
  smeCompanyLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { menuMessages } from 'app/messages/menu';
import { SME_COMPANY_LAYOUT_QUERY } from 'app/queries';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { RoutedTab } from 'components/RoutedTabBar';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Page } from 'features/page';
import {
  CreateProposalModal,
  getCreateProposalAction,
} from 'features/sme/companies/company/proposals/create';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';

import { SmeCompanyOverviewRoute } from './_index';
import { SmeCompanyEditRoute } from './edit';
import { SmeCompanyProposalRoutes } from './proposal';
import { SmeCompanyProposalsRoute } from './proposals';

interface Tab extends RoutedTab {
  path: string;
}

export interface MatchParams {
  companyId: string;
}

export const SmeCompanyRoutes: React.FC = () => {
  const match = useRouteMatch<MatchParams>();
  const location = useLocation();

  const { data, loading, error } = useQuery<
    smeCompanyLayoutQuery,
    smeCompanyLayoutQueryVariables
  >(SME_COMPANY_LAYOUT_QUERY, {
    variables: { id: match.params.companyId },
  });

  const title = data?.company?.name;

  const tabs: Tab[] = [
    { title: menuMessages.overview, url: match.url, path: match.path },
    {
      title: smeCompanyProposalsMessages.proposals,
      url: `${match.url}/proposals`,
      path: `${match.path}/proposals`,
    },
  ];

  if (loading) {
    return <TopLoading />;
  }

  if (!title) {
    return <EmptyStatePage error={error} parentLink="/sme" />;
  }

  return (
    <NavigationAnchor title={title} url={match.url}>
      <Switch>
        <Route exact path={tabs.map(({ path }) => path)}>
          <Page
            title={title}
            tabs={tabs}
            parentLink="/sme"
            actions={[getCreateProposalAction(location)]}
          >
            <ContentContainer>
              <CreateProposalModal />
              <Route
                exact
                path={match.path}
                component={SmeCompanyOverviewRoute}
              />
              <Route
                exact
                path={`${match.path}/proposals`}
                component={SmeCompanyProposalsRoute}
              />
            </ContentContainer>
          </Page>
        </Route>
        <Route path={`${match.path}/edit`} component={SmeCompanyEditRoute} />
        <Route
          path={`${match.path}/proposals/:proposalId`}
          component={SmeCompanyProposalRoutes}
        />
        <Route component={EmptyStatePage} />
      </Switch>
    </NavigationAnchor>
  );
};
