import { Table, TableSortButton, Th, Tr } from '@frontend/ui';
import {
  pageInfoDetails,
  sharedFilesQuery_sharedFiles_SharedFileConnection_edges_SharedFileEdge_node_SharedFile as SharedFile,
} from 'app/apollo/graphql/types';
import { sharedFileMessages } from 'app/messages/shared-files';
import { TableSort } from 'app/utils/use-table-sort';
import { EmptyTable } from 'components/EmptyTable';
import { FormattedMessage } from 'components/formats';
import { TableNavigation } from 'components/TableNavigation';
import React from 'react';

import { SharedFilesTableRow } from '../components/SharedFilesTableRow';

interface Props {
  deleteSharedFile: (sharedFile: SharedFile) => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
  sharedFiles: Array<SharedFile>;
  sort: TableSort<string>;
}

const SharedFilesTableColgroup = () => (
  <colgroup>
    <col style={{ width: '33%' }} />
    <col style={{ width: '33%' }} />
    <col style={{ width: '33%' }} />
  </colgroup>
);

const SharedFilesTableThead = ({ sort }: { sort: TableSort<string> }) => (
  <thead>
    <Tr withInlineMenuTh>
      <Th>
        <FormattedMessage {...sharedFileMessages.name} />
      </Th>
      <Th>
        <TableSortButton
          onClick={sort.handleSort}
          order={sort.orderBy === 'date' ? sort.order : undefined}
          value="date"
          align="left"
        >
          <FormattedMessage {...sharedFileMessages.date} />
        </TableSortButton>
      </Th>
      <Th>
        <FormattedMessage {...sharedFileMessages.uploadedBy} />
      </Th>
    </Tr>
  </thead>
);

export const SharedFilesTable: React.FC<Props> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  sharedFiles,
  deleteSharedFile,
  sort,
}) => (
  <Table
    navigation={
      <TableNavigation
        pageInfo={pageInfo}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    }
    fixed
  >
    {sharedFiles.length === 0 ? (
      <EmptyTable noMatchMessage={sharedFileMessages.noSharedFiles} />
    ) : (
      <>
        <SharedFilesTableColgroup />
        <SharedFilesTableThead sort={sort} />
        <tbody>
          {sharedFiles.map(sharedFile => (
            <SharedFilesTableRow
              key={sharedFile.file.id}
              sharedFile={sharedFile}
              deleteSharedFile={deleteSharedFile}
            />
          ))}
        </tbody>
      </>
    )}
  </Table>
);
