import gql from 'graphql-tag';

export const SALARY_EXCHANGE_REPORTS_INTRO_QUERY = gql`
  query salaryExchangeReportsIntroQuery(
    $companyId: ID!
    $benefitTypeNames: [String!]
  ) {
    company(id: $companyId) {
      id
      flexBenefits(benefitTypeNames: $benefitTypeNames) {
        edges {
          node {
            id
            type
            content {
              locale
            }
            configuration {
              data
            }
          }
        }
      }
    }
  }
`;
