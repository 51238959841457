import { employmentDetails } from 'features/companies/company/employees/employee/employments/graphql/fragments';
import gql from 'graphql-tag';

export const UPDATE_EMPLOYMENT_MUTATION = gql`
  mutation updateEmploymentMutation($input: UpdateEmploymentInput!) {
    updateEmployment(input: $input) {
      employment {
        ...employmentDetails
      }
    }
  }
  ${employmentDetails}
`;
