import { ApolloError } from '@apollo/client';
import { DatePickerField, Form, useFormikContext } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import {
  addBenefitsToBenefitPackageQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage as BenefitPackage,
  addBenefitsToBenefitPackageQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit,
  ManagingCompany,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell33, TextGrid } from 'components/GridCell';
import { isAfter } from 'date-fns';
import { getEarliestRetroactiveDate } from 'features/companies/company/benefits/form/utils';
import React from 'react';
import * as Yup from 'yup';

import { getDisabledBenefitIds, getSelectedBenefitIds } from '../utils';
import { AddBenefitsTable } from './Table';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    effectiveFrom: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test({
        name: 'valid date',
        message: intl.formatMessage(validationMessages.dateMaxThreeMonthsOld),
        test: (value: string) =>
          isAfter(new Date(value), new Date(getEarliestRetroactiveDate())),
      }),
  });

type BenefitId = string;

export interface FormValues {
  benefits: Record<BenefitId, boolean>;
  effectiveFrom: string;
  submissionError?: string;
}

interface Props {
  allBenefitPackages: BenefitPackage[];
  allBenefits: Benefit[];
  companyId: string;
  managedBy: ManagingCompany;
  submissionError?: ApolloError;
}

export const AddBenefitsForm: React.FC<Props> = ({
  allBenefits,
  allBenefitPackages,
  companyId,
  managedBy,
  submissionError,
}) => {
  const { initialValues, values, dirty, isSubmitting, isValid } =
    useFormikContext<FormValues>();

  const selectedBenefitIds = getSelectedBenefitIds(values);
  const initialBenefitIds = getSelectedBenefitIds(initialValues);

  const nNewBenefitsSelected = selectedBenefitIds.filter(
    id => !initialBenefitIds.includes(id),
  ).length;

  const disabledBenefitIds = getDisabledBenefitIds(
    allBenefits,
    initialBenefitIds,
    selectedBenefitIds,
  );

  return (
    <Form>
      <Subsection>
        <AddBenefitsTable
          allBenefits={allBenefits}
          allBenefitPackages={allBenefitPackages}
          companyId={companyId}
          disabledBenefitIds={disabledBenefitIds}
          managedBy={managedBy}
          nBenefitsSelected={nNewBenefitsSelected}
        />
      </Subsection>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage
            {...commonBenefitMessages.inBenefitPackageEffectiveDate}
          />
        </SubsectionHeader>
        <TextGrid>
          <DescriptionWrapper>
            <FormattedMessage
              {...commonBenefitMessages.inBenefitPackageEffectiveDateDescription}
            />
          </DescriptionWrapper>
        </TextGrid>
        <Grid>
          <GridCell33>
            <DatePickerField
              dense
              gridMargin
              label={
                <FormattedMessage
                  {...commonBenefitMessages.inBenefitPackageEffectiveDateLabel}
                />
              }
              min={getEarliestRetroactiveDate()}
              name="effectiveFrom"
              required
              type="month"
            />
          </GridCell33>
        </Grid>
      </Subsection>
      {submissionError && <GraphQlError error={submissionError} />}
      <ButtonLayout>
        <Button
          filled
          disabled={!dirty || !isValid}
          loading={isSubmitting}
          type="submit"
        >
          <FormattedMessage {...formMessages.addBenefits} />
        </Button>
      </ButtonLayout>
    </Form>
  );
};
