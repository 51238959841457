import gql from 'graphql-tag';

export const UPDATE_IMPORT_MUTATION = gql`
  mutation updateImportMutation($input: UpdateImportInput!) {
    updateImport(input: $input) {
      import {
        id
      }
    }
  }
`;
