import { Card, cardLinkCss } from '@frontend/ui';
import { Link } from 'components/Links/Link';
import React from 'react';
import { styled } from 'styled-components';

import { OpenStatus } from '..';
import { NotificationCard } from './NotificationCard';

export const NotificationsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export interface Notification {
  createdAt: Date;
  description: string;
  key: string;
  link: string | null;
  onClick: () => Promise<void>;
  status: OpenStatus;
  title: string;
  author?: string;
  statusUpdatedAt?: Date;
}

interface Props {
  notifications: Notification[];
}

const StyledLink = styled(Link)`
  ${cardLinkCss}
  overflow: hidden;
`;

export const NotificationsList: React.FC<Props> = ({ notifications }) => (
  <NotificationsWrapper>
    {notifications.map(n => (
      <Card key={n.key}>
        {n.link && n.status !== OpenStatus.DISMISSED ? (
          <StyledLink to={n.link}>
            <NotificationCard notification={n} />
          </StyledLink>
        ) : (
          <NotificationCard notification={n} />
        )}
      </Card>
    ))}
  </NotificationsWrapper>
);
