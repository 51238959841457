import { Formik } from '@frontend/formik';
import { PageLede, Section } from '@frontend/ui';
import {
  createJobOfferLayoutQuery,
  createJobOfferLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { FormValues, JobOfferForm, validationSchema } from '../form';
import { initialValues as benefitsFieldsInitialValues } from '../form/benefits';
import { initialValues as compensationFieldsInitialValues } from '../form/compensation';
import { initialValues as informationFieldsInitialValues } from '../form/information';
import { initialValues as settingsInitialValues } from '../form/settings';
import { jobOfferMessages } from '../messages';
import { useSubmit } from '../utils/use-submit';
import { CREATE_JOB_OFFER_LAYOUT_QUERY } from './graphql/queries';

export const Create: React.FC = () => {
  const intl = useIntl();
  const submit = useSubmit();
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  const { data } = useQuery<
    createJobOfferLayoutQuery,
    createJobOfferLayoutQueryVariables
  >(CREATE_JOB_OFFER_LAYOUT_QUERY, {
    variables: { id: companyId },
    errorPolicy: 'all',
    suspend: true,
  });

  return (
    <Section>
      <PageLede>
        <TextGrid>
          <FormattedMessage {...jobOfferMessages.jobOfferDescr} />
        </TextGrid>
      </PageLede>
      <Formik<FormValues>
        validateOnMount
        initialValues={{
          ...informationFieldsInitialValues,
          ...compensationFieldsInitialValues,
          ...benefitsFieldsInitialValues,
          ...settingsInitialValues,
          ...(data?.company?.logoUrl
            ? { profilePicture: { fallbackUrl: data.company.logoUrl } }
            : {}),
        }}
        onSubmit={submit}
        validationSchema={validationSchema(intl)}
      >
        <JobOfferForm />
      </Formik>
    </Section>
  );
};
