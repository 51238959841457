import { Icon, Section, SectionHeader, Td, TextField, Tr } from '@frontend/ui';
import { addCircle, search as searchIcon } from '@frontend/ui/icons';
import {
  companyCustomTypesQuery,
  companyCustomTypesQueryVariables,
  SUPER_TYPE,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { unitCodeMessages } from '../../utils/constants';
import { AccountPlanTable, AccountPlanTableRow } from '../components/Table';
import { LocationState } from '../create-custom-type';
import { COMPANY_CUSTOM_TYPES_QUERY } from '../graphql/queries';
import { accountPlanMessages } from '../messages';
import { useDebouncedSearch } from '../utils/use-debounced-search';
import { updateQuery } from './utils/update-query';

export const BalanceTypes: React.FC = () => {
  const { formatMessage } = useIntl();
  const { pathname, state } = useLocation<LocationState>();

  const suspend = useRef(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );
  const { search, debouncedSearch, setSearch } = useDebouncedSearch();

  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { data, loading, refetch, fetchMore, previousData } = useQuery<
    companyCustomTypesQuery,
    companyCustomTypesQueryVariables
  >(COMPANY_CUSTOM_TYPES_QUERY, {
    suspend: suspend.current,
    variables: {
      companyId,
      superType: SUPER_TYPE.BALANCE,
      search: debouncedSearch,
      first: Number(perPage),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      suspend.current = false;
    },
  });

  useEffect(() => {
    if (state?.refetch === SUPER_TYPE.BALANCE) {
      refetch();
    }
  }, [state]);

  const customBalanceTypesConnection =
    data?.company?.customTypes ?? previousData?.company?.customTypes;

  const customBalanceTypes = customBalanceTypesConnection?.edges.map(
    edge => edge.node,
  );

  return (
    <>
      {loading && <TopLoading />}
      {customBalanceTypesConnection && (
        <Section>
          <SectionHeader>
            <FormattedMessage {...accountPlanMessages.balanceTypes} />
          </SectionHeader>
          <TextField
            leadingIcon={<Icon icon={searchIcon} decorative />}
            placeholder={formatMessage(
              accountPlanMessages.searchRemunerationTypes,
            )}
            onChange={event => setSearch(event.target.value)}
            value={search}
          />
          <ChipsWrapper belowSearch>
            <AssistChip
              text={
                <FormattedMessage {...accountPlanMessages.createBalanceType} />
              }
              leadingIcon={addCircle}
              to={{
                pathname,
                search: qs.stringify({
                  create: SUPER_TYPE.BALANCE,
                }),
              }}
            />
          </ChipsWrapper>
          <AccountPlanTable
            columns={[
              formatMessage(accountPlanMessages.codeColumnLabel),
              formatMessage(accountPlanMessages.nameColumnLabel),
              formatMessage(accountPlanMessages.unitCodeColumnLabel),
              formatMessage(accountPlanMessages.currencyColumnLabel),
            ]}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: customBalanceTypesConnection.pageInfo.endCursor,
                },
                updateQuery,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: customBalanceTypesConnection.pageInfo.startCursor,
                  first: undefined,
                  last: Number(perPage),
                },
                updateQuery,
              });
            }}
            perPage={perPage}
            setPerPage={setPerPage}
            pageInfo={customBalanceTypesConnection.pageInfo}
          >
            {!customBalanceTypes?.length ? (
              <Tr>
                <Td colSpan={4}>
                  <FormattedMessage
                    {...accountPlanMessages.noBalanceTypes}
                    values={{ hasSearch: !!debouncedSearch }}
                  />
                </Td>
              </Tr>
            ) : (
              customBalanceTypes.map(type => (
                <AccountPlanTableRow key={type.id}>
                  <Td>{type.id}</Td>
                  <Td>{type.name ?? <NoValue />}</Td>
                  <Td>
                    {type.unitCode ? (
                      <FormattedMessage
                        select={type.unitCode}
                        messages={unitCodeMessages}
                      />
                    ) : (
                      <NoValue />
                    )}
                  </Td>
                  <Td>{type.currency ?? <NoValue />}</Td>
                </AccountPlanTableRow>
              ))
            )}
          </AccountPlanTable>
        </Section>
      )}
    </>
  );
};
