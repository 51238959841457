import { useFormikObserver } from '@frontend/formik';
import { formMessages } from 'app/messages/form';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import React from 'react';
import {
  LinkProps,
  // eslint-disable-next-line no-restricted-imports
  useHistory,
  useLocation,
} from 'react-router-dom';

interface SafeOnClick {
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

/**
 * Returns a safe onClick handler that prompts the user with a confirmation
 * dialog if the onClick is invoked when there are unsaved form changes on the current page.
 */
export const useSafeOnClick = (to: LinkProps['to']): SafeOnClick => {
  const { hasDirtyFields } = useFormikObserver();
  const location = useLocation();
  const { confirm } = useConfirm();
  const { push } = useHistory();
  const { formatMessage } = useIntl();

  const onClick = async (event): Promise<void> => {
    if (hasDirtyFields) {
      event.preventDefault();
      const confirmation = await confirm({
        title: formatMessage(formMessages.confirmLeaveFormTitle),
        description: formatMessage(formMessages.confirmLeaveFormDescription),
      });

      if (confirmation) {
        if (typeof to === 'function') {
          push(to(location));
          return;
        }

        push(to);
      }
    }
  };

  return { onClick };
};
