import { companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge_node_FlexFcfwRequest_membership_Membership as Membership } from 'app/apollo/graphql/types';

export enum FcfwCheckboxGroupStatus {
  CHECKED = 'CHECKED',
  INDETERMINATE = 'INDETERMINATE',
}

export interface SimpleMembership {
  companyId: string;
  userAccountId: string;
}

export interface RequestEmployee {
  companyId: string;
  membership: Membership;
  possibleAssertionDates: string[];
  requestedDate: string;
  userAccountId: string;
}
