import { Subsection, SubsectionHeader, Table, Td, Tr } from '@frontend/ui';
import {
  smeCompanyBenefitPackageDetailsQuery,
  smeCompanyBenefitPackageDetailsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import {
  smeBenefitsMessages,
  smeCompilationMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import { useParams } from 'react-router';

import { PremiumMatrixTable } from '../../../../PremiumMatrixTable';
import { BenefitsTable } from './BenefitsTable';
import { EmployeesTable } from './EmployeesTable';
import { SME_COMPANY_BENEFIT_PACKAGE_DETAILS_QUERY } from './graphql/queries';
import { getPremiumMatrix } from './utils';

export const BenefitPackageDetails = () => {
  const { proposalId } = useParams<ProposalMatchParams>();

  const { data, loading } = useQuery<
    smeCompanyBenefitPackageDetailsQuery,
    smeCompanyBenefitPackageDetailsQueryVariables
  >(SME_COMPANY_BENEFIT_PACKAGE_DETAILS_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: { id: proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.benefitPackages.length) {
    return (
      <Table size="small">
        <tbody>
          <Tr>
            <Td>
              <FormattedMessage
                {...smeBenefitsMessages.noBenefitPackagesLabel}
              />
            </Td>
          </Tr>
        </tbody>
      </Table>
    );
  }

  const { benefitPackages } = data.proposal;

  return (
    <>
      {benefitPackages.map(benefitPackage => {
        const premiumMatrix = getPremiumMatrix(benefitPackage.benefits);
        return (
          <React.Fragment key={benefitPackage.id}>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage
                  {...smeCompilationMessages.benefitPackageTitle}
                  values={{ name: benefitPackage.name }}
                />
              </SubsectionHeader>
              <BenefitsTable benefits={benefitPackage.benefits} />
            </Subsection>
            {premiumMatrix && (
              <Subsection>
                <PremiumMatrixTable matrix={premiumMatrix} />
              </Subsection>
            )}
            <Subsection>
              <EmployeesTable
                employees={benefitPackage.memberships}
                cost={benefitPackage.cost}
              />
            </Subsection>
          </React.Fragment>
        );
      })}
    </>
  );
};
