import { salaryExchangesTableQuery as Query } from 'app/apollo/graphql/types';

interface FetchMore {
  fetchMoreResult?: Query;
}

export const updateQuery = (
  previousResult: Query,
  { fetchMoreResult }: FetchMore,
) => {
  if (!previousResult?.salaryExchanges || !fetchMoreResult?.salaryExchanges) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.salaryExchanges;

  return {
    salaryExchanges: {
      ...previousResult.salaryExchanges,
      pageInfo,
      edges,
    },
  };
};
