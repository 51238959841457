import gql from 'graphql-tag';

export const FITNESS_CONTRIBUTION_CARD_QUERY = gql`
  query fitnessContributionCardQuery(
    $userAccountId: ID!
    $benefitTypeNames: [String!]
    $companyId: ID!
  ) {
    company(id: $companyId) {
      id
      totalCompensationConfiguration {
        companyId
        wellness
      }
    }
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      fitnessContributionBalance
    }
    entitlements(
      companyId: $companyId
      userAccountId: $userAccountId
      benefitTypeNames: $benefitTypeNames
    ) {
      edges {
        node {
          benefit {
            id
            type
            content {
              locale
            }
            configuration {
              data
            }
          }
        }
      }
    }
  }
`;
