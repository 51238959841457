import gql from 'graphql-tag';

export const ADD_ACCOUNTING_DIMENSION_MUTATION = gql`
  mutation addAccountingDimensionMutation($input: AddAccountingDimensionInput) {
    addAccountingDimension(input: $input) {
      id
    }
  }
`;

export const UPDATE_ACCOUNTING_DIMENSION_MUTATION = gql`
  mutation updateAccountingDimensionMutation(
    $input: UpdateAccountingDimensionInput
  ) {
    updateAccountingDimension(input: $input) {
      id
    }
  }
`;

export const ADD_ACCOUNTING_OBJECT_MUTATION = gql`
  mutation addAccountingObjectMutation($input: AddAccountingObjectInput) {
    addAccountingObject(input: $input) {
      id
    }
  }
`;

export const UPDATE_ACCOUNTING_OBJECT_MUTATION = gql`
  mutation updateAccountingObjectMutation($input: UpdateAccountingObjectInput) {
    updateAccountingObject(input: $input) {
      id
    }
  }
`;
