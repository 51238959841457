export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z',
      },
    },
  ],
};
