import gql from 'graphql-tag';

import { employmentActivityDetails } from '../../graphql/fragments';

export const ADD_EMPLOYMENT_ACTIVITY_MUTATION = gql`
  mutation addEmploymentActivityMutation($input: AddEmploymentActivityInput!) {
    addEmploymentActivity(input: $input) {
      employmentActivity {
        ...employmentActivityDetails
      }
    }
  }
  ${employmentActivityDetails}
`;
