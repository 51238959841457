import {
  employeeSalaryExchangeQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange as SalaryExchange,
  FlexSalaryExchangeProvider,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employeePensionMessages } from 'app/messages/employees';
import {
  salaryExchangeProviderMessages,
  salaryExchangeStatusMessages,
} from 'app/messages/salary-exchange';
import { FUTUR_PENSION } from 'app/utils/constants';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
} from 'components/formats';
import { ListTable, ListTableItem } from 'components/ListTable';
import { NoValue } from 'components/NoValue';
import React from 'react';

import { StatusDot } from '../StatusDot';

interface Props {
  salaryExchange: SalaryExchange;
}

export const SalaryExchangeTable: React.FC<Props> = ({ salaryExchange }) => (
  <ListTable>
    <tbody>
      <ListTableItem label={<FormattedMessage {...commonMessages.status} />}>
        <StatusDot
          item={salaryExchange}
          label={
            <FormattedMessage
              select={salaryExchange.status}
              messages={salaryExchangeStatusMessages}
              values={{
                effectiveDate: (
                  <FormattedDate value={salaryExchange.effectiveDate} />
                ),
              }}
            />
          }
        />
      </ListTableItem>
      <ListTableItem
        label={<FormattedMessage {...employeePensionMessages.deduction} />}
      >
        <FormattedCurrency
          value={salaryExchange.deductionValue}
          currency="SEK"
          after="PER_MONTH"
        />
      </ListTableItem>
      <ListTableItem
        label={<FormattedMessage {...employeePensionMessages.premium} />}
      >
        <FormattedCurrency
          value={salaryExchange.premium}
          currency="SEK"
          after="PER_MONTH"
        />
      </ListTableItem>
      <ListTableItem
        label={<FormattedMessage {...commonMessages.management} />}
      >
        <FormattedMessage
          select={salaryExchange.provider}
          messages={salaryExchangeProviderMessages}
        />
      </ListTableItem>
      {salaryExchange.provider === FlexSalaryExchangeProvider.advinans && (
        <ListTableItem
          label={<FormattedMessage {...commonMessages.insuranceProvider} />}
        >
          {FUTUR_PENSION}
        </ListTableItem>
      )}
      <ListTableItem
        type="number"
        label={<FormattedMessage {...commonMessages.insuranceNumber} />}
      >
        {salaryExchange.insuranceNumber ? (
          salaryExchange.insuranceNumber
        ) : (
          <NoValue />
        )}
      </ListTableItem>
      <ListTableItem
        label={<FormattedMessage {...commonMessages.effectiveFrom} />}
      >
        <FormattedDate value={salaryExchange.effectiveDate} />
      </ListTableItem>
    </tbody>
  </ListTable>
);
