import { insuranceEventsTableQuery } from 'app/apollo/graphql/types';

export const updateInsuranceEventsTableQuery = (
  previousResult: insuranceEventsTableQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: insuranceEventsTableQuery;
  },
) => {
  if (
    !previousResult?.membership?.changesHistory ||
    !fetchMoreResult?.membership?.changesHistory
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.membership.changesHistory;

  return {
    ...previousResult,
    membership: {
      id: previousResult.membership.id,
      changesHistory: {
        edges: [...edges],
        pageInfo,
      },
    },
  };
};
