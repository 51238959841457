import { Chip, Icon } from '@frontend/ui';
import { restartAlt, tune } from '@frontend/ui/icons';
import { advisorMessages } from 'app/messages/advisor';
import { FormattedMessage } from 'components/formats';
import React from 'react';

export const AdviceFilterChips = (props: {
  clearFilter: () => void;
  toggleFilterSideSheet: () => void;
}) => (
  <>
    <Chip
      clickable
      text={<FormattedMessage {...advisorMessages.clearFilter} />}
      leadingIcon={<Icon icon={restartAlt} color="primary" decorative />}
      onClick={props.clearFilter}
    />
    <Chip
      clickable
      text={<FormattedMessage {...advisorMessages.allFilters} />}
      leadingIcon={<Icon icon={tune} color="primary" decorative />}
      onClick={props.toggleFilterSideSheet}
    />
  </>
);
