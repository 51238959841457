import { ContentContainer, PageLede, Section, Subsection } from '@frontend/ui';
import {
  companyFullyCapableForWorkRequestsQuery,
  companyFullyCapableForWorkRequestsQueryVariables,
} from 'app/apollo/graphql/types';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useCurrentUser } from 'contexts/current-permissions';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { FcfwInfoCollapsible } from 'features/companies/company/employees/assert-work-capability/components/FcfwInfoCollapsible';
import { AssertWorkCapabilityForm } from 'features/companies/company/employees/assert-work-capability/form';
import { COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY } from 'features/companies/company/employees/assert-work-capability/graphql/queries';
import { workCapabilityMessages } from 'features/companies/company/employees/assert-work-capability/messages';
import { Page } from 'features/page';
import React from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';

interface MatchParams {
  companyId: string;
}

export const AssertWorkCapability: React.FC = () => {
  const { push } = useHistory();
  const { params, url } = useRouteMatch<MatchParams>();
  const { permissions } = useCurrentUser();

  const { data, loading, error } = useQuery<
    companyFullyCapableForWorkRequestsQuery,
    companyFullyCapableForWorkRequestsQueryVariables
  >(COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY, {
    variables: {
      companyId: params.companyId,
    },
    errorPolicy: 'all',
  });

  if (!hasBackstagePermission('flexifits:fcfw', 'assert', permissions)) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return <TopLoading />;
  }

  const hasRequests = !data?.company?.fcfwRequests.edges.length;

  const infoText = data?.company?.fullyCapableForWorkInfo?.infoText;

  const parentLink = `/companies/${params.companyId}/employees`;

  return (
    <NavigationAnchor
      title={workCapabilityMessages.confirmFullWorkCapability}
      url={url}
    >
      <Page
        title={
          <FormattedMessage
            {...workCapabilityMessages.confirmFullWorkCapability}
          />
        }
        parentLink={parentLink}
      >
        <ContentContainer>
          {error && <GraphQlError error={error} />}
          <Section>
            {hasRequests && (
              <PageLede>
                <FormattedMessage
                  {...workCapabilityMessages.confirmFullWorkCapabilityLede}
                />
              </PageLede>
            )}
            {hasRequests && !!infoText && (
              <>
                <Subsection>
                  <FcfwInfoCollapsible infoText={infoText} />
                </Subsection>
                {hasRequests && (
                  <AssertWorkCapabilityForm
                    companyId={params.companyId}
                    requests={data.company.fcfwRequests.edges.map(
                      ({ node }) => node,
                    )}
                    tableHeader={
                      <FormattedMessage
                        {...workCapabilityMessages.employeesToConfirmFullWorkCapability}
                      />
                    }
                    infoText={infoText}
                    onSuccess={() => {
                      push(parentLink);
                    }}
                  />
                )}
              </>
            )}
            {!hasRequests && (
              <EmptyState
                title={
                  <FormattedMessage
                    {...workCapabilityMessages.noEmployeesToAssertWorkCapability}
                  />
                }
              />
            )}
          </Section>
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
