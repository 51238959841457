import { Figure, Icon } from '@frontend/ui';
import { travelExplore } from '@frontend/ui/icons';
import {
  vacationCardQuery,
  vacationCardQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { VACATION_CARD_QUERY } from './graphql/queries';
import { getVacationCardData } from './utils/get-vacation-card-data';

interface CardFigureProps {
  figure: number;
  label: React.ReactNode;
}

interface VacationCardComponentProps {
  vacationDaysRelevant?: CardFigureProps;
  vacationDaysTotal?: CardFigureProps;
}

export const VacationCardComponent: React.FC<VacationCardComponentProps> = ({
  vacationDaysTotal,
  vacationDaysRelevant,
}) => (
  <SnapshotCard
    icon={
      <Icon icon={travelExplore} color="primary" size="medium" decorative />
    }
    title={<FormattedMessage {...totalCompensationMessages.vacation} />}
    figure1={
      vacationDaysTotal && (
        <Figure
          size="medium"
          title={
            <span>
              <FormattedMessage
                {...totalCompensationMessages.xDays}
                values={{
                  count: vacationDaysTotal.figure,
                }}
              />
            </span>
          }
          description={vacationDaysTotal.label}
        />
      )
    }
    figure2={
      vacationDaysRelevant && (
        <Figure
          size="medium"
          title={
            <span>
              <FormattedMessage
                {...totalCompensationMessages.xDays}
                values={{
                  count: vacationDaysRelevant.figure,
                }}
              />
            </span>
          }
          description={vacationDaysRelevant.label}
        />
      )
    }
  />
);

export const VacationCard: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data } = useQuery<vacationCardQuery, vacationCardQueryVariables>(
    VACATION_CARD_QUERY,
    {
      suspend: true,
      errorPolicy: 'all',
      variables: {
        userAccountId,
        companyId,
      },
      isEmpty: d => !getVacationCardData(d),
    },
  );

  const vacationCardData = getVacationCardData(data);

  if (!vacationCardData) {
    return null;
  }

  const {
    vacationDaysPaid,
    vacationDaysSaved,
    vacationDaysUnpaid,
    vacationDaysAdvance,
    vacationDaysPerYear,
    vacationDaysTotalValue,
  } = vacationCardData;

  const vacationDaysTotal: CardFigureProps = {
    figure: vacationDaysTotalValue,
    label: (
      <FormattedMessage
        {...totalCompensationMessages.remaining}
        values={{ count: vacationDaysTotalValue }}
      />
    ),
  };
  const vacationDaysRelevant: CardFigureProps = {
    figure: vacationDaysPerYear || 0,
    label: (
      <FormattedMessage
        {...commonMessages.perYear}
        values={{ count: vacationDaysPerYear || 0 }}
      />
    ),
  };
  if (vacationDaysSaved) {
    vacationDaysRelevant.figure = vacationDaysSaved;
    vacationDaysRelevant.label = (
      <FormattedMessage
        {...totalCompensationMessages.daysSaved}
        values={{ count: vacationDaysSaved }}
      />
    );
  } else if (vacationDaysPaid) {
    vacationDaysRelevant.figure = vacationDaysPaid;
    vacationDaysRelevant.label = (
      <FormattedMessage
        {...totalCompensationMessages.daysPaid}
        values={{ count: vacationDaysPaid }}
      />
    );
  } else if (vacationDaysAdvance) {
    vacationDaysRelevant.figure = vacationDaysAdvance;
    vacationDaysRelevant.label = (
      <FormattedMessage
        {...totalCompensationMessages.daysAdvance}
        values={{ count: vacationDaysAdvance }}
      />
    );
  } else if (vacationDaysUnpaid) {
    vacationDaysRelevant.figure = vacationDaysUnpaid;
    vacationDaysRelevant.label = (
      <FormattedMessage
        {...totalCompensationMessages.daysUnpaid}
        values={{ count: vacationDaysUnpaid }}
      />
    );
  }

  return (
    <CardGridCell>
      <VacationCardComponent
        vacationDaysTotal={vacationDaysTotal}
        vacationDaysRelevant={vacationDaysRelevant}
      />
    </CardGridCell>
  );
};
