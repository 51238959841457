import {
  FlexSalaryExchangeStatus,
  salaryExchangeCardQuery_Query,
} from 'app/apollo/graphql/types';

import { isSalaryExchangeEntitlement } from './is-salary-exchange-entitlement';

interface SalaryExchangeMonthlyPremiumCard {
  monthlyPremium: string;
  benefitId?: never;
}

interface SalaryExchangeBenefitCard {
  benefitId: string;
  monthlyPremium?: never;
}

/**
 * Retrieves the relevant salary exchange card data from the query response
 * or returns null if the user has no access to salary exchange.
 */
export const getSalaryExchangeCardData = (
  data: salaryExchangeCardQuery_Query | undefined,
  totalCompensation?: boolean,
): SalaryExchangeMonthlyPremiumCard | SalaryExchangeBenefitCard | null => {
  if (
    totalCompensation &&
    !data?.company?.totalCompensationConfiguration?.salaryExchange
  ) {
    return null;
  }

  const salaryExchangeEntitlement = data?.entitlements?.edges
    .map(({ node }) => node)
    .find(isSalaryExchangeEntitlement);

  const salaryExchange = data?.salaryExchange;
  const upcomingSalaryExchange = data?.salaryExchangeRequests?.edges[0]?.node;
  const benefit = salaryExchangeEntitlement?.benefit;
  const eligibleForOngoingExchange =
    salaryExchangeEntitlement?.eligibleForOngoingExchange;
  const eligibleForOnetimeExchange =
    salaryExchangeEntitlement?.eligibleForOnetimeExchange;

  const monthlyPremium =
    /* XXX TODO: Potentially remove this if query only returns active exchanges */
    salaryExchange?.status === FlexSalaryExchangeStatus.ACTIVE
      ? salaryExchange?.premium
      : upcomingSalaryExchange
        ? upcomingSalaryExchange?.premium
        : null;

  if (monthlyPremium) {
    return { monthlyPremium };
  }

  if (benefit && (eligibleForOngoingExchange || eligibleForOnetimeExchange)) {
    return { benefitId: benefit.id };
  }

  return null;
};
