import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

import { invoiceDetails } from './fragments';

export const COMPANY_INVOICES_QUERY = gql`
  query companyInvoicesQuery(
    $companyId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $supplier: [InvoiceSupplier!]
    $issueDate: DateFilter
    $dueDate: DateFilter
    $status: [InvoiceStatus!]
    $invoiceNumber: String
    $payableAmount: IntRangeFilter
  ) {
    company(id: $companyId) {
      id
      invoices(
        first: $first
        after: $after
        last: $last
        before: $before
        filter: {
          supplier: $supplier
          issueDate: $issueDate
          dueDate: $dueDate
          status: $status
          invoiceNumber: $invoiceNumber
          payableAmount: $payableAmount
        }
      ) {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            ...invoiceDetails
          }
        }
      }
    }
  }
  ${invoiceDetails}
  ${pageInfoDetails}
`;
