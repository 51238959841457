import { RadioGroupOption } from '@frontend/ui';
import {
  CollectiveAgreementOption,
  updateProposalQuery_Query as Data,
} from 'app/apollo/graphql/types';

import { OnChange } from '../../contexts/autosave';

interface FormValues {
  text: string;
  collectiveAgreementBlueCollar?: CollectiveAgreementOption;
  collectiveAgreementWhiteCollar?: CollectiveAgreementOption;
  documentationProvided?: string;
}

export const getFormValues = (data?: Data): FormValues => ({
  collectiveAgreementBlueCollar:
    data?.proposal?.need?.collectiveAgreementBlueCollar ?? undefined,
  collectiveAgreementWhiteCollar:
    data?.proposal?.need?.collectiveAgreementWhiteCollar ?? undefined,
  documentationProvided:
    data?.proposal?.need?.documentationProvided != null
      ? data.proposal.need.documentationProvided.toString()
      : undefined,
  text: data?.proposal?.need?.text ?? '',
});

export const getOnChangeHandlers = (onChange: OnChange) => ({
  collectiveAgreementBlueCollar: (
    option: RadioGroupOption<CollectiveAgreementOption>,
  ) =>
    onChange({
      cacheValue: {
        need: existingNeed =>
          existingNeed
            ? {
                ...existingNeed,
                collectiveAgreementBlueCollar: option.value,
              }
            : null,
      },
      mutationInput: {
        need: {
          collectiveAgreementBlueCollar: option.value,
        },
      },
    }),
  collectiveAgreementWhiteCollar: (
    option: RadioGroupOption<CollectiveAgreementOption>,
  ) =>
    onChange({
      cacheValue: {
        need: existingNeed =>
          existingNeed
            ? {
                ...existingNeed,
                collectiveAgreementWhiteCollar: option.value,
              }
            : null,
      },
      mutationInput: {
        need: {
          collectiveAgreementWhiteCollar: option.value,
        },
      },
    }),
  documentationProvided: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        need: existingNeed =>
          existingNeed
            ? {
                ...existingNeed,
                documentationProvided: option.value === 'true',
              }
            : null,
      },
      mutationInput: {
        need: {
          documentationProvided: option.value === 'true',
        },
      },
    }),
  text: (value: string) =>
    onChange({
      cacheValue: {
        need: existingNeed =>
          existingNeed
            ? {
                ...existingNeed,
                text: value,
              }
            : null,
      },
      mutationInput: {
        need: {
          text: value,
        },
      },
    }),
});
