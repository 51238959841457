import { Button } from '@frontend/ui';
import {
  companyFullyCapableForWorkRequestsQuery,
  companyFullyCapableForWorkRequestsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React, { useState } from 'react';

import { COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY } from '../../graphql/queries';
import { workCapabilityMessages } from '../../messages';
import { AssertWorkCapabilityModal } from '../../modal';

interface Prop {
  companyId: string;
  userAccountId: string;
}

export const EmployeeFcfwNotification: React.FC<Prop> = ({
  userAccountId,
  companyId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useQuery<
    companyFullyCapableForWorkRequestsQuery,
    companyFullyCapableForWorkRequestsQueryVariables
  >(COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY, {
    variables: {
      companyId,
    },
    errorPolicy: 'all',
  });

  const request = data?.company?.fcfwRequests.edges.find(
    ({ node }) => node.userAccountId === userAccountId,
  )?.node;

  if (!request) {
    return null;
  }

  return (
    <>
      <NotificationCard
        type="warning"
        title={
          <FormattedMessage
            {...workCapabilityMessages.confirmFullWorkCapabilityFor}
            values={{
              name: `${request.membership.givenName} ${request.membership.lastName}`,
            }}
          />
        }
        actions={
          <Button onClick={() => setIsOpen(true)}>
            <FormattedMessage {...commonMessages.continue} />
          </Button>
        }
      >
        <FormattedMessage
          {...workCapabilityMessages.confirmFullWorkCapabilityEmployeesLede}
        />
      </NotificationCard>
      {isOpen && (
        <AssertWorkCapabilityModal
          isOpen={isOpen}
          request={request}
          infoText={data?.company?.fullyCapableForWorkInfo?.infoText ?? ''}
          onRequestClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
