import { Button, ButtonLayout } from '@frontend/ui';
import { restartAlt, tune } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { AssistChip } from 'components/AssistChip';
import { FormattedMessage } from 'components/formats';
import { SideSheet } from 'components/SideSheet';
import { UpdateConfig } from 'features/filter-sheet/utils/use-debounced-filters';
import React, { useState } from 'react';

interface Props {
  children: React.ReactNode;
  clearFilters: (config?: UpdateConfig) => void;
  nSelected: number;
  customFilterChips?: React.ReactNode;
  singleFilterChipLabel?: React.ReactNode;
}

export const SelectionFilter: React.FC<Props> = ({
  children,
  clearFilters,
  customFilterChips,
  nSelected,
  singleFilterChipLabel,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {customFilterChips}
      <AssistChip
        leadingIcon={tune}
        onClick={() => setOpen(!open)}
        text={
          singleFilterChipLabel && nSelected === 1 ? (
            singleFilterChipLabel
          ) : (
            <FormattedMessage
              {...commonMessages.nFiltersSelected}
              values={{ count: nSelected }}
            />
          )
        }
      />
      {nSelected > 0 && (
        <AssistChip
          onClick={() => clearFilters({ skipDebounce: true })}
          text={<FormattedMessage {...commonMessages.resetFilters} />}
          leadingIcon={restartAlt}
        />
      )}
      <SideSheet
        setOpen={setOpen}
        open={open}
        title={<FormattedMessage {...commonMessages.filter} />}
        footer={
          <ButtonLayout align="right">
            <Button text onClick={() => clearFilters()}>
              <FormattedMessage {...commonMessages.clearFilter} />
            </Button>
            <Button filled onClick={() => setOpen(false)}>
              <FormattedMessage {...commonMessages.done} />
            </Button>
          </ButtonLayout>
        }
      >
        {children}
      </SideSheet>
    </>
  );
};
