import { DRAWER_WIDTH, NAVIGATION_RAIL_WIDTH } from '@frontend/ui';
import {
  MOBILE_SCREEN_RESOLUTION,
  SMALL_SCREEN_RESOLUTION,
} from 'app/utils/constants';
import { useIsSmallScreen } from 'app/utils/use-is-small-screen';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router';

interface BrowseHistory {
  clear: () => void;
  pop: () => string | undefined;
  push: (route: string) => void;
  stack: string[];
}

/**
 * Page specific paramteters that are set by the page
 * component and consumed by the global app bar
 */
interface Page {
  parentLink?: string;
}

interface ContextProps {
  browseHistory: BrowseHistory;
  navigationOffset: number;
  page: Page;
  setIsDrawerOpen: (isDrawerOpen: React.SetStateAction<boolean>) => void;
  setPage: (page: Page) => void;
  isDrawerOpen?: boolean;
  isRailOpen?: boolean;
}

export const NavigationContext = createContext<ContextProps>({
  setIsDrawerOpen: (isDrawerOpen: boolean) => isDrawerOpen,
  setPage: () => {},
  browseHistory: {
    pop: () => '',
    push: () => undefined,
    clear: () => undefined,
    stack: [],
  },
  navigationOffset: 0,
  page: {},
});

export const useNavigationContext = (): ContextProps =>
  useContext(NavigationContext);

interface Props {
  children: React.ReactNode;
}

export const NavigationContextProvider: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const isSmallScreen = useIsSmallScreen(SMALL_SCREEN_RESOLUTION);
  const isMobileScreen = useIsSmallScreen(MOBILE_SCREEN_RESOLUTION);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [stack, setStack] = useState<string[]>([]);
  const [page, setPage] = useState<Page>({});

  const push = (route: string) => setStack(_stack => [route, ..._stack]);

  const pop = () => {
    const [route] = stack;
    setStack(([, ...rest]) => rest);
    return route;
  };

  const clear = () => setStack([]);

  useEffect(() => {
    if (isSmallScreen) {
      setIsDrawerOpen(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (!isSmallScreen) {
      setIsDrawerOpen(true);
    }
  }, [isSmallScreen]);

  const isRailOpen = isSmallScreen ? !isMobileScreen : !isDrawerOpen;

  const navigationOffset = isMobileScreen
    ? 0
    : isSmallScreen
      ? NAVIGATION_RAIL_WIDTH
      : isDrawerOpen
        ? DRAWER_WIDTH
        : NAVIGATION_RAIL_WIDTH;

  const value = useMemo(
    () => ({
      isDrawerOpen,
      setIsDrawerOpen,
      setPage,
      isRailOpen,
      navigationOffset,
      page,
      browseHistory: {
        stack,
        push,
        pop,
        clear,
      },
    }),
    [isDrawerOpen, stack, navigationOffset, isRailOpen, page, setPage],
  );

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};
