import { pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance as Insurance } from 'app/apollo/graphql/types';
import { Permissions } from 'contexts/current-permissions';

import { hasPensionPremiumPermission } from './has-pension-premium-permission';

interface Args {
  pensionInsurance?: Insurance;
  permissions?: Permissions;
}

/**
 * @returns true iff pension insurance exists,
 * and the user has permission to update pension premiums.
 */
export const showUpdateSinglePremiumButton = ({
  pensionInsurance,
  permissions,
}: Args): boolean =>
  !!pensionInsurance && hasPensionPremiumPermission(permissions);
