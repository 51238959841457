import { Subsection, SubsectionHeader } from '@frontend/ui';
import { add } from '@frontend/ui/icons';
import {
  smeCompanyRepresentativesQuery,
  smeCompanyRepresentativesQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { smeRepresentativesMessages } from 'features/sme/messages/sme';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { useProposalReadonly } from '../../utils/use-proposal-readonly';
import {
  CreateRepresentativeModal,
  getCreateRepresentativeLink,
} from './create';
import { EditRepresentativeModal } from './edit';
import { SME_COMPANY_REPRESENTATIVES_QUERY } from './graphql/queries';
import { RepresentativesTable } from './Table';

export const Representatives: React.FC = () => {
  const match = useRouteMatch<ProposalMatchParams>();
  const location = useLocation();
  const { params } = match;
  const isReadonly = useProposalReadonly();

  const { data, loading, error } = useQuery<
    smeCompanyRepresentativesQuery,
    smeCompanyRepresentativesQueryVariables
  >(SME_COMPANY_REPRESENTATIVES_QUERY, {
    errorPolicy: 'all',
    variables: { id: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.representatives) {
    return <GraphQlError error={error} />;
  }

  const { representatives } = data.proposal;

  return (
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...smeRepresentativesMessages.title} />
      </SubsectionHeader>
      {error && <GraphQlError error={error} />}
      <ChipsWrapper>
        <AssistChip
          text={
            <FormattedMessage
              {...smeRepresentativesMessages.createRepresentative}
            />
          }
          leadingIcon={add}
          to={getCreateRepresentativeLink(location)}
          disabled={isReadonly}
        />
      </ChipsWrapper>
      <RepresentativesTable representatives={representatives} />
      <CreateRepresentativeModal />
      <EditRepresentativeModal />
    </Subsection>
  );
};
