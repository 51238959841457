import gql from 'graphql-tag';

export const SALARY_EXCHANGES_CARD_QUERY = gql`
  query salaryExchangesCardQuery(
    $companyId: ID!
    $statuses: [FlexSalaryExchangeStatus!]
  ) {
    salaryExchanges(companyId: $companyId, statuses: $statuses) {
      totalCount
    }
  }
`;
