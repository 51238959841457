import { GenerateProposal } from 'features/sme/companies/company/proposals/edit/generate';
import { GenerateProposalDocuments } from 'features/sme/companies/company/proposals/edit/generate/documents';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

export const SmeCompanyProposalGenerateRoute: React.FC<RouteComponentProps> = ({
  match,
}) => (
  <Switch>
    <Route
      component={GenerateProposalDocuments}
      path={`${match.path}/documents`}
    />
    <Route component={GenerateProposal} path={match.path} />
  </Switch>
);
