import { EventTypeCategory } from 'app/apollo/graphql/types';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export interface FilterParams {
  advisor: string;
  benefitPackageIds: Array<string>;
  brokeredByNode: string;
  companyId: string;
  companyIds: Array<string>;
  eventTypeCategories: Array<EventTypeCategory>;
  excludedEventTypeCategories: Array<EventTypeCategory>;
  from: string;
  maxCapital: string;
  minCapital: string;
  sortBy: string;
  sortOrder: string;
  to: string;
}

export type FilterArrayParamKey = {
  [K in keyof FilterParams]: FilterParams[K] extends Array<unknown> ? K : never;
}[keyof FilterParams];

interface UseFilterParams {
  clearFilter: () => void;
  filterParams: FilterParams;
  setFilterArrayParam: (
    key: FilterArrayParamKey,
    element: FilterParams[typeof key][number],
    shouldRemoveElement: boolean,
  ) => void;
  setFilterParam: (params: Partial<FilterParams>) => void;
}

export const useFilterParams = (): UseFilterParams => {
  const history = useHistory();
  const currentQsSearch = qs.parse(history.location.search);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    excludedEventTypeCategories:
      typeof currentQsSearch.excluded_event_type_categories === 'string'
        ? [currentQsSearch.excluded_event_type_categories as EventTypeCategory]
        : (currentQsSearch.excluded_event_type_categories as Array<EventTypeCategory>) ??
          [],
    from: (currentQsSearch.from as string) ?? '',
    to: (currentQsSearch.to as string) ?? '',
    companyId: (currentQsSearch.company as string) ?? '',
    advisor: (currentQsSearch.advisor as string) ?? '',
    benefitPackageIds:
      typeof currentQsSearch.benefit_package_id === 'string'
        ? [currentQsSearch.benefit_package_id]
        : (currentQsSearch.benefit_package_id as Array<string>) ?? [],
    eventTypeCategories:
      typeof currentQsSearch.event_type_categories === 'string'
        ? [currentQsSearch.event_type_categories as EventTypeCategory]
        : (currentQsSearch.event_type_categories as Array<EventTypeCategory>) ??
          [],
    companyIds:
      typeof currentQsSearch.company_ids === 'string'
        ? [currentQsSearch.company_ids]
        : currentQsSearch.company_ids ?? [],
    minCapital: (currentQsSearch.min_capital as string) ?? '',
    maxCapital: (currentQsSearch.max_capital as string) ?? '',
    brokeredByNode: (currentQsSearch.brokered_by_node as string) ?? '',
    sortOrder: (currentQsSearch.sort_order as string) ?? '',
    sortBy: (currentQsSearch.sort_by as string) ?? '',
  });

  useEffect(() => {
    const search = qs.stringify({
      ...currentQsSearch, // keep 'search' parameter intact
      excluded_event_type_categories:
        filterParams.excludedEventTypeCategories || [],
      from: filterParams.from || [],
      to: filterParams.to || [],
      company: filterParams.companyId || [],
      advisor: filterParams.advisor || [],
      benefit_package_id: filterParams.benefitPackageIds || [],
      event_type_categories: filterParams.eventTypeCategories || [],
      company_ids: filterParams.companyIds || [],
      min_capital: filterParams.minCapital || [],
      max_capital: filterParams.maxCapital || [],
      brokered_by_node: filterParams.brokeredByNode || [],
      sort_order: filterParams.sortOrder || [],
      sort_by: filterParams.sortBy || [],
    });

    history.replace({ search });
  }, [filterParams]);

  const setFilterParam = (params: Partial<FilterParams>) => {
    setFilterParams(previousState => ({ ...previousState, ...params }));
  };

  const clearFilter = () => {
    setFilterParam({
      excludedEventTypeCategories: [],
      from: '',
      to: '',
      companyId: '',
      advisor: '',
      benefitPackageIds: [],
      eventTypeCategories: [],
      companyIds: [],
      minCapital: '',
      maxCapital: '',
      brokeredByNode: '',
      sortOrder: '',
      sortBy: '',
    });
  };

  const setFilterArrayParam = (
    key: {
      [K in keyof FilterParams]: FilterParams[K] extends Array<unknown>
        ? K
        : never;
    }[keyof FilterParams],
    element: FilterParams[typeof key][number],
    shouldRemoveElement: boolean,
  ) => {
    const set = new Set([...filterParams[key], element]);

    if (shouldRemoveElement) {
      set.delete(element);
    }

    setFilterParam({ [key]: Array.from(set) });
  };

  return {
    setFilterParam,
    clearFilter,
    setFilterArrayParam,
    filterParams,
  };
};
