import { toNumber } from '@frontend/utils';

import { PremiumIntervalsValidationError } from './contants';

/**
 * Sanitize ages by converting them to numbers and removing empty strings
 */
export const sanitizeAges = (ages: string[]): number[] =>
  ages.reduce<number[]>(
    (acc, age) => (age !== '' ? [...acc, toNumber(age) ?? 0] : acc),
    [],
  );

/**
 * Sanitize base amounts by removing empty strings
 */
export const sanitizeBaseAmounts = (baseAmounts: string[]): string[] =>
  baseAmounts.filter(baseAmount => baseAmount !== '');

/**
 * Validate ages
 * @returns `PremiumIntervalsValidationError` if validation fails
 */
export const validateAges = (
  _ages: number[],
): PremiumIntervalsValidationError | undefined => {
  const agesAscending = _ages.every((age, i) =>
    i === 0 ? true : age > _ages[i - 1],
  );
  if (!agesAscending) {
    return PremiumIntervalsValidationError.AGES_ASCENDING;
  }
  return undefined;
};

/**
 * Validate base amounts
 * @returns `PremiumIntervalsValidationError` if validation fails
 */
export const validateBaseAmounts = (
  _baseAmounts: string[],
): PremiumIntervalsValidationError | undefined => {
  const baseAmountsAscending = _baseAmounts.every((baseAmount, i) => {
    if (i === 0) {
      return true;
    }
    return (toNumber(baseAmount) ?? 0) > (toNumber(_baseAmounts[i - 1]) ?? 0);
  });
  if (!baseAmountsAscending) {
    return PremiumIntervalsValidationError.BASE_AMOUNTS_ASCENDING;
  }
  const baseAmountsNotOver100 = _baseAmounts.every(
    baseAmount => (toNumber(baseAmount) ?? 0) <= 100,
  );
  if (!baseAmountsNotOver100) {
    return PremiumIntervalsValidationError.BASE_AMOUNT_NOT_OVER_100;
  }
  return undefined;
};

/**
 * Validate premiums are not over 100
 * @returns `true` if intervals are valid, otherwise `false`
 */
export const premiumIsNotOver100 = (premium: string): boolean =>
  (toNumber(premium) ?? 0) <= 100;

/**
 * Trim and pad intervals to match the number of base amounts and ages
 * @returns the intervals array with the correct number of elements.
 */
export const trimAndPadIntervals = (
  ages: readonly number[],
  baseAmounts: readonly string[],
  intervals: readonly (readonly string[])[],
): string[][] =>
  new Array(ages.length)
    .fill(new Array(baseAmounts.length).fill('0'))
    .map((ageInterval, ageIndex) =>
      ageInterval.map(
        (_, baseAmountIndex) => intervals[ageIndex]?.[baseAmountIndex] ?? '0',
      ),
    );
