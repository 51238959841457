import {
  ProposalBenefitType,
  smeCompanyPensionFeesQuery,
  smeCompanyPensionFeesQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useParams } from 'react-router';

import { FeesTable } from './FeesTable';
import { SME_COMPANY_PENSION_FEES_QUERY } from './graphql/queries';

export const PensionFees = () => {
  const { proposalId } = useParams<ProposalMatchParams>();

  const { data, loading } = useQuery<
    smeCompanyPensionFeesQuery,
    smeCompanyPensionFeesQueryVariables
  >(SME_COMPANY_PENSION_FEES_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: { id: proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.fees) {
    return null;
  }

  const { fees } = data.proposal;

  const hasPensionBenefit = data.proposal.benefitPackages
    .flatMap(({ benefits }) => benefits)
    .some(
      ({ type }) =>
        type === ProposalBenefitType.EMPLOYERS_PENSION ||
        type === ProposalBenefitType.OCCUPATIONAL_PENSION,
    );

  return <FeesTable fees={fees} hasPensionBenefit={hasPensionBenefit} />;
};
