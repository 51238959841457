import { Section } from '@frontend/ui';
import {
  salaryReportsQuery,
  salaryReportsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { GraphQlError } from 'components/GraphQlError';
import { NoData } from 'components/NoData';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import { SALARY_REPORTS_QUERY } from 'features/companies/graphql/queries';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { SalaryReportsTable } from './components/SalaryReportsTable';
import { updateSalaryReportsQuery } from './lib';

export const SalaryReports: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({
  location: { search },
  match: {
    params: { companyId },
  },
}) => {
  const { per_page: perPage } = qs.parse(search);
  const {
    previousData,
    data: _data,
    loading,
    error,
    fetchMore,
  } = useQuery<salaryReportsQuery, salaryReportsQueryVariables>(
    SALARY_REPORTS_QUERY,
    {
      variables: {
        companyId,
        first: perPage ? parseInt(perPage, 10) : DEFAULT_RESULT_PER_PAGE,
      },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  );

  const data = _data ?? previousData;

  if (!data && loading) {
    return <TopLoading />;
  }

  if (!data?.company) {
    return <GraphQlError error={error} />;
  }

  const { salaryReports } = data.company;
  const { pageInfo } = salaryReports;
  const { startCursor, endCursor } = pageInfo;
  const reports = salaryReports.edges.map(edge => edge.node);

  return (
    <Section>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      {!reports.length ? (
        <NoData />
      ) : (
        <SalaryReportsTable
          reports={reports}
          navigation={
            <TableNavigation
              pageInfo={pageInfo}
              onNextPage={() => {
                fetchMore({
                  variables: {
                    after: endCursor,
                  },
                  updateQuery: updateSalaryReportsQuery,
                });
              }}
              onPreviousPage={() => {
                fetchMore({
                  variables: {
                    before: startCursor,
                    last: perPage
                      ? parseInt(perPage, 10)
                      : DEFAULT_RESULT_PER_PAGE,
                  },
                  updateQuery: updateSalaryReportsQuery,
                });
              }}
            />
          }
        />
      )}
    </Section>
  );
};
