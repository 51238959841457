import gql from 'graphql-tag';

export const CREATE_COMPANY_QUERY = gql`
  query createCompanyQuery {
    nlpSalesReps {
      id
      firstName
      lastName
      externalId
    }
  }
`;
