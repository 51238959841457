import gql from 'graphql-tag';

export const CREATE_BENEFIT_PACKAGE_MUTATION = gql`
  mutation createBenefitPackageMutation($input: CreateBenefitPackageInput!) {
    createBenefitPackage(input: $input) {
      benefitPackage {
        id
        name
      }
    }
  }
`;
