import gql from 'graphql-tag';

export const CREATE_COMPANY_MUTATION = gql`
  mutation createCompany2Mutation($input: CreateCompany2Input!) {
    createCompany2(input: $input) {
      company {
        id
      }
    }
  }
`;

export const CREATE_PRE_SIGNED_AGREEMENTS_MUTATION = gql`
  mutation createPreSignedAgreementsMutation(
    $input: CreatePreSignedAgreementsInput!
  ) {
    createPreSignedAgreements(input: $input) {
      agreements {
        id
      }
    }
  }
`;
