import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { Modal } from 'components/Modal';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import React from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const ConversionModal: React.FC<Props> = ({ isOpen, setIsOpen }) => (
  <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
    <ModalHeader>
      <FormattedMessage {...smeBenefitFormMessages.conversion} />
    </ModalHeader>
    <ModalBody>
      <FormattedMessage {...smeBenefitFormMessages.conversionExplanation} />
    </ModalBody>
    <ModalFooter>
      <ButtonLayout align="right">
        <Button text onClick={() => setIsOpen(false)}>
          <FormattedMessage {...commonMessages.cancel} />
        </Button>
      </ButtonLayout>
    </ModalFooter>
  </Modal>
);
