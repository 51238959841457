import { Td, Tr } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import React from 'react';

export const EmptyTable = (props: {
  noMatchMessage: { id: string; defaultMessage: string };
}) => (
  <tbody>
    <Tr>
      <Td>
        <FormattedMessage {...props.noMatchMessage} />
      </Td>
    </Tr>
  </tbody>
);
