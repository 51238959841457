import gql from 'graphql-tag';

const employmentActivityDetails = gql`
  fragment employmentActivityDetails on EmploymentActivity {
    id
    type
    period
    rate
  }
`;

const employmentSalaryDetails = gql`
  fragment employmentSalaryDetails on EmploymentSalary {
    monthlySalary
    effectiveDate
  }
`;

export const employmentDetails = gql`
  fragment employmentDetails on Employment {
    id
    period
    rate
    membership {
      id
      userAccountId
      givenName
      lastName
    }
    activities {
      edges {
        node {
          ...employmentActivityDetails
        }
      }
    }
    salaries {
      edges {
        node {
          ...employmentSalaryDetails
        }
      }
    }
  }
  ${employmentActivityDetails}
  ${employmentSalaryDetails}
`;
