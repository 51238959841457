import gql from 'graphql-tag';

export const organizationalUnitDetails = gql`
  fragment organizationalUnitDetails on OrganizationalUnit {
    id
    name
    slug
    path
    unitOf {
      id
      name
    }
    memberships {
      edges {
        node {
          id
          __typename
        }
      }
    }
  }
`;
