import { employeesWithTransferableCapitalQuery } from 'app/apollo/graphql/types';

export const updateEmployeesWithTransferableCapitalQuery = (
  previousResult: employeesWithTransferableCapitalQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: employeesWithTransferableCapitalQuery;
  },
) => {
  if (
    !previousResult?.employeesWithTransferableCapital ||
    !fetchMoreResult?.employeesWithTransferableCapital
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.employeesWithTransferableCapital;

  return {
    employeesWithTransferableCapital: {
      __typename: previousResult.employeesWithTransferableCapital.__typename,
      edges: [...edges],
      pageInfo,
    },
  };
};
