import { IconContent } from '@frontend/ui';
import { check, cloudOff, sync } from '@frontend/ui/icons';

import { AutosaveStatus } from '..';

export const STATUS_ICON: Record<AutosaveStatus, IconContent> = {
  SAVING: sync,
  RECONNECTING: cloudOff,
  SAVED: check,
};
