import gql from 'graphql-tag';

export const ACCOUNTING_DIMENSIONS_QUERY = gql`
  query accountingDimensionsQuery($companyId: ID!, $id: String) {
    accountingDimensions(companyId: $companyId, id: $id) {
      id
      identifier
      name
    }
  }
`;

export const ACCOUNTING_OBJECT_QUERY = gql`
  query accountingObjectQuery($companyId: ID!, $dimensionId: ID!, $id: ID!) {
    accountingObject(
      companyId: $companyId
      dimensionId: $dimensionId
      id: $id
    ) {
      id
      name
      identifier
    }
  }
`;
