import gql from 'graphql-tag';

export const SME_EDIT_COMPANY_QUERY = gql`
  query smeEditCompanyQuery($id: ID!) {
    company(id: $id) {
      id
      hasCompanyGroup
      accountNumber
      clearingCode {
        code
        displayName
      }
      displayName
      registrationNumber
      nlpSalesRep {
        id
        firstName
        lastName
      }
      legalEntityType
      salaryReviewMonth
      accountClosureMonth
      address {
        postalCode
        line1
        city
      }
      paymentMethod
    }
    nlpSalesReps {
      id
      firstName
      lastName
      externalId
    }
    proposalContactPerson(companyId: $id) {
      firstName
      lastName
      personalIdentityNumber
      email
      phoneNumber
    }
    proposalHeadAdmin(companyId: $id) {
      firstName
      lastName
      personalIdentityNumber
      email
      phoneNumber
    }
  }
`;
