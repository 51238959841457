import { Table, Td, Th, Tr } from '@frontend/ui';
import { people } from '@frontend/ui/icons';
import { formatRegistrationNumber } from '@frontend/utils';
import {
  overlappingEmploymentsQuery,
  overlappingEmploymentsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, suffixMessages } from 'app/messages/common';
import { employeesImportMessages } from 'app/messages/employees';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { CollapsibleSimple } from 'components/CollapsibleSimple';
import { FormattedMessage, useIntl } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { useNavigationStackUtils } from 'components/NavigationDrawer/lib/use-navigation-stack-utils';
import { TableNavigation } from 'components/TableNavigation';
import React, { useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { MatchParams } from '..';
import { OVERLAPPING_EMPLOYMENTS_QUERY } from './graphql/queries';
import { updateOverlappingEmployments } from './graphql/update-overlapping-employments';

interface Props {
  importId: string;
}

export const OverlappingEmployments: React.FC<Props> = ({ importId }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  const utils = useNavigationStackUtils();
  const { formatMessage } = useIntl();
  const suspend = useRef(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const { data, previousData, fetchMore } = useQuery<
    overlappingEmploymentsQuery,
    overlappingEmploymentsQueryVariables
  >(OVERLAPPING_EMPLOYMENTS_QUERY, {
    suspend: suspend.current,
    onCompleted: () => {
      suspend.current = false;
    },
    errorPolicy: 'all',
    variables: {
      id: importId,
      first: Number(perPage),
    },
  });

  const overlappingEmployments =
    data?.import?.analysis?.overlappingEmployments ??
    previousData?.import?.analysis?.overlappingEmployments;

  if (!overlappingEmployments?.edges.length) {
    return null;
  }

  const { pageInfo, totalCount } = overlappingEmployments;

  const employments = overlappingEmployments.edges.map(({ node }) => ({
    id: node.membership.userAccountId,
    name: `${node.membership.givenName} ${node.membership.lastName}`,
    nids: node.membership.naturalPersonIdentifiers,
    company: {
      id: node.membership.company.id,
      displayName: `${
        node.membership.company.displayName
      } (${formatRegistrationNumber(
        node.membership.company.registrationNumber,
      )})`,
    },
  }));

  return (
    <CollapsibleSimple
      label={
        <FormattedMessage
          {...employeesImportMessages.overlappingEmploymentsLabel}
        />
      }
      trailingLabel={`${totalCount} ${formatMessage(suffixMessages.pieces)}`}
      icon={people}
    >
      <FormattedMessage
        {...employeesImportMessages.overlappingEmploymentsLede}
      />
      <Table
        fixed
        navigation={
          <TableNavigation
            perPage={perPage}
            setPerPage={setPerPage}
            pageInfo={pageInfo}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: pageInfo.endCursor,
                },
                updateQuery: updateOverlappingEmployments,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: pageInfo.startCursor,
                  first: undefined,
                  last: Number(perPage),
                },
                updateQuery: updateOverlappingEmployments,
              });
            }}
          />
        }
      >
        <colgroup>
          <col style={{ width: '40%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '40%' }} />
        </colgroup>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th type="number">
              <FormattedMessage {...commonMessages.personalIdentityNumber} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.company} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {employments.map(({ name, nids, company, id }, key) => (
            <Tr
              linked
              key={key}
              onClick={() => {
                utils.push(pathname);
                push(`/companies/${companyId}/employees/${id}/employments`);
              }}
            >
              <Td>{name}</Td>
              <Td type="number">
                <FormattedNaturalPersonIdentifier nids={nids} />
              </Td>
              <Td>{company.displayName}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </CollapsibleSimple>
  );
};
