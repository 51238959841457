import {
  CheckboxField,
  CheckboxGroupField,
  DatePickerField,
  Form,
  NumberField,
  SelectField,
  useFormikContext,
} from '@frontend/formik';
import { Button, Section, Subsection, SubsectionHeader } from '@frontend/ui';
import {
  companyAgreementsQuery_nlpSalesReps_AdminUser as SalesRep,
  CompanySize,
  EAGruppAgreementType,
  NLPFundFeeDiscountType,
  NLPPaymentMethodType,
  NLPResponsible,
} from 'app/apollo/graphql/types';
import { suffixMessages, validationMessages } from 'app/messages/common';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import {
  companyMessages,
  companySizeMessages,
} from 'features/companies/messages';
import {
  ClearingFieldType,
  SelectClearingField,
} from 'features/sme/companies/company/select-clearing';
import React from 'react';
import { getSelectComponentMonthOptions } from 'utils/get-select-month-options';
import * as Yup from 'yup';

export enum EuroAccidentAgreementType {
  eaGrupp = 'eaGrupp',
  eaTjp = 'eaTjp',
}

interface Props {
  nlpSalesReps: SalesRep[];
  /**
   * Indicates if the company already has a Euro Accident Grupp agreement. If true
   * Nordea fields will be disabled and omitted from Yup validation and submission.
   */
  hasEuroAccidentGruppAgreement?: boolean;
  /**
   * Indicates if the company already has a Euro Accident TJP agreement. If true
   * Nordea fields will be disabled and omitted from Yup validation and submission
   */
  hasEuroAccidentTjpAgreement?: boolean;
  /**
   * Indicates if the company already has a Nordea agreement. If true
   * Nordea fields will be disabled and omitted from Yup validation and submission
   */
  hasNordeaAgreement?: boolean;
}

type ValidationSchemaArgs = IntlShape & Omit<Props, 'nlpSalesReps'>;

export const validationSchema = (args: ValidationSchemaArgs) => {
  const {
    formatMessage,
    hasEuroAccidentGruppAgreement,
    hasEuroAccidentTjpAgreement,
    hasNordeaAgreement,
  } = args;

  return Yup.object().shape({
    eaCompanySize: Yup.string().when(
      'eaAgreementTypes',
      ([eaAgreementTypes], schema) =>
        (eaAgreementTypes.includes(EuroAccidentAgreementType.eaGrupp) &&
          !hasEuroAccidentGruppAgreement) ||
        (eaAgreementTypes.includes(EuroAccidentAgreementType.eaTjp) &&
          !hasEuroAccidentTjpAgreement)
          ? schema.required(formatMessage(validationMessages.mandatoryField))
          : schema.nullable(),
    ),
    eaGruppAgreementType: Yup.string().when(
      'eaAgreementTypes',
      ([eaAgreementTypes], schema) =>
        eaAgreementTypes.includes(EuroAccidentAgreementType.eaGrupp) &&
        !hasEuroAccidentGruppAgreement
          ? schema.required(formatMessage(validationMessages.mandatoryField))
          : schema.nullable(),
    ),
    eaStartDate: Yup.string().when(
      'eaAgreementTypes',
      ([eaAgreementTypes], schema) =>
        (eaAgreementTypes.includes(EuroAccidentAgreementType.eaGrupp) &&
          !hasEuroAccidentGruppAgreement) ||
        (eaAgreementTypes.includes(EuroAccidentAgreementType.eaTjp) &&
          !hasEuroAccidentTjpAgreement)
          ? schema.required(formatMessage(validationMessages.mandatoryField))
          : schema.nullable(),
    ),
    nordeaSalesRep: Yup.string().when(
      'createNordea',
      ([createNordea], schema) =>
        !!createNordea && !hasNordeaAgreement
          ? schema.required(formatMessage(validationMessages.mandatoryField))
          : schema.nullable(),
    ),
    nordeaStartDate: Yup.string().when(
      'createNordea',
      ([createNordea], schema) =>
        !!createNordea && !hasNordeaAgreement
          ? schema.required(formatMessage(validationMessages.mandatoryField))
          : schema.nullable(),
    ),
    nlpClearingCode: Yup.object().when(
      'createNordea',
      ([createNordea], schema) =>
        !!createNordea && !hasNordeaAgreement
          ? schema.required(formatMessage(validationMessages.mandatoryField))
          : schema.nullable(),
    ),
    nlpClosureMonth: Yup.string().when(
      'createNordea',
      ([createNordea], schema) =>
        !!createNordea && !hasNordeaAgreement
          ? schema.required(formatMessage(validationMessages.mandatoryField))
          : schema.nullable(),
    ),
  });
};

export interface FormValues {
  createNordea: boolean;
  eaAgreementTypes: EuroAccidentAgreementType[];
  eaCompanySize: CompanySize | '';
  eaExtendedFcfw: boolean;
  eaGruppAgreementType: EAGruppAgreementType | '';
  eaStartDate: string;
  nlpClearingCode: ClearingFieldType;
  nlpClosureMonth: string;
  nlpPaymentMethodType: NLPPaymentMethodType.INVOICE;
  nlpResponsible: NLPResponsible;
  nordeaAnnualFeeFund: string;
  nordeaAnnualFeeGuarantee: string;
  nordeaCapitalFeeFund: string;
  nordeaCapitalFeeGuarantee: string;
  nordeaFundFeeDiscountType: NLPFundFeeDiscountType | '';
  nordeaSalesRep: string;
  nordeaStartDate: string;
  submissionError?: string;
}

export const AddAgreementsForm: React.FC<Props> = ({
  nlpSalesReps,
  hasNordeaAgreement,
  hasEuroAccidentGruppAgreement,
  hasEuroAccidentTjpAgreement,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { isSubmitting, isValid, values, errors } =
    useFormikContext<FormValues>();
  const { createNordea, eaAgreementTypes } = values;
  const { submissionError } = errors;

  const createEaGrupp = eaAgreementTypes?.includes(
    EuroAccidentAgreementType.eaGrupp,
  );

  const createEaTjp = eaAgreementTypes?.includes(
    EuroAccidentAgreementType.eaTjp,
  );

  const monthOptions = getSelectComponentMonthOptions({ intl });

  const salesRepOptions =
    nlpSalesReps.map(({ id, firstName, lastName, externalId }) => ({
      label: `${firstName} ${lastName} (${externalId})`,
      value: id,
    })) ?? [];

  return (
    <TextGrid>
      <Form>
        <Section>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...companyMessages.nordeaAgreement} />
            </SubsectionHeader>
            <CheckboxField
              label={formatMessage(companyMessages.createNordea)}
              name="createNordea"
              disabled={hasNordeaAgreement}
            />
            {createNordea && !hasNordeaAgreement && (
              <>
                <SelectField
                  name="nordeaSalesRep"
                  dense
                  options={salesRepOptions}
                  label={formatMessage(companyMessages.nordeaSalesRep)}
                  required
                />
                <SelectClearingField name="nlpClearingCode" required />
                <SelectField
                  name="nlpClosureMonth"
                  dense
                  required
                  options={monthOptions}
                  label={formatMessage(companyMessages.accountClosureMonth)}
                />
                <SelectField
                  label={formatMessage(
                    companyMessages.nordeaFundFeeDiscountType,
                  )}
                  name="nordeaFundFeeDiscountType"
                  dense
                  options={Object.keys(NLPFundFeeDiscountType).map(k => ({
                    value: k,
                    label: k,
                  }))}
                />
                <DatePickerField
                  label={formatMessage(companyMessages.nordeaStartDate)}
                  name="nordeaStartDate"
                  dense
                  required
                />
                <NumberField
                  dense
                  decimalScale={4}
                  affix={formatMessage(suffixMessages.percentage)}
                  name="nordeaCapitalFeeFund"
                  label={formatMessage(companyMessages.nordeaCapitalFeeFund)}
                />
                <NumberField
                  dense
                  decimalScale={4}
                  affix={formatMessage(suffixMessages.percentage)}
                  name="nordeaAnnualFeeFund"
                  label={formatMessage(companyMessages.nordeaAnnualFeeFund)}
                />
                <NumberField
                  dense
                  decimalScale={4}
                  affix={formatMessage(suffixMessages.percentage)}
                  name="nordeaCapitalFeeGuarantee"
                  label={formatMessage(
                    companyMessages.nordeaCapitalFeeGuarantee,
                  )}
                />
                <NumberField
                  dense
                  decimalScale={4}
                  affix={formatMessage(suffixMessages.percentage)}
                  name="nordeaAnnualFeeGuarantee"
                  label={formatMessage(
                    companyMessages.nordeaAnnualFeeGuarantee,
                  )}
                />
              </>
            )}
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...companyMessages.eaAgreement} />
            </SubsectionHeader>
            <CheckboxGroupField
              name="eaAgreementTypes"
              options={[
                {
                  value: EuroAccidentAgreementType.eaGrupp,
                  label: formatMessage(companyMessages.createEaGrupp),
                  disabled: hasEuroAccidentGruppAgreement,
                },
                {
                  value: EuroAccidentAgreementType.eaTjp,
                  label: formatMessage(companyMessages.createEaTjp),
                  disabled: hasEuroAccidentTjpAgreement,
                },
              ]}
            />
            {((createEaGrupp && !hasEuroAccidentGruppAgreement) ||
              (createEaTjp && !hasEuroAccidentTjpAgreement)) && (
              <>
                {(createEaGrupp || createEaTjp) && (
                  <SelectField
                    label={formatMessage(companyMessages.companySize)}
                    name="eaCompanySize"
                    options={Object.keys(CompanySize).map(k => ({
                      value: k,
                      label: formatMessage({
                        messages: companySizeMessages,
                        select: k,
                      }),
                    }))}
                    required
                    dense
                  />
                )}
                {createEaGrupp && (
                  <SelectField
                    label={formatMessage(companyMessages.eaGruppAgreementType)}
                    name="eaGruppAgreementType"
                    options={Object.keys(EAGruppAgreementType).map(k => ({
                      value: k,
                      label: k,
                    }))}
                    required
                    dense
                  />
                )}
                {(createEaGrupp || createEaTjp) && (
                  <>
                    <CheckboxField
                      label={formatMessage(companyMessages.eaExtendedFcfw)}
                      name="eaExtendedFcfw"
                    />
                    <DatePickerField
                      label={formatMessage(companyMessages.eaStartDate)}
                      name="eaStartDate"
                      dense
                      required
                    />
                  </>
                )}
              </>
            )}
          </Subsection>
          {submissionError && (
            <NotificationCard type="error">{submissionError}</NotificationCard>
          )}
          <Button
            filled
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
          >
            <FormattedMessage {...companyMessages.addAgreement} />
          </Button>
        </Section>
      </Form>
    </TextGrid>
  );
};
