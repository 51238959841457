import {
  ProposalBenefitType,
  SortingDirection,
} from 'app/apollo/graphql/types';

interface Benefit {
  name: string;
  type: ProposalBenefitType;
}

export type SortableColumns = 'name';

/**
 * Default benefit sort order
 */
export const DEFAULT_BENEFITS_SORT_ORDER: Record<ProposalBenefitType, number> =
  {
    [ProposalBenefitType.OCCUPATIONAL_PENSION]: 0,
    [ProposalBenefitType.EMPLOYERS_PENSION]: 1,
    [ProposalBenefitType.RISK_SICKNESS]: 2,
    [ProposalBenefitType.RISK_HEALTHCARE]: 3,
    [ProposalBenefitType.RISK_ACCIDENT]: 4,
    [ProposalBenefitType.RISK_TGL]: 5,
    [ProposalBenefitType.RISK_LIFE]: 6,
    [ProposalBenefitType.SALARY_EXCHANGE]: 7,
  };

interface Params {
  order: SortingDirection;
  orderBy: SortableColumns;
}

/**
 * Determines the sort order of benefits accoring to the given parameters.
 * If no parameters are supplied a a predefined default order is returned.
 */
export const by =
  <T extends Benefit>(params?: Params) =>
  (a: T, b: T) => {
    const order = params?.order;
    const orderBy = params?.orderBy;

    if (orderBy === 'name') {
      return order === SortingDirection.ASC
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    }

    return (
      DEFAULT_BENEFITS_SORT_ORDER[a.type] - DEFAULT_BENEFITS_SORT_ORDER[b.type]
    );
  };
