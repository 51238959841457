import {
  Button,
  ButtonLayout,
  SelectField,
  SelectOption,
  TextField,
} from '@frontend/ui';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import {
  SideSheet,
  SideSheetSection,
  SideSheetSubtitle,
} from 'components/SideSheet';
import { FilterParams } from 'features/advisor/utils/use-filter-params';
import React from 'react';

type SideSheetProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  setFilterParam: (params: Partial<FilterParams>) => void;
  clearFilter: () => void;
  filterParams: FilterParams;
  companies: Array<SelectOption>;
};

export const PtStatisticsFilterSideSheet: React.FC<SideSheetProps> = ({
  isOpen,
  setOpen,
  setFilterParam,
  clearFilter,
  filterParams,
  companies,
}) => (
  <SideSheet
    footer={
      <ButtonLayout align="right">
        <Button onClick={clearFilter}>
          <FormattedMessage {...commonMessages.clear} />
        </Button>
        <Button filled onClick={() => setOpen(false)}>
          <FormattedMessage {...commonMessages.done} />
        </Button>
      </ButtonLayout>
    }
    open={isOpen}
    setOpen={setOpen}
    title={<FormattedMessage {...advisorMessages.filters} />}
  >
    <SideSheetSection>
      <SideSheetSubtitle>
        <FormattedMessage {...advisorMessages.period} />
      </SideSheetSubtitle>
      <TextField
        dense
        label={<FormattedMessage {...commonMessages.from} />}
        persistentLabel
        type="date"
        value={filterParams.from}
        onChange={event => {
          setFilterParam({ from: event.target.value });
        }}
      />
      <TextField
        dense
        label={<FormattedMessage {...commonMessages.to} />}
        persistentLabel
        type="date"
        value={filterParams.to}
        onChange={event => {
          setFilterParam({ to: event.target.value });
        }}
      />
    </SideSheetSection>
    <SideSheetSection>
      <SideSheetSubtitle>
        <FormattedMessage {...advisorMessages.chooseCompany} />
      </SideSheetSubtitle>
      <SelectField
        dense
        label={<FormattedMessage {...commonMessages.company} />}
        options={companies}
        value={filterParams.companyId}
        onChange={event => {
          setFilterParam({ companyId: event.detail.value });
        }}
      />
    </SideSheetSection>
  </SideSheet>
);
