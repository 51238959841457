import { companyFullyCapableForWorkRequestsQuery_company_Company_fcfwRequests_FlexFcfwRequestConnection_edges_FlexFcfwRequestEdge_node_FlexFcfwRequest as FullyCapableForWorkRequest } from 'app/apollo/graphql/types';

import { RequestEmployee } from './constants';

export const uniqueEmployeesFromRequests = (
  requests: readonly FullyCapableForWorkRequest[],
): RequestEmployee[] => {
  const uniqueRequest = [...requests]
    .sort((a, b) => (a.requestedDate > b.requestedDate ? -1 : 1))
    .reduce(
      (
        acc,
        {
          companyId,
          userAccountId,
          membership,
          possibleAssertionDates,
          requestedDate,
        },
      ) => ({
        ...acc,
        [userAccountId]: {
          companyId,
          userAccountId,
          membership,
          possibleAssertionDates,
          requestedDate,
        },
      }),
      {},
    );

  return Object.values(uniqueRequest);
};
