import {
  FlexSalaryExchangeRequestStatus,
  upcomingSalaryExchangesTableQuery,
  upcomingSalaryExchangesTableQueryVariables,
} from 'app/apollo/graphql/types';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React, { useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { UpcomingSalaryExchangesTable } from './components/Table';
import { UPCOMING_SALARY_EXCHANGES_TABLE_QUERY } from './graphql/queries';
import { updateQuery } from './utils/update-query';

interface Props {
  violatedQueryParam: string;
}

export const UpcomingSalaryExchanges: React.FC<Props> = ({
  violatedQueryParam,
}) => {
  const location = useLocation();
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );
  const suspendRef = useRef(true);
  const parsedSearch = qs.parse(location.search);
  const violated = parsedSearch[violatedQueryParam] === 'true';

  const { data, previousData, loading, fetchMore } = useQuery<
    upcomingSalaryExchangesTableQuery,
    upcomingSalaryExchangesTableQueryVariables
  >(UPCOMING_SALARY_EXCHANGES_TABLE_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    suspend: suspendRef.current,
    variables: {
      companyId,
      statuses: [FlexSalaryExchangeRequestStatus.APPROVED],
      onlyViolated: violated,
      first: Number(perPage),
    },
    onCompleted: () => {
      suspendRef.current = false;
    },
  });

  const _data = data ?? previousData;

  if (!_data) {
    return null;
  }

  const { salaryExchangeRequests } = _data;

  const upcomingSalaryExchanges =
    salaryExchangeRequests?.edges?.map(edge => edge.node) ?? [];

  const navigation = salaryExchangeRequests?.pageInfo ? (
    <TableNavigation
      perPage={perPage}
      setPerPage={setPerPage}
      pageInfo={salaryExchangeRequests.pageInfo}
      onNextPage={() => {
        fetchMore({
          variables: {
            after: salaryExchangeRequests.pageInfo.endCursor,
          },
          updateQuery,
        });
      }}
      onPreviousPage={() => {
        fetchMore({
          variables: {
            before: salaryExchangeRequests.pageInfo.startCursor,
            first: undefined,
            last: Number(perPage),
          },
          updateQuery,
        });
      }}
    />
  ) : null;

  return (
    <>
      {!suspendRef.current && loading && <TopLoading />}
      <UpcomingSalaryExchangesTable
        upcomingSalaryExchanges={upcomingSalaryExchanges}
        navigation={navigation}
      />
    </>
  );
};
