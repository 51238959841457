import { ApolloError, useMutation } from '@apollo/client';
import {
  updateMembershipRolesMutation,
  updateMembershipRolesMutationVariables,
} from 'app/apollo/graphql/types';
import { companyMessages } from 'app/messages/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { UPDATE_MEMBERSHIP_ROLES_MUTATION } from '../../graphql/mutations';
import { COMPANY_ADMINISTRATORS_QUERY } from '../../graphql/queries';

interface Delete {
  _delete: (userAccountId: string, companyId: string) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export const useDelete = (): Delete => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [updateMembershipRoles, { error, loading }] = useMutation<
    updateMembershipRolesMutation,
    updateMembershipRolesMutationVariables
  >(UPDATE_MEMBERSHIP_ROLES_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [COMPANY_ADMINISTRATORS_QUERY],
  });

  const callback = async (userAccountId, companyId) => {
    if (
      // eslint-disable-next-line no-alert
      confirm(formatMessage(companyMessages.confirmRemoveAdministrator))
    ) {
      const result = await updateMembershipRoles({
        variables: {
          userAccountId,
          companyId,
          roles: [],
        },
      });

      if (!result.errors || result.errors.length === 0) {
        send({
          type: 'success',
          message: formatMessage(companyMessages.administratorRemoveSuccess),
        });
      }
    }
  };

  return {
    _delete: callback,
    loading,
    error,
  };
};
