import { ContentContainer, Section } from '@frontend/ui';
import { add } from '@frontend/ui/icons';
import {
  smeCompaniesLayoutQuery,
  smeCompaniesLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { SME_COMPANIES_LAYOUT_QUERY } from 'app/queries';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { SearchField } from 'components/SearchField';
import { TopLoading } from 'components/TopLoading';
import { Page } from 'features/page';
import { SmeCompanies } from 'features/sme/companies';
import { Filters } from 'features/sme/companies/filters';
import { smeCompanyMessages } from 'features/sme/messages/sme';
import React from 'react';
import { RouteComponentProps } from 'react-router';

export const SmeOverviewRoute: React.FC<RouteComponentProps> = ({ match }) => {
  const { data, loading, error } = useQuery<
    smeCompaniesLayoutQuery,
    smeCompaniesLayoutQueryVariables
  >(SME_COMPANIES_LAYOUT_QUERY, {
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.adminViewer || !data?.nlpSalesReps) {
    return <GraphQlError error={error} />;
  }
  const { adminViewer, nlpSalesReps } = data;

  return (
    <Page
      title={<FormattedMessage {...smeCompanyMessages.proposal} />}
      actions={[
        {
          text: <FormattedMessage {...smeCompanyMessages.createNewCompany} />,
          leadingIcon: add,
          to: `${match.url}/create`,
        },
      ]}
    >
      <ContentContainer>
        <Section>
          <SearchField
            label={<FormattedMessage {...smeCompanyMessages.searchCompanies} />}
          />
          <Filters
            availableSalesReps={nlpSalesReps}
            currentAdminId={adminViewer.id}
          />
          <SmeCompanies />
        </Section>
      </ContentContainer>
    </Page>
  );
};
