import { Icon } from '@frontend/ui';
import { _delete, edit } from '@frontend/ui/icons';
import { noteMessages } from 'app/messages/notes';
import { FormattedMessage } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import React from 'react';

interface Props {
  deleteNote: (noteId: string) => void;
  disabled: boolean;
  noteId: string;
}

export const RowOptions: React.FC<Props> = ({
  deleteNote,
  noteId,
  disabled,
}) => (
  <KebabMenu
    label={noteMessages.notes}
    inClickableArea
    fixed
    disabled={disabled}
  >
    <MenuItem
      title={<FormattedMessage {...noteMessages.editNote} />}
      icon={<Icon icon={edit} decorative />}
      link={`notes/${noteId}/edit`}
    />
    <MenuItem
      title={<FormattedMessage {...noteMessages.deleteNote} />}
      icon={<Icon icon={_delete} decorative />}
      onClick={() => deleteNote(noteId)}
    />
  </KebabMenu>
);
