import { bonusSalaryExchangeRequestsQuery } from 'app/apollo/graphql/types';

interface FetchMoreResult {
  fetchMoreResult?: bonusSalaryExchangeRequestsQuery;
}

export const updateQuery = (
  previousResult: bonusSalaryExchangeRequestsQuery,
  { fetchMoreResult }: FetchMoreResult,
) => {
  if (
    !previousResult.bonusSalaryExchangeRequests ||
    !fetchMoreResult?.bonusSalaryExchangeRequests
  ) {
    return previousResult;
  }

  return fetchMoreResult;
};
