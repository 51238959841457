import { useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  emptyStringToNull,
  objectMap,
  toMoney,
  toNumber,
} from '@frontend/utils';
import {
  editJobOfferMutation,
  editJobOfferMutationVariables,
  UpdateJobOfferInput,
} from 'app/apollo/graphql/types';
import { JobOffersEditRouteMatchParams } from 'app/pages/companies/company/job-offers/edit';
import { useIntl } from 'components/formats';
import { MONTHLY_SALARY_REMUNERATION_TYPE_ID } from 'features/companies/company/utils/constants';
import { useNotification } from 'features/notifications';
import { useRouteMatch } from 'react-router';

import { FormValues } from '../../form';
import { EDIT_JOB_OFFER_MUTATION } from '../../graphql/mutations';
import { jobOfferMessages } from '../../messages';

export const useSubmit = () => {
  const intl = useIntl();
  const { send } = useNotification();
  const {
    params: { jobOfferId },
  } = useRouteMatch<JobOffersEditRouteMatchParams>();

  const [updateJobOffer] = useMutation<
    editJobOfferMutation,
    editJobOfferMutationVariables
  >(EDIT_JOB_OFFER_MUTATION);

  return async (
    _formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => {
    const formValues = objectMap(_formValues, emptyStringToNull);
    try {
      const input: UpdateJobOfferInput = {
        id: jobOfferId,
        title: formValues.title,
        body: formValues.body,
        prospectName: formValues.prospectName,
        birthdate: formValues.birthdate,
        profilePictureFile: formValues.profilePicture?.uploadId,
        coverPictureFile: formValues.coverPicture?.uploadId,
        yearlyVacationDays: toNumber(formValues.yearlyVacationDays),
        collectiveAgreement:
          formValues.collectiveAgreement === 'null' ||
          formValues.collectiveAgreement === ''
            ? null
            : formValues.collectiveAgreement,
        monthlyLunchAllowance: toMoney(formValues.monthlyLunchAllowance),
        yearlyWellnessAllowance: toMoney(formValues.yearlyWellnessAllowance),
        monthlyCompanyCarValue: toMoney(formValues.monthlyCompanyCarValue),
        hidden: !formValues.visible,
        validUntil:
          formValues.untilFurtherNotice === 'no' ? formValues.validUntil : null,
        workLocation: formValues.workLocation,
        workSetting: formValues.workSetting,
        showDiscount: formValues.showDiscount,
        optionsProgram: formValues.hasOptionsProgram
          ? formValues.optionsProgram
          : null,
        benefitPackageId: formValues.benefitPackageId,
        remunerations: [
          {
            idCode: MONTHLY_SALARY_REMUNERATION_TYPE_ID,
            value: toMoney(formValues.monthlySalary) ?? '',
          },
          ...(formValues.remunerations?.map(r => ({
            idCode: r.idCode,
            value: toMoney(r.value) ?? '',
          })) ?? []),
        ],
        attachmentFiles: formValues.attachmentFiles?.map(file => file.id),
        optionsProgrammeFiles: formValues.optionsProgrammeFiles?.map(
          file => file.id,
        ),
      };

      const updateJobOfferResult = await updateJobOffer({
        variables: { input },
      });

      if (!updateJobOfferResult.data?.updateJobOffer?.jobOffer) {
        throw new Error();
      }

      send({
        message: intl.formatMessage(jobOfferMessages.updated),
        type: 'success',
      });
    } catch (error) {
      formikHelpers.setErrors({
        submissionError: error?.message,
      });
    }
  };
};
