import { Formik } from '@frontend/formik';
import {
  ProposalPremiumCalculationMethod,
  smeCompanyEditEmployersPensionQuery,
  smeCompanyEditEmployersPensionQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as BenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/benefit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { customPremiumMatrixInitialValues } from '../../form/CustomPremiumMatrixSection/utils/contants';
import {
  EmployersPensionForm,
  FormValues,
  validationSchema,
} from '../../form/employers-pension';
import { itpPremiumMatrixInitialValues } from '../../form/ItpPremiumMatrixSection/utils/constants';
import { formatPremiumMatrixFormValues } from '../../form/utils';
import { SME_COMPANY_EDIT_EMPLOYERS_PENSION_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

interface Config {
  __typename?: string;
}

interface EmployersPensionConfig {
  __typename: 'ProposalEmployersPensionBenefitConfig';
}

export const isEmployersPensionConfig = (
  config: Config,
): config is EmployersPensionConfig =>
  config.__typename === 'ProposalEmployersPensionBenefitConfig';

export const EditEmployersPension: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<BenefitMatchParams>();
  const { submit, submissionError } = useSubmit({ params });

  const { data, loading, error } = useQuery<
    smeCompanyEditEmployersPensionQuery,
    smeCompanyEditEmployersPensionQueryVariables
  >(SME_COMPANY_EDIT_EMPLOYERS_PENSION_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, proposalId: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.benefit) {
    return <EmptyState error={error} />;
  }

  const { benefit, benefitPackages } = data.proposal;

  if (
    !benefit?.configuration ||
    !isEmployersPensionConfig(benefit.configuration)
  ) {
    return null;
  }

  const initialValues: FormValues = {
    benefitPackageIds: benefit.benefitPackages.map(({ id }) => id),
    customPremiumMatrix:
      benefit.configuration.premiumCalculationMethod ===
        ProposalPremiumCalculationMethod.CUSTOM_MATRIX &&
      benefit.configuration.premiumMatrix
        ? formatPremiumMatrixFormValues(benefit.configuration.premiumMatrix)
        : customPremiumMatrixInitialValues,
    defaultManagementType: benefit.configuration.defaultManagementType,
    extraFixedPremium: benefit.configuration.extraFixedPremium,
    itpPremiumMatrix:
      benefit.configuration.premiumCalculationMethod ===
        ProposalPremiumCalculationMethod.ITP_MATRIX &&
      benefit.configuration.premiumMatrix
        ? formatPremiumMatrixFormValues(benefit.configuration.premiumMatrix)
        : itpPremiumMatrixInitialValues,
    premiumCalculationMethod: benefit.configuration.premiumCalculationMethod,
    premiumDeductions: [...benefit.configuration.premiumDeductions],
    premiumWaiver: benefit.configuration.premiumWaiver ? 'yes' : 'no',
    retirementAge: benefit.configuration.retirementAge.toString(),
  };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ isValid, isSubmitting, values }) => (
        <EmployersPensionForm
          benefitPackages={benefitPackages}
          isValid={isValid}
          isSubmitting={isSubmitting}
          submissionError={submissionError}
          values={values}
        />
      )}
    </Formik>
  );
};
