import { FieldInlineEditTd } from '@frontend/formik';
import { Td, Tr } from '@frontend/ui';
import { terminateSalaryExchangesTableQuery_salaryExchanges_FlexSalaryExchangeConnection_edges_FlexSalaryExchangeEdge_node_FlexSalaryExchange as SalaryExchange } from 'app/apollo/graphql/types';
import { salaryExchangeProviderMessages } from 'app/messages/salary-exchange';
import { UnitCodeOptions, unitCodeSuffixMessages } from 'app/utils/constants';
import {
  FormattedCurrency,
  FormattedMessage,
  useIntl,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';

import { Status } from '../../../components/Status';
import { companySalaryExchangeMessages } from '../../../messages';

interface Props {
  item: SalaryExchange;
}

export const Row: React.FC<Props> = ({ item }) => {
  const { formatMessage } = useIntl();
  return (
    <Tr>
      <Td>
        {item.effectiveDate ? (
          <FormattedDate value={item.effectiveDate} />
        ) : (
          <NoValue />
        )}
      </Td>
      <Td>
        {item.membership.givenName} {item.membership.lastName}
      </Td>
      <Td type="number">
        {item.remuneration ? (
          <FormattedCurrency
            currency={item.remuneration.currency ?? ''}
            value={item.remuneration.value}
            after={
              item.remuneration.unitCode &&
              item.remuneration.unitCode !== UnitCodeOptions.LS ? (
                <>
                  /
                  <FormattedMessage
                    select={item.remuneration.unitCode}
                    messages={unitCodeSuffixMessages}
                  />
                </>
              ) : null
            }
          />
        ) : (
          <NoValue />
        )}
      </Td>
      <Td type="number">
        {item.deductionValue ? (
          <Status
            item={item}
            label={
              <FormattedCurrency
                after="PER_MONTH"
                currency="SEK"
                value={item.deductionValue}
              />
            }
          />
        ) : (
          <NoValue />
        )}
      </Td>
      <Td type="number">
        {item.premium ? (
          <FormattedCurrency
            after="PER_MONTH"
            currency="SEK"
            value={item.premium}
          />
        ) : (
          <NoValue />
        )}
      </Td>
      <Td>
        <FormattedMessage
          select={item.provider}
          messages={salaryExchangeProviderMessages}
        />
      </Td>
      <FieldInlineEditTd
        name={`salaryExchanges.${item.userAccountId}.reason`}
        label={formatMessage(companySalaryExchangeMessages.comments)}
      />
    </Tr>
  );
};
