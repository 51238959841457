import { Button } from '@frontend/ui';
import type { ButtonProps } from '@frontend/ui/components/Button';
import React from 'react';
import { LinkProps } from 'react-router-dom';

import { DefaultLink } from '../DefaultLink';

export type LinkButtonProps = LinkProps & ButtonProps;

export const LinkButton: React.FC<LinkButtonProps> = ({
  to,
  replace,
  ...props
}) =>
  props.disabled ? (
    <Button tabIndex={-1} {...props} />
  ) : (
    <DefaultLink tabIndex={-1} to={to} replace={replace}>
      <Button {...props} />
    </DefaultLink>
  );
