import { FormValues } from '../form';

export const getSelectedBenefitIds = (formValues?: FormValues): string[] =>
  formValues
    ? Object.keys(formValues.benefits).filter(id => !!formValues.benefits[id])
    : [];

interface Benefit {
  id: string;
  type: string;
}

/**
 * Disable all benefits that are already part of the benefit package (initial benefits).
 * Disable all benefits which are of the same type as a benefit that is already part of the benefit package.
 * Do not disable benefits that are selected and not yet part of the benefit package.
 * Disable all benefits which are of the same type as a benefit that is selected but not yet part of the benefit package.
 */
export const getDisabledBenefitIds = (
  benefits: Benefit[],
  initialBenefitIds: string[],
  selectedBenefitIds: string[],
): string[] => {
  const initialBenefitTypes = benefits
    .filter(b => initialBenefitIds.includes(b.id))
    .map(b => b.type);

  const selectedBenefitTypes = benefits
    .filter(b => selectedBenefitIds.includes(b.id))
    .map(b => b.type);

  const disabledByInitialType = benefits
    .filter(b => initialBenefitTypes.includes(b.type))
    .map(b => b.id);

  const disabledBySelectedType = benefits
    .filter(
      b =>
        selectedBenefitTypes.includes(b.type) &&
        !selectedBenefitIds.includes(b.id),
    )
    .map(b => b.id);

  return [...new Set([...disabledByInitialType, ...disabledBySelectedType])];
};
