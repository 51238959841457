import { PremiumMatrix } from '@frontend/benefit-types';
import { Permissions } from 'contexts/current-permissions';

import { hasPensionPremiumPermission } from './has-pension-premium-permission';

interface Args {
  fixedPremium?: boolean;
  permissions?: Permissions;
  premiumMatrix?: PremiumMatrix;
}

/**
 * @returns true iff the user has permission to update pension premiums,
 * the pension benefit has a premium matrix,
 * and it is configured to have an extra fixed premium; otherwise, returns false.
 */
export const showUpdateExtraPremiumButton = ({
  fixedPremium,
  premiumMatrix,
  permissions,
}: Args): boolean =>
  hasPensionPremiumPermission(permissions) && !!premiumMatrix && !!fixedPremium;
