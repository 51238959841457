import { Main } from '@frontend/ui';
import { NavigationDrawer } from 'components/NavigationDrawer';
import { useNavigationContext } from 'components/NavigationDrawer/containers/NavigationContext';
import React from 'react';
import styled from 'styled-components';

import { AppBar } from './app-bar';

interface OffsetContainerProps {
  navigationOffset: number;
}

const OffsetContainer = styled.div<OffsetContainerProps>`
  margin-left: ${p => p.navigationOffset}px;
`;

interface Props {
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children }) => {
  const { navigationOffset } = useNavigationContext();
  return (
    <>
      <AppBar />
      <NavigationDrawer />
      <OffsetContainer navigationOffset={navigationOffset}>
        <Main>{children}</Main>
      </OffsetContainer>
    </>
  );
};
