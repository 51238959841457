import { EmptyState } from '@frontend/ui';
import { archive } from '@frontend/ui/icons';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { ProposalsSection } from 'features/sme/companies/company/proposals';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const SmeCompanyProposalsRoute: React.FC = () => (
  <Suspense
    batch
    emptyState={
      <EmptyState
        title={
          <FormattedMessage {...smeCompanyProposalsMessages.noProposalsTitle} />
        }
        icon={archive}
      >
        <FormattedMessage
          {...smeCompanyProposalsMessages.noProposalsDescription}
        />
      </EmptyState>
    }
    fallback={<TopLoading />}
  >
    {({ errors }) => (
      <>
        {errors && <GraphQlErrors errors={errors} />}
        <ProposalsSection />
      </>
    )}
  </Suspense>
);
