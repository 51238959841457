/* eslint-disable */
import { Icon } from '@frontend/ui';
import React from 'react';
import { Option } from '../Option';

interface History {
  onChange: (input: any) => void;
  onExpandEvent: () => void;
  doCollapse: () => void;
  doExpand: () => void;
  config?: { [key: string]: any };
  currentState?: { [key: string]: any };
  expanded?: boolean;
  translations?: { [key: string]: any };
}

export const History: React.FC<History> = ({
  config,
  currentState,
  translations,
  onChange,
}) => (
  <div className="rdw-history-wrapper" aria-label="rdw-history-control">
    {config?.options.indexOf('undo') >= 0 && (
      <Option
        value="undo"
        onClick={e => !!onChange && onChange(e)}
        className={config?.undo.className}
        disabled={currentState?.undoDisabled}
        title={
          config?.undo.title ||
          (!!translations && translations['components.controls.history.undo'])
        }
      >
        {typeof config?.undo.icon === 'string' ? (
          <img alt="icon" src={config?.undo.icon} />
        ) : (
          <Icon icon={config?.undo.icon} size="small" decorative />
        )}
      </Option>
    )}
  </div>
);
