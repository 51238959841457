import gql from 'graphql-tag';

export const EDIT_BENEFIT_PACKAGE_MUTATION = gql`
  mutation editBenefitPackageMutation(
    $input: UpdateProposalBenefitPackageInput!
  ) {
    updateProposalBenefitPackage(input: $input) {
      id
      benefitPackages {
        id
        name
      }
    }
  }
`;
