import {
  CardPrimaryAction,
  Figure,
  Icon,
  SnapshotCardLayout,
} from '@frontend/ui';
import { moneyHand } from '@frontend/ui/icons/legacy';
import {
  FlexSalaryExchangeStatus,
  salaryExchangesCardQuery,
  salaryExchangesCardQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { Card } from 'components/LinkedCard';
import { LinkScrollTo } from 'components/LinkScrollTo';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { SX_TABLE_ID } from '../components/Table';
import { companySalaryExchangeMessages } from '../messages';
import { SALARY_EXCHANGES_CARD_QUERY } from './graphql/queries';

export const SalaryExchangesCard: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data } = useQuery<
    salaryExchangesCardQuery,
    salaryExchangesCardQueryVariables
  >(SALARY_EXCHANGES_CARD_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: {
      companyId,
      statuses: [FlexSalaryExchangeStatus.ACTIVE],
    },
  });

  const count = data?.salaryExchanges?.totalCount;

  if (count == null) {
    return null;
  }

  return (
    <CardGridCell>
      <Card fullHeight>
        <LinkScrollTo to={`#${SX_TABLE_ID}`} fullWidth>
          <CardPrimaryAction>
            <SnapshotCardLayout
              icon={
                <Icon
                  icon={moneyHand}
                  color="primary"
                  size="medium"
                  decorative
                />
              }
              title={
                <FormattedMessage
                  {...companySalaryExchangeMessages.salaryExchanges}
                />
              }
              figure1={
                <Figure
                  title={
                    <FormattedMessage
                      {...companySalaryExchangeMessages.salaryExchangesCount}
                      values={{ count }}
                    />
                  }
                  description={
                    <FormattedMessage
                      {...commonMessages.activeCount}
                      values={{ count }}
                    />
                  }
                />
              }
            />
          </CardPrimaryAction>
        </LinkScrollTo>
      </Card>
    </CardGridCell>
  );
};
