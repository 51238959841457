import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';

import { benefitFormMessages } from '../../messages';
import { Agreements } from '../../types';
import { getHasEaTjpAgreement } from '../../utils';

interface Props {
  agreements: Agreements;
}

export const MissingTglAgreementNotification: React.FC<Props> = ({
  agreements,
}) => {
  const hasEaTjpAgreement = getHasEaTjpAgreement(agreements);

  if (hasEaTjpAgreement) {
    return null;
  }

  return (
    <NotificationCard type="warning">
      <FormattedMessage {...benefitFormMessages.missingTglAgreement} />
    </NotificationCard>
  );
};
