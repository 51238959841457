import gql from 'graphql-tag';

export const ADD_EMPLOYMENT_MODAL_QUERY = gql`
  query addEmploymentModalQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      givenName
      lastName
    }
  }
`;
