import { useMutation } from '@apollo/client';
import {
  Section,
  SectionHeader,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import {
  updateCompilationLookedAtMutation,
  updateCompilationLookedAtMutationVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { BenefitCosts } from 'features/sme/companies/company/proposals/edit/compilation/benefit-costs';
import { BenefitPackageDetails } from 'features/sme/companies/company/proposals/edit/compilation/benefit-packages-details';
import { DownloadPriceProposalChip } from 'features/sme/companies/company/proposals/edit/compilation/download-price-proposal';
import { CompilationNotifications } from 'features/sme/companies/company/proposals/edit/compilation/notifications';
import { PensionFees } from 'features/sme/companies/company/proposals/edit/compilation/pension-fees';
import { smeCompilationMessages } from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { MatchParams } from '..';
import { UPDATE_COMPILATION_LOOKED_AT_MUTATION } from './graphql/mutations';

export const SmeCompanyProposalCompilationRoute: React.FC = () => {
  const params = useParams<MatchParams>();

  const [updateCompilationLookedAt] = useMutation<
    updateCompilationLookedAtMutation,
    updateCompilationLookedAtMutationVariables
  >(UPDATE_COMPILATION_LOOKED_AT_MUTATION);

  useEffect(() => {
    updateCompilationLookedAt({
      variables: {
        input: { proposalId: params.proposalId },
      },
    });
  }, [updateCompilationLookedAt, params.proposalId]);

  return (
    <Suspense batch fallback={<TopLoading />}>
      {({ errors }) => (
        <>
          {errors && <GraphQlErrors errors={errors} />}
          <CompilationNotifications />
          <Section>
            <SectionHeader>
              <FormattedMessage {...smeCompilationMessages.summation} />
            </SectionHeader>
            <ChipsWrapper>
              <DownloadPriceProposalChip />
            </ChipsWrapper>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...commonMessages.benefits} />
              </SubsectionHeader>
              <BenefitCosts />
            </Subsection>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...smeCompilationMessages.pensionFees} />
              </SubsectionHeader>
              <PensionFees />
            </Subsection>
          </Section>
          <Section>
            <SectionHeader>
              <FormattedMessage
                {...smeCompilationMessages.benefitPackageDetails}
              />
            </SectionHeader>
            <BenefitPackageDetails />
          </Section>
        </>
      )}
    </Suspense>
  );
};
