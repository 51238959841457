import { useEffect, useRef, useState } from 'react';

interface Params {
  skipRetry: boolean;
}

export type Operation<T> = () => Promise<T>;

interface ExponentialBackoff<T> {
  isRetrying: boolean;
  run: (operation: Operation<T>) => Promise<void>;
}
/**
 * Runs an operation and retries on rejection with exponential backoff
 * with retry delay as follows:
 *
 * 1s, 2s, 4s, 8s, 16s, 30s, 30s, 30s, ...
 *
 * @param skipRetry - Pause the operation retries if value is true. The operation will
 * run once when skip is set to true, so optimistic mutation response can be utilized.
 * Resume retries once value updates to true in case the last call isn't resolved.
 */
export const useRetryWithExponentialBackoff = <T>({
  skipRetry,
}: Params): ExponentialBackoff<T> => {
  const BASE_DELAY = 1000;
  const MAX_DELAY = 30000;

  const nRetriesRef = useRef(0);
  const [isRetrying, setIsRetrying] = useState(false);

  const skipRef = useRef(skipRetry);
  const operationRef = useRef<Operation<T> | null>(null);

  const run = async (operation: Operation<T>) => {
    operationRef.current = operation;
    try {
      if (skipRef.current && nRetriesRef.current !== 0) {
        return;
      }
      await operation();
      operationRef.current = null;
      nRetriesRef.current = 0;
      setIsRetrying(false);
    } catch {
      setIsRetrying(true);
      nRetriesRef.current += 1;
      const delay = Math.min(BASE_DELAY * 2 ** nRetriesRef.current, MAX_DELAY);
      setTimeout(() => run(operation), delay);
    }
  };

  useEffect(() => {
    skipRef.current = skipRetry;
    if (!skipRetry && operationRef.current) {
      run(operationRef.current);
    }
  }, [skipRetry]);

  return { isRetrying, run };
};
