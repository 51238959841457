import { toNumber } from '@frontend/utils';
import {
  smeCompanyEmployeesQuery_proposal_Proposal_memberships_ProposalMembership as Employee,
  SortingDirection,
} from 'app/apollo/graphql/types';

export type SortableColumns =
  | 'nid'
  | 'firstName'
  | 'benefitPackage'
  | 'monthlySalary'
  | 'isOwner';

interface Params {
  order: SortingDirection;
  orderBy: SortableColumns;
}

export const by =
  ({ order, orderBy }: Params) =>
  (a: Employee, b: Employee) => {
    switch (orderBy) {
      case 'nid':
        return order === SortingDirection.ASC
          ? a.personalIdentityNumber.localeCompare(b.personalIdentityNumber)
          : b.personalIdentityNumber.localeCompare(a.personalIdentityNumber);
      case 'firstName':
        return order === SortingDirection.ASC
          ? a.firstName.localeCompare(b.firstName)
          : b.firstName.localeCompare(a.firstName);
      case 'benefitPackage':
        return order === SortingDirection.ASC
          ? a.benefitPackage.name.localeCompare(b.benefitPackage.name)
          : b.benefitPackage.name.localeCompare(a.benefitPackage.name);
      case 'monthlySalary':
        return order === SortingDirection.ASC
          ? (toNumber(a.monthlySalary) ?? 0) - (toNumber(b.monthlySalary) ?? 0)
          : (toNumber(b.monthlySalary) ?? 0) - (toNumber(a.monthlySalary) ?? 0);
      case 'isOwner':
        return order === SortingDirection.ASC
          ? Number(a.isOwner) - Number(b.isOwner)
          : Number(b.isOwner) - Number(a.isOwner);
      default:
        return 0;
    }
  };
