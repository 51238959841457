import {
  CheckboxField,
  NumberField,
  SelectField,
  useFormikContext,
} from '@frontend/formik';
import {
  Section,
  SectionHeader,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import {
  jobOfferBenefitPackagesQuery,
  jobOfferBenefitPackagesQueryVariables,
  TotalCompensationCollectiveAgreement,
} from 'app/apollo/graphql/types';
import { suffixMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/companies/company';
import { ValidAgreementTypeNames } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FileInfo } from 'components/FileUploadTable';
import { FormattedMessage, useIntl } from 'components/formats';
import { FileUploadField } from 'components/formik/FileUploadField';
import { RichTextEditor } from 'components/formik/RichTextEditor';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { JOB_OFFER_BENEFIT_PACKAGES_QUERY } from '../../graphql/queries';
import {
  collectiveAgreementMessages,
  jobOfferFormMessages,
  jobOfferMessages,
} from '../../messages';

export interface FormValues {
  benefitPackageId: string;
  collectiveAgreement: TotalCompensationCollectiveAgreement | 'null' | '';
  monthlyCompanyCarValue: string;
  monthlyLunchAllowance: string;
  hasOptionsProgram?: boolean | null;
  optionsProgram?: string | null;
  optionsProgrammeFiles?: FileInfo[] | null;
  showDiscount?: boolean | null;
}

export const initialValues: FormValues = {
  benefitPackageId: '',
  collectiveAgreement: '',
  monthlyCompanyCarValue: '',
  monthlyLunchAllowance: '',
};

export const BenefitsFields: React.FC = () => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();
  const { values } = useFormikContext<FormValues>();
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  const { data: benefitPackageData } = useQuery<
    jobOfferBenefitPackagesQuery,
    jobOfferBenefitPackagesQueryVariables
  >(JOB_OFFER_BENEFIT_PACKAGES_QUERY, {
    variables: { companyId },
    errorPolicy: 'all',
    suspend: true,
  });

  const benefitPackageOptions =
    benefitPackageData?.company?.benefitPackages?.edges.map(
      ({ node: { name, id } }) => ({ label: name, value: id }),
    ) ?? [];

  const collectiveAgreementOptions = [
    'null',
    ...Object.values(ValidAgreementTypeNames),
  ].map(value => ({
    label: formatMessage({
      select: value,
      messages: collectiveAgreementMessages,
    }),
    value,
  }));

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...jobOfferFormMessages.benefits} />
      </SectionHeader>
      <SelectField
        fixed
        dense
        name="benefitPackageId"
        options={benefitPackageOptions}
        label={<FormattedMessage {...jobOfferFormMessages.benefitPackage} />}
      />
      <SelectField
        fixed
        dense
        name="collectiveAgreement"
        options={collectiveAgreementOptions}
        label={
          <FormattedMessage {...jobOfferFormMessages.collectiveAgreement} />
        }
      />
      <NumberField
        dense
        name="monthlyLunchAllowance"
        label={<FormattedMessage {...jobOfferFormMessages.lunch} />}
        affix={formatMessage(suffixMessages.krPerMonth)}
        decimalScale={0}
        locale={locale}
      />
      <NumberField
        dense
        name="monthlyCompanyCarValue"
        label={<FormattedMessage {...jobOfferFormMessages.companyCar} />}
        affix={formatMessage(suffixMessages.krPerMonth)}
        decimalScale={0}
        locale={locale}
      />
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...jobOfferMessages.discounts} />
        </SubsectionHeader>
        <DescriptionWrapper>
          <FormattedMessage {...jobOfferFormMessages.showDiscountsHelper} />
        </DescriptionWrapper>
        <CheckboxField
          name="showDiscount"
          label={<FormattedMessage {...jobOfferFormMessages.showDiscounts} />}
        />
      </Subsection>
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...jobOfferMessages.incentiveProgram} />
        </SubsectionHeader>
        <DescriptionWrapper>
          <FormattedMessage {...jobOfferFormMessages.incentiveHelper} />
        </DescriptionWrapper>
        <CheckboxField
          name="hasOptionsProgram"
          label={<FormattedMessage {...jobOfferFormMessages.incentive} />}
        />
      </Subsection>

      {values.hasOptionsProgram && (
        <>
          <div className="with-presentation-text-styles">
            <RichTextEditor
              name="optionsProgram"
              placeholder={formatMessage(jobOfferFormMessages.description)}
              height="8.5rem"
            />
          </div>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...jobOfferFormMessages.attachFiles} />
            </SubsectionHeader>
            <FileUploadField
              companyId={companyId}
              name="optionsProgrammeFiles"
              label={<FormattedMessage {...jobOfferFormMessages.selectFiles} />}
            />
          </Subsection>
        </>
      )}
    </Section>
  );
};
