import { CheckboxField, Table, Td, Th, Tr } from '@frontend/ui';
import { upcomingSalaryExchangesTableQuery_salaryExchangeRequests_FlexSalaryExchangeRequestConnection_edges_FlexSalaryExchangeRequestEdge_node_FlexSalaryExchangeRequest as UpcomingSalaryExchange } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { salaryExchangeProviderMessages } from 'app/messages/salary-exchange';
import { UnitCodeOptions, unitCodeSuffixMessages } from 'app/utils/constants';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
  useIntl,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import React, { useId, useState } from 'react';

import { Status } from '../../../components/Status';
import { companySalaryExchangeMessages } from '../../../messages';
import { TableActions } from '../../../requests/components/TableActions';

export const UPCOMING_SX_TABLE_ID = 'upcoming-salary-exchanges-table';

interface Props {
  navigation: React.ReactNode;
  upcomingSalaryExchanges: UpcomingSalaryExchange[];
}

export const UpcomingSalaryExchangesTable: React.FC<Props> = ({
  upcomingSalaryExchanges,
  navigation,
}) => {
  const [requestIds, setRequestIds] = useState<string[]>([]);
  const { formatMessage } = useIntl();
  const labelId = useId();

  const indeterminate =
    (requestIds.length < upcomingSalaryExchanges.length &&
      requestIds.length !== 0) ||
    !upcomingSalaryExchanges.length;

  const selectAll = requestIds.length === upcomingSalaryExchanges.length;

  const handleRowSelect = (id: string) => {
    const _requestIds = requestIds.some(_id => _id === id)
      ? requestIds.filter(_id => _id !== id)
      : [...requestIds, id];

    setRequestIds(_requestIds);
  };

  const handleAllRowSelect = (checked: boolean) => {
    const _requestIds = checked
      ? upcomingSalaryExchanges.map(({ id }) => id)
      : [];

    setRequestIds(_requestIds);
  };

  return (
    <div id={UPCOMING_SX_TABLE_ID}>
      <Table
        feedback={
          upcomingSalaryExchanges.length
            ? {
                actions: <TableActions requestIds={requestIds} approved />,
                active: !!requestIds.length,
                children: (
                  <FormattedMessage
                    {...commonMessages.nRowsSelected}
                    values={{ count: requestIds.length }}
                  />
                ),
              }
            : undefined
        }
        navigation={navigation}
      >
        <thead>
          <Tr>
            <Th type="input">
              <CheckboxField
                checked={selectAll}
                onChange={handleAllRowSelect}
                indeterminate={indeterminate}
                disabled={!upcomingSalaryExchanges.length}
                aria-label={formatMessage(commonMessages.selectAllRows)}
              />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.from} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th type="number">
              <FormattedMessage
                {...companySalaryExchangeMessages.benefitQualifyingIncome}
              />
            </Th>
            <Th type="number">
              <FormattedMessage {...companySalaryExchangeMessages.deduction} />
            </Th>
            <Th type="number">
              <FormattedMessage {...companySalaryExchangeMessages.premium} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.via} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {!upcomingSalaryExchanges.length ? (
            <Tr>
              <Td>
                <FormattedMessage
                  {...companySalaryExchangeMessages.noSalaryExchangeItems}
                />
              </Td>
            </Tr>
          ) : (
            upcomingSalaryExchanges.map(item => (
              <Tr key={item.id}>
                <Td type="input">
                  <CheckboxField
                    id={item.id}
                    checked={requestIds.includes(item.id)}
                    onChange={() => handleRowSelect(item.id)}
                    aria-labelledby={labelId}
                  />
                </Td>
                <Td>
                  <FormattedDate value={item.effectiveDate} />
                </Td>
                <Td id={labelId}>
                  {item.membership.givenName} {item.membership.lastName}
                </Td>
                <Td type="number">
                  {item.remuneration ? (
                    <FormattedCurrency
                      currency={item.remuneration.currency ?? ''}
                      value={item.remuneration.value}
                      after={
                        item.remuneration.unitCode &&
                        item.remuneration.unitCode !== UnitCodeOptions.LS ? (
                          <>
                            /
                            <FormattedMessage
                              select={item.remuneration.unitCode}
                              messages={unitCodeSuffixMessages}
                            />
                          </>
                        ) : null
                      }
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                <Td type="number">
                  {item.deductionValue ? (
                    <Status
                      item={item}
                      label={
                        <FormattedCurrency
                          after="PER_MONTH"
                          currency="SEK"
                          value={item.deductionValue}
                        />
                      }
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                <Td type="number">
                  {item.premium ? (
                    <FormattedCurrency
                      after="PER_MONTH"
                      currency="SEK"
                      value={item.premium}
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                <Td>
                  <FormattedMessage
                    select={item.provider}
                    messages={salaryExchangeProviderMessages}
                  />
                </Td>
              </Tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};
