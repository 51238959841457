import { ImportColumn } from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { IntlShape } from 'components/formats';
import * as Yup from 'yup';

import { ImportColumnOptionValue } from './static-column-options';

const mapRulesToProperties = (map, rule) =>
  Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});

/* Organizational unit is an exception to the unique field requiement */

const uniqueValue = (
  record: Record<string, ImportColumnOptionValue>,
  intl: IntlShape,
) =>
  Yup.string().test({
    name: 'Unique select',
    message: intl.formatMessage(validationMessages.uniqueField),
    test: (value: ImportColumnOptionValue) =>
      value
        ? value === ImportColumn.ORGANIZATIONAL_UNIT ||
          Object.values(record).filter(recVal => value === recVal).length <= 1
        : true,
  });

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    updatedColumns: Yup.lazy(
      (record: Record<string, ImportColumnOptionValue> | undefined) =>
        record
          ? Yup.object(mapRulesToProperties(record, uniqueValue(record, intl)))
          : Yup.mixed().notRequired(),
    ),
    fallbackDateForBenefits: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });
