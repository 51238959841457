import { salaryExchangeEventsQuery } from 'app/apollo/graphql/types';

interface FetchResult {
  fetchMoreResult?: salaryExchangeEventsQuery;
}

export const updateSalaryExchangeEventsQuery = (
  previousResult: salaryExchangeEventsQuery,
  { fetchMoreResult }: FetchResult,
) => {
  if (
    !previousResult.salaryExchangeRequestsHistory ||
    !fetchMoreResult?.salaryExchangeRequestsHistory
  ) {
    return previousResult;
  }

  return fetchMoreResult;
};
