import { ProposalAdviceOption } from 'app/apollo/graphql/types';

interface ProductKnowledge {
  adviceSavingsInsurance: boolean | null;
}

interface Propsal {
  adviceWaived: ProposalAdviceOption;
  productKnowledge: ProductKnowledge | null;
}

/**
 * Determines if the proposal should allow creation of pension benefits
 *
 * @returns true if the proposal allows creation of pension benefits, otherwise false
 */
export const hasPensionBenefitAccess = <T extends Propsal>(
  proposal?: T | null,
): boolean => {
  if (!proposal) {
    return false;
  }

  if (proposal.productKnowledge?.adviceSavingsInsurance) {
    return true;
  }

  return proposal.adviceWaived !== ProposalAdviceOption.ADVICE_REQUIRED;
};
