import { ApolloError, useMutation } from '@apollo/client';
import {
  editProposalNameMutation,
  editProposalNameMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';

import { EDIT_PROPOSAL_NAME_MUTATION } from '../../graphql/mutations';
import { FormValues } from '..';

interface Props {
  onRequestClose: () => void;
  proposalId: string;
}

interface Submit {
  submit: (values?: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = ({ onRequestClose, proposalId }: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [createProposal, { error: submissionError }] = useMutation<
    editProposalNameMutation,
    editProposalNameMutationVariables
  >(EDIT_PROPOSAL_NAME_MUTATION);

  const submit = async (values: FormValues) => {
    try {
      const res = await createProposal({
        variables: {
          input: {
            id: proposalId,
            proposal: {
              name: values.name,
            },
          },
        },
      });
      const id = res.data?.updateProposal.id;
      if (!id) {
        return;
      }
      send({
        message: formatMessage(smeCompanyProposalsMessages.nameUpdated),
        type: 'success',
      });
      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    submissionError,
  };
};
