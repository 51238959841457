import { CheckboxGroupField } from '@frontend/formik';
import {
  CheckboxGroupOption,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import React from 'react';

import { benefitFormMessages } from '../../messages';
import { Agreements, SalaryExchangeProviderOptionValue } from '../../types';
import { hasAgreementWithProvider } from '../../utils';
import { PENSION_PROVIDER_OPTIONS } from '../../utils/constants';

interface Props {
  agreements: Agreements;
  name: string;
}

export const SalaryExchangeProviderSection: React.FC<Props> = ({
  agreements,
  name,
}) => {
  const { formatMessage } = useIntl();

  const pensionProviders = PENSION_PROVIDER_OPTIONS.filter(providerOption =>
    hasAgreementWithProvider(providerOption.value, agreements),
  );

  const providers: CheckboxGroupOption<SalaryExchangeProviderOptionValue>[] = [
    ...pensionProviders,
    {
      label: formatMessage(benefitFormMessages.collectum),
      value: 'COLLECTUM',
    },
  ];

  return (
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...benefitFormMessages.providers} />
      </SubsectionHeader>
      <DescriptionWrapper>
        <FormattedMessage {...benefitFormMessages.providersDescription} />
      </DescriptionWrapper>
      <CheckboxGroupField name={name} options={providers} required />
    </Subsection>
  );
};
