import { useIntl } from 'components/formats';
import { EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';

import { StyleWrapper, StyleWrapperProps } from './style';
import { toolbarConfig } from './toolbar';
import {
  draftToHtml,
  draftToMarkdown,
  htmlToDraft,
  localization,
  markdownToDraft,
} from './utils';

export interface RichTextEditorProps extends EditorProps, StyleWrapperProps {
  setValue: (value: string) => void;
  value: string;
  initialValue?: string;
  markup?: 'markdown' | 'html';
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  setValue,
  initialValue,
  wysiwyg,
  height,
  markup = 'markdown',
  ...props
}) => {
  const intl = useIntl();

  const isHtml = markup === 'html';

  const _toDraft = (state: string) =>
    isHtml ? htmlToDraft(state) : markdownToDraft(state);

  const _fromDraft = (state: EditorState) =>
    isHtml ? draftToHtml(state) : draftToMarkdown(state);

  const [editorState, setEditorState] = useState<EditorState>(_toDraft(value));

  const handleChange = (state: EditorState) => {
    setEditorState(state);
    setValue(_fromDraft(state));
  };

  useEffect(() => {
    setEditorState(_toDraft(value));
  }, [initialValue]);

  return (
    <StyleWrapper wysiwyg={wysiwyg} height={height}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleChange}
        toolbar={toolbarConfig(wysiwyg)}
        localization={localization(intl)}
        {...props}
      />
    </StyleWrapper>
  );
};
