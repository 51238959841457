import { ApolloError, useMutation } from '@apollo/client';
import { DropzoneContainer, DropzoneLayout } from '@frontend/ui';
import {
  createImportMutation,
  createImportMutationVariables,
} from 'app/apollo/graphql/types';
import { employeesImportMessages } from 'app/messages/employees';
import { HandleHistoryReplace } from 'app/pages/companies/company/employees/import';
import { useUploadFiles } from 'app/utils/use-upload-files';
import importFile from 'assets/illustrations/instructional-agreement-sent.svg';
import { Dropzone } from 'components/Dropzone';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { NotificationCard } from 'components/NotificationCard';
import { SimpleWizard } from 'components/SimpleWizard';
import { TopLoading } from 'components/TopLoading';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '../../..';
import { CREATE_IMPORT_MUTATION } from './graphql/mutations';

interface Props {
  onContinue: HandleHistoryReplace;
  title: React.ReactNode;
}

export const Upload: React.FC<Props> = ({ title, onContinue }) => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  const { formatMessage } = useIntl();
  const [dropError, setDropError] = useState(false);
  const [createError, setCreateError] = useState<ApolloError | undefined>(
    undefined,
  );

  const [createImport, { loading: createLoading }] = useMutation<
    createImportMutation,
    createImportMutationVariables
  >(CREATE_IMPORT_MUTATION, {
    onCompleted: ({ createImport: data }) =>
      data?.import.id && onContinue({ importId: data.import.id }),
    onError: error => setCreateError(error),
  });

  const {
    upload,
    loading: isUploading,
    error: uploadError,
  } = useUploadFiles({
    companyId,
    onCompleted: uploadData => {
      createImport({
        variables: {
          input: {
            companyId,
            fileId: uploadData ? uploadData[0].id : '',
          },
        },
      });
    },
  });

  const loading = createLoading || isUploading;

  return (
    <SimpleWizard
      title={title}
      parentLink={`/companies/${companyId}/employees/imports`}
    >
      {loading && <TopLoading />}
      <DropzoneContainer>
        <Dropzone
          disabled={loading}
          multiple={false}
          onDropAccepted={upload}
          onDropRejected={() => setDropError(true)}
          label={<FormattedMessage {...employeesImportMessages.selectFile} />}
        >
          <DropzoneLayout
            buttonLabel={
              <FormattedMessage {...employeesImportMessages.selectFile} />
            }
            description={
              <FormattedMessage
                {...employeesImportMessages.fileUploadDescription}
              />
            }
            illustration={
              <img
                src={importFile}
                alt={formatMessage(employeesImportMessages.uploadFile)}
              />
            }
            title={
              <FormattedMessage {...employeesImportMessages.fileUploadTitle} />
            }
          />
        </Dropzone>
      </DropzoneContainer>
      {createError && <GraphQlError error={createError} />}
      {(uploadError || dropError) && (
        <NotificationCard type="error">
          <FormattedMessage {...employeesImportMessages.fileUploadError} />
        </NotificationCard>
      )}
    </SimpleWizard>
  );
};
