import { Formik } from '@frontend/formik';
import {
  companyCreateBenefitQuery,
  companyCreateBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { FormValues, SynsamForm, validationSchema } from '../../form/synsam';
import { getBenefitPackageOptions } from '../../form/utils';
import { COMPANY_CREATE_BENEFIT_QUERY } from '../graphql/queries';
import { useSubmit } from './utils/use-submit';

export const CreateSynsam: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<MatchParams>();
  const { submit, submissionError } = useSubmit();

  const { data, loading, error } = useQuery<
    companyCreateBenefitQuery,
    companyCreateBenefitQueryVariables
  >(COMPANY_CREATE_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { id: params.companyId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company?.benefitPackages) {
    return <EmptyState error={error} />;
  }

  const { benefitPackages } = data.company;

  const benefitPackageOptions = getBenefitPackageOptions(
    'synsam',
    benefitPackages,
  );

  const initialValues: FormValues = {
    benefitPackageIds: [],
    effectiveFrom: '',
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={submit}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      <SynsamForm
        benefitPackageOptions={benefitPackageOptions}
        submissionError={submissionError}
      />
    </Formik>
  );
};
