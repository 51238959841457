import gql from 'graphql-tag';

import { editJobOfferDetails } from './fragments';

export const CREATE_JOB_OFFER_MUTATION = gql`
  mutation createJobOfferMutation($input: CreateJobOfferInput!) {
    createJobOffer(input: $input) {
      jobOffer {
        id
        companyId
        title
        body
        prospectName
        yearlyVacationDays
        collectiveAgreement
        monthlyLunchAllowance
        yearlyWellnessAllowance
        monthlyCompanyCarValue
        hidden
        validUntil
        status
        updatedAt
        companyName
        companyLogo
        workLocation
        workSetting
        showDiscount
        optionsProgram
        birthdate
        benefitPackageId
        remunerations {
          idCode
          value
        }
      }
    }
  }
`;

export const EDIT_JOB_OFFER_MUTATION = gql`
  mutation editJobOfferMutation($input: UpdateJobOfferInput!) {
    updateJobOffer(input: $input) {
      jobOffer {
        ...editJobOfferDetails
      }
    }
  }
  ${editJobOfferDetails}
`;

export const DELETE_JOB_OFFER_MUTATION = gql`
  mutation deleteJobOfferMutation($input: DeleteJobOfferInput!) {
    deleteJobOffer(input: $input) {
      jobOfferId
    }
  }
`;
