import { EmptyState, Section, SectionHeader, Subsection } from '@frontend/ui';
import { loyalty } from '@frontend/ui/icons';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import { BenefitPackages } from 'features/sme/companies/company/proposals/edit/benefit-packages';
import {
  CreateBenefitPackageAssistChip,
  CreateBenefitPackageModal,
} from 'features/sme/companies/company/proposals/edit/benefit-packages/create';
import { Benefits } from 'features/sme/companies/company/proposals/edit/benefits';
import {
  CreateBenefitAssistChip,
  CreateBenefitModal,
} from 'features/sme/companies/company/proposals/edit/benefits/create';
import {
  smeBenefitPackagesMessages,
  smeBenefitsMessages,
} from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const SmeCompanyProposalBenefitsRoute: React.FC = () => (
  <Suspense batch fallback={<TopLoading />}>
    {({ errors, isEmpty }) => (
      <Section>
        {errors && <GraphQlErrors errors={errors} />}
        <Subsection>
          <SectionHeader>
            <FormattedMessage {...smeBenefitPackagesMessages.benefitPackages} />
          </SectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage {...smeBenefitPackagesMessages.description} />
            </DescriptionWrapper>
          </TextGrid>
          <ChipsWrapper>
            <CreateBenefitPackageAssistChip />
            {!isEmpty() && <CreateBenefitAssistChip />}
          </ChipsWrapper>
        </Subsection>
        <CreateBenefitModal />
        <CreateBenefitPackageModal />
        <BenefitPackages />
        <Benefits />
        {isEmpty() && (
          <EmptyState
            title={
              <FormattedMessage {...smeBenefitsMessages.emptyStateTitle} />
            }
            icon={loyalty}
          >
            <FormattedMessage {...smeBenefitsMessages.emptyStateDescription} />
          </EmptyState>
        )}
      </Section>
    )}
  </Suspense>
);
