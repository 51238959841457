import gql from 'graphql-tag';

export const ADD_BENEFITS_TO_BENEFIT_PACKAGE_QUERY = gql`
  query addBenefitsToBenefitPackageQuery(
    $companyId: ID!
    $onlyGetBenefits: Boolean
    $locale: String
  ) {
    company(id: $companyId) {
      id
      name
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
      benefits: flexBenefits(
        onlyGetBenefits: $onlyGetBenefits
        locale: $locale
      ) {
        edges {
          node {
            id
            type
            content {
              title
              locale
            }
            supplier {
              id
              name
            }
            entitlementRules {
              benefitPackageIds
            }
            categories {
              id
              name
            }
            configuration {
              data
            }
          }
        }
      }
      managedBy
    }
  }
`;
