import gql from 'graphql-tag';

export const DELETE_PROPOSAL_EMPLOYEE_MUTATION = gql`
  mutation deleteProposalEmployeeMutation(
    $input: DeleteProposalMembershipInput!
  ) {
    deleteProposalMembership(input: $input) {
      id
      benefitPackages {
        id
        memberships {
          id
        }
      }
      memberships {
        id
      }
    }
  }
`;
