import { ApolloError, useMutation } from '@apollo/client';
import { client } from 'app/apollo/client';
import {
  rejectSalaryExchangeRequestMutation,
  rejectSalaryExchangeRequestMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';
import { useId, useState } from 'react';

import { companySalaryExchangeMessages } from '../../../messages';
import { FormValues } from '../components/Form';
import { REJECT_SALARY_EXCHANGE_REQUEST_MUTATION } from '../graphql/mutations';
import { getFailedRejections } from './get-failed-rejections';

interface Success {
  requestId: string;
  error?: never;
}
interface Error {
  error: ApolloError;
  requestId?: never;
}

export type Result = Success | Error;

export const isError = (result: Result): result is Error => 'error' in result;

interface Props {
  approved: boolean;
  onCompleted: () => void;
  onError: (failedIds: string[]) => void;
}

interface Submit {
  results: Result[];
  submit: (values: FormValues) => Promise<void>;
}

export const useSubmit = ({
  approved,
  onCompleted,
  onError,
}: Props): Submit => {
  const [results, setResults] = useState<Result[]>([]);
  const batchId = useId();
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const { confirm } = useConfirm();

  const [rejectSalaryExchange] = useMutation<
    rejectSalaryExchangeRequestMutation,
    rejectSalaryExchangeRequestMutationVariables
  >(REJECT_SALARY_EXCHANGE_REQUEST_MUTATION, {
    context: { batch: true, batchId },
  });

  const submit = async (values: FormValues) => {
    const confirmed = await confirm({
      title: formatMessage(
        approved
          ? companySalaryExchangeMessages.confirmTerminateTitle
          : companySalaryExchangeMessages.confirmRejectTitle,
      ),
      description: formatMessage(
        approved
          ? companySalaryExchangeMessages.confirmTerminate
          : companySalaryExchangeMessages.confirmReject,
      ),
    });

    if (!confirmed) {
      return;
    }
    try {
      const settledResults = await Promise.allSettled(
        Object.entries(values.requests).map(request => {
          const [requestId, { reason }] = request;
          return rejectSalaryExchange({
            variables: {
              input: {
                requestId,
                rejectionReason: reason,
              },
            },
          });
        }),
      );

      const _results = settledResults.reduce<Result[]>(
        (acc, result) =>
          result.status === 'rejected'
            ? [...acc, { error: result.reason }]
            : [
                ...acc,
                {
                  requestId:
                    result.value.data?.rejectSalaryExchangeRequest
                      ?.salaryExchangeRequest.id ?? '',
                },
              ],
        [],
      );

      setResults(_results);
      const submissionErrors = _results.filter(isError);
      const successCount = _results.length - submissionErrors.length;

      if (successCount) {
        send({
          message: formatMessage(
            approved
              ? companySalaryExchangeMessages.canceledSalaryExchangesCount
              : companySalaryExchangeMessages.rejectSubmitSuccessMessage,
            {
              count: successCount,
            },
          ),
          type: 'success',
        });
        client.cache.evict({ fieldName: 'salaryExchangeRequests' });
        client.cache.evict({ fieldName: 'salaryExchangeRequestsHistory' });
        client.cache.evict({ fieldName: 'salaryExchanges' });
        client.cache.evict({ fieldName: 'bonusSalaryExchangeRequests' });
        client.cache.evict({ fieldName: 'bonusSalaryExchangeRequestsHistory' });
        client.cache.evict({ fieldName: 'bonusSalaryExchanges' });
        client.cache.gc();
      }

      if (!submissionErrors.length) {
        onCompleted();
      } else {
        const requestIds = getFailedRejections({
          results: _results,
          requestIds: Object.keys(values.requests),
        });
        onError(requestIds);
      }
    } catch {
      // Do nothing
    }
  };
  return { submit, results };
};
