import {
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit as Benefit,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration as Config,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig as EmployersPensionConfig,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig as OccupationalPensionConfig,
} from 'app/apollo/graphql/types';

import { formatPremiumMatrixFormValues } from '../../../benefits/form/utils';
import { ProposalPremiumMatrixFormValues } from '../../../benefits/form/utils/types';

type PensionConfig = OccupationalPensionConfig | EmployersPensionConfig;

const isPensionConfig = (config: Config): config is PensionConfig =>
  [
    'ProposalEmployersPensionBenefitConfig',
    'ProposalOccupationalPensionBenefitConfig',
  ].includes(config.__typename ?? '');

/**
 * From the benefits in a benefit package, extract
 * the premium matrix from the pension benefit if it exists.
 * Return format is ProposalPremiumMatrixFormValues so the read only
 * form table can be reused for presentation in the compilation
 */
export const getPremiumMatrix = (
  benefits: readonly Benefit[],
): ProposalPremiumMatrixFormValues | null => {
  const pensionBenefit = benefits.find(benefit =>
    isPensionConfig(benefit.configuration),
  );
  const pensionConfig = pensionBenefit?.configuration;
  if (!pensionConfig || !isPensionConfig(pensionConfig)) {
    return null;
  }
  const { premiumMatrix } = pensionConfig;

  if (premiumMatrix) {
    return formatPremiumMatrixFormValues(premiumMatrix);
  }
  return null;
};
