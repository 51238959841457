import { NavigationAnchor } from 'contexts/navigation-anchor';
import { jobOfferMessages } from 'features/companies/company/job-offers/messages';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { Overview } from './_index';
import { CreateRoute } from './create';
import { EditRoute } from './edit';

export const JobOffers: React.FC<RouteComponentProps> = ({
  match: { path, url },
}) => (
  <NavigationAnchor title={jobOfferMessages.job} url={url}>
    <Switch>
      <Route path={`${path}/create`} component={CreateRoute} />
      <Route path={`${path}/edit/:jobOfferId`} component={EditRoute} />
      <Route path={path} component={Overview} />
    </Switch>
  </NavigationAnchor>
);
