import { parseIsoDuration, toNumber } from '@frontend/utils';
import { frequencySuffixMessages } from 'app/messages/common';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

interface Insurance {
  nextPremiumPayment: {
    value: string;
    currency: string;
  } | null;
  premiumPaymentFrequency: string | null;
}

export const getInsurancePremium = (insurance: Insurance) => {
  const premium = insurance.nextPremiumPayment?.value;
  const premiumCurrency = insurance.nextPremiumPayment?.currency;
  const paymentFrequency = parseIsoDuration(insurance.premiumPaymentFrequency);

  return toNumber(premium) ? (
    <FormattedCurrency
      value={Number(premium ?? 0)}
      currency={premiumCurrency ?? ''}
      after={
        paymentFrequency && (
          <FormattedMessage
            select={paymentFrequency.unit}
            messages={frequencySuffixMessages}
            values={{ count: paymentFrequency.value }}
          />
        )
      }
    />
  ) : (
    <NoValue />
  );
};
