import { ContentContainer } from '@frontend/ui';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Page } from 'features/page';
import { FullyCapableForWork } from 'features/sme/companies/company/proposals/edit/employees/fcfw';
import { CompanySizeDescription } from 'features/sme/companies/company/proposals/edit/employees/fcfw/company-size-description';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams } from '../..';

export const SmeCompanyProposalEmployeesFcfwRoute: React.FC<
  RouteComponentProps<MatchParams>
> = ({ match: { params, url } }) => (
  <NavigationAnchor title={smeEmployeesMessages.fcfwTitle} url={url}>
    <Page
      title={<FormattedMessage {...smeEmployeesMessages.fcfwTitle} />}
      parentLink={`/sme/${params.companyId}/proposals/${params.proposalId}/employees`}
    >
      <Suspense fallback={<TopLoading />}>
        {({ errors }) => (
          <>
            {errors && <GraphQlErrors errors={errors} />}
            <ContentContainer>
              <TextGrid>
                <DescriptionWrapper>
                  <p>
                    <FormattedMessage
                      {...smeEmployeesMessages.fcfwDescription}
                    />
                  </p>
                  <p>
                    <CompanySizeDescription />
                  </p>
                </DescriptionWrapper>
              </TextGrid>
              <FullyCapableForWork />
            </ContentContainer>
          </>
        )}
      </Suspense>
    </Page>
  </NavigationAnchor>
);
