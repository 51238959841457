import {
  CompaniesOrderDirection,
  SortingDirection,
} from 'app/apollo/graphql/types';

import { SortableColumns } from '..';

interface Params {
  order?: SortingDirection;
  orderBy?: SortableColumns;
}

export const getSortingQueryArgs = ({ order, orderBy }: Params) => {
  if (!orderBy || !order) {
    return undefined;
  }

  return {
    field: orderBy,
    direction:
      order === SortingDirection.ASC
        ? CompaniesOrderDirection.ASC
        : CompaniesOrderDirection.DESC,
  };
};
