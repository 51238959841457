import { Table, Td, Th, Tr } from '@frontend/ui';
import { people } from '@frontend/ui/icons';
import {
  missingEmploymentsQuery,
  missingEmploymentsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, suffixMessages } from 'app/messages/common';
import {
  employeesImportMessages,
  employmentMessages,
} from 'app/messages/employees';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { CollapsibleSimple } from 'components/CollapsibleSimple';
import { FormattedMessage, useIntl } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { useNavigationStackUtils } from 'components/NavigationDrawer/lib/use-navigation-stack-utils';
import { NoValue } from 'components/NoValue';
import { TableNavigation } from 'components/TableNavigation';
import React, { useRef, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { MatchParams } from '..';
import { MISSING_EMPLOYMENTS_QUERY } from './graphql/queries';
import { updateMissingEmployments } from './graphql/update-missing-employments';

interface Props {
  importId: string;
}

export const MissingEmployments: React.FC<Props> = ({ importId }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  const utils = useNavigationStackUtils();
  const { formatMessage } = useIntl();
  const suspend = useRef(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const { data, previousData, fetchMore } = useQuery<
    missingEmploymentsQuery,
    missingEmploymentsQueryVariables
  >(MISSING_EMPLOYMENTS_QUERY, {
    suspend: suspend.current,
    onCompleted: () => {
      suspend.current = false;
    },
    errorPolicy: 'all',
    variables: {
      id: importId,
      first: Number(perPage),
    },
  });

  const missingEmployments =
    data?.import?.analysis?.missingEmployments ??
    previousData?.import?.analysis?.missingEmployments;

  if (!missingEmployments?.edges.length) {
    return null;
  }

  const { pageInfo, totalCount } = missingEmployments;

  const employments = missingEmployments.edges.map(({ node }) => ({
    id: node.membership.userAccountId,
    name: `${node.membership.givenName} ${node.membership.lastName}`,
    nids: node.membership.naturalPersonIdentifiers,
    employmentEndDate: node.membership.employments?.edges?.[0].node.period[1],
  }));

  return (
    <CollapsibleSimple
      label={
        <FormattedMessage
          {...employeesImportMessages.missingEmploymentsLabel}
        />
      }
      trailingLabel={`${totalCount} ${formatMessage(suffixMessages.pieces)}`}
      icon={people}
    >
      <FormattedMessage {...employeesImportMessages.missingEmploymentsLede} />
      <Table
        fixed
        navigation={
          <TableNavigation
            perPage={perPage}
            setPerPage={setPerPage}
            pageInfo={pageInfo}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: pageInfo.endCursor,
                },
                updateQuery: updateMissingEmployments,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: pageInfo.startCursor,
                  first: undefined,
                  last: Number(perPage),
                },
                updateQuery: updateMissingEmployments,
              });
            }}
          />
        }
      >
        <colgroup>
          <col style={{ width: '40%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '40%' }} />
        </colgroup>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th type="number">
              <FormattedMessage {...commonMessages.personalIdentityNumber} />
            </Th>
            <Th>
              <FormattedMessage {...employmentMessages.employmentEndDate} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {employments.map(({ name, nids, id, employmentEndDate }, key) => (
            <Tr
              linked
              key={key}
              onClick={() => {
                utils.push(pathname);
                push(`/companies/${companyId}/employees/${id}/employments`);
              }}
            >
              <Td>{name}</Td>
              <Td type="number">
                <FormattedNaturalPersonIdentifier nids={nids} />
              </Td>
              <Td>
                {employmentEndDate ? (
                  <FormattedDate value={employmentEndDate} />
                ) : (
                  <NoValue />
                )}
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </CollapsibleSimple>
  );
};
