import { Table, TableRowProps, Td, Th, Tr } from '@frontend/ui';
import React from 'react';

interface TableProps {
  children: React.ReactNode;
}

interface ListTableItemProps extends TableRowProps {
  label: React.ReactNode;
  type?: 'number' | 'input';
}

const ListTableItem: React.FC<ListTableItemProps> = ({
  label,
  type,
  children,
  ...props
}) => (
  <Tr {...props}>
    <Th multiline>{label}</Th>
    <Td align="left" type={type} multiline>
      {children}
    </Td>
  </Tr>
);

export const ListTable: React.FC<TableProps> = ({ children }) => (
  <Table size="xsmall">
    <colgroup>
      <col style={{ width: '50%' }} />
      <col style={{ width: '50%' }} />
    </colgroup>
    {children}
  </Table>
);

export { ListTableItem };
