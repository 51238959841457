import {
  Section,
  SectionHeader,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import {
  employeeSalaryExchangeQuery,
  employeeSalaryExchangeQueryVariables,
  FlexSalaryExchangeRequestStatus,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import {
  salaryExchangeAdminStatusMessages,
  salaryExchangeAgreementMessages,
} from 'app/messages/salary-exchange';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
} from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { AgreementsTable } from './components/AgreementsTable';
import { SalaryExchangeTable } from './components/Table';
import { EMPLOYEE_SALARY_EXCHANGE_QUERY } from './graphql/queries';

const isEmpty = (data?: employeeSalaryExchangeQuery) =>
  !data?.salaryExchanges?.edges.length &&
  !data?.salaryExchangeRequests?.edges.length &&
  !data?.salaryExchangeRequestsHistory?.edges.length;

const hasSalaryExchangeInfo = (
  data?: employeeSalaryExchangeQuery,
): data is employeeSalaryExchangeQuery => !isEmpty(data);

export const SalaryExchange: React.FC = () => {
  const {
    params: { companyId, userAccountId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const { data } = useQuery<
    employeeSalaryExchangeQuery,
    employeeSalaryExchangeQueryVariables
  >(EMPLOYEE_SALARY_EXCHANGE_QUERY, {
    isEmpty,
    suspend: true,
    errorPolicy: 'all',
    variables: {
      companyId,
      userAccountId,
      statuses: [FlexSalaryExchangeRequestStatus.APPROVED],
    },
  });

  if (!hasSalaryExchangeInfo(data)) {
    return null;
  }

  const salaryExchange = data.salaryExchanges.edges[0]?.node;
  const request = data?.salaryExchangeRequests?.edges[0]?.node;
  const requestsHistory = data?.salaryExchangeRequestsHistory?.edges.map(
    edge => edge.node,
  );

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonBenefitMessages.salaryExchange} />
      </SectionHeader>
      {request && (
        <NotificationCard type="info">
          <FormattedMessage
            {...salaryExchangeAdminStatusMessages.willBeActive}
            values={{
              effectiveDate: <FormattedDate value={request.effectiveDate} />,
              monthlyPremium: (
                <FormattedCurrency
                  value={request.premium}
                  currency="SEK"
                  noSuffix
                />
              ),
            }}
          />
        </NotificationCard>
      )}
      {salaryExchange && (
        <Subsection>
          <SalaryExchangeTable salaryExchange={salaryExchange} />
        </Subsection>
      )}
      {!!requestsHistory?.length && (
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage
              {...salaryExchangeAgreementMessages.agreementShortTitle}
            />
          </SubsectionHeader>
          <AgreementsTable requests={requestsHistory} />
        </Subsection>
      )}
    </Section>
  );
};
