// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --md-sys-state-dragged-state-layer-opacity: 0.16;
  --md-sys-state-pressed-state-layer-opacity: 0.12;
  --md-sys-state-focus-state-layer-opacity: 0.12;
  --md-sys-state-hover-state-layer-opacity: 0.08;
}`, "",{"version":3,"sources":["webpack://./../../lib/ui/dist/css/state.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,gDAAgD;EAChD,8CAA8C;EAC9C,8CAA8C;AAChD","sourcesContent":[":root {\n  --md-sys-state-dragged-state-layer-opacity: 0.16;\n  --md-sys-state-pressed-state-layer-opacity: 0.12;\n  --md-sys-state-focus-state-layer-opacity: 0.12;\n  --md-sys-state-hover-state-layer-opacity: 0.08;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
