import { NumberField } from '@frontend/formik';
import { Section, SectionHeader } from '@frontend/ui';
import { suffixMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';

import { jobOfferFormMessages } from '../../messages';
import {
  FormValues as RemunerationFormValues,
  RemunerationFields,
} from './remunerations';

export { validationSchema } from './remunerations';

export interface FormValues extends RemunerationFormValues {
  yearlyVacationDays: string;
  yearlyWellnessAllowance: string;
}

export const initialValues: FormValues = {
  yearlyVacationDays: '',
  yearlyWellnessAllowance: '',
};

export const CompensationFields: React.FC = () => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...jobOfferFormMessages.compensation} />
      </SectionHeader>
      <RemunerationFields />
      <NumberField
        dense
        name="yearlyWellnessAllowance"
        label={<FormattedMessage {...jobOfferFormMessages.wellness} />}
        affix={formatMessage(suffixMessages.krPerYear)}
        decimalScale={0}
        locale={locale}
      />
      <NumberField
        dense
        name="yearlyVacationDays"
        label={<FormattedMessage {...jobOfferFormMessages.vacation} />}
        affix={formatMessage(suffixMessages.days)}
        locale={locale}
      />
    </Section>
  );
};
