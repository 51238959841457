import { ApolloError } from '@apollo/client';
import {
  DatePickerField,
  Form,
  Formik,
  FormikHelpers,
  getIn,
  NumberField,
  SelectField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Figure,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { toNumber } from '@frontend/utils';
import {
  companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType as RemunerationTypeDetails,
  membershipRemunerationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem as Remuneration,
} from 'app/apollo/graphql/types';
import { accountPlanMessages } from 'app/messages/account-plan';
import { commonMessages, validationMessages } from 'app/messages/common';
import { UnitCodeOptions, unitCodeSuffixMessages } from 'app/utils/constants';
import {
  FormattedCurrency,
  FormattedMessage,
  IntlShape,
  useIntl,
} from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React, { useEffect, useMemo } from 'react';
import { isValidMonth } from 'validations';
import * as Yup from 'yup';

export const fields = ['payrollElementId', 'effectiveDate', 'value'];

const formatRemunerationSuffix = (
  intl: IntlShape,
  currency?: string | null,
  unitCode?: string | null,
) => {
  if (!currency) {
    return '';
  }
  const unit = unitCodeSuffixMessages[unitCode ?? ''];

  if (!unit) {
    return currency;
  }
  return `${currency}/${intl.formatMessage(unit)}`;
};

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    payrollElementId: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    effectiveDate: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid date',
        intl.formatMessage(validationMessages.invalidMonth),
        value => isValidMonth(value),
      ),
    value: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface RemunerationModalFormValues {
  effectiveDate: string;
  payrollElementId: string;
  value: string;
}

export type EditEmployeeRemunerationFormValues = RemunerationModalFormValues;

export type Submit = (
  values?: RemunerationModalFormValues,
  helpers?: FormikHelpers<RemunerationModalFormValues>,
) => Promise<void> | void;

interface FormFieldsProps {
  getCurrent: (id?: string) => Remuneration | undefined;
  editId?: string;
  error?: ApolloError;
  isAddEmployeeSubform?: boolean;
  onRequestClose?: () => void;
  parentField?: string;
  remunerationTypeOptions?: readonly RemunerationTypeDetails[];
  submit?: Submit;
}

const FormFields: React.FC<FormFieldsProps> = ({
  getCurrent,
  remunerationTypeOptions,
  error,
  onRequestClose,
  parentField,
  isAddEmployeeSubform,
  submit,
  editId,
}) => {
  const intl = useIntl();
  const { locale } = useIntlContext();
  const { values, isSubmitting, setFieldValue } =
    useFormikContext<EditEmployeeRemunerationFormValues>();

  const selectOptions = remunerationTypeOptions?.map(({ id, name }) => ({
    value: id,
    label: `${id} ${name ?? ''}`,
  }));

  const fieldName = (name: string) =>
    parentField ? `${parentField}.${name}` : name;

  const payrollElementId = getIn(values, fieldName('payrollElementId'));

  const current = useMemo(
    () => getCurrent(payrollElementId),
    [payrollElementId],
  );

  useEffect(() => {
    if (isAddEmployeeSubform) {
      setFieldValue(`${parentField}`, {
        payrollElementId: editId,
      });
    }
  }, []);

  useEffect(() => {
    setFieldValue(fieldName('value'), current?.value ?? '');
  }, [current]);

  const { currency, unitCode } =
    remunerationTypeOptions?.find(option => option.id === payrollElementId) ??
    {};

  return (
    <>
      <ModalHeader>
        <FormattedMessage {...accountPlanMessages.editRemunerationTitle} />
      </ModalHeader>
      <ModalBody>
        <p>
          <FormattedMessage {...accountPlanMessages.editRemunerationDesc} />
        </p>
        {!!error && <GraphQlError inModal error={error} />}
        <SelectField
          fixed
          dense
          name={fieldName('payrollElementId')}
          label={<FormattedMessage {...accountPlanMessages.payrollElement} />}
          options={selectOptions}
          required
        />
        {!!current && (
          <Figure
            description={
              <FormattedMessage {...accountPlanMessages.latestChangeLabel} />
            }
            title={
              <FormattedMessage
                {...accountPlanMessages.latestChangeDesc}
                values={{
                  value: toNumber(current.value) ? (
                    <FormattedCurrency
                      value={toNumber(current.value) ?? 0}
                      currency={current.currency ?? ''}
                      noSuffix={!current.currency}
                      after={
                        current.unitCode &&
                        current.unitCode !== UnitCodeOptions.LS ? (
                          <>
                            /
                            <FormattedMessage
                              select={current.unitCode}
                              messages={unitCodeSuffixMessages}
                            />
                          </>
                        ) : null
                      }
                    />
                  ) : (
                    current.value
                  ),
                  effectiveDate: current.from
                    ? new Date(current.from)
                    : current.from,
                }}
              />
            }
          />
        )}
        <NumberField
          dense
          name={fieldName('value')}
          affix={formatRemunerationSuffix(intl, currency, unitCode)}
          label={<FormattedMessage {...commonMessages.value} />}
          decimalScale={0}
          required
          locale={locale}
        />
        <DatePickerField
          fixed
          dense
          name={fieldName('effectiveDate')}
          type="month"
          label={<FormattedMessage {...commonMessages.effectiveDate} />}
          required
        />
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={onRequestClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button
            text
            type={isAddEmployeeSubform ? 'button' : 'submit'}
            loading={isSubmitting ? true : undefined}
            onClick={() => isAddEmployeeSubform && submit?.()}
          >
            <FormattedMessage {...commonMessages.confirm} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </>
  );
};

interface Props {
  currentRemunerations: Remuneration[];
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: Submit;
  editId?: string;
  error?: ApolloError;
  isAddEmployeeSubform?: boolean;
  parentField?: string;
  remunerationTypeOptions?: readonly RemunerationTypeDetails[];
}

export const UpdateRemunerationModal: React.FC<Props> = ({
  parentField,
  onSubmit,
  isOpen,
  editId,
  currentRemunerations,
  remunerationTypeOptions,
  onRequestClose,
  error,
  isAddEmployeeSubform,
}) => {
  const intl = useIntl();
  const getCurrent = (id?: string) =>
    currentRemunerations.find(remuneration => remuneration.type.id === id);

  const fieldProps: FormFieldsProps = {
    editId,
    isAddEmployeeSubform,
    getCurrent,
    remunerationTypeOptions,
    error,
    onRequestClose,
  };

  if (isAddEmployeeSubform) {
    return (
      <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
        <div role="form">
          <FormFields
            {...fieldProps}
            parentField={parentField}
            submit={onSubmit}
          />
        </div>
      </Modal>
    );
  }

  return (
    <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik<EditEmployeeRemunerationFormValues>
        initialValues={{
          payrollElementId: editId ?? '',
          effectiveDate: '',
          value: getCurrent(editId)?.value ?? '',
        }}
        validationSchema={validationSchema(intl)}
        onSubmit={onSubmit}
      >
        <Form>
          <FormFields {...fieldProps} />
        </Form>
      </Formik>
    </Modal>
  );
};
