import {
  AdvinansOccupationalPension,
  isOccupationalPensionBenefit,
} from '@frontend/benefit-types';
import {
  FlexChangeStatus,
  FlexChangeType,
  InsuranceComponentManagementType,
  InsurancePremiumStatus,
  pensionTableQuery,
  pensionTableQuery_changes as Change,
  pensionTableQuery_changes_FlexOccupationalPensionChange as OccupationalPensionChange,
  pensionTableQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexEpassiBikeEntitlement_benefit_FlexBenefit as Benefit,
  pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance as PensionInsurance,
  pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components as MembershipInsuranceComponents,
  pensionTableQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentRetirement as InsuranceComponentRetirement,
} from 'app/apollo/graphql/types';
import { pensionInsuranceProviderMessages } from 'app/messages/insurance';
import {
  MEMBERSHIP_INSURANCE_PREMIUM_STATUS,
  PENSION_PROVIDER,
} from 'app/utils/constants';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';

import { getPensionInsurance } from '../../utils/get-pension-insurance';
import { isNotSinglePremium } from '../../utils/is-not-single-premium';
import { PremiumEntry } from '../PremiumEntry';

/**
 * Array of change types which should should be considered in
 * premium entries table.
 *
 * As 'FlexOccupationalPensionChange' also includes salary exchange changes
 * we need to amend additinal filtering on change.type
 */
const OCCUPATIONAL_PENSION_TYPES: FlexChangeType[] = [
  FlexChangeType.PENSION_FUTUR,
  FlexChangeType.PENSION_NORDNET,
  FlexChangeType.PENSION_NORDEA,
];

const isOccupationalPensionChange = (
  change: Change,
): change is OccupationalPensionChange =>
  change.__typename === 'FlexOccupationalPensionChange' &&
  OCCUPATIONAL_PENSION_TYPES.includes(change.type);

const isInsuranceComponentRetirement = (
  insuranceComponent: MembershipInsuranceComponents,
): insuranceComponent is InsuranceComponentRetirement =>
  insuranceComponent.__typename === 'InsuranceComponentRetirement';

interface Entries {
  effectiveDate: React.ReactNode;
  mainInsuranceNumber: React.ReactNode;
  premium: React.ReactNode;
  provider: React.ReactNode;
  subInsuranceNumber: React.ReactNode;
  pensionInsurance?: PensionInsurance;
  pensionInsuranceBenefit?: AdvinansOccupationalPension<Benefit>;
  status?: InsurancePremiumStatus | null;
}

export const getPensionTableEntries = (
  data?: pensionTableQuery,
): Entries | null => {
  const currentPensionInsuranceBenefit = data?.entitlements?.edges
    .map(({ node }) => node.benefit)
    .filter(isOccupationalPensionBenefit)[0];

  const upcomingPensionInsuranceBenefit = data?.upcomingEntitlements?.edges
    .map(({ node }) => node.benefit)
    .filter(isOccupationalPensionBenefit)[0];

  const pensionInsuranceBenefit =
    currentPensionInsuranceBenefit ?? upcomingPensionInsuranceBenefit;

  const pensionInsurance = getPensionInsurance(
    data?.membership?.insurances?.edges.map(edge => edge.node) ?? [],
  );

  const firstChange = data?.changes
    ?.filter(isOccupationalPensionChange)
    .find(isNotSinglePremium);

  if (!pensionInsurance && !firstChange) {
    return null;
  }

  if (pensionInsurance) {
    const occupationalPensionComponent = pensionInsurance.components?.find(
      isInsuranceComponentRetirement,
    );

    const status = pensionInsurance.premiumStatus
      ? pensionInsurance.premiumStatus
      : occupationalPensionComponent?.status &&
        MEMBERSHIP_INSURANCE_PREMIUM_STATUS[
          occupationalPensionComponent.status
        ];

    const premium = (
      <PremiumEntry
        pensionInsurance={pensionInsurance}
        status={status}
        firstChange={firstChange}
      />
    );

    return {
      status,
      premium,
      mainInsuranceNumber: pensionInsurance.insuranceNumber,
      subInsuranceNumber: occupationalPensionComponent?.insuranceNumber,
      effectiveDate: pensionInsurance.effectiveDate ? (
        <FormattedDate value={pensionInsurance.effectiveDate} />
      ) : (
        <NoValue />
      ),
      provider: (
        <FormattedMessage
          select={pensionInsurance.institute.id}
          messages={pensionInsuranceProviderMessages}
          values={{
            fundManagement:
              occupationalPensionComponent?.capital?.managementType ===
              InsuranceComponentManagementType.DEPOSITORY,
          }}
        />
      ),
      pensionInsurance,
      pensionInsuranceBenefit,
    };
  }

  if (firstChange) {
    return {
      status:
        firstChange.status === FlexChangeStatus.ERROR
          ? undefined
          : InsurancePremiumStatus.PENDING_ACTIVATION,
      premium: firstChange.premium ? (
        <FormattedCurrency
          value={firstChange.premium}
          currency="SEK"
          after="PER_MONTH"
        />
      ) : (
        <NoValue />
      ),
      effectiveDate: <FormattedDate value={firstChange.effectiveDate} />,
      mainInsuranceNumber: <NoValue />,
      subInsuranceNumber: <NoValue />,
      provider:
        firstChange.type === FlexChangeType.PENSION_FUTUR ? (
          PENSION_PROVIDER.advinans
        ) : firstChange.type === FlexChangeType.PENSION_LF ? (
          PENSION_PROVIDER.lf
        ) : firstChange.type === FlexChangeType.PENSION_NORDNET ? (
          PENSION_PROVIDER.nordnet
        ) : firstChange.type === FlexChangeType.PENSION_NORDEA ? (
          PENSION_PROVIDER.nordea_fund
        ) : (
          <NoValue />
        ),
      pensionInsurance,
      pensionInsuranceBenefit,
    };
  }

  return null;
};
