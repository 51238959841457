import { Formik } from '@frontend/formik';
import {
  addBenefitsToBenefitPackageQuery,
  addBenefitsToBenefitPackageQueryVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { MatchParams } from 'app/pages/companies/company/benefit-packages/benefit-package';
import { useQuery } from 'app/utils/use-query';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { format } from 'date-fns';
import React from 'react';
import { useRouteMatch } from 'react-router';

import {
  CreateBenefitAssistChip,
  CreateBenefitModal,
} from '../../benefits/create';
import { AddBenefitsForm, FormValues, validationSchema } from './form';
import { ADD_BENEFITS_TO_BENEFIT_PACKAGE_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

interface Props {
  parentLink: string;
}

export const AddBenefitsToBenefitPackage: React.FC<Props> = ({
  parentLink,
}) => {
  const { params } = useRouteMatch<MatchParams>();

  const { locale } = useIntlContext();

  const intl = useIntl();

  const { benefitPackageId, companyId } = params;

  const { data, loading, error } = useQuery<
    addBenefitsToBenefitPackageQuery,
    addBenefitsToBenefitPackageQueryVariables
  >(ADD_BENEFITS_TO_BENEFIT_PACKAGE_QUERY, {
    errorPolicy: 'all',
    variables: {
      companyId,
      onlyGetBenefits: true,
      locale,
    },
  });

  const { submit, submissionError } = useSubmit({
    benefitPackageId,
    companyId,
    parentLink,
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company?.benefitPackages || !data?.company?.benefits) {
    return <EmptyState error={error} />;
  }

  const allBenefits = data.company.benefits.edges.map(({ node }) => node);

  if (!allBenefits.length) {
    return (
      <>
        <ChipsWrapper>
          <CreateBenefitAssistChip />
        </ChipsWrapper>
        <EmptyState
          title={<FormattedMessage {...commonBenefitMessages.noBenefits} />}
        >
          <FormattedMessage {...commonBenefitMessages.noBenefitsText} />
        </EmptyState>
        <CreateBenefitModal />
      </>
    );
  }

  const allBenefitPackages = data.company.benefitPackages.edges.map(
    ({ node }) => node,
  );

  const initialValues = {
    benefits: Object.fromEntries(
      allBenefits.map(benefit => [
        benefit.id,
        benefit.entitlementRules?.benefitPackageIds.includes(
          benefitPackageId,
        ) ?? false,
      ]),
    ),
    effectiveFrom: format(new Date(), 'yyyy-MM'),
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={values => submit(initialValues, values)}
      validationSchema={validationSchema(intl)}
    >
      <AddBenefitsForm
        allBenefits={allBenefits}
        allBenefitPackages={allBenefitPackages}
        companyId={companyId}
        managedBy={data.company.managedBy}
        submissionError={submissionError}
      />
    </Formik>
  );
};
