import { defineMessages } from 'react-intl';

export const imageUploadMessages = defineMessages({
  dragAndDrop: {
    id: 'imageUploadMessages.dragAndDrop',
    defaultMessage: 'Dra och släpp',
  },
  imageUploadDescription: {
    id: 'imageUploadMessages.imageUploadDescription',
    defaultMessage:
      'Ladda upp en högupplöst bild i formatet .jpg, .png eller .svg',
  },
  imageUploadError: {
    id: 'imageUploadMessages.imageUploadError',
    defaultMessage:
      'Bilden kunde inte laddas upp. Vänligen kontrollera att formatet är .jpg, .png eller .svg. Du kan bara ladda upp en bild.',
  },
});
