import { ContentContainer, Section, SectionHeader } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { Page } from 'features/page';
import { Employee } from 'features/sme/companies/company/proposals/edit/employees/employee';
import { EditEmployeeAssistChip } from 'features/sme/companies/company/proposals/edit/employees/employee/edit';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams } from './';

interface Props {
  title: string;
}

export const SmeCompanyProposalEmployeeRoute: React.FC<Props> = ({ title }) => {
  const { params } = useRouteMatch<MatchParams>();
  const parentLink = `/sme/${params.companyId}/proposals/${params.proposalId}/employees`;

  return (
    <Page title={title} parentLink={parentLink}>
      <Suspense fallback={<TopLoading />}>
        {({ errors }) => (
          <>
            {errors && <GraphQlErrors errors={errors} />}
            <ContentContainer>
              <Section>
                <SectionHeader>
                  <FormattedMessage {...smeEmployeesMessages.details} />
                </SectionHeader>
                <EditEmployeeAssistChip
                  to={`/sme/${params.companyId}/proposals/${params.proposalId}/employees/${params.employeeId}/edit`}
                />
                <Employee />
              </Section>
            </ContentContainer>
          </>
        )}
      </Suspense>
    </Page>
  );
};
