import { TotalCompensationId2 as TotalCompensationId } from 'app/apollo/graphql/types';

export const TOTAL_COMP_ID_SORT_ORDER: TotalCompensationId[] = [
  TotalCompensationId.NET_SALARY,
  TotalCompensationId.GROSS_SALARY,
  TotalCompensationId.SALARY_EXCHANGE_DEDUCTION,
  TotalCompensationId.INCOME_TAX,
  TotalCompensationId.SPECIAL_PAYROLL_TAX,
  TotalCompensationId.VARIABLE_SALARY,
  TotalCompensationId.PENSION,
  TotalCompensationId.SALARY_EXCHANGE,
  TotalCompensationId.BTP1,
  TotalCompensationId.ITP,
  TotalCompensationId.ITP1,
  TotalCompensationId.ITPK,
  TotalCompensationId.SAFLO,
  TotalCompensationId.KAPKL,
  TotalCompensationId.PAKFS09,
  TotalCompensationId.AKAPKL,
  TotalCompensationId.AKAPKR,
  TotalCompensationId.COMPANY_CAR,
  TotalCompensationId.LUNCH_ALLOWANCE,
];
