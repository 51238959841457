import { useField } from '@frontend/formik';
import { Subsection, SubsectionHeader } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import React from 'react';

import { PremiumMatrixTable } from '../components/PremiumMatrixTable';
import { PremiumMatrixFormValues } from '../types';

interface Props {
  name: string;
}

export const StaticPremiumMatrixSection: React.FC<Props> = ({ name }) => {
  const [{ value }] = useField<PremiumMatrixFormValues>(name);

  return (
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...smeBenefitFormMessages.premiumMatrix} />
      </SubsectionHeader>
      <PremiumMatrixTable matrix={value} />
    </Subsection>
  );
};
