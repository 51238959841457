import gql from 'graphql-tag';

export const EMPLOYMENT_DETAILS_QUERY = gql`
  query employmentDetailsQuery($id: ID!) {
    employment(id: $id) {
      id
      period
      rate
      membership {
        id
        userAccountId
        givenName
        lastName
      }
    }
  }
`;
