import { companyBookkeepingAccountsQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: companyBookkeepingAccountsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: companyBookkeepingAccountsQuery;
  },
) => {
  if (
    !previousResult?.company?.bookkeepingAccounts ||
    !fetchMoreResult?.company?.bookkeepingAccounts
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.bookkeepingAccounts;

  return {
    company: {
      ...previousResult.company,
      bookkeepingAccounts: {
        pageInfo,
        __typename: previousResult.company.bookkeepingAccounts.__typename,
        edges: [...edges],
      },
    },
  };
};
