import { Chip, Icon, Table, Td, Th, Tr } from '@frontend/ui';
import { addCircle, cancel, edit, flightTakeoff } from '@frontend/ui/icons';
import {
  administratorDetails as Administrator,
  MembershipRole,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { EMPLOYEE_ROLE } from 'app/utils/constants';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { GraphQlError } from 'components/GraphQlError';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import { useCurrentUser } from 'contexts/current-permissions';
import React from 'react';
import { impersonate } from 'utils/impersonate';

import { useDelete } from '../../remove/utils/use-delete';

interface Props {
  administrators: Administrator[];
  companyId: string;
  navigation: React.ReactNode;
  openEditModal: (
    userAccountId?: string,
    membershipRoles?: Array<MembershipRole>,
  ) => void;
}

export const AdministratorsTable: React.FC<Props> = ({
  administrators,
  navigation,
  companyId,
  openEditModal,
}) => {
  const { permissions } = useCurrentUser();
  const { formatMessage } = useIntl();

  const { _delete, error } = useDelete();

  const canEditAdministrator = hasBackstagePermission(
    'membership:role',
    'write',
    permissions,
  );

  return (
    <>
      {error && <GraphQlError error={error} />}
      {canEditAdministrator && (
        <ChipsWrapper>
          <Chip
            clickable
            text={<FormattedMessage {...companyMessages.addAdministrator} />}
            leadingIcon={<Icon icon={addCircle} color="primary" decorative />}
            onClick={() => openEditModal()}
          />
        </ChipsWrapper>
      )}
      <Table size="xsmall" navigation={navigation}>
        <thead>
          <Tr withInlineMenuTh={canEditAdministrator}>
            <Th>
              <FormattedMessage {...commonMessages.nid} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th>
              <FormattedMessage {...companyMessages.membershipRole} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {administrators.length > 0 ? (
            administrators.map(node => (
              <Tr
                key={node.id}
                inlineMenu={
                  canEditAdministrator && (
                    <KebabMenu fixed>
                      <MenuItem
                        icon={<Icon icon={edit} decorative />}
                        title={
                          <FormattedMessage
                            {...companyMessages.editAdministrator}
                          />
                        }
                        onClick={() =>
                          openEditModal(node.userAccountId, [...node.roles])
                        }
                      />
                      <MenuItem
                        icon={<Icon icon={cancel} decorative />}
                        title={
                          <FormattedMessage
                            {...companyMessages.removeAdministrator}
                          />
                        }
                        onClick={() => _delete(node.userAccountId, companyId)}
                      />
                      <MenuItem
                        icon={<Icon icon={flightTakeoff} decorative />}
                        title={
                          <FormattedMessage {...companyMessages.impersonate} />
                        }
                        onClick={() =>
                          impersonate({ userAccountId: node.userAccountId })
                        }
                      />
                    </KebabMenu>
                  )
                }
              >
                <Td>
                  <FormattedNaturalPersonIdentifier
                    nids={node.naturalPersonIdentifiers}
                  />
                </Td>
                <Td>
                  {node.firstName} {node.lastName}
                </Td>
                <Td>
                  {node.roles
                    .map(role => formatMessage(EMPLOYEE_ROLE[role]))
                    .join(', ')}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={3}>
                <FormattedMessage {...companyMessages.noAdministrators} />
              </Td>
            </Tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
