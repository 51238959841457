import {
  smeCompanyEmployeeQuery,
  smeCompanyEmployeeQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal/employee';
import { useQuery } from 'app/utils/use-query';
import React from 'react';
import { useParams } from 'react-router';

import { SME_COMPANY_EMPLOYEE_QUERY } from './graphql/queries';
import { InformationTable } from './InformationTable';

export const Employee: React.FC = () => {
  const { employeeId } = useParams<MatchParams>();
  const { data } = useQuery<
    smeCompanyEmployeeQuery,
    smeCompanyEmployeeQueryVariables
  >(SME_COMPANY_EMPLOYEE_QUERY, {
    variables: {
      id: employeeId,
    },
    errorPolicy: 'all',
    suspend: true,
  });

  if (!data?.proposalMembership) {
    return null;
  }

  const { proposalMembership } = data;

  return <InformationTable employee={proposalMembership} />;
};
