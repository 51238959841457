import { SelectOption } from '@frontend/ui';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';

/**
 * Retrieves the array of valid proposal start date options. All options are the first day of the month,
 * starting from the current month if the current day is the 15th or later. The array should at most contain
 * start dates for the next 12 months.
 *
 * @returns the array of select options for proposal start date
 */
export const getProposalStartDateOptions = (today: Date): SelectOption[] => {
  const dateOffset = today.getDate() > 15 ? 1 : 0;

  return Array.from(Array(12).keys()).map(i => {
    const month = format(
      startOfMonth(addMonths(today, i + dateOffset)),
      'yyyy-MM-dd',
    );
    return {
      value: month,
      label: month,
    };
  });
};
