import { formatNaturalPersonIdentifier } from '@frontend/utils';
import { NoValue } from 'components/NoValue';
import React from 'react';

interface Props {
  nid?: string;
  nids?: readonly string[];
}

export const FormattedNaturalPersonIdentifier: React.FC<Props> = ({
  nid,
  nids,
}) => {
  const _nid = nids?.[0] ?? nid;
  try {
    if (!_nid) {
      throw new Error();
    }
    const formattedNid = formatNaturalPersonIdentifier(_nid);
    return <span>{formattedNid}</span>;
  } catch {
    return <NoValue />;
  }
};
