import { Card as C } from '@frontend/ui';
import { CardLink } from 'components/CardLink';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

export const Card = styled(C)<{ fullHeight?: boolean }>`
  ${p => p.fullHeight && `height: 100%;`};
`;

interface Props extends LinkProps {
  children: React.ReactElement;
  fullHeight?: boolean;
}

export const LinkedCard: React.FC<Props> = ({
  children,
  fullHeight,
  ...props
}) => (
  <Card fullHeight={fullHeight}>
    <CardLink {...props}>{children}</CardLink>
  </Card>
);
