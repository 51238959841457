import { EmployeeHistoryEventAuthorType } from 'app/apollo/graphql/types';
import { employeeHistoryEventAuthorMessages } from 'app/messages/employees';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

interface Props {
  name: string | null;
  type: EmployeeHistoryEventAuthorType;
}

export const EventAuthor: React.FC<Props> = ({ type, name }) => (
  <FormattedMessage
    select={type}
    messages={employeeHistoryEventAuthorMessages}
    values={{
      noValue: <NoValue />,
      name,
    }}
  />
);
