import {
  APP_BAR_HEIGHT,
  Drawer as DrawerUI,
  Icon,
  IconButton,
  ScrollableMenuArea as ScrollableMenuAreaComponent,
} from '@frontend/ui';
import {
  business,
  construction,
  downhillSkiing,
  homeFilled,
  menuOpen,
  people,
  shield,
} from '@frontend/ui/icons';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages } from 'app/messages/common';
import { employeeMessages } from 'app/messages/employees';
import { insuranceMessages } from 'app/messages/insurance';
import { menuMessages } from 'app/messages/menu';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { useIsSmallScreen } from 'app/utils/use-is-small-screen';
import { FormattedMessage, useIntl } from 'components/formats';
import { MenuItem } from 'components/MenuItem';
import { useNavigationContext } from 'components/NavigationDrawer/containers/NavigationContext';
import { useCurrentUser } from 'contexts/current-permissions';
import { messages } from 'features/layout/messages';
import { PortalLogo } from 'features/layout/PortalLogo';
import { smeCompanyMessages } from 'features/sme/messages/sme';
import React from 'react';
import styled from 'styled-components';

import { SMALL_SCREEN_RESOLUTION } from '../../app/utils/constants';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const Drawer = styled(DrawerUI)`
  padding-left: env(safe-area-inset-right, 0.75rem);
`;

const ScrollableMenuArea = styled(ScrollableMenuAreaComponent)<{
  withOffsetTop?: boolean;
}>`
  ${p => p.withOffsetTop && `margin-top: ${APP_BAR_HEIGHT}px`};
`;

const Ul = styled.ul`
  padding: 0.25rem;
  margin: 0;

  a {
    margin: 0.1875rem 0;
  }
`;

export const NavigationDrawer: React.FC = () => {
  const isSmallScreen = useIsSmallScreen(SMALL_SCREEN_RESOLUTION);

  const { formatMessage } = useIntl();
  const { isDrawerOpen, setIsDrawerOpen } = useNavigationContext();
  const { permissions } = useCurrentUser();

  if (!isSmallScreen && !isDrawerOpen) {
    return null;
  }

  const withOffsetTop = !isSmallScreen && isDrawerOpen;

  return (
    <Drawer
      modal={isSmallScreen}
      open={!!isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      aria-hidden={!isDrawerOpen}
      id="navigation-drawer"
    >
      <nav>
        <ScrollableMenuArea withOffsetTop={withOffsetTop}>
          {isSmallScreen && (
            <HeaderWrapper>
              <IconButton
                icon={menuOpen}
                label={formatMessage(messages.openDrawer)}
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              />
              <PortalLogo />
            </HeaderWrapper>
          )}
          <Ul role="menu">
            <MenuItem
              navigation
              icon={<Icon icon={homeFilled} size="medium" decorative />}
              link="/"
              title={<FormattedMessage {...menuMessages.home} />}
              exact
            />
            {hasBackstagePermission(
              'backstageApp',
              'advisorTab',
              permissions,
            ) && (
              <MenuItem
                navigation
                icon={<Icon icon={downhillSkiing} size="medium" decorative />}
                link="/advisor"
                title={<FormattedMessage {...advisorMessages.advisor} />}
              />
            )}
            {hasBackstagePermission(
              'backstageApp',
              'employeesTab',
              permissions,
            ) && (
              <MenuItem
                navigation
                icon={<Icon icon={people} size="medium" decorative />}
                link="/employees"
                title={<FormattedMessage {...employeeMessages.employees} />}
              />
            )}
            {hasBackstagePermission(
              'backstageApp',
              'companiesTab',
              permissions,
            ) && (
              <MenuItem
                navigation
                icon={<Icon icon={construction} size="medium" decorative />}
                link="/companies"
                title={<FormattedMessage {...commonMessages.company} />}
              />
            )}
            {hasBackstagePermission(
              'backstageApp',
              'insurancesTab',
              permissions,
            ) && (
              <MenuItem
                navigation
                icon={<Icon icon={shield} size="medium" decorative />}
                link="/insurances"
                title={<FormattedMessage {...insuranceMessages.insurances} />}
              />
            )}
            {hasBackstagePermission('backstageApp', 'smeTab', permissions) && (
              <MenuItem
                navigation
                icon={<Icon icon={business} size="medium" decorative />}
                link="/sme"
                title={<FormattedMessage {...smeCompanyMessages.proposal} />}
              />
            )}
          </Ul>
        </ScrollableMenuArea>
      </nav>
    </Drawer>
  );
};
