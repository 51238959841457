import { defineMessages } from 'react-intl';

export const accountPlanMessages = defineMessages({
  createAbsenceType: {
    id: 'accountPlanMessages.createAbsenceType',
    description: 'Create absence type',
    defaultMessage: 'Skapa frånvarotyp',
  },
  absenceTypes: {
    id: 'accountPlanMessages.absenceTypes',
    description: 'Absence types',
    defaultMessage: 'Frånvarotyper',
  },
  searchAbenceTypes: {
    id: 'accountPlanMessages.searchAbenceTypes',
    description: 'Search absence types',
    defaultMessage: 'Sök frånvarotyper...',
  },
  createBalanceType: {
    id: 'accountPlanMessages.createBalanceType',
    description: 'Create balance type',
    defaultMessage: 'Skapa ackumulator',
  },
  balanceTypes: {
    id: 'accountPlanMessages.balanceType',
    description: 'Balance types',
    defaultMessage: 'Ackumulatorer',
  },
  searchBalanceTypes: {
    id: 'accountPlanMessages.searchBalanceTypes',
    description: 'Search balance types',
    defaultMessage: 'Sök ackumulatorer...',
  },
  bookkeepingTypes: {
    id: 'accountPlanMessages.bookkeepingTypes',
    description: 'Bookkeeping types',
    defaultMessage: 'Bokföringskonton',
  },
  editBookkeepingTitle: {
    id: 'accountPlanMessages.editBookkeepingTitle',
    description: 'Edit bookkeeping title',
    defaultMessage: 'Ändra bokföringskonto',
  },
  createRemunerationType: {
    id: 'accountPlanMessages.createRemunerationType',
    description: 'Create remuneration type',
    defaultMessage: 'Skapa löneart',
  },
  remunerationTypes: {
    id: 'accountPlanMessages.remunerationTypes',
    description: 'Remuneration types',
    defaultMessage: 'Lönearter',
  },
  searchRemunerationTypes: {
    id: 'accountPlanMessages.searchRemunerationTypes',
    description: 'Search Rremuneration types',
    defaultMessage: 'Sök lönearter...',
  },
  codeColumnLabel: {
    id: 'accountPlanMessages.codeColumnLabel',
    description: 'Code column label',
    defaultMessage: 'Kod',
  },
  codeColumnHelperText: {
    id: 'accountPlanMessages.codeColumnHelperText',
    description: 'Code column helper text',
    defaultMessage: 'Unik kod i systemet',
  },
  nameColumnLabel: {
    id: 'accountPlanMessages.nameColumnLabel',
    description: 'Name column label',
    defaultMessage: 'Namn',
  },
  unitCodeColumnLabel: {
    id: 'accountPlanMessages.unitCodeColumnLabel',
    description: 'Unit code column label',
    defaultMessage: 'Ersättningsenhet',
  },
  unitCodeColumnHelperText: {
    id: 'accountPlanMessages.unitCodeColumnHelperText',
    description: 'Unit code column helper text',
    defaultMessage:
      'Om värdet anges som engångsbelopp eller per tidsenhet (t.ex. per månad eller per år)',
  },
  currencyColumnLabel: {
    id: 'accountPlanMessages.currencyColumnLabel',
    description: 'Currency column label',
    defaultMessage: 'Valutakod',
  },
  transactionLabel: {
    id: 'accountPlanMessages.transactionColumnLabel',
    description: 'Transaction label',
    defaultMessage: 'Transaktion',
  },
  accountLabel: {
    id: 'accountPlanMessages.accountColumnLabel',
    description: 'Account column label',
    defaultMessage: 'Konto',
  },
  accountUpdateSuccess: {
    id: 'accountPlanMessages.accountUpdateSuccess',
    description: 'Account update success',
    defaultMessage: '{account} har uppdaterats',
  },
  noBalanceTypes: {
    id: 'accountPlanMessages.noBalanceTypes',
    defaultMessage:
      'Inga ackumulatorer{hasSearch, select, true { matchar sökningen} other {}}',
  },
  noRemunerationTypes: {
    id: 'accountPlanMessages.noRemunerationTypes',
    defaultMessage:
      'Inga lönearter{hasSearch, select, true { matchar sökningen} other {}}',
  },
  noBookkeepingAccounts: {
    id: 'accountPlanMessages.noBookkeepingAccounts',
    defaultMessage: 'Inga bokföringskonton',
  },
});

export const createAccountPlanSuccessMessages = defineMessages({
  BALANCE: {
    id: 'createAccountPlanSuccessMessages.BALANCE',
    defaultMessage: 'Ackumulatorn har skapats',
  },
  REMUNERATION: {
    id: 'createAccountPlanSuccessMessages.REMUNERATION',
    defaultMessage: 'Lönearten har skapats',
  },
});

export const createAccountPlanTitleMessages = defineMessages({
  BALANCE: {
    id: 'createAccountPlanTitleMessages.BALANCE',
    defaultMessage: 'Skapa ackumulator',
  },
  REMUNERATION: {
    id: 'createAccountPlanTitleMessages.REMUNERATION',
    defaultMessage: 'Skapa löneart',
  },
});
