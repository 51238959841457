import gql from 'graphql-tag';

export const VIOLATED_SALARY_EXCHANGES_BANNER_QUERY = gql`
  query violatedSalaryExchangesBannerQuery(
    $companyId: ID!
    $statuses: [FlexSalaryExchangeStatus!]
    $requestStatuses: [FlexSalaryExchangeRequestStatus!]
    $onlyViolated: Boolean
  ) {
    salaryExchanges(
      companyId: $companyId
      statuses: $statuses
      onlyViolated: $onlyViolated
    ) {
      totalCount
    }
    salaryExchangeRequests(
      companyId: $companyId
      statuses: $requestStatuses
      onlyViolated: $onlyViolated
    ) {
      totalCount
    }
  }
`;
