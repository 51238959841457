import gql from 'graphql-tag';

export const COMPANY_EDIT_BENEFIT_QUERY = gql`
  query companyEditBenefitQuery($benefitId: ID!, $companyId: ID!) {
    flexBenefit(id: $benefitId) {
      id
      type
      configEffectiveDate
      configuration {
        data
      }
      content {
        locale
      }
      entitlementRules {
        accountingCostIds
        benefitPackageIds
        ouIds
        from
      }
    }
    company(id: $companyId) {
      id
      agreements(status: ACTIVE) {
        id
        ... on AgreementEuroAccident {
          subAgreementType
        }
        ... on AgreementRequestEuroAccident {
          subAgreementType
        }
      }
      benefitPackages {
        edges {
          node {
            benefits(onlyGetBenefits: true) {
              edges {
                node {
                  id
                  type
                }
              }
            }
            id
            name
          }
        }
      }
      managedBy
      remunerationTypes {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
