import { SelectOption } from '@frontend/ui';
import { capitalize } from '@frontend/utils';
import { IntlShape } from 'components/formats';

interface Args {
  intl: IntlShape;
}

/**
 * Get's month options for a select component with localized month names.
 */
export const getSelectComponentMonthOptions = (args: Args): SelectOption[] => {
  const { formatDate } = args.intl;

  return [...Array(12).keys()].map(i => ({
    label: capitalize(formatDate(new Date().setMonth(i), { month: 'long' })),
    value: i + 1,
  }));
};
