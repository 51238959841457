import { defineMessages } from 'react-intl';

export const sharedFileMessages = defineMessages({
  sharedFiles: {
    id: 'sharedFile.sharedFiles',
    defaultMessage: 'Delade filer',
  },
  noSharedFiles: {
    id: 'sharedFile.noSharedFiles',
    defaultMessage: 'Inga delade filer',
  },
  name: {
    id: 'sharedFile.name',
    defaultMessage: 'Namn',
  },
  date: {
    id: 'sharedFile.date',
    defaultMessage: 'Datum',
  },
  uploadedBy: {
    id: 'sharedFile.uploadedBy',
    defaultMessage: 'Uppladdad av',
  },
  deleteFile: {
    id: 'sharedFile.deleteFile',
    defaultMessage: 'Ta bort fil',
  },
  confirmDeleteSharedFile: {
    id: 'sharedFile.confirmDeleteSharedFile',
    defaultMessage:
      'Är du säker på att du vill ta bort filen “{filename}”? Det går ej att ångra en borttagen fil utan måste i så fall laddas upp på nytt.',
  },
  deleteSharedFileSuccess: {
    id: 'sharedFile.deleteSharedFileSuccess',
    defaultMessage: 'Filen har tagits bort',
  },
  uploadFile: {
    id: 'sharedFile.uploadFile',
    defaultMessage: 'Ladda upp fil',
  },
  uploadFileSucess: {
    id: 'sharedFile.uploadFileSucess',
    defaultMessage: 'Filen har laddats upp',
  },
  selectFile: {
    id: 'sharedFile.selectFile',
    defaultMessage: 'Välj fil',
  },
  uploadHelperText: {
    id: 'sharedFile.uploadHelperText',
    defaultMessage:
      'Dra och släpp en fil i formatet JPG, PNG, DOC, TXT, PDF, Excel- eller CSV-filer',
  },
  gdprCheckbox: {
    id: 'sharedFile.gdprCheckbox',
    defaultMessage:
      'Filen innehåller personlig eller annan GDPR-känslig information',
  },
});
