import { ApolloError, useMutation } from '@apollo/client';
import {
  terminateSalaryExchangeMutation,
  terminateSalaryExchangeMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';
import { useId, useState } from 'react';
import { normalizeWhiteSpace } from 'validations';

import { companySalaryExchangeMessages } from '../../messages';
import { TERMINATE_SALARY_EXCHANGE_MUTATION } from '../graphql/mutations';
import { FormValues } from '../Table';

interface Success {
  userAccountId: string;
  error?: never;
}
interface Error {
  error: ApolloError;
  userAccountId?: never;
}

export type Result = Success | Error;

export const isError = (result: Result): result is Error => 'error' in result;

interface Props {
  onCompleted: () => void;
}

interface Submit {
  results: Result[];
  submit: (values: FormValues) => Promise<void>;
}

export const useSubmit = ({ onCompleted }: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const { confirm } = useConfirm();

  const [results, setResults] = useState<Result[]>([]);
  const batchId = useId();

  const [terminateSalaryExchange] = useMutation<
    terminateSalaryExchangeMutation,
    terminateSalaryExchangeMutationVariables
  >(TERMINATE_SALARY_EXCHANGE_MUTATION, {
    context: { batch: true, batchId },
  });

  const submit = async (values: FormValues) => {
    const confirmed = await confirm({
      title: formatMessage(companySalaryExchangeMessages.confirmTerminateTitle),
      description: formatMessage(
        companySalaryExchangeMessages.confirmTerminate,
      ),
    });

    if (!confirmed) {
      return;
    }

    try {
      const settledResults = await Promise.allSettled(
        Object.entries(values.salaryExchanges).map(salaryExchange => {
          const [, { reason, salaryExchangeId }] = salaryExchange;
          return terminateSalaryExchange({
            variables: {
              input: {
                reason: normalizeWhiteSpace(reason),
                id: salaryExchangeId,
              },
            },
          });
        }),
      );

      const _results = settledResults.reduce<Result[]>(
        (acc, result) =>
          result.status === 'rejected'
            ? [...acc, { error: result.reason }]
            : [
                ...acc,
                {
                  userAccountId:
                    result.value.data?.flexTerminateSalaryExchange
                      .salaryExchange.userAccountId ?? '',
                },
              ],
        [],
      );

      setResults(_results);
      const submissionErrors = _results.filter(isError);
      const successCount = _results.length - submissionErrors.length;
      if (successCount) {
        send({
          message: formatMessage(
            companySalaryExchangeMessages.canceledSalaryExchangesCount,
            {
              count: successCount,
            },
          ),
          type: 'success',
        });
      }
      if (!submissionErrors.length) {
        onCompleted();
      }
    } catch {
      // Do nothing
    }
  };

  return { submit, results };
};
