import { useMutation } from '@apollo/client';
import { FieldWrapper, InlineSwitchFieldTd, Table, Td, Tr } from '@frontend/ui';
import {
  benefitPackageSettingsQuery,
  benefitPackageSettingsQueryVariables,
  updateBenefitPackageSettingsMutation,
  updateBenefitPackageSettingsMutationVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { MatchParams } from 'app/pages/companies/company/benefit-packages/benefit-package';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import React, { useId } from 'react';
import { useRouteMatch } from 'react-router';

import { UPDATE_BENEFIT_PACKAGE_SETTINGS_MUTATION } from './graphql/mutations';
import { BENEFIT_PACKAGE_SETTINGS_QUERY } from './graphql/queries';

export const BenefitPackageSettings: React.FC = () => {
  const benefitPortalId = useId();
  const totalCompensationId = useId();

  const { params } = useRouteMatch<MatchParams>();
  const { benefitPackageId } = params;

  const { data } = useQuery<
    benefitPackageSettingsQuery,
    benefitPackageSettingsQueryVariables
  >(BENEFIT_PACKAGE_SETTINGS_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    variables: { benefitPackageId },
  });

  const [update, { loading: updateLoading, error: updateError }] = useMutation<
    updateBenefitPackageSettingsMutation,
    updateBenefitPackageSettingsMutationVariables
  >(UPDATE_BENEFIT_PACKAGE_SETTINGS_MUTATION);

  if (!data?.benefitPackage) {
    return null;
  }

  const { hasBenefitPortal, showTotalCompensation } = data.benefitPackage;

  return (
    <>
      {updateLoading && <TopLoading />}
      <FieldWrapper>
        <Table size="xsmall">
          <tbody>
            <Tr>
              <Td id={benefitPortalId}>
                <FormattedMessage
                  {...commonBenefitMessages.activateBenefitPortal}
                />
              </Td>
              <InlineSwitchFieldTd
                checked={hasBenefitPortal}
                onChange={() => {
                  const _hasBenefitPortal = !hasBenefitPortal;

                  update({
                    variables: {
                      input: {
                        benefitPackageId,
                        hasBenefitPortal: _hasBenefitPortal,
                      },
                    },
                    optimisticResponse: {
                      updateBenefitPackage: {
                        benefitPackage: {
                          __typename: 'BenefitPackage',
                          id: benefitPackageId,
                          showTotalCompensation,
                          hasBenefitPortal: _hasBenefitPortal,
                        },
                      },
                    },
                  });
                }}
                labelId={benefitPortalId}
              />
            </Tr>
            <Tr>
              <Td id={totalCompensationId}>
                <FormattedMessage
                  {...commonBenefitMessages.showTotalCompensation}
                />
              </Td>
              <InlineSwitchFieldTd
                checked={showTotalCompensation}
                onChange={() => {
                  const _showTotalCompensation = !showTotalCompensation;

                  update({
                    variables: {
                      input: {
                        benefitPackageId,
                        showTotalCompensation: _showTotalCompensation,
                      },
                    },
                    optimisticResponse: {
                      updateBenefitPackage: {
                        benefitPackage: {
                          __typename: 'BenefitPackage',
                          id: benefitPackageId,
                          hasBenefitPortal,
                          showTotalCompensation: _showTotalCompensation,
                        },
                      },
                    },
                  });
                }}
                labelId={totalCompensationId}
              />
            </Tr>
          </tbody>
        </Table>
      </FieldWrapper>
      {updateError && <GraphQlError error={updateError} />}
    </>
  );
};
