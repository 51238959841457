import { Td, Tr } from '@frontend/ui';
import { companyBillingConfigurationDetails } from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import React from 'react';
import styled from 'styled-components';

import { billingMessages } from '../../messages';

const StyledTd = styled(Td)`
  overflow: visible;
  vertical-align: middle;
`;

interface Props {
  billingEmail: companyBillingConfigurationDetails;
  onClick: () => void;
}

export const BillingMailRow: React.FC<Props> = ({ billingEmail, onClick }) => {
  const intl = useIntl();

  return (
    <Tr onClick={onClick} linked>
      <StyledTd>{billingEmail.email}</StyledTd>
      <StyledTd align="left">
        {billingEmail.attachmentFormats
          .map(attachmentFormat =>
            intl.formatMessage(billingMessages.formats, {
              format: attachmentFormat,
            }),
          )
          .join(', ')}
      </StyledTd>
    </Tr>
  );
};
