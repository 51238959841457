import { ApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  emptyStringToUndefined,
  objectMap,
  toEffectiveUntil,
  toMoney,
  toNumber,
} from '@frontend/utils';
import {
  CreateMembershipInput,
  createMembershipMutation,
  createMembershipMutationVariables,
} from 'app/apollo/graphql/types';
import { employeeFormMessages } from 'app/messages/employees';
import { formSubmitMessages } from 'app/messages/form';
import { MatchParams } from 'app/pages/companies/company/employees/employee/add';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';
import { monthToDate } from 'validations';

import { COMPANY_EMPLOYEES_OVERVIEW_QUERY } from '../graphql/queries';
import { CREATE_MEMBERSHIP_MUTATION } from './graphql/mutations';
import { FormValues } from './index';
import { getEmployeeWarningMessage } from './utils/get-employee-warning-message';

interface Submit {
  submit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { send } = useNotification();
  const { params } = useRouteMatch<MatchParams>();
  const { confirm } = useConfirm();

  const [createMembership, { error: submissionError }] = useMutation<
    createMembershipMutation,
    createMembershipMutationVariables
  >(CREATE_MEMBERSHIP_MUTATION, {
    errorPolicy: 'all',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: COMPANY_EMPLOYEES_OVERVIEW_QUERY,
        variables: {
          id: params.companyId,
        },
      },
    ],
  });

  const submit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    const warningMsg = getEmployeeWarningMessage(
      values.birthdate,
      intl,
      'submit',
    );
    const confirmEmployeeAge = warningMsg
      ? await confirm({
          title: formatMessage(employeeFormMessages.confirmEmployeeAgeTitle),
          description: warningMsg,
        })
      : true;

    if (!confirmEmployeeAge) {
      return;
    }

    setSubmitting(true);
    const _values = objectMap(values, emptyStringToUndefined);

    const {
      benefitPackageEffectiveDate,
      benefitPackageId,
      birthdate,
      city,
      collectiveAgreement,
      companyCarMonthlyValue,
      confirmedAgreement,
      costCenterId,
      email,
      employeeId,
      employmentEffectiveDate,
      employmentEffectiveThrough,
      fitnessContributionBalance,
      givenName,
      incomeTaxTable,
      isOwner,
      lastName,
      line1,
      nid,
      nidOptOut,
      organizationalUnitId,
      phone,
      postalCode,
      remunerations,
      sendWelcomeEmail,
      sex,
      vacationDaysAdvance,
      vacationDaysPaid,
      vacationDaysPerYear,
      vacationDaysSaved,
      vacationDaysUnpaid,
    } = _values;

    try {
      if (!confirmedAgreement) {
        throw new Error();
      }

      const createMembershipInput: CreateMembershipInput = {
        address: { line1, postalCode, city },
        birthdate,
        collectiveAgreement,
        companyCarMonthlyValue: toMoney(companyCarMonthlyValue) ?? undefined,
        companyId: params.companyId,
        costCenterId,
        email,
        employeeNumber: employeeId,
        fitnessContributionBalance:
          toMoney(fitnessContributionBalance) ?? undefined,
        givenName,
        incomeTaxTable: toNumber(incomeTaxTable) ?? null,
        isOwner,
        lastName,
        organizationalUnitId,
        personalIdentityNumber: !nidOptOut ? nid : undefined,
        phone,
        remunerations: remunerations
          ? remunerations.map(({ from, value, itemTypeId }) => ({
              itemTypeId,
              from: monthToDate(from),
              value: toMoney(value) ?? '',
            }))
          : undefined,
        sex: sex || undefined,
        vacationDaysAdvance: toNumber(vacationDaysAdvance) ?? undefined,
        vacationDaysPaid: toNumber(vacationDaysPaid) ?? undefined,
        vacationDaysPerYear: toNumber(vacationDaysPerYear) ?? undefined,
        vacationDaysSaved: toNumber(vacationDaysSaved) ?? undefined,
        vacationDaysUnpaid: toNumber(vacationDaysUnpaid) ?? undefined,
        skipWelcomeEmail: !sendWelcomeEmail,
        benefitPackageId,
        benefitPackageEffectiveDate: monthToDate(benefitPackageEffectiveDate),
        employmentStart: employmentEffectiveDate,
        employmentEnd: employmentEffectiveThrough
          ? toEffectiveUntil(employmentEffectiveThrough)
          : null,
      };

      const res = await createMembership({
        variables: { input: createMembershipInput },
      });

      const userAccountId =
        res.data?.createMembership?.membership.userAccountId;

      if (!userAccountId) {
        throw new Error();
      }

      setSubmitting(false);
      send({
        message: formatMessage(formSubmitMessages.addEmployeeSubmitSuccess),
        type: 'success',
      });
      push(`/companies/${params.companyId}/employees/${userAccountId}`);
    } catch {
      // Do nothing
    }
  };

  return { submit, submissionError };
};
