import gql from 'graphql-tag';

export const CREATE_MEMBERSHIP_MUTATION = gql`
  mutation createMembershipMutation($input: CreateMembershipInput!) {
    createMembership(input: $input) {
      membership {
        userAccountId
      }
    }
  }
`;
