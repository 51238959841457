import { isError, Result } from './use-submit';

interface Args {
  requestIds: string[];
  results: Result[];
}

export const getFailedRejections = ({
  requestIds,
  results,
}: Args): string[] => {
  const successIds = results.reduce(
    (acc, r) => (!isError(r) ? [...acc, r.requestId] : acc),
    [],
  );
  return requestIds.filter(id => !successIds.includes(id));
};
