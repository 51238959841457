import { Table as UiTable, Td, Th, Tr } from '@frontend/ui';
import {
  membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_edges_MembershipEventHistoryEdge_node_MembershipEventHistory as EventHistory,
  pageInfoDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { membershipEventHistoryMessages } from 'app/messages/employees';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { PerPageSelectProps } from 'components/PerPageSelect';
import { TableNavigation } from 'components/TableNavigation';
import React from 'react';
import { FormattedDate } from 'react-intl';

import { EventAuthor } from '../EventAuthor';
import { EventDetails } from '../EventDetails';
import { EventSource } from '../EventSource';
import { EventType } from '../EventType';

interface Props extends PerPageSelectProps {
  eventHistory: EventHistory[];
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
}

export const Table: React.FC<Props> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  eventHistory,
  perPage,
  setPerPage,
}) => (
  <UiTable
    navigation={
      <TableNavigation
        perPage={perPage}
        setPerPage={setPerPage}
        pageInfo={pageInfo}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    }
  >
    <colgroup>
      <col style={{ width: '20%' }} />
      <col style={{ width: '20%' }} />
      <col style={{ width: '15%' }} />
      <col style={{ width: '15%' }} />
      <col style={{ width: '15%' }} />
      <col style={{ width: '15%' }} />
    </colgroup>
    <thead>
      <Tr>
        <Th>
          <FormattedMessage {...membershipEventHistoryMessages.event} />
        </Th>
        <Th>
          <FormattedMessage {...commonMessages.detail} />
        </Th>
        <Th type="number">
          <FormattedMessage {...commonMessages.effectiveDate} />
        </Th>
        <Th type="number">
          <FormattedMessage {...membershipEventHistoryMessages.reportingDate} />
        </Th>
        <Th>
          <FormattedMessage {...membershipEventHistoryMessages.source} />
        </Th>
        <Th multiline>
          <FormattedMessage {...membershipEventHistoryMessages.author} />
        </Th>
      </Tr>
    </thead>
    <tbody>
      {eventHistory.map((event, i) => (
        <Tr key={i}>
          <Td>
            <EventType event={event} />
          </Td>
          <Td>
            <EventDetails event={event} />
          </Td>
          <Td type="number">
            {event.effectiveDate ? (
              <FormattedDate value={event.effectiveDate} />
            ) : (
              <NoValue />
            )}
          </Td>
          <Td type="number">
            {event.createdAt ? (
              <FormattedDate value={event.createdAt} />
            ) : (
              <NoValue />
            )}
          </Td>
          <Td>
            <EventSource source={event.source} />
          </Td>
          <Td multiline>
            <EventAuthor type={event.authorType} name={event.authorName} />
          </Td>
        </Tr>
      ))}
    </tbody>
  </UiTable>
);
