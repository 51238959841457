import { ApolloError, useMutation } from '@apollo/client';
import {
  editProposalHealthcareBenefitMutation,
  editProposalHealthcareBenefitMutationVariables,
  ProposalHealthcareLevel,
  UpdateProposalHealthcareBenefitInput,
} from 'app/apollo/graphql/types';
import { MatchParams as BenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/benefit';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import { useHistory } from 'react-router';

import { clearProposalCache } from '../../../../../utils/clear-proposal-cache';
import { FormValues } from '../../../form/healthcare';
import { EDIT_PROPOSAL_HEALTHCARE_BENEFIT_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

interface Params {
  params: BenefitMatchParams;
}

export const useSubmit = ({ params }: Params): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [editBenefit, { error: submissionError }] = useMutation<
    editProposalHealthcareBenefitMutation,
    editProposalHealthcareBenefitMutationVariables
  >(EDIT_PROPOSAL_HEALTHCARE_BENEFIT_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals'],
    }),
  });

  const submit = async (formValues: FormValues) => {
    // This value is guaranteed through the form validation
    if (!formValues.level) {
      return;
    }
    try {
      const input: UpdateProposalHealthcareBenefitInput = {
        id: params.benefitId,
        benefitPackageIds: formValues.benefitPackageIds,
        converting: formValues.converting,
        convertingFrom: formValues.converting
          ? formValues.convertingFrom
          : undefined,
        level: formValues.level,
        // Waiting period is mandatory when selecting gold level
        waitingPeriod:
          formValues.level === ProposalHealthcareLevel.GOLD ||
          formValues.waitingPeriod === 'true',
      };

      const res = await editBenefit({
        variables: { benefitId: params.benefitId, input },
      });

      if (!res.data?.updateProposalHealthcareBenefit) {
        return;
      }

      send({
        message: formatMessage(smeBenefitFormMessages.benefitSaved),
        type: 'success',
      });
      push(`/sme/${params.companyId}/proposals/${params.proposalId}/benefits`);
    } catch (error) {
      // do nothing
    }
  };

  return { submit, submissionError };
};
