import {
  getLocale as getBrowserStoreLocale,
  setLocale as setBrowserStoreLocale,
} from 'app/browser-store';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';

import formats from './configure-locale';

export type EffectiveLocale = 'sv-SE' | 'en-US';

interface IntlProviderContext {
  setLocale: (locale: EffectiveLocale, id?: string) => void;
  locale?: EffectiveLocale;
}

const messagesFiles = {
  'sv-SE': import('../../lang/input_sv.json'),
  // XXX: import path should be updated when we want to support
  // english in backstage. For now, we require the Swedish language
  // file to supress console errors
  'en-US': import('../../lang/input_sv.json'),
};

const getNearestLocale = (): EffectiveLocale => {
  const shortLocales = Array.from(navigator.languages).map(l =>
    l.indexOf('-') !== -1 ? l.split('-')[0] : l,
  );

  const svIndex = shortLocales.indexOf('sv');
  const enIndex = shortLocales.indexOf('en');
  return svIndex !== -1
    ? enIndex !== -1
      ? svIndex < enIndex
        ? 'sv-SE'
        : 'en-US'
      : 'sv-SE'
    : enIndex !== -1
      ? 'en-US'
      : 'sv-SE';
};

export const IntlContext = React.createContext<IntlProviderContext>({
  setLocale: (locale: EffectiveLocale) => locale,
});

export const IntlProviderWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [messages, setMessages] = useState<typeof import('*.json') | null>(
    null,
  );
  const [locale, set] = useState<EffectiveLocale | undefined>(undefined);

  useEffect(() => {
    const getLocale = async () => {
      let _locale = await getBrowserStoreLocale();

      if (!_locale) {
        _locale = getNearestLocale();
        await setBrowserStoreLocale(_locale);
      }

      /**
       * As long as we do not have any English translations, we will
       * always default to Swedish to prevent from console errors, and
       * avoid confustion for users with mixed langages in the UI
       *
       * When translationa are available and backstage has implemented a mechanism
       * for switching language setting we can switch to the following:
       * set(_locale === 'en-US' ? 'en-US' : 'sv-SE');
       */
      set('sv-SE');
    };

    getLocale();
  }, []);

  useEffect(() => {
    const setLang = async () => {
      if (locale) {
        const _result = await messagesFiles[locale];
        setMessages(_result);
      }
    };
    setLang();
  }, [locale]);

  const setLocale = async (_locale: EffectiveLocale) => {
    set(_locale);
    await setBrowserStoreLocale(_locale);
  };

  const value = useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale],
  );

  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }} />
      <IntlContext.Provider value={value}>
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <IntlProvider
          defaultFormats={formats}
          formats={formats}
          locale={locale ?? 'sv-SE'}
          messages={messages}
        >
          {children}
        </IntlProvider>
      </IntlContext.Provider>
    </>
  );
};

export const useIntlContext = (): IntlProviderContext =>
  useContext(IntlContext);
