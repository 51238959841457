import { CompanyFilters, ProposalStatusFilter } from 'app/apollo/graphql/types';
import qs from 'query-string';

import { Filters } from '..';

type QueryFields<T> = {
  [K in keyof T]?: string | string[];
};

const isStringArray = (value?: string | string[]): value is string[] =>
  Array.isArray(value);

const isString = (value?: string | string[]): value is string =>
  typeof value === 'string';

const isProposalStatusFilter = (
  status: string,
): status is ProposalStatusFilter =>
  Object.values(ProposalStatusFilter).some(_status => _status === status);

export const getFilterQueryArgs = (): CompanyFilters => {
  const { salesRep, status }: QueryFields<Filters> = qs.parse(location.search);

  return {
    assignedSalesRep: isString(salesRep) ? salesRep : undefined,
    proposalStatuses:
      isStringArray(status) && status.every(isProposalStatusFilter)
        ? status
        : isString(status) && isProposalStatusFilter(status)
          ? [status]
          : undefined,
  };
};
