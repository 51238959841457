import { useEffect, useState } from 'react';

interface IsOnline {
  isOnline: boolean;
}

/**
 * Subscribes to the client network status using the `Window.navigator` API
 */
export const useIsOnline = (): IsOnline => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleIsOnline = () => setIsOnline(true);
    const handleIsOffline = () => setIsOnline(false);

    window.addEventListener('online', handleIsOnline);
    window.addEventListener('offline', handleIsOffline);
    return () => {
      window.removeEventListener('online', handleIsOnline);
      window.removeEventListener('offline', handleIsOffline);
    };
  }, []);

  return { isOnline };
};
