import { companiesQuery } from 'app/apollo/graphql/types';

export const updateCompaniesQuery = (
  previousResult: companiesQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: companiesQuery;
  },
) => {
  if (!previousResult?.companies || !fetchMoreResult?.companies) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.companies;

  return {
    companies: {
      __typename: previousResult.companies.__typename,
      edges: [...edges],
      pageInfo,
    },
  };
};
