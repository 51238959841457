import { getEmployeeRiskInsurances } from '@frontend/utils';
import {
  riskInsurancesAdminQuery,
  riskInsurancesAdminQueryVariables,
} from 'app/apollo/graphql/types';
import { employeeFormMessages } from 'app/messages/employees';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { AccidentInsurance } from './components/AccidentInsurance';
import { HealthInsurance } from './components/HealthInsurance';
import { LifeInsurance } from './components/LifeInsurance';
import { SicknessInsurance } from './components/SicknessInsurance';
import { RISK_INSURANCES_ADMIN_QUERY } from './graphql/queries';

export const RiskRoute: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data, loading, error } = useQuery<
    riskInsurancesAdminQuery,
    riskInsurancesAdminQueryVariables
  >(RISK_INSURANCES_ADMIN_QUERY, {
    errorPolicy: 'all',
    variables: {
      userAccountId,
      companyId,
      includeDeleted: true,
    },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.membership?.insurances) {
    return <EmptyState error={error} />;
  }

  const advinansBrokeredEAInsuranceComponents = getEmployeeRiskInsurances(
    data?.membership?.insurances?.edges.map(({ node }) => node) ?? [],
  );

  return advinansBrokeredEAInsuranceComponents?.length ? (
    <div>
      {advinansBrokeredEAInsuranceComponents.map(component => {
        switch (component.__typename) {
          case 'InsuranceComponentSickness':
            return <SicknessInsurance key={component.id} data={component} />;
          case 'InsuranceComponentAccident':
            return <AccidentInsurance key={component.id} data={component} />;
          case 'InsuranceComponentDeath':
            return <LifeInsurance key={component.id} data={component} />;
          case 'InsuranceComponentHealthCare':
            return <HealthInsurance key={component.id} data={component} />;
          default:
            return null;
        }
      })}
    </div>
  ) : (
    <EmptyState
      title={<FormattedMessage {...employeeFormMessages.noInsuranceBenefits} />}
    >
      <FormattedMessage {...employeeFormMessages.noInsuranceBenefitsText} />
    </EmptyState>
  );
};
