import { formatRegistrationNumber } from '@frontend/utils';
import { GraphQlErrors } from 'components/GraphQlError';
import { Company } from 'features/companies/company';
import { Page } from 'features/page';
import { Suspense } from 'features/Suspense';
import React from 'react';

import { Loading } from './loading';

export interface Props {
  registrationNumber: string;
  title: string;
}

export interface MatchParams {
  companyId: string;
}

export const CompanyRoute: React.FC<Props> = ({
  title,
  registrationNumber,
}) => (
  <Page
    title={title}
    subtitle={formatRegistrationNumber(registrationNumber)}
    parentLink="/companies"
  >
    <Suspense batch fallback={<Loading />}>
      {({ errors }) => (
        <>
          {errors && <GraphQlErrors errors={errors} />}
          <Company />
        </>
      )}
    </Suspense>
  </Page>
);
