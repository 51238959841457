import {
  smeGenerateProposalQuery,
  smeGenerateProposalQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { FormikWizard, SubmissionError } from 'features/FormikWizard';
import { smeGenerateProposalMessages } from 'features/sme/messages/sme';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { SME_GENERATE_PROPOSAL_QUERY } from './graphql/queries';
import {
  FormValues as MissingCompanyDetailsFormValues,
  MissingCompanyDetails,
  validationSchema as missingCompanyDetailsSchema,
} from './missing-company-details';
import {
  FormValues as ProposalDetailsFormValues,
  initialValues as proposalDetailsInitialValues,
  ProposalDetails,
  validationSchema as proposalDetailsSchema,
} from './proposal-details';
import { getHasMissingCompanyDetails } from './utils/get-has-missing-company-details';
import { useSubmit } from './utils/use-submit';

interface PagesParams {
  hasMissingCompanyDetails: boolean;
  intl: IntlShape;
}

const pages = ({ hasMissingCompanyDetails, intl }: PagesParams) => [
  {
    offWhite: true,
    page: MissingCompanyDetails,
    skip: () => !hasMissingCompanyDetails,
    validationSchema: () => missingCompanyDetailsSchema(intl),
  },
  {
    offWhite: true,
    page: ProposalDetails,
    validationSchema: () => proposalDetailsSchema(intl),
  },
];
export type FormValues = MissingCompanyDetailsFormValues &
  ProposalDetailsFormValues &
  SubmissionError;

export const GenerateProposal: React.FC<RouteComponentProps<MatchParams>> = ({
  history: { push },
  match,
}) => {
  const { params, url } = match;
  const intl = useIntl();

  const parentLink = `/sme/${params.companyId}/proposals/${params.proposalId}`;

  const onCompleted = () => push(`${match.url}/documents`);
  const { submit } = useSubmit({ params, onCompleted });

  const { data, loading, error } = useQuery<
    smeGenerateProposalQuery,
    smeGenerateProposalQueryVariables
  >(SME_GENERATE_PROPOSAL_QUERY, {
    errorPolicy: 'all',
    variables: { companyId: params.companyId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company) {
    return <EmptyStatePage error={error} parentLink={parentLink} />;
  }

  const { accountNumber, clearingCode, paymentMethod } = data.company;

  const hasMissingCompanyDetails = getHasMissingCompanyDetails({
    accountNumber,
    clearingSalesOffice: clearingCode?.code,
    paymentMethod,
  });

  return (
    <FormikWizard<FormValues>
      baseUrl={url}
      initialValues={{
        accountNumber: accountNumber ?? '',
        clearingSalesOffice: clearingCode
          ? { label: clearingCode.displayName, value: clearingCode.code }
          : null,
        paymentMethod: paymentMethod ?? '',
        ...proposalDetailsInitialValues,
      }}
      pages={pages({
        intl,
        hasMissingCompanyDetails,
      })}
      title={
        <FormattedMessage {...smeGenerateProposalMessages.generateProposal} />
      }
      parentLink={parentLink}
      submitLabel={
        <FormattedMessage {...smeGenerateProposalMessages.generate} />
      }
      onSubmit={submit({ updateCompanyDetails: hasMissingCompanyDetails })}
      validateOnMount
    />
  );
};
