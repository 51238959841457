import { A, ASet, Subsection, SubsectionHeader } from '@frontend/ui';
import { attachFile } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import React from 'react';

interface Attachment {
  name: string | null;
  url: string;
}

interface Props {
  attachments?: readonly Attachment[];
  noHeader?: boolean;
}

export const Attachments: React.FC<Props> = ({ attachments, noHeader }) => (
  <Subsection>
    {!noHeader && (
      <SubsectionHeader>
        <FormattedMessage {...commonMessages.attachedFiles} />
      </SubsectionHeader>
    )}
    <ASet>
      {attachments?.map((file, index) => (
        <A key={index} href={file.url} icon={attachFile} target="_blank">
          {file.name ?? file.url}
        </A>
      ))}
    </ASet>
  </Subsection>
);
