/* eslint-disable no-restricted-imports */
import {
  IconButton,
  SideSheet as SideSheetUI,
  SideSheetBody,
  SideSheetFooter,
  SideSheetHeader,
  SideSheetListContainer,
  SideSheetSection,
  SideSheetSubtitle,
} from '@frontend/ui';
import { clear } from '@frontend/ui/icons';
import { a11yMessages } from 'app/messages/a11y';
import { useIntl } from 'components/formats';
import React from 'react';

interface Props {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: React.ReactNode;
  footer?: React.ReactNode;
  zIndex?: number;
}

export const SideSheet: React.FC<Props> = ({
  title,
  open,
  children,
  footer,
  setOpen,
  zIndex,
}) => {
  const { formatMessage } = useIntl();

  return (
    <SideSheetUI open={open} onClose={() => setOpen(false)} zIndex={zIndex}>
      <SideSheetHeader
        action={
          <IconButton
            icon={clear}
            size="small"
            color="primary"
            label={formatMessage(a11yMessages.close)}
            onClick={() => setOpen(false)}
          />
        }
        title={title}
      />
      <SideSheetBody>{children}</SideSheetBody>
      {!!footer && <SideSheetFooter>{footer}</SideSheetFooter>}
    </SideSheetUI>
  );
};

export { SideSheetListContainer, SideSheetSection, SideSheetSubtitle };
