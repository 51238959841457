import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const SME_COMPANIES_QUERY = gql`
  query smeCompaniesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $search: String
    $filters: CompanyFilters
    $orderBy: CompaniesOrderBy
  ) {
    companies(
      first: $first
      last: $last
      before: $before
      after: $after
      search: $search
      filters: $filters
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          latestProposalStatus
          name
          registrationNumber
          nlpSalesRep {
            id
            firstName
            lastName
          }
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${pageInfoDetails}
`;
