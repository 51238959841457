import { ButtonLayout } from '@frontend/ui';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { FormattedMessage } from 'components/formats';
import { LinkButton } from 'components/LinkButton';
import qs from 'query-string';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { companySalaryExchangeMessages } from '../../messages';

interface Props {
  userAccountIds: string[];
}

export const TableActions: React.FC<Props> = ({ userAccountIds }) => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const search = qs.stringify({ userAccountIds });

  return (
    <ButtonLayout>
      <LinkButton
        text
        to={{
          pathname: `/companies/${companyId}/salary-exchanges/terminate`,
          search,
        }}
      >
        <FormattedMessage
          {...companySalaryExchangeMessages.stopSalaryExchange}
          values={{
            count: userAccountIds.length,
          }}
        />
      </LinkButton>
    </ButtonLayout>
  );
};
