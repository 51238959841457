import { useFormikObserver } from '@frontend/formik';
import {
  AppBar as AppBarUi,
  AppBarContent,
  AppBarMeta,
  AppBarTitle,
  IconButton,
} from '@frontend/ui';
import { arrowBack, menu, menuOpen } from '@frontend/ui/icons';
import { formMessages } from 'app/messages/form';
import { useIntl } from 'components/formats';
import { useNavigationContext } from 'components/NavigationDrawer/containers/NavigationContext';
import { useAppBarNavigationStack } from 'components/NavigationDrawer/lib/use-app-bar-navigation-stack';
import { useConfirm } from 'contexts/confirmation';
import React from 'react';
import { useLocation } from 'react-router';

import { messages } from '../messages';
import { SkipToMainLink } from '../SkipToMainLink';
import { UserMenu } from '../user-menu';

export const AppBar: React.FC = () => {
  const { state } = useLocation();
  const { hasDirtyFields } = useFormikObserver();
  const { confirm } = useConfirm();
  const { formatMessage } = useIntl();
  const {
    isDrawerOpen,
    page: { parentLink },
    setIsDrawerOpen,
  } = useNavigationContext();

  const { peek, push } = useAppBarNavigationStack();

  return (
    <AppBarUi>
      <SkipToMainLink />
      <AppBarContent>
        <>
          {!parentLink && (
            <IconButton
              icon={isDrawerOpen ? menuOpen : menu}
              label={formatMessage(messages.openDrawer)}
              onClick={() => setIsDrawerOpen(_isDrawerOpen => !_isDrawerOpen)}
              aria-expanded={!!isDrawerOpen}
              aria-controls="navigation-drawer"
            />
          )}
          {parentLink && (
            <IconButton
              icon={arrowBack}
              label={formatMessage(messages.back)}
              onClick={async () => {
                const link = peek();

                if (!hasDirtyFields) {
                  push(link ?? parentLink, state);
                  return;
                }

                const confirmation = await confirm({
                  title: formatMessage(formMessages.confirmLeaveFormTitle),
                  description: formatMessage(
                    formMessages.confirmLeaveFormDescription,
                  ),
                });

                if (confirmation) {
                  push(link ?? parentLink, state);
                }
              }}
            />
          )}
          <AppBarTitle>Backstage</AppBarTitle>
        </>
        <AppBarMeta>
          <UserMenu />
        </AppBarMeta>
      </AppBarContent>
    </AppBarUi>
  );
};
