import gql from 'graphql-tag';

export const EDIT_PROPOSAL_HEALTHCARE_BENEFIT_MUTATION = gql`
  mutation editProposalHealthcareBenefitMutation(
    $benefitId: ID!
    $input: UpdateProposalHealthcareBenefitInput!
  ) {
    updateProposalHealthcareBenefit(input: $input) {
      id
      benefit(id: $benefitId) {
        id
        benefitPackages {
          id
        }
        configuration {
          ... on ProposalHealthcareBenefitConfig {
            converting
            convertingFrom
            healthcareLevel
            waitingPeriod
          }
        }
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
