import gql from 'graphql-tag';

import { companyBillingConfigurationDetails } from './fragments';

export const ADD_COMPANY_BILLING_CONFIGURATION_MUTATION = gql`
  mutation addCompanyBillingConfigurationMutation(
    $input: AddCompanyBillingConfigurationInput!
  ) {
    addCompanyBillingConfiguration(input: $input) {
      billingConfiguration {
        ...companyBillingConfigurationDetails
      }
    }
  }
  ${companyBillingConfigurationDetails}
`;

export const UPDATE_COMPANY_BILLING_CONFIGURATION_MUTATION = gql`
  mutation updateCompanyBillingConfigurationMutation(
    $input: UpdateCompanyBillingConfigurationInput!
  ) {
    updateCompanyBillingConfiguration(input: $input) {
      billingConfiguration {
        ...companyBillingConfigurationDetails
      }
    }
  }
  ${companyBillingConfigurationDetails}
`;

export const DELETE_COMPANY_BILLING_CONFIGURATION_MUTATION = gql`
  mutation deleteCompanyBillingConfigurationMutation(
    $input: DeleteCompanyBillingConfigurationInput!
  ) {
    deleteCompanyBillingConfiguration(input: $input) {
      clientMutationId
    }
  }
`;
