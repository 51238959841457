import { ApolloError, useMutation } from '@apollo/client';
import {
  DeleteCompanyRepresentativeInput,
  deleteRepresentativeMutation,
  deleteRepresentativeMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeRepresentativesMessages } from 'features/sme/messages/sme';
import { useParams } from 'react-router';

import { clearProposalCache } from '../../../utils/clear-proposal-cache';
import { DELETE_REPRESENTATIVE_MUTATION } from './graphql/mutations';

interface Submit {
  _delete: (input: DeleteCompanyRepresentativeInput) => Promise<void>;
  deleteLoading: boolean;
  deleteError?: ApolloError;
}

export const useDelete = (): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const params = useParams<MatchParams>();

  const [deleteRepresentative, { loading: deleteLoading, error: deleteError }] =
    useMutation<
      deleteRepresentativeMutation,
      deleteRepresentativeMutationVariables
    >(DELETE_REPRESENTATIVE_MUTATION, {
      update: clearProposalCache({
        proposalId: params.proposalId,
        fieldNames: ['proposals', 'validity'],
      }),
    });

  const _delete = async (input: DeleteCompanyRepresentativeInput) => {
    try {
      const res = await deleteRepresentative({
        variables: {
          input,
        },
      });
      const proposalId = res.data?.deleteCompanyRepresentative?.id;
      if (!proposalId) {
        return;
      }
      send({
        message: formatMessage(
          smeRepresentativesMessages.representativeDeleted,
        ),
        type: 'success',
      });
    } catch {
      // Do nothing
    }
  };

  return {
    _delete,
    deleteLoading,
    deleteError,
  };
};
