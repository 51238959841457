import { ApolloError, useMutation } from '@apollo/client';
import {
  createRepresentativeMutation,
  createRepresentativeMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeRepresentativesMessages } from 'features/sme/messages/sme';
import { useParams } from 'react-router';

import { clearProposalCache } from '../../../../utils/clear-proposal-cache';
import { FormValues } from '..';
import { CREATE_REPRESENTATIVE_MUTATION } from '../graphql/mutations';

interface Props {
  onRequestClose: () => void;
}

interface Submit {
  submit: (values?: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = ({ onRequestClose }: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const params = useParams<MatchParams>();

  const [createRepresentative, { error: submissionError }] = useMutation<
    createRepresentativeMutation,
    createRepresentativeMutationVariables
  >(CREATE_REPRESENTATIVE_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['proposals', 'validity'],
    }),
  });

  const submit = async (values: FormValues) => {
    // These values are guaranteed through the form validation
    if (!values.role || !values.identification) {
      return;
    }
    try {
      const res = await createRepresentative({
        variables: {
          input: {
            firstName: values.firstName,
            identification: values.identification,
            identificationReference: values.identificationReference,
            lastName: values.lastName,
            personalIdentityNumber: values.personalIdentityNumber,
            proposalId: values.proposalId,
            role: values.role,
          },
        },
      });
      const proposalId = res.data?.createCompanyRepresentative?.id;
      if (!proposalId) {
        return;
      }
      send({
        message: formatMessage(
          smeRepresentativesMessages.representativeCreated,
        ),
        type: 'success',
      });
      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    submissionError,
  };
};
