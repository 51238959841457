import { Icon, Table, Td, Th, Tr } from '@frontend/ui';
import { _delete as deleteIcon, edit } from '@frontend/ui/icons';
import { smeCompanyRepresentativesQuery_proposal_Proposal_representatives_CompanyRepresentative as Representative } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { FormattedMessage, useIntl } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { GraphQlError } from 'components/GraphQlError';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import { NoValue } from 'components/NoValue';
import { TopLoading } from 'components/TopLoading';
import { useConfirm } from 'contexts/confirmation';
import {
  smeRepresentativeIdentificationTypesMessages,
  smeRepresentativeRolesMessages,
  smeRepresentativesMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import { useDelete } from '../delete';
import { getEditRepresentativeLink } from '../edit';

const ErrorWrapper = styled.div`
  margin-top: 1rem;
`;

const representativesByNameAscending = (a: Representative, b: Representative) =>
  a.firstName.localeCompare(b.firstName);

interface Props {
  representatives: readonly Representative[];
}

export const RepresentativesTable: React.FC<Props> = ({ representatives }) => {
  const location = useLocation();
  const { params } = useRouteMatch<MatchParams>();
  const disabled = useProposalReadonly();
  const { confirm } = useConfirm();

  const { formatMessage } = useIntl();
  const { _delete, deleteLoading, deleteError } = useDelete();

  const tableHead = (
    <>
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <Tr withInlineMenuTh>
          <Th>
            <FormattedMessage {...commonMessages.name} />
          </Th>
          <Th>
            <FormattedMessage {...commonMessages.id} />
          </Th>
          <Th>
            <FormattedMessage {...smeRepresentativesMessages.roleDescription} />
          </Th>
          <Th>
            <FormattedMessage {...smeRepresentativesMessages.legitimized} />
          </Th>
          <Th>
            <FormattedMessage {...smeRepresentativesMessages.identifier} />
          </Th>
        </Tr>
      </thead>
    </>
  );

  if (!representatives.length) {
    return (
      <Table size="small">
        {tableHead}
        <tbody>
          <Tr>
            <Td colSpan={6}>
              <FormattedMessage
                {...smeRepresentativesMessages.noRepresentatives}
              />
            </Td>
          </Tr>
        </tbody>
      </Table>
    );
  }
  return (
    <>
      {deleteLoading && <TopLoading />}
      <Table size="small">
        <>
          {tableHead}
          <tbody>
            {[...representatives]
              .sort(representativesByNameAscending)
              .map(representative => (
                <Tr
                  key={representative.id}
                  inlineMenu={
                    <KebabMenu inClickableArea fixed disabled={disabled}>
                      <MenuItem
                        title={<FormattedMessage {...commonMessages.edit} />}
                        icon={<Icon icon={edit} decorative />}
                        link={getEditRepresentativeLink({
                          location,
                          representativeId: representative.id,
                        })}
                      />
                      <MenuItem
                        title={<FormattedMessage {...commonMessages.remove} />}
                        icon={<Icon icon={deleteIcon} decorative />}
                        onClick={async () => {
                          const confirmed = await confirm({
                            description: formatMessage(
                              smeRepresentativesMessages.confirmDeleteRepresentative,
                            ),
                          });

                          if (confirmed) {
                            _delete({
                              id: representative.id,
                              proposalId: params.proposalId,
                            });
                          }
                        }}
                      />
                    </KebabMenu>
                  }
                >
                  <Td>{`${representative.firstName} ${representative.lastName}`}</Td>
                  <Td>
                    <FormattedNaturalPersonIdentifier
                      nid={representative.personalIdentityNumber}
                    />
                  </Td>
                  <Td>
                    <FormattedMessage
                      select={representative.role}
                      messages={smeRepresentativeRolesMessages}
                    />
                  </Td>
                  <Td>
                    <FormattedMessage
                      select={representative.identificationType}
                      messages={smeRepresentativeIdentificationTypesMessages}
                    />
                  </Td>
                  <Td>
                    {representative.identificationReference ?? <NoValue />}
                  </Td>
                </Tr>
              ))}
          </tbody>
        </>
      </Table>
      {deleteError && (
        <ErrorWrapper>
          <GraphQlError error={deleteError} />
        </ErrorWrapper>
      )}
    </>
  );
};
