import gql from 'graphql-tag';

export const SINGLE_PREMIUM_DEPOSIT_MODAL_QUERY = gql`
  query singlePremiumDepositModalQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      insurances {
        edges {
          node {
            id
            institute {
              id
            }
            nextPremiumPayment {
              value
            }
            previousPremiumPayment {
              value
            }
            insuranceNumber
            components {
              id
              typeCode
            }
          }
        }
      }
    }
  }
`;
