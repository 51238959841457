import { employeeFormMessages } from 'app/messages/employees';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { Events } from 'features/companies/company/employees/employee/events';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const EventsRoute: React.FC = () => (
  <Suspense
    batch
    fallback={<TopLoading />}
    emptyState={
      <EmptyState
        title={<FormattedMessage {...employeeFormMessages.noStatus} />}
      />
    }
  >
    {({ errors }) => (
      <>
        {errors && <GraphQlErrors errors={errors} />}
        <Events />
      </>
    )}
  </Suspense>
);
