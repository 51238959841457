import { ApolloError, useMutation } from '@apollo/client';
import {
  setInsuranceDeleted,
  setInsuranceDeletedVariables,
} from 'app/apollo/graphql/types';
import { insuranceMessages } from 'app/messages/insurance';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useId, useState } from 'react';

import { SET_INSURANCE_DELETED_MUTATION } from '../graphql/mutations';
import { InsuranceKey } from '../table';

export const useDeleteInsurances = () => {
  const { send } = useNotification();
  const batchId = useId();
  const { formatMessage } = useIntl();

  const [deleteInsurance] = useMutation<
    setInsuranceDeleted,
    setInsuranceDeletedVariables
  >(SET_INSURANCE_DELETED_MUTATION, { context: { batch: true, batchId } });

  const [deleteInsuranceError, setDeleteInsuranceError] = useState<
    ApolloError | undefined
  >(undefined);

  const deleteInsurances = async (insuranceKeys: Array<InsuranceKey>) => {
    if (
      // eslint-disable-next-line no-alert
      confirm(
        formatMessage(insuranceMessages.confirmDeleteInsurancesMessage, {
          count: insuranceKeys.length,
        }),
      )
    ) {
      const results = await Promise.allSettled(
        insuranceKeys.map(({ insuranceNumber, instituteId }) =>
          deleteInsurance({
            variables: {
              insuranceNumber,
              instituteId,
            },
          }),
        ),
      );

      const successes = results.filter(result => result.status === 'fulfilled');
      const errors = results.filter(result => result.status === 'rejected');

      if (successes.length > 0) {
        send({
          type: 'success',
          message: formatMessage(insuranceMessages.deleteInsurancesSuccess, {
            count: successes.length,
          }),
        });
      }

      if (errors.length > 0) {
        setDeleteInsuranceError(
          new ApolloError({
            errorMessage: formatMessage(
              insuranceMessages.deleteInsurancesError,
              { count: errors.length },
            ),
          }),
        );
      }
    }
  };

  return {
    deleteInsurances,
    deleteInsuranceError,
  };
};
