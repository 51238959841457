import { ContentContainer } from '@frontend/ui';
import {
  insurancesQuery,
  insurancesQueryVariables,
} from 'app/apollo/graphql/types';
import { insuranceMessages } from 'app/messages/insurance';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { Page } from 'features/page';
import qs from 'query-string';
import React from 'react';

import { INSURANCES_QUERY } from './graphql/queries';
import { updateInsurancesQuery } from './lib/update-insurances-query';
import { InsuranceTable } from './table';
import { useDeleteInsurances } from './utils/use-delete-insurances';

export const Insurances: React.FC = () => {
  const { deleteInsurances, deleteInsuranceError } = useDeleteInsurances();

  const { search, per_page } = qs.parse(location.search);

  const { data, loading, previousData, error, fetchMore } = useQuery<
    insurancesQuery,
    insurancesQueryVariables
  >(INSURANCES_QUERY, {
    variables: {
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      search,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    suspend: false,
  });

  const _data = data ?? previousData;
  const insurances = _data?.insurances;
  const pageInfo = _data?.insurances.pageInfo ?? {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  };

  return (
    <Page title={<FormattedMessage {...insuranceMessages.insurances} />}>
      <ContentContainer>
        {loading && <TopLoading />}
        {(error || deleteInsuranceError) && (
          <GraphQlError error={error ?? deleteInsuranceError} />
        )}
        <InsuranceTable
          pageInfo={pageInfo}
          insurances={insurances?.edges.map(e => e.node) ?? []}
          onNextPage={() => {
            fetchMore({
              variables: {
                after: pageInfo.endCursor,
              },
              updateQuery: updateInsurancesQuery,
            });
          }}
          onPreviousPage={() => {
            fetchMore({
              variables: {
                before: pageInfo.startCursor,
                first: undefined,
                last: per_page
                  ? parseInt(per_page, 10)
                  : DEFAULT_RESULT_PER_PAGE,
              },
              updateQuery: updateInsurancesQuery,
            });
          }}
          deleteInsurances={deleteInsurances}
        />
      </ContentContainer>
    </Page>
  );
};
