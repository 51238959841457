import gql from 'graphql-tag';

export const EDIT_EMPLOYEE_LAYOUT_QUERY = gql`
  query editEmployeeLayoutQuery($userAccountId: ID!, $companyId: ID!) {
    membership(userAccountId: $userAccountId, companyId: $companyId) {
      id
      givenName
      lastName
    }
  }
`;
