export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z',
      },
    },
  ],
};
