export default {
  children: [
    {
      name: 'g',
      attribs: {
        fill: 'currentColor',
      },
      children: [
        {
          name: 'rect',
          attribs: {
            height: '7',
            width: '3',
            x: '4',
            y: '10',
          },
        },
        {
          name: 'rect',
          attribs: {
            height: '7',
            width: '3',
            x: '10.5',
            y: '10',
          },
        },
        {
          name: 'rect',
          attribs: {
            height: '3',
            width: '20',
            x: '2',
            y: '19',
          },
        },
        {
          name: 'rect',
          attribs: {
            height: '7',
            width: '3',
            x: '17',
            y: '10',
          },
        },
        {
          name: 'polygon',
          attribs: {
            points: '12,1 2,6 2,8 22,8 22,6',
          },
        },
      ],
    },
  ],
};
