import {
  ImportColumn,
  importColumnsQuery_import_Import_columnMappings_ImportColumnMapping as SourceColumnPair,
} from 'app/apollo/graphql/types';

import { ImportColumnOptionValue } from './static-column-options';

export interface FormValues {
  benefitsStartWithEmployment: boolean;
  createMissingOrganizationalUnits: boolean;
  fallbackDateForBenefits: string;
  updatedColumns?: Record<string, ImportColumnOptionValue>;
}

const periodRegex = /\./g;
const sanitize = (value: string): string => value.replace(periodRegex, '_');

export const formValueIdentifier = (name: string) =>
  `updatedColumns.${sanitize(name)}`;

export const toFormValues = (
  columns: readonly SourceColumnPair[],
): Record<string, ImportColumnOptionValue> =>
  columns.reduce(
    (acc, column) => ({
      ...acc,
      [sanitize(column.source)]: column.column || '',
    }),
    {},
  );

export const fromFormValues = (
  record: Record<string, ImportColumnOptionValue>,
  columns: readonly SourceColumnPair[],
): Array<ImportColumn | string | null> =>
  columns.map(column => {
    const value = record[sanitize(column.source)];
    return value === '' ? null : value;
  });
