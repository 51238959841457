import {
  ProposalAccidentLevel,
  ProposalDefaultManagementType,
  ProposalHealthcareLevel,
  ProposalLifeLevel,
  ProposalPremiumCalculationMethod,
  ProposalPremiumDeductions,
  ProposalSicknessLevel,
} from 'app/apollo/graphql/types';

export const SALARY_MULTIPLIER_OPTIONS = [12.0, 12.2];

/**
 * This array lists the premium calculation methods available for a proposal,
 * sorted for display purposes rather than in the alphabetical order of the enum values.
 */
export const PREMIUM_CALCULATION_METHODS: ProposalPremiumCalculationMethod[] = [
  ProposalPremiumCalculationMethod.ITP_MATRIX,
  ProposalPremiumCalculationMethod.CUSTOM_MATRIX,
  ProposalPremiumCalculationMethod.FIXED_PREMIUM_ONLY,
];

/**
 * This array lists the default management types available for a proposal,
 * sorted for display purposes rather than in the alphabetical order of the enum values.
 */
export const DEFAULT_MANAGEMENT_TYPES: ProposalDefaultManagementType[] = [
  ProposalDefaultManagementType.FUND,
  ProposalDefaultManagementType.TRAD,
  ProposalDefaultManagementType.PORTFOLIO,
];

/**
 * This array lists the premium deductions available for a proposal,
 * sorted for display purposes rather than in the alphabetical order of the enum values.
 */
export const PREMIUM_DEDUCTIONS: ProposalPremiumDeductions[] = [
  ProposalPremiumDeductions.PREMIUM_WAIVER,
  ProposalPremiumDeductions.SICKNESS_INSURANCE,
];

/**
 * This array lists the sickness levels available for a proposal,
 * sorted for display purposes rather than in the alphabetical order of the enum values.
 */
export const SICKNESS_LEVELS: ProposalSicknessLevel[] = [
  ProposalSicknessLevel.ITP,
  ProposalSicknessLevel.KOMP,
  ProposalSicknessLevel.MAX,
  ProposalSicknessLevel.CONSOLIDATION,
  ProposalSicknessLevel.HYBRID,
];

/**
 * This array lists the healthcare levels available for a proposal,
 * sorted for display purposes rather than in the alphabetical order of the enum values.
 */
export const HEALTHCARE_LEVELS: ProposalHealthcareLevel[] = [
  ProposalHealthcareLevel.BRONS_500,
  ProposalHealthcareLevel.BRONS_0,
  ProposalHealthcareLevel.L500,
  ProposalHealthcareLevel.L1000,
  ProposalHealthcareLevel.L1500,
  ProposalHealthcareLevel.L0,
  ProposalHealthcareLevel.GOLD,
];

export const ACCIDENT_LEVELS: ProposalAccidentLevel[] = [
  ProposalAccidentLevel.PBB20,
  ProposalAccidentLevel.PBB30,
  ProposalAccidentLevel.PBB40,
  ProposalAccidentLevel.PBB50,
];

export const LIFE_LEVELS: ProposalLifeLevel[] = [
  ProposalLifeLevel.PBB5,
  ProposalLifeLevel.PBB10,
  ProposalLifeLevel.PBB15,
  ProposalLifeLevel.PBB20,
  ProposalLifeLevel.PBB25,
  ProposalLifeLevel.PBB30,
  ProposalLifeLevel.PBB35,
  ProposalLifeLevel.PBB40,
  ProposalLifeLevel.PBB45,
  ProposalLifeLevel.PBB50,
];
