import { toNumber } from '@frontend/utils';
import { InsurancePremiumStatus } from 'app/apollo/graphql/types';
import { SALARY_EXCHANGE_TYPE_CODE } from 'app/utils/constants';

interface Component {
  typeCode: string | null;
  __typename?: string;
}

interface PremiumPayment {
  value: string;
}

interface PensionInsurance {
  readonly nextPremiumPayment: PremiumPayment | null;
  readonly previousPremiumPayment: PremiumPayment | null;
  components?: readonly Component[] | null;
  premiumStatus?: InsurancePremiumStatus | null;
}

const byPremiumPaymentDescending = (
  a: PensionInsurance,
  b: PensionInsurance,
): number => {
  const compare = (aPayment?: string | null, bPayment?: string | null) => {
    const _aPayment = toNumber(aPayment);
    const _bPayment = toNumber(bPayment);

    if (_bPayment && _aPayment) {
      return _bPayment - _aPayment;
    }

    if (_aPayment) {
      return -1;
    }

    if (_bPayment) {
      return 1;
    }

    return undefined;
  };

  return (
    compare(a.nextPremiumPayment?.value, b.nextPremiumPayment?.value) ??
    compare(a.previousPremiumPayment?.value, b.previousPremiumPayment?.value) ??
    0
  );
};

const INSURANCE_PREMIUM_STATUS_SORT_ORDER: Record<
  InsurancePremiumStatus | 'NO_STATUS',
  number
> = {
  PENDING_ACTIVATION: 0,
  ACTIVE: 1,
  ACTIVE_WAIVER: 2,
  OPEN: 3,
  INACTIVE: 4,
  COMPLETE: 5,
  NO_STATUS: 6,
};

export const getPensionInsurance = <T extends PensionInsurance>(
  insurances: T[],
): T | undefined =>
  insurances
    .filter(({ components }) =>
      components?.some(
        component =>
          component.__typename === 'InsuranceComponentRetirement' &&
          component.typeCode !== SALARY_EXCHANGE_TYPE_CODE,
      ),
    )
    .sort(byPremiumPaymentDescending)
    .sort(
      (a, b) =>
        INSURANCE_PREMIUM_STATUS_SORT_ORDER[a.premiumStatus ?? 'NO_STATUS'] -
        INSURANCE_PREMIUM_STATUS_SORT_ORDER[b.premiumStatus ?? 'NO_STATUS'],
    )
    .shift();
