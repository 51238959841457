import { Table, Td, Th, Tr } from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import { jobOffersOverviewQuery_company_Company_jobOffers_JobOfferConnection_edges_JobOfferEdge_node_JobOffer as JobOffer } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/companies/company';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';

import { MONTHLY_SALARY_REMUNERATION_TYPE_ID } from '../../../utils/constants';
import { jobOfferMessages, jobOfferStatusMessages } from '../../messages';

interface Props {
  jobOffers: JobOffer[];
  navigation: React.ReactNode;
}

export const JobOffersTable: React.FC<Props> = ({ jobOffers, navigation }) => {
  const history = useHistory();
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  return (
    <>
      <ChipsWrapper>
        <AssistChip
          to={`/companies/${companyId}/job-offers/create`}
          text={<FormattedMessage {...jobOfferMessages.createJobOffer} />}
          leadingIcon={addCircle}
        />
      </ChipsWrapper>
      <Table navigation={navigation}>
        <colgroup>
          <col style={{ width: '40%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.status} />
            </Th>
            <Th type="number">
              <FormattedMessage {...jobOfferMessages.monthlySalary} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.updatedAt} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {jobOffers.map(jobOffer => {
            const monthlySalary = jobOffer.remunerations?.find(
              r => r.idCode === MONTHLY_SALARY_REMUNERATION_TYPE_ID,
            )?.value;
            return (
              <Tr
                key={jobOffer.id}
                linked
                onClick={() => {
                  history.push(
                    `/companies/${companyId}/job-offers/edit/${jobOffer.id}`,
                  );
                }}
              >
                <Td>{jobOffer.prospectName}</Td>
                <Td>
                  <FormattedMessage
                    select={jobOffer.status}
                    messages={jobOfferStatusMessages}
                  />
                </Td>
                <Td type="number">
                  {monthlySalary ? (
                    <FormattedCurrency
                      value={monthlySalary}
                      currency="SEK"
                      noSuffix
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                <Td>
                  {jobOffer.updatedAt ? (
                    <FormattedDate value={jobOffer.updatedAt} />
                  ) : (
                    <NoValue />
                  )}
                </Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
