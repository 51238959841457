import { suffixMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import React, { isValidElement } from 'react';

export type After = 'PER_MONTH' | 'PER_YEAR' | React.ReactNode;

interface Props {
  after: After;
}

export const AfterValue: React.FC<Props> = ({ after }) => {
  switch (after) {
    case 'PER_MONTH':
      return <FormattedMessage {...suffixMessages.perMonth} />;
    case 'PER_YEAR':
      return <FormattedMessage {...suffixMessages.perYear} />;
    default:
      return isValidElement(after) ? <span>{after}</span> : null;
  }
};
