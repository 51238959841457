import gql from 'graphql-tag';

export const CREATE_REPRESENTATIVE_MODAL_QUERY = gql`
  query createRepresentativeModalQuery($companyId: ID!) {
    proposalContactPerson(companyId: $companyId) {
      firstName
      lastName
      personalIdentityNumber
      email
      phoneNumber
    }
  }
`;
