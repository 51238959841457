export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M6.47727273,0 C5.53568182,0 4.77272727,0.762954545 4.77272727,1.70454545 C4.77272727,2.64613636 5.53568182,3.40909091 6.47727273,3.40909091 C6.59454545,3.40909091 6.70970864,3.3969673 6.820845,3.37446736 C6.82902682,2.348331 7.21617205,1.41432525 7.84889932,0.700461682 C7.53867205,0.277052591 7.04181818,0 6.47727273,0 Z M10.9090909,0.681818182 C9.40295455,0.681818182 8.18181818,1.90295455 8.18181818,3.40909091 C8.18181818,4.91522727 9.40295455,6.13636364 10.9090909,6.13636364 C12.4152273,6.13636364 13.6363636,4.91522727 13.6363636,3.40909091 C13.6363636,1.90295455 12.4152273,0.681818182 10.9090909,0.681818182 Z M3.40909091,5.45454545 C3.19743322,5.45539177 2.98718926,5.48908463 2.78586648,5.55442091 L0,6.20960591 L0,11.1194959 L2.72727273,10.2272727 L8.28835227,12.1728518 C8.73289773,12.3283064 9.22276295,12.2912536 9.63867205,12.0689809 L14.6337893,9.39763841 L13.8307882,7.68377114 L8.92089818,9.93563591 L8.66788023,9.60271636 L8.86763114,9.15527318 C9.17785841,8.46322773 8.86395614,7.65091636 8.16850159,7.34818909 L4.24937857,5.636985 C4.22517495,5.62585566 4.20075623,5.61520022 4.17613636,5.60502477 C3.93250518,5.50602297 3.67206878,5.45493045 3.40909091,5.45454545 Z',
        transform: 'translate(2.500000, 3.750000) scale(1.33)',
      },
    },
  ],
};
