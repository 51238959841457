import {
  ContentContainer,
  Grid,
  NavigationCardLayoutSkeleton,
} from '@frontend/ui';
import { GridCell33 } from 'components/GridCell';
import React from 'react';

export const Loading: React.FC = () => (
  <ContentContainer>
    <Grid>
      <GridCell33>
        <NavigationCardLayoutSkeleton characterCount={50} />
      </GridCell33>
      <GridCell33>
        <NavigationCardLayoutSkeleton characterCount={50} />
      </GridCell33>
      <GridCell33>
        <NavigationCardLayoutSkeleton characterCount={50} />
      </GridCell33>
      <GridCell33>
        <NavigationCardLayoutSkeleton characterCount={50} />
      </GridCell33>
      <GridCell33>
        <NavigationCardLayoutSkeleton characterCount={50} />
      </GridCell33>
      <GridCell33>
        <NavigationCardLayoutSkeleton characterCount={50} />
      </GridCell33>
      <GridCell33>
        <NavigationCardLayoutSkeleton characterCount={50} />
      </GridCell33>
      <GridCell33>
        <NavigationCardLayoutSkeleton characterCount={50} />
      </GridCell33>
      <GridCell33>
        <NavigationCardLayoutSkeleton characterCount={50} />
      </GridCell33>
    </Grid>
  </ContentContainer>
);
