import { ImportColumn } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  employeeFormMessages,
  employmentMessages,
} from 'app/messages/employees';
import { defineMessages } from 'react-intl';

export const staticColumnLabelMessages = defineMessages<ImportColumn>({
  ADDRESS_CAREOF: {
    id: 'staticColumnLabelMessages.ADDRESS_CAREOF',
    defaultMessage: 'Adress c/o',
  },
  ADDRESS_CITY: {
    id: 'staticColumnLabelMessages.ADDRESS_CITY',
    defaultMessage: 'Adress stad',
  },
  ADDRESS_COUNTRY: {
    id: 'staticColumnLabelMessages.ADDRESS_COUNTRY',
    defaultMessage: 'Adress land',
  },
  ADDRESS_LINE1: {
    id: 'staticColumnLabelMessages.ADDRESS_LINE1',
    defaultMessage: 'Adressrad 1',
  },
  ADDRESS_LINE2: {
    id: 'staticColumnLabelMessages.ADDRESS_LINE2',
    defaultMessage: 'Adressrad 2',
  },
  ADDRESS_POSTAL_CODE: commonMessages.postalCode,
  BENEFIT_PACKAGE_EFFECTIVE_DATE: {
    id: 'staticColumnLabelMessages.BENEFIT_PACKAGE_EFFECTIVE_DATE',
    defaultMessage: 'Förmånsgrupp fr.o.m.',
  },
  BENEFIT_PACKAGE: commonMessages.benefitPackage,
  BIRTH_DATE: employeeFormMessages.birthdate,
  COLLECTIVE_AGREEMENT: commonMessages.collectiveAgreement,
  COMPANY_CAR_MONTHLY_VALUE: {
    id: 'staticColumnLabelMessages.COMPANY_CAR_MONTHLY_VALUE',
    defaultMessage: 'Förmånsvärde tjänstebil (kr/mån)',
  },
  COMPANY_REGISTRATION_NUMBER: commonMessages.registrationNumber,
  COST_CENTER: {
    id: 'staticColumnLabelMessages.COST_CENTER',
    defaultMessage: 'Kostnadsställe',
  },
  EFFECTIVE_DATE: commonMessages.effectiveDate,
  EMAIL: commonMessages.email,
  EMPLOYEE_NUMBER: {
    id: 'staticColumnLabelMessages.EMPLOYEE_NUMBER',
    defaultMessage: 'Anställningsnummer',
  },
  EMPLOYER_CONTRIBUTION_SHARE: {
    id: 'staticColumnLabelMessages.EMPLOYER_CONTRIBUTION_SHARE',
    defaultMessage: 'Arbetsgivaravgifter (%)',
  },
  EMPLOYMENT_TYPE: {
    id: 'staticColumnLabelMessages.EMPLOYMENT_TYPE',
    defaultMessage: 'Anställningstyp',
  },
  EXTENT: {
    id: 'staticColumnLabelMessages.EXTENT',
    defaultMessage: 'Sysselsättningsgrad',
  },
  FITNESS_CONTRIBUTION_BALANCE: {
    id: 'staticColumnLabelMessages.FITNESS_CONTRIBUTION_BALANCE',
    defaultMessage: 'Friskvårdssaldo',
  },
  GIVEN_NAME: commonMessages.givenName,
  HOURS_WORKED: {
    id: 'staticColumnLabelMessages.HOURS_WORKED',
    defaultMessage: 'Arbetade timmar',
  },
  INCOME_TAX_TABLE: {
    id: 'staticColumnLabelMessages.INCOME_TAX_TABLE',
    defaultMessage: 'Skattetabell',
  },
  IS_FULLY_CAPABLE_FOR_WORK: {
    id: 'staticColumnLabelMessages.IS_FULLY_CAPABLE_FOR_WORK',
    defaultMessage: 'Fullt arbetsför',
  },
  IS_OWNER: {
    id: 'staticColumnLabelMessages.IS_OWNER',
    defaultMessage: 'Företagsägare',
  },
  LAST_NAME: commonMessages.lastName,
  MONTHLY_SALARY_EFFECTIVE_DATE: {
    id: 'staticColumnLabelMessages.MONTHLY_SALARY_EFFECTIVE_DATE',
    defaultMessage: 'Månadslön gäller fr.o.m.',
  },
  MONTHLY_SALARY: commonMessages.monthlySalary,
  ORGANIZATIONAL_UNIT: commonMessages.organizationalUnit,
  PERSONAL_IDENTITY_NUMBER: commonMessages.personalIdentityNumber,
  PHONE: commonMessages.phone,
  SEX: employeeFormMessages.sex,
  STARTED_AT: employmentMessages.employmentStartDate,
  STATUS_EFFECTIVE_DATE: employmentMessages.employmentActivityEffectiveDate,
  STATUS_EFFECTIVE_UNTIL: employmentMessages.employmentActivityEffectiveUntil,
  STATUS_RATE: employmentMessages.employmentActivityRate,
  STATUS: employmentMessages.employmentActivity,
  TERMINATED_AT: employmentMessages.employmentEndDate,
  VACATION_DAYS_ADVANCE: {
    id: 'staticColumnLabelMessages.VACATION_DAYS_ADVANCE',
    defaultMessage: 'Förskottssemester',
  },
  VACATION_DAYS_PAID: {
    id: 'staticColumnLabelMessages.VACATION_DAYS_PAID',
    defaultMessage: 'Betalda semesterdagar',
  },
  VACATION_DAYS_PER_YEAR: {
    id: 'staticColumnLabelMessages.VACATION_DAYS_PER_YEAR',
    defaultMessage: 'Semesterrätt',
  },
  VACATION_DAYS_SAVED: {
    id: 'staticColumnLabelMessages.VACATION_DAYS_SAVED',
    defaultMessage: 'Sparade semesterdagar',
  },
  VACATION_DAYS_UNPAID: {
    id: 'staticColumnLabelMessages.VACATION_DAYS_UNPAID',
    defaultMessage: 'Obetalda semesterdagar',
  },
});
