import {
  FlexSalaryExchangeStatus,
  salaryExchangesTableQuery,
  salaryExchangesTableQueryVariables,
} from 'app/apollo/graphql/types';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React, { useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { SalaryExchangesTable } from './components/Table';
import { SALARY_EXCHANGES_TABLE_QUERY } from './graphql/queries';
import { updateQuery } from './utils/update-query';

interface Props {
  violatedQueryParam: string;
}

export const SalaryExchanges: React.FC<Props> = ({ violatedQueryParam }) => {
  const location = useLocation();
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );
  const suspendRef = useRef(true);
  const parsedSearch = qs.parse(location.search);
  const violated = parsedSearch[violatedQueryParam] === 'true';

  const { data, previousData, loading, fetchMore } = useQuery<
    salaryExchangesTableQuery,
    salaryExchangesTableQueryVariables
  >(SALARY_EXCHANGES_TABLE_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    suspend: suspendRef.current,
    variables: {
      companyId,
      statuses: violated ? [FlexSalaryExchangeStatus.ACTIVE] : undefined,
      onlyViolated: violated,
      first: Number(perPage),
    },
    onCompleted: () => {
      suspendRef.current = false;
    },
  });

  const _data = data ?? previousData;

  if (!_data?.salaryExchanges) {
    return null;
  }

  const { edges, pageInfo } = _data.salaryExchanges;

  const salaryExchanges = edges?.map(edge => edge.node) ?? [];

  const navigation = (
    <TableNavigation
      perPage={perPage}
      setPerPage={setPerPage}
      pageInfo={pageInfo}
      onNextPage={() => {
        fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
          updateQuery,
        });
      }}
      onPreviousPage={() => {
        fetchMore({
          variables: {
            before: pageInfo.startCursor,
            first: undefined,
            last: Number(perPage),
          },
          updateQuery,
        });
      }}
    />
  );

  return (
    <>
      {loading && <TopLoading />}
      <SalaryExchangesTable
        salaryExchanges={salaryExchanges}
        navigation={navigation}
      />
    </>
  );
};
