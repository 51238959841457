import { Dot, Td, Tr } from '@frontend/ui';
import { EventTypeCategory } from 'app/apollo/graphql/types';
import { eventTypeCategoryMessages } from 'app/messages/advisor';
import { commonMessages } from 'app/messages/common';
import { OpenAddHeldMeetingModal } from 'app/pages/advisor';
import { FormattedMessage } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import { RowOptions } from 'features/advisor/components/RowOptions';
import React from 'react';

import { EmployeeWithMeeting } from '../..';

interface Props {
  meeting: EmployeeWithMeeting;
  openAddHeldMeetingModal: OpenAddHeldMeetingModal;
  sendInvitation: () => void;
  cancelMeeting?: () => void;
  deleteMeeting?: () => void;
}

export const AdviceMeetingTableRow: React.FC<Props> = ({
  meeting,
  sendInvitation,
  openAddHeldMeetingModal,
  deleteMeeting,
  cancelMeeting,
}) => (
  <Tr
    inlineMenu={
      <RowOptions
        employee={{
          ...meeting,
          meetingDate: meeting.date,
          eventTypeCategory: meeting.eventTypeCategory as EventTypeCategory,
        }}
        deleteMeeting={deleteMeeting}
        cancelMeeting={cancelMeeting}
        sendInvitation={sendInvitation}
        openAddHeldMeetingModal={openAddHeldMeetingModal}
      />
    }
  >
    <Td align="left" type="number">
      <FormattedNaturalPersonIdentifier
        nids={meeting.membership.naturalPersonIdentifiers}
      />
    </Td>
    <Td align="left">
      {`${meeting.membership.givenName} ${meeting.membership.lastName}`}
    </Td>
    <Td>{meeting.membership.company.displayName}</Td>
    <Td align="right" type="number">
      {meeting.date}
    </Td>
    <Td>
      <FormattedMessage
        select={meeting.eventTypeCategory}
        messages={eventTypeCategoryMessages}
      />
    </Td>
    <Td>
      {meeting.confirmed ? (
        <Dot
          colorValue="green"
          label={<FormattedMessage {...commonMessages.yes} />}
        />
      ) : (
        <Dot
          colorValue="orange"
          label={<FormattedMessage {...commonMessages.no} />}
        />
      )}
    </Td>
    <Td align="right" type="number">
      {meeting.latestEmailSentAt ?? <NoValue />}
    </Td>
  </Tr>
);
