import { companyCustomTypesQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: companyCustomTypesQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: companyCustomTypesQuery;
  },
) => {
  if (
    !previousResult?.company?.customTypes ||
    !fetchMoreResult?.company?.customTypes
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.customTypes;

  return {
    company: {
      ...previousResult.company,
      customTypes: {
        pageInfo,
        __typename: previousResult.company.customTypes.__typename,
        edges: [...edges],
      },
    },
  };
};
