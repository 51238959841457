import { EmptyState as EmptyStateUI } from '@frontend/ui';
import { work } from '@frontend/ui/icons';
import { FormattedMessage } from 'components/formats';
import { smeCompanyMessages } from 'features/sme/messages/sme';
import React from 'react';

export const EmptyState: React.FC = () => (
  <EmptyStateUI
    icon={work}
    title={<FormattedMessage {...smeCompanyMessages.emptyStateHeader} />}
  >
    <FormattedMessage {...smeCompanyMessages.emptyStateDescription} />
  </EmptyStateUI>
);
