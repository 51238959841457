import { Icon, Subsection, SubsectionHeader } from '@frontend/ui';
import { _delete as deleteIcon, edit } from '@frontend/ui/icons';
import {
  smeCompanyBenefitPackagesQuery,
  smeCompanyBenefitPackagesQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import { TopLoading } from 'components/TopLoading';
import { useConfirm } from 'contexts/confirmation';
import {
  smeBenefitPackagesMessages,
  smeBenefitTypesMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { useProposalReadonly } from '../../utils/use-proposal-readonly';
import { Details } from '../benefits/Details';
import { BenefitsTable } from './BenefitsTable';
import { useDelete } from './delete';
import { EditBenefitPackageModal, getEditBenefitPackageLink } from './edit';
import { SME_COMPANY_BENEFIT_PACKAGES_QUERY } from './graphql/queries';
import { useRemoveBenefit } from './remove-benefit';

const ErrorWrapper = styled.div`
  margin-top: 1rem;
`;

const getBenefitPackages = (data?: smeCompanyBenefitPackagesQuery) =>
  data?.proposal?.benefitPackages?.length
    ? data.proposal.benefitPackages
    : null;

const isEmpty = (data?: smeCompanyBenefitPackagesQuery) =>
  !getBenefitPackages(data);

export const BenefitPackages: React.FC = () => {
  const { formatMessage } = useIntl();

  const { push } = useHistory();
  const location = useLocation();
  const match = useRouteMatch<ProposalMatchParams>();
  const { params } = match;
  const disabled = useProposalReadonly();
  const { confirm } = useConfirm();

  const { _delete, deleteLoading, deleteError } = useDelete({ params });
  const { remove, removeLoading, removeError } = useRemoveBenefit({ params });

  const { data } = useQuery<
    smeCompanyBenefitPackagesQuery,
    smeCompanyBenefitPackagesQueryVariables
  >(SME_COMPANY_BENEFIT_PACKAGES_QUERY, {
    errorPolicy: 'all',
    isEmpty,
    suspend: true,
    variables: { id: params.proposalId },
  });

  const benefitPackages = getBenefitPackages(data);

  if (!benefitPackages) {
    return null;
  }

  return (
    <>
      {(removeLoading || deleteLoading) && <TopLoading />}
      <EditBenefitPackageModal />
      {benefitPackages.map(benefitPackage => (
        <Subsection key={benefitPackage.id}>
          <SubsectionHeader
            actions={
              <KebabMenu disabled={disabled}>
                <MenuItem
                  title={
                    <FormattedMessage
                      {...smeBenefitPackagesMessages.editName}
                    />
                  }
                  icon={<Icon icon={edit} decorative />}
                  link={getEditBenefitPackageLink({
                    location,
                    benefitPackageId: benefitPackage.id,
                  })}
                />
                <MenuItem
                  title={
                    <FormattedMessage
                      {...smeBenefitPackagesMessages.deleteBenefitPackage}
                    />
                  }
                  icon={<Icon icon={deleteIcon} decorative />}
                  onClick={async () => {
                    const confirmed = await confirm({
                      description: formatMessage(
                        smeBenefitPackagesMessages.confirmDeleteBenefitPackage,
                      ),
                    });

                    if (confirmed) {
                      _delete({
                        id: benefitPackage.id,
                        proposalId: params.proposalId,
                      });
                    }
                  }}
                />
              </KebabMenu>
            }
          >
            <FormattedMessage
              {...smeBenefitPackagesMessages.benefitPackage}
              values={{ name: benefitPackage.name }}
            />
          </SubsectionHeader>
          <BenefitsTable
            key={benefitPackage.id}
            benefits={benefitPackage.benefits.map(benefit => ({
              id: benefit.id,
              name: formatMessage({
                select: benefit.type,
                messages: smeBenefitTypesMessages,
              }),
              details: <Details benefit={benefit} />,
              type: benefit.type,
            }))}
            onEdit={id =>
              push(
                `/sme/${params.companyId}/proposals/${params.proposalId}/benefits/${id}`,
              )
            }
            onRemove={id =>
              remove({
                benefitId: id,
                benefitPackageId: benefitPackage.id,
                proposalId: params.proposalId,
              })
            }
          />
          {(removeError.id === benefitPackage.id ||
            deleteError.id === benefitPackage.id) && (
            <ErrorWrapper>
              <GraphQlError error={removeError.error ?? deleteError.error} />
            </ErrorWrapper>
          )}
        </Subsection>
      ))}
    </>
  );
};
