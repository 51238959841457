import { useMutation } from '@apollo/client';
import {
  InlineSwitchFieldTd,
  Section,
  SectionHeader,
  Table,
  Td,
  Tr,
} from '@frontend/ui';
import {
  Language,
  updateCompanyDetailsMutation,
  updateCompanyDetailsMutationVariables,
} from 'app/apollo/graphql/types';
import { companyMessages } from 'app/messages/company';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LanguageSelector } from 'components/LanguageSelector';
import { TopLoading } from 'components/TopLoading';
import { UPDATE_COMPANY_DETAILS_MUTATION } from 'features/companies/graphql/mutations';
import { COMPANY_DETAILS_QUERY } from 'features/companies/graphql/queries';
import React, { useId, useState } from 'react';

interface Props {
  companyId: string;
  emailLanguage: Language | null;
  newsletterOptOut: boolean | null;
}

export const NewsLetterSettings: React.FC<Props> = ({
  emailLanguage,
  newsletterOptOut,
  companyId,
}) => {
  const id = useId();

  const [updateSetting, { loading, error }] = useMutation<
    updateCompanyDetailsMutation,
    updateCompanyDetailsMutationVariables
  >(UPDATE_COMPANY_DETAILS_MUTATION, {
    errorPolicy: 'all',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: COMPANY_DETAILS_QUERY,
        variables: { id: companyId },
      },
    ],
  });

  // The checkbox should be checked when the setting is false,
  // i.e. when the company wants to have newsletter emails
  // sent to its employees.
  const [checked, setChecked] = useState<boolean>(!newsletterOptOut);

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <Section>
        <SectionHeader>
          <FormattedMessage {...companyMessages.newsletterSettingsHeader} />
        </SectionHeader>
        <Table size="xsmall">
          <tbody>
            <Tr
              inlineMenu={
                <LanguageSelector
                  setLocale={locale => {
                    updateSetting({
                      variables: {
                        input: {
                          id: companyId,
                          employeeEmailLanguage:
                            locale === 'en-US'
                              ? Language.EN_US
                              : Language.SV_SE,
                        },
                      },
                    });
                  }}
                  value={emailLanguage === Language.EN_US ? 'en-US' : 'sv-SE'}
                />
              }
            >
              <Td>
                <FormattedMessage {...companyMessages.emailLanguage} />
              </Td>
            </Tr>
            <Tr>
              <Td id={id}>
                <FormattedMessage
                  {...companyMessages.newsletterSettingsOptOut}
                />
              </Td>
              <InlineSwitchFieldTd
                labelId={id}
                checked={checked}
                onChange={() => {
                  setChecked(_checked => !_checked);
                  updateSetting({
                    variables: {
                      input: {
                        id: companyId,
                        newsletterOptOut: checked,
                      },
                    },
                  });
                }}
              />
            </Tr>
          </tbody>
        </Table>
      </Section>
    </>
  );
};
