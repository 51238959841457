import { Grid, GridCell } from '@frontend/ui';
import React from 'react';

interface CommonGridCellProps {
  children: React.ReactNode;
}

interface GridCell50Props extends CommonGridCellProps {
  allBreakpoints?: boolean;
}

export const GridCell50: React.FC<GridCell50Props> = ({
  allBreakpoints,
  ...props
}) => (
  <GridCell
    desktop={6}
    tablet={allBreakpoints ? 4 : 8}
    phone={allBreakpoints ? 2 : 4}
    {...props}
  />
);

export const GridCell50OffsetRight: React.FC<CommonGridCellProps> = props => (
  <>
    <GridCell desktop={5} tablet={8} phone={4} {...props} />
    <GridCell desktop={1} />
  </>
);

export const GridCell33: React.FC<CommonGridCellProps> = props => (
  <GridCell desktop={4} tablet={4} phone={4} {...props} />
);

export const GridCell25: React.FC<CommonGridCellProps> = props => (
  <GridCell desktop={3} tablet={4} phone={4} {...props} />
);

export const CardGridCell: React.FC<CommonGridCellProps> = ({ children }) => (
  <GridCell tablet={4} span={4}>
    {children}
  </GridCell>
);

export const TextGrid: React.FC<CommonGridCellProps> = ({ children }) => (
  <Grid>
    <GridCell span={8}>{children}</GridCell>
  </Grid>
);

interface FigureGridCellProps extends CommonGridCellProps {
  wide?: boolean;
}

export const FigureGridCell: React.FC<FigureGridCellProps> = props => (
  <GridCell
    desktop={3}
    tablet={props.wide ? 4 : 2}
    phone={props.wide ? 4 : 2}
    {...props}
  />
);
