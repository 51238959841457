import { defineMessages } from 'react-intl';

export const costReportingMessages = defineMessages({
  emptyStateDimensionsTitle: {
    id: 'costReportingMessages.emptyStateDimensionsTitle',
    defaultMessage: 'Det finns inga dimensioner att visa',
  },
  emptyStateDimensionsDescription: {
    id: 'costReportingMessages.emptyStateDimensionsDescription',
    defaultMessage: 'Dimensioner som skapas kommer att visas här',
  },
  emptyStateObjectsTitle: {
    id: 'costReportingMessages.emptyStateObjectsTitle',
    defaultMessage: 'Det finns inga objekt att visa',
  },
  emptyStateObjectsDescription: {
    id: 'costReportingMessages.emptyStateObjectsDescription',
    defaultMessage: 'Objekt som skapas kommer att visas här',
  },
  createDimension: {
    id: 'costReportingMessages.createDimension',
    defaultMessage: 'Skapa dimension',
  },
  editDimension: {
    id: 'costReportingMessages.editDimension',
    defaultMessage: 'Ändra dimension',
  },
  createDimensionSuccess: {
    id: 'costReportingMessages.createDimensionSuccess',
    defaultMessage: 'Dimensionen har skapats',
  },
  editDimensionSuccess: {
    id: 'costReportingMessages.editDimensionSuccess',
    defaultMessage: 'Dimensionen har ändrats',
  },
  updatelink: {
    id: 'costReportingMessages.updatelink',
    defaultMessage: 'Ändra',
  },
  deleteLink: {
    id: 'costReportingMessages.deleteLink',
    defaultMessage: 'Ta bort',
  },
  nameFieldLabel: {
    id: 'costReportingMessages.nameFieldLabel',
    defaultMessage: 'Filnamn',
  },
  codeFieldLabel: {
    id: 'costReportingMessages.codeFieldLabel',
    defaultMessage: 'Kod',
  },
  dimensionAppbarTitle: {
    id: 'costReportingMessages.dimensionAppbarTitle',
    defaultMessage: '{dimensionName} ({dimensionCode})',
  },
  createObject: {
    id: 'costReportingMessages.createObject',
    defaultMessage: 'Skapa {dimensionName}',
  },
  editObject: {
    id: 'costReportingMessages.editObject',
    defaultMessage: 'Ändra {dimensionName}',
  },
  tableCodeColumn: {
    id: 'costReportingMessages.tableCodeColumn',
    defaultMessage: 'Kod',
  },
  tableNameColumn: {
    id: 'costReportingMessages.tableNameColumn',
    defaultMessage: 'Namn',
  },
  tableMembershipsColumn: {
    id: 'costReportingMessages.tableMembershipsColumn',
    defaultMessage: 'Antal anställda',
  },
  createObjectSuccess: {
    id: 'costReportingMessages.createObjectSuccess',
    defaultMessage: 'Objektet har skapats',
  },
  editObjectSuccess: {
    id: 'costReportingMessages.editObjectSuccess',
    defaultMessage: 'Objektet har ändrats',
  },
  costReportingEditMenu: {
    id: 'costReportingMessages.costReportingEditMenu',
    defaultMessage: 'Kostnadsrapporteringsalternativ',
  },
});
