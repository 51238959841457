import { ApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import { toMoney } from '@frontend/utils';
import {
  occupationalPensionChangesQuery,
  occupationalPensionChangesQueryVariables,
  pensionTableQuery_changes,
  setFixedPremiumMutation,
  setFixedPremiumMutationVariables,
} from 'app/apollo/graphql/types';
import { formSubmitMessages } from 'app/messages/form';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useRef } from 'react';
import { useParams } from 'react-router';

import { SET_FIXED_PREMIUM_MUTATION } from '../graphql/mutations';
import { OCCUPATIONAL_PENSION_CHANGES_QUERY } from '../graphql/queries';
import { isOccupationalPensionChange } from './is-occupational-pension-change';

const POLL_INTERVAL = 1000;

interface Props {
  onRequestClose: () => void;
}

export interface FormValues {
  effectiveDate: string;
  premium: number;
  submissionError?: string;
}

interface Submit {
  submit: (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => Promise<void>;
  mutationError?: ApolloError;
}

export const useSubmit = ({ onRequestClose }: Props): Submit => {
  const { send } = useNotification();
  const { formatMessage } = useIntl();
  const { userAccountId, companyId } = useParams<EmployeeRouteMatchParams>();

  const resolveRef = useRef<(value: pensionTableQuery_changes) => void>(
    () => undefined,
  );
  const rejectRef = useRef<(reason: Error) => void>((reason: Error) => reason);

  const { startPolling, stopPolling, previousData } = useQuery<
    occupationalPensionChangesQuery,
    occupationalPensionChangesQueryVariables
  >(OCCUPATIONAL_PENSION_CHANGES_QUERY, {
    skip: !companyId,
    errorPolicy: 'all',
    variables: {
      userAccountId,
      companyId,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: _data => {
      // When updating premium to 0
      if (
        !!previousData?.changes?.find(isOccupationalPensionChange) &&
        !_data.changes?.find(isOccupationalPensionChange)
      ) {
        stopPolling();
        resolveRef.current(previousData.changes[0]);
      }

      // When updating premium to any amount greater than 0,
      // find the first occupational pension change.
      // This change may not be the newly created change.
      // Fix it some other time to find the correct change.
      const change = _data.changes?.find(isOccupationalPensionChange);

      if (change) {
        stopPolling();
        resolveRef.current(change);
      }
    },
    onError: _error => {
      rejectRef.current(new Error(_error.message));
      stopPolling();
    },
  });

  const [setFixedPremium, { error: mutationError }] = useMutation<
    setFixedPremiumMutation,
    setFixedPremiumMutationVariables
  >(SET_FIXED_PREMIUM_MUTATION, {
    update: cache => {
      cache.evict({ fieldName: 'fixedPremium' });
      cache.gc();
    },
  });

  const submit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    try {
      const { premium, effectiveDate } = values;

      const _premium = toMoney(premium);

      if (_premium == null) {
        throw new Error();
      }

      await setFixedPremium({
        variables: {
          input: {
            userAccountId,
            companyId,
            effectiveDate,
            premium: _premium,
          },
        },
      });

      startPolling(POLL_INTERVAL);

      const getChange = async () =>
        new Promise((resolve, reject) => {
          resolveRef.current = resolve;
          rejectRef.current = reject;
        });

      await getChange();

      send({
        message: formatMessage(
          formSubmitMessages.updateFixedPensionPremiumSubmitSuccess,
        ),
        type: 'success',
      });
      onRequestClose();
    } catch (err) {
      helpers.setErrors({
        submissionError: err?.message,
      });
    }
  };

  return { submit, mutationError };
};
