import {
  companyEmployeesOverviewQuery,
  companyEmployeesOverviewQueryVariables,
} from 'app/apollo/graphql/types';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { useQuery } from 'app/utils/use-query';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useCurrentUser } from 'contexts/current-permissions';
import qs from 'query-string';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { CompanyFcfwNotification } from './assert-work-capability/notification/company';
import { EmployeesEmptyState } from './components/EmployeesEmptyState';
import { EmployeesTable } from './components/EmployeesTable';
import { queryParam } from './components/IncludeTerminatedFilter';
import { COMPANY_EMPLOYEES_OVERVIEW_QUERY } from './graphql/queries';
import { updateQuery } from './utils/update-query';

export interface MatchParams {
  companyId: string;
}

export const EmployeesOverview: React.FC = () => {
  const match = useRouteMatch<MatchParams>();
  const location = useLocation();

  const parsed = qs.parse(location.search);
  const { search, per_page } = parsed;
  const includeTerminated = parsed[queryParam] === 'true';
  const { permissions } = useCurrentUser();

  const {
    previousData: membershipPreviousData,
    data: membershipData,
    loading: membershipLoading,
    error: membershipError,
    fetchMore,
  } = useQuery<
    companyEmployeesOverviewQuery,
    companyEmployeesOverviewQueryVariables
  >(COMPANY_EMPLOYEES_OVERVIEW_QUERY, {
    variables: {
      id: match.params.companyId,
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      search,
      includeTerminated,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const data = membershipData ?? membershipPreviousData;
  const pageInfo = data?.company?.memberships?.pageInfo;
  const memberships = data?.company?.memberships;

  const canAssertFcfw = hasBackstagePermission(
    'flexifits:fcfw',
    'assert',
    permissions,
  );

  return (
    <>
      {membershipLoading && <TopLoading />}
      {membershipError && <GraphQlError error={membershipError} />}
      {canAssertFcfw && <CompanyFcfwNotification />}
      {memberships && pageInfo ? (
        <EmployeesTable
          pageInfo={pageInfo}
          onNextPage={() => {
            fetchMore({
              variables: {
                after: pageInfo.endCursor,
              },
              updateQuery,
            });
          }}
          onPreviousPage={() => {
            fetchMore({
              variables: {
                before: pageInfo.startCursor,
                first: undefined,
                last: per_page
                  ? parseInt(per_page, 10)
                  : DEFAULT_RESULT_PER_PAGE,
              },
              updateQuery,
            });
          }}
          employees={memberships.edges.map(e => e.node)}
          membershipExportUrl={data?.company?.membershipExportUrl}
        />
      ) : !membershipLoading ? (
        <EmployeesEmptyState />
      ) : null}
    </>
  );
};
