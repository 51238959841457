import {
  FlexChangesHistoryInputFilter,
  FlexChangeStatus,
  FlexChangeType,
  FlexInsuranceAction,
} from 'app/apollo/graphql/types';
import qs from 'query-string';

import { Filters } from '..';

type QueryFields<T> = {
  [K in keyof T]?: string | string[];
};

const isStringArray = (value?: string | string[]): value is string[] =>
  Array.isArray(value);

const isString = (value?: string | string[]): value is string =>
  typeof value === 'string';

const isStatusFilter = (status: string): status is FlexChangeStatus =>
  Object.values(FlexChangeStatus).some(_status => _status === status);

const isTypeFilter = (type: string): type is FlexChangeType =>
  Object.values(FlexChangeType).some(_type => _type === type);

const isActionFilter = (action: string): action is FlexInsuranceAction =>
  Object.values(FlexInsuranceAction).some(_action => _action === action);

export const getFilterQueryArgs = (): FlexChangesHistoryInputFilter => {
  const {
    type,
    status,
    action,
    effectiveFrom,
    effectiveUntil,
  }: QueryFields<Filters> = qs.parse(location.search);

  return {
    type:
      isStringArray(type) && type.every(isTypeFilter)
        ? type
        : isString(type) && isTypeFilter(type)
          ? [type]
          : undefined,
    status:
      isStringArray(status) && status.every(isStatusFilter)
        ? status
        : isString(status) && isStatusFilter(status)
          ? [status]
          : undefined,
    insuranceAction:
      isStringArray(action) && action.every(isActionFilter)
        ? action
        : isString(action) && isActionFilter(action)
          ? [action]
          : undefined,
    effectiveDateRange: {
      from: isString(effectiveFrom) ? effectiveFrom : null,
      until: isString(effectiveUntil) ? effectiveUntil : null,
    },
  };
};
