import { validationMessages } from 'app/messages/common';
import { IntlShape } from 'components/formats';
import { isEmail, isValidPhoneNumber } from 'validations';
import * as Yup from 'yup';

export const personalDataValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid email',
        intl.formatMessage(validationMessages.isValidEmail),
        value => isEmail(value),
      ),
    phone: Yup.string()
      .nullable()
      .test(
        'valid or no phone number',
        intl.formatMessage(validationMessages.isValidPhone),
        value => !value || isValidPhoneNumber(value),
      ),
  });
