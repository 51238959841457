import gql from 'graphql-tag';

import { editJobOfferDetails } from './fragments';

export const JOB_OFFER_BENEFIT_PACKAGES_QUERY = gql`
  query jobOfferBenefitPackagesQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const EDIT_JOB_OFFER_QUERY = gql`
  query editJobOfferQuery($jobOfferId: ID!) {
    jobOffer(id: $jobOfferId) {
      ...editJobOfferDetails
    }
  }
  ${editJobOfferDetails}
`;
