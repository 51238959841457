import gql from 'graphql-tag';

export const CREATE_SHARED_FILES_MUTATION = gql`
  mutation createSharedFilesMutation($input: CreateSharedFilesInput!) {
    createSharedFiles(input: $input) {
      clientMutationId
    }
  }
`;

export const DELETE_SHARED_FILE_MUTATION = gql`
  mutation deleteSharedFileMutation($input: DeleteSharedFileInput!) {
    deleteSharedFile(input: $input) {
      clientMutationId
    }
  }
`;
