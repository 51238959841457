import gql from 'graphql-tag';

export const ADD_BENEFITS_TO_BENEFIT_PACKAGE_MUTATION = gql`
  mutation addBenefitsToBenefitPackageMutation(
    $input: FlexAddBenefitsToBenefitPackageInput!
  ) {
    addBenefitsToBenefitPackage(input: $input) {
      clientMutationId
    }
  }
`;
