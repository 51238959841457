import { employeeFormMessages } from 'app/messages/employees';
import { IntlShape } from 'components/formats';

import { getAgeAt } from './get-age-at';

/**
 * @returns a warning message if user is under 18 or over 62 otherwise null
 */
export const getEmployeeWarningMessage = (
  birthdate: string,
  intl: IntlShape,
  context?: 'submit',
): string | null => {
  const age = getAgeAt(new Date().toString(), birthdate);

  if (age < 18) {
    return intl.formatMessage(
      context === 'submit'
        ? employeeFormMessages.confirmEmployeeIsUnder18
        : employeeFormMessages.employeeIsUnder18,
    );
  }

  if (age > 62) {
    return intl.formatMessage(
      context === 'submit'
        ? employeeFormMessages.confirmEmployeeIsOver62
        : employeeFormMessages.employeeIsOver62,
    );
  }

  return null;
};
