import { useMutation } from '@apollo/client';
import {
  CreateBenefitPackageInput,
  createBenefitPackageMutation,
  createBenefitPackageMutationVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { useIntl } from 'components/formats';
import { MatchParams } from 'features/companies/company';
import { useNotification } from 'features/notifications';
import { useRouteMatch } from 'react-router';

import { FormValues } from '..';
import { CREATE_BENEFIT_PACKAGE_MUTATION } from '../graphql/mutations';

interface Props {
  onRequestClose: () => void;
}

export const useSubmit = ({ onRequestClose }: Props) => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const { params } = useRouteMatch<MatchParams>();

  const [createBenefitPackage, { error: submissionError }] = useMutation<
    createBenefitPackageMutation,
    createBenefitPackageMutationVariables
  >(CREATE_BENEFIT_PACKAGE_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${params.companyId}`,
        fieldName: 'benefitPackages',
      });
      cache.gc();
    },
  });

  const submit = async (formValues: FormValues) => {
    try {
      const { name } = formValues;
      const input: CreateBenefitPackageInput = {
        companyId: params.companyId,
        name,
      };

      await createBenefitPackage({
        variables: { input },
      });

      send({
        message: formatMessage(commonBenefitMessages.benefitPackageCreated, {
          name,
        }),
        type: 'success',
      });

      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return { submit, submissionError };
};
