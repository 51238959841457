import { Card } from '@frontend/ui';
import styled from 'styled-components';

export const DISCOUNT_CARD_WIDTH = 17.5;
export const DISCOUNT_CARD_GUTTER_WIDTH = 1.5;

export const ScrollableCard = styled(Card)`
  width: ${DISCOUNT_CARD_WIDTH}rem;
  overflow: visible;
  display: block;

  &:not(:last-child) {
    margin-right: ${DISCOUNT_CARD_GUTTER_WIDTH}rem;
  }

  > div {
    width: inherit;
    height: inherit;
  }
`;
