import { Section, SectionHeader } from '@frontend/ui';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { Needs } from 'features/sme/companies/company/proposals/edit/needs';
import { Representatives } from 'features/sme/companies/company/proposals/edit/representatives';
import { smeNeedsMessages } from 'features/sme/messages/sme';
import React from 'react';

export const SmeCompanyProposalNeedsRoute: React.FC = () => (
  <Section>
    <SectionHeader>
      <FormattedMessage {...smeNeedsMessages.needsTitle} />
    </SectionHeader>
    <TextGrid>
      <DescriptionWrapper>
        <FormattedMessage {...smeNeedsMessages.needsDescription} />
      </DescriptionWrapper>
    </TextGrid>
    <Needs />
    <Representatives />
  </Section>
);
