import gql from 'graphql-tag';

export const EMPLOYEE_OVERVIEW_NOTIFICATIONS_QUERY = gql`
  query employeeOverviewNotificationsQuery(
    $companyId: ID!
    $userAccountId: ID!
    $benefitTypeNames: [String!]
  ) {
    company(id: $companyId) {
      id
      remunerationTypes {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    membership(userAccountId: $userAccountId, companyId: $companyId) {
      id
      email
      employment {
        from
        remuneration {
          type {
            id
          }
          price
        }
      }
    }
    entitlements(
      userAccountId: $userAccountId
      companyId: $companyId
      benefitTypeNames: $benefitTypeNames
    ) {
      edges {
        node {
          benefit {
            id
            type
            content {
              locale
            }
            configuration {
              data
            }
          }
        }
      }
    }
  }
`;
