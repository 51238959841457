import {
  totalCompensationSummaryCardQuery_membership_Membership_totalCompensation_TotalCompensationItem2 as TotalCompensationItem,
  totalCompensationSummaryCardQuery_Query,
} from 'app/apollo/graphql/types';

import {
  aggregateTotalCompChartData,
  ChartData,
} from '../../utils/aggregate-total-comp-chart-data';

interface Summary extends Omit<ChartData, 'titles'> {
  totalCompensation: readonly TotalCompensationItem[];
}

export const getSummaryData = (
  data?: totalCompensationSummaryCardQuery_Query,
): null | Summary => {
  if (!data?.membership?.totalCompensation) {
    return null;
  }

  const { totalCompensation } = data.membership;

  const { graphData, totalValue } =
    aggregateTotalCompChartData(totalCompensation);

  if (!graphData.length) {
    return null;
  }

  return {
    totalCompensation,
    graphData,
    totalValue,
  };
};
