import {
  employersPensionBenefitConfigWithFixedPremium,
  occupationalPensionBenefitConfigWithFixedPremium,
  ProposalBenefitType,
  ProposalPremiumCalculationMethod,
} from 'app/apollo/graphql/types';

import { isPensionBenefit } from '../../../compilation/benefit-packages-details/BenefitsTable/utils';

interface Configuration {
  __typename?: string;
}

interface ProposalBenefit {
  configuration: Configuration;
  id: string;
  type: ProposalBenefitType;
}

export interface BenefitPackage {
  benefits: readonly ProposalBenefit[];
  id: string;
  name: string;
}
type PensionConfigWithFixedPremium =
  | occupationalPensionBenefitConfigWithFixedPremium
  | employersPensionBenefitConfigWithFixedPremium;

const isPensionConfigWithFixedPremium = (
  config: Configuration,
): config is PensionConfigWithFixedPremium =>
  [
    'ProposalEmployersPensionBenefitConfig',
    'ProposalOccupationalPensionBenefitConfig',
  ].includes(config.__typename ?? '');

export enum FixedPremiumType {
  NO_FIXED_PREMIUM = 'NO_FIXED_PREMIUM',
  EXTRA_FIXED_PREMIUM = 'EXTRA_FIXED_PREMIUM',
  FIXED_PREMIUM_ONLY = 'FIXED_PREMIUM_ONLY',
}

/**
 *  Checks whether the provided benefit package contains a occupational/employers
 *  pension benefit and whether the benefit is configured with a fixed premium.
 *
 *  @param benefitPackage - A benefit package containing one or more benefits.
 *  @returns The type of fixed premium configuration.
 */
export const getFixedPremiumPensionConfig = (
  benefitPackage: BenefitPackage,
): FixedPremiumType => {
  const pensionBenefit = benefitPackage?.benefits.find(benefit =>
    isPensionBenefit(benefit),
  );

  if (
    !pensionBenefit ||
    !isPensionConfigWithFixedPremium(pensionBenefit.configuration)
  ) {
    return FixedPremiumType.NO_FIXED_PREMIUM;
  }

  const {
    configuration: { extraFixedPremium, premiumCalculationMethod },
  } = pensionBenefit;

  if (extraFixedPremium) {
    return FixedPremiumType.EXTRA_FIXED_PREMIUM;
  }

  if (
    premiumCalculationMethod ===
    ProposalPremiumCalculationMethod.FIXED_PREMIUM_ONLY
  ) {
    return FixedPremiumType.FIXED_PREMIUM_ONLY;
  }

  return FixedPremiumType.NO_FIXED_PREMIUM;
};
