import { gql } from 'graphql-tag';

export const MEMBERSHIP_REMUNERATIONS_QUERY = gql`
  query membershipRemunerationsQuery(
    $companyId: ID!
    $userAccountId: ID!
    $employmentId: ID!
    $hasEmploymentId: Boolean!
  ) {
    employment(id: $employmentId) @include(if: $hasEmploymentId) {
      id
      period
    }
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      employment {
        from
        to
        remuneration {
          from
          to
          type {
            id
            name
          }
          value: price
          currency
          unitCode
        }
      }
    }
  }
`;
