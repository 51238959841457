import { bodyMediumCSS } from '@frontend/ui';
import { formMessages } from 'app/messages/form';
import { FormattedMessage } from 'components/formats';
import { EditorState } from 'draft-js';
import React, { useEffect, useRef, useState } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import styled from 'styled-components';

import {
  checkCharacterLimitBeforeInput,
  checkCharacterLimitBeforePaste,
  disableRichTextShortcuts,
  draftToHtml,
  htmlToDraft,
  moveFocusToStart,
} from './utils';

interface StyleWrapperProps {
  focusIndicator: boolean;
  disabled?: boolean;
  readonly?: boolean;
}

const StyleWrapper = styled.div<StyleWrapperProps>`
  position: relative;
  border-radius: 0.25rem;
  outline: 0.125rem solid
    ${p => (p.focusIndicator ? p.theme.primary : 'transparent')};
  border: 0.0625rem solid
    ${p =>
      p.focusIndicator
        ? 'transparent'
        : p.disabled
          ? p.theme.disabled
          : p.theme.outline};
  ${p => p.disabled && `color: ${p.theme.onDisabled};`}
  ${p => p.readonly && 'cursor: not-allowed;'}
  .rdw-editor-wrapper {
    padding: 0 1rem;
    min-height: 5rem;
    ${p => p.readonly && 'pointer-events: none;'}

    .DraftEditor-editorContainer {
      z-index: 0;
      ${p => p.disabled && `color: ${p.theme.onDisabled};`}
    }
  }
`;

interface HelperTextProps {
  disabled?: boolean;
}

const HelperText = styled.div<HelperTextProps>`
  margin-top: 0.5rem;
  color: ${p => p.theme.onSurfaceVariant};
  ${p => p.disabled && `color: ${p.theme.disabled};`}
  ${bodyMediumCSS}
`;

export interface Props extends EditorProps {
  setValue: (value: string) => void;
  value: string;
  characterLimit?: number;
  disabled?: boolean;
  staticText?: boolean;
}

export const SimpleTextEditor: React.FC<Props> = ({
  value,
  setValue,
  characterLimit,
  disabled,
  staticText,
  ...props
}) => {
  const readonly = disabled || staticText;

  const editorRef = useRef<HTMLDivElement>();

  const setRef = (ref: HTMLDivElement) => {
    if (editorRef) {
      editorRef.current = ref;
    }
  };

  const initRenderRef = useRef(true);

  const [editorState, setEditorState] = useState<EditorState>(() =>
    htmlToDraft(value),
  );

  const handleChange = (state: EditorState) => {
    setEditorState(state);
    const currentContent = editorState.getCurrentContent();
    const newContent = state.getCurrentContent();
    // Don't update external value only when focus changes.

    //   This is required because draftToHtml(htmlToDraft(value))
    //   is not strictly equal to value for an externally provided value,
    //   since the editor adds paragraphs and spaces internally.

    //   In practice, this prevents template select dropdown from
    //   losing the preselected option until the text differs from
    //   template option.

    if (currentContent === newContent) {
      return;
    }
    setValue(draftToHtml(state));
  };

  useEffect(() => {
    // Prevent editor focus on initial page render
    if (initRenderRef.current) {
      initRenderRef.current = false;
      return;
    }
    // Only update the editor state if the value has been
    // externally updated.

    // In practice, this allows us focus the editor whenever
    // the user selects a new template from the dropdown.

    const stateHtml = draftToHtml(editorState);
    if (stateHtml === value) {
      return;
    }
    setEditorState(
      readonly ? htmlToDraft(value) : moveFocusToStart(htmlToDraft(value)),
    );
  }, [value]);

  const [focusIndicator, setFocusIndicator] = useState(false);

  useEffect(() => {
    if (readonly && editorRef.current) {
      editorRef.current.blur();
    }
  }, [readonly]);

  return (
    <>
      <StyleWrapper
        disabled={disabled}
        focusIndicator={focusIndicator}
        readonly={readonly}
      >
        <Editor
          editorRef={setRef}
          editorState={editorState}
          // The draft-js prop handleBeforeInput is not included
          // in the react-draft-wysiwyg props type. We'll ignore that,
          // since it works.

          // eslint-disable-next-line
          // @ts-ignore
          handleBeforeInput={() =>
            checkCharacterLimitBeforeInput(editorState, characterLimit)
          }
          handlePastedText={checkCharacterLimitBeforePaste(
            editorState,
            characterLimit,
          )}
          handleKeyCommand={disableRichTextShortcuts}
          onBlur={() => setFocusIndicator(false)}
          onEditorStateChange={handleChange}
          onFocus={() => setFocusIndicator(true)}
          stripPastedStyles
          tabIndex={readonly ? -1 : undefined}
          toolbarHidden
          {...props}
        />
      </StyleWrapper>
      {characterLimit && (
        <HelperText disabled={disabled}>
          <FormattedMessage
            {...formMessages.characterLimit}
            values={{
              nCharacters: editorState.getCurrentContent().getPlainText()
                .length,
              nMaxCharacters: characterLimit,
            }}
          />
        </HelperText>
      )}
    </>
  );
};
