import gql from 'graphql-tag';

export const TOTAL_COMPENSATION_SUMMARY_CARD_QUERY = gql`
  query totalCompensationSummaryCardQuery(
    $companyId: ID!
    $userAccountId: ID!
  ) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      totalCompensation: totalCompensation2 {
        key: id
        category
        includedInCompensation
        annualCompensation
      }
    }
  }
`;
