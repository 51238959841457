import { defineMessages } from 'react-intl';

export const costReportingMessages = defineMessages({
  createDimension: {
    id: 'costReportingMessages.createDimension',
    description: 'Create dimension',
    defaultMessage: 'Skapa dimension',
  },
  editDimension: {
    id: 'costReportingMessages.editDimension',
    description: 'Edit dimension',
    defaultMessage: 'Ändra dimension',
  },
  createDimensionSuccess: {
    id: 'costReportingMessages.createDimensionSuccess',
    description: 'Create dimension success',
    defaultMessage: 'Dimensionen har skapats',
  },
  editDimensionSuccess: {
    id: 'costReportingMessages.editDimensionSuccess',
    description: 'Edit dimension success',
    defaultMessage: 'Dimensionen har ändrats',
  },
  codeFieldLabel: {
    id: 'costReportingMessages.codeFieldLabel',
    description: 'Code field label',
    defaultMessage: 'Kod',
  },
  dimensionAppbarTitle: {
    id: 'costReportingMessages.dimensionAppbarTitle',
    description: 'Dimension app bar title',
    defaultMessage: '{dimensionName} ({dimensionCode})',
  },
  createObject: {
    id: 'costReportingMessages.createObject',
    description: 'Create object',
    defaultMessage: 'Skapa {dimensionName}',
  },
  editObject: {
    id: 'costReportingMessages.editObject',
    description: 'Edit object',
    defaultMessage: 'Ändra {dimensionName}',
  },
  tableCodeColumn: {
    id: 'costReportingMessages.tableCodeColumn',
    description: 'Table code colmun',
    defaultMessage: 'Kod',
  },
  tableNameColumn: {
    id: 'costReportingMessages.tableNameColumn',
    description: 'Table name colmun',
    defaultMessage: 'Namn',
  },
  createObjectSuccess: {
    id: 'costReportingMessages.createObjectSuccess',
    description: 'Create object success',
    defaultMessage: 'Objektet har skapats',
  },
  editObjectSuccess: {
    id: 'costReportingMessages.editObjectSuccess',
    description: 'Edit object success',
    defaultMessage: 'Objektet har ändrats',
  },
  updateEmployeeObjectEmptyStateTitle: {
    id: 'costReportingMessages.updateEmployeeObjectEmptyStateTitle',
    description: 'Update employee object empty state title',
    defaultMessage: 'Skapa {dimensionName} först',
  },
  updateEmployeeObjectEmptyStateDescription: {
    id: 'costReportingMessages.updateEmployeeObjectEmptyStateDescription',
    description: 'Update employee object empty state description',
    defaultMessage:
      'För att ändra {dimensionName} för en anställd måste det finnas objekt i dimensionen.',
  },
  updateEmployeeObjectTitle: {
    id: 'costReportingMessages.updateEmployeeObjectTitle',
    description: 'Update employee object title',
    defaultMessage: 'Ändra {dimensionName} för {firstName} {lastName}',
  },
  updateEmployeeObjectDescription: {
    id: 'costReportingMessages.updateEmployeeObjectDescription',
    description: 'Update employee object description',
    defaultMessage:
      'En ändring gäller kommande fakturor och rapporter enligt från och med-datumet.',
  },
  updateEmployeeObjectDateLabel: {
    id: 'costReportingMessages.updateEmployeeObjectDateLabel',
    description: 'Update employee object date label',
    defaultMessage: 'Från och med',
  },
  updateEmployeeObjectCurrent: {
    id: 'costReportingMessages.updateEmployeeObjectCurrent',
    description: 'Update employee object current',
    defaultMessage: '{label} (nuvarande)',
  },
  updateEmployeeObjectSuccess: {
    id: 'costReportingMessages.updateEmployeeObjectSuccess',
    description: 'Update employee object success',
    defaultMessage: '{dimensionName} har sparats',
  },
});
