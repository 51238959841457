import { Table, Td, Th, Tr } from '@frontend/ui';
import {
  invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection as InvoiceLines,
  pageInfoDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { invoiceLinesMessages } from 'app/messages/invoice-lines';
import { FormattedMessage } from 'components/formats';
import { TableNavigation } from 'components/TableNavigation';
import React from 'react';

import { InvoiceLinesTableRow } from './InvoiceLinesTableRow';

interface Props {
  invoiceLines: InvoiceLines;
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
}

export const InvoiceLinesTable: React.FC<Props> = ({
  invoiceLines,
  pageInfo,
  onNextPage,
  onPreviousPage,
}) => (
  <Table
    navigation={
      <TableNavigation
        pageInfo={pageInfo}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    }
  >
    <colgroup>
      <col style={{ width: '25%' }} />
      <col style={{ width: '25%' }} />
      <col style={{ width: '15%' }} />
      <col style={{ width: '20%' }} />
      <col style={{ width: '15%' }} />
    </colgroup>
    {!invoiceLines.totalCount ? (
      <tbody>
        <Tr>
          <Td>
            <FormattedMessage {...invoiceLinesMessages.noInvoiceLineMatch} />
          </Td>
        </Tr>
      </tbody>
    ) : (
      <>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...invoiceLinesMessages.employee} />
            </Th>
            <Th>
              <FormattedMessage {...invoiceLinesMessages.description} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.supplier} />
            </Th>
            <Th>
              <FormattedMessage {...invoiceLinesMessages.period} />
            </Th>
            <Th type="number">
              <FormattedMessage {...invoiceLinesMessages.amountIncludingVat} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {invoiceLines.edges.map(({ node: line }) => (
            <InvoiceLinesTableRow
              key={`${line.invoice?.id}-${line.id}`}
              invoiceLine={line}
            />
          ))}
        </tbody>
      </>
    )}
  </Table>
);
