import { A, Table, Td, Th, Tr } from '@frontend/ui';
import { description } from '@frontend/ui/icons';
import { employeeSalaryExchangeQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection_edges_FlexSalaryExchangeRequestsHistoryEdge_node_FlexSalaryExchangeRequest as Request } from 'app/apollo/graphql/types';
import { salaryExchangeBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import {
  salaryExchangeAgreementMessages,
  salaryExchangeMessages,
} from 'app/messages/salary-exchange';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
} from 'components/formats';
import React from 'react';

interface Props {
  requests: Request[];
}

export const AgreementsTable: React.FC<Props> = ({ requests }) => (
  <Table>
    <thead>
      <Tr>
        <Th>
          <FormattedMessage {...commonMessages.document} />
        </Th>
        <Th type="number">
          <FormattedMessage
            {...salaryExchangeBenefitMessages.premiumPerMonth}
          />
        </Th>
        <Th type="number">
          <FormattedMessage {...salaryExchangeMessages.grossSalaryDeduction} />
        </Th>
        <Th type="number">
          <FormattedMessage {...commonMessages.registered} />
        </Th>
        <Th type="number" align="right">
          <FormattedMessage {...commonMessages.effectiveDate} />
        </Th>
      </Tr>
    </thead>
    <tbody>
      {requests.map(request => (
        <Tr key={request.id}>
          <Td>
            <A
              target="_blank"
              href={`org/document/salary-exchange/${request.companyId}/${request.id}`}
              icon={description}
            >
              <FormattedMessage
                {...salaryExchangeAgreementMessages.agreementShortTitle}
              />
            </A>
          </Td>
          <Td type="number">
            <FormattedCurrency
              noSuffix
              currency="SEK"
              value={request.premium}
            />
          </Td>
          <Td type="number">
            <FormattedCurrency
              noSuffix
              currency="SEK"
              value={request.deductionValue}
            />
          </Td>
          <Td type="number">
            <FormattedDate value={request.requestedAt} />
          </Td>
          <Td type="number">
            <FormattedDate value={request.effectiveDate} />
          </Td>
        </Tr>
      ))}
    </tbody>
  </Table>
);
