import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

export interface Size {
  height: string;
  width: string;
}
export interface Props extends DropzoneOptions {
  label: React.ReactNode;
  actions?: (open: () => void) => React.ReactNode;
  children?: React.ReactNode;
  complementary?: React.ReactNode;
  size?: Size;
}

export const Dropzone: React.FC<Props> = ({
  children,
  complementary,
  label,
  actions,
  size,
  ...props
}) => {
  const { getRootProps, getInputProps, open } = useDropzone(props);

  const rootProps = size
    ? { style: { maxWidth: size.width, height: size.height } }
    : { style: { maxWidth: '100%' } };

  return (
    <>
      <div {...getRootProps(rootProps)}>
        <input id="dropzone" type="file" {...getInputProps()} />
        <label style={{ display: 'none' }} htmlFor="dropzone">
          {label}
        </label>
        {children}
      </div>
      {complementary}
      {actions?.(open)}
    </>
  );
};
