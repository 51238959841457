import { ApolloError } from '@apollo/client';
import { DisabilityLevel } from '@frontend/benefit-types';
import {
  CheckboxField,
  DatePickerField,
  Form,
  RadioGroupField,
  TextField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  Icon,
  IconButton,
  Section,
  Subsection,
  SubsectionHeader,
  Text,
  Tooltip,
} from '@frontend/ui';
import { infoOutline } from '@frontend/ui/icons';
import { ManagingCompany } from 'app/apollo/graphql/types';
import { a11yMessages } from 'app/messages/a11y';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell33, TextGrid } from 'components/GridCell';
import isAfter from 'date-fns/isAfter';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { BenefitPackagesSection } from '../components/BenefitPackagesSection';
import { ConversionModal } from '../components/ConversionModal';
import {
  FormValues as QualifyingIncomeFormValues,
  QualifyingIncomeSection,
  validationSchema as qualifyingIncomeValidationSchema,
} from '../components/QualifyingIncomeSection';
import { UpcomingChangesNotification } from '../components/UpcomingChangesNotification';
import {
  benefitDisabilityLevelMessages,
  benefitFormMessages,
} from '../messages';
import { Agreements, BenefitPackageOption, RemunerationTypes } from '../types';
import { getEarliestRetroactiveDate } from '../utils';
import { DISABILITY_LEVELS } from '../utils/constants';
import { ConsolidationModal } from './ConsolidationModal';
import { MissingDisabilityAgreementNotification } from './MissingDisabilityAgreementNotification';

export interface FormValues extends QualifyingIncomeFormValues {
  benefitPackageIds: string[];
  converting: boolean;
  convertingFrom: string;
  effectiveFrom: string;
  extendedSickPay: boolean;
  isActiveOnParentalLeave: boolean;
  level: DisabilityLevel | '';
}

interface Props {
  agreements: Agreements;
  benefitPackageOptions: BenefitPackageOption[];
  managedBy: ManagingCompany;
  remunerationTypes: RemunerationTypes;
  isEdit?: boolean;
  latestChangesEffectiveDate?: string | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object()
    .shape({
      convertingFrom: Yup.string().when('converting', {
        is: true,
        then: schema =>
          schema.required(
            intl.formatMessage(validationMessages.mandatoryField),
          ),
      }),
      effectiveFrom: Yup.string()
        .required(intl.formatMessage(validationMessages.mandatoryField))
        .test({
          name: 'valid date',
          message: intl.formatMessage(validationMessages.dateMaxThreeMonthsOld),
          test: (value: string) =>
            isAfter(new Date(value), new Date(getEarliestRetroactiveDate())),
        }),
      level: Yup.string().required(
        intl.formatMessage(validationMessages.mandatoryField),
      ),
    })
    .concat(qualifyingIncomeValidationSchema(intl));

export const DisabilityForm: React.FC<Props> = ({
  agreements,
  benefitPackageOptions,
  isEdit = false,
  latestChangesEffectiveDate,
  managedBy,
  remunerationTypes,
  submissionError,
}) => {
  const { formatMessage } = useIntl();

  const { values, isValid, isSubmitting } = useFormikContext<FormValues>();

  const isManagedByNode = managedBy === 'NODE';

  const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);
  const [isConsolidationModalOpen, setIsConsolidationModalOpen] =
    useState(false);

  const levelIconButton: Partial<Record<DisabilityLevel, React.ReactNode>> = {
    CONSOLIDATION: (
      <IconButton
        onClick={() => setIsConsolidationModalOpen(true)}
        label={formatMessage(a11yMessages.information)}
        icon={infoOutline}
        size="xsmall"
      />
    ),
    HYBRID: (
      <Tooltip
        parent={<Icon icon={infoOutline} size="xsmall" decorative />}
        tooltipContent={
          <FormattedMessage
            {...benefitFormMessages.requiresBusinessCouncilDecision}
          />
        }
      />
    ),
  };

  return (
    <>
      <Form>
        <Section>
          <UpcomingChangesNotification
            latestChangesEffectiveDate={latestChangesEffectiveDate}
          />
          <MissingDisabilityAgreementNotification agreements={agreements} />
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...benefitFormMessages.level} />
            </SubsectionHeader>
            <RadioGroupField
              name="level"
              options={DISABILITY_LEVELS.map(level => ({
                label: (
                  <Text icon={levelIconButton[level]}>
                    {formatMessage({
                      select: level,
                      messages: benefitDisabilityLevelMessages,
                    })}
                  </Text>
                ),
                value: level,
              }))}
              required
            />
          </Subsection>
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage {...benefitFormMessages.extendedSickPay} />
            </SubsectionHeader>
            <CheckboxField
              name="extendedSickPay"
              label={formatMessage(benefitFormMessages.extendedSickPayLabel)}
            />
          </Subsection>
          <Subsection>
            <SubsectionHeader
              iconButton={
                <IconButton
                  onClick={() => setIsConversionModalOpen(true)}
                  label={formatMessage(a11yMessages.information)}
                  icon={infoOutline}
                  size="xsmall"
                />
              }
            >
              <FormattedMessage {...benefitFormMessages.conversion} />
            </SubsectionHeader>
            <CheckboxField
              name="converting"
              label={<FormattedMessage {...benefitFormMessages.convert} />}
            />
            {values.converting && (
              <TextGrid>
                <TextField
                  name="convertingFrom"
                  dense
                  label={
                    <FormattedMessage {...benefitFormMessages.conversionFrom} />
                  }
                  required
                  gridMargin
                />
              </TextGrid>
            )}
          </Subsection>
          <QualifyingIncomeSection remunerationTypes={remunerationTypes} />
          {isManagedByNode && (
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage
                  {...benefitFormMessages.isActiveOnParentalLeave}
                />
              </SubsectionHeader>
              <CheckboxField
                name="isActiveOnParentalLeave"
                label={formatMessage(
                  benefitFormMessages.pensionIsActiveOnParentalLeave,
                )}
              />
            </Subsection>
          )}
          <BenefitPackagesSection
            benefitPackageOptions={benefitPackageOptions}
            name="benefitPackageIds"
          />
          <Subsection>
            <SubsectionHeader>
              <FormattedMessage
                {...(isEdit
                  ? benefitFormMessages.effectiveEditDate
                  : benefitFormMessages.effectiveStartDate)}
              />
            </SubsectionHeader>
            <TextGrid>
              <DescriptionWrapper>
                <FormattedMessage
                  {...(isEdit
                    ? benefitFormMessages.effectiveEditDateDescription
                    : benefitFormMessages.effectiveStartDateDescription)}
                />
              </DescriptionWrapper>
            </TextGrid>
            <Grid>
              <GridCell33>
                <DatePickerField
                  dense
                  gridMargin
                  label={
                    <FormattedMessage
                      {...(isEdit
                        ? benefitFormMessages.effectiveEditDateLabel
                        : benefitFormMessages.effectiveStartDateLabel)}
                    />
                  }
                  min={getEarliestRetroactiveDate()}
                  name="effectiveFrom"
                  required
                  type="month"
                />
              </GridCell33>
            </Grid>
          </Subsection>
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              loading={isSubmitting}
              filled
              type="submit"
              disabled={!isValid}
            >
              <FormattedMessage {...benefitFormMessages.save} />
            </Button>
          </ButtonLayout>
        </Section>
      </Form>
      <ConversionModal
        isOpen={isConversionModalOpen}
        setIsOpen={setIsConversionModalOpen}
      />
      <ConsolidationModal
        isOpen={isConsolidationModalOpen}
        setIsOpen={setIsConsolidationModalOpen}
      />
    </>
  );
};
