import {
  APP_BAR_HEIGHT,
  Breadcrumbs,
  ChipSet as _ChipSet,
  Icon,
  IconContent,
  PageBody,
  PageHeader,
  PageTitle,
  PageTitleBar,
  StatusLabel,
  TitleSmall,
  useComponentSize,
} from '@frontend/ui';
import { a11yMessages } from 'app/messages/a11y';
import { useHideOnScroll } from 'app/utils/use-hide-on-scroll';
import { AssistChip, Props as AssistChipProps } from 'components/AssistChip';
import { useIntl } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { NavLink } from 'components/Links/NavLink';
import { MenuItem } from 'components/MenuItem';
import { useNavigationContext } from 'components/NavigationDrawer/containers/NavigationContext';
import { useAppBarNavigationStack } from 'components/NavigationDrawer/lib/use-app-bar-navigation-stack';
import { RoutedTab, RoutedTabBar } from 'components/RoutedTabBar';
import { useNavigationAnchorContext } from 'contexts/navigation-anchor';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface ActionHolderProps {
  hide?: boolean;
}

const ChipSet = styled(_ChipSet)<ActionHolderProps>`
  padding-left: 2rem;
  flex-wrap: nowrap;

  // In order to hide the chip set when it's too wide to fit in the title bar
  // we set the visibility to hidden and the position to absolute instead of display: none.
  // The reason for this is that we must persist the width of the chip set to not re-trigger
  // calulations which would cause the chip set to flicker.
  ${p =>
    p.hide &&
    `
      visibility: hidden;
      position: absolute;
  `}
`;

const KebabMenuHolder = styled.div<ActionHolderProps>`
  ${p => p.hide && `display: none;`}
`;

interface Status {
  icon: IconContent;
  title: React.ReactNode;
}

interface Props {
  children: React.ReactNode;
  actions?: AssistChipProps[];
  fixed?: boolean;
  parentLink?: string;
  status?: Status;
  subtitle?: React.ReactNode;
  tabs?: RoutedTab[];
  title?: React.ReactNode;
  trackPageOnUnmount?: boolean;
}

export const Page: React.FC<Props> = ({
  children,
  title,
  subtitle,
  tabs,
  parentLink,
  actions,
  status,
  trackPageOnUnmount = true,
  fixed,
}) => {
  const { formatMessage } = useIntl();
  const { setPage } = useNavigationContext();

  useEffect(() => {
    setPage({ parentLink });
  }, [parentLink]);

  useAppBarNavigationStack({
    trackPageOnUnmount,
  });
  const { stack } = useNavigationAnchorContext();

  const hideHeader = useHideOnScroll({ offsetValue: APP_BAR_HEIGHT }) && !fixed;

  const [showChipSet, setshowChipSet] = useState(false);
  const titleBarRef = useRef(null);
  const { width: titleBarWidth } = useComponentSize(titleBarRef);
  const titleRef = useRef(null);
  const { width: titleWidth } = useComponentSize(titleRef);
  const chipSetRef = useRef(null);
  const { width: chipSetWidth } = useComponentSize(chipSetRef);
  useEffect(() => {
    setshowChipSet(chipSetWidth + titleWidth < titleBarWidth);
  }, [titleBarWidth, titleWidth, chipSetWidth]);

  const showPageHeader = !!(title || tabs || actions?.length);

  const stackItems = Object.values(stack);
  const breadcrumbs = stackItems
    .sort((a, b) => a.url.split('/').length - b.url.split('/').length)
    .map(({ title: breadcrumbTitle, url }, index) => {
      if (index === stackItems.length - 1) {
        return breadcrumbTitle;
      }
      return (
        <NavLink to={url} key={url}>
          {breadcrumbTitle}
        </NavLink>
      );
    });

  return (
    <>
      {showPageHeader && (
        <PageHeader hide={hideHeader}>
          {breadcrumbs.length > 1 && (
            <Breadcrumbs
              ariaLabel={formatMessage(a11yMessages.breadcrumb)}
              buttonLabel={formatMessage(a11yMessages.showBreadcrumbs)}
              breadcrumbs={breadcrumbs}
            />
          )}
          <PageTitleBar ref={titleBarRef}>
            <PageTitle
              ref={titleRef}
              status={
                status && (
                  <StatusLabel title={status.title} icon={status.icon} />
                )
              }
            >
              {title}
            </PageTitle>
            {!!actions?.length && (
              <>
                <ChipSet ref={chipSetRef} hide={!showChipSet}>
                  {actions.map((action, i) => (
                    <AssistChip key={i} {...action} />
                  ))}
                </ChipSet>
                <KebabMenuHolder hide={showChipSet}>
                  <KebabMenu label={a11yMessages.profileButton}>
                    {actions.map((action, i) => (
                      <MenuItem
                        key={i}
                        title={action.text}
                        icon={
                          <Icon
                            icon={action.leadingIcon}
                            size="small"
                            decorative
                          />
                        }
                        onClick={action.onClick}
                        link={action.to}
                      />
                    ))}
                  </KebabMenu>
                </KebabMenuHolder>
              </>
            )}
          </PageTitleBar>
          {!!subtitle && <TitleSmall>{subtitle}</TitleSmall>}
          {!!tabs && <RoutedTabBar tabs={tabs} />}
        </PageHeader>
      )}
      <PageBody>{children}</PageBody>
    </>
  );
};
