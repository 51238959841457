import { CheckboxField, SelectField } from '@frontend/ui';
import { alphabeticallyAscending } from '@frontend/utils';
import {
  noteAuthorsQuery_noteAuthors_Author as NoteAuthor,
  NoteCategory,
} from 'app/apollo/graphql/types';
import { noteCategoryMessages, noteMessages } from 'app/messages/notes';
import { FormattedMessage, useIntl } from 'components/formats';
import { FilterSection } from 'features/filter-sheet/components/FilterSection';
import { SelectionFilter } from 'features/filter-sheet/components/SelectionFilter';
import { useDebouncedFilters } from 'features/filter-sheet/utils/use-debounced-filters';
import React from 'react';

interface Props {
  noteAuthors: Array<NoteAuthor>;
}

interface Filters {
  author?: string;
  categories?: Array<string>;
}

export const Filters: React.FC<Props> = props => {
  const { clearFilters, filters, setFilterValue } =
    useDebouncedFilters<Filters>();

  const { formatMessage } = useIntl();

  const categories = filters.categories
    ? Array.isArray(filters.categories)
      ? filters.categories
      : [filters.categories]
    : [];

  const categoryOptions = Object.values(NoteCategory)
    .map(category => ({
      id: category,
      label: formatMessage({
        select: category,
        messages: noteCategoryMessages,
      }),
      selected: categories.includes(category),
    }))
    .sort((a, b) => alphabeticallyAscending(a.label, b.label));

  const noteAuthors = props.noteAuthors
    .filter(author => author.adminUserExternalId && author.name)
    .map(author => ({
      label: author.name!,
      value: author.adminUserExternalId!,
    }));

  const selectedFilters = Object.keys(filters);

  return (
    <SelectionFilter
      nSelected={selectedFilters.length}
      clearFilters={clearFilters}
    >
      <FilterSection header={<FormattedMessage {...noteMessages.category} />}>
        {categoryOptions.map(({ selected, id, label }) => (
          <div key={id}>
            <CheckboxField
              checked={selected}
              label={label}
              onChange={() => {
                const value = categories.includes(id)
                  ? categories.filter(_id => id !== _id)
                  : [id, ...categories];

                setFilterValue(value, 'categories');
              }}
            />
          </div>
        ))}
      </FilterSection>
      <FilterSection header={<FormattedMessage {...noteMessages.createdBy} />}>
        <SelectField
          dense
          label={<FormattedMessage {...noteMessages.name} />}
          options={noteAuthors}
          value={filters.author ?? ''}
          onChange={event => setFilterValue(event.detail.value, 'author')}
        />
      </FilterSection>
    </SelectionFilter>
  );
};
