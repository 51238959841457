import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const BONUS_SALARY_EXCHANGES_TABLE_QUERY = gql`
  query bonusSalaryExchangesTableQuery(
    $companyId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $statuses: [FlexSalaryExchangeRequestStatus!]
  ) {
    bonusSalaryExchangeRequestsHistory(
      companyId: $companyId
      first: $first
      last: $last
      before: $before
      after: $after
      statuses: $statuses
    ) {
      edges {
        node {
          id
          effectiveDate
          deductionValue
          premium
          provider
          membership {
            id
            givenName
            lastName
          }
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${pageInfoDetails}
`;
