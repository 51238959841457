import { ApolloError, useMutation } from '@apollo/client';
import {
  DeleteCompanyRepresentativeInput,
  deleteProposalEmployeeMutation,
  deleteProposalEmployeeMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeEmployeesMessages } from 'features/sme/messages/sme';

import { clearProposalCache } from '../../../../utils/clear-proposal-cache';
import { DELETE_PROPOSAL_EMPLOYEE_MUTATION } from './graphql/mutations';

interface Submit {
  _delete: (input: DeleteCompanyRepresentativeInput) => Promise<void>;
  deleteLoading: boolean;
  deleteError?: ApolloError;
}

interface Params {
  params: MatchParams;
}

export const useDelete = ({ params }: Params): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [
    deleteProposalEmployee,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation<
    deleteProposalEmployeeMutation,
    deleteProposalEmployeeMutationVariables
  >(DELETE_PROPOSAL_EMPLOYEE_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals', 'validity'],
    }),
  });

  const _delete = async (input: DeleteCompanyRepresentativeInput) => {
    try {
      const res = await deleteProposalEmployee({
        variables: {
          input,
        },
      });
      const proposalId = res.data?.deleteProposalMembership?.id;
      if (!proposalId) {
        return;
      }
      send({
        message: formatMessage(smeEmployeesMessages.employeeDeleted),
        type: 'success',
      });
    } catch {
      // Do nothing
    }
  };

  return {
    _delete,
    deleteLoading,
    deleteError,
  };
};
