import { defineMessages } from 'react-intl';

export const accountPlanMessages = defineMessages({
  monthly: {
    id: 'accountPlanMessages.monthly',
    defaultMessage: 'Per månad',
  },
  annually: {
    id: 'accountPlanMessages.annually',
    defaultMessage: 'Per år',
  },
  lumpSum: {
    id: 'accountPlanMessages.lumpSum',
    defaultMessage: 'Engångsbelopp',
  },
  payrollElement: {
    id: 'accountPlanMessages.payrollElement',
    description: 'Payroll element',
    defaultMessage: 'Löneart',
  },
  dateSpan: {
    id: 'accountPlanMessages.dateSpan',
    description: 'Date span',
    defaultMessage: 'Datering',
  },
  dateLowerBound: {
    id: 'accountPlanMessages.dateLowerBound',
    description: 'Date lower bound',
    defaultMessage: '{lowerBound, date, ::yyyyMMdd} - ',
  },
  dateUpperBound: {
    id: 'accountPlanMessages.dateUpperBound',
    description: 'Date upper bound',
    defaultMessage: '{upperBound, date, ::yyyyMMdd}',
  },
  editRemunerationTitle: {
    id: 'accountPlanMessages.editRemunerationTitle',
    description: 'Edit remuneration title',
    defaultMessage: 'Ny lön eller ersättning',
  },
  editRemunerationDesc: {
    id: 'accountPlanMessages.editRemunerationDesc',
    description: 'Edit remuneration description',
    defaultMessage:
      'Lön och andra ersättningar anges månadsvis. En ny lön eller ersättning gäller från och med den första dagen i månaden.',
  },
  latestChangeLabel: {
    id: 'accountPlanMessages.latestChangeLabel',
    description: 'Latest change label',
    defaultMessage: 'Senaste ändring',
  },
  latestChangeDesc: {
    id: 'accountPlanMessages.latestChangeDesc',
    description: 'Latest change description',
    defaultMessage: '{value} från och med {effectiveDate, date, ::yMMMM }',
  },
  editRemunerationSuccess: {
    id: 'accountPlanMessages.editRemunerationSuccess',
    description: 'Edit remuneration success',
    defaultMessage: 'Ändringarna har sparats',
  },
  newRemuneration: {
    id: 'accountPlanMessages.newRemuneration',
    defaultMessage: 'Ny {remunerationName}',
  },
});
