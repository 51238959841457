import { Button } from '@frontend/ui';
import { employeesImportMessages } from 'app/messages/employees';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import React from 'react';

interface Props {
  action: () => void;
}

export const ImportEmptyState: React.FC<Props> = ({ action }) => (
  <EmptyState
    title={<FormattedMessage {...employeesImportMessages.emptyStateTitle} />}
    actions={
      <Button onClick={action}>
        <FormattedMessage {...employeesImportMessages.emptyStateButtonText} />
      </Button>
    }
  >
    <span style={{ whiteSpace: 'pre-wrap' }}>
      <FormattedMessage {...employeesImportMessages.emptyStateDescription} />
    </span>
  </EmptyState>
);
