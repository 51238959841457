import { PremiumMatrixFormValues } from '../../types';

export const customPremiumMatrixInitialValues: PremiumMatrixFormValues = {
  age: [0, 25, 66],
  baseAmount: ['0', '7.5', '30'],
  baseAmountType: 'IBB',
  intervals: [
    ['0', '0', '0'],
    ['0', '0', '0'],
    ['0', '0', '0'],
  ],
};

export enum PremiumIntervalsValidationError {
  AGES_ASCENDING = 'AGES_ASCENDING',
  BASE_AMOUNTS_ASCENDING = 'BASE_AMOUNTS_ASCENDING',
  BASE_AMOUNT_NOT_OVER_100 = 'BASE_AMOUNT_NOT_OVER_100',
  PREMIUMS_NOT_OVER_100 = 'PREMIUMS_NOT_OVER_100',
}
