export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z',
      },
    },
  ],
};
