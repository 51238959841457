import { ContentContainer } from '@frontend/ui';
import {
  FlexSalaryExchangeStatus,
  terminateSalaryExchangesTableQuery,
  terminateSalaryExchangesTableQueryVariables,
} from 'app/apollo/graphql/types';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { companySalaryExchangeMessages } from 'features/companies/company/salary-exchanges/messages';
import { TerminateSalaryExchangesTable } from 'features/companies/company/salary-exchanges/terminate/Table';
import { Page } from 'features/page';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router';

import { SalaryExchangeRouteMatchParams } from '..';
import { TERMINATE_SALARY_EXCHANGES_TABLE_QUERY } from './graphql/queries';

export const Terminate: React.FC<RouteComponentProps> = props => {
  const { formatMessage } = useIntl();
  const { location } = props;
  const { userAccountIds } = qs.parse(location.search);
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data, loading, error } = useQuery<
    terminateSalaryExchangesTableQuery,
    terminateSalaryExchangesTableQueryVariables
  >(TERMINATE_SALARY_EXCHANGES_TABLE_QUERY, {
    variables: {
      companyId,
      statuses: [FlexSalaryExchangeStatus.ACTIVE],
    },
  });

  if (loading) {
    return <TopLoading />;
  }

  const parentLink = `/companies/${companyId}/salary-exchanges`;

  const salaryExchanges =
    data?.salaryExchanges.edges.map(edge => edge.node) ?? [];

  const _salaryExchanges = salaryExchanges.filter(sx =>
    userAccountIds.includes(sx.userAccountId),
  );

  if (!_salaryExchanges?.length || !userAccountIds) {
    return (
      <NavigationAnchor
        title={formatMessage(companySalaryExchangeMessages.stopSalaryExchange, {
          count: undefined,
        })}
        url={location.pathname}
      >
        <Page
          parentLink={parentLink}
          title={
            <FormattedMessage
              {...companySalaryExchangeMessages.stopSalaryExchange}
              values={{ count: undefined }} // Pass undefined to fallback to plural
            />
          }
        >
          <ContentContainer>
            {error && <GraphQlError error={error} />}
            <EmptyState
              title={
                <FormattedMessage
                  {...companySalaryExchangeMessages.noSalaryExchangeRequestItems}
                />
              }
            >
              <FormattedMessage
                {...companySalaryExchangeMessages.noSalaryExchangeRequestItemsText}
              />
            </EmptyState>
          </ContentContainer>
        </Page>
      </NavigationAnchor>
    );
  }

  return (
    <NavigationAnchor
      title={formatMessage(companySalaryExchangeMessages.stopSalaryExchange, {
        count: _salaryExchanges.length,
      })}
      url={location.pathname}
    >
      <Page
        parentLink={parentLink}
        title={
          <FormattedMessage
            {...companySalaryExchangeMessages.stopSalaryExchange}
            values={{ count: _salaryExchanges.length }}
          />
        }
      >
        <ContentContainer>
          {error && <GraphQlError error={error} />}
          <TerminateSalaryExchangesTable
            salaryExchanges={_salaryExchanges}
            {...props}
          />
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
