import { InvoiceStatus } from 'app/apollo/graphql/types';
import { defineMessages, MessageDescriptor } from 'react-intl';

export const invoiceMessages = defineMessages({
  invoices: {
    id: 'menuMessages.invoices',
    defaultMessage: 'Fakturor',
  },
  invoiceLines: {
    id: 'menuMessages.invoiceLines',
    defaultMessage: 'Fakturarader',
  },
  supplier: {
    id: 'invoiceMessages.supplier',
    description: 'Supplier',
    defaultMessage: 'Leverantör',
  },
  invoiceNumber: {
    id: 'invoiceMessages.invoiceNumber',
    description: 'Invoice number',
    defaultMessage: 'Fakturanummer',
  },
  invoiceDate: {
    id: 'invoiceMessages.invoiceDate',
    description: 'Invoice date',
    defaultMessage: 'Fakturadatum',
  },
  dueDate: {
    id: 'invoiceMessages.dueDate',
    description: 'Due date',
    defaultMessage: 'Förfallodag',
  },
  downloadSie4: {
    id: 'invoiceMessages.downloadSie4',
    description: 'Download as SIE4',
    defaultMessage: 'Ladda ned som SIE4',
  },
  downloadPeppol: {
    id: 'invoiceMessages.downloadPeppol',
    description: 'Download as PEPPOL',
    defaultMessage: 'Ladda ned som PEPPOL',
  },
  downloadPdf: {
    id: 'invoiceMessages.downloadPdf',
    description: 'Download as PDF',
    defaultMessage: 'Ladda ned som PDF',
  },
  downloadExcel: {
    id: 'invoiceMessages.downloadExcel',
    description: 'Download as Excel',
    defaultMessage: 'Ladda ned som Excel',
  },
  downloadCsv: {
    id: 'invoiceMessages.downloadCsv',
    description: 'Download as CSV',
    defaultMessage: 'Ladda ned som CSV',
  },
  noInvoices: {
    id: 'invoiceMessages.enptyStateText',
    description: 'There are no invoices',
    defaultMessage: 'Det finns inga fakturor att visa',
  },
  statusIgnored: {
    id: 'invoiceMessages.statusIgnored',
    description: 'Ignored',
    defaultMessage: 'Ignorerad',
  },
  statusPaid: {
    id: 'invoiceMessages.statusPaid',
    description: 'Paid',
    defaultMessage: 'Betald',
  },
  statusNotPaid: {
    id: 'invoiceMessages.statusNotPaid',
    description: 'Not Paid',
    defaultMessage: 'Obetald',
  },
  statusError: {
    id: 'invoiceMessages.statusError',
    description: 'Could not update status of invoice',
    defaultMessage: 'Statusen kunde inte ändras',
  },
  noInvoiceMatch: {
    id: 'invoiceMessages.noInvoiceMatch',
    defaultMessage: 'Inga fakturor matchar sökningen',
  },
  selectSupplier: {
    id: 'invoiceMessages.selectSupplier',
    defaultMessage: 'Välj leverantör',
  },
  selectInvoiceDate: {
    id: 'invoiceMessages.selectInvoiceDate',
    defaultMessage: 'Välj fakturadatum',
  },
  selectStatus: {
    id: 'invoiceMessages.selectStatus',
    defaultMessage: 'Välj status',
  },
  searchInvoiceNumber: {
    id: 'invoiceMessages.searchInvoiceNumber',
    defaultMessage: 'Sök fakturanummer',
  },
  selectAmount: {
    id: 'invoiceMessages.selectAmount',
    defaultMessage: 'Välj belopp',
  },
  selectDueDate: {
    id: 'invoiceMessages.selectDueDate',
    defaultMessage: 'Välj förfallodag',
  },
});

export const invoiceStatusMessages: Record<InvoiceStatus, MessageDescriptor> = {
  [InvoiceStatus.PAID]: invoiceMessages.statusPaid,
  [InvoiceStatus.NOT_PAID]: invoiceMessages.statusNotPaid,
  [InvoiceStatus.IGNORED]: invoiceMessages.statusIgnored,
};
