import { companyNotificationsQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: companyNotificationsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: companyNotificationsQuery;
  },
) => {
  if (
    !previousResult ||
    !previousResult.notifications ||
    !fetchMoreResult ||
    !fetchMoreResult.notifications
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.notifications;

  return {
    notifications: {
      __typename: previousResult.notifications.__typename,
      pageInfo,
      totalCount: fetchMoreResult.notifications.totalCount,
      edges: [...previousResult.notifications.edges, ...edges],
    },
  };
};
