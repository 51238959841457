import gql from 'graphql-tag';

export const UPDATE_BENEFIT_PACKAGE_SETTINGS_MUTATION = gql`
  mutation updateBenefitPackageSettingsMutation(
    $input: UpdateBenefitPackageInput!
  ) {
    updateBenefitPackage(input: $input) {
      benefitPackage {
        id
        hasBenefitPortal
        showTotalCompensation
      }
    }
  }
`;
