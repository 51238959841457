import gql from 'graphql-tag';

export const editJobOfferDetails = gql`
  fragment editJobOfferDetails on JobOffer {
    id
    title
    body
    prospectName
    yearlyVacationDays
    collectiveAgreement
    monthlyLunchAllowance
    yearlyWellnessAllowance
    monthlyCompanyCarValue
    hidden
    validUntil
    status
    companyLogo
    workLocation
    workSetting
    showDiscount
    optionsProgram
    birthdate
    benefitPackageId
    remunerations {
      idCode
      value
    }
    attachmentFiles {
      id
      url
      filename
    }
    optionsProgrammeFiles {
      id
      url
      filename
    }
    coverPictureFile {
      id
      url
      filename
    }
    profilePictureFile {
      id
      url
      filename
    }
  }
`;
