import { ApolloError, useMutation } from '@apollo/client';
import {
  createNoteMutation,
  createNoteMutationVariables,
} from 'app/apollo/graphql/types';
import { noteMessages } from 'app/messages/notes';
import { NoteMatchParams } from 'app/pages/companies/company/notes';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { NoteFormValues } from '../components/NoteForm';
import { CREATE_NOTE_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: NoteFormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (): Submit => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<NoteMatchParams>();
  const { push } = useHistory();

  const [createNote, { error }] = useMutation<
    createNoteMutation,
    createNoteMutationVariables
  >(CREATE_NOTE_MUTATION, {
    update: cache => {
      cache.evict({ fieldName: 'notes' });
      cache.gc();
    },
  });

  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const submit = async (values: NoteFormValues) => {
    try {
      const { subject, category, content, attachments } = values;

      const result = await createNote({
        variables: {
          input: {
            companyId: !userAccountId ? companyId : undefined,
            userAccountId,
            subject: subject!,
            category: category!,
            content,
            fileIds: attachments?.map(({ id }) => id),
          },
        },
      });

      send({
        message: `${formatMessage(noteMessages.saveNoteSuccess)}`,
        type: 'success',
      });
      push(`${result.data?.createNote?.id}`);
    } catch {
      // Do nothing
    }
  };

  return { submit, error };
};
