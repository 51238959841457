import {
  employeesWithTransferableCapitalQuery,
  employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital as EmployeeWithTransferableCapital,
  employeesWithTransferableCapitalQuery_employeesWithTransferableCapital_EmployeeWithTransferableCapitalConnection_edges_EmployeeWithTransferableCapitalEdge_node_EmployeeWithTransferableCapital_memberships_MembershipConnection_edges_MembershipEdge_node_Membership as Membership,
  employeesWithTransferableCapitalQueryVariables,
  MembershipKey,
  SortTransferableCapitalBy,
} from 'app/apollo/graphql/types';
import { OpenAddHeldMeetingModal } from 'app/pages/advisor';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';

import { EMPLOYEES_WITH_TRANSFERABLE_CAPITAL } from '../graphql/queries';
import { FilterParams } from '../utils/use-filter-params';
import { updateEmployeesWithTransferableCapitalQuery } from './lib/update-employees-with-transferable-capital-query';
import { PensionTransferEmployeesTable } from './table';
import { usePensionTransferTableSort } from './table/use-table-sort';

export type PensionTransferEmployee = EmployeeWithTransferableCapital & {
  membership: Membership;
  companyId: string;
};

interface Props {
  clearFilter: () => void;
  filterParams: FilterParams;
  openAddHeldMeetingModal: OpenAddHeldMeetingModal;
  openSendInvitationsModal: (membershipKeys: Array<MembershipKey>) => void;
  toggleFilterSideSheet: () => void;
}

export const PensionTransferEmployees: React.FC<Props> = ({
  openAddHeldMeetingModal,
  openSendInvitationsModal,
  toggleFilterSideSheet,
  clearFilter,
  filterParams,
}) => {
  const location = useLocation();

  const { search, per_page } = qs.parse(location.search);

  const { handleSort, order, orderBy } =
    usePensionTransferTableSort<SortTransferableCapitalBy>();

  const { data, loading, previousData, error, fetchMore } = useQuery<
    employeesWithTransferableCapitalQuery,
    employeesWithTransferableCapitalQueryVariables
  >(EMPLOYEES_WITH_TRANSFERABLE_CAPITAL, {
    variables: {
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      search: search || undefined,
      minCapital: filterParams.minCapital
        ? Number(filterParams.minCapital)
        : undefined,
      maxCapital: filterParams.maxCapital
        ? Number(filterParams.maxCapital)
        : undefined,
      brokeredByNode:
        filterParams.brokeredByNode === 'true'
          ? true
          : filterParams.brokeredByNode === 'false'
            ? false
            : undefined,
      companyId: filterParams.companyId || undefined,
      benefitPackageIds:
        filterParams.benefitPackageIds.length > 0
          ? filterParams.benefitPackageIds
          : undefined,
      sortOrder: order,
      sortBy: orderBy,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;
  const employees =
    _data?.employeesWithTransferableCapital?.edges
      .map(e => {
        const membership = e.node.memberships?.edges?.reduce(
          (newestMembership, m) =>
            (m.node.employments?.edges[0]?.node.period[0] ?? '1900-01-01') >
            (newestMembership?.employments?.edges[0]?.node.period[0] ??
              '1900-01-01')
              ? m.node
              : newestMembership,
          null as Membership | null,
        );

        return {
          ...e.node,
          companyId: membership?.company?.id,
          membership,
        };
      })
      .filter(
        (node): node is PensionTransferEmployee =>
          node.membership != null && node.companyId != null,
      ) ?? [];
  const pageInfo = _data?.employeesWithTransferableCapital.pageInfo ?? {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  };

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <PensionTransferEmployeesTable
        pageInfo={pageInfo}
        onNextPage={() => {
          fetchMore({
            variables: {
              after: pageInfo.endCursor,
            },
            updateQuery: updateEmployeesWithTransferableCapitalQuery,
          });
        }}
        onPreviousPage={() => {
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
              first: undefined,
              last: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
            },
            updateQuery: updateEmployeesWithTransferableCapitalQuery,
          });
        }}
        employees={employees}
        openAddHeldMeetingModal={openAddHeldMeetingModal}
        toggleFilterSideSheet={toggleFilterSideSheet}
        clearFilter={clearFilter}
        openSendInvitationsModal={openSendInvitationsModal}
        sort={{ order, orderBy, handleSort }}
      />
    </>
  );
};
