export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M16.01 11H4v2h12.01v3L20 12l-3.99-4z',
      },
    },
  ],
};
