import gql from 'graphql-tag';

export const CREATE_REPRESENTATIVE_MUTATION = gql`
  mutation createRepresentativeMutation(
    $input: CreateCompanyRepresentativeInput!
  ) {
    createCompanyRepresentative(input: $input) {
      id
      representatives {
        id
      }
    }
  }
`;
