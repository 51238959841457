interface ChangeConfig {
  __typename?: string;
}

interface RetirementAgeChangeConfig {
  __typename: 'RetirementAgeChangeConfig';
}

interface OccupationalPensionChangeConfig {
  __typename: 'OccupationalPensionChangeConfig';
}

interface RiskAccidentChangeConfig {
  __typename: 'RiskAccidentChangeConfig';
}

interface RiskSicknessAndAccidentChangeConfig {
  __typename: 'RiskSicknessAndAccidentChangeConfig';
}

interface RiskHealthcareChangeConfig {
  __typename: 'RiskHealthcareChangeConfig';
}

interface RiskLifeChangeConfig {
  __typename: 'RiskLifeChangeConfig';
}

interface RiskPremiumWaiverChangeConfig {
  __typename: 'RiskPremiumWaiverChangeConfig';
}

interface RiskSicknessChangeConfig {
  __typename: 'RiskSicknessChangeConfig';
}

export const isOccupationalPensionChange = (
  config: ChangeConfig,
): config is OccupationalPensionChangeConfig =>
  config.__typename === 'OccupationalPensionChangeConfig';

export const isRetirementAgeChangeConfig = (
  config: ChangeConfig,
): config is RetirementAgeChangeConfig =>
  config.__typename === 'RetirementAgeChangeConfig';

export const isRiskAccidentChangeConfig = (
  config: ChangeConfig,
): config is RiskAccidentChangeConfig =>
  config.__typename === 'RiskAccidentChangeConfig';

export const isRiskSicknessAndAccidentChangeConfig = (
  config: ChangeConfig,
): config is RiskSicknessAndAccidentChangeConfig =>
  config.__typename === 'RiskSicknessAndAccidentChangeConfig';

export const isRiskHealthcareChangeConfig = (
  config: ChangeConfig,
): config is RiskHealthcareChangeConfig =>
  config.__typename === 'RiskHealthcareChangeConfig';

export const isRiskLifeChangeConfig = (
  config: ChangeConfig,
): config is RiskLifeChangeConfig =>
  config.__typename === 'RiskLifeChangeConfig';

export const isRiskPremiumWaiverChangeConfig = (
  config: ChangeConfig,
): config is RiskPremiumWaiverChangeConfig =>
  config.__typename === 'RiskPremiumWaiverChangeConfig';

export const isRiskSicknessChangeConfig = (
  config: ChangeConfig,
): config is RiskSicknessChangeConfig =>
  config.__typename === 'RiskSicknessChangeConfig';
