import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const JOB_OFFERS_OVERVIEW_QUERY = gql`
  query jobOffersOverviewQuery(
    $companyId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    company(id: $companyId) {
      id
      jobOffers(first: $first, after: $after, last: $last, before: $before) {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            id
            prospectName
            remunerations {
              idCode
              value
            }
            status
            updatedAt
          }
        }
      }
    }
  }
  ${pageInfoDetails}
`;
