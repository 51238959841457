import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { MembershipBenefitPackage } from 'features/companies/company/employees/employee/benefit-package';
import { MembershipDetails } from 'features/companies/company/employees/employee/details';
import { EmployeeOverviewNotifications } from 'features/companies/company/employees/employee/notifications';
import { MembershipOrganization } from 'features/companies/company/employees/employee/organization';
import { MembershipRemunerations } from 'features/companies/company/employees/employee/remunerations';
import { MembershipRoles } from 'features/companies/company/employees/employee/roles';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const EmployeeOverview: React.FC = () => (
  <Suspense batch fallback={<TopLoading />}>
    {({ errors }) => (
      <>
        {errors && <GraphQlErrors errors={errors} />}
        <EmployeeOverviewNotifications />
        <MembershipDetails />
        <MembershipRoles />
        <MembershipRemunerations />
        <MembershipOrganization />
        <MembershipBenefitPackage />
      </>
    )}
  </Suspense>
);
