import { RadioGroupOption } from '@frontend/ui';
import { bpsToDecimalFraction, toNumber } from '@frontend/utils';
import {
  RiskBucket,
  updateProposalQuery_Query as Data,
} from 'app/apollo/graphql/types';
import { IntlShape } from 'components/formats';
import { smeFeesMessages } from 'features/sme/messages/sme';

import { OnChange } from '../../../edit/contexts/autosave';

interface FormValues {
  capitalFeeFund: string;
  capitalFeePortfolio: string;
  capitalFeeTraditional: string;
  fundDiscountPermitted: boolean;
  provisionWaiverAllowed: boolean;
  provisionWaiverHealthcare: string;
  provisionWaiverPremiumWaiver: string;
  provisionWaiverSickness: string;
  riskBucket: string;
  annualFeeFund?: string;
  annualFeePortfolio?: string;
  annualFeeTraditional?: string;
  fundDiscountValue?: string;
}

export const formatRiskBucketInput = (value: string): RiskBucket | null => {
  if (value === '') {
    return null;
  }
  const riskBucketValue = Object.values(RiskBucket).find(v => v === value);
  return riskBucketValue ?? null;
};

export const getFormValues = (data?: Data): FormValues => ({
  annualFeeFund:
    data?.proposal?.fees?.annualFeeFund != null
      ? data.proposal.fees?.annualFeeFund.toString()
      : undefined,
  annualFeePortfolio:
    data?.proposal?.fees?.annualFeePortfolio != null
      ? data.proposal.fees?.annualFeePortfolio.toString()
      : undefined,
  annualFeeTraditional:
    data?.proposal?.fees?.annualFeeTraditional != null
      ? data.proposal.fees?.annualFeeTraditional.toString()
      : undefined,
  capitalFeeFund:
    data?.proposal?.fees?.capitalFeeFund != null
      ? data.proposal.fees?.capitalFeeFund.toString()
      : '',
  capitalFeePortfolio:
    data?.proposal?.fees?.capitalFeePortfolio != null
      ? data.proposal.fees?.capitalFeePortfolio.toString()
      : '',
  capitalFeeTraditional:
    data?.proposal?.fees?.capitalFeeTraditional != null
      ? data.proposal.fees?.capitalFeeTraditional.toString()
      : '',
  fundDiscountPermitted: data?.proposal?.fees?.fundDiscountPermitted ?? false,
  fundDiscountValue:
    data?.proposal?.fees?.fundDiscountValue != null
      ? data.proposal.fees?.fundDiscountValue.toString()
      : undefined,
  provisionWaiverAllowed: data?.proposal?.fees?.provisionWaiverAllowed ?? false,
  provisionWaiverHealthcare:
    data?.proposal?.fees?.provisionWaiverHealthcare != null
      ? data.proposal.fees?.provisionWaiverHealthcare.toString()
      : '',
  provisionWaiverPremiumWaiver:
    data?.proposal?.fees?.provisionWaiverPremiumWaiver != null
      ? data.proposal.fees?.provisionWaiverPremiumWaiver.toString()
      : '',
  provisionWaiverSickness:
    data?.proposal?.fees?.provisionWaiverSickness != null
      ? data.proposal.fees?.provisionWaiverSickness.toString()
      : '',
  riskBucket: data?.proposal?.fees?.riskBucket ?? '',
});

export const getOnChangeHandlers = (onChange: OnChange) => ({
  annualFeeFund: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          annualFeeFund: option.value === 'true',
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            annualFeeFund: option.value === 'true',
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  annualFeePortfolio: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          annualFeePortfolio: option.value === 'true',
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            annualFeePortfolio: option.value === 'true',
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  annualFeeTraditional: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          annualFeeTraditional: option.value === 'true',
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            annualFeeTraditional: option.value === 'true',
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  capitalFeeFund: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          capitalFeeFund: toNumber(value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            capitalFeeFund: toNumber(value) ?? null,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  capitalFeePortfolio: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          capitalFeePortfolio: toNumber(value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            capitalFeePortfolio: toNumber(value) ?? null,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  capitalFeeTraditional: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          capitalFeeTraditional: toNumber(value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            capitalFeeTraditional: toNumber(value) ?? null,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  fundDiscountPermitted: (value: boolean) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          fundDiscountPermitted: value,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            fundDiscountPermitted: value,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  fundDiscountValue: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          fundDiscountValue: toNumber(option.value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            fundDiscountValue: toNumber(option.value),
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  provisionWaiverAllowed: (value: boolean) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          provisionWaiverAllowed: value,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            provisionWaiverAllowed: value,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  provisionWaiverHealthcare: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          provisionWaiverHealthcare: toNumber(value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            provisionWaiverHealthcare: toNumber(value) ?? null,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  provisionWaiverPremiumWaiver: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          provisionWaiverPremiumWaiver: toNumber(value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            provisionWaiverPremiumWaiver: toNumber(value) ?? null,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  provisionWaiverSickness: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          provisionWaiverSickness: toNumber(value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            provisionWaiverSickness: toNumber(value) ?? null,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  riskBucket: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          riskBucket: formatRiskBucketInput(value),
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            riskBucket: formatRiskBucketInput(value),
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
});

export const getFeeOptions = (intl: IntlShape) =>
  [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75].map(value => ({
    label: intl.formatNumber(Number(bpsToDecimalFraction(value)), {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    value: value.toString(),
  }));

export const getDiscountOptions = (intl: IntlShape) =>
  [0.2, 0.3, 0.5].map(value => ({
    label: intl.formatMessage(smeFeesMessages.fundDiscountValue, {
      percentage: intl.formatNumber(value, {
        style: 'percent',
      }),
    }),
    value: value.toString(),
  }));

export const getProvisionWaiverOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage(smeFeesMessages.noProvisionWaiver),
    value: '0',
  },
  {
    label: intl.formatMessage(smeFeesMessages.halfProvisionWaiver),
    value: '0.5',
  },
  {
    label: intl.formatMessage(smeFeesMessages.fullProvisionWaiver),
    value: '1',
  },
];
