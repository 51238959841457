import { BenefitType } from '@frontend/benefit-types';
import { ContentContainer } from '@frontend/ui';
import {
  benefitTypesMessages,
  createBenefitTypesMessages,
} from 'app/messages/benefits';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { CreateAccident } from 'features/companies/company/benefits/create/accident';
import { CreateDigitalAdvice } from 'features/companies/company/benefits/create/digital-advice';
import { CreateDisability } from 'features/companies/company/benefits/create/disability';
import { CreateEpassi } from 'features/companies/company/benefits/create/epassi';
import { CreateEpassiBike } from 'features/companies/company/benefits/create/epassi-bike';
// import { CreateEmployersPension } from 'features/companies/company/benefits/create/employers-pension';
import { CreateHealthcare } from 'features/companies/company/benefits/create/healthcare';
import { CreateLife } from 'features/companies/company/benefits/create/life';
import { CreateOccupationalPension } from 'features/companies/company/benefits/create/occupational-pension';
import { CreatePersonalAdvice } from 'features/companies/company/benefits/create/personal-advice';
import { CreateSalaryExchange } from 'features/companies/company/benefits/create/salary-exchange';
import { CreateSicknessAndAccident } from 'features/companies/company/benefits/create/sickness-and-accident';
import { CreateSynsam } from 'features/companies/company/benefits/create/synsam';
import { CreateTgl } from 'features/companies/company/benefits/create/tgl';
import { CreateWellness } from 'features/companies/company/benefits/create/wellness';
import { Page } from 'features/page';
import React from 'react';
import { matchPath, Route, RouteComponentProps, Switch } from 'react-router';

import { MatchParams as CompanyMatchParams } from '../..';

type UrlSegment = string;

export const BENEFIT_URL: Record<BenefitType, UrlSegment> = {
  advinans_occupational_pension: 'occupational-pension',
  advinans_salary_exchange: 'salary-exchange',
  epassi_bike: 'epassi-bike',
  epassi: 'epassi',
  euro_accident_accident: 'accident-insurance',
  euro_accident_life: 'life-insurance',
  euro_accident_plansjuk: 'disability-insurance',
  euro_accident_privataccess: 'healthcare-insurance',
  euro_accident_sickness_and_accident: 'sickness-and-accident-insurance',
  euro_accident_tgl: 'tgl-insurance',
  nordea_node_digital_advice: 'digital-advice',
  // nordea_node_employers_pension: 'employers-pension',
  nordea_node_personal_advice: 'personal-advice',
  synsam: 'synsam',
  wellness: 'wellness',
};

export interface MatchParams extends CompanyMatchParams {
  benefitType: UrlSegment;
}

export const BenefitsCreateRoutes: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({ match: { params, path, url }, location: { pathname } }) => {
  const parentLink = `/companies/${params.companyId}/benefit-packages/benefits`;
  const { formatMessage } = useIntl();

  const benefitTypeMatch = matchPath<MatchParams>(pathname, {
    path: `${path}/:benefitType`,
    exact: true,
    strict: false,
  });

  const benefitTypeUrlSegment = benefitTypeMatch?.params.benefitType;

  const benefitType = Object.keys(BENEFIT_URL).find(
    _benefitType => BENEFIT_URL[_benefitType] === benefitTypeUrlSegment,
  );

  if (!benefitType) {
    return <EmptyStatePage parentLink={parentLink} />;
  }

  return (
    <NavigationAnchor
      title={formatMessage({
        select: benefitType,
        messages: createBenefitTypesMessages,
      })}
      url={url}
    >
      <Page
        title={
          <FormattedMessage
            select={benefitType}
            messages={benefitTypesMessages}
          />
        }
        parentLink={parentLink}
      >
        <ContentContainer>
          <Switch>
            <Route
              component={CreateAccident}
              exact
              path={`${path}/accident-insurance`}
            />
            <Route
              component={CreateDigitalAdvice}
              exact
              path={`${path}/digital-advice`}
            />
            <Route
              component={CreateDisability}
              exact
              path={`${path}/disability-insurance`}
            />
            {/*
            <Route
              component={CreateEmployersPension}
              exact
              path={`${path}/employers-pension`}
            /> */}
            <Route component={CreateEpassi} exact path={`${path}/epassi`} />
            <Route
              component={CreateEpassiBike}
              exact
              path={`${path}/epassi-bike`}
            />
            <Route
              component={CreateHealthcare}
              exact
              path={`${path}/healthcare-insurance`}
            />
            <Route
              component={CreateLife}
              exact
              path={`${path}/life-insurance`}
            />
            <Route
              component={CreateOccupationalPension}
              exact
              path={`${path}/occupational-pension`}
            />
            <Route
              component={CreatePersonalAdvice}
              exact
              path={`${path}/personal-advice`}
            />
            <Route
              component={CreateSalaryExchange}
              exact
              path={`${path}/salary-exchange`}
            />
            <Route
              component={CreateSicknessAndAccident}
              exact
              path={`${path}/sickness-and-accident-insurance`}
            />

            <Route component={CreateSynsam} exact path={`${path}/synsam`} />
            <Route component={CreateTgl} exact path={`${path}/tgl-insurance`} />
            <Route component={CreateWellness} exact path={`${path}/wellness`} />
          </Switch>
        </ContentContainer>
      </Page>
    </NavigationAnchor>
  );
};
