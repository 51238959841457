import {
  ContentContainer,
  PageLede,
  Section,
  SectionHeader,
} from '@frontend/ui';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import { TotalCompensationConfiguration } from 'features/companies/company/total-compensation';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const TotalCompensationRoute: React.FC = () => (
  <Suspense fallback={<TopLoading />}>
    {({ errors }) => (
      <ContentContainer>
        {errors && <GraphQlErrors errors={errors} />}
        <Section>
          <SectionHeader>
            <FormattedMessage
              {...totalCompensationMessages.totalCompensation}
            />
          </SectionHeader>
          <PageLede>
            <TextGrid>
              <FormattedMessage
                {...totalCompensationMessages.totalCompensationConfigurationDescription}
              />
            </TextGrid>
          </PageLede>
          <TotalCompensationConfiguration />
        </Section>
      </ContentContainer>
    )}
  </Suspense>
);
