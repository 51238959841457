import { inactiveEmploymentsQuery } from 'app/apollo/graphql/types';

export const updateInactiveEmployments = (
  previousResult: inactiveEmploymentsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: inactiveEmploymentsQuery;
  },
): inactiveEmploymentsQuery => {
  if (
    !previousResult?.import?.analysis?.inactiveEmployments ||
    !fetchMoreResult?.import?.analysis?.inactiveEmployments
  ) {
    return previousResult;
  }

  return fetchMoreResult;
};
