import { ContentContainer, Td } from '@frontend/ui';
import {
  accountingObjectsQuery,
  accountingObjectsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { NoValue } from 'components/NoValue';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import { ACCOUNTING_OBJECTS_QUERY } from 'features/companies/graphql/queries';
import qs from 'query-string';
import React, { useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { CostReportingTable, CostReportingTableRow } from '../components/Table';
import { costReportingMessages } from '../messages';
import { updateAccountingObjectsQuery } from '../utils/update-accounting-objects-query';
import { useAccountingDimension } from '../utils/use-accounting-dimension';

interface MatchParams extends CompanyMatchParams {
  dimensionId: string;
}

export const CostReportingDimension: React.FC = () => {
  const {
    params: { companyId, dimensionId },
  } = useRouteMatch<MatchParams>();
  const { pathname } = useLocation();

  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const {
    loading: dimensionLoading,
    error: dimensionError,
    name: dimensionName,
  } = useAccountingDimension(dimensionId);

  const {
    data: _objectsData,
    previousData: objectsPreviousData,
    loading: objectsLoading,
    error: objectsError,
    fetchMore,
  } = useQuery<accountingObjectsQuery, accountingObjectsQueryVariables>(
    ACCOUNTING_OBJECTS_QUERY,
    {
      variables: {
        companyId,
        dimensionId,
        first: Number(perPage),
      },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  );

  const objectsData = _objectsData ?? objectsPreviousData;

  if (dimensionLoading || (!objectsData && objectsLoading)) {
    return <TopLoading />;
  }

  const objects = objectsData?.accountingObjects?.edges.map(e => e.node);
  const objectsPageInfo = objectsData?.accountingObjects?.pageInfo;

  if (!objects?.length || !objectsPageInfo) {
    return (
      <EmptyState
        error={dimensionError ?? objectsError}
        title={
          <FormattedMessage {...costReportingMessages.emptyStateObjectsTitle} />
        }
        actions={
          <LinkButton to={{ pathname, search: qs.stringify({ create: true }) }}>
            <FormattedMessage
              {...costReportingMessages.createObject}
              values={{ dimensionName }}
            />
          </LinkButton>
        }
      >
        <p>
          <FormattedMessage
            {...costReportingMessages.emptyStateObjectsDescription}
          />
        </p>
      </EmptyState>
    );
  }

  return (
    <ContentContainer>
      {objectsLoading && <TopLoading />}
      {(!!dimensionError || !!objectsError) && (
        <GraphQlError error={dimensionError ?? objectsError} />
      )}
      <CostReportingTable
        dimensionName={dimensionName}
        columns={[
          {
            children: (
              <FormattedMessage
                key={costReportingMessages.tableCodeColumn.id}
                {...costReportingMessages.tableCodeColumn}
              />
            ),
          },
          {
            children: (
              <FormattedMessage
                key={costReportingMessages.tableNameColumn.id}
                {...costReportingMessages.tableNameColumn}
              />
            ),
          },
          {
            type: 'number',
            children: (
              <FormattedMessage
                key={costReportingMessages.tableMembershipsColumn.id}
                {...costReportingMessages.tableMembershipsColumn}
              />
            ),
          },
        ]}
        navigation={
          <TableNavigation
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: objectsPageInfo.endCursor,
                },
                updateQuery: updateAccountingObjectsQuery,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  first: undefined,
                  before: objectsPageInfo.startCursor,
                  last: Number(perPage),
                },
                updateQuery: updateAccountingObjectsQuery,
              });
            }}
            perPage={perPage}
            setPerPage={setPerPage}
            pageInfo={objectsPageInfo}
          />
        }
      >
        {objects.map(object => (
          <CostReportingTableRow
            key={object.id}
            editLink={{
              pathname,
              search: qs.stringify({ edit: object.id }),
              state: { perPage },
            }}
          >
            <Td>{object.identifier}</Td>
            <Td>{object.name}</Td>
            <Td type="number">
              {object.memberships?.totalCount ?? <NoValue />}
            </Td>
          </CostReportingTableRow>
        ))}
      </CostReportingTable>
    </ContentContainer>
  );
};
