import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { BalanceTypes } from 'features/companies/company/account-plan/balance-types';
import { BookkeepingAccounts } from 'features/companies/company/account-plan/bookkeeping-accounts';
import { CreateCustomTypeModal } from 'features/companies/company/account-plan/create-custom-type';
import { Remunerations } from 'features/companies/company/account-plan/remunerations';
import { UpdateBookkeepingAccountModal } from 'features/companies/company/account-plan/update-bookkeeping-account';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const AccountPlanRoute: React.FC = () => (
  <Suspense fallback={<TopLoading />}>
    {({ errors }) => (
      <>
        {errors && <GraphQlErrors errors={errors} />}
        <BookkeepingAccounts />
        <Remunerations />
        <BalanceTypes />
        <CreateCustomTypeModal />
        <UpdateBookkeepingAccountModal />
      </>
    )}
  </Suspense>
);
