import gql from 'graphql-tag';

export const APPROVE_SALARY_EXCHANGE_REQUEST_MUTATION = gql`
  mutation approveSalaryExchangeRequestMutation(
    $input: FlexApproveSalaryExchangeRequestInput!
  ) {
    approveSalaryExchangeRequest(input: $input) {
      salaryExchangeRequest {
        id
      }
    }
  }
`;
