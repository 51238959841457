import gql from 'graphql-tag';

import { employmentDetails } from '../../graphql/fragments';

export const ADD_EMPLOYMENT_MUTATION = gql`
  mutation addEmploymentMutation($input: AddEmploymentInput!) {
    addEmployment(input: $input) {
      employment {
        ...employmentDetails
      }
    }
  }
  ${employmentDetails}
`;
