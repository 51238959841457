import gql from 'graphql-tag';

export const CREATE_PROPOSAL_DISABILITY_BENEFIT_MUTATION = gql`
  mutation createProposalDisabilityBenefitMutation(
    $input: CreateProposalSicknessBenefitInput!
  ) {
    createProposalSicknessBenefit(input: $input) {
      id
      benefits {
        id
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
