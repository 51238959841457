import {
  companyFullyCapableForWorkRequestsQuery,
  companyFullyCapableForWorkRequestsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { NotificationCard } from 'components/NotificationCard';
import { TopLoading } from 'components/TopLoading';
import { MatchParams } from 'features/companies/company/employees';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY } from '../../graphql/queries';
import { workCapabilityMessages } from '../../messages';

export const CompanyFcfwNotification: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    companyFullyCapableForWorkRequestsQuery,
    companyFullyCapableForWorkRequestsQueryVariables
  >(COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY, {
    variables: { companyId },
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company?.fcfwRequests?.edges.length) {
    return null;
  }

  return (
    <>
      {error && <GraphQlError error={error} />}
      <NotificationCard
        type="warning"
        title={
          <FormattedMessage
            {...workCapabilityMessages.confirmFullWorkCapability}
          />
        }
        actions={
          <LinkButton
            to={`/companies/${companyId}/employees/assert-work-capability`}
          >
            <FormattedMessage {...commonMessages.continue} />
          </LinkButton>
        }
      >
        <FormattedMessage
          {...workCapabilityMessages.confirmFullWorkCapabilityEmployeesLede}
        />
      </NotificationCard>
    </>
  );
};
