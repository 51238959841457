import { salaryExchangeRequestsQuery } from 'app/apollo/graphql/types';

interface FetchMoreResult {
  fetchMoreResult?: salaryExchangeRequestsQuery;
}

export const updateQuery = (
  previousResult: salaryExchangeRequestsQuery,
  { fetchMoreResult }: FetchMoreResult,
) => {
  if (
    !previousResult.salaryExchangeRequests ||
    !fetchMoreResult?.salaryExchangeRequests
  ) {
    return previousResult;
  }

  return fetchMoreResult;
};
