import { Chip as UiChip, Icon, IconContent } from '@frontend/ui';
import { linkCss } from 'components/DefaultLink';
import { Link } from 'components/Links/Link';
import React, { MouseEventHandler } from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const ChipLink = styled(Link)`
  ${linkCss}
  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  &:focus {
    outline: none;

    > * {
      border: 0.125rem solid ${p => p.theme.primary};
    }
  }
`;

interface ChipProps {
  /**
   * If true, the margin-bottom of the chip will be removed.
   */
  collapseMarginBottom?: boolean;
}

const Chip = styled(UiChip)<ChipProps>`
  ${p => p.collapseMarginBottom && `margin-bottom: 0;`};
`;

interface CommonProps extends ChipProps {
  leadingIcon: IconContent;
  text: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

interface ChipWithLink extends CommonProps, Pick<LinkProps, 'to'> {
  onClick?: never;
}

interface ChipWithOnClick extends CommonProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
  to?: never;
}

export type Props = ChipWithLink | ChipWithOnClick;

const isChipWithLink = (chip: Props): chip is ChipWithLink => 'to' in chip;

export const AssistChip: React.FC<Props> = props => {
  const commonProps = {
    text: props.text,
    leadingIcon: (
      <Icon icon={props.leadingIcon} size="small" color="primary" decorative />
    ),
    clickable: true,
    disabled: props.disabled,
    loading: props.loading,
    collapseMarginBottom: props.collapseMarginBottom,
  };

  if (isChipWithLink(props)) {
    // Disabled link chips must not add the
    // wrapping link to avoid being clickable
    if (props.disabled) {
      return <Chip {...commonProps} />;
    }

    return (
      <ChipLink to={props.to}>
        <Chip {...commonProps} />
      </ChipLink>
    );
  }

  return <Chip {...commonProps} onClick={props.onClick} />;
};
