import { Table, Td, Tfoot, Th, Tr } from '@frontend/ui';
import { toNumber } from '@frontend/utils';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { insuranceMessages } from 'app/messages/insurance';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import {
  smeBenefitsMessages,
  smeCompilationMessages,
} from 'features/sme/messages/sme';
import React from 'react';

interface Costs {
  employersPension: string | null;
  occupationalPension: string | null;
  riskAccident: string | null;
  riskHealthcare: string | null;
  riskLife: string | null;
  riskPensionPremiumWaiver: string | null;
  riskSickness: string | null;
  riskTgl: string | null;
  salaryExchange: string | null;
  total: string | null;
}

interface Props {
  costs?: Costs | null;
}

export const CostsTable: React.FC<Props> = ({ costs }) => {
  const tableHead = (
    <>
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: '40%' }} />
      </colgroup>
      <thead>
        <Tr>
          <Th align="left">
            <FormattedMessage {...smeCompilationMessages.offer} />
          </Th>
          <Th align="left">
            <FormattedMessage {...smeCompilationMessages.supplier} />
          </Th>
          <Th align="right">
            <FormattedMessage {...smeCompilationMessages.monthlyPayment} />
          </Th>
        </Tr>
      </thead>
    </>
  );

  if (!costs) {
    return (
      <Table size="small">
        {tableHead}
        <tbody>
          <Tr>
            <Td>
              <FormattedMessage {...smeBenefitsMessages.noBenefits} />
            </Td>
          </Tr>
        </tbody>
      </Table>
    );
  }

  const hasPension =
    costs.employersPension != null || costs.occupationalPension != null;
  const pensionCosts =
    (toNumber(costs.employersPension) ?? 0) +
    (toNumber(costs.occupationalPension) ?? 0);

  return (
    <Table size="small">
      {tableHead}
      <tbody>
        {hasPension && (
          <Tr>
            <Td>
              <FormattedMessage
                {...commonBenefitMessages.occupationalPension}
              />
            </Td>
            <Td>
              <FormattedMessage {...commonMessages.nordea} />
            </Td>
            <Td align="right">
              <FormattedCurrency value={pensionCosts} currency="SEK" />
            </Td>
          </Tr>
        )}
        {costs.riskPensionPremiumWaiver != null && (
          <Tr>
            <Td>
              <FormattedMessage {...insuranceMessages.premiumWaiver} />
            </Td>
            <Td>
              <FormattedMessage {...commonMessages.euroAccident} />
            </Td>
            <Td align="right">
              <FormattedCurrency
                value={costs.riskPensionPremiumWaiver}
                currency="SEK"
              />
            </Td>
          </Tr>
        )}
        {costs.riskSickness != null && (
          <Tr>
            <Td>
              <FormattedMessage {...commonBenefitMessages.sickness} />
            </Td>
            <Td>
              <FormattedMessage {...commonMessages.euroAccident} />
            </Td>
            <Td align="right">
              <FormattedCurrency value={costs.riskSickness} currency="SEK" />
            </Td>
          </Tr>
        )}
        {costs.riskHealthcare != null && (
          <Tr>
            <Td>
              <FormattedMessage {...commonBenefitMessages.health} />
            </Td>
            <Td>
              <FormattedMessage {...commonMessages.euroAccident} />
            </Td>
            <Td align="right">
              <FormattedCurrency value={costs.riskHealthcare} currency="SEK" />
            </Td>
          </Tr>
        )}
        {costs.riskAccident != null && (
          <Tr>
            <Td>
              <FormattedMessage {...commonBenefitMessages.accident} />
            </Td>
            <Td>
              <FormattedMessage {...commonMessages.euroAccident} />
            </Td>
            <Td align="right">
              <FormattedCurrency value={costs.riskAccident} currency="SEK" />
            </Td>
          </Tr>
        )}
        {costs.riskTgl != null && (
          <Tr>
            <Td>
              <FormattedMessage {...commonBenefitMessages.tgl} />
            </Td>
            <Td>
              <FormattedMessage {...commonMessages.euroAccident} />
            </Td>
            <Td align="right">
              <FormattedCurrency value={costs.riskTgl} currency="SEK" />
            </Td>
          </Tr>
        )}
        {costs.riskLife != null && (
          <Tr>
            <Td>
              <FormattedMessage {...commonBenefitMessages.life} />
            </Td>
            <Td>
              <FormattedMessage {...commonMessages.euroAccident} />
            </Td>
            <Td align="right">
              <FormattedCurrency value={costs.riskLife} currency="SEK" />
            </Td>
          </Tr>
        )}
        {costs.salaryExchange != null && (
          <Tr>
            <Td>
              <FormattedMessage {...commonBenefitMessages.salaryExchange} />
            </Td>
            <Td>
              <FormattedMessage {...commonMessages.nordea} />
            </Td>
            <Td align="right">
              <FormattedCurrency value={costs.salaryExchange} currency="SEK" />
            </Td>
          </Tr>
        )}
      </tbody>
      {costs.total != null && (
        <Tfoot>
          <Tr>
            <Td>
              <FormattedMessage {...commonMessages.total} />
            </Td>
            <Td />
            <Td>
              <FormattedCurrency value={costs.total} currency="SEK" />
            </Td>
          </Tr>
        </Tfoot>
      )}
    </Table>
  );
};
