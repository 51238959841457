import { missingEmploymentsQuery } from 'app/apollo/graphql/types';

export const updateMissingEmployments = (
  previousResult: missingEmploymentsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: missingEmploymentsQuery;
  },
): missingEmploymentsQuery => {
  if (
    !previousResult?.import?.analysis?.missingEmployments ||
    !fetchMoreResult?.import?.analysis?.missingEmployments
  ) {
    return previousResult;
  }

  return fetchMoreResult;
};
