import gql from 'graphql-tag';

export const TERMINATE_SALARY_EXCHANGE_MUTATION = gql`
  mutation terminateSalaryExchangeMutation(
    $input: FlexTerminateSalaryExchangeInput!
  ) {
    flexTerminateSalaryExchange(input: $input) {
      salaryExchange {
        id
        userAccountId
        status
        deductionValue
        premium
        effectiveDate
      }
    }
  }
`;
