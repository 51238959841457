import gql from 'graphql-tag';

export const IMPORT_POLLING_QUERY = gql`
  query importPollingQuery($id: ID!, $statuses: [ImportStatus!]) {
    import(id: $id) {
      id
      status
      fileUrl
      progress {
        preview {
          total
          done
        }
        runner {
          total
          done
        }
      }
      records(statuses: $statuses) {
        totalCount
      }
    }
  }
`;

export const IMPORT_COMPLETE_USER_RECORDS_QUERY = gql`
  query importCompleteUserRecordsQuery($id: ID!) {
    import(id: $id) {
      id
      records(statuses: [COMPLETE]) {
        edges {
          node {
            id {
              membershipId
            }
          }
        }
      }
    }
  }
`;
