import { Formik } from '@frontend/formik';
import { edit } from '@frontend/ui/icons';
import { formatNaturalPersonIdentifier } from '@frontend/utils';
import {
  smeCompanyEditEmployeeQuery,
  smeCompanyEditEmployeeQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal/employee';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import React from 'react';
import { LinkProps, useRouteMatch } from 'react-router-dom';

import { useProposalReadonly } from '../../../../utils/use-proposal-readonly';
import { EmployeeForm, FormValues, validationSchema } from '../../form';
import { SME_COMPANY_EDIT_EMPLOYEE_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

export const EditEmployee: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<MatchParams>();
  const { submit, submissionError } = useSubmit({ params });

  const { data, loading, error } = useQuery<
    smeCompanyEditEmployeeQuery,
    smeCompanyEditEmployeeQueryVariables
  >(SME_COMPANY_EDIT_EMPLOYEE_QUERY, {
    errorPolicy: 'all',
    variables: { employeeId: params.employeeId, proposalId: params.proposalId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposalMembership || !data.proposal?.benefitPackages) {
    return <EmptyState error={error} />;
  }

  const {
    proposal: { benefitPackages },
    proposalMembership,
  } = data;

  const initialValues: FormValues = {
    benefitPackageId: proposalMembership.benefitPackage.id,
    email: proposalMembership.email,
    firstName: proposalMembership.firstName,
    isOwner: proposalMembership.isOwner,
    lastName: proposalMembership.lastName,
    monthlyFixedPensionPremium:
      proposalMembership.monthlyFixedPensionPremium ?? '',
    monthlySalary: proposalMembership.monthlySalary ?? '',
    personalIdentityNumber: formatNaturalPersonIdentifier(
      proposalMembership.personalIdentityNumber,
    ),
    phoneNumber: proposalMembership.phoneNumber ?? '',
  };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl, benefitPackages)}
    >
      {({ isValid, isSubmitting }) => (
        <EmployeeForm
          benefitPackages={benefitPackages}
          isValid={isValid}
          isSubmitting={isSubmitting}
          submissionError={submissionError}
        />
      )}
    </Formik>
  );
};

type Props = Pick<LinkProps, 'to'>;

export const EditEmployeeAssistChip: React.FC<Props> = ({ to }) => {
  const isReadonly = useProposalReadonly();

  return (
    <ChipsWrapper>
      <AssistChip
        text={<FormattedMessage {...smeEmployeesMessages.editDetails} />}
        leadingIcon={edit}
        to={to}
        disabled={isReadonly}
      />
    </ChipsWrapper>
  );
};
