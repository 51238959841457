import gql from 'graphql-tag';

export const CREATE_IMPORT_MUTATION = gql`
  mutation createImportMutation($input: CreateImportInput!) {
    createImport(input: $input) {
      import {
        id
      }
    }
  }
`;
