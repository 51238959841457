import { ContentContainer, SelectOption } from '@frontend/ui';
import {
  companyOptionsQuery,
  membershipsQuery,
  membershipsQueryVariables,
} from 'app/apollo/graphql/types';
import { employeeMessages } from 'app/messages/employees';
import { companyName } from 'app/utils/company-name';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { SearchField } from 'components/SearchField';
import { TopLoading } from 'components/TopLoading';
import { Page } from 'features/page';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';

import { EmployeesTable } from './components/EmployeesTable';
import { Filters } from './components/EmployeesTable/Filters';
import { COMPANY_OPTIONS_QUERY, MEMBERSHIPS_QUERY } from './graphql/queries';
import { updateMembershipsQuery } from './utils/update-company-memberships-query';

export const Employees: React.FC = () => {
  const location = useLocation();

  const { search, includeTerminated, per_page, companyId } = qs.parse(
    location.search,
  );

  const {
    previousData,
    data: _data,
    loading: membershipLoading,
    error: membershipError,
    fetchMore,
  } = useQuery<membershipsQuery, membershipsQueryVariables>(MEMBERSHIPS_QUERY, {
    variables: {
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      search,
      includeTerminated: includeTerminated === 'true',
      companyId,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: companyOptionsData,
    loading: companyOptionsLoading,
    error: companyOptionsError,
  } = useQuery<companyOptionsQuery>(COMPANY_OPTIONS_QUERY, {
    errorPolicy: 'all',
  });

  const companyOptions: SelectOption[] | undefined =
    companyOptionsData?.companies?.edges
      .map(c => ({
        label: companyName(c.node),
        value: c.node.id,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));

  const data = _data ?? previousData;
  const pageInfo = data?.memberships?.pageInfo;
  const memberships = data?.memberships;

  return (
    <Page title={<FormattedMessage {...employeeMessages.employees} />}>
      <ContentContainer>
        {(membershipLoading || companyOptionsLoading) && <TopLoading />}
        {(membershipError || companyOptionsError) && (
          <GraphQlError error={membershipError ?? companyOptionsError} />
        )}
        <SearchField
          label={<FormattedMessage {...employeeMessages.search} />}
        />
        <Filters companyOptions={companyOptions} />
        {memberships && pageInfo && (
          <EmployeesTable
            pageInfo={pageInfo}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: pageInfo.endCursor,
                },
                updateQuery: updateMembershipsQuery,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: pageInfo.startCursor,
                  first: undefined,
                  last: per_page
                    ? parseInt(per_page, 10)
                    : DEFAULT_RESULT_PER_PAGE,
                },
                updateQuery: updateMembershipsQuery,
              });
            }}
            employees={memberships.edges.map(e => e.node)}
          />
        )}
      </ContentContainer>
    </Page>
  );
};
