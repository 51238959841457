import { companyAdministratorsQuery } from 'app/apollo/graphql/types';

export const updateCompanyAdministratorsQuery = (
  previousResult: companyAdministratorsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: companyAdministratorsQuery;
  },
) => {
  if (
    !previousResult?.company?.administrators ||
    !fetchMoreResult?.company?.administrators
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.administrators;

  return {
    company: {
      ...previousResult.company,
      administrators: {
        pageInfo,
        __typename: previousResult.company.administrators.__typename,
        edges: [...edges],
      },
    },
  };
};
