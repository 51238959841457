import { FormattedMessage } from 'components/formats';
import { Downloadable, TableDownloadMenu } from 'components/TableDownloadMenu';
import React from 'react';

import { invoiceMessages } from '../../messages';

interface Props {
  csvUrl: string | null;
  excelUrl: string | null;
  pdfUrl: string | null;
  peppolUrl: string | null;
  sie4Url: string | null;
}
export const InvoiceDownloadMenu: React.FC<Props> = ({
  sie4Url,
  peppolUrl,
  pdfUrl,
  excelUrl,
  csvUrl,
}) => {
  const sie4 = sie4Url
    ? {
        url: sie4Url,
        displayName: <FormattedMessage {...invoiceMessages.downloadSie4} />,
      }
    : null;
  const peppol = peppolUrl
    ? {
        url: peppolUrl,
        displayName: <FormattedMessage {...invoiceMessages.downloadPeppol} />,
      }
    : null;

  const pdf = pdfUrl
    ? {
        url: pdfUrl,
        displayName: <FormattedMessage {...invoiceMessages.downloadPdf} />,
      }
    : null;

  const excel = excelUrl
    ? {
        url: excelUrl,
        displayName: <FormattedMessage {...invoiceMessages.downloadExcel} />,
      }
    : null;

  const csv = csvUrl
    ? {
        url: csvUrl,
        displayName: <FormattedMessage {...invoiceMessages.downloadCsv} />,
      }
    : null;

  // Filter out possible null elements. As
  // Array.prototype.filter is unable to
  // return a different type than it recieves
  // we have to cheat this with reduce instead
  const downloadables = [sie4, peppol, pdf, excel, csv].reduce((acc, doc) => {
    if (doc) {
      acc.push(doc);
    }
    return acc;
  }, new Array<Downloadable>());

  return <TableDownloadMenu downloadables={downloadables} />;
};
