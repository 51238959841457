import { employeeMeetingsQuery } from 'app/apollo/graphql/types';

export const updateMeetingsQuery = (
  previousResult: employeeMeetingsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: employeeMeetingsQuery;
  },
) => {
  if (!previousResult?.employeeMeetings || !fetchMoreResult?.employeeMeetings) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.employeeMeetings;

  return {
    employeeMeetings: {
      __typename: previousResult.employeeMeetings.__typename,
      edges: [...edges],
      pageInfo,
    },
  };
};
