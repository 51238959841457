import gql from 'graphql-tag';

export const ADD_CUSTOM_TYPE_MUTATION = gql`
  mutation addCustomTypeMutation($input: AddCustomTypeInput) {
    addCustomType(input: $input) {
      id
    }
  }
`;

export const UPDATE_BOOKKEEPING_ACCOUNT_MUTATION = gql`
  mutation updateBookkeepingAccountMutation(
    $input: UpdateBookkeepingAccountInput!
  ) {
    updateBookkeepingAccount(input: $input) {
      accountKey
    }
  }
`;
