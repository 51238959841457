import {
  TotalCompensationCategory2 as TotalCompensationCategory,
  totalCompensationDetailsQuery_company_Company_totalCompensationConfiguration_TotalCompensationConfiguration as TotalCompensationConfiguration,
  totalCompensationDetailsQuery_Query,
  TotalCompensationId2 as TotalCompensationId,
} from 'app/apollo/graphql/types';

import { TableItem } from '../../components/Table';
import { groupTotalCompByCategory } from '../../utils/group-total-comp-by-category';

type Sections = Partial<Record<TotalCompensationCategory, TableItem[]>>;

interface Details {
  benefitItems: TableItem[];
  cashItems: TableItem[];
  employerContributionShare: string | null;
  pensionItems: TableItem[];
  sections: Sections;
  totalCompensationConfiguration: TotalCompensationConfiguration | null;
}

export const getTotalCompDetails = (
  data?: totalCompensationDetailsQuery_Query,
): Details | null => {
  if (!data?.membership || !data?.company) {
    return null;
  }

  const {
    membership: { totalCompensation, employerContributionShare },
    company: { totalCompensationConfiguration },
  } = data;

  const sections: Sections = groupTotalCompByCategory(totalCompensation);

  const cashItems: TableItem[] =
    sections.CASH?.reduce((acc, item) => {
      if (item.id === TotalCompensationId.GROSS_SALARY) {
        return [...acc, item];
      }

      if (item.id === TotalCompensationId.SALARY_EXCHANGE_DEDUCTION) {
        return [
          ...acc,
          {
            ...item,
            value: Math.abs(item.value) * -1,
          },
        ];
      }

      return acc;
    }, []) ?? [];

  const pensionItems: TableItem[] =
    sections.PENSION?.filter(item => {
      if (item.id === TotalCompensationId.PENSION) {
        return !!totalCompensationConfiguration?.pension;
      }

      if (item.id === TotalCompensationId.SALARY_EXCHANGE) {
        return !!totalCompensationConfiguration?.salaryExchange;
      }

      return true;
    }) ?? [];

  const benefitItems: TableItem[] = totalCompensationConfiguration?.benefits
    ? sections.BENEFITS?.filter(item => {
        if (item.id === TotalCompensationId.WELLNESS) {
          return totalCompensationConfiguration.wellness;
        }

        return true;
      }) ?? []
    : [];

  const isEmpty =
    (!totalCompensationConfiguration?.salary || !cashItems.length) &&
    (!totalCompensationConfiguration?.variableCompensation ||
      !sections.BONUS?.length) &&
    !pensionItems.length &&
    !benefitItems.length &&
    !sections.EMPLOYERS_CONTRIBUTIONS?.length;

  if (isEmpty) {
    return null;
  }

  return {
    cashItems,
    pensionItems,
    benefitItems,
    sections,
    employerContributionShare,
    totalCompensationConfiguration,
  };
};
