import { edit } from '@frontend/ui/icons';
import { membershipDetailsQuery_membership_Membership as Membership } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { workInformationMessages } from 'app/messages/employees';
import { COLLECTIVE_AGREEMENT_LABEL } from 'app/utils/constants';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { DescriptionTable } from 'components/DescriptionTable';
import {
  FormattedCurrency,
  FormattedMessage,
  FormattedPercent,
} from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import React from 'react';

interface Props {
  membership: Membership;
}

export const PersonalDetailsTable: React.FC<Props> = ({ membership }) => (
  <>
    <ChipsWrapper>
      <AssistChip
        to={`/companies/${membership.company.id}/employees/${membership.userAccountId}/edit`}
        text={<FormattedMessage {...commonMessages.updateInformationDetails} />}
        leadingIcon={edit}
      />
    </ChipsWrapper>
    <DescriptionTable
      alignLeft
      entries={[
        {
          id: 'employee.name',
          label: <FormattedMessage {...commonMessages.name} />,
          value: `${membership.firstName} ${membership.lastName}`,
        },
        {
          id: 'employee.nid',
          label: <FormattedMessage {...commonMessages.nid} />,
          value: (
            <FormattedNaturalPersonIdentifier
              nids={membership.naturalPersonIdentifiers}
            />
          ),
        },
        {
          id: 'employee.email',
          label: <FormattedMessage {...commonMessages.email} />,
          value: membership.email,
        },
        {
          id: 'employee.phone',
          label: <FormattedMessage {...commonMessages.phone} />,
          value: membership.phone ? membership.phone : <NoValue />,
        },
        {
          id: 'employee.collectiveAgreement',
          label: <FormattedMessage {...commonMessages.collectiveAgreement} />,
          value: membership.collectiveAgreement ? (
            COLLECTIVE_AGREEMENT_LABEL[membership.collectiveAgreement] ||
            membership.collectiveAgreement
          ) : (
            <NoValue />
          ),
        },
        {
          id: 'employee.employeeNumberLabel',
          label: (
            <FormattedMessage
              {...workInformationMessages.employeeNumberLabel}
            />
          ),
          value: membership.employeeNumber ? (
            membership.employeeNumber
          ) : (
            <NoValue />
          ),
        },
        {
          id: 'employee.incomeTaxTableLabel',
          label: (
            <FormattedMessage
              {...workInformationMessages.incomeTaxTableLabel}
            />
          ),
          value: membership.incomeTaxTable ? (
            membership.incomeTaxTable
          ) : (
            <NoValue />
          ),
        },
        {
          id: 'employee.employerContributionShareLabel',
          label: (
            <FormattedMessage
              {...workInformationMessages.employerContributionShareLabel}
            />
          ),
          value: membership.employerContributionShare ? (
            <FormattedPercent
              value={parseFloat(membership.employerContributionShare)}
              noSuffix
            />
          ) : (
            <NoValue />
          ),
        },
        {
          id: 'employee.companyCarMonthlyValueLabel',
          label: (
            <FormattedMessage
              {...workInformationMessages.companyCarMonthlyValueLabel}
            />
          ),
          value: membership.companyCarMonthlyValue ? (
            <FormattedCurrency
              value={parseFloat(membership.companyCarMonthlyValue)}
              currency="SEK"
              noSuffix
            />
          ) : (
            <NoValue />
          ),
        },
        {
          id: 'employee.fitnessContributionBalanceLabel',
          label: (
            <FormattedMessage
              {...workInformationMessages.fitnessContributionBalanceLabel}
            />
          ),
          value: membership.fitnessContributionBalance ? (
            <FormattedCurrency
              value={parseFloat(membership.fitnessContributionBalance)}
              currency="SEK"
              noSuffix
            />
          ) : (
            <NoValue />
          ),
        },
        {
          id: 'employee.vacationDaysSavedLabel',
          label: (
            <FormattedMessage
              {...workInformationMessages.vacationDaysSavedLabel}
            />
          ),
          value: membership.vacationDaysSaved ? (
            membership.vacationDaysSaved
          ) : (
            <NoValue />
          ),
        },
        {
          id: 'employee.vacationDaysPaidLabel',
          label: (
            <FormattedMessage
              {...workInformationMessages.vacationDaysPaidLabel}
            />
          ),
          value: membership.vacationDaysPaid ? (
            membership.vacationDaysPaid
          ) : (
            <NoValue />
          ),
        },
        {
          id: 'employee.vacationDaysUnpaidLabel',
          label: (
            <FormattedMessage
              {...workInformationMessages.vacationDaysUnpaidLabel}
            />
          ),
          value: membership.vacationDaysUnpaid ? (
            membership.vacationDaysUnpaid
          ) : (
            <NoValue />
          ),
        },
        {
          id: 'employee.vacationDaysAdvanceLabel',
          label: (
            <FormattedMessage
              {...workInformationMessages.vacationDaysAdvanceLabel}
            />
          ),
          value: membership.vacationDaysAdvance ? (
            membership.vacationDaysAdvance
          ) : (
            <NoValue />
          ),
        },
        {
          id: 'employee.vacationDaysPerYearLabel',
          label: (
            <FormattedMessage
              {...workInformationMessages.vacationDaysPerYearLabel}
            />
          ),
          value: membership.vacationDaysPerYear ? (
            membership.vacationDaysPerYear
          ) : (
            <NoValue />
          ),
        },
      ]}
    />
  </>
);
