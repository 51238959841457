import { ApolloError, useMutation } from '@apollo/client';
import {
  deleteBenefitPackageMutation,
  deleteBenefitPackageMutationVariables,
  DeleteProposalBenefitPackageInput,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitPackagesMessages } from 'features/sme/messages/sme';
import { useMemo, useRef } from 'react';

import { clearProposalCache } from '../../../utils/clear-proposal-cache';
import { DELETE_BENEFIT_PACKAGE_MUTATION } from './graphql/mutations';

interface DeleteError {
  error?: ApolloError;
  id?: string;
}

interface Submit {
  _delete: (input: DeleteProposalBenefitPackageInput) => Promise<void>;
  deleteError: DeleteError;
  deleteLoading: boolean;
}

interface Params {
  params: MatchParams;
}

export const useDelete = ({ params }: Params): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const benefitPackageRef = useRef<string>();

  const [deleteBenefitPackage, { loading: deleteLoading, error }] = useMutation<
    deleteBenefitPackageMutation,
    deleteBenefitPackageMutationVariables
  >(DELETE_BENEFIT_PACKAGE_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals', 'validity'],
    }),
  });

  const _delete = async (input: DeleteProposalBenefitPackageInput) => {
    benefitPackageRef.current = input.id;
    try {
      const res = await deleteBenefitPackage({
        variables: {
          input,
        },
      });
      const proposalId = res.data?.deleteProposalBenefitPackage?.id;
      if (!proposalId) {
        return;
      }
      send({
        message: formatMessage(
          smeBenefitPackagesMessages.benefitPackageDeleted,
        ),
        type: 'success',
      });
      benefitPackageRef.current = undefined;
    } catch {
      // Do nothing
    }
  };

  const deleteError = useMemo(
    () => ({
      error,
      id: benefitPackageRef.current,
    }),
    [error],
  );

  return {
    _delete,
    deleteLoading,
    deleteError,
  };
};
