import {
  ProposalCompanySize,
  updateProposalQuery_Query as Data,
} from 'app/apollo/graphql/types';

import { OnChange } from '../../../contexts/autosave';

interface FormValues {
  companySize?: ProposalCompanySize;
}

export const getFormValues = (data?: Data): FormValues => ({
  companySize: data?.proposal?.companySize,
});

export const getOnChangeHandlers = (onChange: OnChange) => ({
  companySize: (value: string) =>
    onChange({
      cacheValue: {
        companySize: () => ProposalCompanySize[value],
      },
      evictCacheFields: ['cost'],
      mutationInput: {
        proposal: {
          companySize: ProposalCompanySize[value],
        },
      },
    }),
});
