import {
  CheckboxField,
  RadioGroup,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import {
  smeKnowledgeMessages,
  smeNeedsMessages,
} from 'features/sme/messages/sme';
import React, { useId } from 'react';

import { TemplateSelect } from '../../../../components/TemplateSelect';
import { useProposalReadonly } from '../../utils/use-proposal-readonly';
import { AutosaveStatus, useAutosave } from '../contexts/autosave';
import { getFormValues, getOnChangeHandlers } from './utils';

export const Knowledge: React.FC = () => {
  const adviceId = useId();
  const recentExperienceId = useId();
  const professionalExperienceId = useId();

  const { loading, data, onChange, status } = useAutosave();
  const intl = useIntl();
  const { formatMessage } = intl;
  const isReadonly = useProposalReadonly();

  if (loading) {
    return <TopLoading />;
  }

  const formValues = getFormValues(data);
  const _onChange = getOnChangeHandlers(onChange);

  const disabled = isReadonly || status === AutosaveStatus.RECONNECTING;

  return (
    <>
      <Subsection>
        <TextGrid>
          <SubsectionHeader id={adviceId}>
            <FormattedMessage
              {...smeKnowledgeMessages.adviceSavingsInsuranceDescription}
            />
          </SubsectionHeader>
          <RadioGroup
            disabled={disabled}
            checked={formValues.adviceSavingsInsurance}
            onChange={_onChange.adviceSavingsInsurance}
            options={[
              { label: formatMessage(commonMessages.yes), value: 'true' },
              { label: formatMessage(commonMessages.no), value: 'false' },
            ]}
            withoutMargin
            aria-labelledby={adviceId}
          />
        </TextGrid>
      </Subsection>
      {formValues.adviceSavingsInsurance !== 'false' && (
        <>
          <Subsection>
            <TextGrid>
              <SubsectionHeader id={recentExperienceId}>
                <FormattedMessage
                  {...smeKnowledgeMessages.recentExperiencePensionDescription}
                />
              </SubsectionHeader>
              <RadioGroup
                disabled={disabled}
                checked={formValues.recentExperiencePension}
                onChange={_onChange.recentExperiencePension}
                options={[
                  { label: formatMessage(commonMessages.yes), value: 'true' },
                  { label: formatMessage(commonMessages.no), value: 'false' },
                ]}
                withoutMargin
                aria-labelledby={recentExperienceId}
              />
            </TextGrid>
          </Subsection>
          <Subsection>
            <TextGrid>
              <SubsectionHeader id={professionalExperienceId}>
                <FormattedMessage
                  {...smeKnowledgeMessages.professionalExperiencePensionDescription}
                />
              </SubsectionHeader>
              <RadioGroup
                disabled={disabled}
                checked={formValues.professionalExperiencePension}
                onChange={_onChange.professionalExperiencePension}
                options={[
                  { label: formatMessage(commonMessages.yes), value: 'true' },
                  { label: formatMessage(commonMessages.no), value: 'false' },
                ]}
                withoutMargin
                aria-labelledby={professionalExperienceId}
              />
            </TextGrid>
          </Subsection>
          <Subsection>
            <TextGrid>
              <SubsectionHeader>
                <FormattedMessage
                  {...smeKnowledgeMessages.instrumentKnowledgeDescription}
                />
              </SubsectionHeader>
              <CheckboxField
                disabled={disabled}
                checked={formValues.pensionInstrumentKnowledge}
                onChange={_onChange.pensionInstrumentKnowledge}
              >
                <FormattedMessage
                  {...smeKnowledgeMessages.pensionInstrumentKnowledge}
                />
              </CheckboxField>
            </TextGrid>
          </Subsection>
          <Subsection>
            <TextGrid>
              <SubsectionHeader>
                <FormattedMessage {...smeKnowledgeMessages.notesHeading} />
              </SubsectionHeader>
              <DescriptionWrapper>
                <FormattedMessage {...smeKnowledgeMessages.notesDescription} />
              </DescriptionWrapper>
              <TemplateSelect
                disabled={disabled}
                label={formatMessage(
                  smeNeedsMessages.situationTemplateSelectLabel,
                )}
                options={[
                  {
                    label: formatMessage(
                      smeKnowledgeMessages.lacksPreviousKnowledge,
                    ),
                    value: formatMessage(
                      smeKnowledgeMessages.lacksPreviousKnowledgePlaceholder,
                    ),
                  },
                  {
                    label: formatMessage(
                      smeKnowledgeMessages.somePreviousKnowledge,
                    ),
                    value: formatMessage(
                      smeKnowledgeMessages.somePreviousKnowledgePlaceholder,
                    ),
                  },
                  {
                    label: formatMessage(
                      smeKnowledgeMessages.goodPreviousKnowledge,
                    ),
                    value: formatMessage(
                      smeKnowledgeMessages.goodPreviousKnowledgePlaceholder,
                    ),
                  },
                ]}
                placeholder={formatMessage(
                  smeNeedsMessages.situationTemplateEditorPlaceholder,
                )}
                setValue={_onChange.notes}
                staticText
                value={formValues.notes}
              />
            </TextGrid>
          </Subsection>
        </>
      )}
    </>
  );
};
