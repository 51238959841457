import { Logo } from '@frontend/ui';
import { NavLink } from 'components/Links/NavLink';
import React from 'react';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
  align-self: center;
  display: inline-block;
`;

const LogoHolder = styled.div`
  max-height: calc(4.5rem - 2px);

  svg {
    display: block;
  }
`;

export const PortalLogo: React.FC = () => (
  <StyledNavLink to="/">
    <LogoHolder>
      <Logo width={130} height={26} />
    </LogoHolder>
  </StyledNavLink>
);
