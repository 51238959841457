import { membershipsQuery } from 'app/apollo/graphql/types';

export const updateMembershipsQuery = (
  previousResult: membershipsQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: membershipsQuery;
  },
) => {
  if (!previousResult?.memberships || !fetchMoreResult?.memberships) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.memberships;

  return {
    memberships: {
      __typename: previousResult.memberships.__typename,
      edges: [...edges],
      pageInfo,
    },
  };
};
