import { CheckboxGroupOption, RadioGroupOption } from '@frontend/ui';
import {
  ProposalSummaryFormat,
  updateProposalQuery_Query as Data,
} from 'app/apollo/graphql/types';
import { IntlShape } from 'components/formats';
import { smeSummaryMessages } from 'features/sme/messages/sme';

import { OnChange } from '../../contexts/autosave';

export interface Checks {
  checkDeductionSpace?: boolean;
  checkExistingCoverage?: boolean;
  checkFinancialSituation?: boolean;
  checkNeed?: boolean;
  checkPlannedDevelopment?: boolean;
}

interface FormValues {
  checks: Checks;
  text: string;
  format?: ProposalSummaryFormat;
}

export const getFormValues = (data?: Data): FormValues => ({
  format:
    data?.proposal?.summary?.format != null
      ? data.proposal.summary.format
      : undefined,
  checks: {
    checkDeductionSpace: !!data?.proposal?.summary?.checkDeductionSpace,
    checkExistingCoverage: !!data?.proposal?.summary?.checkExistingCoverage,
    checkFinancialSituation: !!data?.proposal?.summary?.checkFinancialSituation,
    checkNeed: !!data?.proposal?.summary?.checkNeed,
    checkPlannedDevelopment: !!data?.proposal?.summary?.checkPlannedDevelopment,
  },
  text: data?.proposal?.summary?.text ?? '',
});

export const getOnChangeHandlers = (onChange: OnChange) => ({
  checks: (option: CheckboxGroupOption, checked: boolean) =>
    onChange({
      cacheValue: {
        summary: existingSummary =>
          existingSummary
            ? {
                ...existingSummary,
                [option.value]: checked,
              }
            : null,
      },
      mutationInput: {
        summary: {
          [option.value]: checked,
        },
      },
    }),
  format: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        summary: existingSummary =>
          existingSummary
            ? {
                ...existingSummary,
                format: ProposalSummaryFormat[option.value],
              }
            : null,
      },
      mutationInput: {
        summary: {
          format: ProposalSummaryFormat[option.value],
        },
      },
    }),
  text: (value: string) =>
    onChange({
      cacheValue: {
        summary: existingSummary =>
          existingSummary
            ? {
                ...existingSummary,
                text: value,
              }
            : null,
      },
      mutationInput: {
        summary: {
          text: value,
        },
      },
    }),
});

export const getCheckOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage(smeSummaryMessages.checkNeed),
    value: 'checkNeed',
  },
  {
    label: intl.formatMessage(smeSummaryMessages.checkExistingCoverage),
    value: 'checkExistingCoverage',
  },
  {
    label: intl.formatMessage(smeSummaryMessages.checkPlannedDevelopment),
    value: 'checkPlannedDevelopment',
  },
  {
    label: intl.formatMessage(smeSummaryMessages.checkDeductionSpace),
    value: 'checkDeductionSpace',
  },
  {
    label: intl.formatMessage(smeSummaryMessages.checkFinancialSituation),
    value: 'checkFinancialSituation',
  },
];
