import { SelectField, Table, Td, Th, Tr } from '@frontend/ui';
import { ProposalCompanySize } from 'app/apollo/graphql/types';
import { FormattedMessage, useIntl } from 'components/formats';
import { InlineSelectWrapper as _InlineSelectWrapper } from 'components/InlineSelect';
import { TopLoading } from 'components/TopLoading';
import {
  smeCompanySizeMessages,
  smeEmployeesMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import styled from 'styled-components';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import { AutosaveStatus, useAutosave } from '../../contexts/autosave';
import { getFormValues, getOnChangeHandlers } from './utils';

const InlineSelectWrapper = styled(_InlineSelectWrapper)`
  margin-left: auto;
`;

const SelectTd = styled(Td)`
  padding: 0;
`;

export const EmployeesSettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const { loading, data, onChange, status } = useAutosave();
  const isReadonly = useProposalReadonly();

  if (loading) {
    return <TopLoading />;
  }

  const formValues = getFormValues(data);
  const _onChange = getOnChangeHandlers(onChange);

  const disabled = isReadonly || status === AutosaveStatus.RECONNECTING;

  return (
    <Table size="xsmall">
      <colgroup>
        <col style={{ width: '50%' }} />
        <col style={{ width: '50%' }} />
      </colgroup>
      <tbody>
        <Tr>
          <Th>
            <FormattedMessage {...smeEmployeesMessages.nInsuredInGroup} />
          </Th>
          <SelectTd>
            <InlineSelectWrapper
              nbrOfCharacters={formValues.companySize?.length ?? 3}
            >
              <SelectField
                disabled={disabled}
                fixed
                label={
                  <FormattedMessage {...smeEmployeesMessages.nInsuredInGroup} />
                }
                onChange={e => _onChange.companySize(e.detail.value)}
                options={[
                  ProposalCompanySize.LT_10,
                  ProposalCompanySize.LT_50,
                  ProposalCompanySize.GTE_50,
                  ProposalCompanySize.GTE_250,
                ].map(value => ({
                  label: formatMessage({
                    select: value,
                    messages: smeCompanySizeMessages,
                  }),
                  value,
                }))}
                required
                value={formValues.companySize}
                withoutMargin
              />
            </InlineSelectWrapper>
          </SelectTd>
        </Tr>
      </tbody>
    </Table>
  );
};
