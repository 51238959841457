import gql from 'graphql-tag';

export const EDIT_REPRESENTATIVE_MUTATION = gql`
  mutation editRepresentativeMutation(
    $input: UpdateCompanyRepresentativeInput!
  ) {
    updateCompanyRepresentative(input: $input) {
      id
      representatives {
        firstName
        id
        identificationType
        identificationReference
        lastName
        personalIdentityNumber
        role
      }
    }
  }
`;
