import { Td, Tr } from '@frontend/ui';
import { membershipsQuery_memberships_MembershipConnection_edges_MembershipEdge_node_Membership as Membership } from 'app/apollo/graphql/types';
import { companyName } from 'app/utils/company-name';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import React from 'react';
import { useHistory } from 'react-router';

interface Props {
  employee: Membership;
}

export const EmployeeTableRow: React.FC<Props> = ({ employee }) => {
  const { push } = useHistory();

  return (
    <Tr
      linked
      onClick={() => {
        push(
          `/companies/${employee.company.id}/employees/${employee.userAccountId}`,
        );
      }}
    >
      <Td align="left">{employee.userAccountId}</Td>
      <Td type="number" align="left">
        <FormattedNaturalPersonIdentifier
          nids={employee.naturalPersonIdentifiers}
        />
      </Td>
      <Td>{employee.givenName}</Td>
      <Td>{employee.lastName}</Td>
      <Td>{companyName(employee.company)}</Td>
    </Tr>
  );
};
