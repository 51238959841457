import { EmployeeHistoryEventSource } from 'app/apollo/graphql/types';
import { membershipEventHistoryMessages } from 'app/messages/employees';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

interface Props {
  source: EmployeeHistoryEventSource;
}

export const EventSource: React.FC<Props> = ({ source }) =>
  source === EmployeeHistoryEventSource.IMPORT ? (
    <FormattedMessage {...membershipEventHistoryMessages.fileImport} />
  ) : EmployeeHistoryEventSource.PORTAL ? (
    <FormattedMessage {...membershipEventHistoryMessages.portal} />
  ) : (
    <NoValue />
  );
