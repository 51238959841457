import { SelectField } from '@frontend/formik';
import { Grid, Subsection, SubsectionHeader } from '@frontend/ui';
import { FormattedMessage, useIntl } from 'components/formats';
import { GridCell33 } from 'components/GridCell';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import React from 'react';

import { useProposalReadonly } from '../../../../utils/use-proposal-readonly';
import { SALARY_MULTIPLIER_OPTIONS } from '../utils/constants';

interface Props {
  name: string;
}

export const SalaryMultiplerSection: React.FC<Props> = ({ name }) => {
  const { formatNumber } = useIntl();
  const disabled = useProposalReadonly();
  return (
    <Subsection>
      <Grid>
        <GridCell33>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.salaryMultiplier} />
          </SubsectionHeader>
          <SelectField
            name={`${name}.salaryMultiplier`}
            dense
            label={
              <FormattedMessage {...smeBenefitFormMessages.salaryMultiplier} />
            }
            options={SALARY_MULTIPLIER_OPTIONS.map(_value => ({
              label: formatNumber(_value, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }),
              value: _value,
            }))}
            required
            gridMargin
            disabled={disabled}
          />
        </GridCell33>
      </Grid>
    </Subsection>
  );
};
