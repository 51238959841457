import { totalCompensationDetailsQuery_membership_Membership_totalCompensation_TotalCompensationItem2 as TotalCompensationItem } from 'app/apollo/graphql/types';
import BigNumber from 'bignumber.js';

import { TOTAL_COMP_ID_SORT_ORDER } from './total-comp-sort-order';

export const groupTotalCompByCategory = (
  totalCompensation: readonly TotalCompensationItem[],
) => {
  const totalCompItems = totalCompensation
    .map(item => ({
      id: item.key,
      category: item.category,
      value:
        item.annualCompensation &&
        new BigNumber(item.annualCompensation).dividedBy(12).toNumber(),
    }))
    .sort(
      (a, b) =>
        TOTAL_COMP_ID_SORT_ORDER.indexOf(a.id) -
        TOTAL_COMP_ID_SORT_ORDER.indexOf(b.id),
    );

  return totalCompItems.reduce((acc, current) => {
    (acc[current.category] = acc[current.category] || []).push(current);
    return acc;
  }, {});
};
