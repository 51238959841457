import { employeeFormMessages } from 'app/messages/employees';
import { formMessages } from 'app/messages/form';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { LinkButton } from 'components/LinkButton';
import { MatchParams } from 'features/companies/company/employees';
import React from 'react';
import { useRouteMatch } from 'react-router';

export const EmployeesEmptyState: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();

  return (
    <EmptyState
      title={<FormattedMessage {...formMessages.adminNoEmployee} />}
      actions={
        <LinkButton to={`/companies/${params.companyId}/employees/add`}>
          <FormattedMessage {...employeeFormMessages.addEmployee} />
        </LinkButton>
      }
    >
      <FormattedMessage {...formMessages.adminNoEmployeeText} />
    </EmptyState>
  );
};
