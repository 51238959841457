import gql from 'graphql-tag';

export const employmentActivityDetails = gql`
  fragment employmentActivityDetails on EmploymentActivity {
    id
    type
    period
    rate
  }
`;
