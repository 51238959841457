import { InlineSelect } from 'components/InlineSelect';
import React from 'react';

interface Option {
  label: string;
  selected: boolean;
  value: string;
}

interface Props {
  onChange: ((event: React.ChangeEvent<HTMLSelectElement>) => void) | undefined;
  options: Option[];
}

export const InlineMenu: React.FC<Props> = ({ options, onChange }) => (
  <InlineSelect onChange={onChange}>
    {options.map((option, index) => (
      <option
        key={index}
        label={option.label}
        value={option.value}
        selected={option.selected}
        aria-selected={option.selected}
      >
        {option.label}
      </option>
    ))}
  </InlineSelect>
);
