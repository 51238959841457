import { Table, Td, Th, Tr } from '@frontend/ui';
import { costReportingMessages } from 'app/messages/cost-reporting';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

interface Entry {
  from: string;
  id: string;
  name: string;
}

interface Props {
  dimensionName: string;
  entries: Entry[];
}

export const AccountingDimensionTable: React.FC<Props> = ({
  dimensionName,
  entries,
}) => (
  <Table size="xsmall">
    <colgroup>
      <col style={{ width: '50%' }} />
      <col style={{ width: '50%' }} />
    </colgroup>
    <thead>
      <Tr>
        <Th>{dimensionName}</Th>
        <Th>
          <FormattedMessage
            {...costReportingMessages.updateEmployeeObjectDateLabel}
          />
        </Th>
      </Tr>
    </thead>
    <tbody>
      {entries.map(({ id, name, from }) => (
        <Tr key={`${id}-${from}`}>
          <Td>{name}</Td>
          <Td>{from}</Td>
        </Tr>
      ))}
      {!entries.length && (
        <Tr key="no-value">
          <Td>
            <NoValue />
          </Td>
          <Td>
            <NoValue />
          </Td>
        </Tr>
      )}
    </tbody>
  </Table>
);
