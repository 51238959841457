import { useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import { toMoney } from '@frontend/utils';
import {
  AddCustomItemInput,
  addCustomItemMutation,
  addCustomItemMutationVariables,
} from 'app/apollo/graphql/types';
import { workInformationMessages } from 'app/messages/employees';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useRouteMatch } from 'react-router';
import { monthToDate } from 'validations';

import { EMPLOYEE_OVERVIEW_NOTIFICATIONS_QUERY } from '../../notifications/graphql/queries';
import { ADD_CUSTOM_ITEM_MUTATION } from '../graphql/mutations';
import { MEMBERSHIP_REMUNERATIONS_QUERY } from '../graphql/queries';
import { EditEmployeeRemunerationFormValues } from './';

type Submit = (
  onRequestClose: () => void,
) => (
  values: EditEmployeeRemunerationFormValues,
  helpers: FormikHelpers<EditEmployeeRemunerationFormValues>,
) => Promise<void>;

export const useSubmit = () => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const [addRemuneration, { error }] = useMutation<
    addCustomItemMutation,
    addCustomItemMutationVariables
  >(ADD_CUSTOM_ITEM_MUTATION, {
    errorPolicy: 'all',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: MEMBERSHIP_REMUNERATIONS_QUERY,
        variables: {
          userAccountId,
          companyId,
          // The 'employmentId' is not used in the query since we pass
          // 'hasEmploymentId: false',  but it is required as input,
          // otherwise apollo will fail on this query
          employmentId: '',
          hasEmploymentId: false,
        },
      },
      {
        query: EMPLOYEE_OVERVIEW_NOTIFICATIONS_QUERY,
        variables: {
          userAccountId,
          companyId,
          benefitTypeNames: ['euro_accident_plansjuk'],
        },
      },
    ],
  });

  const submit: Submit = onRequestClose => async values => {
    try {
      const { payrollElementId: itemTypeId, value, effectiveDate } = values;

      if (!itemTypeId) {
        throw new Error();
      }

      const input: AddCustomItemInput = {
        userAccountId,
        companyId,
        itemTypeId,
        value: toMoney(value),
        from: monthToDate(effectiveDate),
      };

      const res = await addRemuneration({ variables: { input } });
      if (!res.data?.addCustomItem) {
        throw new Error();
      }
      send({
        message: formatMessage(workInformationMessages.editRemunerationSuccess),
        type: 'success',
      });
      onRequestClose();
    } catch {
      // Do nothing
    }
  };
  return { submit, error };
};
