import { ContentContainer, Section } from '@frontend/ui';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { SalaryExchangeReports } from 'features/companies/company/salary-exchanges/reports';
import { SalaryExchangeReportsIntro } from 'features/companies/company/salary-exchanges/reports/intro';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { RouteComponentProps } from 'react-router';

export const Reports: React.FC<RouteComponentProps> = props => (
  <Suspense fallback={<TopLoading />} batch>
    {({ errors }) => (
      <ContentContainer>
        <Section>
          {errors && <GraphQlErrors errors={errors} />}
          <SalaryExchangeReportsIntro />
          <SalaryExchangeReports {...props} />
        </Section>
      </ContentContainer>
    )}
  </Suspense>
);
