import { Subsection, SubsectionHeader } from '@frontend/ui';
import {
  smeCompanyBenefitsQuery,
  smeCompanyBenefitsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import {
  smeBenefitsMessages,
  smeBenefitTypesMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { useDelete } from './delete';
import { Details } from './Details';
import { SME_COMPANY_BENEFITS_QUERY } from './graphql/queries';
import { BenefitsTable } from './Table';

const ErrorWrapper = styled.div`
  margin-top: 1rem;
`;

const getBenefits = (data?: smeCompanyBenefitsQuery) =>
  data?.proposal?.benefits.length ? data.proposal.benefits : null;

const isEmpty = (data?: smeCompanyBenefitsQuery) => !getBenefits(data);

export const Benefits: React.FC = () => {
  const { params } = useRouteMatch<ProposalMatchParams>();
  const { push } = useHistory();

  const { formatMessage } = useIntl();
  const { _delete, deleteLoading, deleteError } = useDelete({ params });

  const { data } = useQuery<
    smeCompanyBenefitsQuery,
    smeCompanyBenefitsQueryVariables
  >(SME_COMPANY_BENEFITS_QUERY, {
    errorPolicy: 'all',
    isEmpty,
    suspend: true,
    variables: { id: params.proposalId },
  });

  const benefits = getBenefits(data);

  if (!benefits) {
    return null;
  }

  return (
    <>
      {deleteLoading && <TopLoading />}
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...smeBenefitsMessages.allBenefits} />
        </SubsectionHeader>
        <DescriptionWrapper>
          <FormattedMessage {...smeBenefitsMessages.allBenefitsDescription} />
        </DescriptionWrapper>
        <BenefitsTable
          benefits={benefits.map(benefit => ({
            id: benefit.id,
            name: formatMessage({
              select: benefit.type,
              messages: smeBenefitTypesMessages,
            }),
            benefitPackages: benefit.benefitPackages.map(({ name }) => name),
            details: <Details benefit={benefit} />,
            type: benefit.type,
          }))}
          onDelete={id => _delete({ id, proposalId: params.proposalId })}
          onEdit={id =>
            push(
              `/sme/${params.companyId}/proposals/${params.proposalId}/benefits/${id}`,
            )
          }
        />
        {deleteError && (
          <ErrorWrapper>
            <GraphQlError error={deleteError} />
          </ErrorWrapper>
        )}
      </Subsection>
    </>
  );
};
