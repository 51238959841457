import { InvoiceSupplier } from 'app/apollo/graphql/types';
import { accountPlanMessages } from 'app/messages/account-plan';
import { UnitCodeOptions } from 'app/utils/constants';
import { MessageDescriptor } from 'react-intl';

export const INVOICE_SUPPLIER: Record<InvoiceSupplier, string> = {
  ACTIWAY: 'Actiway',
  ADVINANS: 'Nordea Node',
  EDENRED: 'Edenred',
  EURO_ACCIDENT: 'Euro Accident',
  FUTUR: 'Futur Pension',
  LF: 'Länsförsäkringar',
  NORDNET: 'Nordnet',
  SL: 'SL',
  NLP: 'Nordea',
};

export const unitCodeMessages: Record<UnitCodeOptions, MessageDescriptor> = {
  [UnitCodeOptions.MON]: accountPlanMessages.monthly,
  [UnitCodeOptions.ANN]: accountPlanMessages.annually,
  [UnitCodeOptions.LS]: accountPlanMessages.lumpSum,
};

export const MONTHLY_SALARY_REMUNERATION_TYPE_ID = '11100';
