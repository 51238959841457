import type { ThemeColor } from '@frontend/ui/theme';
import { TotalCompensationId2 as TotalCompensationId } from 'app/apollo/graphql/types';

export const TOTAL_COMP_LEGEND_COLOR: Record<
  TotalCompensationId,
  ThemeColor | undefined
> = {
  NET_SALARY: 'primary',
  CASH_SALARY: 'primary',
  INCOME_TAX: 'primary',
  PENSION: 'darkLavender',
  SALARY_EXCHANGE: 'brightLavender',
  COMPANY_CAR: 'redCopper',
  LUNCH_ALLOWANCE: 'redCopper',
  WELLNESS: 'redCopper',
  BTP1: 'darkLavender',
  ITPK: 'darkLavender',
  ITP: 'darkLavender',
  ITP1: 'darkLavender',
  KAPKL: 'darkLavender',
  AKAPKL: 'darkLavender',
  AKAPKR: 'darkLavender',
  SAFLO: 'darkLavender',
  PAKFS09: 'darkLavender',
  EMPLOYERS_CONTRIBUTIONS: undefined,
  SPECIAL_PAYROLL_TAX: undefined,
  GROSS_SALARY: 'primary',
  SALARY_EXCHANGE_DEDUCTION: 'primary',
  VARIABLE_SALARY: 'blau',
};
