/* eslint-disable */
import React from 'react';

interface Option {
  onClick: (input: any) => void;
  children?: any;
  value?: string;
  className?: string;
  activeClassName?: string;
  active?: boolean;
  disabled?: boolean;
  title?: string;
}

export const Option: React.FC<Option> = ({
  children,
  active,
  disabled,
  title,
  value,
  onClick,
}) => (
  <div
    className={`rdw-option-wrapper${active ? ' rdw-option-active' : ''}${
      disabled ? ' rdw-option-disabled' : ''
    }`}
    onClick={() => !disabled && onClick(value)}
    title={title}
    role="button"
  >
    {children}
  </div>
);
