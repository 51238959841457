import { SelectField } from '@frontend/formik';
import { Section, SectionHeader } from '@frontend/ui';
import { formOrgUnitsOptions } from '@frontend/utils';
import {
  organizationFieldsQuery,
  organizationFieldsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { workInformationMessages } from 'app/messages/employees';
import { MatchParams } from 'app/pages/companies/company/employees/employee/add';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { ORGANIZATION_FIELDS_QUERY } from './graphql/queries';

export interface OrganizationFormValues {
  costCenterId?: string;
  organizationalUnitId?: string;
}

export const OrganizationFields: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    organizationFieldsQuery,
    organizationFieldsQueryVariables
  >(ORGANIZATION_FIELDS_QUERY, {
    variables: { companyId: params.companyId },
    errorPolicy: 'all',
  });

  if (loading) {
    return null;
  }

  if (!data?.company?.costCenters || !data?.company?.organizationalUnits) {
    return (
      <Section>
        <SectionHeader>
          <FormattedMessage {...commonMessages.organization} />
        </SectionHeader>
        <GraphQlError error={error} />
      </Section>
    );
  }

  const {
    company: { costCenters, organizationalUnits },
  } = data;

  const organizationalUnitOptions = formOrgUnitsOptions(
    organizationalUnits.edges.map(e => e.node),
  );

  const costCenterOptions = costCenters.edges
    .map(e => e.node)
    .map(p => ({
      value: p.id,
      label: p.name,
    }));

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonMessages.organization} />
      </SectionHeader>
      {error && <GraphQlError error={error} />}
      <SelectField
        dense
        name="organizationalUnitId"
        label={<FormattedMessage {...commonMessages.organizationalUnit} />}
        options={organizationalUnitOptions}
      />
      <SelectField
        dense
        name="costCenterId"
        label={
          <FormattedMessage {...workInformationMessages.costCenterLabel} />
        }
        options={costCenterOptions}
      />
    </Section>
  );
};
