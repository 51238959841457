import { ContentContainer } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Edit } from 'features/companies/company/job-offers/edit';
import { jobOfferMessages } from 'features/companies/company/job-offers/messages';
import { Page } from 'features/page';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams } from '../..';

export interface JobOffersEditRouteMatchParams extends MatchParams {
  jobOfferId: string;
}

export const EditRoute: React.FC<
  RouteComponentProps<JobOffersEditRouteMatchParams>
> = ({ match: { params, url } }) => (
  <NavigationAnchor title={jobOfferMessages.updateJobOffer} url={url}>
    <Page
      parentLink={`/companies/${params.companyId}/job-offers`}
      title={<FormattedMessage {...jobOfferMessages.updateJobOffer} />}
    >
      <Suspense batch fallback={<TopLoading />}>
        {({ errors }) => (
          <ContentContainer>
            {errors && <GraphQlErrors errors={errors} />}
            <Edit />
          </ContentContainer>
        )}
      </Suspense>
    </Page>
  </NavigationAnchor>
);
