import { companyInvoicesQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: companyInvoicesQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: companyInvoicesQuery;
  },
) => {
  if (
    !previousResult ||
    !previousResult.company ||
    !previousResult.company.invoices ||
    !fetchMoreResult ||
    !fetchMoreResult.company ||
    !fetchMoreResult.company.invoices
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.invoices;

  return {
    company: {
      ...previousResult.company,
      invoices: {
        pageInfo,
        __typename: previousResult.company.invoices.__typename,
        edges: [...edges],
      },
    },
  };
};
