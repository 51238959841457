import { getBackstageCsrfTokenFromCookie as getCsrfTokenFromCookie } from 'app/helper';
import axios from 'axios';

const IMPERSONATE_URL = `${window.env.API}/impersonate`;

interface Args {
  userAccountId: string;
}

/**
 * Calls the impersonate endpoint to impersonate a user for a given
 * userAccountId, and then redirects to the app.
 */
export const impersonate = (args: Args): Promise<void | Window | null> => {
  const { userAccountId } = args;

  return axios
    .post(IMPERSONATE_URL, JSON.stringify({ userAccountId }), {
      method: 'POST',
      withCredentials: true,
      headers: {
        'X-Csrf-Token': getCsrfTokenFromCookie(),
        'Content-Type': 'application/json',
      },
    })
    .then(() =>
      window.open(`https://${window.env.APP_HOST}/app`, '_blank', 'noreferrer'),
    );
};
