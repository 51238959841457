import { Button } from '@frontend/ui';
import {
  FlexSalaryExchangeRequestStatus,
  FlexSalaryExchangeStatus,
  violatedSalaryExchangesBannerQuery,
  violatedSalaryExchangesBannerQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { scrollTo } from 'components/LinkScrollTo';
import { NotificationCard } from 'components/NotificationCard';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router';

import { SX_TABLE_ID } from '../components/Table';
import { companySalaryExchangeMessages } from '../messages';
import { UPCOMING_SX_TABLE_ID } from '../upcoming/components/Table';
import { VIOLATED_SALARY_EXCHANGES_BANNER_QUERY } from './graphql/queries';

export const ViolatedSalaryExchangesNotification: React.FC<
  RouteComponentProps
> = ({ location, history: { push } }) => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data } = useQuery<
    violatedSalaryExchangesBannerQuery,
    violatedSalaryExchangesBannerQueryVariables
  >(VIOLATED_SALARY_EXCHANGES_BANNER_QUERY, {
    variables: {
      companyId,
      statuses: [FlexSalaryExchangeStatus.ACTIVE],
      requestStatuses: [FlexSalaryExchangeRequestStatus.APPROVED],
      onlyViolated: true,
    },
    suspend: true,
    errorPolicy: 'all',
  });

  const activeCount = data?.salaryExchanges.totalCount;
  const upcomingCount = data?.salaryExchanges.totalCount;

  if (!activeCount && !upcomingCount) {
    return null;
  }

  return (
    <NotificationCard
      type="warning"
      title={
        <FormattedMessage
          {...companySalaryExchangeMessages.reviewSalaryExchangeDesc}
        />
      }
      actions={
        <Button
          onClick={() => {
            push({
              ...location,
              search: qs.stringify(
                activeCount
                  ? { violated: true }
                  : { 'upcoming-violated': true },
              ),
            });
            scrollTo(`#${activeCount ? SX_TABLE_ID : UPCOMING_SX_TABLE_ID}`);
          }}
          text
        >
          <FormattedMessage {...commonMessages.review} />
        </Button>
      }
    />
  );
};
