import { Section, Subsection, SubsectionHeader } from '@frontend/ui';
import { commonBenefitMessages } from 'app/messages/benefits';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { RenameBenefitPackageModal } from 'features/companies/company/benefit-packages/rename';
import { BenefitPackageSettings } from 'features/companies/company/benefit-packages/settings';
import { Benefits } from 'features/companies/company/benefits';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const BenefitPackageOverviewRoute: React.FC = () => (
  <Suspense fallback={<TopLoading />} batch>
    {({ errors }) => (
      <>
        <Section>
          {errors && <GraphQlErrors errors={errors} />}
          <Subsection>
            <Benefits suspend />
          </Subsection>
          <SubsectionHeader>
            <FormattedMessage
              {...commonBenefitMessages.benefitPackageSettings}
            />
          </SubsectionHeader>
          <Subsection>
            <BenefitPackageSettings />
          </Subsection>
        </Section>
        <RenameBenefitPackageModal />
      </>
    )}
  </Suspense>
);
