import { ApolloError } from '@apollo/client';
import { CheckboxGroupField, Form, RadioGroupField } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { ProposalLifeLevel } from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { NotificationCard } from 'components/NotificationCard';
import {
  smeBenefitFormMessages,
  smeBenefitLifeLevelMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../../../utils/use-proposal-readonly';
import { LIFE_LEVELS } from '../utils/constants';

export interface FormValues {
  benefitPackageIds: string[];
  level: ProposalLifeLevel | '';
}

interface BenefitPackage {
  id: string;
  name: string;
}

interface Props {
  isSubmitting: boolean;
  isValid: boolean;
  benefitPackages?: readonly BenefitPackage[] | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    level: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const LifeForm: React.FC<Props> = ({
  benefitPackages,
  isSubmitting,
  isValid,
  submissionError,
}) => {
  const { formatMessage } = useIntl();
  const disabled = useProposalReadonly();

  return (
    <Form>
      <Section>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.level} />
          </SubsectionHeader>
          <RadioGroupField
            name="level"
            options={LIFE_LEVELS.map(level => ({
              label: formatMessage({
                select: level,
                messages: smeBenefitLifeLevelMessages,
              }),
              value: level,
            }))}
            disabled={disabled}
          />
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.benefitPackages} />
          </SubsectionHeader>
          {benefitPackages?.length ? (
            <>
              <DescriptionWrapper>
                <FormattedMessage
                  {...smeBenefitFormMessages.benefitPackagesDescription}
                />
              </DescriptionWrapper>

              <CheckboxGroupField
                name="benefitPackageIds"
                options={benefitPackages.map(benefitPackage => ({
                  label: benefitPackage.name,
                  value: benefitPackage.id,
                }))}
                disabled={disabled}
              />
            </>
          ) : (
            <NotificationCard type="warning">
              <FormattedMessage {...commonBenefitMessages.noBenefitPackages} />
            </NotificationCard>
          )}
        </Subsection>
        <Subsection>
          <NotificationCard type="warning">
            <FormattedMessage
              {...smeBenefitFormMessages.lifeInsuranceDisclaimer}
            />
          </NotificationCard>
        </Subsection>
        {submissionError && <GraphQlError error={submissionError} />}
        <ButtonLayout>
          <Button
            loading={isSubmitting}
            filled
            type="submit"
            disabled={disabled || !isValid}
          >
            <FormattedMessage {...smeBenefitFormMessages.save} />
          </Button>
        </ButtonLayout>
      </Section>
    </Form>
  );
};
