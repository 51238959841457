import { useMutation } from '@apollo/client';
import {
  createOrganizationalUnitMutation,
  createOrganizationalUnitMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { CREATE_ORGANIZATIONAL_UNIT_MUTATION } from '../../graphql/mutations';
import { ORGANIZATIONAL_UNITS_QUERY } from '../../graphql/queries';
import { orgUnitMessages } from '../../messages';
import { FormValues } from '../';

interface Args {
  companyId: string;
  onRequestClose: () => void;
}

export const useSubmit = (args: Args) => {
  const { companyId, onRequestClose } = args;
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [create, { error }] = useMutation<
    createOrganizationalUnitMutation,
    createOrganizationalUnitMutationVariables
  >(CREATE_ORGANIZATIONAL_UNIT_MUTATION, {
    refetchQueries: [
      {
        query: ORGANIZATIONAL_UNITS_QUERY,
        variables: { companyId },
      },
    ],
  });

  const submit = async ({ unitOf, ...formValues }: FormValues) => {
    try {
      const res = await create({
        variables: {
          input: {
            unitOf: unitOf === 'NO_ORG_UNIT' ? undefined : unitOf,
            ...formValues,
          },
        },
      });
      if (!res?.data?.createOrganizationalUnit) {
        throw new Error();
      }
      send({
        type: 'success',
        message: formatMessage(orgUnitMessages.createOrgUnitSuccess),
      });

      onRequestClose();
    } catch (err) {
      // Do nothing, error is handled by apollo hook
    }
  };

  return {
    submit,
    error,
  };
};
