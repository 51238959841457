import React from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  Link as RouterLink,
  LinkProps,
} from 'react-router-dom';

import { useSafeOnClick } from '../utils/use-safe-on-click';

/**
 * Wrapper around react-router-dom's Link component that prompts the user
 * with a confirmation dialog if they try to navigate away from a page with unsaved changes.
 */
export const Link: React.FC<LinkProps> = ({ children, ...props }) => {
  const { onClick } = useSafeOnClick(props.to);

  return (
    <RouterLink {...props} onClick={onClick}>
      {children}
    </RouterLink>
  );
};
