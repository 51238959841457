import {
  Button,
  Section,
  SectionHeader,
  Table,
  Td,
  Th,
  Tr,
} from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import { toEffectiveThrough } from '@frontend/utils';
import {
  membershipEmploymentsQuery,
  membershipEmploymentsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employmentMessages } from 'app/messages/employees';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, FormattedPercent } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { NoValue } from 'components/NoValue';
import { TopLoading } from 'components/TopLoading';
import React, { useState } from 'react';
import { FormattedDate } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';

import { AddEmploymentModal } from './add';
import { MEMBERSHIP_EMPLOYMENTS_QUERY } from './graphql/queries';

export const Employments: React.FC = () => {
  const {
    url,
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const { push } = useHistory();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { data, loading, error } = useQuery<
    membershipEmploymentsQuery,
    membershipEmploymentsQueryVariables
  >(MEMBERSHIP_EMPLOYMENTS_QUERY, {
    variables: { companyId, userAccountId },
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  const employments = data?.membership?.employments?.edges ?? [];

  return (
    <>
      {error ? <GraphQlError error={error} /> : null}
      <AddEmploymentModal
        isOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false);
        }}
      />
      {employments.length === 0 && (
        <EmptyState
          title={<FormattedMessage {...employmentMessages.noEmployments} />}
          actions={
            <Button
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <FormattedMessage {...employmentMessages.addEmployment} />
            </Button>
          }
        />
      )}
      {employments.length > 0 && (
        <Section>
          <SectionHeader>
            <FormattedMessage {...commonMessages.employments} />
          </SectionHeader>
          <ChipsWrapper>
            <AssistChip
              text={<FormattedMessage {...employmentMessages.addEmployment} />}
              onClick={() => setModalOpen(true)}
              leadingIcon={addCircle}
            />
          </ChipsWrapper>
          <Table>
            <thead>
              <Tr>
                <Th>
                  <FormattedMessage
                    {...employmentMessages.employmentStartDate}
                  />
                </Th>
                <Th>
                  <FormattedMessage {...employmentMessages.employmentEndDate} />
                </Th>
                <Th align="left">
                  <FormattedMessage
                    {...employmentMessages.partTimeEmployment}
                  />
                </Th>
              </Tr>
            </thead>
            <tbody>
              {employments.map(
                ({
                  node: {
                    rate,
                    id,
                    period: [effectiveDate, effectiveUntil],
                  },
                }) => (
                  <Tr
                    key={id}
                    onClick={() => {
                      push(`${url}/${id}`);
                    }}
                    linked
                  >
                    <Td>
                      <FormattedDate value={effectiveDate} />
                    </Td>
                    <Td>
                      {effectiveUntil ? (
                        <FormattedDate
                          value={toEffectiveThrough(effectiveUntil)}
                        />
                      ) : (
                        <NoValue />
                      )}
                    </Td>
                    <Td align="left">
                      <FormattedPercent value={rate} />
                    </Td>
                  </Tr>
                ),
              )}
            </tbody>
          </Table>
        </Section>
      )}
    </>
  );
};
