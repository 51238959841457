import { useMutation } from '@apollo/client';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import {
  updateCompanyDetailsMutation,
  updateCompanyDetailsMutationVariables,
  UpdateCompanyInput,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import { UPDATE_COMPANY_DETAILS_MUTATION } from 'features/companies/graphql/mutations';
import { COMPANY_DETAILS_QUERY } from 'features/companies/graphql/queries';
import React from 'react';

interface Props {
  emailLoginAllowed: boolean | null;
  id: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

export const ConfirmModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  emailLoginAllowed,
  id,
}) => {
  const [updateCompanyDetails, { loading, error }] = useMutation<
    updateCompanyDetailsMutation,
    updateCompanyDetailsMutationVariables
  >(UPDATE_COMPANY_DETAILS_MUTATION);

  const updateLoginMethod = async () => {
    try {
      const input: UpdateCompanyInput = {
        id,
        emailLoginAllowed: !emailLoginAllowed,
      };

      const res = await updateCompanyDetails({
        variables: { input },
        refetchQueries: [{ query: COMPANY_DETAILS_QUERY, variables: { id } }],
        errorPolicy: 'all',
      });

      if (res.errors) {
        throw new Error();
      }

      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return (
    <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader>
        {emailLoginAllowed ? (
          <FormattedMessage {...companyMessages.deactivateEmailLoginHeader} />
        ) : (
          <FormattedMessage {...companyMessages.activateEmailLoginHeader} />
        )}
      </ModalHeader>
      <ModalBody>
        {emailLoginAllowed ? null : (
          <p>
            <FormattedMessage {...companyMessages.activateEmailLoginDesc} />
          </p>
        )}
        {error && <GraphQlError inModal error={error} />}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={onRequestClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button text loading={loading} onClick={() => updateLoginMethod()}>
            {emailLoginAllowed ? (
              <FormattedMessage {...commonMessages.deactivate} />
            ) : (
              <FormattedMessage {...commonMessages.activate} />
            )}
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};
