import { BenefitType } from '@frontend/benefit-types';

/**
 * This array lists the benefit types available for NLP companies
 * and consists of a subset of `BENEFIT_TYPES`.
 */
export const NLP_BENEFIT_TYPES: BenefitType[] = [
  'advinans_occupational_pension',
  // 'nordea-node_employers_pension',
  'advinans_salary_exchange',
  'euro_accident_accident',
  'euro_accident_plansjuk',
  'euro_accident_privataccess',
  'euro_accident_tgl',
  'euro_accident_life',
  'euro_accident_sickness_and_accident',
];
