import { defaultTheme, Table, Td, Th, Tr } from '@frontend/ui';
import { useIsSmallScreen } from 'app/utils/use-is-small-screen';
import React from 'react';
import styled from 'styled-components';

const StyledP = styled.p`
  margin: 0;
  font-weight: normal;
`;

export interface DescriptionTableEntry {
  id: string;
  label: React.ReactNode;
  value: React.ReactNode;
}

interface Props {
  entries: DescriptionTableEntry[];
  alignLeft?: boolean;
  multiline?: boolean;
}

export const DescriptionTable: React.FC<Props> = ({
  entries,
  alignLeft,
  multiline,
}) => {
  const isSmallScreen = useIsSmallScreen(defaultTheme.breakpoints.tablet * 16);
  return (
    <Table size="xsmall">
      <colgroup>
        <col style={{ width: '50%' }} />
        <col style={{ width: '50%' }} />
      </colgroup>
      <tbody>
        {entries.map(entry => (
          <Tr key={entry.id}>
            {isSmallScreen ? (
              <Th>
                {entry.label}
                <StyledP>{entry.value}</StyledP>
              </Th>
            ) : (
              <>
                <Th>{entry.label}</Th>
                <Td align={alignLeft ? 'left' : 'right'} multiline={multiline}>
                  {entry.value}
                </Td>
              </>
            )}
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};
