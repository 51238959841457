import { ApolloError, useMutation } from '@apollo/client';
import { assert, toMoney } from '@frontend/utils';
import {
  createSinglePremiumMutation,
  createSinglePremiumMutationVariables,
} from 'app/apollo/graphql/types';
import { pensionMessages } from 'app/messages/pension';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { SINGLE_PREMIUM_DEPOSIT_HISTORY_TABLE_QUERY } from '../single-premium-history/graphql/queries';
import { FormValues } from './';
import { CREATE_SINGLE_PREMIUM_MUTATION } from './graphql/mutations';

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const intl = useIntl();
  const { send } = useNotification();
  const { push } = useHistory();

  const [singlePremiumDeposit, { error: submissionError }] = useMutation<
    createSinglePremiumMutation,
    createSinglePremiumMutationVariables
  >(CREATE_SINGLE_PREMIUM_MUTATION);

  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const submit = async (values: FormValues) => {
    const { insuranceNumber, insuranceInstituteId, premium } = values;

    try {
      assert(premium);
      assert(insuranceNumber);
      const sanitizedPremium = toMoney(premium);

      if (sanitizedPremium == null || !insuranceInstituteId) {
        throw new Error();
      }

      await singlePremiumDeposit({
        variables: {
          input: {
            insuranceNumber,
            insuranceInstituteId,
            premium: sanitizedPremium,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: SINGLE_PREMIUM_DEPOSIT_HISTORY_TABLE_QUERY,
            variables: {
              userAccountId,
              companyId,
            },
          },
        ],
      });
      send({
        message: intl.formatMessage(
          pensionMessages.createSinglePremiumDepositSuccess,
        ),
        type: 'success',
      });

      push(`/companies/${companyId}/employees/${userAccountId}/occupational`);
    } catch (error) {
      // do nothing
    }
  };

  return { submit, submissionError };
};
