import { FcfwCheckboxGroupStatus, SimpleMembership } from './constants';

export const getFcfwSelectStatus = (args: {
  employees: SimpleMembership[];
  includedEmployees: Record<string, boolean>;
}): FcfwCheckboxGroupStatus | null => {
  const { employees, includedEmployees } = args;
  const includedUserAccountIds = Object.entries(includedEmployees).filter(
    ([, included]) => included,
  );

  if (!includedUserAccountIds.length) {
    return null;
  }
  if (employees.length === includedUserAccountIds.length) {
    return FcfwCheckboxGroupStatus.CHECKED;
  }
  return FcfwCheckboxGroupStatus.INDETERMINATE;
};
