import { Dot, Table as TableUI, Td, Tfoot, Th, Tr } from '@frontend/ui';
import {
  TotalCompensationCategory2 as TotalCompensationCategory,
  TotalCompensationId2 as TotalCompensationId,
} from 'app/apollo/graphql/types';
import {
  totalCompensationDescriptionMessages,
  totalCompensationMessages,
  totalCompensationTitleMessages,
} from 'app/messages/total-compensation';
import { DEFAULT_EMPLOYER_CONTRIBUTIONS_SHARE } from 'app/utils/constants';
import {
  FormattedCurrency,
  FormattedMessage,
  FormattedPercent,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import styled from 'styled-components';

import { TOTAL_COMP_LEGEND_COLOR } from '../../utils/total-comp-legend-color';

const StyledSpan = styled.span`
  vertical-align: middle;
`;

export interface TableItem {
  category: TotalCompensationCategory;
  id: TotalCompensationId;
  value: number;
}

interface Props {
  items: TableItem[];
  employerContributionShare?: string | null;
}

export const Table: React.FC<Props> = ({
  items,
  employerContributionShare,
}) => {
  const totalValue = items.map(e => e.value).reduce((a, b) => a + b);

  return (
    <TableUI fixed>
      <colgroup>
        <col style={{ width: '20%' }} />
        <col style={{ width: '60%' }} />
        <col style={{ width: '20%' }} />
      </colgroup>
      <thead>
        <Tr>
          <Th>
            <FormattedMessage {...totalCompensationMessages.title} />
          </Th>
          <Th multiline>
            <FormattedMessage {...totalCompensationMessages.description} />
          </Th>
          <Th type="number">
            <FormattedMessage {...totalCompensationMessages.value} />
          </Th>
        </Tr>
      </thead>
      <tbody>
        {items.map(item => (
          <Tr key={item.id}>
            <Td>
              {TOTAL_COMP_LEGEND_COLOR[item.id] ? (
                <Dot
                  colorValue={TOTAL_COMP_LEGEND_COLOR[item.id]}
                  size={0.75}
                  label={
                    <FormattedMessage
                      messages={totalCompensationTitleMessages}
                      select={item.id}
                    />
                  }
                />
              ) : (
                <FormattedMessage
                  messages={totalCompensationTitleMessages}
                  select={item.id}
                />
              )}
            </Td>
            <Td multiline>
              {item.id === TotalCompensationId.EMPLOYERS_CONTRIBUTIONS ? (
                <FormattedMessage
                  {...totalCompensationMessages.employersContributionsDescription}
                  values={{
                    employerContributionShare: employerContributionShare ? (
                      <FormattedPercent
                        value={parseFloat(employerContributionShare)}
                      />
                    ) : (
                      <FormattedPercent
                        value={DEFAULT_EMPLOYER_CONTRIBUTIONS_SHARE}
                      />
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  messages={totalCompensationDescriptionMessages}
                  select={item.id}
                />
              )}
            </Td>
            <Td type="number">
              {item.value !== null ? (
                <FormattedCurrency value={item.value} noSuffix currency="SEK" />
              ) : (
                <NoValue />
              )}
            </Td>
          </Tr>
        ))}
      </tbody>
      <Tfoot>
        <Tr>
          <Td>
            <StyledSpan>
              <FormattedMessage {...totalCompensationMessages.sum} />
            </StyledSpan>
          </Td>
          <Td />
          <Td type="number">
            {totalValue !== null ? (
              <FormattedCurrency value={totalValue} noSuffix currency="SEK" />
            ) : (
              <NoValue />
            )}
          </Td>
        </Tr>
      </Tfoot>
    </TableUI>
  );
};
