import { AdvinansSalaryExchange } from '@frontend/benefit-types';
import { Formik } from '@frontend/formik';
import { toPercent } from '@frontend/utils';
import {
  companyEditBenefitQuery,
  companyEditBenefitQuery_flexBenefit_FlexBenefit as Benefit,
  companyEditBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import format from 'date-fns/format';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  FormValues,
  SalaryExchangeForm,
  validationSchema,
} from '../../form/salary-exchange';
import { getBenefitPackageOptions } from '../../form/utils';
import { SALARY_EXCHANGE_PROVIDER_FORM_VALUES } from '../../form/utils/constants';
import { COMPANY_EDIT_BENEFIT_QUERY } from '../graphql/queries';
import { useSubmit } from './utils/use-submit';

export const EditSalaryExchange: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    companyEditBenefitQuery,
    companyEditBenefitQueryVariables
  >(COMPANY_EDIT_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, companyId: params.companyId },
  });

  const { submit, submissionError } = useSubmit();

  if (loading) {
    return <TopLoading />;
  }

  if (
    !data?.company?.agreements ||
    !data.company.benefitPackages ||
    !data.company.remunerationTypes ||
    !data.flexBenefit?.configuration?.data ||
    !data.flexBenefit?.entitlementRules
  ) {
    return <EmptyState error={error} />;
  }

  const benefit: AdvinansSalaryExchange<Benefit> = data.flexBenefit;

  const { agreements, benefitPackages, managedBy, remunerationTypes } =
    data.company;

  const benefitPackageOptions = getBenefitPackageOptions(
    'advinans_salary_exchange',
    benefitPackages,
    benefit.id,
  );

  const { configEffectiveDate, configuration, entitlementRules } = benefit;

  if (!configuration?.data || !entitlementRules) {
    return null;
  }

  const effectiveFrom = entitlementRules.from
    ? format(new Date(entitlementRules.from), 'yyyy-MM')
    : '';

  const initialValues: FormValues = {
    automaticApproval: configuration.data.automaticApproval ?? false,
    benefitPackageIds: [...entitlementRules.benefitPackageIds],
    effectiveFrom,
    incomeMinIbb: configuration.data.incomeMinIbb?.toString() ?? '',
    ongoingDeductionShareMax: configuration.data.ongoingDeductionShareMax
      ? toPercent(configuration.data.ongoingDeductionShareMax).toString()
      : '',
    ongoingMax: configuration.data.ongoingMax?.toString() ?? '',
    payrollElementCode:
      configuration.data.basePensionableIncomePayrollElementCode,
    payrollElementMultiplier:
      configuration.data.basePensionableIncomePayrollElementMultiplier,
    pensionTaxBenefitShare: toPercent(
      configuration.data.pensionTaxBenefitShare,
    ).toString(),
    providers: [
      ...new Set(
        configuration.data.providers.map(
          provider => SALARY_EXCHANGE_PROVIDER_FORM_VALUES[provider],
        ),
      ),
    ],
    retirementAge: configuration.data.retirementAge?.toString() ?? '',
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={values => submit(values, benefit)}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      <SalaryExchangeForm
        agreements={agreements}
        benefitPackageOptions={benefitPackageOptions}
        isEdit
        latestChangesEffectiveDate={configEffectiveDate}
        managedBy={managedBy}
        remunerationTypes={remunerationTypes}
        submissionError={submissionError}
      />
    </Formik>
  );
};
