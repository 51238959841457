import { EmptyState } from '@frontend/ui';
import { contactPage } from '@frontend/ui/icons';
import {
  smeCompanyEmployeesQuery,
  smeCompanyEmployeesQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { useDelete } from './employee/delete';
import { EmployeesTable } from './EmployeesTable';
import { SME_COMPANY_EMPLOYEES_QUERY } from './graphql/queries';

const ErrorWrapper = styled.div`
  margin-top: 1rem;
`;

export const Employees: React.FC = () => {
  const { push } = useHistory();
  const match = useRouteMatch<MatchParams>();
  const { params } = match;

  const { _delete, deleteLoading, deleteError } = useDelete({ params });

  const { data } = useQuery<
    smeCompanyEmployeesQuery,
    smeCompanyEmployeesQueryVariables
  >(SME_COMPANY_EMPLOYEES_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: { id: params.proposalId },
  });

  if (!data?.proposal?.memberships) {
    return null;
  }

  const { memberships } = data.proposal;

  if (!memberships.length) {
    return (
      <EmptyState
        title={<FormattedMessage {...smeEmployeesMessages.noEmployeesTitle} />}
        icon={contactPage}
      >
        <FormattedMessage {...smeEmployeesMessages.noEmployeesDescription} />
      </EmptyState>
    );
  }

  return (
    <>
      {deleteLoading && <TopLoading />}
      <EmployeesTable
        employees={memberships}
        onDelete={id => _delete({ id, proposalId: params.proposalId })}
        onEdit={id =>
          push(
            `/sme/${params.companyId}/proposals/${params.proposalId}/employees/${id}/edit`,
          )
        }
        onEmployeeClick={id =>
          push(
            `/sme/${params.companyId}/proposals/${params.proposalId}/employees/${id}`,
          )
        }
      />
      {deleteError && (
        <ErrorWrapper>
          <GraphQlError error={deleteError} />
        </ErrorWrapper>
      )}
    </>
  );
};
