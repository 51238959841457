import gql from 'graphql-tag';

import { organizationalUnitDetails } from './fragments';

export const CREATE_ORGANIZATIONAL_UNIT_MUTATION = gql`
  mutation createOrganizationalUnitMutation(
    $input: CreateOrganizationalUnitInput!
  ) {
    createOrganizationalUnit(input: $input) {
      organizationalUnit {
        ...organizationalUnitDetails
      }
    }
  }
  ${organizationalUnitDetails}
`;

export const UPDATE_ORGANIZATIONAL_UNIT_MUTATION = gql`
  mutation updateOrganizationalUnitMutation(
    $input: UpdateOrganizationalUnitInput!
  ) {
    updateOrganizationalUnit(input: $input) {
      organizationalUnit {
        ...organizationalUnitDetails
      }
    }
  }
  ${organizationalUnitDetails}
`;

export const DELETE_ORGANIZATIONAL_UNIT_MUTATION = gql`
  mutation deleteOrganizationalUnitMutation(
    $input: DeleteOrganizationalUnitInput!
  ) {
    deleteOrganizationalUnit(input: $input) {
      id
    }
  }
`;
