import { ProposalAdviceOption } from 'app/apollo/graphql/types';

/**
 * This array lists the propoal advice options for a new proposal,
 * sorted for display purposes rather than in the alphabetical order of the enum values.
 */
export const PROPOSAL_ADVICE_OPTIONS: ProposalAdviceOption[] = [
  ProposalAdviceOption.ADVICE_REQUIRED,
  ProposalAdviceOption.ADVICE_WAIVED_CUSTOMER_INITIATED,
  ProposalAdviceOption.ADVICE_WAIVED_OWNER,
];
