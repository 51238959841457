import { bodyLargeCSS, labelSmallCSS } from '@frontend/ui';
import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
  ${labelSmallCSS};
`;

const Value = styled.div`
  ${bodyLargeCSS};

  @media print {
    font-size: 14px;
    line-height: 14px;
  }
`;

interface Props {
  label: React.ReactNode;
  value: React.ReactNode;
}

export const ViewField: React.FC<Props> = ({ label, value }) => (
  <>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </>
);
