import gql from 'graphql-tag';

export const START_IMPORT_MUTATION = gql`
  mutation startImportMutation($input: StartImportInput!) {
    startImport(input: $input) {
      import {
        id
      }
    }
  }
`;
