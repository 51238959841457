import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const SME_COMPANY_PROPOSALS_QUERY = gql`
  query smeCompanyProposalsQuery(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $companyId: ID!
    $orderBy: ProposalsSortingArguments
  ) {
    proposals(
      before: $before
      after: $after
      first: $first
      last: $last
      companyId: $companyId
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          createdBy {
            id
            firstName
            lastName
          }
          lastEditedBy {
            id
            firstName
            lastName
          }
          name
          status
          updatedAt
          documents {
            offer {
              name
              url
            }
            otherDocuments {
              name
              url
            }
          }
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${pageInfoDetails}
`;

export const SME_PROPOSAL_READONLY_QUERY = gql`
  query smeProposalReadonlyQuery($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      status
    }
  }
`;
