import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { Modal } from 'components/Modal';
import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

interface ConfirmationModalProps {
  description: string;
  title?: string;
}

interface ConfirmationContextProps {
  /**
   * A thread blocking callback for invoking a confirmation dialog.
   *
   * @param modalProps A description for additional context, along with an optional short, succinct title for the confirmation dialog.
   * @returns A promise that resolves to `true` if the user confirms the action, and `false` if the user cancels the action.
   */
  confirm: (modalProps: ConfirmationModalProps) => Promise<boolean>;
  /**
   * Invoked by the confirmation dialog when the user cancels the action.
   */
  handleCancel: () => void;
  /**
   * Invoked by the confirmation dialog when the user confirms the action.
   */
  handleConfirm: () => void;
  /**
   * The modal title and description. This is set by the `confirm` function.
   */
  modalProps?: ConfirmationModalProps | null;
}

interface Props {
  children: React.ReactNode;
}

const ConfirmationContext = createContext<ConfirmationContextProps>({
  confirm: () => Promise.resolve(false),
  handleCancel: () => undefined,
  handleConfirm: () => undefined,
  modalProps: undefined,
});

export const useConfirm = (): ConfirmationContextProps =>
  useContext(ConfirmationContext);

export const ConfirmationContextProvider: React.FC<Props> = ({ children }) => {
  const [modalProps, setModalProps] = useState<ConfirmationModalProps | null>(
    null,
  );
  const resolveRef = useRef(val => val);

  const confirm = (props: ConfirmationModalProps) => {
    setModalProps(() => props);

    return new Promise<boolean>(resolve => {
      resolveRef.current = resolve;
    });
  };

  const handleCancel = () => {
    if (resolveRef?.current) {
      resolveRef?.current(false);
      setModalProps(() => null);
    }
  };

  const handleConfirm = () => {
    if (resolveRef?.current) {
      resolveRef?.current(true);
      setModalProps(() => null);
    }
  };

  const value = useMemo(
    () => ({
      confirm,
      modalProps,
      handleCancel,
      handleConfirm,
    }),
    [modalProps],
  );

  return (
    <ConfirmationContext.Provider value={value}>
      {children}
    </ConfirmationContext.Provider>
  );
};

/**
 * The confirmation dialog. This component should be rendered at the root of the application.
 */
export const Confirmation = () => {
  const { handleCancel, handleConfirm, modalProps } = useConfirm();

  if (!modalProps) {
    return null;
  }

  const { title, description } = modalProps;

  return (
    <Modal isOpen contentLabel={title} size="small">
      <ModalHeader>{title}</ModalHeader>
      {description && <ModalBody>{description}</ModalBody>}
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={handleCancel}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button text onClick={handleConfirm}>
            <FormattedMessage {...commonMessages.confirm} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};
