import { ContentContainer } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { EmploymentActivities } from 'features/companies/company/employees/employee/employments/employment/activities';
import { EmploymentDetails } from 'features/companies/company/employees/employee/employments/employment/details';
import { MembershipRemunerations } from 'features/companies/company/employees/employee/remunerations';
import { Page } from 'features/page';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { EmployeeRouteMatchParams } from '../..';

export interface EmploymentRouteMatchParams extends EmployeeRouteMatchParams {
  employmentId: string;
}

export const EmploymentRoute: React.FC = () => {
  const {
    url,
    params: { companyId, userAccountId },
  } = useRouteMatch<EmploymentRouteMatchParams>();

  return (
    <NavigationAnchor title={commonMessages.employment} url={url}>
      <Page
        parentLink={`/companies/${companyId}/employees/${userAccountId}/employments`}
        title={<FormattedMessage {...commonMessages.detail} />}
      >
        <Suspense batch fallback={<TopLoading />} emptyState={<EmptyState />}>
          {({ errors }) => (
            <>
              {errors && <GraphQlErrors errors={errors} />}
              <ContentContainer>
                <EmploymentDetails />
                <MembershipRemunerations />
                <EmploymentActivities />
              </ContentContainer>
            </>
          )}
        </Suspense>
      </Page>
    </NavigationAnchor>
  );
};
