import { media } from '@frontend/ui';
import styled from 'styled-components';

export interface StyleWrapperProps {
  height?: string;
  wysiwyg?: boolean;
}

export const StyleWrapper = styled.div<StyleWrapperProps>`
  .rdw-editor-wrapper {
    ${p =>
      !p.wysiwyg &&
      `
        border: 0.0625rem solid ${p.theme.surfaceVariant};
        border-radius: 0.25rem;
        padding: 0.5rem;
    `}
  }
  .rdw-editor-toolbar {
    border: none;
    border-radius: 0.25rem;
    padding: 0.625rem 1rem;
    background-color: ${p => p.theme.surface1};
    & > div {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    .rdw-option-wrapper,
    .rdw-dropdown-wrapper {
      border: none;
      background: none;
      padding: 0;
      &:hover {
        box-shadow: none;
      }
    }
    .rdw-option-active {
      box-shadow: none;
      & > svg path {
        fill: ${p => p.theme.primaryBlueMid};
      }
    }
  }
  .rdw-editor-main {
    overflow: visible;
    margin-top: 1.25rem;
  }
  .DraftEditor-root {
    ${p => p.height && `height: ${p.height}`};
    ${p => !p.wysiwyg && `overflow: auto;`};
  }
  .DraftEditor-editorContainer {
    z-index: 0;
  }
  .public-DraftEditor-content {
    & > div {
      padding-left: 0 !important;
      padding-right: 0 !important;
      & > * {
        width: calc(8 / 12 * 100%);
        ${media.lessThan('desktop')`
          width: 100%;
        `};
      }
      & > figure {
        margin: 0;
      }
    }
    .public-DraftStyleDefault-block {
      margin-top: 0;
      margin-bottom: 0.75rem;
    }
  }
`;
