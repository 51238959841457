import gql from 'graphql-tag';

export const EDIT_PROPOSAL_EMPLOYERS_PENSION_BENEFIT_MUTATION = gql`
  mutation editProposalEmployersPensionBenefitMutation(
    $benefitId: ID!
    $input: UpdateProposalEmployersPensionBenefitInput!
  ) {
    updateProposalEmployersPensionBenefit(input: $input) {
      id
      benefit(id: $benefitId) {
        id
        benefitPackages {
          id
        }
        configuration {
          ... on ProposalEmployersPensionBenefitConfig {
            defaultManagementType
            extraFixedPremium
            premiumCalculationMethod
            premiumDeductions
            premiumWaiver
            premiumMatrix {
              age
              intervals
              baseAmount
              baseAmountType
              salaryMultiplier
            }
            retirementAge
          }
        }
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
