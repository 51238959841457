import { PremiumMatrix } from '@advinans/premium-matrix';

import { PremiumMatrixFormValues } from '../../types';
import { formatPremiumMatrixFormValues } from '../../utils';

export const itpLikePremiumMatrixInitialValues: PremiumMatrixFormValues =
  formatPremiumMatrixFormValues({
    ...PremiumMatrix.ITP_LIKE,
    age: [...PremiumMatrix.ITP_LIKE.age],
    baseAmount: [...PremiumMatrix.ITP_LIKE.baseAmount],
    intervals: PremiumMatrix.ITP_LIKE.intervals.map(interval => [...interval]),
  });

export const itpPremiumMatrixInitialValues: PremiumMatrixFormValues =
  formatPremiumMatrixFormValues({
    ...PremiumMatrix.ITP1,
    age: [...PremiumMatrix.ITP1.age],
    baseAmount: [...PremiumMatrix.ITP1.baseAmount],
    intervals: PremiumMatrix.ITP1.intervals.map(interval => [...interval]),
  });
