import { ContentContainer } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Page } from 'features/page';
import { Edit } from 'features/sme/companies/company/edit';
import { smeCompanyMessages } from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '..';

export const SmeCompanyEditRoute: React.FC = () => {
  const match = useRouteMatch<MatchParams>();
  return (
    <NavigationAnchor
      title={smeCompanyMessages.editOrganisationInformation}
      url={match.url}
    >
      <Page
        title={
          <FormattedMessage
            {...smeCompanyMessages.editOrganisationInformation}
          />
        }
        parentLink={`/sme/${match.params.companyId}`}
      >
        <Suspense fallback={<TopLoading />}>
          {({ errors }) => (
            <>
              {errors && <GraphQlErrors errors={errors} />}
              <ContentContainer>
                <Edit />
              </ContentContainer>
            </>
          )}
        </Suspense>
      </Page>
    </NavigationAnchor>
  );
};
