import { Section, SectionHeader } from '@frontend/ui';
import { upload } from '@frontend/ui/icons';
import {
  sharedFilesQuery,
  sharedFilesQueryVariables,
  SortOrder,
} from 'app/apollo/graphql/types';
import { sharedFileMessages } from 'app/messages/shared-files';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { useTableSort } from 'app/utils/use-table-sort';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams } from '..';
import { SHARED_FILES_QUERY } from './graphql/queries';
import { SharedFilesTable } from './table';
import { UploadModal } from './upload';
import { useDelete } from './use-delete';
import { updateQuery } from './utils/update-query';

const getUploadLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ upload: true }),
});

export const SharedFiles: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { companyId },
  },
  location,
}) => {
  const { per_page } = qs.parse(location.search);

  const { order, orderBy, handleSort } = useTableSort<string>();

  const {
    data: sharedFilesData,
    loading: sharedFilesLoading,
    previousData,
    error,
    fetchMore,
  } = useQuery<sharedFilesQuery, sharedFilesQueryVariables>(
    SHARED_FILES_QUERY,
    {
      variables: {
        companyId,
        first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
        sortOrder: (order as SortOrder | undefined) ?? SortOrder.DESC,
      },
    },
  );

  const _data = sharedFilesData ?? previousData;
  const sharedFiles = _data?.sharedFiles?.edges.map(e => e.node) ?? [];
  const pageInfo = _data?.sharedFiles?.pageInfo ?? {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  };

  const {
    _delete: deleteSharedFile,
    loading: deleteLoading,
    error: deleteError,
  } = useDelete();

  if (!_data && sharedFilesLoading) {
    return <TopLoading />;
  }

  return (
    <>
      <Section>
        <SectionHeader>
          <FormattedMessage {...sharedFileMessages.sharedFiles} />
        </SectionHeader>
        {(deleteLoading || sharedFilesLoading) && <TopLoading />}
        {(error || deleteError) && (
          <GraphQlError error={error ?? deleteError} />
        )}
        <ChipsWrapper>
          <AssistChip
            leadingIcon={upload}
            text={<FormattedMessage {...sharedFileMessages.uploadFile} />}
            to={getUploadLink(location)}
          />
        </ChipsWrapper>
        <SharedFilesTable
          pageInfo={pageInfo}
          onNextPage={() => {
            fetchMore({
              variables: {
                after: pageInfo.endCursor,
              },
              updateQuery,
            });
          }}
          onPreviousPage={() => {
            fetchMore({
              variables: {
                before: pageInfo.startCursor,
                first: undefined,
                last: per_page
                  ? parseInt(per_page, 10)
                  : DEFAULT_RESULT_PER_PAGE,
              },
              updateQuery,
            });
          }}
          sharedFiles={sharedFiles}
          deleteSharedFile={deleteSharedFile}
          sort={{ order, orderBy, handleSort }}
        />
      </Section>
      <UploadModal />
    </>
  );
};
