import gql from 'graphql-tag';

import { organizationalUnitDetails } from './fragments';

export const ORGANIZATIONAL_UNITS_QUERY = gql`
  query organizationalUnitsQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      organizationalUnits {
        edges {
          node {
            ...organizationalUnitDetails
          }
        }
      }
    }
  }
  ${organizationalUnitDetails}
`;

export const ORGANIZATIONAL_UNIT_QUERY = gql`
  query organizationalUnitQuery($id: ID!) {
    organizationalUnit(id: $id) {
      ...organizationalUnitDetails
    }
  }
  ${organizationalUnitDetails}
`;
