import { useEffect, useState } from 'react';

interface Status {
  hide: boolean;
  scrollPos: number;
}

interface Offset {
  offsetValue?: number;
}

const newScrollPos = () => document.body.getBoundingClientRect().top;

const hasScrolledDown = (prevScrollPos: number) =>
  newScrollPos() < prevScrollPos;

const hasPassedOffset = (offset?: Offset) =>
  offset?.offsetValue ? newScrollPos() < -offset.offsetValue : true;

export const useHideOnScroll = (offset?: Offset): boolean => {
  const [status, setStatus] = useState<Status>({ hide: false, scrollPos: 0 });

  const handleScroll = () => {
    if (
      (hasScrolledDown(status.scrollPos) && hasPassedOffset(offset)) ||
      !hasScrolledDown(status.scrollPos)
    ) {
      setStatus(prevStatus => ({
        hide: hasScrolledDown(prevStatus.scrollPos),
        scrollPos: newScrollPos(),
      }));
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [offset]);

  return status.hide;
};
