import { ApolloError } from '@apollo/client';
import {
  Icon,
  Section,
  SectionHeader,
  Table,
  Td,
  Th,
  Tr,
  useComponentSize,
} from '@frontend/ui';
import { _delete, addCircle, edit } from '@frontend/ui/icons';
import {
  alphabeticallyAscending,
  group,
  toEffectiveThrough,
} from '@frontend/utils';
import {
  MembershipRemunerationItemInput,
  membershipRemunerationsQuery_membership_Membership_employment_M2Employment_remuneration_RemunerationItem as Remuneration,
} from 'app/apollo/graphql/types';
import { accountPlanMessages } from 'app/messages/account-plan';
import { commonMessages } from 'app/messages/common';
import { workInformationMessages } from 'app/messages/employees';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { UnitCodeOptions, unitCodeSuffixMessages } from 'app/utils/constants';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { CollapsibleTr, SubTd, SubTr } from 'components/CollapsibleTable';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { Link } from 'components/Links/Link';
import { MenuItem } from 'components/MenuItem';
import { NoValue } from 'components/NoValue';
import { MONTHLY_SALARY_REMUNERATION_TYPE_ID } from 'features/companies/company/utils/constants';
import { useRemunerationTypes } from 'features/companies/company/utils/use-remuneration-types';
import React, { useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { Submit, UpdateRemunerationModal } from '../update';
import { fromFormData } from './utils/from-form-data';
import { byDateDescending } from './utils/sort-remunerations';

const CHEVRON_WIDTH = 2.625;

const isFormikData = (
  input: readonly Remuneration[] | MembershipRemunerationItemInput[],
): input is MembershipRemunerationItemInput[] =>
  !!(input as MembershipRemunerationItemInput[])[0]?.itemTypeId;

const halfRowWidth = ({
  hasChevron,
  rowWidth,
}: {
  hasChevron: boolean;
  rowWidth: number;
}) => rowWidth * 0.5 - (hasChevron ? CHEVRON_WIDTH * 16 : 0);

interface OptionTypes {
  hasDeleteOption?: boolean;
  hasEditOption?: boolean;
}

interface ModalState {
  isOpen: boolean;
  editId?: string;
}

interface Props {
  onSubmit: (onRequestClose: () => void) => Submit;
  remunerations: readonly Remuneration[] | MembershipRemunerationItemInput[];
  deleteItem?: (id: string, from: string) => void;
  disabled?: boolean;
  isAddEmployeeSubform?: boolean;
  onClose?: () => void;
  parentField?: string;
  submissionError?: ApolloError;
}

export const RemunerationsTable: React.FC<Props> = ({
  parentField,
  remunerations: _remunerations,
  onSubmit,
  deleteItem,
  submissionError,
  disabled,
  isAddEmployeeSubform,
  onClose,
}) => {
  const {
    params: { companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const [{ isOpen: isModalOpen, editId: selectedEditId }, setModalState] =
    useState<ModalState>({
      isOpen: false,
    });

  /* XXX: The modal unmount is slightly delayed upon isOpen state update,
    so don't clear potential edit data before unmounting  */
  const closeModal = () =>
    setModalState(state => ({ ...state, isOpen: false }));

  const onRequestClose = () => {
    closeModal();
    onClose?.();
  };

  const {
    remunerationTypes,
    loading: remunerationLoading,
    error: remunerationError,
  } = useRemunerationTypes({ companyId });

  const remunerations: readonly Remuneration[] = _remunerations.length
    ? isFormikData(_remunerations)
      ? _remunerations.map(
          fromFormData(remunerationTypes ?? [], _remunerations),
        )
      : _remunerations
    : [];

  const remunerationsByType = group({
    itemsOf: remunerations,
    by: r => r.type.id,
  }).sort(([a], [b]) => alphabeticallyAscending(a.type.id, b.type.id));

  const tableHasHistory = remunerationsByType.some(
    entries => entries.length > 1,
  );

  const ref = useRef(null);

  const { width: rowWidth } = useComponentSize(ref);

  return (
    <Section ref={ref}>
      {!isAddEmployeeSubform && (
        <>
          <SectionHeader>
            <FormattedMessage
              {...workInformationMessages.salaryAndOtherBenefits}
            />
          </SectionHeader>
          <DescriptionWrapper>
            <FormattedMessage
              {...workInformationMessages.salaryAndOtherBenefitsDescription}
              values={{
                // eslint-disable-next-line
                accountPlanHref: chunk => (
                  <Link to={`/companies/${companyId}/settings/account-plan`}>
                    {chunk}
                  </Link>
                ),
              }}
            />
          </DescriptionWrapper>
        </>
      )}
      {!disabled && (
        <ChipsWrapper>
          <AssistChip
            text={<FormattedMessage {...commonMessages.add} />}
            leadingIcon={addCircle}
            onClick={() => setModalState({ isOpen: true })}
          />
        </ChipsWrapper>
      )}
      <Table size="xsmall">
        <colgroup>
          {tableHasHistory && <col style={{ width: 'auto' }} />}
          <col
            style={{
              width: `${halfRowWidth({
                hasChevron: tableHasHistory,
                rowWidth,
              })}px`,
            }}
          />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
        </colgroup>
        <thead>
          <Tr withInlineMenuTh={!disabled}>
            {tableHasHistory && <Th chevron />}
            <Th>
              <FormattedMessage {...accountPlanMessages.payrollElement} />
            </Th>
            <Th>
              <FormattedMessage {...accountPlanMessages.dateSpan} />
            </Th>
            <Th align="right">
              <FormattedMessage {...commonMessages.value} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {remunerationsByType.map(entries => {
            const [current, ...rowHistory] = entries.sort(byDateDescending);
            const {
              type: { id, name },
              from,
              to,
              value,
              currency,
              unitCode,
            } = current;

            const editMenu = (
              item: Remuneration,
              { hasEditOption, hasDeleteOption }: OptionTypes,
            ) => (
              <KebabMenu fixed inClickableArea>
                {hasEditOption && (
                  <MenuItem
                    title={
                      <FormattedMessage
                        {...accountPlanMessages.newRemuneration}
                        values={{ remunerationName: name }}
                      />
                    }
                    onClick={() =>
                      setModalState({ isOpen: true, editId: item.type.id })
                    }
                    icon={<Icon icon={edit} decorative />}
                  />
                )}
                {hasDeleteOption && (
                  <MenuItem
                    title={<FormattedMessage {...commonMessages.remove} />}
                    onClick={() => deleteItem?.(item.type.id, item.from)}
                    icon={<Icon icon={_delete} decorative />}
                  />
                )}
              </KebabMenu>
            );

            const tableCells = (
              <>
                <Td>{`${id} ${name ?? ''}`}</Td>
                <Td type="number" align="left">
                  <div>
                    {from} - {to ? toEffectiveThrough(to) : ''}
                  </div>
                </Td>
                <Td align="right">
                  <FormattedCurrency
                    value={value}
                    currency={currency ?? ''}
                    noSuffix={!currency}
                    after={
                      unitCode && unitCode !== UnitCodeOptions.LS ? (
                        <>
                          /
                          <FormattedMessage
                            select={unitCode}
                            messages={unitCodeSuffixMessages}
                          />
                        </>
                      ) : null
                    }
                  />
                </Td>
              </>
            );

            if (!rowHistory.length) {
              return (
                <Tr
                  key={`${id}-${from}`}
                  inlineMenu={
                    !disabled &&
                    editMenu(current, {
                      hasEditOption: true,
                      hasDeleteOption: isAddEmployeeSubform,
                    })
                  }
                >
                  {tableHasHistory && <Td chevron />}
                  {tableCells}
                </Tr>
              );
            }

            const subRows = rowHistory.map(item => (
              <SubTr
                key={`${item.type.id}-${item.from}`}
                inlineMenu={
                  !disabled &&
                  (isAddEmployeeSubform
                    ? editMenu(item, {
                        hasEditOption: false,
                        hasDeleteOption: isAddEmployeeSubform,
                      })
                    : true)
                }
              >
                <SubTd>{`${item.type.id} ${item.type.name ?? ''}`}</SubTd>
                <SubTd type="number" align="left">
                  <div>
                    {item.from} - {item.to || ''}
                  </div>
                </SubTd>
                <SubTd align="right">
                  <FormattedCurrency
                    value={item.value}
                    currency={item.currency ?? ''}
                    noSuffix={!item.currency}
                    after={
                      item.unitCode && item.unitCode !== UnitCodeOptions.LS ? (
                        <>
                          /
                          <FormattedMessage
                            select={item.unitCode}
                            messages={unitCodeSuffixMessages}
                          />
                        </>
                      ) : null
                    }
                  />
                </SubTd>
              </SubTr>
            ));

            return (
              <CollapsibleTr
                key={`${id}-${from}`}
                subrows={subRows}
                inlineMenu={
                  !disabled &&
                  editMenu(current, {
                    hasEditOption: true,
                    hasDeleteOption: isAddEmployeeSubform,
                  })
                }
              >
                {tableCells}
              </CollapsibleTr>
            );
          })}
          {!remunerationsByType.length && (
            <Tr inlineMenu={!disabled}>
              <Td>
                <NoValue />
              </Td>
              <Td>
                <NoValue />
              </Td>
              <Td align="right">
                <NoValue />
              </Td>
            </Tr>
          )}
        </tbody>
      </Table>
      <UpdateRemunerationModal
        parentField={parentField}
        onSubmit={onSubmit(onRequestClose)}
        isOpen={isModalOpen && (!remunerationLoading || !!edit)}
        editId={
          selectedEditId ??
          (!remunerationsByType.length
            ? MONTHLY_SALARY_REMUNERATION_TYPE_ID
            : undefined)
        }
        currentRemunerations={remunerationsByType.map(([current]) => current)}
        remunerationTypeOptions={remunerationTypes}
        onRequestClose={onRequestClose}
        error={submissionError ?? remunerationError}
        isAddEmployeeSubform={isAddEmployeeSubform}
      />
    </Section>
  );
};
