import gql from 'graphql-tag';

export const CREATE_PROPOSAL_HEALTHCARE_BENEFIT_MUTATION = gql`
  mutation createProposalHealthcareBenefitMutation(
    $input: CreateProposalHealthcareBenefitInput!
  ) {
    createProposalHealthcareBenefit(input: $input) {
      id
      benefits {
        id
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
