import {
  CollapsibleList,
  CollapsibleListHeader,
  CollapsibleListItem,
  Section,
} from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import { formOrgUnitsTree, TreeNode } from '@frontend/utils';
import {
  organizationalUnitsQuery,
  organizationalUnitsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { OrgUnitTableEditMenu } from './components/OrgUnitTableEditMenu';
import { ORGANIZATIONAL_UNITS_QUERY } from './graphql/queries';
import { orgUnitMessages } from './messages';

const renderCollapsibleListItems = (
  node: Record<string, TreeNode>,
  intl: IntlShape,
  index: number,
) => {
  const { formatMessage } = intl;
  const entries = Object.entries(node);

  if (entries.length) {
    return entries.map(([key, value]) =>
      value.children ? (
        <CollapsibleListItem
          label={value.name}
          index={index}
          key={key}
          actions={<OrgUnitTableEditMenu id={value.id} label={value.name} />}
          expandButtonLabel={formatMessage(commonMessages.expandName, {
            name: value.name,
          })}
        >
          {renderCollapsibleListItems(value.children, intl, index + 1)}
        </CollapsibleListItem>
      ) : (
        <CollapsibleListItem
          label={value.name}
          key={key}
          index={index}
          actions={<OrgUnitTableEditMenu id={value.id} label={value.name} />}
        />
      ),
    );
  }

  return null;
};

export const OrganizationalUnits: React.FC = () => {
  const { pathname } = useLocation();
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const intl = useIntl();
  const { formatMessage } = intl;

  const { loading, data, error } = useQuery<
    organizationalUnitsQuery,
    organizationalUnitsQueryVariables
  >(ORGANIZATIONAL_UNITS_QUERY, {
    errorPolicy: 'all',
    skip: !companyId,
    variables: { companyId: companyId ?? '' },
  });

  if (loading) {
    return <TopLoading />;
  }

  const orgUnits =
    data?.company?.organizationalUnits?.edges?.map(e => e.node) ?? [];

  const orgUnitTree = formOrgUnitsTree(orgUnits);

  if (!orgUnits.length) {
    return (
      <EmptyState
        error={error}
        title={
          <p>
            <FormattedMessage {...orgUnitMessages.noOrgUnitsTitle} />
          </p>
        }
        actions={
          <LinkButton to={{ pathname, search: qs.stringify({ create: true }) }}>
            <FormattedMessage {...orgUnitMessages.createOrgUnit} />
          </LinkButton>
        }
      >
        <p>
          <FormattedMessage {...orgUnitMessages.noOrgUnitsText} />
        </p>
      </EmptyState>
    );
  }

  return (
    <Section>
      {error && <GraphQlError error={error} />}
      <ChipsWrapper>
        <AssistChip
          to={{ pathname, search: qs.stringify({ create: true }) }}
          text={<FormattedMessage {...orgUnitMessages.createOrgUnit} />}
          leadingIcon={addCircle}
        />
      </ChipsWrapper>
      <CollapsibleList
        label={formatMessage(orgUnitMessages.namesOfOrgUnits)}
        header={
          <CollapsibleListHeader
            title={<FormattedMessage {...orgUnitMessages.name} />}
          />
        }
        items={
          orgUnitTree ? renderCollapsibleListItems(orgUnitTree, intl, 0) : null
        }
      />
    </Section>
  );
};
