import { capitalize } from '@frontend/utils';
import { companyDetailsQuery_company_Company as Company } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  FormattedAddress,
  FormattedIdentityNumber,
  FormattedMessage,
  IntlShape,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import {
  companyMessages,
  companyPaymentMethodMessages,
} from 'features/companies/messages';
import React from 'react';

import { CompanyDetailRow } from '../containers/CompanyDetails';

export const getCompanyDetails = (company: Company, intl: IntlShape) => {
  const companyDetails: CompanyDetailRow[] = [
    {
      id: 'registrationNumber',
      label: <FormattedMessage {...commonMessages.registrationNumber} />,
      value: <FormattedIdentityNumber value={company.registrationNumber} />,
    },
    {
      id: 'clearingCode',
      label: <FormattedMessage {...companyMessages.clearingSalesOffice} />,
      value: company.clearingCode?.displayName ?? <NoValue />,
    },
    {
      id: 'nlpSalesRep',
      label: <FormattedMessage {...companyMessages.nordeaSalesRep} />,
      value: company.nlpSalesRep ? (
        `${company.nlpSalesRep.firstName} ${company.nlpSalesRep.lastName} (${company.nlpSalesRep.externalId})`
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'paymentMethod',
      label: <FormattedMessage {...companyMessages.paymentMethod} />,
      value: company.paymentMethod ? (
        <FormattedMessage
          messages={companyPaymentMethodMessages}
          select={company.paymentMethod}
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'accountClosureMonth',
      label: <FormattedMessage {...companyMessages.accountClosureMonth} />,
      value: company.accountClosureMonth ? (
        capitalize(
          intl.formatDate(
            new Date().setMonth(company.accountClosureMonth - 1),
            {
              month: 'long',
            },
          ),
        )
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'companyId',
      label: <FormattedMessage {...commonMessages.companyId} />,
      value: company.id,
    },
    {
      id: 'companyName',
      label: <FormattedMessage {...commonMessages.companyName} />,
      value: company.name,
    },
    {
      id: 'displayName',
      label: <FormattedMessage {...commonMessages.displayName} />,
      value: company.displayName,
    },
    {
      id: 'address',
      label: <FormattedMessage {...commonMessages.address} />,
      value: (
        <FormattedAddress
          address={company.address.line1 ?? ''}
          careOf={company.address.co}
          postalCode={company.address.postalCode}
          city={company.address.city}
        />
      ),
    },
    {
      id: 'phone',
      label: <FormattedMessage {...commonMessages.phone} />,
      value: company.phone ? company.phone : <NoValue />,
      type: 'number',
    },
    {
      id: 'email',
      label: <FormattedMessage {...commonMessages.email} />,
      value: company.email ? company.email : <NoValue />,
    },
  ];

  return companyDetails;
};
