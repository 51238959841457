import gql from 'graphql-tag';

export const ORGANIZATION_FIELDS_QUERY = gql`
  query organizationFieldsQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      costCenters {
        edges {
          node {
            id
            identifier
            name
          }
        }
      }
      organizationalUnits {
        edges {
          node {
            id
            name
            slug
            path
          }
        }
      }
    }
  }
`;
