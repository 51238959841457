import { SectionHeader } from '@frontend/ui';
import { noteMessages } from 'app/messages/notes';
import { FormattedMessage } from 'components/formats';
import { Notes } from 'features/companies/company/notes';
import React from 'react';

export const NotesOverviewRoute: React.FC = () => (
  <>
    <SectionHeader>
      <FormattedMessage {...noteMessages.notes} />
    </SectionHeader>
    <Notes />
  </>
);
