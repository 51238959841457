import { Section, SectionHeader, Table, Td, Th, Tr } from '@frontend/ui';
import { addCircle, edit } from '@frontend/ui/icons';
import {
  byEffectiveDateDescending,
  toEffectiveThrough,
  withinDateRangeExcludeEndDate,
} from '@frontend/utils';
import {
  membershipBenefitPackageQuery,
  membershipBenefitPackageQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedDate, FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { MEMBERSHIP_BENEFIT_PACKAGE_QUERY } from './graphql/queries';
import { UpdateMembershipBenefitPackageModal } from './update';

export const MembershipBenefitPackage: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const [isModalOpen, setModalOpen] = useState(false);

  const { data } = useQuery<
    membershipBenefitPackageQuery,
    membershipBenefitPackageQueryVariables
  >(MEMBERSHIP_BENEFIT_PACKAGE_QUERY, {
    variables: {
      userAccountId,
      companyId,
    },
    errorPolicy: 'all',
    suspend: true,
  });

  if (!data?.membership) {
    return null;
  }

  const { membership } = data;

  const benefitPackages = membership.employment.flatMap(employment =>
    employment.benefitPackage
      .reduce(
        (acc, { benefitPackage, from, to }) =>
          benefitPackage
            ? [
                ...acc,
                {
                  to,
                  from,
                  id: benefitPackage.id,
                  name: benefitPackage.name,
                },
              ]
            : acc,
        [],
      )
      .sort((a, b) => byEffectiveDateDescending(a.from, b.from)),
  );

  const activeBenefitPackage = benefitPackages.find(({ from, to }) =>
    withinDateRangeExcludeEndDate(new Date().toISOString(), [from, to]),
  );

  const hasActiveBenefitPackage = !!activeBenefitPackage;

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonMessages.benefitPackage} />
      </SectionHeader>
      <ChipsWrapper>
        <AssistChip
          text={
            hasActiveBenefitPackage ? (
              <FormattedMessage {...formMessages.changeBenefitPackage} />
            ) : (
              <FormattedMessage {...formMessages.addToBenefitPackage} />
            )
          }
          onClick={() => setModalOpen(true)}
          leadingIcon={hasActiveBenefitPackage ? edit : addCircle}
        />
      </ChipsWrapper>
      <Table size="xsmall">
        <colgroup>
          <col style={{ width: '50%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.benefitPackage} />
            </Th>
            <Th type="number" align="right">
              <FormattedMessage {...commonMessages.effectiveDate} />
            </Th>
            <Th type="number" align="right">
              <FormattedMessage {...commonMessages.effectiveUntil} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {benefitPackages.map(({ id, from, to, name }) => (
            <Tr key={`${id}-${from}`}>
              <Td> {name ?? <NoValue />} </Td>
              <Td type="number">
                <FormattedDate value={from} />
              </Td>
              <Td type="number">
                {to ? (
                  <FormattedDate value={toEffectiveThrough(to)} />
                ) : (
                  <NoValue />
                )}
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
      <UpdateMembershipBenefitPackageModal
        isOpen={isModalOpen}
        benefitPackage={activeBenefitPackage}
        onRequestClose={() => setModalOpen(false)}
      />
    </Section>
  );
};
