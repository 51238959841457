import gql from 'graphql-tag';

import { membershipDetails } from './fragments';

export const MEMBERSHIP_DETAILS_QUERY = gql`
  query membershipDetailsQuery($userAccountId: ID!, $companyId: ID!) {
    membership(userAccountId: $userAccountId, companyId: $companyId) {
      ...membershipDetails
    }
  }
  ${membershipDetails}
`;
