import { CheckboxField } from '@frontend/formik';
import { Section, SectionHeader } from '@frontend/ui';
import { validationMessages } from 'app/messages/common';
import { employeeFormMessages } from 'app/messages/employees';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, IntlShape } from 'components/formats';
import React from 'react';
import * as Yup from 'yup';

export const confirmationValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    confirmedAgreement: Yup.boolean().oneOf(
      [true],
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface ConfirmationFormValues {
  confirmedAgreement: boolean;
  sendWelcomeEmail: boolean;
}

export const confirmationInitialValues = {
  sendWelcomeEmail: true,
  confirmedAgreement: false,
};

export const ConfirmationFields: React.FC = () => (
  <Section>
    <SectionHeader>
      <FormattedMessage {...formMessages.approve} />
    </SectionHeader>
    <CheckboxField
      name="sendWelcomeEmail"
      label={
        <FormattedMessage {...employeeFormMessages.confirmSendWelcomeEmail} />
      }
    />
    <CheckboxField
      name="confirmedAgreement"
      label={
        <FormattedMessage {...employeeFormMessages.confirmSubmitAddEmployee} />
      }
    />
  </Section>
);
