import { bonusSalaryExchangeEventsQuery } from 'app/apollo/graphql/types';

interface FetchResult {
  fetchMoreResult?: bonusSalaryExchangeEventsQuery;
}

export const updateBonusSalaryExchangeEventsQuery = (
  previousResult: bonusSalaryExchangeEventsQuery,
  { fetchMoreResult }: FetchResult,
) => {
  if (
    !previousResult.bonusSalaryExchangeRequestsHistory ||
    !fetchMoreResult?.bonusSalaryExchangeRequestsHistory
  ) {
    return previousResult;
  }

  return fetchMoreResult;
};
