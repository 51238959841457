import { useMutation } from '@apollo/client';
import {
  deleteOrganizationalUnitMutation,
  deleteOrganizationalUnitMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { DELETE_ORGANIZATIONAL_UNIT_MUTATION } from '../../graphql/mutations';
import { ORGANIZATIONAL_UNITS_QUERY } from '../../graphql/queries';
import { orgUnitMessages } from '../../messages';

interface Args {
  companyId: string;
  id: string;
  onRequestClose: () => void;
}

export const useSubmit = (args: Args) => {
  const { id, companyId, onRequestClose } = args;
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [deleteOrgUnit, { error, loading }] = useMutation<
    deleteOrganizationalUnitMutation,
    deleteOrganizationalUnitMutationVariables
  >(DELETE_ORGANIZATIONAL_UNIT_MUTATION, {
    variables: { input: { id } },
    refetchQueries: [
      {
        query: ORGANIZATIONAL_UNITS_QUERY,
        variables: { companyId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const submit = async () => {
    try {
      const res = await deleteOrgUnit();

      if (res?.data?.deleteOrganizationalUnit) {
        send({
          type: 'success',
          message: formatMessage(orgUnitMessages.deleteOrgUnitSuccess),
        });
        onRequestClose();
      }
    } catch {
      // Do nothing, error is handled by apollo hook
    }
  };

  return {
    submit,
    error,
    loading,
  };
};
