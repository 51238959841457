import gql from 'graphql-tag';

export const COMPANY_CREATE_BENEFIT_OPTIONS_QUERY = gql`
  query companyCreateBenefitOptionsQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      managedBy
    }
  }
`;

export const COMPANY_CREATE_BENEFIT_QUERY = gql`
  query companyCreateBenefitQuery($id: ID!) {
    company(id: $id) {
      id
      agreements(status: ACTIVE) {
        id
        ... on AgreementEuroAccident {
          subAgreementType
        }
      }
      benefitPackages {
        edges {
          node {
            benefits(onlyGetBenefits: true) {
              edges {
                node {
                  id
                  type
                }
              }
            }
            id
            name
          }
        }
      }
      managedBy
      remunerationTypes {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
