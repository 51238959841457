import { RadioGroupOption } from '@frontend/ui';
import { updateProposalQuery_Query as Data } from 'app/apollo/graphql/types';

import { OnChange } from '../../../edit/contexts/autosave';

interface FormValues {
  notes: string;
  adviceSavingsInsurance?: string;
  pensionInstrumentKnowledge?: boolean;
  professionalExperiencePension?: string;
  recentExperiencePension?: string;
}

export const getFormValues = (data?: Data): FormValues => ({
  adviceSavingsInsurance:
    data?.proposal?.productKnowledge?.adviceSavingsInsurance != null
      ? data.proposal.productKnowledge.adviceSavingsInsurance.toString()
      : undefined,
  recentExperiencePension:
    data?.proposal?.productKnowledge?.recentExperiencePension != null
      ? data.proposal.productKnowledge.recentExperiencePension.toString()
      : undefined,
  professionalExperiencePension:
    data?.proposal?.productKnowledge?.professionalExperiencePension != null
      ? data.proposal.productKnowledge.professionalExperiencePension.toString()
      : undefined,
  pensionInstrumentKnowledge:
    data?.proposal?.productKnowledge?.pensionInstrumentKnowledge != null
      ? data?.proposal?.productKnowledge?.pensionInstrumentKnowledge
      : undefined,
  notes: data?.proposal?.productKnowledge?.notes ?? '',
});

export const getOnChangeHandlers = (onChange: OnChange) => ({
  adviceSavingsInsurance: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        productKnowledge: existingProductKnowledge =>
          existingProductKnowledge
            ? {
                ...existingProductKnowledge,
                adviceSavingsInsurance: option.value === 'true',
              }
            : null,
      },
      mutationInput: {
        productKnowledge: {
          adviceSavingsInsurance: option.value === 'true',
        },
      },
    }),
  recentExperiencePension: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        productKnowledge: existingProductKnowledge =>
          existingProductKnowledge
            ? {
                ...existingProductKnowledge,
                recentExperiencePension: option.value === 'true',
              }
            : null,
      },
      mutationInput: {
        productKnowledge: {
          recentExperiencePension: option.value === 'true',
        },
      },
    }),
  professionalExperiencePension: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        productKnowledge: existingProductKnowledge =>
          existingProductKnowledge
            ? {
                ...existingProductKnowledge,
                professionalExperiencePension: option.value === 'true',
              }
            : null,
      },
      mutationInput: {
        productKnowledge: {
          professionalExperiencePension: option.value === 'true',
        },
      },
    }),
  pensionInstrumentKnowledge: (value: boolean) =>
    onChange({
      cacheValue: {
        productKnowledge: existingProductKnowledge =>
          existingProductKnowledge
            ? {
                ...existingProductKnowledge,
                pensionInstrumentKnowledge: value,
              }
            : null,
      },
      mutationInput: {
        productKnowledge: {
          pensionInstrumentKnowledge: value,
        },
      },
    }),
  notes: (value: string) =>
    onChange({
      cacheValue: {
        productKnowledge: existingProductKnowledge =>
          existingProductKnowledge
            ? {
                ...existingProductKnowledge,
                notes: value,
              }
            : null,
      },
      mutationInput: {
        productKnowledge: {
          notes: value,
        },
      },
    }),
});
