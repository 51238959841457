import { CheckboxField, Form, Formik } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@frontend/ui';
import { formMessages } from 'app/messages/form';
import { sharedFileMessages } from 'app/messages/shared-files';
import { FileInfo } from 'components/FileUploadTable';
import { FormattedMessage } from 'components/formats';
import { FileUploadField } from 'components/formik/FileUploadField';
import { GraphQlError } from 'components/GraphQlError';
import { Modal } from 'components/Modal';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { MatchParams } from '../..';
import { useSubmit } from './use-submit';

const ACCEPTED_FILE_FORMATS = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc', '.docx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'text/plain': ['.txt'],
  'application/vnd.ms-excel': ['.xls', '.xlsx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'text/csv': ['.csv'],
};

export type UploadFormValues = {
  isGdprProtected: boolean;
  attachments: FileInfo[];
};

export const UploadModal: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  const { submit, error } = useSubmit();

  const { search } = useLocation();
  const { push } = useHistory();
  const { upload: isOpen } = qs.parse(search);

  if (!isOpen) {
    return null;
  }

  const onRequestClose = () =>
    push(`/companies/${companyId}/reports/shared-files`);

  return (
    <Modal size="default" isOpen onRequestClose={onRequestClose}>
      <Formik<UploadFormValues>
        initialValues={{ attachments: [], isGdprProtected: true }}
        onSubmit={submit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <ModalHeader>
              <FormattedMessage {...sharedFileMessages.uploadFile} />
            </ModalHeader>
            <ModalBody>
              <FileUploadField
                name="attachments"
                label={<FormattedMessage {...sharedFileMessages.selectFile} />}
                companyId={companyId}
                disabled={values.attachments?.length > 0}
                helperText={
                  <FormattedMessage {...sharedFileMessages.uploadHelperText} />
                }
                accept={ACCEPTED_FILE_FORMATS}
                multiple={false}
              />
              <CheckboxField name="isGdprProtected">
                <FormattedMessage {...sharedFileMessages.gdprCheckbox} />
              </CheckboxField>
              {error && <GraphQlError error={error} inModal />}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button filled type="submit" loading={isSubmitting}>
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
