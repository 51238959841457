import { NotificationType } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

import { OpenStatus } from '..';

export const notificationTitles = defineMessages<NotificationType>({
  MISSING_EMAIL: {
    id: 'notificationTitles.missingEmail',
    defaultMessage: 'E-post saknas',
  },
  MISSING_NAME: {
    id: 'notificationTitles.missingName',
    defaultMessage: 'Namn saknas',
  },
  MISSING_FCFW_ASSERTION: {
    id: 'notificationTitles.missingFcfwAssertion',
    defaultMessage: 'Intyga fullt arbetsför',
  },
  MISSING_PENSION_PREMIUM: {
    id: 'notificationTitles.missingPensionPremium',
    defaultMessage: 'Pensionspremie saknas',
  },
  MISSING_SALARY: {
    id: 'notificationTitles.missingSalary',
    defaultMessage: 'Lön saknas',
  },
  SX_VIOLATED: {
    id: 'notificationTitles.sxViolated',
    defaultMessage: 'Löneväxling strider mot policy',
  },
  MISSING_PENSION_PROVIDER_CHOICE: {
    id: 'notificationTitles.missingPensionProviderChoice',
    defaultMessage: 'Pensionsleverantör saknas',
  },
  NEW_ADVICE: {
    id: 'notificationTitles.newAdvice',
    defaultMessage: 'Nytt råd',
  },
  RENEW_POA: {
    id: 'notificationTitles.renewPoa',
    defaultMessage: 'Förnya fullmakt',
  },
  SX_ERROR: {
    id: 'notificationTitles.sxError',
    defaultMessage: 'Fel vid löneväxling',
  },
  SX_ONETIME_AVAILABLE: {
    id: 'notificationTitles.sxOnetimeAvailable',
    defaultMessage: 'Engångsbelopp tillgängligt',
  },
  SX_REQUESTED: {
    id: 'notificationTitles.sxRequested',
    defaultMessage: 'Ny löneväxlingsansökan',
  },
  SX_REQUEST_APPROVED: {
    id: 'notificationTitles.sxRequestApproved',
    defaultMessage: 'Löneväxling godkänd',
  },
  SX_REQUEST_REJECTED: {
    id: 'notificationTitles.sxRequestRejected',
    defaultMessage: 'Löneväxling avslagen',
  },
});

export const notificationDescriptions = defineMessages<NotificationType>({
  MISSING_EMAIL: {
    id: 'notificationDescriptions.missingEmail',
    defaultMessage:
      'E-post saknas för {fullName} och måste fyllas i för att riskförsäkringar ska tecknas.',
  },
  MISSING_NAME: {
    id: 'notificationDescriptions.missingName',
    defaultMessage:
      'E-post saknas för {fullName} och måste fyllas i för att riskförsäkringar ska tecknas.',
  },
  MISSING_FCFW_ASSERTION: {
    id: 'notificationDescriptions.missingFcfwAssertion',
    defaultMessage: 'Intyga att {fullName} är fullt arbetsför.',
  },
  MISSING_PENSION_PREMIUM: {
    id: 'notificationDescriptions.missingPensionPremium',
    defaultMessage:
      'Om {fullName} ska ha insättningar till pension behöver du ange vilken månatlig premie som ska gälla.',
  },
  MISSING_SALARY: {
    id: 'notificationDescriptions.missingSalary',
    defaultMessage:
      'Lön saknas för {fullName} från och med {from} och måste fyllas i för att försäkringar ska tecknas.',
  },
  SX_VIOLATED: {
    id: 'notificationDescriptions.sxViolated',
    defaultMessage:
      'Löneväxling för {fullName} följer inte er löneväxlingspolicy. Detta kan bero på att den anställda har fått ändrad inkomst, så att löneavdraget är för högt.',
  },
  MISSING_PENSION_PROVIDER_CHOICE: {
    id: 'notificationDescriptions.missingPensionProviderChoice',
    defaultMessage: 'Pensionsleverantör saknas',
  },
  NEW_ADVICE: {
    id: 'notificationDescriptions.newAdvice',
    defaultMessage: 'Nytt råd',
  },
  RENEW_POA: {
    id: 'notificationDescriptions.renewPoa',
    defaultMessage: 'Förnya fullmakt',
  },
  SX_ERROR: {
    id: 'notificationDescriptions.sxError',
    defaultMessage: 'Fel vid löneväxling',
  },
  SX_ONETIME_AVAILABLE: {
    id: 'notificationDescriptions.sxOnetimeAvailable',
    defaultMessage: 'Engångsbelopp tillgängligt',
  },
  SX_REQUESTED: {
    id: 'notificationDescriptions.sxRequested',
    defaultMessage:
      'Det finns en ny ansökan om löneväxling för {fullName} att hantera.',
  },
  SX_REQUEST_APPROVED: {
    id: 'notificationDescriptions.sxRequestApproved',
    defaultMessage: 'Löneväxling godkänd',
  },
  SX_REQUEST_REJECTED: {
    id: 'notificationDescriptions.sxRequestRejected',
    defaultMessage: 'Löneväxling avslagen',
  },
});

export const notificationFooterMessages = defineMessages<OpenStatus>({
  REOPENED: {
    id: 'notificationFooterMessages.reopened',
    defaultMessage:
      '{createdAt, date, ::yyyyMMMMdd} • Öppnat på nytt av {author} {statusUpdatedAt, date, ::yyyyMMMMdd}',
  },
  DISMISSED: {
    id: 'notificationFooterMessages.dismissed',
    defaultMessage:
      '{createdAt, date, ::yyyyMMMMdd} • Avfärdat av {author} {statusUpdatedAt, date, ::yyyyMMMMdd}',
  },
  OPEN: {
    id: 'notificationFooterMessages.OPEN',
    defaultMessage: '{createdAt, date, ::yyyyMMMMdd}',
  },
});

export const notificationMessages = defineMessages({
  notifications: {
    id: 'menuMessages.notifications',
    defaultMessage: 'Ärenden',
  },
  noNotifications: {
    id: 'notificationMessages.noNotifications',
    defaultMessage: 'Det finns inga nya ärenden',
  },
  open: {
    id: 'notificationMessages.open',
    defaultMessage: 'Öppna',
  },
  dismissed: {
    id: 'notificationMessages.dismissed',
    defaultMessage: 'Avfärdade',
  },
  reopen: {
    id: 'notificationMessages.reopen',
    defaultMessage: 'Öppna ärende på nytt',
  },
  dismiss: {
    id: 'notificationMessages.dismiss',
    defaultMessage: 'Avfärda ärende',
  },
  statusUpdateError: {
    id: 'notificationMessages.statusUpdateError',
    defaultMessage: 'Det gick inte att uppdatera status på ärendet',
  },
  loadMore: {
    id: 'notificationMessages.loadMore',
    defaultMessage: 'Ladda fler',
  },
});
