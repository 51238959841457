import { ApolloError, useMutation } from '@apollo/client';
import {
  startImportMutation,
  startImportMutationVariables,
} from 'app/apollo/graphql/types';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '..';
import { FormValues } from '../components/ConfirmFields';
import { START_IMPORT_MUTATION } from '../graphql/mutations';

interface Params {
  onCompleted: () => void;
  onError: () => void;
}

interface Submit {
  submissionCalled: boolean;
  submit: (values: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = ({ onCompleted, onError }: Params): Submit => {
  const {
    params: { companyId, importId },
  } = useRouteMatch<MatchParams>();

  const [startImport, { called: submissionCalled, error: submissionError }] =
    useMutation<startImportMutation, startImportMutationVariables>(
      START_IMPORT_MUTATION,
      {
        errorPolicy: 'all',
        onCompleted,
        onError,
        update: cache => {
          cache.evict({ id: `CompanyAnalytics:${companyId}` });
          cache.gc();
        },
      },
    );

  const submit = async (values: FormValues) => {
    await startImport({
      variables: {
        input: {
          id: importId,
          runChanges: true,
          skipWelcomeEmails: !values.skipWelcomeEmails,
        },
      },
    });
  };

  return { submissionCalled, submit, submissionError };
};
