import { membershipEventHistoryTableQuery } from 'app/apollo/graphql/types';

export const updatemembershipEventHistoryTableQuery = (
  previousResult: membershipEventHistoryTableQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: membershipEventHistoryTableQuery;
  },
) => {
  if (
    !previousResult?.membership?.eventHistory ||
    !fetchMoreResult?.membership?.eventHistory
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.membership.eventHistory;

  return {
    membership: {
      ...previousResult.membership,
      eventHistory: {
        edges: [...edges],
        pageInfo,
      },
    },
  };
};
