import gql from 'graphql-tag';

export const TOTAL_COMPENSATION_DETAILS_QUERY = gql`
  query totalCompensationDetailsQuery($companyId: ID!, $userAccountId: ID!) {
    company(id: $companyId) {
      id
      totalCompensationConfiguration {
        companyId
        salary
        variableCompensation
        pension
        salaryExchange
        benefits
        wellness
      }
    }
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      employerContributionShare
      totalCompensation: totalCompensation2 {
        key: id
        category
        annualCompensation
      }
    }
  }
`;
