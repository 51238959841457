import { ApolloError, useMutation } from '@apollo/client';
import {
  deleteMeetingMutation,
  deleteMeetingMutationVariables,
} from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { DELETE_MEETING_MUTATION } from '../graphql/mutations';
import { EMPLOYEE_MEETINGS_QUERY } from '../graphql/queries';

interface DeleteMeeting {
  deleteMeeting: (meetingId: string) => void;
  loading: boolean;
  error?: ApolloError;
}

export const useDeleteMeeting = (): DeleteMeeting => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [deleteMeeting, { error, loading }] = useMutation<
    deleteMeetingMutation,
    deleteMeetingMutationVariables
  >(DELETE_MEETING_MUTATION, { refetchQueries: [EMPLOYEE_MEETINGS_QUERY] });

  const callback = async (id: string) => {
    // eslint-disable-next-line no-alert
    if (confirm(formatMessage(advisorMessages.deleteMeetingConfirmation))) {
      await deleteMeeting({
        variables: {
          input: { id },
        },
      });
      send({
        message: formatMessage(advisorMessages.meetingDeleted),
        type: 'success',
      });
    }
  };

  return {
    deleteMeeting: callback,
    loading,
    error,
  };
};
