import { getBackstageCsrfTokenFromCookie as getCsrfTokenFromCookie } from 'app/helper';
import { formSubmitMessages } from 'app/messages/form';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { useIntl } from 'components/formats';
import { useEffect, useRef, useState } from 'react';

// upload reponse types specified at:
// public-api/services/api-next/src/rest-endpoints/upload
interface FileInfo {
  contentType: string;
  id: string;
  name: string;
}

interface UploadResponse {
  files: FileInfo[];
  id: string;
}

interface FileInfoWithPreview extends FileInfo {
  url: string;
}

interface Response {
  data: FileInfoWithPreview[] | null;
  error: string | null;
  loading: boolean;
  upload: (files: File[] | undefined) => void;
}

interface CompanyIdParams {
  companyId: string;
  userAccountId?: never;
}

interface UserAccountIdParams {
  userAccountId: string;
  companyId?: never;
}

export type OneOfUserAccountIdOrCompanyId =
  | CompanyIdParams
  | UserAccountIdParams;

type Args = {
  onCompleted: (uploadResponse: FileInfoWithPreview[] | null) => void;
} & OneOfUserAccountIdOrCompanyId;

export const useUploadFiles = ({
  onCompleted,
  companyId,
  userAccountId,
}: Args): Response => {
  const { formatMessage } = useIntl();
  const [data, setData] = useState<FileInfoWithPreview[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const previewUrlRef = useRef<Record<string, string>>({});

  const _upload = async (requestData: FormData, config: AxiosRequestConfig) => {
    setLoading(true);
    try {
      const response = await axios.post<{ upload: UploadResponse }>(
        `${window.env.API}/upload`,
        requestData,
        config,
      );

      const responseWithPreview = response.data.upload.files.map(file => ({
        ...file,
        url: previewUrlRef.current[file.name],
      }));

      setData(responseWithPreview);
      if (onCompleted) {
        onCompleted(responseWithPreview);
      }
    } catch (e) {
      const errorMessage =
        e?.response?.data?.errors?.[0]?.message ??
        formatMessage(formSubmitMessages.frontendSubmitError);
      if (errorMessage) {
        setError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const upload = async (files: File[] | undefined) => {
    cancelTokenSourceRef.current?.cancel();
    cancelTokenSourceRef.current = axios.CancelToken.source();

    const config: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        'X-Csrf-Token': getCsrfTokenFromCookie(),
      },
      cancelToken: cancelTokenSourceRef.current.token,
      params: { company: companyId, user: userAccountId },
    };
    if (files && (companyId || userAccountId)) {
      setError(null);
      const requestData = new FormData();
      files.forEach(file => {
        const previewUrl = URL.createObjectURL(file);
        previewUrlRef.current[file.name] = previewUrl;

        requestData.append(`files`, file, file.name);
      });
      await _upload(requestData, config);
    }
  };

  useEffect(() => cancelTokenSourceRef.current?.cancel, []);

  return { data, upload, loading, error };
};
