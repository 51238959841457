import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const SALARY_EXCHANGE_REPORTS_QUERY = gql`
  query salaryExchangeReportsQuery(
    $companyId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    flexSalaryExchangeReports(
      companyId: $companyId
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          reportCreatedAt
          file {
            url
            name
          }
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${pageInfoDetails}
`;
