import { Dot, Section, SectionHeader, Table, Td, Th, Tr } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

const Active: React.FC = () => (
  <Dot
    size={0.75}
    colorValue="green"
    label={<FormattedMessage {...commonMessages.approved} />}
  />
);

const Inactive: React.FC = () => (
  <Dot
    size={0.75}
    colorValue="error"
    label={<FormattedMessage {...commonMessages.missing} />}
  />
);

interface Props {
  validKyc: boolean | null;
}

export const KycTable: React.FC<Props> = ({ validKyc }) => (
  <Section>
    <SectionHeader>
      <FormattedMessage {...companyMessages.kyc} />
    </SectionHeader>
    <Table size="xsmall">
      <tbody>
        <Tr inlineMenu>
          <Th>
            <FormattedMessage {...commonMessages.status} />
          </Th>
          <Td>
            {validKyc === true ? (
              <Active />
            ) : validKyc === false ? (
              <Inactive />
            ) : (
              <NoValue />
            )}
          </Td>
        </Tr>
      </tbody>
    </Table>
  </Section>
);
