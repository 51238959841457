import { ImportColumn } from 'app/apollo/graphql/types';
import { employeesImportMessages } from 'app/messages/employees';
import { IntlShape } from 'components/formats';

import { staticColumnLabelMessages } from '../messages';

export type ImportColumnOptionValue = ImportColumn | string | '';

interface ImportColumnOption {
  label: string;
  value: ImportColumnOptionValue;
}

const LEGACY_OPTIONS = [ImportColumn.IS_FULLY_CAPABLE_FOR_WORK];

const excludeLegacy = (option: ImportColumn) =>
  !LEGACY_OPTIONS.includes(option);

const formatOption = (intl: IntlShape, option: ImportColumn) => ({
  label: intl.formatMessage({
    messages: staticColumnLabelMessages,
    select: option,
  }),
  value: option,
});

const byLabelAscending = (a: ImportColumnOption, b: ImportColumnOption) =>
  a.label > b.label ? 1 : -1;

export const selectStaticColumnOptions = (
  intl: IntlShape,
): ImportColumnOption[] => [
  { label: intl.formatMessage(employeesImportMessages.skipImport), value: '' },
  ...Object.values(ImportColumn)
    .filter(excludeLegacy)
    .map(option => formatOption(intl, option))
    .sort(byLabelAscending),
];
