import qs from 'query-string';
import { useHistory, useLocation } from 'react-router';

export const DUPLICATE_PROPOAL_QUERY_KEY = 'duplicate-proposal';

export const useDuplicateProposal = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const duplicate = (id: string) => {
    push({
      pathname,
      search: qs.stringify({ [DUPLICATE_PROPOAL_QUERY_KEY]: id }),
    });
  };

  return { duplicate };
};
