import gql from 'graphql-tag';

export const SME_COMPANY_CREATE_BENEFIT_QUERY = gql`
  query smeCompanyCreateBenefitQuery($id: ID!) {
    proposal(id: $id) {
      id
      benefitPackages {
        id
        name
      }
    }
  }
`;
