import { Td, Tr } from '@frontend/ui';
import { toNumber } from '@frontend/utils';
import { invoiceLinesQuery_invoiceSearch_InvoiceSearchResult_invoiceLines_InvoiceLineConnection_edges_InvoiceLineEdge_node_InvoiceLine as InvoiceLine } from 'app/apollo/graphql/types';
import { FormattedCurrency, FormattedIdentityNumber } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';

interface Props {
  invoiceLine: InvoiceLine;
}

export const InvoiceLinesTableRow: React.FC<Props> = ({
  invoiceLine: {
    personalIdentityNumber,
    firstName,
    lastName,
    name,
    invoicePeriod,
    invoice,
    priceWithVat,
    lineExtensionCurrency,
    invoicedQuantity,
  },
}) => {
  const formattedPin = personalIdentityNumber ? (
    <FormattedIdentityNumber value={personalIdentityNumber} />
  ) : null;
  const hasEmployeeInfo = !!formattedPin || !!firstName || !!lastName;
  const linePrice =
    (toNumber(priceWithVat) ?? 0) * (toNumber(invoicedQuantity) ?? 0);

  return (
    <Tr>
      <Td>
        {hasEmployeeInfo ? (
          <>
            {formattedPin}
            {(firstName || lastName) && (
              <div>
                {firstName}
                {!!firstName && ' '}
                {lastName}
              </div>
            )}
          </>
        ) : (
          <NoValue />
        )}
      </Td>
      <Td multiline>{name ?? <NoValue />}</Td>
      <Td>{invoice?.supplier ?? <NoValue />}</Td>
      <Td>
        {invoicePeriod?.startDate && invoicePeriod.endDate ? (
          <>
            <FormattedDate value={invoicePeriod.startDate} />
            <span> – </span>
            <FormattedDate value={invoicePeriod.endDate} />
          </>
        ) : (
          <NoValue />
        )}
      </Td>
      <Td>
        {lineExtensionCurrency && linePrice ? (
          <FormattedCurrency
            value={linePrice}
            currency={lineExtensionCurrency}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        ) : (
          <NoValue />
        )}
      </Td>
    </Tr>
  );
};
