import { defineMessages } from 'react-intl';

export const pensionMessages = defineMessages({
  singlePremiumDeposits: {
    id: 'pensionMessages.singlePremiumDeposits',
    description: 'Single deposits',
    defaultMessage: `Engångspremier`,
  },
  singlePremiumDeposit: {
    id: 'pensionMessages.singlePremiumDeposit',
    description: 'Single deposit',
    defaultMessage: `Gör engångsinsättning`,
  },
  createSinglePremiumDepositSuccess: {
    id: 'pensionMessages.createSinglePremiumDepositSuccess',
    defaultMessage: 'Engångsinsättningen faktureras på nästa faktura',
  },
  singlePremiumDepositDescription: {
    id: 'pensionMessages.singlePremiumDepositDescription',
    defaultMessage: `Engångsinsättningar faktureras tillsammans med era regelbundna premier.`,
  },
  updateFixedExtraPremium: {
    id: 'pensionMessages.updateFixedExtraPremium',
    defaultMessage: 'Ändra fast extra premie',
  },
  updateFixedExtraPremiumDesc: {
    id: 'pensionMessages.updateFixedExtraPremiumDesc',
    defaultMessage:
      'Här ändrar du den fasta extra premien som den anställda har utöver den valda premien i förmånsgruppen. Ändring av pensionspremie gäller från och med den första i månaden. Du kan ändra upp till en månad framåt och tre månader bakåt i tiden.',
  },
  currentFixedExtraPremium: {
    id: 'pensionMessages.currentFixedExtraPremium',
    defaultMessage: 'Nuvarande fast extra premie (fr.o.m. {effectiveDate})',
  },
  newFixedExtraPremium: {
    id: 'pensionMessages.newFixedExtraPremium',
    defaultMessage: 'Ange ny fast extra premie',
  },
});
