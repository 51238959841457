import { CheckboxGroupField } from '@frontend/formik';
import { A, Subsection, SubsectionHeader } from '@frontend/ui';
import { commonBenefitMessages } from 'app/messages/benefits';
import { EXTERNAL_URLS } from 'app/utils/external-urls';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';

import { benefitFormMessages } from '../../messages';
import { BenefitPackageOption } from '../../types';

const RulesHref = (chunk: React.ReactNode) => (
  <A
    href={EXTERNAL_URLS.skatteverketHealthcareRules}
    rel="noreferrer"
    target="_blank"
  >
    {chunk}
  </A>
);

interface Props {
  benefitPackageOptions: BenefitPackageOption[];
  name: string;
  disabled?: boolean;
  isWellnessBenefit?: boolean;
}

export const BenefitPackagesSection: React.FC<Props> = ({
  benefitPackageOptions,
  disabled,
  isWellnessBenefit,
  name,
}) => {
  if (!benefitPackageOptions?.length) {
    return (
      <Subsection>
        <SubsectionHeader>
          <FormattedMessage {...benefitFormMessages.benefitPackages} />
        </SubsectionHeader>
        <NotificationCard type="warning">
          <FormattedMessage {...commonBenefitMessages.noBenefitPackages} />
        </NotificationCard>
      </Subsection>
    );
  }

  return (
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...benefitFormMessages.benefitPackages} />
      </SubsectionHeader>
      <TextGrid>
        <DescriptionWrapper>
          <FormattedMessage
            {...(isWellnessBenefit
              ? benefitFormMessages.benefitPackagesWellnessDescription
              : benefitFormMessages.benefitPackagesDescription)}
            values={{
              rulesHref: RulesHref,
            }}
          />
        </DescriptionWrapper>
      </TextGrid>
      <CheckboxGroupField
        disabled={disabled}
        name={name}
        options={benefitPackageOptions}
      />
    </Subsection>
  );
};
