import { OrganizationalUnits } from 'features/companies/company/organizational-units';
import { CreateOrgUnitModal } from 'features/companies/company/organizational-units/create';
import { DeleteOrgUnitModal } from 'features/companies/company/organizational-units/delete';
import { EditOrgUnitModal } from 'features/companies/company/organizational-units/edit';
import React from 'react';

export const OrganizationalUnitsRoute: React.FC = () => (
  <>
    <OrganizationalUnits />
    <EditOrgUnitModal />
    <DeleteOrgUnitModal />
    <CreateOrgUnitModal />
  </>
);
