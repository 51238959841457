import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const DEBOUNCE_TIMER = 300;

interface DebouncedSearch {
  debouncedSearch: string;
  search: string;
  setSearch: (search: string) => void;
}

/**
 * Hook to handle debounced search.
 * Returns the search value, the setter for the search value and the debounced search value.
 */
export const useDebouncedSearch = (): DebouncedSearch => {
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
  const [setStateDebounced] = useDebouncedCallback((newValue: string) => {
    setDebouncedSearch(newValue);
  }, DEBOUNCE_TIMER);

  useEffect(() => {
    setStateDebounced(search);
  }, [search]);

  return { setSearch, search, debouncedSearch };
};
