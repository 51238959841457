import gql from 'graphql-tag';

export const COMPILATION_NOTIFICATIONS_QUERY = gql`
  query compilationNotificationsQuery($id: ID!) {
    proposal(id: $id) {
      id
      validity {
        validCompilation {
          errors
          warnings
          info
        }
      }
    }
  }
`;
