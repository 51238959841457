import { QueryResult } from '@apollo/client';
import { CheckboxField, RadioGroup, TextField } from '@frontend/ui';
import { download } from '@frontend/ui/icons';
import { alphabeticallyAscending } from '@frontend/utils';
import { client } from 'app/apollo/client';
import {
  companyCostCentersQuery,
  companyCostCentersQueryVariables,
  employeeNameSearchQuery,
  employeeNameSearchQueryVariables,
  invoiceLinesQuery_invoiceSearch_InvoiceSearchResult as InvoiceSearch,
  membershipsSearchQuery,
  membershipsSearchQueryVariables,
  PensionLayer,
  TaxClass,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  invoiceLinesMessages,
  pensionActMessages,
  taxClassMessages,
} from 'app/messages/invoice-lines';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FieldChips } from 'components/FieldChips';
import { FormattedMessage, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { INVOICE_SUPPLIER } from 'features/companies/company/utils/constants';
import { FilterSection } from 'features/filter-sheet/components/FilterSection';
import { SelectionFilter } from 'features/filter-sheet/components/SelectionFilter';
import {
  DEBOUNCE_TIMER,
  useDebouncedFilters,
} from 'features/filter-sheet/utils/use-debounced-filters';
import React, { useEffect, useId, useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import {
  COMPANY_COST_CENTERS_QUERY,
  EMPLOYEE_NAME_SEARCH_QUERY,
  MEMBERSHIPS_SEARCH_QUERY,
} from './graphql/queries';

const FieldChipsWrapper = styled.div`
  margin-top: 1rem;
`;

const isFulfilled = (
  res: PromiseSettledResult<
    QueryResult<employeeNameSearchQuery, employeeNameSearchQueryVariables>
  >,
): res is PromiseFulfilledResult<
  QueryResult<employeeNameSearchQuery, employeeNameSearchQueryVariables>
> => res.status === 'fulfilled';

interface FilterProps {
  companyId: string;
  invoiceSearch: InvoiceSearch;
}

interface Filters {
  accountingCosts?: string | string[];
  dueFrom?: string;
  dueTo?: string;
  invoiceFrom?: string;
  invoiceTo?: string;
  pensionAct?: string;
  periodFrom?: string;
  periodTo?: string;
  suppliers?: string | string[];
  taxClass?: string;
  userAccountIds?: string | string[];
}

export const Filters: React.FC<FilterProps> = ({
  companyId,
  invoiceSearch,
}) => {
  const pensionActId = useId();
  const taxClassId = useId();
  const { formatMessage } = useIntl();
  const { clearFilters, filters, setFilterValue } =
    useDebouncedFilters<Filters>();
  const [employeeSearch, setEmployeeSearch] = useState<string>('');
  const [selectedEmployees, setSelectedEmployees] = useState<
    Record<string, string>
  >({});
  const [selectedCostCenters, setSelectedCostCenters] = useState<
    Record<string, string>
  >({});
  const [debouncedEmployeeSearch] = useDebouncedCallback((search: string) => {
    setEmployeeSearch(search);
  }, DEBOUNCE_TIMER);

  const { data: membershipSearchData, loading: membershipSearchLoading } =
    useQuery<membershipsSearchQuery, membershipsSearchQueryVariables>(
      MEMBERSHIPS_SEARCH_QUERY,
      {
        variables: {
          id: companyId,
          search: employeeSearch,
        },
        skip: !employeeSearch,
        errorPolicy: 'all',
      },
    );

  const { data: costCenterData, loading: costCenterLoading } = useQuery<
    companyCostCentersQuery,
    companyCostCentersQueryVariables
  >(COMPANY_COST_CENTERS_QUERY, {
    variables: {
      companyId,
    },
    errorPolicy: 'all',
  });

  const userAccountIds = filters.userAccountIds
    ? Array.isArray(filters.userAccountIds)
      ? filters.userAccountIds
      : [filters.userAccountIds]
    : [];

  const fetchMembership = async (input: string[]) => {
    const result = await Promise.allSettled(
      input.map(userAccountId =>
        client.query({
          query: EMPLOYEE_NAME_SEARCH_QUERY,
          variables: {
            companyId,
            userAccountId,
          },
        }),
      ),
    );

    setSelectedEmployees(() =>
      result.filter(isFulfilled).reduce((acc, res) => {
        if (!res.value.data?.membership) {
          return acc;
        }
        return {
          ...acc,
          [res.value.data.membership.userAccountId]:
            `${res.value.data.membership.givenName} ${res.value.data.membership.lastName}`,
        };
      }, {}),
    );
  };

  const accountingCosts = filters.accountingCosts
    ? Array.isArray(filters.accountingCosts)
      ? filters.accountingCosts
      : [filters.accountingCosts]
    : [];

  const fetchCostCenters = async (input: string[]) => {
    const res = await client.query<
      companyCostCentersQuery,
      companyCostCentersQueryVariables
    >({
      query: COMPANY_COST_CENTERS_QUERY,
      variables: {
        companyId,
      },
    });

    setSelectedCostCenters(() =>
      input.reduce((acc, cc) => {
        const selectedItem = res.data?.company?.costCenters?.edges.find(
          ({ node }) => node.id === cc,
        );

        if (!selectedItem) {
          return acc;
        }

        return {
          ...acc,
          [selectedItem.node.id]: selectedItem.node.name,
        };
      }, {}),
    );
  };

  useEffect(() => {
    if (userAccountIds.length) {
      fetchMembership(userAccountIds);
    }

    if (accountingCosts.length) {
      fetchCostCenters(accountingCosts);
    }
  }, []);

  const employeeOptions =
    membershipSearchData?.company?.memberships?.edges.map(({ node }) => ({
      label: `${node.givenName} ${node.lastName}`,
      value: node.userAccountId,
    })) ?? [];

  const suppliers = filters.suppliers
    ? Array.isArray(filters.suppliers)
      ? filters.suppliers
      : [filters.suppliers]
    : [];

  const supplierOptions = Object.entries(INVOICE_SUPPLIER)
    .map(([key, value]) => ({
      id: key,
      label: value,
      selected: suppliers.includes(key),
    }))
    .sort((a, b) => alphabeticallyAscending(a.label, b.label));

  const costCenterOptions =
    costCenterData?.company?.costCenters?.edges.map(({ node }) => ({
      label: node.name,
      value: node.id,
    })) ?? [];

  const taxClassOptions = [
    ...Object.values(TaxClass).map(value => ({
      label: formatMessage({ select: value, messages: taxClassMessages }),
      value,
    })),
    {
      label: formatMessage(invoiceLinesMessages.allOfTheAbove),
      value: '',
    },
  ];

  const pensionActOptions = [
    ...Object.values(PensionLayer).map(value => ({
      label: formatMessage({ select: value, messages: pensionActMessages }),
      value,
    })),
    {
      label: formatMessage(invoiceLinesMessages.allOfTheAbove),
      value: '',
    },
  ];

  const selectedFilters = Object.keys(filters);

  const reset = () => {
    clearFilters();
    setSelectedEmployees({});
    setSelectedCostCenters({});
  };

  const { excelUrl, csvUrl } = invoiceSearch;

  return (
    <>
      {(membershipSearchLoading || costCenterLoading) && <TopLoading />}
      <ChipsWrapper>
        <SelectionFilter
          nSelected={selectedFilters.length}
          clearFilters={reset}
        >
          <FilterSection
            header={
              <FormattedMessage {...invoiceLinesMessages.searchEmployee} />
            }
          >
            <FieldChipsWrapper>
              <FieldChips
                dense
                persistCasing
                label={<FormattedMessage {...invoiceLinesMessages.nameOrPin} />}
                options={employeeOptions}
                onChange={value => {
                  setSelectedEmployees(value);
                  setFilterValue(Object.keys(value), 'userAccountIds');
                }}
                value={selectedEmployees}
                onSearchFieldValueChange={debouncedEmployeeSearch}
                filterOptionsBySearchFieldValue={false}
              />
            </FieldChipsWrapper>
          </FilterSection>
          <FilterSection
            header={<FormattedMessage {...invoiceLinesMessages.selectPeriod} />}
          >
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.from} />}
              persistentLabel
              type="date"
              value={filters.periodFrom ?? ''}
              onChange={event =>
                setFilterValue(event.target.value, 'periodFrom')
              }
            />
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.to} />}
              persistentLabel
              type="date"
              value={filters.periodTo ?? ''}
              onChange={event => setFilterValue(event.target.value, 'periodTo')}
            />
          </FilterSection>
          <FilterSection
            header={
              <FormattedMessage {...invoiceLinesMessages.selectSupplier} />
            }
          >
            {supplierOptions.map(({ selected, id, label }) => (
              <div key={id}>
                <CheckboxField
                  checked={selected}
                  label={label}
                  onChange={() => {
                    const value = suppliers.includes(id)
                      ? suppliers.filter(_id => id !== _id)
                      : [id, ...suppliers];

                    setFilterValue(value, 'suppliers');
                  }}
                />
              </div>
            ))}
          </FilterSection>
          <FilterSection
            header={
              <FormattedMessage {...invoiceLinesMessages.searchCostCenter} />
            }
          >
            <FieldChipsWrapper>
              <FieldChips
                dense
                label={<FormattedMessage {...commonMessages.search} />}
                options={costCenterOptions}
                onChange={value => {
                  setSelectedCostCenters(value);
                  setFilterValue(Object.keys(value), 'accountingCosts');
                }}
                value={selectedCostCenters}
              />
            </FieldChipsWrapper>
          </FilterSection>
          <FilterSection
            header={
              <FormattedMessage {...invoiceLinesMessages.selectTaxClass} />
            }
            id={taxClassId}
          >
            <RadioGroup
              checked={filters.taxClass ?? ''}
              onChange={({ value }) => setFilterValue(value, 'taxClass')}
              options={taxClassOptions}
              aria-labelledby={taxClassId}
            />
          </FilterSection>
          <FilterSection
            header={
              <FormattedMessage {...invoiceLinesMessages.selectPensionAct} />
            }
            id={pensionActId}
          >
            <RadioGroup
              checked={filters.pensionAct ?? ''}
              onChange={({ value }) => setFilterValue(value, 'pensionAct')}
              options={pensionActOptions}
              aria-labelledby={pensionActId}
            />
          </FilterSection>
          <FilterSection
            header={
              <FormattedMessage
                {...invoiceLinesMessages.selectLastPaymentDate}
              />
            }
          >
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.from} />}
              persistentLabel
              type="date"
              value={filters.dueFrom ?? ''}
              onChange={event => setFilterValue(event.target.value, 'dueFrom')}
            />
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.to} />}
              persistentLabel
              type="date"
              value={filters.dueTo ?? ''}
              onChange={event => setFilterValue(event.target.value, 'dueTo')}
            />
          </FilterSection>
          <FilterSection
            header={
              <FormattedMessage {...invoiceLinesMessages.selectInvoiceDate} />
            }
          >
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.from} />}
              persistentLabel
              type="date"
              value={filters.invoiceFrom ?? ''}
              onChange={event =>
                setFilterValue(event.target.value, 'invoiceFrom')
              }
            />
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.to} />}
              persistentLabel
              type="date"
              value={filters.invoiceTo ?? ''}
              onChange={event =>
                setFilterValue(event.target.value, 'invoiceTo')
              }
            />
          </FilterSection>
        </SelectionFilter>
        {invoiceSearch.invoiceLines.totalCount > 0 && (excelUrl || csvUrl) && (
          <>
            {/* XXX: All downloading of invoice lines as excel is temporarily disabled
            due to backend issues. This will be re-enabled in the future. */}

            {excelUrl && (
              <AssistChip
                text={
                  <FormattedMessage {...invoiceLinesMessages.downloadExcel} />
                }
                leadingIcon={download}
                // eslint-disable-next-line
                onClick={() => (window.location.href = excelUrl)}
              />
            )}
            {csvUrl && (
              <AssistChip
                text={
                  <FormattedMessage {...invoiceLinesMessages.downloadCsv} />
                }
                leadingIcon={download}
                // eslint-disable-next-line
                onClick={() => (window.location.href = csvUrl)}
              />
            )}
          </>
        )}
      </ChipsWrapper>
    </>
  );
};
