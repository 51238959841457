import { MembershipKey } from 'app/apollo/graphql/types';
import { useEffect, useState } from 'react';

type CheckedRowKey = MembershipKey & {
  rowId: string;
};

export const useCheckboxRows = (rowKeys: Array<CheckedRowKey>) => {
  const [checkedRowKeys, setCheckedRows] = useState<Array<CheckedRowKey>>([]);

  // If search result changes, filter out rows that no longer exist
  useEffect(() => {
    setCheckedRows(previousState =>
      previousState.filter(checkedKey =>
        rowKeys.some(rowKey => rowKey.rowId === checkedKey.rowId),
      ),
    );
  }, [JSON.stringify(rowKeys)]);

  const allSelected =
    checkedRowKeys.length === rowKeys.length && rowKeys.length > 0;
  const indeterminate = checkedRowKeys.length > 0 && !allSelected;

  const toggleSelectAll = (checked: boolean) => {
    if (checked) {
      setCheckedRows(rowKeys);
    } else {
      setCheckedRows([]);
    }
  };

  const toggleSelectRow = (checked: boolean, membershipKey: CheckedRowKey) => {
    if (checked) {
      setCheckedRows(previousState => [...previousState, membershipKey]);
    } else {
      setCheckedRows(previousState =>
        previousState.filter(
          checkedKey => checkedKey.rowId !== membershipKey.rowId,
        ),
      );
    }
  };

  return {
    checkedRowKeys,
    allSelected,
    indeterminate,
    toggleSelectAll,
    toggleSelectRow,
  };
};
