import { ContentContainer } from '@frontend/ui';
import { noteMessages } from 'app/messages/notes';
import { FormattedMessage } from 'components/formats';
import { Notes } from 'features/companies/company/notes';
import { Page } from 'features/page';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { NoteMatchParams } from './';

export const NotesOverviewRoute: React.FC<
  RouteComponentProps<NoteMatchParams>
> = props => (
  <Page
    title={<FormattedMessage {...noteMessages.notes} />}
    parentLink={`/companies/${props.match.params.companyId}/notes`}
  >
    <ContentContainer>
      <Notes />
    </ContentContainer>
  </Page>
);
