import { ContentContainer, Grid, Section } from '@frontend/ui';
import { EmptyState } from 'components/EmptyState';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { Details } from 'features/companies/company/employees/employee/total-compensation/details';
import { FitnessContributionCard } from 'features/companies/company/employees/employee/total-compensation/fitness-contribution-card';
import { PensionCard } from 'features/companies/company/employees/employee/total-compensation/pension-card';
import { SalaryExchangeCard } from 'features/companies/company/employees/employee/total-compensation/salary-exchange-card';
import { SummaryCard } from 'features/companies/company/employees/employee/total-compensation/summary-card';
import { VacationCard } from 'features/companies/company/employees/employee/total-compensation/vacation-card';
import { VariableCompensationCard } from 'features/companies/company/employees/employee/total-compensation/variable-compensation-card';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const CompensationRoute: React.FC = () => (
  <Suspense fallback={<TopLoading />} batch emptyState={<EmptyState />}>
    {({ errors }) => (
      <ContentContainer>
        {errors && <GraphQlErrors errors={errors} />}
        <Section>
          <Grid>
            <SummaryCard />
            <PensionCard />
            <FitnessContributionCard />
            <VacationCard />
            <SalaryExchangeCard />
            <VariableCompensationCard />
          </Grid>
        </Section>
        <Details />
      </ContentContainer>
    )}
  </Suspense>
);
