import {
  FigureSkeleton,
  SectionBodySkeleton,
  SectionHeaderSkeleton,
} from '@frontend/ui';
import { TopLoading } from 'components/TopLoading';
import React from 'react';

export const Loading = () => (
  <>
    <TopLoading />
    <FigureSkeleton />
    <SectionHeaderSkeleton />
    <SectionHeaderSkeleton />
    <SectionBodySkeleton nRows={5} />
    <SectionHeaderSkeleton />
    <SectionBodySkeleton nRows={3} />
  </>
);
