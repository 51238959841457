import gql from 'graphql-tag';

export const EXECUTE_PROPOSAL_QUERY = gql`
  query executeProposalQuery($id: ID!) {
    proposal(id: $id) {
      id
      startDate
    }
  }
`;
