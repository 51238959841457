import gql from 'graphql-tag';

export const COMPANY_FULLY_CAPABLE_FOR_WORK_QUERY = gql`
  query companyFullyCapableForWorkRequestsQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      fullyCapableForWorkInfo {
        infoText
      }
      fcfwRequests {
        edges {
          node {
            userAccountId
            companyId
            requestedDate
            possibleAssertionDates
            membership {
              id
              naturalPersonIdentifiers
              givenName
              lastName
            }
          }
        }
      }
    }
  }
`;
