import gql from 'graphql-tag';

export const totalCompensationConfigurationDetails = gql`
  fragment totalCompensationConfigurationDetails on TotalCompensationConfiguration {
    companyId
    salary
    variableCompensation
    pension
    salaryExchange
    benefits
    wellness
    vacation
    salaryPayrollElementCode
    variableCompensationPayrollElementCode
  }
`;
