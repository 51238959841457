import {
  Table,
  Td,
  Th,
  Tr,
  WizardSection,
  WizardSectionHeader,
} from '@frontend/ui';
import {
  ImportStatus,
  previewTableQuery,
  previewTableQueryVariables,
} from 'app/apollo/graphql/types';
import { employeesImportMessages } from 'app/messages/employees';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { TableNavigation } from 'components/TableNavigation';
import React, { ReactNode, useRef, useState } from 'react';

import { CHANGES_ONLY } from '../utils/changes-only';
import { TABLE_COLUMNS, useCustomTableColumns } from '../utils/format-records';
import { PREVIEW_TABLE_QUERY } from './graphql/queries';
import { updateRecords } from './graphql/update-records';

interface Props {
  importId: string;
}
interface CellItem {
  rowId: string;
  value: ReactNode | undefined;
}
interface ColumnWithData {
  data: CellItem[];
  id: string | number;
  title: string;
  type?: 'number' | 'input';
}

export const ImportPreviewTable: React.FC<Props> = ({ importId }) => {
  const { formatMessage } = useIntl();
  const suspend = useRef(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const { data, previousData, fetchMore } = useQuery<
    previewTableQuery,
    previewTableQueryVariables
  >(PREVIEW_TABLE_QUERY, {
    suspend: suspend.current,
    variables: {
      id: importId,
      first: Number(perPage),
      statuses: CHANGES_ONLY,
    },
    errorPolicy: 'all',
    onCompleted: () => {
      suspend.current = false;
    },
  });

  const customColumns = useCustomTableColumns();
  const defaultColumns = Object.values(TABLE_COLUMNS);
  const records = data?.import?.records ?? previousData?.import?.records;

  if (!records?.edges.length) {
    return null;
  }

  const pending =
    data?.import?.status === ImportStatus.PENDING && !!records.edges.length;
  const recordData = records.edges.map(edge => edge.node);

  const defaultColumnsData: ColumnWithData[] = defaultColumns.map(column => ({
    id: column.title.id ?? '',
    title: formatMessage(column.title),
    type: column.type,
    data: recordData.map(recordItem => ({
      rowId:
        recordItem.id?.personalIdentityNumber ??
        recordItem.id?.employeeNumber?.value ??
        '',
      value: column.data(recordItem),
    })),
  }));

  const customColumnsData: ColumnWithData[] = customColumns.map(column => ({
    id: column.title,
    title: column.title,
    data: recordData.map(recordItem => ({
      rowId:
        recordItem.id?.personalIdentityNumber ??
        recordItem.id?.employeeNumber?.value ??
        '',
      value: column.data(recordItem),
    })),
  }));

  const populatedColumns = (item: ColumnWithData) =>
    item.data.some(d => !!d.value);

  const columnData = [...defaultColumnsData, ...customColumnsData].filter(
    populatedColumns,
  );

  return (
    <WizardSection>
      {recordData.some(record => record.status !== ImportStatus.NO_CHANGES) && (
        <>
          <WizardSectionHeader>
            <FormattedMessage
              {...(pending
                ? employeesImportMessages.previewTableTitlePending
                : employeesImportMessages.previewTableTitle)}
            />
          </WizardSectionHeader>
          <Table
            navigation={
              <TableNavigation
                perPage={perPage}
                setPerPage={setPerPage}
                pageInfo={records.pageInfo}
                onNextPage={() => {
                  fetchMore({
                    variables: {
                      after: records.pageInfo.endCursor,
                    },
                    updateQuery: updateRecords,
                  });
                }}
                onPreviousPage={() => {
                  fetchMore({
                    variables: {
                      before: records.pageInfo.startCursor,
                      first: undefined,
                      last: Number(perPage),
                    },
                    updateQuery: updateRecords,
                  });
                }}
              />
            }
          >
            {columnData && (
              <>
                <thead>
                  <Tr>
                    {columnData.map(column => (
                      <Th key={column.id} type={column.type}>
                        {column.title}
                      </Th>
                    ))}
                  </Tr>
                </thead>
                <tbody>
                  {columnData[0].data.map(({ rowId }, index) => (
                    <Tr key={rowId}>
                      {columnData.map(column => (
                        <Td key={`${rowId}-${column.id}`} type={column.type}>
                          {column.data[index].value}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </tbody>
              </>
            )}
          </Table>
        </>
      )}
    </WizardSection>
  );
};
