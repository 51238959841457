import { ContentContainer } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { FormattedMessage } from 'components/formats';
import { RoutedTab } from 'components/RoutedTabBar';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { BenefitPackages as BenefitPackagesOverview } from 'features/companies/company/benefit-packages';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { MatchParams as CompanyMatchParams } from '..';
import { BenefitsRoute } from '../benefits';
import { BenefitsCreateRoutes } from '../benefits/create';
import { BenefitsEditRoute } from '../benefits/edit';
import { TotalCompensationRoute } from '../total-compensation';
import { BenefitPackageRoutes } from './benefit-package';

interface Tab extends RoutedTab {
  path: string;
}

export const BenefitPackages: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({ match }) => {
  const tabs: Tab[] = [
    {
      title: companyMessages.benefitPackages,
      url: match.url,
      path: match.path,
    },
    {
      title: commonMessages.benefits,
      url: `${match.url}/benefits`,
      path: `${match.path}/benefits`,
    },
    {
      title: totalCompensationMessages.totalCompensation,
      url: `${match.url}/total-compensation`,
      path: `${match.path}/total-compensation`,
    },
  ];

  return (
    <NavigationAnchor title={commonMessages.benefits} url={match.url}>
      <Switch>
        <Route exact path={tabs.map(({ path }) => path)}>
          <Page
            title={<FormattedMessage {...commonMessages.benefits} />}
            parentLink={`/companies/${match.params.companyId}`}
            tabs={tabs}
          >
            <ContentContainer>
              <Route
                exact
                path={match.path}
                component={BenefitPackagesOverview}
              />
              <Route
                exact
                path={`${match.path}/benefits`}
                component={BenefitsRoute}
              />
              <Route
                exact
                path={`${match.path}/total-compensation`}
                component={TotalCompensationRoute}
              />
            </ContentContainer>
          </Page>
        </Route>
        <Route
          path={`${match.path}/benefits/create`}
          component={BenefitsCreateRoutes}
        />
        <Route
          path={`${match.path}/benefits/:benefitId`}
          component={BenefitsEditRoute}
        />
        <Route
          path={`${match.path}/:benefitPackageId`}
          component={BenefitPackageRoutes}
        />
      </Switch>
    </NavigationAnchor>
  );
};
