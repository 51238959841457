import gql from 'graphql-tag';

export const salaryExchangeEventDetails = gql`
  fragment salaryExchangeEventDetails on FlexSalaryExchangeRequest {
    id
    effectiveDate
    status
    provider
    premium
    deductionValue
    remuneration {
      unitCode
      currency
      value: price
    }
    membership {
      id
      givenName
      lastName
      naturalPersonIdentifiers
    }
  }
`;
