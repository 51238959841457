import { Table, TableSortButton, Td, Th, Tr } from '@frontend/ui';
import {
  benefitPackagesOptionsQuery_company_Company_benefitPackages_BenefitPackageConnection_edges_BenefitPackageEdge_node_BenefitPackage as BenefitPackage,
  benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit,
  ManagingCompany,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/sme/company';
import { useTableSort } from 'app/utils/use-table-sort';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { getBenefitDisplayName } from 'features/companies/company/benefits/utils/get-benefit-display-name';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { by, SortableColumns } from '../../create/utils/sort-benefits-by';
import { DetailCell } from './DetailCell';
import { InBenefitPackage } from './InBenefitPackage';

interface Props {
  benefits: Benefit[];
  companyId: string;
  managedBy: ManagingCompany;
  benefitPackageId?: string;
  benefitPackageOptions?: BenefitPackage[];
}

export const BenefitsTable: React.FC<Props> = ({
  benefits,
  benefitPackageId,
  benefitPackageOptions,
  companyId,
  managedBy,
}) => {
  const { push } = useHistory();
  const match = useRouteMatch<MatchParams>();

  const intl = useIntl();
  const { handleSort, order, orderBy } = useTableSort<SortableColumns>();

  const sortableBenefits = benefits.map(benefit => ({
    id: benefit.id,
    name: getBenefitDisplayName(benefit, intl),
    category: benefit.categories[0]?.name,
    detail: (
      <DetailCell
        benefit={benefit}
        companyId={companyId}
        managedBy={managedBy}
      />
    ),
    inBenefitPackages: (
      <InBenefitPackage
        benefit={benefit}
        benefitPackageOptions={benefitPackageOptions}
      />
    ),
    type: benefit.type,
  }));

  const params = order && orderBy ? { order, orderBy } : undefined;
  const sortedBenefits = [...sortableBenefits].sort(by(params));

  if (!sortedBenefits.length) {
    return (
      <EmptyState
        title={
          benefitPackageId ? (
            <FormattedMessage {...commonBenefitMessages.noBenefitsInBackage} />
          ) : (
            <FormattedMessage {...commonBenefitMessages.noBenefits} />
          )
        }
      >
        {benefitPackageId ? (
          <FormattedMessage
            {...commonBenefitMessages.noBenefitsInBackageText}
          />
        ) : (
          <FormattedMessage {...commonBenefitMessages.noBenefitsText} />
        )}
      </EmptyState>
    );
  }

  return (
    <Table fixed>
      <colgroup>
        <col style={{ width: '25%' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '25%' }} />
      </colgroup>
      <thead>
        <Tr>
          <Th multiline>
            <TableSortButton
              order={orderBy === 'name' ? order : undefined}
              onClick={handleSort}
              value="name"
            >
              <FormattedMessage {...commonMessages.benefit} />
            </TableSortButton>
          </Th>
          <Th>
            <TableSortButton
              order={orderBy === 'category' ? order : undefined}
              onClick={handleSort}
              value="category"
            >
              <FormattedMessage {...commonMessages.category} />
            </TableSortButton>
          </Th>
          <Th multiline>
            <FormattedMessage {...commonMessages.detail} />
          </Th>
          <Th multiline>
            <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />
          </Th>
        </Tr>
      </thead>
      <tbody>
        {sortedBenefits.map(benefit => (
          <Tr
            key={benefit.id}
            linked
            onClick={() =>
              push(
                `/companies/${match.params.companyId}/benefit-packages/benefits/${benefit.id}`,
              )
            }
          >
            <Th multiline>{benefit.name}</Th>
            <Td>{benefit.category}</Td>
            <Td multiline>{benefit.detail}</Td>
            <Td multiline>{benefit.inBenefitPackages}</Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};
