import {
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_memberships_ProposalMembership as Employee,
  SortingDirection,
} from 'app/apollo/graphql/types';

export type SortableColumns = 'firstName';

interface Params {
  order: SortingDirection;
  orderBy: SortableColumns;
}

export const by =
  ({ order, orderBy }: Params) =>
  (a: Employee, b: Employee) => {
    if (orderBy === 'firstName') {
      return order === SortingDirection.ASC
        ? a.firstName.localeCompare(b.firstName)
        : b.firstName.localeCompare(a.firstName);
    }
    return 0;
  };
