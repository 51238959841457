import { ApolloError, useMutation } from '@apollo/client';
import {
  executeProposalMutation,
  executeProposalMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';

import { FormValues } from '..';
import { EXECUTE_PROPOSAL_MUTATION } from '../graphql/mutations';

interface Props {
  onRequestClose: () => void;
  proposalId: string;
}

interface Submit {
  submit: (values?: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = ({ onRequestClose, proposalId }: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [createProposal, { error: submissionError }] = useMutation<
    executeProposalMutation,
    executeProposalMutationVariables
  >(EXECUTE_PROPOSAL_MUTATION);

  const submit = async (values: FormValues) => {
    try {
      const res = await createProposal({
        variables: {
          input: {
            proposalId,
            dateOfSigning: values.dateOfSigning,
            signedByRelevantParties: values.signedByRelevantParties,
          },
        },
      });
      const proposal = res.data?.executeProposal;
      if (!proposal?.id) {
        return;
      }
      send({
        message: formatMessage(
          smeCompanyProposalsMessages.proposalSentToProduction,
          { name: proposal?.name },
        ),
        type: 'success',
      });
      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    submissionError,
  };
};
