import gql from 'graphql-tag';

export const ADD_CUSTOM_ITEM_MUTATION = gql`
  mutation addCustomItemMutation($input: AddCustomItemInput) {
    addCustomItem(input: $input) {
      remuneration {
        unitCode
      }
    }
  }
`;
