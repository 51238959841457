import { Formik } from '@frontend/formik';
import {
  totalCompensationConfigurationQuery,
  totalCompensationConfigurationQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { useIntl } from 'components/formats';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { formatRemunerationTypes } from '../utils/use-remuneration-types';
import { ConfigurationForm, FormValues, validationSchema } from './form';
import { TOTAL_COMPENSATION_CONFIGURATION_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

export const TotalCompensationConfiguration: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();

  const intl = useIntl();

  const { data } = useQuery<
    totalCompensationConfigurationQuery,
    totalCompensationConfigurationQueryVariables
  >(TOTAL_COMPENSATION_CONFIGURATION_QUERY, {
    errorPolicy: 'all',
    variables: { id: params.companyId },
    suspend: true,
  });

  const benefitPackages =
    data?.company?.benefitPackages?.edges.map(({ node }) => node) ?? [];

  const { submit, submissionError } = useSubmit({
    benefitPackageIds: benefitPackages.map(({ id }) => id),
  });

  if (!data?.company) {
    return null;
  }

  const benefitPackageOptions = benefitPackages.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const remunerationTypes = formatRemunerationTypes(
    data.company.remunerationTypes?.edges,
  );

  const { __typename, companyId, ...configFields } =
    data.company.totalCompensationConfiguration ?? {};

  const initialValues: FormValues = {
    benefitPackageIds: benefitPackages
      .filter(({ showTotalCompensation }) => !!showTotalCompensation)
      .map(({ id }) => id),
    ...configFields,
  };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema(intl)}
      onSubmit={submit}
      enableReinitialize
    >
      {formikProps => (
        <ConfigurationForm
          benefitPackageOptions={benefitPackageOptions}
          remunerationTypes={remunerationTypes}
          submissionError={submissionError}
          {...formikProps}
        />
      )}
    </Formik>
  );
};
