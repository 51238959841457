import { ApolloError, useMutation } from '@apollo/client';
import {
  updateNoteMutation,
  updateNoteMutationVariables,
} from 'app/apollo/graphql/types';
import { noteMessages } from 'app/messages/notes';
import { NoteMatchParams } from 'app/pages/companies/company/notes';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { NoteFormValues } from '../components/NoteForm';
import { UPDATE_NOTE_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: NoteFormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (): Submit => {
  const {
    params: { noteId, companyId, userAccountId },
  } = useRouteMatch<NoteMatchParams>();
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [updateNote, { error }] = useMutation<
    updateNoteMutation,
    updateNoteMutationVariables
  >(UPDATE_NOTE_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Note:${noteId}`,
      });
      cache.gc();
    },
  });

  const submit = async (values: NoteFormValues) => {
    try {
      const { subject, category, content, attachments } = values;

      if (noteId) {
        await updateNote({
          variables: {
            input: {
              id: noteId,
              subject,
              category,
              content,
              fileIds: attachments?.map(({ id }) => id),
            },
          },
        });
      }

      send({
        message: `${formatMessage(noteMessages.saveNoteSuccess)}`,
        type: 'success',
      });

      push(
        userAccountId
          ? `/companies/${companyId}/employees/${userAccountId}/notes/${noteId}`
          : `/companies/${companyId}/notes/${noteId}`,
      );
    } catch {
      // Do nothing
    }
  };

  return { submit, error };
};
