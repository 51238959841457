import gql from 'graphql-tag';

export const VARIABLE_COMPENSATION_CARD_QUERY = gql`
  query variableCompensationCardQuery($companyId: ID!, $userAccountId: ID!) {
    company(id: $companyId) {
      id
      totalCompensationConfiguration {
        companyId
        variableCompensation
      }
    }
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      totalCompensation: totalCompensation2 {
        key: id
        annualCompensation
      }
    }
  }
`;
