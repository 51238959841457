import gql from 'graphql-tag';

export const SINGLE_PREMIUM_DEPOSIT_HISTORY_TABLE_QUERY = gql`
  query singlePremiumDepositHistoryTableQuery(
    $userAccountId: ID!
    $companyId: ID!
  ) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      singlePremiums {
        effectiveDate
        premium
        authorizedBy # Used by authorizedByMembership in stitcher
        authorizedByMembership(companyId: $companyId) {
          givenName
          lastName
        }
      }
    }
  }
`;
