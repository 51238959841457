import { ApolloError, useMutation } from '@apollo/client';
import {
  createSharedFilesMutation,
  createSharedFilesMutationVariables,
} from 'app/apollo/graphql/types';
import { sharedFileMessages } from 'app/messages/shared-files';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { MatchParams } from '../..';
import { CREATE_SHARED_FILES_MUTATION } from '../graphql/mutations';
import { UploadFormValues } from './';

interface Submit {
  submit: (values: UploadFormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (): Submit => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [createSharedFiles, { error }] = useMutation<
    createSharedFilesMutation,
    createSharedFilesMutationVariables
  >(CREATE_SHARED_FILES_MUTATION, {
    update: cache => {
      cache.evict({
        fieldName: 'sharedFiles',
      });
      cache.gc();
    },
  });

  const submit = async (values: UploadFormValues) => {
    try {
      const { attachments, isGdprProtected } = values;

      await createSharedFiles({
        variables: {
          input: {
            companyId,
            isGdprProtected,
            fileIds: [attachments?.[0].id],
          },
        },
      });

      send({
        message: `${formatMessage(sharedFileMessages.uploadFileSucess)}`,
        type: 'success',
      });

      push(`/companies/${companyId}/reports/shared-files`);
    } catch {
      // Do nothing
    }
  };

  return { submit, error };
};
