import { sharedFilesQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: sharedFilesQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: sharedFilesQuery;
  },
) => {
  if (!previousResult?.sharedFiles || !fetchMoreResult?.sharedFiles) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.sharedFiles;

  return {
    sharedFiles: {
      pageInfo,
      edges: [...edges],
    },
  };
};
