import gql from 'graphql-tag';

export const CREATE_PROPOSAL_EMPLOYERS_PENSION_BENEFIT_MUTATION = gql`
  mutation createProposalEmployersPensionBenefitMutation(
    $input: CreateProposalEmployersPensionBenefitInput!
  ) {
    createProposalEmployersPensionBenefit(input: $input) {
      id
      benefits {
        id
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
